/* eslint-disable no-return-assign */
import React from 'react';
import axios from 'axios';
import qs from 'qs';
import NotificationSystem from 'rc-notification';
import ResetPasswordForm from './form';
import { BasicNotification } from '../../shared/components/Notification';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 2,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up'
  });
};

const handleSubmit = (values, dispatch, props) => {
  const tokenQS = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  axios
    .post(`${process.env.REACT_APP_FRONTEND_API}/reset_password`, {
      password: values.password,
      token: tokenQS.token
    })
    .then((response) => {
      if (!response.data.status) {
        showNotification('Reset Password', 'danger', 'Something went wrong!');
      } else {
        showNotification(
          'Reset Password',
          'primary',
          'Password successfully changed!'
        );
      }
      props.history.push('/');
    })
    .catch(() => {
      showNotification('Reset Password', 'danger', 'Something went wrong!');
    });
};

const ResetPassword = (props) => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            <span
              style={{ display: 'inline-block' }}
              className="topbar__logo"
            />
          </h3>
        </div>
        <ResetPasswordForm {...props} onSubmit={handleSubmit} />
      </div>
    </div>
  </div>
);

export default ResetPassword;
