import React, { useRef } from 'react';
import Scrollbar from 'simplebar-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'simplebar-react/dist/simplebar.min.css';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

const Sidebar = ({
  changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar, changeSidebarCategoryCollapse,
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });
  const scrollRef = useRef();
  const sidebarCategoryCollapse = (key) => {
    changeSidebarCategoryCollapse(key);
    scrollRef.current.showScrollbar();
  };

  return (
    <div className={sidebarClass}>
      <button className="sidebar__back" onClick={changeMobileSidebarVisibility} />
      <Scrollbar style={{ maxHeight: '100%' }} ref={scrollRef} className="scroll-simplebar">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            onClick={() => {}}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
            sidebar={sidebar}
            changeSidebarCategoryCollapse={sidebarCategoryCollapse}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
            sidebar={sidebar}
            changeSidebarCategoryCollapse={sidebarCategoryCollapse}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarCategoryCollapse: PropTypes.func.isRequired,
};

export default Sidebar;
