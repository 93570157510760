import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { inject, observer } from 'mobx-react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { LiteralProps } from '../../../shared/prop-types/ReducerProps';
import renderSelectField from '../../../shared/components/form/SelectBox';

@inject('rootStore')
@observer
class TopbarProfile extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.state = {
        collapse: false,
      };
    }

    onChange=(ra) => {
      const { riskAccount } = ra;
      this.props.rootStore.reportStore.reportState.alias = riskAccount.alias;
      this.props.rootStore.reportStore.reportState.riskAccount = riskAccount.risk_account;
      this.props.rootStore.reportStore.reportState.taker = riskAccount.taker;
      this.props.rootStore.reportStore.reportState.execModel = riskAccount.tem;
      this.props.rootStore.reportStore.reportState.feed = riskAccount.taker_feed;
      this.props.rootStore.reportStore.reportState.readOnly = riskAccount.read_only;
      this.props.rootStore.reportStore.reportState.extOrderInfo = riskAccount.ext_order_info;
      setTimeout(() => {
        window.location.reload(false);
      });
    };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { literals } = this.props;
    const userRiskAccounts = this.props.rootStore.authStore.user.user_risk_accounts || [];
    const options = userRiskAccounts.map(riskAccount =>
      ({
        label: riskAccount.alias,
        value: riskAccount.alias,
        riskAccount,
      }));
    return (
      <div className="topbar__profile">
        <form className="form broker-risk-select">
          <div className="form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.trading_account}</span>
              <div className="form__form-group-field">
                <Field
                  name="user_risk_account"
                  component={renderSelectField}
                  type="text"
                  options={options}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('top_risk_account_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    userRiskAccount: selector(state, 'user_risk_account'),
  };
}
export default connect(mapStateToProps)(reduxForm({
  form: 'top_risk_account_form', // a unique identifier for this form
  destroyOnUnmount: false,
})(TopbarProfile));
