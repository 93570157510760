import React, { PureComponent } from 'react';
import { inject } from 'mobx-react';
import { connect } from 'react-redux';
import GoldenLayoutComponent from './goldenLayoutComponent';
import TradingStatus from './tradingStatus';
import FeederStatus from './feederStatus';
import FeedingStatus from './feedingStatus';

@inject('rootStore')
class MultiWindow extends PureComponent {
  render() {
    return (
      <GoldenLayoutComponent
        htmlAttrs={{
          style: {
            height: '92vh',
          },
        }}
        config={{
          settings: {
            showPopoutIcon: false,
            showMaximiseIcon: false,
            showCloseIcon: false,
          },
          content: [
            {
              type: 'row',
              content: [
                {
                  type: 'stack',
                  content: [
                    {
                      title: 'Trading Status',
                      type: 'react-component',
                      component: 'TradingStatus',
                      isClosable: false,
                    },
                    {
                      title: 'Depth Feeding Status',
                      type: 'react-component',
                      component: 'FeedingStatus',
                      isClosable: false,
                    },
                  ],
                },
                {
                  type: 'stack',
                  content: [
                    {
                      title: 'Feeder Status',
                      type: 'react-component',
                      component: 'FeederStatus',
                      isClosable: false,
                    },
                  ],
                },
              ],
            },
          ],
        }}
        registerComponents={(myLayout) => {
          myLayout.registerComponent('TradingStatus', TradingStatus);
          myLayout.registerComponent('FeederStatus', FeederStatus);
          myLayout.registerComponent('FeedingStatus', FeedingStatus);
        }}
      />
    );
  }
}
export default connect()(MultiWindow);
