import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import axios from 'axios';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import UrgencyHeadlineNewsForm from './search';
import Collapse from '../../shared/components/Collapse';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';

const REDUCE_HEIGHT = 200;
const exportColumns = ['urgency_value', 'headline', 'created_at_value'];

class UrgencyHeadlineNews extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
    };
    constructor(props) {
      super(props);
      const { literals } = this.props;
      const tableID = 'sidebar_urgency_headline_news';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.tables.urgency_headline_news_table.urgency,
            field: 'urgency_value',
            cellRenderer(params) {
              if (params.data.urgency === '1') { // Urgency_FLASH
                return `<span class="cell-renderer-blue" style="background-color: #ff4861;color: black">
${params.value}</span>`;
              } else if (params.data.urgency === '2') { // Urgency_BACKGROUND
                return `<span class="cell-renderer-blue" style="background-color: #a5ee6e;color: black">
${params.value}</span>`;
              } else if (params.data.urgency === '0') { // Urgency_NORMAL
                return `<span class="cell-renderer-blue" style="background-color: #FFA500;color: black">
${params.value}</span>`;
              }
              return `<span class="cell-renderer-blue">${params.value}</span>`;
            },
          },
          {
            headerName: literals.tables.urgency_headline_news_table.headline,
            field: 'headline',
            width: 100,
            minWidth: 100,
          },
          {
            headerName: literals.tables.urgency_headline_news_table.created_at,
            field: 'created_at_value',
          },
        ],
        rowData: [],
        collapse: '',
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
          flex: 1,
          cellClass: 'cell-wrap-text',
          autoHeight: true,
        },
        getRowNodeId(data) {
          return data.id;
        },
        height: window.innerHeight,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }
    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.tables.urgency_headline_news_table.urgency
          !== prevProps.literals.tables.urgency_headline_news_table.urgency) {
          this.gridColumnApi.getColumn('urgency_value').getColDef().headerName
                = literals.tables.urgency_headline_news_table.urgency;
          this.gridColumnApi.getColumn('headline').getColDef().headerName
              = literals.tables.urgency_headline_news_table.headline;
          this.gridColumnApi.getColumn('created_at_value').getColDef().headerName
            = literals.tables.urgency_headline_news_table.created_at;
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.onGridSizeChanged(params);
    }

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
        params.api.resetRowHeights();
      }, 0);
    };

    onBtExportExcel = () => {
      const params = {
        columnKeys: exportColumns,
        fileName: 'UrgencyNews.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV = () => {
      const params = {
        columnKeys: exportColumns,
        fileName: 'UrgencyNews.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    handleSubmit=(values) => {
      this.gridApi.showLoadingOverlay();
      axios.post(`${process.env.REACT_APP_BACKEND_API}/monitoring/urgency_headline_news`, JSON.stringify({
        start_date: values.start_date ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf()
          : values.start_date,
        end_date: values.end_date ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf()
          : values.end_date,
        urgency: values.urgency,
      }))
        .then((response) => {
          this.setState({ rowData: response.data });
          this.gridApi.hideOverlay();
        })
        .catch(() => {
          this.gridApi.hideOverlay();
        });
      this.setState({ collapse: 'force-close' });
      setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
    };

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.urgency_headline_news}
                </h5>
              </div>
              <Collapse
                title={literals.tables.ag_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <UrgencyHeadlineNewsForm onSubmit={this.handleSubmit} />
              </Collapse>
              <div className="outer-grid-label">
                {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
              </div>
              <div className="outer-grid-button">
                <ButtonToolbar>
                  <Tooltip title={literals.tables.ag_table.export_excel}>
                    <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                      <p className="fa fa-file-excel" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.export_csv}>
                    <Button
                      onClick={this.onBtExportCSV}
                      className="icon"
                      color="primary"
                    >
                      <p className="fa fa-file-alt" />
                    </Button>
                  </Tooltip>
                </ButtonToolbar>
              </div>
              <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                <div
                  id={this.myGridID}
                  style={{
                                    boxSizing: 'border-box',
                                    height: this.state.height - REDUCE_HEIGHT,
                                    width: '100%',
                                }}
                  className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnDefs}
                    immutableData
                    suppressCellSelection
                    suppressContextMenu
                    defaultColDef={this.state.defaultColDef}
                    onColumnResized={this.onColumnResized}
                    getRowNodeId={this.state.getRowNodeId}
                    rowData={this.state.rowData}
                    onGridReady={this.onGridReady}
                    onGridSizeChanged={this.onGridSizeChanged}
                    rowHeight={30}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(UrgencyHeadlineNews);
