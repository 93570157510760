/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import Loader from '../../shared/components/Loader';
import { onGridSizeChanged, onColumnResized, numberFormatter } from '../../shared/helper';
import TakerMakerEditor from './TakerMakerEditor';
import AccountActionRenderer from './accountActionRenderer';
import TypeEditor from './typeEditor';

let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};
let notificationStay = null;
NotificationSystem.newInstance({}, n => notificationStay = n);
const showNotificationStay = (title, color, message) => {
  notificationStay.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 60,
    maxCount: 2,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
    key: '111',
  });
};

class AccountGroup extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      formData: PropTypes.instanceOf(Object).isRequired,
      rowOldDataRiskAccount: PropTypes.instanceOf(Object).isRequired,
      showLoader: PropTypes.func.isRequired,
      hideLoader: PropTypes.func.isRequired,
      resetAccountGroup: PropTypes.func.isRequired,
      rowDataRiskAccount: PropTypes.instanceOf(Array),
    };
    static defaultProps = {
      rowDataRiskAccount: [],
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.stylesToUpdate = {};
      const { literals } = this.props;
      const tableID = 'sidebar_account_group_risk_account';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.account_form.name,
            field: 'name',
            editable: false,
            pinned: 'left',
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.account_form.group,
            field: 'group',
            editable: false,
          },
          {
            headerName: literals.forms.account_form.currency,
            field: 'currency',
            editable: false,
          },
          {
            headerName: literals.forms.account_form.category,
            field: 'category_value',
            editable: false,
          },
          {
            headerName: literals.forms.account_form.type,
            field: 'type_value',
            cellEditor: 'typeEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.account_form.taker_maker,
            field: 'tm_models',
            cellEditor: 'takerMakerEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.account_form.stopout,
            field: 'stopout',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.account_form.exposure_limit,
            field: 'exposure_limit',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.account_form.warn_levels,
            field: 'warn_levels',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.account_form.state,
            field: 'state_value',
            editable: false,
            cellRenderer: 'stateRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.forms.account_form.square_off_mode,
            field: 'square_off_mode_value',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: () => ({
              values: this.props.formData.SquareOffMode ?
                this.props.formData.SquareOffMode.map(a => a.label) : [],
            }),
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 70,
            minWidth: 70,
            headerComponent: 'cellHeaderComponent',
            headerComponentParams: {
              selectValueLiteral: 'SquareOffMode',
            },
          },
          {
            headerName: literals.forms.account_form.creation_date_sec,
            field: 'creation_date_sec_value',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            editable: false,
            width: 90,
            minWidth: 90,
          },
          {
            headerName: literals.forms.account_form.margin_call_level,
            field: 'margin_call_level',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.account_form.description,
            field: 'description',
            cellEditor: 'agLargeTextCellEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
          },
        ],
        frameworkComponents: {
          takerMakerEditor: TakerMakerEditor,
          actionRenderer: AccountActionRenderer,
          stateRenderer: StateRenderer,
          stateHeaderComponent: StateHeaderComponent,
          cellHeaderComponent: HeaderComponent,
          typeEditor: TypeEditor,
        },
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.name;
        },
        cellEditUpdate: false,
        activeTab: '1',
        loaderShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.account_form.name !== prevProps.literals.forms.account_form.name) {
          this.gridColumnApi.getColumn('name').getColDef().headerName
              = literals.forms.account_form.name;
          this.gridColumnApi.getColumn('group').getColDef().headerName
              = literals.forms.account_form.group;
          this.gridColumnApi.getColumn('currency').getColDef().headerName
              = literals.forms.account_form.currency;
          this.gridColumnApi.getColumn('category_value').getColDef().headerName
              = literals.forms.account_form.category;
          this.gridColumnApi.getColumn('type_value').getColDef().headerName
              = literals.forms.account_form.type;
          this.gridColumnApi.getColumn('stopout').getColDef().headerName
              = literals.forms.account_form.stopout;
          this.gridColumnApi.getColumn('exposure_limit').getColDef().headerName
              = literals.forms.account_form.exposure_limit;
          this.gridColumnApi.getColumn('exposure_delta').getColDef().headerName
              = literals.forms.account_form.exposure_delta;
          this.gridColumnApi.getColumn('exposure_delta_span').getColDef().headerName
              = literals.forms.account_form.exposure_delta_span;
          this.gridColumnApi.getColumn('underflow').getColDef().headerName
              = literals.forms.account_form.underflow;
          this.gridColumnApi.getColumn('warn_levels').getColDef().headerName
              = literals.forms.account_form.warn_levels;
          this.gridColumnApi.getColumn('state_value').getColDef().headerName
              = literals.forms.account_form.state;
          this.gridColumnApi.getColumn('square_off_mode_value').getColDef().headerName
              = literals.forms.account_form.square_off_mode;
          this.gridColumnApi.getColumn('creation_date_sec_value').getColDef().headerName
              = literals.forms.account_form.creation_date_sec;
          this.gridColumnApi.getColumn('margin_call_level').getColDef().headerName
              = literals.forms.account_form.margin_call_level;
          this.gridColumnApi.getColumn('description').getColDef().headerName
              = literals.forms.account_form.description;
          this.gridApi.refreshHeader();
        }
      }
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.onGridSizeChanged(params);
    }

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
        typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (params.colDef.field === 'square_off_mode_value' || params.colDef.field === 'state_value'
            || params.colDef.field === 'tm_models') {
          this.onShowNotificationStay();
        }
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridApi.refreshCells({ rowNodes: [params.node], columns: ['name'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridApi.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'state_value') {
          params.data.state =
              this.props.formData.StateObj[params.newValue].value;
        }
        if (params.colDef.field === 'type_value') {
          params.data.type =
              this.props.formData.TypeObj[params.newValue].value;
        }
        if (params.colDef.field === 'square_off_mode_value') {
          params.data.square_off_mode =
              this.props.formData.SquareOffModeObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
      }
    };

    onShowNotificationStay=() => {
      notificationStay.removeNotice('111');
      showNotificationStay('Risk Account Alert', 'danger', '(1) Please note that enabling the ' +
          'risk account or changing the square off more may result in automatic trading activities ' +
          'or stop trading activities due to the application of the risk account rules on the trading stream.\n' +
          '(2) Please note that changing members of the risk account will not take into account the old open ' +
          'positions. however, it will start capturing trades from the time it is linked on wards.');
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['name', 'group', 'currency', 'category_value', 'type_value',
          'stopout', 'exposure_limit', 'exposure_delta', 'exposure_delta_span', 'underflow',
          'warn_levels', 'state_value', 'square_off_mode_value',
          'creation_date_sec_value', 'margin_call_level', 'description'],
        fileName: 'RiskAccount.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['name', 'group', 'currency', 'category_value', 'type_value',
          'stopout', 'exposure_limit', 'exposure_delta', 'exposure_delta_span', 'underflow',
          'warn_levels', 'state_value', 'square_off_mode_value',
          'creation_date_sec_value', 'margin_call_level', 'description'],
        fileName: 'RiskAccount.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    updateAccount=() => {
      this.props.showLoader();
      const bulkData = [];
      const rowData = _.keyBy(this.props.rowOldDataRiskAccount, 'name');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.name];
        const dataToUpdate = {
          name: values.name,
          group: values.group,
          currency: values.currency,
          category: parseInt(values.category, 10),
          tm_models: values.tm_models,
          type: parseInt(values.type, 10),
          stopout: parseInt(values.stopout, 10),
          exposure_limit: parseFloat(values.exposure_limit),
          exposure_delta: parseFloat(oldRow.exposure_delta),
          exposure_delta_span: parseInt(oldRow.exposure_delta_span, 10),
          underflow: parseInt(oldRow.underflow, 10),
          parent: values.parent,
          warn_levels: values.warn_levels,
          description: values.description,
          state: parseInt(values.state, 10),
          square_off_mode: parseInt(values.square_off_mode, 10),
          creation_date_sec: parseInt(values.creation_date_sec, 10),
          margin_call_level: parseFloat(values.margin_call_level),
          old_data: {
            name: oldRow.name,
            group: oldRow.group,
            currency: oldRow.currency,
            category: parseInt(oldRow.category, 10),
            tm_models: oldRow.tm_models,
            type: parseInt(oldRow.type, 10),
            stopout: parseInt(oldRow.stopout, 10),
            exposure_limit: parseFloat(oldRow.exposure_limit),
            exposure_delta: parseFloat(oldRow.exposure_delta),
            exposure_delta_span: parseInt(oldRow.exposure_delta_span, 10),
            underflow: parseInt(oldRow.underflow, 10),
            parent: oldRow.parent,
            warn_levels: oldRow.warn_levels,
            description: oldRow.description,
            state: parseInt(oldRow.state, 10),
            square_off_mode: parseInt(oldRow.square_off_mode, 10),
            creation_date_sec: parseInt(oldRow.creation_date_sec, 10),
            margin_call_level: parseFloat(oldRow.margin_call_level),
          },
        };
        bulkData.push(dataToUpdate);
      });
      axios.put(`${process.env.REACT_APP_BACKEND_API}/account_bulk`, JSON.stringify(bulkData))
        .then((response) => {
          this.resetLocalAccount();
          this.props.resetAccountGroup();
          showNotification(
            this.props.literals.sidebar.account, 'primary',
            this.props.literals.forms.completed_message,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.account, 'danger',
              `${this.props.literals.forms.fail_message} - ${response.data.toString()}}`,
            );
          }
        })
        .catch((error) => {
          this.resetLocalAccount();
          this.props.resetAccountGroup();
          showNotification(
            this.props.literals.sidebar.account, 'danger',
            `${this.props.literals.forms.fail_message} - ${error.message}}`,
          );
        });
    };

    resetLocalAccount=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({ rowNodes: [node], columns: ['name'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridApi.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="outer-grid-label">
                {literals.sidebar.account} ({literals.tables.ag_table.total_records}: {this.props.rowDataRiskAccount ?
                  this.props.rowDataRiskAccount.length : 0})
              </div>
              <div className="outer-grid-button">
                <ButtonToolbar>
                  <Tooltip title={literals.tables.ag_table.save}>
                    <Button
                      style={{
                                    display: this.state.cellEditUpdate ? '' : 'none',
                                }}
                      onClick={this.updateAccount}
                      className="icon"
                      color="primary"
                    ><p className="fa fa-save" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.undo}>
                    <Button
                      style={{
                                    display: this.state.cellEditUpdate ? '' : 'none',
                                }}
                      onClick={() => {
                                    this.props.showLoader();
                                    this.resetLocalAccount(); this.props.resetAccountGroup();
                                }}
                      className="icon"
                      color="primary"
                    ><p
                      className="fas fa-undo"
                    />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.export_excel}>
                    <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                      <p className="fa fa-file-excel" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.export_csv}>
                    <Button
                      onClick={this.onBtExportCSV}
                      className="icon"
                      color="primary"
                    >
                      <p className="fa fa-file-alt" />
                    </Button>
                  </Tooltip>
                </ButtonToolbar>
              </div>
              <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                <div
                  id={this.myGridID}
                  style={{
                            boxSizing: 'border-box',
                            height: '43vh',
                            width: '100%',
                        }}
                  className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnDefs}
                    stopEditingWhenCellsLoseFocus
                    suppressCellSelection
                    suppressContextMenu
                    immutableData
                    getRowNodeId={this.state.getRowNodeId}
                    defaultColDef={this.state.defaultColDef}
                    onColumnResized={this.onColumnResized}
                    onCellValueChanged={this.onCellValueChanged}
                    rowData={this.props.rowDataRiskAccount}
                    frameworkComponents={this.state.frameworkComponents}
                    onGridReady={this.onGridReady}
                    onGridSizeChanged={this.onGridSizeChanged}
                    rowHeight={20}
                    context={{
                      formData: this.props.formData,
                      theme: this.props.theme,
                      resetAccountGroup: this.props.resetAccountGroup,
                      showLoader: this.props.showLoader,
                      hideLoader: this.props.hideLoader,
                      onCellValueChanged: this.onCellValueChanged,
                    }}
                  />
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(AccountGroup);
