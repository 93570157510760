/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  let multiplierState = '';
  if (values.enable_multiplier) {
    if (values.enable_multiplier === 'multiplier_1') {
      multiplierState = '1|0|0';
    } else if (values.enable_multiplier === 'multiplier_2') {
      multiplierState = '0|1|0';
    } else if (values.enable_multiplier === 'multiplier_3') {
      multiplierState = '0|0|1';
    }
  }
  const data = {
    name: values.name,
    state: values.state ? 1 : 0,
    description: values.description,
    bid_points: parseInt(values.bid_points, 10),
    ask_points: parseInt(values.ask_points, 10),
    multiplier: `${values.multiplier_1 || 1}|${values.multiplier_2 || 1}|${values.multiplier_3 || 1}`,
    multiplier_state: multiplierState,
    copy_from: values.copy_from,
  };
  axios.post(`${process.env.REACT_APP_BACKEND_API}/security/markup_group`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.markup_group_form.title_create, 'primary',
          props.literals.forms.success_message,
        );
        stateObj.resetMarkupGroup();
        stateObj.toggle('1');
      } else {
        showNotification(
          props.literals.forms.markup_group_form.title_create, 'danger',
          props.literals.forms.fail_message,
        );
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.markup_group_form.title_create, 'danger', error.message);
      stateObj.hideLoader();
    });
};
