import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from './TreeSelect';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class MarketWatchSearchForm extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
      marketWatchSend: PropTypes.func.isRequired,
      showLoader: PropTypes.func.isRequired,
      hideLoader: PropTypes.func.isRequired,
      handleSymbolChange: PropTypes.func.isRequired,
    };
    constructor(props) {
      super(props);
      this.state = {
        symbol: [],
      };
    }
    componentDidMount() {
      this.props.showLoader();
      const { reportState } = this.props.rootStore.reportStore;
      axios.post(`${process.env.REACT_APP_BACKEND_API}/risk_user/trading/search`, JSON.stringify({
        alias: reportState.alias ? reportState.alias : 'null',
      }))
        .then((response) => {
          this.setState({ symbol: response.data.symbol || [] });
          this.props.handleSymbolChange(response.data.symbol);
          this.props.marketWatchSend();
          this.props.hideLoader();
        })
        .catch(() => {
          this.props.hideLoader();
        });
    }
    render() {
      const {
        handleSubmit, literals, theme,
      } = this.props;
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half form__search">
            <div className="form__form-group">
              <span className="form__form-group-label" style={{ margin: '7px 0' }}>
                {literals.tables.position_table.symbol}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="symbol"
                  component={renderTreeSelectField}
                  treeData={this.state.symbol}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">{literals.tables.trading_table.search}</Button>
              </ButtonToolbar>
            </div>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('market_watch_broker_user_search_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    symbol: selector(state, 'symbol'),
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'market_watch_broker_user_search_form', // a unique identifier for this form
  destroyOnUnmount: false,
})(MarketWatchSearchForm));
