const giveUpRuleValidate = (values, props) => {
  const errors = {};
  if (values.target_taker && !values.target_taker.value) {
    errors.target_taker =
        `${props.literals.forms.give_up_rule_form.target_taker} ${props.literals.forms.required_validate}`;
  }
  if (values.target_taker &&
    (values.target_taker.title === 'MT4' || values.target_taker.title === 'MT5')
    && values.target_login === 0) {
    errors.target_login =
        `${props.literals.forms.give_up_rule_form.target_login} ${props.literals.forms.zero_validate}`;
  }
  return errors;
};

export default giveUpRuleValidate;
