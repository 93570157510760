import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TreeSelect from 'rc-tree-select';
import masterRiskAccountValidate from './validate';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from '../filtration_pool/TreeSelect';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class MasterRiskAccountForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals, theme,
      } = this.props;
      const disabled = false;
      const { submit } = literals.forms.master_risk_account_form;
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.master_risk_account_form.name}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.master_risk_account_form.name}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.master_risk_account_form.risk_accounts}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="risk_accounts"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.RiskAccount}
                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.master_risk_account_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.master_risk_account_form.description}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.master_risk_account_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.master_risk_account_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
  };
}


export default connect(mapStateToProps)(reduxForm({
  form: 'master_risk_account_form', // a unique identifier for this form
  validate: masterRiskAccountValidate,
})(MasterRiskAccountForm));
