import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import userValidate from './userValidate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/Select';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class UserForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals,
      } = this.props;
      let clientValues = [];
      if (this.props.state) {
        clientValues = this.props.state.formData.Client;
      } else {
        clientValues = JSON.parse(JSON.parse(localStorage.getItem('persist:form')).user_form).values.formData.Client;
      }
      const roleValues = [{ label: 'admin', value: 'admin' },
        { label: 'broker', value: 'broker' }, { label: 'client', value: 'client' }];
      const disabled = false;
      const { submit } = literals.forms.user_form;

      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.username}</span>
              <div className="form__form-group-field">
                <Field
                  name="username"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.user_form.username}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.password}</span>
              <div className="form__form-group-field">
                <Field
                  name="password"
                  component={renderField}
                  type="password"
                  placeholder={literals.forms.user_form.password}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.confirm_password}</span>
              <div className="form__form-group-field">
                <Field
                  name="confirm_password"
                  component={renderField}
                  type="password"
                  placeholder={literals.forms.user_form.confirm_password}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.first_name}</span>
              <div className="form__form-group-field">
                <Field
                  name="first_name"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.user_form.first_name}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.last_name}</span>
              <div className="form__form-group-field">
                <Field
                  name="last_name"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.user_form.last_name}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.client}</span>
              <div className="form__form-group-field">
                <Field
                  name="client"
                  component={renderSelectField}
                  type="text"
                  options={clientValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.role}</span>
              <div className="form__form-group-field">
                <Field
                  name="role"
                  component={renderSelectField}
                  type="text"
                  options={roleValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.email}</span>
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.user_form.email}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.phone}</span>
              <div className="form__form-group-field">
                <Field
                  name="phone"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.user_form.phone}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.user_form.email_notify}</span>
              <div className="form__form-group-field">
                <Field
                  name="email_notify"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.user_form.email_notify}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.user_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.user_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.state) {
    if (ownProps.state.detail) {
      return {
        literals: state.literals,
        initialValues: {
          username: ownProps.state.detail.username,
          password: ownProps.state.detail.password,
          first_name: ownProps.state.detail.first_name,
          last_name: ownProps.state.detail.last_name,
          client: ownProps.state.detail.client,
          role: ownProps.state.detail.role,
          email: ownProps.state.detail.email,
          email_notify: ownProps.state.detail.email_notify,
          phone: ownProps.state.detail.phone,
          stateMode: ownProps.state.mode,
          formData: ownProps.state.formData,
        },
      };
    }
    return {
      literals: state.literals,
      initialValues: {
        stateMode: ownProps.state.mode,
        formData: ownProps.state.formData,
      },
    };
  }
  return { literals: state.literals };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'user_form', // a unique identifier for this form
  validate: userValidate,
})(UserForm));
