import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import changePasswordValidate from './validate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class ChangePasswordForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting, literals,
    } = this.props;
    const disabled = false;
    const { submit } = literals.forms.node_form;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{literals.topbar.change_password}</h5>
            </div>
            <form className="form form--horizontal" onSubmit={handleSubmit}>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.user_form.username}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="username"
                      component={renderField}
                      type="text"
                      placeholder={literals.forms.user_form.username}
                      disabled
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.user_form.current_password}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="current_password"
                      component={renderField}
                      type="password"
                      placeholder={literals.forms.user_form.current_password}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.user_form.new_password}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="new_password"
                      component={renderField}
                      type="password"
                      placeholder={literals.forms.user_form.new_password}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.user_form.confirm_new_password}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="confirm_password"
                      component={renderField}
                      type="password"
                      placeholder={literals.forms.user_form.confirm_new_password}
                      disabled={disabled}
                    />
                  </div>
                </div>

                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">{submit}</Button>
                  <Button type="button" onClick={reset} disabled={pristine || submitting}>
                    {literals.forms.user_form.reset}
                  </Button>
                </ButtonToolbar>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    literals: state.literals,
    initialValues: {
      username: props.rootStore.authStore.user.username,
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'change_password_form', // a unique identifier for this form
  enableReinitialize: true,
  validate: changePasswordValidate,
})(ChangePasswordForm));
