import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import TakerNotionalSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized
} from '../../shared/helper';
import Loader from '../../shared/components/Loader';
import PropTypes from 'prop-types';

const REDUCE_HEIGHT = 200;

class NetPerLogin extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    groupByTakerValue: PropTypes.bool,
    groupByTEMValue: PropTypes.bool
  };
  constructor(props) {
    super(props);
    const tableID = 'sidebar_net_per_login';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    const { literals } = props;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.net_per_login_table.ext_login,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.ext_login'
            ),
          field: 'ext_login',
          headerTooltip: literals.tables.net_per_login_table.ext_login,
          type: 'text'
        },
        {
          headerName: literals.tables.net_per_login_table.party_symbol,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.party_symbol'
            ),
          field: 'party_symbol',
          headerTooltip: literals.tables.net_per_login_table.party_symbol,
          minWidth: 90,
          type: 'text'
        },
        {
          headerName: literals.tables.net_per_login_table.symbol,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.net_per_login_table.symbol'),
          field: 'symbol',
          headerTooltip: literals.tables.net_per_login_table.symbol,
          minWidth: 90,
          type: 'text'
        },
        {
          headerName: literals.tables.order_table.node,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.node'),
          field: 'taker',
          headerTooltip: literals.tables.order_table.node,
          minWidth: 90,
          type: 'text'
        },
        {
          headerName: literals.tables.order_table.node_account,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.node_account'),
          field: 'tem',
          headerTooltip: literals.tables.order_table.node_account,
          minWidth: 90,
          type: 'text'
        },
        {
          headerName: literals.tables.net_per_login_table.net_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.net_fill_volume'
            ),
          field: 'net_fill_volume',
          headerTooltip: literals.tables.net_per_login_table.net_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        },
        {
          headerName: literals.tables.net_per_login_table.net_buy_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.net_buy_fill_volume'
            ),
          field: 'net_buy_fill_volume',
          headerTooltip:
            literals.tables.net_per_login_table.net_buy_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        },
        {
          headerName: literals.tables.net_per_login_table.net_sell_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.net_sell_fill_volume'
            ),
          field: 'net_sell_fill_volume',
          headerTooltip:
            literals.tables.net_per_login_table.net_sell_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        },
        {
          headerName: literals.tables.net_per_login_table.a_net_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.a_net_fill_volume'
            ),
          field: 'a_net_fill_volume',
          headerTooltip: literals.tables.net_per_login_table.a_net_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        },
        {
          headerName: literals.tables.net_per_login_table.a_net_buy_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.a_net_buy_fill_volume'
            ),
          field: 'a_net_buy_fill_volume',
          headerTooltip:
            literals.tables.net_per_login_table.a_net_buy_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        },
        {
          headerName:
            literals.tables.net_per_login_table.a_net_sell_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.a_net_sell_fill_volume'
            ),
          field: 'a_net_sell_fill_volume',
          headerTooltip:
            literals.tables.net_per_login_table.a_net_sell_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        },
        {
          headerName: literals.tables.net_per_login_table.b_net_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.b_net_fill_volume'
            ),
          field: 'b_net_fill_volume',
          headerTooltip: literals.tables.net_per_login_table.b_net_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        },
        {
          headerName: literals.tables.net_per_login_table.b_net_buy_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.b_net_buy_fill_volume'
            ),
          field: 'b_net_buy_fill_volume',
          headerTooltip:
            literals.tables.net_per_login_table.b_net_buy_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        },
        {
          headerName:
            literals.tables.net_per_login_table.b_net_sell_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.net_per_login_table.b_net_sell_fill_volume'
            ),
          field: 'b_net_sell_fill_volume',
          headerTooltip:
            literals.tables.net_per_login_table.b_net_sell_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(parseFloat(params.value));
          },
          type: 'number'
        }
      ],
      rowData: [],
      collapse: '',
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter'
        }
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: ['filterMenuTab'],
        suppressMovable: true,
        width: 50,
        minWidth: 50
      },
      getRowNodeId(data) {
        return data.ext_login + data.party_symbol + data.taker + data.tem;
      },
      height: window.innerHeight,
      loaderShow: false
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (
        literals.forms.security_form.name !==
        prevProps.literals.forms.security_form.name
      ) {
        this.gridApi.refreshHeader();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onGroupByTakerChange({
      target: {
        checked: this.props.groupByTakerValue
      }
    });
    this.onGroupByTEMChange({
      target: {
        checked: this.props.groupByTEMValue
      }
    });
    this.onGridSizeChanged(params);
    this.gridApi.refreshHeader();
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: [
        'ext_login',
        'party_symbol',
        'symbol',
        'taker',
        'tem',
        'net_fill_volume',
        'net_buy_fill_volume',
        'net_sell_fill_volume',
        'a_net_fill_volume',
        'a_net_buy_fill_volume',
        'a_net_sell_fill_volume',
        'b_net_fill_volume',
        'b_net_buy_fill_volume',
        'b_net_sell_fill_volume'
      ],
      fileName: 'NetPerLogin.xlsx',
      suppressTextAsCDATA: true
    };
    if (!this.props.groupByTakerValue) {
      params.columnKeys = params.columnKeys.filter((item) => item !== 'taker');
    }
    if (!this.props.groupByTEMValue) {
      params.columnKeys = params.columnKeys.filter((item) => item !== 'tem');
    }
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: [
        'ext_login',
        'party_symbol',
        'symbol',
        'taker',
        'tem',
        'net_fill_volume',
        'net_buy_fill_volume',
        'net_sell_fill_volume',
        'a_net_fill_volume',
        'a_net_buy_fill_volume',
        'a_net_sell_fill_volume',
        'b_net_fill_volume',
        'b_net_buy_fill_volume',
        'b_net_sell_fill_volume'
      ],
      fileName: 'NetPerLogin.csv'
    };
    if (!this.props.groupByTakerValue) {
      params.columnKeys = params.columnKeys.filter((item) => item !== 'taker');
    }
    if (!this.props.groupByTEMValue) {
      params.columnKeys = params.columnKeys.filter((item) => item !== 'tem');
    }
    this.gridApi.exportDataAsCsv(params);
  };

  onGroupByTakerChange = (event) => {
    const checked = event?.target?.checked;
    if (this.gridColumnApi) {
      this.gridColumnApi.setColumnVisible('taker', checked);
    }
  };

  onGroupByTEMChange = (event) => {
    const checked = event?.target?.checked;
    if (this.gridColumnApi) {
      this.gridColumnApi.setColumnVisible('tem', checked);
    }
  };

  handleSubmit = (values) => {
    this.gridApi.showLoadingOverlay();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/report/net_per_login`,
        JSON.stringify({
          taker: values.taker,
          tem: values.tem,
          symbol: values.symbol,
          party_symbol: values.party_symbol,
          login: values.login
            ? values.login.split(',').map((item) => item.trim())
            : undefined,
          hide_zero_a_volume: !!values.hide_zero_a_volume,
          hide_zero_b_volume: !!values.hide_zero_b_volume,
          hide_giveup: !!values.hide_giveup,
          hide_drop_copy: !!values.hide_drop_copy,
          group_by_taker: !!values.group_by_taker,
          group_by_tem: !!values.group_by_tem
        })
      )
      .then((response) => {
        this.setState({ rowData: response.data });
        this.gridApi.hideOverlay();
      })
      .catch(() => {
        this.gridApi.hideOverlay();
      });
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  localizeHeader = (header) => _.get(this.props, header);

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title card__title_custom">
              <h5 className="bold-text">{literals.sidebar.net_per_login}</h5>
            </div>
            <Collapse
              title={literals.tables.ag_table.toggle_search}
              className="with-shadow"
              collapse={this.state.collapse}
            >
              <TakerNotionalSearchForm
                onSubmit={this.handleSubmit}
                showLoader={this.showLoader}
                hideLoader={this.hideLoader}
                onGroupByTakerChange={this.onGroupByTakerChange}
                onGroupByTEMChange={this.onGroupByTEMChange}
              />
            </Collapse>
            <div className="outer-grid-label">
              {literals.tables.ag_table.total_records}:{' '}
              {this.state.rowData ? this.state.rowData.length : 0}
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip title={literals.tables.ag_table.export_excel}>
                  <Button
                    onClick={this.onBtExportExcel}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-excel" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.export_csv}>
                  <Button
                    onClick={this.onBtExportCSV}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-alt" />
                  </Button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div
              id={this.gridWrapperID}
              style={{ width: '100%', height: '100%', clear: 'both' }}
            >
              <div
                id={this.myGridID}
                style={{
                  boxSizing: 'border-box',
                  height: this.state.height - REDUCE_HEIGHT,
                  width: '100%'
                }}
                className={
                  theme === 'theme-light'
                    ? 'ag-theme-balham'
                    : 'ag-theme-balham-dark'
                }
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.columnDefs}
                  immutableData
                  suppressCellSelection
                  suppressContextMenu
                  defaultColDef={this.state.defaultColDef}
                  onColumnResized={this.onColumnResized}
                  getRowNodeId={this.state.getRowNodeId}
                  rowData={this.state.rowData}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.onGridSizeChanged}
                  rowHeight={20}
                  columnTypes={this.state.columnTypes}
                />
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals,
  groupByTakerValue:
    state.form?.net_per_login_search_form?.values?.group_by_taker,
  groupByTEMValue: state.form?.net_per_login_search_form?.values?.group_by_tem
}))(NetPerLogin);
