/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import classnames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import MakerScalingDetailCellRenderer from './detailCellRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import MakerScalingForm from './form';
import makerAction from './action';
import Loader from '../../shared/components/Loader';
import MakerScalingActionRenderer from './actionRenderer';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import MakerScalingSymbolUpload from '../maker_scaling/upload';

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));

@inject('rootStore')
class MakerScaling extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };

  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_maker_scaling';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.forms.maker_scaling_symbol_form.shared_id,
          field: 'shared_id',
          cellRenderer: 'agGroupCellRenderer',
          width: 60,
          minWidth: 60
        }
      ],
      detailCellRenderer: 'myDetailCellRenderer',
      frameworkComponents: {
        myDetailCellRenderer: MakerScalingDetailCellRenderer,
        actionRenderer: MakerScalingActionRenderer
      },
      rowData: [],
      rowOldData: [],
      formData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50
      },
      getRowNodeId(data) {
        return data.shared_id;
      },
      cellEditUpdateDetail: {},
      rowsToUpdateDetail: {},
      stylesToUpdateDetail: {},
      activeTab: '1',
      height: window.innerHeight,
      loaderShow: false
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (
        literals.forms.maker_scaling_symbol_form.shared_id !==
        prevProps.literals.forms.maker_scaling_symbol_form.shared_id
      ) {
        this.gridColumnApi.getColumn('shared_id').getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.shared_id;
        this.gridApi.refreshHeader();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.onGridSizeChanged(params);
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/maker/scaling_symbol`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({
          rowOldData: JSON.parse(
            JSON.stringify(response.data.MakerScalingSymbolData)
          )
        });
      })
      .catch(() => {});
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: ['shared_id'],
      fileName: 'MakerScaling.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: ['shared_id'],
      fileName: 'MakerScaling.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  updateData = (data) => {
    this.setState({ rowData: data.MakerScalingSymbolData });
    this.setState({
      formData: {
        MakerSession: data.MakerSessionEnum,
        MakerSessionObj: arrayToObject(data.MakerSessionEnum, 'label'),
        UniSymbol: data.UniSymbol,
        State: data.State,
        StateObj: arrayToObject(data.State, 'label')
      }
    });
  };

  resetMakerScaling = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/maker/scaling_symbol`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({
          rowOldData: JSON.parse(
            JSON.stringify(response.data.MakerScalingSymbolData)
          )
        });
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  updateStateDetail = (key, cellEditUpdate, rowsToUpdate, stylesToUpdate) => {
    const cellEditUpdateDetail = { ...this.state.cellEditUpdateDetail };
    cellEditUpdateDetail[key] = cellEditUpdate;
    this.setState({ cellEditUpdateDetail });
    const rowsToUpdateDetail = { ...this.state.rowsToUpdateDetail };
    rowsToUpdateDetail[key] = rowsToUpdate;
    this.setState({ rowsToUpdateDetail });
    const stylesToUpdateDetail = { ...this.state.stylesToUpdateDetail };
    stylesToUpdateDetail[key] = stylesToUpdate;
    this.setState({ stylesToUpdateDetail });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="tabs tabs--bordered-top tabs__custom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1'
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {literals.sidebar.maker_scaling}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '2'
                      })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {literals.forms.maker_scaling_symbol_form.title_create}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '3'
                      })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      {`${literals.forms.title_import} ${literals.sidebar.maker_scaling}`}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="outer-grid-label">
                      {literals.tables.ag_table.total_records}:{' '}
                      {this.state.rowData ? this.state.rowData.length : 0}
                    </div>
                    <div className="outer-grid-button">
                      <ButtonToolbar>
                        <Tooltip title={literals.tables.ag_table.export_excel}>
                          <Button
                            onClick={this.onBtExportExcel}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-file-excel" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.export_csv}>
                          <Button
                            onClick={this.onBtExportCSV}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-file-alt" />
                          </Button>
                        </Tooltip>
                      </ButtonToolbar>
                    </div>
                    <div
                      id={this.gridWrapperID}
                      style={{
                        width: '100%',
                        height: '100%',
                        clear: 'both'
                      }}
                    >
                      <div
                        id={this.myGridID}
                        style={{
                          boxSizing: 'border-box',
                          height: this.state.height - REDUCE_HEIGHT,
                          width: '100%'
                        }}
                        className={
                          theme === 'theme-light'
                            ? 'ag-theme-balham'
                            : 'ag-theme-balham-dark'
                        }
                      >
                        <AgGridReact
                          modules={this.state.modules}
                          columnDefs={this.state.columnDefs}
                          suppressCellSelection
                          suppressContextMenu
                          immutableData
                          getRowNodeId={this.state.getRowNodeId}
                          defaultColDef={this.state.defaultColDef}
                          onColumnResized={this.onColumnResized}
                          rowData={this.state.rowData}
                          masterDetail
                          getRowHeight={this.state.getRowHeight}
                          detailCellRenderer={this.state.detailCellRenderer}
                          detailRowHeight={340}
                          frameworkComponents={this.state.frameworkComponents}
                          onGridReady={this.onGridReady}
                          onGridSizeChanged={this.onGridSizeChanged}
                          rowHeight={20}
                          context={{
                            formData: this.state.formData,
                            resetMakerScaling: this.resetMakerScaling,
                            updateStateDetail: this.updateStateDetail,
                            cellEditUpdateDetail:
                              this.state.cellEditUpdateDetail,
                            rowsToUpdateDetail: this.state.rowsToUpdateDetail,
                            stylesToUpdateDetail:
                              this.state.stylesToUpdateDetail,
                            showLoader: this.showLoader,
                            hideLoader: this.hideLoader
                          }}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <MakerScalingForm
                      {...this.props}
                      state={{
                        formData: this.state.formData,
                        resetMakerScaling: this.resetMakerScaling,
                        toggle: this.toggle,
                        showLoader: this.showLoader,
                        hideLoader: this.hideLoader
                      }}
                      onSubmit={makerAction}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <MakerScalingSymbolUpload
                      formData={this.state.formData}
                      resetMakerScaling={this.resetMakerScaling}
                      rowOldData={this.state.rowOldData}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(MakerScaling);
