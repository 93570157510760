const lpValidate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${props.literals.forms.lp_form.name} ${props.literals.forms.required_validate}`;
  }
  if (!values.sec_types) {
    errors.sec_types = `${props.literals.forms.lp_form.securities} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default lpValidate;
