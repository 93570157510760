import React, { PureComponent } from 'react';
import { Button, Popover, PopoverBody, PopoverHeader, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, getFormValues, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import renderSelectField from '../../shared/components/form/SelectBox';
import handleTradingSubmit from './submit';
import renderInputNumberField from '../../shared/components/form/NumberInput';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';

const renderField = ({
  input, placeholder, type, disabled, step, min, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} min={min} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};
const bestAvail = 'Best Available';

@inject('rootStore')
class TradingActionForm extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      trading_action_form_values: PropTypes.instanceOf(Object),
      symbol: PropTypes.string,
      digits: PropTypes.number,
      bidPrice: PropTypes.string,
      askPrice: PropTypes.string,
      minVolume: PropTypes.number,
      maxVolume: PropTypes.number,
      priceFill: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      change: PropTypes.func.isRequired,
      oneClickTradingStatus: PropTypes.string,
      theme: ThemeProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
      literals: LiteralProps.isRequired,
    };
    static defaultProps = {
      trading_action_form_values: {},
      symbol: '',
      digits: 0,
      bidPrice: '0',
      askPrice: '0',
      minVolume: 0,
      maxVolume: 100000000000,
      priceFill: 0,
      oneClickTradingStatus: 'Enable',
    };
    constructor() {
      super();
      this.state = {
        popoverOpenBuy: false,
        popoverOpenSell: false,
      };
    }

    onOrderTypeChange=(selectedOption) => {
      if (selectedOption.value === 'Market') {
        this.props.change('price', bestAvail);
      } else {
        this.props.change('price', this.props.priceFill);
      }
    };

    toggleBuy = () => {
      this.setState({
        popoverOpenBuy: !this.state.popoverOpenBuy,
      });
    };

    toggleSell = () => {
      this.setState({
        popoverOpenSell: !this.state.popoverOpenSell,
      });
    };

    priceFormatter=(value) => {
      if (value === bestAvail) {
        return value;
      }
      return parseFloat(value).toFixed(this.props.digits);
    };

    priceParser=(value) => {
      if (value === bestAvail) {
        return value;
      }
      const reg = /^\d+(\.\d+)?$/;
      let input = '0';
      if (reg.test(value)) {
        input = value;
      }
      return parseFloat(input).toFixed(this.props.digits);
    };
    quantityParser=(value) => {
      const reg = /^[0-9\d,]*\.?[0-9\d,]*$/;
      let input = '';
      if (reg.test(value)) {
        input = value;
        const dec = input.split('.');
        if (dec.length > 1) {
          if (dec[1].length > 2) {
            input = parseFloat(input).toFixed(2);
          }
        }
      }
      return input;
    };
    deviationParser=(value) => {
      const reg = /^[0-9\d,]*\.?[0-9\d,]*$/;
      let input = '';
      if (value === '') {
        return input;
      }
      if (reg.test(value)) {
        input = parseFloat(value).toFixed(1);
      }
      return input;
    };

    render() {
      const {
        handleSubmit, priceFill, oneClickTradingStatus, minVolume, maxVolume, literals,
      } = this.props;
      const theme = this.props.theme.className;
      const extOrderInfo = this.props.rootStore.reportStore.reportState.extOrderInfo || 0;
      const orderType = [{
        label: 'Market',
        value: 'Market',
      },
      {
        label: 'Limit',
        value: 'Limit',
      },
      {
        label: 'Stop',
        value: 'Stop',
      },
      ];
      const validity = [{
        label: 'IOC',
        value: 'IOC',
      },
      {
        label: 'FOK',
        value: 'FOK',
      }, {
        label: 'GTC',
        value: 'GTC',
      }];
      return (
        <form className="form form--vertical">
          <div className="form__half form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.ord_type_value}</span>
              <div className="form__form-group-field">
                <Field
                  name="order_type"
                  component={renderSelectField}
                  type="text"
                  options={orderType}
                  onChange={this.onOrderTypeChange}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.price}</span>
              <div className="form__form-group-field">
                <Field
                  name="price"
                  component={renderInputNumberField}
                  step={1}
                  min={0}
                  value={priceFill}
                  disabled={this.props.trading_action_form_values.order_type ?
                                    this.props.trading_action_form_values.order_type.value === 'Market' : true}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.deviation}</span>
              <div className="form__form-group-field">
                <Field
                  name="deviation"
                  component={renderInputNumberField}
                  step={1}
                  min={0}
                />
              </div>
            </div>
            {extOrderInfo === 1 && (
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.tables.order_table.ext_login}</span>
                <div className="form__form-group-field">
                  <Field
                    name="client_login"
                    component={renderField}
                    type="number"
                    min="1"
                  />
                </div>
              </div>
              )}
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.volume_value}</span>
              <div className="form__form-group-field">
                <Field
                  name="quantity"
                  component={renderInputNumberField}
                  step={1}
                  min={minVolume}
                  max={maxVolume}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.validity}</span>
              <div className="form__form-group-field">
                <Field
                  name="validity"
                  component={renderSelectField}
                  type="text"
                  options={validity}
                />
              </div>
            </div>
            {extOrderInfo === 1 && (
              <React.Fragment>
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.tables.order_table.ext_order}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="order_id"
                      component={renderField}
                      type="number"
                      min="1"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.tables.order_table.ext_group}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="client_group"
                      component={renderField}
                    />
                  </div>
                </div>
              </React.Fragment>
              )}
          </div>
          <div className="form__half trading__button">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Button
                  id="PopoverTopSell"
                  color="danger"
                  type="button"
                  onClick={oneClickTradingStatus === 'Enable' ?
                      handleSubmit((values) => {
                        setTimeout(() => this.toggleSell(), 0);
                        handleTradingSubmit({
                        ...values,
                        side: 'Sell',
                        symbol: this.props.symbol,
                        account_alias: this.props.rootStore.reportStore.reportState.alias,
                        digits: this.props.digits,
                        bid_price: this.props.bidPrice,
                        ask_price: this.props.askPrice,
                        rootStore: this.props.rootStore,
                        literals,
                    });
}) : this.toggleSell}
                >{literals.tables.trading_table.sell.toUpperCase()}
                </Button>
                <Popover
                  placement="top"
                  isOpen={this.state.popoverOpenSell}
                  target="PopoverTopSell"
                  toggle={this.toggleSell}
                  className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
                >
                  <PopoverHeader>{literals.tables.trading_table.confirm_trading}</PopoverHeader>
                  <PopoverBody>
                    <span>{literals.tables.trading_table.sell_message} {this.props.trading_action_form_values.quantity}?
                    </span>
                    <ButtonToolbar className="btn-toolbar--center">
                      <Button className="btn btn-danger" onClick={this.toggleSell}>
                        {literals.forms.cancel}
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={handleSubmit(values => handleTradingSubmit({
                                        ...values,
                                        side: 'Sell',
                                        symbol: this.props.symbol,
                                        account_alias: this.props.rootStore.reportStore.reportState.alias,
                                        digits: this.props.digits,
                                        bid_price: this.props.bidPrice,
                                        ask_price: this.props.askPrice,
                                        toggle: this.toggleSell,
                                        rootStore: this.props.rootStore,
                                        literals,
                                    }))}
                      >
                        {literals.forms.confirm}
                      </Button>
                    </ButtonToolbar>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
          </div>
          <div className="form__half trading__button__last">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Button
                  id="PopoverTopBuy"
                  color="blue"
                  type="button"
                  onClick={oneClickTradingStatus === 'Enable' ?
                        handleSubmit((values) => {
                          setTimeout(() => this.toggleBuy(), 0);
                          handleTradingSubmit({
                        ...values,
                        side: 'Buy',
                        symbol: this.props.symbol,
                        account_alias: this.props.rootStore.reportStore.reportState.alias,
                        digits: this.props.digits,
                        bid_price: this.props.bidPrice,
                        ask_price: this.props.askPrice,
                        rootStore: this.props.rootStore,
                        literals,
                    });
}) : this.toggleBuy}
                >{literals.tables.trading_table.buy.toUpperCase()}
                </Button>
                <Popover
                  placement="top"
                  isOpen={this.state.popoverOpenBuy}
                  target="PopoverTopBuy"
                  toggle={this.toggleBuy}
                  className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
                >
                  <PopoverHeader>{literals.tables.trading_table.confirm_trading}</PopoverHeader>
                  <PopoverBody>
                    <span>{literals.tables.trading_table.buy_message} {this.props.trading_action_form_values.quantity}?
                    </span>
                    <ButtonToolbar className="btn-toolbar--center">
                      <Button className="btn btn-blue" onClick={this.toggleBuy}>
                        {literals.forms.cancel}
                      </Button>
                      <Button
                        className="btn btn-blue"
                        onClick={handleSubmit(values => handleTradingSubmit({
                            ...values,
                            side: 'Buy',
                            symbol: this.props.symbol,
                            account_alias: this.props.rootStore.reportStore.reportState.alias,
                            digits: this.props.digits,
                            bid_price: this.props.bidPrice,
                            ask_price: this.props.askPrice,
                            toggle: this.toggleBuy,
                            rootStore: this.props.rootStore,
                            literals,
                        }))}
                      >
                        {literals.forms.confirm}
                      </Button>
                    </ButtonToolbar>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
          </div>
        </form>
      );
    }
}
const selector = formValueSelector('trading_action_form');

function mapStateToProps(state, props) {
  return {
    literals: state.literals,
    theme: state.theme,
    order_type: selector(state, 'order_type'),
    trading_action_form_values: getFormValues('trading_action_form')(state),
    initialValues: {
      order_type: {
        label: 'Market',
        value: 'Market',
      },
      price: bestAvail,
      quantity: props.minVolume || 0,
      validity: {
        label: 'IOC',
        value: 'IOC',
      },
      deviation: '',
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'trading_action_form', // a unique identifier for this form
  enableReinitialize: true,
})(TradingActionForm));
