import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class ChartBarLimitSearchForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      risk_account: [],
      asset_class: [],
    };
  }
  componentDidMount() {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/report/search`)
      .then((response) => {
        this.setState({ risk_account: response.data.risk_account_select });
      })
      .catch(() => {
      });
    axios.get(`${process.env.REACT_APP_BACKEND_API}/report/asset_class_list`)
      .then((response) => {
        this.setState({ asset_class: response.data });
      })
      .catch(() => {
      });
  }
  render() {
    const {
      handleSubmit, literals,
    } = this.props;
    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__half form__search">
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.tables.balance_transaction_table.risk_account}</span>
            <div className="form__form-group-field">
              <Field
                name="risk_account"
                component={renderSelectField}
                type="text"
                options={this.state.risk_account}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.forms.asset_class_form.name}</span>
            <div className="form__form-group-field">
              <Field
                name="asset_class"
                component={renderSelectField}
                type="text"
                options={this.state.asset_class}
              />
            </div>
          </div>
        </div>
        <div className="form__half">
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">{literals.tables.ag_table.search}</Button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    initialValues: {
      risk_account: { label: 'GLOBAL_RISK', value: 'GLOBAL_RISK' },
      asset_class: { label: 'ALL', value: 'ALL' },
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'chart_bar_limit_search_form', // a unique identifier for this form
})(ChartBarLimitSearchForm));
