import React, { PureComponent } from 'react';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import PropTypes from 'prop-types';

class MultiDatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]).isRequired,
    multiple: PropTypes.bool,
    theme: PropTypes.string,
    dateFormat: PropTypes.string,
  };
  static defaultProps = {
    multiple: false,
    theme: 'theme-light',
    dateFormat: null,
  };

  handleChange = (date) => {
    this.props.onChange(date);
  };

  render() {
    const {
      value, name, multiple, theme, dateFormat,
    } = this.props;
    return (
      <div className="date-picker">
        <DatePicker
          name={name}
          format={dateFormat}
          value={value}
          onChange={this.handleChange}
          className={theme === 'theme-light' ?
              '' : 'bg-dark'}
          multiple={multiple}
          plugins={[
            <DatePanel />,
          ]}
        />
      </div>
    );
  }
}

const renderMultiDatePickerField = props => (
  <div className="form__form-group-input-wrap">
    <MultiDatePickerField
      {...props.input}
      multiple={props.multiple}
      theme={props.theme}
      dateFormat={props.dateFormat}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderMultiDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  multiple: PropTypes.bool,
  theme: PropTypes.string,
  dateFormat: PropTypes.string,
};

renderMultiDatePickerField.defaultProps = {
  meta: null,
  multiple: false,
  theme: 'theme-light',
  dateFormat: null,
};

export default renderMultiDatePickerField;
