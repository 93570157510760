/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import ValuesForm from './valuesForm';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

class SessionEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    colDef: PropTypes.instanceOf(Object).isRequired,
    pattern: PropTypes.bool,
    context: PropTypes.instanceOf(Object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    literals: LiteralProps.isRequired,
  };
  static defaultProps = {
    pattern: false,
  };
  constructor(props) {
    super(props);
    let values = [];
    const val = props.value.split(',');
    val.forEach((item) => {
      const tagVal = item.split('=');
      values.push({ tag: tagVal[0], val: tagVal[1] });
    });
    if (values.length === 0) {
      values = [{ tag: '', val: '' }];
    }
    this.state = {
      values,
    };
  }

  handleValuesChange=(values) => {
    this.setState({ values });
  }

  render() {
    return (
      <div>
        <form className="form form--vertical" onSubmit={this.props.handleSubmit}>
          <div className="borderValuesForm">
            <ValuesForm {...this.props} values={this.state.values} onChangeValues={this.handleValuesChange} />
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" type="submit">{this.props.literals.forms.submit}</Button>
              <Button color="primary" onClick={this.props.toggleModalSession}>
                {this.props.literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}
const selector = formValueSelector('maker_api_link_table_form');

function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    pattern: selector(state, 'pattern'),
    initialValues: {
      tree: ownProps.value === '*,' ? ['*,'] : ownProps.value.split(','),
      text: ownProps.value,
    },
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(reduxForm({
  form: 'maker_api_link_table_form', // a unique identifier for this form
})(SessionEdit));
