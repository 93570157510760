import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import nodeAccountValidate from './validate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/Select';
import renderMultiSelectField from '../../shared/components/form/MultiSelect';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class NodeAccountForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals,
      } = this.props;
      const nodeValues = this.props.state.formData.Node || [];
      const markupGroupValues = this.props.state.formData.MarkupGroup || [];
      const aggregateGroupValues = this.props.state.formData.AggregateGroup || [];
      const disabled = false;
      const { submit } = literals.forms.node_account_form;
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.name}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.node_account_form.name}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.node}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="node"
                  component={renderMultiSelectField}
                  type="text"
                  options={nodeValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.currency}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="currency"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.node_account_form.currency}
                  disabled
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.trade_limit}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="trade_limit"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.node_account_form.trade_limit}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.markup_group}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="markup_group"
                  component={renderSelectField}
                  type="text"
                  options={markupGroupValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.aggregate_group}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="aggregate_group"
                  component={renderSelectField}
                  type="text"
                  options={aggregateGroupValues}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.trade_span}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="trade_span"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.node_account_form.trade_span}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.trade_reject_delay}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="trade_reject_delay"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.node_account_form.trade_reject_delay}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.state}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="state"
                  component={renderCheckBoxField}
                  label={literals.forms.aggregate_group_form.state}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.node_account_form.description}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.copy_from}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="copy_from"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.NodeAccount}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.node_account_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.node_account_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.state) {
    if (ownProps.state.detail) {
      return {
        literals: state.literals,
        initialValues: {
          name: ownProps.state.detail.name,
          node: ownProps.state.detail.node,
          currency: ownProps.state.detail.currency,
          trade_limit: ownProps.state.detail.trade_limit,
          trade_span: ownProps.state.detail.trade_span,
          trade_reject_delay: ownProps.state.detail.trade_reject_delay,
          markup_group: ownProps.state.detail.markup_group,
          aggregate_group: ownProps.state.detail.aggregate_group,
          state: ownProps.state.detail.state,
          description: ownProps.state.detail.description,
          stateMode: ownProps.state.mode,
          formData: ownProps.state.formData,
        },
      };
    }
    return {
      literals: state.literals,
      initialValues: {
        state: false,
        stateMode: ownProps.state.mode,
        formData: ownProps.state.formData,
        trade_limit: 500,
        trade_span: 1000,
        trade_reject_delay: 200,
        currency: 'USD',
      },
    };
  }
  return { literals: state.literals };
}


export default connect(mapStateToProps)(reduxForm({
  form: 'node_account_form', // a unique identifier for this form
  validate: nodeAccountValidate,
})(NodeAccountForm));
