/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham-dark.css';
import { withRouter } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import axios from 'axios';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ThemeProps, LiteralProps } from '../../shared/prop-types/ReducerProps';
import ServerInstanceDetailCellRenderer from './detailCellRenderer';
import ServerInstanceForm from './form';
import serverInstanceAction from './action';
import Loader from '../../shared/components/Loader';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

@inject('rootStore')
class ServerInstance extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      const { literals } = this.props;
      const tableID = 'sidebar_server_instance';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.client_form.name,
            field: 'name',
            cellRenderer: 'agGroupCellRenderer',
            pinned: 'left',
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.client_form.code,
            field: 'code',
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.client_form.server_address,
            field: 'server_address',
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.client_form.zmq_address,
            field: 'zmq_address',
            width: 60,
            minWidth: 60,
          },
        ],
        detailCellRenderer: 'myDetailCellRenderer',
        frameworkComponents: {
          myDetailCellRenderer: ServerInstanceDetailCellRenderer,
        },
        rowData: [],
        formData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.name;
        },
        cellEditUpdateDetail: {},
        rowsToUpdateDetail: {},
        stylesToUpdateDetail: {},
        activeTab: '1',
        height: window.innerHeight,
        loaderShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.client_form.name !== prevProps.literals.forms.client_form.name) {
          this.gridColumnApi.getColumn('name').getColDef().headerName = literals.forms.client_form.name;
          this.gridColumnApi.getColumn('code').getColDef().headerName = literals.forms.client_form.code;
          this.gridColumnApi.getColumn('server_address').getColDef().headerName =
            literals.forms.client_form.server_address;
          this.gridColumnApi.getColumn('zmq_address').getColDef().headerName =
            literals.forms.client_form.zmq_address;
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.onGridSizeChanged(params);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/client`)
        .then((response) => {
          this.updateData(response.data);
        })
        .catch(() => {
        });
    }

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    updateData = (data) => {
      this.setState({ rowData: data.Client });
      this.setState({
        formData: {
          Client: data.ClientEnum,
          ClientObj: arrayToObject(data.ClientEnum, 'label'),
        },
      });
    };

    resetClient=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/client`)
        .then((response) => {
          this.updateData(response.data);
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  updateStateDetail=(key, cellEditUpdate, rowsToUpdate, stylesToUpdate) => {
    const cellEditUpdateDetail = { ...this.state.cellEditUpdateDetail };
    cellEditUpdateDetail[key] = cellEditUpdate;
    this.setState({ cellEditUpdateDetail });
    const rowsToUpdateDetail = { ...this.state.rowsToUpdateDetail };
    rowsToUpdateDetail[key] = rowsToUpdate;
    this.setState({ rowsToUpdateDetail });
    const stylesToUpdateDetail = { ...this.state.stylesToUpdateDetail };
    stylesToUpdateDetail[key] = stylesToUpdate;
    this.setState({ stylesToUpdateDetail });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title card__title_custom">
              <h5 className="bold-text">{literals.sidebar.server_instance}
              </h5>
            </div>
            <div className="tabs tabs--bordered-top tabs__custom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                          this.toggle('1');
                        }}
                    >
                      {literals.sidebar.server_instance}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                          this.toggle('2');
                        }}
                    >
                      {literals.forms.server_instance_form.title_create}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                      <div
                        id={this.myGridID}
                        style={{
                            boxSizing: 'border-box',
                            height: this.state.height - REDUCE_HEIGHT,
                            width: '100%',
                          }}
                        className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                      >
                        <AgGridReact
                          modules={this.state.modules}
                          columnDefs={this.state.columnDefs}
                          suppressCellSelection
                          suppressContextMenu
                          immutableData
                          getRowNodeId={this.state.getRowNodeId}
                          defaultColDef={this.state.defaultColDef}
                          onColumnResized={this.onColumnResized}
                          onCellValueChanged={this.onCellValueChanged}
                          rowData={this.state.rowData}
                          masterDetail
                          getRowHeight={this.state.getRowHeight}
                          detailCellRenderer={this.state.detailCellRenderer}
                          detailRowHeight={340}
                          frameworkComponents={this.state.frameworkComponents}
                          onGridReady={this.onGridReady}
                          onGridSizeChanged={this.onGridSizeChanged}
                          rowHeight={20}
                          context={{
                            formData: this.state.formData,
                            resetClient: this.resetClient,
                            updateStateDetail: this.updateStateDetail,
                            cellEditUpdateDetail: this.state.cellEditUpdateDetail,
                            rowsToUpdateDetail: this.state.rowsToUpdateDetail,
                            stylesToUpdateDetail: this.state.stylesToUpdateDetail,
                            showLoader: this.showLoader,
                            hideLoader: this.hideLoader,
                          }}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <ServerInstanceForm
                      {...this.props}
                      state={{
                          formData: this.state.formData,
                          resetClient: this.resetClient,
                          toggle: this.toggle,
                          showLoader: this.showLoader,
                          hideLoader: this.hideLoader,
                        }}
                      onSubmit={serverInstanceAction}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withRouter(connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(ServerInstance));
