import React, { PureComponent } from 'react';
import 'rc-input-number/assets/index.css';
import InputNumber from 'rc-input-number';
import PropTypes from 'prop-types';

class InputNumberField extends PureComponent {
    static propTypes = {
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      step: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      min: PropTypes.number,
      max: PropTypes.number,
      formatter: PropTypes.func,
      parser: PropTypes.func,
      disabled: PropTypes.bool,
    };

    static defaultProps = {
      disabled: false,
      step: 1,
      min: 0,
      max: 100000000000,
      formatter: value => value,
      parser: displayValue => displayValue,
    };

    formatter = value => parseFloat(value).toFixed(6);

    render() {
      const {
        value, name, disabled, onChange, formatter, step, parser, min, max,
      } = this.props;

      return (
        <InputNumber
          name={name}
          step={step}
          value={value}
          min={min}
          max={max}
          formatter={formatter}
          onChange={onChange}
          disabled={disabled}
          parser={parser}
        />
      );
    }
}

const renderInputNumberField = props => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <InputNumberField
      {...props.input}
      formatter={props.formatter}
      parser={props.parser}
      step={props.step}
      min={props.min}
      max={props.max}
      disabled={props.disabled}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderInputNumberField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  formatter: PropTypes.func,
  step: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
};

renderInputNumberField.defaultProps = {
  meta: null,
  disabled: false,
  step: 1,
  min: 0,
  max: 100000000000,
  formatter: value => value,
};

export default renderInputNumberField;
