/* eslint-disable arrow-parens */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';
import TotalPositionStatusBarComponent from './totalPositionStatusBarComponent';

const windowHeight = window.screen.height;

@inject('rootStore')
class PositionBT extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rowData: PropTypes.instanceOf(Array),
    rowDataTotal: PropTypes.instanceOf(Object),
  };
  static defaultProps = {
    rowData: [],
    rowDataTotal: {},
  };

  constructor(props) {
    super(props);
    const tableID = 'sidebar_balance_transaction_report_position';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.gridWrapperIDTotal = `grid-wrapper-${tableID}-total`;
    this.myGridIDTotal = `myGrid_${tableID}-total`;
    const { literals } = props;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: 'Pos ID',
          field: 'position_id',
          width: 40,
          minWidth: 40,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.symbol,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.symbol'),
          field: 'symbol_val',
          headerTooltip: literals.tables.position_table.symbol,
          width: 60,
          minWidth: 60,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.risk_account,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.risk_account'),
          field: 'riskaccount',
          headerTooltip: literals.tables.position_table.risk_account,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.aggregate_group,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.aggregate_group'),
          field: 'aggregate_group',
          headerTooltip: literals.tables.position_table.aggregate_group,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.net_volume,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.net_volume'),
          field: 'net_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.net_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.anet_volume,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.anet_volume'),
          field: 'anet_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.anet_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.bnet_volume,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.bnet_volume'),
          field: 'bnet_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.bnet_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.avg_price,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.avg_price'),
          field: 'avg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.avg_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.aavg_price,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.aavg_price'),
          field: 'aavg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.aavg_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.bavg_price,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.bavg_price'),
          field: 'bavg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.bavg_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.last_time,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.last_time'),
          field: 'last_time_value',
          headerTooltip: literals.tables.position_table.last_time,
          tooltipField: 'last_time_value',
          width: 85,
          minWidth: 85,
          suppressMenu: true,
        },
        {
          headerName: literals.tables.position_table.close_price,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.close_price'),
          field: 'close_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.close_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.pl,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.pl'),
          field: 'pl',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.pl,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.margin,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.margin'),
          field: 'margin',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.margin,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.notional,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.notional'),
          field: 'notional',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.notional,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.swaps,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.swaps'),
          field: 'swaps',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.swaps,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.commission,
          headerValueGetter: () => this.localizeHeader('literals.tables.position_table.commission'),
          field: 'commission',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.commission,
          type: 'number',
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
        menuTabs: ['filterMenuTab'],
        suppressMovable: true,
      },
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter',
        },
      },
      getRowNodeId(data) {
        return data.position_id + data.symbol_val + data.riskaccount;
      },
      frameworkComponents: {
        totalPositionStatusBarComponent: TotalPositionStatusBarComponent,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'totalPositionStatusBarComponent' },
        ],
      },
    };
    this.onGridReadyPosition = this.onGridReadyPosition.bind(this);
    this.onGridReadyPositionTotal = this.onGridReadyPositionTotal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApiPosition) {
      const { literals } = this.props;
      if (literals.forms.security_form.name !== prevProps.literals.forms.security_form.name) {
        this.gridApiPosition.refreshHeader();
      }
    }
  }

  onGridReadyPosition(params) {
    this.gridApiPosition = params.api;
    this.gridColumnApiPosition = params.columnApi;
    this.onGridSizeChangedPosition(params);
    this.gridApiPosition.hideOverlay();
    this.gridApiPosition.refreshHeader();
  }

  onGridSizeChangedPosition = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResizedPosition = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onGridReadyPositionTotal(params) {
    this.gridApiPositionTotal = params.api;
    this.gridColumnApiPositionTotal = params.columnApi;
    this.onGridSizeChangedPositionTotal(params);
    this.gridApiPositionTotal.hideOverlay();
  }

  onGridSizeChangedPositionTotal = (params) => {
    onGridSizeChanged(params, this.gridWrapperIDTotal, this.myGridIDTotal);
  };

  onColumnResizedPositionTotal = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridIDTotal);
    }, 0);
  };

  localizeHeader=header => _.get(this.props, header)

  render() {
    const theme = this.props.theme.className;
    const { literals } = this.props;
    let height = ((this.props.rowData ? this.props.rowData.length : 0) * 28) + 56 + 34;
    const wHeight = windowHeight - 400;
    if (height > wHeight) {
      height = wHeight;
    }
    return (
      <div>
        <div className="outer-grid-label">
          {literals.tables.ag_table.total_records}: {this.props.rowData ? this.props.rowData.length : 0}
        </div>
        <div
          id={this.gridWrapperID}
          style={{
 width: '100%', height: '100%', clear: 'both',
}}
        >
          <div
            id={this.myGridID}
            style={{
                                    boxSizing: 'border-box',
                                    height,
                                    width: '100%',
                                }}
            className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              immutableData
              suppressCellSelection
              suppressContextMenu
              defaultColDef={this.state.defaultColDef}
              onColumnResized={this.onColumnResizedPosition}
              getRowNodeId={this.state.getRowNodeId}
              rowData={this.props.rowData}
              onGridReady={this.onGridReadyPosition}
              onGridSizeChanged={this.onGridSizeChangedPosition}
              columnTypes={this.state.columnTypes}
              frameworkComponents={this.state.frameworkComponents}
              statusBar={this.state.statusBar}
              context={{
                rowDataTotal: this.props.rowDataTotal,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(PositionBT);
