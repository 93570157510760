/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import AssetClassActionRenderer from './assetClassActionRenderer';
import StateRenderer from '../../shared/components/StateRenderer';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class AssetClassGroupDetailCellRenderer extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      data: PropTypes.instanceOf(Object).isRequired,
      api: PropTypes.instanceOf(Object).isRequired,
      context: PropTypes.instanceOf(Object).isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = props.context.rowsToUpdateDetail[props.node.id] || {};
      this.stylesToUpdate = props.context.stylesToUpdateDetail[props.node.id] || {};
      const { literals } = this.props;
      this.state = {
        modules: AllModules,
        colDefs: [
          {
            headerName: literals.forms.asset_class_form.name,
            field: 'name',
            editable: false,
            pinned: 'left',
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.asset_class_form.symbols,
            field: 'symbols_val',
            editable: false,
          },
          {
            headerName: literals.forms.asset_class_form.exposure_limit,
            field: 'exposure_limit',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.asset_class_form.buffer_percent,
            field: 'buffer_percent',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.asset_class_form.state,
            field: 'state_value',
            editable: false,
            cellRenderer: 'stateRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
          },
        ],
        frameworkComponents: {
          actionRenderer: AssetClassActionRenderer,
          stateRenderer: StateRenderer,
          stateHeaderComponent: StateHeaderComponent,
          cellHeaderComponent: HeaderComponent,
        },
        rowData: [],
        rowOldData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        cellEditUpdate: props.context.cellEditUpdateDetail[props.node.id],
        getRowNodeId(data) {
          return data.name;
        },
      };

      this.state.rowID = props.node.id ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff') : props.node.id;
      this.gridWrapperID = 'grid-wrapper-sidebar_asset_class_group';
      this.myGridID = `myGrid_sidebar_asset_class_${this.state.rowID}`;
      this.state.masterGridApi = props.api;
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridInfo.columnApi) {
        const { literals } = this.props;
        if (literals.forms.asset_class_form.name !== prevProps.literals.forms.asset_class_form.name) {
          this.gridInfo.columnApi.getColumn('name').getColDef().headerName
              = literals.forms.asset_class_form.name;
          this.gridInfo.columnApi.getColumn('symbols_val').getColDef().headerName
                  = literals.forms.asset_class_form.symbols;
          this.gridInfo.columnApi.getColumn('exposure_limit').getColDef().headerName
                  = literals.forms.asset_class_form.exposure_limit;
          this.gridInfo.columnApi.getColumn('buffer_percent').getColDef().headerName
                  = literals.forms.asset_class_form.buffer_percent;
          this.gridInfo.columnApi.getColumn('state_value').getColDef().headerName
            = literals.forms.asset_class_form.state;
          this.gridInfo.api.refreshHeader();
        }
      }
    }

    componentWillUnmount = () => {
      if (this.gridInfo) {
        window[`colStateAssetClassGroup${this.state.rowID}`] = this.gridInfo.columnApi.getColumnState();
        window[`groupStateAssetClassGroup${this.state.rowID}`] = this.gridInfo.columnApi.getColumnGroupState();
        window[`filterStateAssetClassGroup${this.state.rowID}`] = this.gridInfo.api.getFilterModel();
      }

      const detailGridId = this.createDetailGridId();

      // ag-Grid is automatically destroyed
      this.state.masterGridApi.removeDetailGridInfo(detailGridId);
    };

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridInfo.api.refreshCells({ rowNodes: [params.node], columns: ['name'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridInfo.api.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'state_value') {
          params.data.state =
            this.props.context.formData.StateObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
        this.props.context.updateStateDetail(
          this.state.rowID,
          true, this.rowsToUpdate, this.stylesToUpdate,
        );
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onGridReady(params) {
      const detailGridId = this.createDetailGridId();

      this.gridInfo = {
        id: detailGridId,
        api: params.api,
        columnApi: params.columnApi,
      };
      this.gridInfo.api.showLoadingOverlay();
      this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfo);

      if (window[`colStateAssetClassGroup${this.state.rowID}`]) {
        this.gridInfo.columnApi.setColumnState(window[`colStateAssetClassGroup${this.state.rowID}`]);
      }
      if (window[`groupStateAssetClassGroup${this.state.rowID}`]) {
        this.gridInfo.columnApi.setColumnGroupState(window[`groupStateAssetClassGroup${this.state.rowID}`]);
      }
      if (window[`filterStateAssetClassGroup${this.state.rowID}`]) {
        this.gridInfo.api.setFilterModel(window[`filterStateAssetClassGroup${this.state.rowID}`]);
      }
      this.setState({ rowData: this.props.data.asset_classes });
      this.setState({ rowOldData: JSON.parse(JSON.stringify(this.props.data.asset_classes)) });
      this.onGridSizeChanged(params);
    }

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['name', 'symbols_val', 'exposure_limit', 'buffer_percent', 'state_value'],
        fileName: 'AssetClass.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridInfo.api.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['name', 'symbols_val', 'exposure_limit', 'buffer_percent', 'state_value'],
        fileName: 'AssetClass.csv',
      };
      this.gridInfo.api.exportDataAsCsv(params);
    };

    createDetailGridId = () => `detail_${this.state.rowID}`;

    updateAssetClass=() => {
      this.props.context.showLoader();
      const bulkData = [];
      const rowData = _.keyBy(this.state.rowOldData, 'name');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.name];
        const dataToUpdate = {
          name: values.name,
          symbols_val: values.symbols_val,
          exposure_limit: parseFloat(values.exposure_limit),
          buffer_percent: parseFloat(values.buffer_percent),
          state: parseInt(values.state, 10),
          old_data: {
            name: oldRow.name,
            symbols_val: oldRow.symbols_val,
            exposure_limit: parseFloat(oldRow.exposure_limit),
            buffer_percent: parseFloat(oldRow.buffer_percent),
            state: parseInt(oldRow.state, 10),
          },
        };
        bulkData.push(dataToUpdate);
      });
      axios.put(`${process.env.REACT_APP_BACKEND_API}/asset_class_bulk`, JSON.stringify(bulkData))
        .then((response) => {
          this.resetAssetClass();
          showNotification(
            this.props.literals.sidebar.asset_class, 'primary',
            this.props.literals.forms.completed_message,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.asset_class, 'danger',
              `${this.props.literals.forms.fail_message} - ${response.data.toString()}}`,
            );
          }
        })
        .catch((error) => {
          this.resetAssetClass();
          showNotification(
            this.props.literals.sidebar.asset_class, 'danger',
            `${this.props.literals.forms.fail_message} - ${error.message}}`,
          );
        });
    };

    resetAssetClass=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      this.props.context.resetAssetClassGroup();
      window[`colStateAssetClassGroup${this.state.rowID}`] = this.gridInfo.columnApi.getColumnState();
      window[`groupStateAssetClassGroup${this.state.rowID}`] = this.gridInfo.columnApi.getColumnGroupState();
      window[`filterStateAssetClassGroup${this.state.rowID}`] = this.gridInfo.api.getFilterModel();
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridInfo.api.getRowNode(keys);
        if (node) {
          this.gridInfo.api.refreshCells({ rowNodes: [node], columns: ['name'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridInfo.api.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
      this.props.context.updateStateDetail(
        this.state.rowID,
        false, this.rowsToUpdate, this.stylesToUpdate,
      );
    };

    render() {
      const { literals } = this.props;
      return (
        <div style={{
          boxSizing: 'border-box', width: '100%', height: '100%',
        }}
        >
          <div className="outer-grid-label">
            {literals.sidebar.asset_class} ({literals.tables.ag_table.total_records}: {this.state.rowData.length})
          </div>
          <div className="outer-grid-button">
            <ButtonToolbar>
              <Tooltip title={literals.tables.ag_table.save}>
                <Button
                  style={{
                                display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                  onClick={this.updateAssetClass}
                  className="icon"
                  color="primary"
                ><p className="fa fa-save" />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.undo}>
                <Button
                  style={{
                                display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                  onClick={() => {
                    this.props.context.showLoader();
                    this.resetAssetClass();
                  }}
                  className="icon"
                  color="primary"
                ><p
                  className="fas fa-undo"
                />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.export_excel}>
                <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                  <p className="fa fa-file-excel" />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.export_csv}>
                <Button
                  onClick={this.onBtExportCSV}
                  className="icon"
                  color="primary"
                >
                  <p className="fa fa-file-alt" />
                </Button>
              </Tooltip>
            </ButtonToolbar>
          </div>
          <div
            id={this.myGridID}
            style={{ width: '100%', height: this.props.node.rowHeight - REDUCE_HEIGHT, clear: 'both' }}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.colDefs}
              stopEditingWhenCellsLoseFocus
              suppressCellSelection
              suppressContextMenu
              immutableData
              getRowNodeId={this.state.getRowNodeId}
              frameworkComponents={this.state.frameworkComponents}
              defaultColDef={this.state.defaultColDef}
              onColumnResized={this.onColumnResized}
              onCellValueChanged={this.onCellValueChanged}
              rowData={this.state.rowData}
              onGridReady={this.onGridReady}
              onGridSizeChanged={this.onGridSizeChanged}
              rowHeight={20}
              context={{
                formData: this.props.context.formData,
                theme: this.props.theme,
                resetAssetClassGroup: this.props.context.resetAssetClassGroup,
                showLoader: this.props.context.showLoader,
                hideLoader: this.props.context.hideLoader,
                onCellValueChanged: this.onCellValueChanged,
              }}
            />
          </div>
        </div>

      );
    }
}

export default connect(state => ({
  literals: state.literals,
  theme: state.theme,
}))(withRouter(AssetClassGroupDetailCellRenderer));

