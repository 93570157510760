import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import { formValueSelector } from 'redux-form';
import _ from 'lodash';
import moment from 'moment-timezone';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import ChartMarketImpactSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import Loader from '../../shared/components/Loader';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
}
const REDUCE_HEIGHT = 200;
class ChartMarketImpact extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      login: PropTypes.instanceOf(Object),
      symbol: PropTypes.instanceOf(Object),
    };
    static defaultProps = {
      login: {},
      symbol: {},
    };
    constructor(props) {
      super(props);
      this.state = {
        rowData: {},
        collapse: '',
        loaderShow: false,
        height: window.innerHeight,
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    handleSubmit=(values) => {
      this.showLoader();
      const dataToSend = this.filtersToSend(values);
      axios.post(`${process.env.REACT_APP_BACKEND_API}/report/market_impact`, JSON.stringify(dataToSend))
        .then((response) => {
          this.setState({ rowData: response.data });
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
      this.setState({ collapse: 'force-close' });
      setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
    };

    filtersToSend = values => ({
      login: values.login.value ? parseInt(values.login.value, 10) : null,
      symbol: values.symbol.value || null,
      criteria: values.criteria.value ? parseInt(values.criteria.value, 10) : null,
      search_date: values.search_date ? moment.tz(values.search_date, 'DD-MM-YYYY', 'UTC').unix() : null,
    });

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    render() {
      const { literals, login, symbol } = this.props;
      const theme = this.props.theme.className;
      let options = {
        chart: {
          zoomType: 'x',
          height: this.state.height - REDUCE_HEIGHT,
          type: 'area',
        },
        credits: { enabled: false },
        exporting: {
          sourceWidth: 1122,
          buttons: {
            contextButton: {
              menuItems:
                ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
            },
          },
        },
        tooltip: {
          formatter() {
            let { x } = this;
            if (this.x < 1) {
              x = `${this.x * 1000}ms`;
            } else if (this.x < 60) {
              x = `${this.x}s`;
            } else {
              const minutes = Math.floor(this.x / 60);
              let seconds = this.x - (minutes * 60);
              seconds = seconds ? ` ${seconds}s` : '';
              x = `${minutes}m${seconds}`;
            }
            return `<b>${x}</b><br/>
<span style="color:${this.point.color}">\u25CF</span>
Yield per $1M: <b>${Math.round(this.y)}</b><br/>`;
          },
        },
        title: {
          text: `${literals.charts.market_impact} (${literals.charts.av_price_diff}:
 <span style="color:orange">${this.state.rowData.price_diff ?
    parseFloat((this.state.rowData.price_diff).toFixed(2)) : 0}</span>, ${literals.charts.percent_of_Adv}:
  <span style="color:orange">${this.state.rowData.adv ?
    parseFloat((this.state.rowData.adv * 100).toFixed(2)) : 0}%</span>, ${literals.charts.no_of_trades}:
  <span style="color:orange">${this.state.rowData.count || 0}</span>, ${literals.tables.mt45report_table.login}:
  <span style="color:orange">${login.label || ''}</span>, ${literals.tables.mt45report_table.symbol}:
  <span style="color:orange">${symbol.label || ''}</span>)`,
        },
        subtitle: {
          text: document.ontouchstart === undefined ?
            literals.charts.click_zoom_in : literals.charts.pinch_zoom_in,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter() {
              if (this.value < 1) {
                return `${this.value * 1000}ms`;
              } else if (this.value < 60) {
                return `${this.value}s`;
              }
              const minutes = Math.floor(this.value / 60);
              let seconds = this.value - (minutes * 60);
              seconds = seconds ? ` ${seconds}s` : '';
              return `${minutes}m${seconds}`;
            },
          },
          categories:
            [0.0, 0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25, 3.5, 3.75, 4.0, 4.25, 4.5,
              4.75, 5.0, 5.25, 5.5, 5.75, 6.0, 6.25, 6.5, 6.75, 7.0, 7.25, 7.5, 7.75, 8.0, 8.25, 8.5, 8.75, 9.0, 9.25,
              9.5, 9.75, 10.0, 12.5, 15.0, 17.5, 20.0, 25.0, 30.0, 35.0, 40.0, 45.0, 50.0, 55.0, 60.0, 75.0, 90.0,
              105.0, 120.0, 135.0, 150.0, 165.0, 180.0, 195.0, 210.0, 225.0, 240.0, 255.0, 270.0, 285.0, 300.0, 315.0,
              330.0, 345.0, 360.0, 375.0, 390.0, 405.0, 420.0, 435.0, 450.0, 465.0, 480.0, 495.0, 510.0, 525.0, 540.0,
              555.0, 570.0, 585.0, 600.0],
        },
        yAxis: {
          tickPositioner: () => {
            if (this.state.rowData.xy) {
              const rowDataParse = JSON.parse(this.state.rowData.xy);
              const max = _.max(rowDataParse);
              const min = _.min(rowDataParse);
              const maxDeviation = Math.ceil(Math.max(Math.abs(max), Math.abs(min)));
              const halfMaxDeviation = Math.ceil(maxDeviation / 2);
              return [-maxDeviation, -halfMaxDeviation, 0,
                halfMaxDeviation, maxDeviation];
            }
            return [0];
          },
          title: {
            text: literals.charts.yield_traded,
          },
          plotLines: [{
            color: 'red',
            width: 5,
            value: 0,
          }],
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: true,
                  radius: 3,
                },
              },
            },
          },
        },
        series: [{
          name: literals.charts.market_impact,
          color: theme === 'theme-dark' ? '#2b908f' : '#7cb5ec',
          data: this.state.rowData.xy ? JSON.parse(this.state.rowData.xy) : [],
        }],
      };
      if (theme === 'theme-dark') {
        options = {
          ...options,
          ...{
            colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
              '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
            chart: {
              ...options.chart,
              backgroundColor: {
                linearGradient: {
                  x1: 0, y1: 0, x2: 1, y2: 1,
                },
                stops: [
                  [0, '#2a2a2b'],
                  [1, '#3e3e40'],
                ],
              },
              plotBorderColor: '#606063',
            },
            title: {
              ...options.title,
              style: {
                color: '#E0E0E3',
                textTransform: 'capitalize',
              },
            },
            subtitle: {
              ...options.subtitle,
              style: {
                color: '#E0E0E3',
                textTransform: 'capitalize',
              },
            },
            xAxis: {
              ...options.xAxis,
              gridLineColor: '#707073',
              labels: {
                ...options.xAxis.labels,
                style: {
                  color: '#E0E0E3',
                },
              },
              lineColor: '#707073',
              minorGridLineColor: '#505053',
              tickColor: '#707073',
              title: {
                style: {
                  color: '#A0A0A3',

                },
              },
            },
            yAxis: {
              ...options.yAxis,
              gridLineColor: '#707073',
              labels: {
                ...options.yAxis.labels,
                style: {
                  color: '#E0E0E3',
                },
              },
              lineColor: '#707073',
              minorGridLineColor: '#505053',
              tickColor: '#707073',
              title: {
                ...options.yAxis.title,
                style: {
                  color: '#A0A0A3',
                },
              },
            },
            tooltip: {
              ...options.tooltip,
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              style: {
                color: '#F0F0F0',
              },
            },
            plotOptions: {
              series: {
                dataLabels: {
                  color: '#F0F0F3',
                },
                marker: {
                  ...options.plotOptions.series.marker,
                  lineColor: '#333',
                },
              },
              boxplot: {
                fillColor: '#505053',
              },
              candlestick: {
                lineColor: 'white',
              },
              errorbar: {
                color: 'white',
              },
            },
            legend: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              itemStyle: {
                color: '#E0E0E3',
              },
              itemHoverStyle: {
                color: '#FFF',
              },
              itemHiddenStyle: {
                color: '#606063',
              },
              title: {
                style: {
                  color: '#C0C0C0',
                },
              },
            },
            credits: {
              ...options.credits,
              style: {
                color: '#666',
              },
            },
            labels: {
              style: {
                color: '#707073',
              },
            },
            drilldown: {
              activeAxisLabelStyle: {
                color: '#F0F0F3',
              },
              activeDataLabelStyle: {
                color: '#F0F0F3',
              },
            },
            navigation: {
              buttonOptions: {
                symbolStroke: '#DDDDDD',
                theme: {
                  fill: '#505053',
                },
              },
            },
            // scroll charts
            rangeSelector: {
              buttonTheme: {
                fill: '#505053',
                stroke: '#000000',
                style: {
                  color: '#CCC',
                },
                states: {
                  hover: {
                    fill: '#707073',
                    stroke: '#000000',
                    style: {
                      color: 'white',
                    },
                  },
                  select: {
                    fill: '#000003',
                    stroke: '#000000',
                    style: {
                      color: 'white',
                    },
                  },
                },
              },
              inputBoxBorderColor: '#505053',
              inputStyle: {
                backgroundColor: '#333',
                color: 'silver',
              },
              labelStyle: {
                color: 'silver',
              },
            },
            navigator: {
              handles: {
                backgroundColor: '#666',
                borderColor: '#AAA',
              },
              outlineColor: '#CCC',
              maskFill: 'rgba(255,255,255,0.1)',
              series: {
                color: '#7798BF',
                lineColor: '#A6C7ED',
              },
              xAxis: {
                gridLineColor: '#505053',
              },
            },
            scrollbar: {
              barBackgroundColor: '#808083',
              barBorderColor: '#808083',
              buttonArrowColor: '#CCC',
              buttonBackgroundColor: '#606063',
              buttonBorderColor: '#606063',
              rifleColor: '#FFF',
              trackBackgroundColor: '#404043',
              trackBorderColor: '#404043',
            },
          },
        };
      } else {
        options = {
          ...options,
          ...{
            colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9',
              '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
            chart: {
              ...options.chart,
              backgroundColor: '#ffffff',
              plotBorderColor: '#cccccc',
            },
            title: {
              ...options.title,
              style: {
                color: '#333333',
                textTransform: 'capitalize',
              },
            },
            subtitle: {
              ...options.subtitle,
              style: {
                color: '#666666',
                textTransform: 'capitalize',
              },
            },
            xAxis: {
              ...options.xAxis,
              gridLineColor: '#e6e6e6',
              labels: {
                style: {
                  color: '#666666',
                },
              },
              lineColor: '#e6e6e6',
              minorGridLineColor: '#e6e6e6',
              tickColor: '#e6e6e6',
              title: {
                style: {
                  color: '#666666',

                },
              },
            },
            yAxis: {
              ...options.yAxis,
              gridLineColor: '#e6e6e6',
              labels: {
                ...options.yAxis.labels,
                style: {
                  color: '#666666',
                },
              },
              lineColor: '#e6e6e6',
              minorGridLineColor: '#e6e6e6',
              tickColor: '#e6e6e6',
              title: {
                ...options.yAxis.title,
                style: {
                  color: '#666666',
                },
              },
            },
            tooltip: {
              ...options.tooltip,
              backgroundColor: 'rgba(247,247,247,0.85)',
              style: {
                color: '#333333',
              },
            },
            plotOptions: {
              series: {
                dataLabels: {
                  color: '#333333',
                },
                marker: {
                  ...options.plotOptions.series.marker,
                  lineColor: '#F0F0F0',
                },
              },
              boxplot: {
                fillColor: '#e6e6e6',
              },
              candlestick: {
                lineColor: 'black',
              },
              errorbar: {
                color: 'black',
              },
            },
            legend: {
              backgroundColor: 'transparent',
              itemStyle: {
                color: '#333333',
              },
              itemHoverStyle: {
                color: '#000000',
              },
              itemHiddenStyle: {
                color: '#cccccc',
              },
              title: {
                style: {
                  color: '#333333',
                },
              },
            },
            credits: {
              ...options.credits,
              style: {
                color: '#999999',
              },
            },
            labels: {
              style: {
                color: '#333333',
              },
            },
            drilldown: {
              activeAxisLabelStyle: {
                color: '#333333',
              },
              activeDataLabelStyle: {
                color: '#333333',
              },
            },
            navigation: {
              buttonOptions: {
                symbolStroke: '#333333',
                theme: {
                  fill: '#e6e6e6',
                },
              },
            },
            // scroll charts
            rangeSelector: {
              buttonTheme: {
                fill: '#e6e6e6',
                stroke: '#ffffff',
                style: {
                  color: '#333333',
                },
                states: {
                  hover: {
                    fill: '#333333',
                    stroke: '#ffffff',
                    style: {
                      color: 'black',
                    },
                  },
                  select: {
                    fill: '#ffffff',
                    stroke: '#ffffff',
                    style: {
                      color: 'black',
                    },
                  },
                },
              },
              inputBoxBorderColor: '#e6e6e6',
              inputStyle: {
                backgroundColor: '#F0F0F0',
                color: 'white',
              },
              labelStyle: {
                color: 'white',
              },
            },
            navigator: {
              handles: {
                backgroundColor: '#999999',
                borderColor: '#333333',
              },
              outlineColor: '#333333',
              maskFill: 'rgba(0.1,255,255,255)',
              series: {
                color: '#7798BF',
                lineColor: '#A6C7ED',
              },
              xAxis: {
                gridLineColor: '#e6e6e6',
              },
            },
            scrollbar: {
              barBackgroundColor: '#cccccc',
              barBorderColor: '#cccccc',
              buttonArrowColor: '#333333',
              buttonBackgroundColor: '#cccccc',
              buttonBorderColor: '#cccccc',
              rifleColor: '#000',
              trackBackgroundColor: '#cccccc',
              trackBorderColor: '#cccccc',
            },
          },
        };
      }
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.chart_market_impact}
                </h5>
              </div>
              <Collapse
                title={literals.tables.ag_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <ChartMarketImpactSearchForm
                  onSubmit={this.handleSubmit}
                  showLoader={this.showLoader}
                  hideLoader={this.hideLoader}
                />
              </Collapse>
              <HighchartsReact highcharts={Highcharts} options={options} />
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

const selector = formValueSelector('chart_market_impact_search_form');
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  login: selector(state, 'login'),
  symbol: selector(state, 'symbol'),
}))(ChartMarketImpact);
