import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error }, divClassName,
}) => (
  <div className={`form__form-group-input-wrap ${divClassName}`}>
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);
class CommandsForm extends PureComponent {
  static propTypes = {
    commandClick: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div>
        <form className="form commands-form">
          <Table responsive className="table-borderless">
            <tbody>
              <tr>
                <td>
                  <div className="label-text">
                  ping
                  </div>
                </td>
                <td>
                  <Field
                    name="ping_address"
                    component={renderField}
                    type="text"
                    placeholder="ping address"
                  />
                </td>
                <td />
                <td />
                <td>
                  <Button
                    color="primary"
                    type="button"
                    className="command-button"
                    onClick={() => this.props.commandClick('ping')}
                  >ping send
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="label-text">
                  traceroute
                  </div>
                </td>
                <td>
                  <Field
                    name="traceroute_address"
                    component={renderField}
                    type="text"
                    placeholder="traceroute address"
                  />
                </td>
                <td />
                <td />
                <td>
                  <Button
                    color="primary"
                    type="button"
                    className="command-button"
                    onClick={() => this.props.commandClick('traceroute')}
                  >traceroute send
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="label-text">
                  telnet
                  </div>
                </td>
                <td>
                  <Field
                    name="telnet_ip"
                    component={renderField}
                    type="text"
                    placeholder="telnet ip"
                  />
                </td>
                <td>
                  <div className="label-text">
                  :
                  </div>
                </td>
                <td>
                  <Field
                    name="telnet_port"
                    component={renderField}
                    type="text"
                    placeholder="telnet port"
                    divClassName="small-form__form-group-input-wrap"
                  />
                </td>
                <td>
                  <Button
                    color="primary"
                    type="button"
                    className="command-button"
                    onClick={() => this.props.commandClick('telnet')}
                  >telnet send
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="label-text">
                  openssl s_client -showcerts -connect
                  </div>
                </td>
                <td>
                  <Field
                    name="openssl_ip"
                    component={renderField}
                    type="text"
                    placeholder="openssl ip"
                  />
                </td>
                <td>
                  <div className="label-text">
                  :
                  </div>
                </td>
                <td>
                  <Field
                    name="openssl_port"
                    component={renderField}
                    type="text"
                    placeholder="openssl port"
                    divClassName="small-form__form-group-input-wrap"
                  />
                </td>
                <td>
                  <Button
                    color="primary"
                    type="button"
                    className="command-button"
                    onClick={() => this.props.commandClick('openssl')}
                  >openssl send
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </form>
      </div>
    );
  }
}

export default connect()(reduxForm({
  form: 'commands_form', // a unique identifier for this form
  destroyOnUnmount: false,
})(CommandsForm));

