/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import _ from 'lodash';
import numeral from 'numeral';
import Websocket from 'react-websocket';
import { getFormValues } from 'redux-form';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import ChartBarLimitSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import Loader from '../../shared/components/Loader';
import { numberFormatter } from '../../shared/helper';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
}
const yellow = '#cccc00';
const green = '#00bb00';
const red = 'red';
@inject('rootStore')
class ChartBarLimit extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
    search_values: PropTypes.instanceOf(Object),
  };
  static defaultProps = {
    search_values: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      collapse: '',
      loaderShow: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.loadData();
    }, 0);
  }

  loadData=() => {
    this.showLoader();
    let riskAccount = '';
    if (this.props.search_values.risk_account) {
      riskAccount = this.props.search_values.risk_account.value;
    }
    let assetClass = '';
    if (this.props.search_values.asset_class) {
      assetClass = this.props.search_values.asset_class.value;
    }
    if (this.refWebSocket.state.ws.readyState === 1) {
      this.socketOpenListener();
    }
    const queryParams = `risk_account=${riskAccount}&asset_class=${assetClass}`;
    axios.get(`${process.env.REACT_APP_BACKEND_API}/report/bar_limit?${queryParams}`)
      .then((response) => {
        this.setState({ rowData: response.data });
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  handleSubmit=() => {
    this.loadData();
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    if ('market_exposure' in data) {
      this.setState({ rowData: data.market_exposure });
    }
  };
  socketOpenListener = () => {
    let riskAccount = '';
    if (this.props.search_values.risk_account) {
      riskAccount = this.props.search_values.risk_account.value;
    }
    let assetClass = '';
    if (this.props.search_values.asset_class) {
      assetClass = this.props.search_values.asset_class.value;
    }
    this.sendMessage(JSON.stringify({
      key: 'market_exposure',
      value: JSON.stringify({ risk_account: riskAccount, asset_class: assetClass }),
    }));
  };
  sendMessage=(message) => {
    this.refWebSocket.sendMessage(message);
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    let options = {
      chart: {
        type: 'bar',
        className: 'bar-limit-chart',
      },
      credits: { enabled: false },
      exporting: {
        sourceWidth: 1122,
        allowHTML: true,
        buttons: {
          contextButton: {
            menuItems:
              ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
          },
        },
      },
      tooltip: {
        useHTML: true,
        padding: 0,
        formatter() {
          return `<div class="highchart-custom-tooltip"><b>${this.x.symbol}</b><br/>
<span style="color:${this.point.color}">\u25CF</span>
${literals.charts.usd_exposure}: <b>${numberFormatter(parseFloat(this.y.toFixed(2)))}</b></div>`;
        },
      },
      title: {
        text: literals.sidebar.chart_bar_limit,
      },
      xAxis: [{
        lineWidth: 0,
        tickLength: 0,
        left: '50%',
        labels: {
          align: 'center',
          useHTML: true,
          step: 1,
          reserveSpace: false,
          x: -5,
          formatter() {
            if (this.isFirst) {
              let color = '';
              if (this.value.security !== 'ALL') {
                if (Math.abs(this.value.sum_usd_exposure) >= (this.value.security_exposure_limit * 90) / 100) {
                  color = red;
                } else if (Math.abs(this.value.sum_usd_exposure) > (this.value.security_exposure_limit * 50) / 100
                  && Math.abs(this.value.sum_usd_exposure) < (this.value.security_exposure_limit * 90) / 100) {
                  color = yellow;
                } else if (Math.abs(this.value.sum_usd_exposure) <= (this.value.security_exposure_limit * 50) / 100) {
                  color = green;
                }
              } else {
                color = 'transparent';
              }
              return `
<table class="highcharts-table">
<tr>
<td class='width-25 align-center' style='color: white; background-color: ${color}'>${this.value.security}</td>
</tr>
</table>`;
            } else if (this.pos === 1) {
              return `
<table class="highcharts-table">
<tr>
<td class='width-25'>${this.value.symbol}</td>
<td class='width-25'>${literals.charts.underlying_exposure}</td>
<td class='width-25'>${literals.charts.usd_exposure}</td>
<td class='width-25'>${literals.charts.exposure_limit}</td>
</tr>
</table>`;
            } else if (this.isLast) {
              return `
<table class="highcharts-table">
<tr>
<td class='width-25'>Total</td>
<td class='width-25'> </td>
<td class='width-25 align-right' style='color: ${this.value.sum_usd_exposure < 0 ? red : green}'>
${this.value.sum_usd_exposure < 0 ? '(' : ''}\
${numberFormatter(parseFloat(Math.abs(this.value.sum_usd_exposure).toFixed(2)))}\
${this.value.sum_usd_exposure < 0 ? ')' : ''}\
</td>
<td class='width-25'> </td>
</tr>
</table>`;
            }
            return `
<table class="highcharts-table">
<tr>
<td class='width-25'>${this.value.symbol}</td>
<td class='width-25 align-right' style='color: ${this.value.exposure < 0 ? red : green}'>
${this.value.exposure < 0 ? '(' : ''}\
${numberFormatter(parseFloat(Math.abs(this.value.exposure).toFixed(2)))}\
${this.value.exposure < 0 ? ')' : ''}\
</td>
<td class='width-25 align-right' style='color: ${this.value.usd < 0 ? red : green}'>
${this.value.usd < 0 ? '(' : ''}\
${numberFormatter(parseFloat(Math.abs(this.value.usd).toFixed(2)))}\
${this.value.usd < 0 ? ')' : ''}
</td>
<td class='width-25 align-right'>${numberFormatter(parseFloat(this.value.exposure_limit.toFixed(2)))}</td>
</tr>
</table>`;
          },
        },
      }],
      yAxis: [{
        title: {
          text: null,
        },
        labels: {
          formatter() {
            return numeral(this.value).format('0a').toUpperCase();
          },
        },
        left: 0,
        width: '35%',
        offset: 0,
        gridLineWidth: 0,
        plotLines: [{
          value: 0,
          color: '#E0E0E3',
        }],
      }, {
        title: {
          text: null,
        },
        labels: {
          formatter() {
            return numeral(this.value).format('0.0a').toUpperCase();
          },
          style: {
            color: 'white',
          },
        },
        left: '65%',
        width: '35%',
        offset: 0,
        gridLineWidth: 0,
        plotLines: [{
          value: 0,
          color: '#E0E0E3',
        }],
      }],
      series: [{
        showInLegend: false,
        data: [],
      }, {
        showInLegend: false,
        yAxis: 1,
        data: [],
      }],
    };
    if (theme === 'theme-dark') {
      options = {
        ...options,
        ...{
          colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
            '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
          chart: {
            ...options.chart,
            backgroundColor: {
              linearGradient: {
                x1: 0, y1: 0, x2: 1, y2: 1,
              },
              stops: [
                [0, '#2a2a2b'],
                [1, '#3e3e40'],
              ],
            },
            plotBorderColor: '#606063',
          },
          title: {
            ...options.title,
            style: {
              color: '#E0E0E3',
              textTransform: 'capitalize',
            },
          },
          subtitle: {
            ...options.subtitle,
            style: {
              color: '#E0E0E3',
              textTransform: 'capitalize',
            },
          },
          xAxis: [{
            ...options.xAxis[0],
            gridLineColor: '#707073',
            labels: {
              ...options.xAxis[0].labels,
              style: {
                color: '#E0E0E3',
              },
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            title: {
              style: {
                color: '#A0A0A3',

              },
            },
          }],
          yAxis: [{
            ...options.yAxis[0],
            gridLineColor: '#E0E0E3',
            labels: {
              ...options.yAxis[0].labels,
              style: {
                color: '#E0E0E3',
              },
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            title: {
              ...options.yAxis[0].title,
              style: {
                color: '#A0A0A3',
              },
            },
          }, {
            ...options.yAxis[1],
            gridLineColor: '#E0E0E3',
            labels: {
              ...options.yAxis[0].labels,
              style: {
                color: '#E0E0E3',
              },
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            title: {
              ...options.yAxis[1].title,
              style: {
                color: '#A0A0A3',
              },
            },
          }],
          tooltip: {
            ...options.tooltip,
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            style: {
              color: '#F0F0F0',
            },
          },
          plotOptions: {
            series: {
              maxPointWidth: 20,
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              marker: {
                lineColor: '#333',
              },
            },
            boxplot: {
              fillColor: '#505053',
            },
            candlestick: {
              lineColor: 'white',
            },
            errorbar: {
              color: 'white',
            },
          },
          legend: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            itemStyle: {
              color: '#E0E0E3',
            },
            itemHoverStyle: {
              color: '#FFF',
            },
            itemHiddenStyle: {
              color: '#606063',
            },
            title: {
              style: {
                color: '#C0C0C0',
              },
            },
          },
          credits: {
            ...options.credits,
            style: {
              color: '#666',
            },
          },
          labels: {
            style: {
              color: '#707073',
            },
          },
          drilldown: {
            activeAxisLabelStyle: {
              color: '#F0F0F3',
            },
            activeDataLabelStyle: {
              color: '#F0F0F3',
            },
          },
          navigation: {
            buttonOptions: {
              symbolStroke: '#DDDDDD',
              theme: {
                fill: '#505053',
              },
            },
          },
          // scroll charts
          rangeSelector: {
            buttonTheme: {
              fill: '#505053',
              stroke: '#000000',
              style: {
                color: '#CCC',
              },
              states: {
                hover: {
                  fill: '#707073',
                  stroke: '#000000',
                  style: {
                    color: 'white',
                  },
                },
                select: {
                  fill: '#000003',
                  stroke: '#000000',
                  style: {
                    color: 'white',
                  },
                },
              },
            },
            inputBoxBorderColor: '#505053',
            inputStyle: {
              backgroundColor: '#333',
              color: 'silver',
            },
            labelStyle: {
              color: 'silver',
            },
          },
          navigator: {
            handles: {
              backgroundColor: '#666',
              borderColor: '#AAA',
            },
            outlineColor: '#CCC',
            maskFill: 'rgba(255,255,255,0.1)',
            series: {
              color: '#7798BF',
              lineColor: '#A6C7ED',
            },
            xAxis: {
              gridLineColor: '#505053',
            },
          },
          scrollbar: {
            barBackgroundColor: '#808083',
            barBorderColor: '#808083',
            buttonArrowColor: '#CCC',
            buttonBackgroundColor: '#606063',
            buttonBorderColor: '#606063',
            rifleColor: '#FFF',
            trackBackgroundColor: '#404043',
            trackBorderColor: '#404043',
          },
        },
      };
    } else {
      options = {
        ...options,
        ...{
          colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9',
            '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
          chart: {
            ...options.chart,
            backgroundColor: '#ffffff',
            plotBorderColor: '#cccccc',
          },
          title: {
            ...options.title,
            style: {
              color: '#333333',
              textTransform: 'capitalize',
            },
          },
          subtitle: {
            ...options.subtitle,
            style: {
              color: '#666666',
              textTransform: 'capitalize',
            },
          },
          xAxis: [{
            ...options.xAxis[0],
            gridLineColor: '#e6e6e6',
            labels: {
              ...options.xAxis[0].labels,
              style: {
                color: '#666666',
              },
            },
            lineColor: '#e6e6e6',
            minorGridLineColor: '#e6e6e6',
            tickColor: '#e6e6e6',
            title: {
              style: {
                color: '#666666',

              },
            },
          }],
          yAxis: [{
            ...options.yAxis[0],
            gridLineColor: '#e6e6e6',
            labels: {
              ...options.yAxis[0].labels,
              style: {
                color: '#666666',
              },
            },
            lineColor: '#e6e6e6',
            minorGridLineColor: '#e6e6e6',
            tickColor: '#e6e6e6',
            title: {
              ...options.yAxis[0].title,
              style: {
                color: '#666666',
              },
            },
          }, {
            ...options.yAxis[1],
            gridLineColor: '#e6e6e6',
            labels: {
              ...options.yAxis[0].labels,
              style: {
                color: '#666666',
              },
            },
            lineColor: '#e6e6e6',
            minorGridLineColor: '#e6e6e6',
            tickColor: '#e6e6e6',
            title: {
              ...options.yAxis[1].title,
              style: {
                color: '#666666',
              },
            },
          }],
          tooltip: {
            ...options.tooltip,
            backgroundColor: 'rgba(247,247,247,0.85)',
            style: {
              color: '#333333',
            },
          },
          plotOptions: {
            series: {
              maxPointWidth: 30,
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              marker: {
                lineColor: '#F0F0F0',
              },
            },
            boxplot: {
              fillColor: '#e6e6e6',
            },
            candlestick: {
              lineColor: 'black',
            },
            errorbar: {
              color: 'black',
            },
          },
          legend: {
            backgroundColor: 'transparent',
            itemStyle: {
              color: '#333333',
            },
            itemHoverStyle: {
              color: '#000000',
            },
            itemHiddenStyle: {
              color: '#cccccc',
            },
            title: {
              style: {
                color: '#333333',
              },
            },
          },
          credits: {
            ...options.credits,
            style: {
              color: '#999999',
            },
          },
          labels: {
            style: {
              color: '#333333',
            },
          },
          drilldown: {
            activeAxisLabelStyle: {
              color: '#333333',
            },
            activeDataLabelStyle: {
              color: '#333333',
            },
          },
          navigation: {
            buttonOptions: {
              symbolStroke: '#333333',
              theme: {
                fill: '#e6e6e6',
              },
            },
          },
          // scroll charts
          rangeSelector: {
            buttonTheme: {
              fill: '#e6e6e6',
              stroke: '#ffffff',
              style: {
                color: '#333333',
              },
              states: {
                hover: {
                  fill: '#333333',
                  stroke: '#ffffff',
                  style: {
                    color: 'black',
                  },
                },
                select: {
                  fill: '#ffffff',
                  stroke: '#ffffff',
                  style: {
                    color: 'black',
                  },
                },
              },
            },
            inputBoxBorderColor: '#e6e6e6',
            inputStyle: {
              backgroundColor: '#F0F0F0',
              color: 'white',
            },
            labelStyle: {
              color: 'white',
            },
          },
          navigator: {
            handles: {
              backgroundColor: '#999999',
              borderColor: '#333333',
            },
            outlineColor: '#333333',
            maskFill: 'rgba(0.1,255,255,255)',
            series: {
              color: '#7798BF',
              lineColor: '#A6C7ED',
            },
            xAxis: {
              gridLineColor: '#e6e6e6',
            },
          },
          scrollbar: {
            barBackgroundColor: '#cccccc',
            barBorderColor: '#cccccc',
            buttonArrowColor: '#333333',
            buttonBackgroundColor: '#cccccc',
            buttonBorderColor: '#cccccc',
            rifleColor: '#000',
            trackBackgroundColor: '#cccccc',
            trackBorderColor: '#cccccc',
          },
        },
      };
    }
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title card__title_custom">
              <h5 className="bold-text">{literals.sidebar.chart_bar_limit}
              </h5>
            </div>
            <Collapse
              title={literals.tables.ag_table.toggle_search}
              className="with-shadow"
              collapse={this.state.collapse}
              maxDeviation
            >
              <ChartBarLimitSearchForm onSubmit={this.handleSubmit} />
            </Collapse>
            { this.state.rowData.map((security) => {
              const op = _.cloneDeep(options);
              op.title.text = null;
              const sumUSDExposure = _.sumBy(security.bar_limits, 'usd');
              const ff = {
                exposure: 0,
                security: security.security,
                symbol: 'Market',
                usd: 0,
                y: 0,
                sum_usd_exposure: sumUSDExposure,
                security_exposure_limit: security.exposure_limit,
              };
              if (security.bar_limits[0].symbol !== 'Market' || security.bar_limits[1].symbol !== 'Market') {
                security.bar_limits.unshift(ff, ff);
              }
              if (security.bar_limits[security.bar_limits.length - 1].symbol !== 'Market') {
                security.bar_limits.push(ff);
              }
              op.xAxis[0].categories = security.bar_limits;
              const max = _.maxBy(security.bar_limits, 'y').y;
              const min = _.minBy(security.bar_limits, 'y').y;
              op.yAxis[0].tickPositioner = () => {
                let maxDeviation = Math.ceil(Math.max(Math.abs(max), Math.abs(min)));
                const roundedUpto = _.padEnd('1', maxDeviation.toString().length, '0');
                const maxDeviationRounded = Math.ceil(maxDeviation / roundedUpto) * roundedUpto;
                maxDeviation = Math.ceil(maxDeviation / maxDeviationRounded) * maxDeviationRounded;
                const halfMaxDeviation = Math.ceil(maxDeviation / 2);
                return [-maxDeviation, -halfMaxDeviation, 0];
              };
              op.yAxis[1].tickPositioner = () => {
                let maxDeviation = Math.ceil(Math.max(Math.abs(max), Math.abs(min)));
                const roundedUpto = _.padEnd('1', maxDeviation.toString().length, '0');
                const maxDeviationRounded = Math.ceil(maxDeviation / roundedUpto) * roundedUpto;
                maxDeviation = Math.ceil(maxDeviation / maxDeviationRounded) * maxDeviationRounded;
                const halfMaxDeviation = Math.ceil(maxDeviation / 2);
                return [0, halfMaxDeviation, maxDeviation];
              };
              op.series[0].data = _.map(security.bar_limits, (item) => {
                const res = JSON.parse(JSON.stringify(item));
                if (item.y > 0) {
                  res.y = 0;
                } else if (Math.abs(res.usd) >= (res.exposure_limit * 90) / 100) {
                  res.color = red;
                  res.borderColor = red;
                } else if (Math.abs(res.usd) > (res.exposure_limit * 50) / 100
                  && Math.abs(res.usd) < (res.exposure_limit * 90) / 100) {
                  res.color = yellow;
                  res.borderColor = yellow;
                } else if (Math.abs(res.usd) <= (res.exposure_limit * 50) / 100) {
                  res.color = green;
                  res.borderColor = green;
                }
                return res;
              });
              op.series[1].data = _.map(security.bar_limits, (item) => {
                const res = JSON.parse(JSON.stringify(item));
                if (item.y < 0) {
                  res.y = 0;
                } else if (Math.abs(res.usd) >= (res.exposure_limit * 90) / 100) {
                  res.color = red;
                  res.borderColor = red;
                } else if (Math.abs(res.usd) > (res.exposure_limit * 50) / 100
                  && Math.abs(res.usd) < (res.exposure_limit * 90) / 100) {
                  res.color = yellow;
                  res.borderColor = yellow;
                } else if (Math.abs(res.usd) <= (res.exposure_limit * 50) / 100) {
                  res.color = green;
                  res.borderColor = green;
                }
                return res;
              });
              op.chart.height = (security.bar_limits.length + 3) * 30;
              return <HighchartsReact key={security.security} highcharts={Highcharts} options={op} />;
            })
            }
            <Loader display={this.state.loaderShow} />
            <Websocket
              url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
              reconnectIntervalInMilliSeconds={1000}
              onMessage={this.socketMessageListener}
              onOpen={this.socketOpenListener}
              ref={(WS) => {
                this.refWebSocket = WS;
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  search_values: getFormValues('chart_bar_limit_search_form')(state),
}))(ChartBarLimit);
