/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import moment from 'moment';
import { DateObject } from 'react-multi-date-picker';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderMultiDatePicker from '../../shared/components/form/MultiDatePicker';

class SessionEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
  };

  componentDidMount() {
    const { value } = this.props;
    const dates = [];
    value.split(',').forEach((item) => {
      dates.push(new DateObject(moment(item, 'YYYYMMDD').format('YYYY/MM/DD')));
    });
    this.props.change('date_calendar', dates);
  }

  render() {
    const { literals, theme } = this.props;
    return (
      <div>
        <form className="form form--vertical" onSubmit={this.props.handleSubmit}>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.action_scheduler_form.date_calendar}
            </span>
            <div className="form__form-group-field">
              <Field
                name="date_calendar"
                component={renderMultiDatePicker}
                theme={theme.className}
                multiple
              />
            </div>
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" type="submit">{literals.forms.submit}</Button>
              <Button color="primary" onClick={this.props.toggleModalSession}>
                {literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(reduxForm({
  form: 'action_scheduler_date_calendar_form', // a unique identifier for this form
})(SessionEdit));
