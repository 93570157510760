/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import MultiEditorForm from './MultiEditorForm';

class SessionRenderer extends PureComponent {
  static propTypes = {
    context: PropTypes.instanceOf(Object).isRequired,
    value: PropTypes.string.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    colDef: PropTypes.instanceOf(Object).isRequired
  };
  state = {
    sessionModalShow: false
  };
  parseMultiPattern = (values, pattern, text, tree, securitySymbol) => {
    let result = '*,';
    if (values[pattern] && values[text]) {
      result = values[text].trim() || '*,';
    } else if (!values[pattern] && values[tree]) {
      if (this.props.colDef.field === 'symbols') {
        if (values[tree].length > 0) {
          const symbols = [];
          if (!Array.isArray(values[tree])) {
            values[tree] = [values[tree]];
          }
          values[tree].forEach((item) => {
            if (item in securitySymbol) {
              symbols.push(...securitySymbol[item]);
            } else {
              symbols.push(item);
            }
          });
          result = symbols.join(',');
        } else {
          result = '*,';
        }
      } else {
        result = values[tree].length > 0 ? values[tree].join(',') : '*,';
      }
    }
    return result;
  };
  toggleModalSession = () => {
    this.setState({ sessionModalShow: !this.state.sessionModalShow });
  };
  showModalSession = () => {
    if (!this.state.sessionModalShow) {
      this.setState({ sessionModalShow: true });
    }
  };
  handleSubmit = (values) => {
    const data = this.parseMultiPattern(
      values,
      'pattern',
      'text',
      'tree',
      this.props.context.formData.SecuritySymbol
    );
    const rowNode = this.props.node;
    rowNode.setDataValue(this.props.colDef.field, data);
    this.toggleModalSession();
  };
  render() {
    const theme = this.props.context.theme.className;
    return (
      <div onDoubleClick={this.showModalSession}>
        <label>{this.props.value}</label>
        <Modal
          isOpen={this.state.sessionModalShow}
          toggle={() => this.toggleModalSession}
          className={`modal-dialog--primary modal-dialog--header crp-modal ${theme}`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              onClick={() => this.toggleModalSession()}
            />
            <h4 className="bold-text  modal__title">
              {this.props.colDef.headerName}
            </h4>
          </div>
          <div className="modal__body risk_account_modal_body">
            <MultiEditorForm
              {...this.props}
              onSubmit={this.handleSubmit}
              toggleModalSession={this.toggleModalSession}
            />
          </div>
          <div className="modal__footer" />
        </Modal>
      </div>
    );
  }
}
export default SessionRenderer;
