/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { connect } from 'react-redux';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import $ from 'jquery';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';

class AccountBalanceView extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    activeTab: PropTypes.string.isRequired,
    accountBalanceWatch: PropTypes.instanceOf(Array),
  };
  static defaultProps = {
    accountBalanceWatch: [],
  }
  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_trading_platform_account_balance';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.account_balance_table.balance,
          field: 'balance',
          headerTooltip: literals.tables.account_balance_table.balance,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.available_withdraw,
          field: 'available_withdraw',
          headerTooltip: literals.tables.account_balance_table.available_withdraw,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.equity,
          field: 'equity',
          headerTooltip: literals.tables.account_balance_table.equity,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.pl,
          field: 'pl',
          headerTooltip: literals.tables.account_balance_table.pl,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
        },
        {
          headerName: literals.tables.account_balance_table.margin,
          field: 'margin',
          headerTooltip: literals.tables.account_balance_table.margin,
          pinned: 'left',
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.margin_level,
          field: 'margin_level',
          headerTooltip: literals.tables.account_balance_table.margin_level,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.commission,
          field: 'commission',
          headerTooltip: literals.tables.account_balance_table.commission,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.swaps,
          field: 'swaps',
          headerTooltip: literals.tables.account_balance_table.swaps,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.notional,
          field: 'notional',
          headerTooltip: literals.tables.account_balance_table.notional,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.credit,
          field: 'credit',
          headerTooltip: literals.tables.account_balance_table.credit,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.account_balance_table.trading_state,
          field: 'trading_state_value',
          headerTooltip: literals.tables.account_balance_table.trading_state,
          width: 60,
          minWidth: 60,
          cellRenderer(params) {
            if (params.value === 'REACHED NOP') {
              return `<span class="cell-renderer-blue">${params.value}</span>`;
            } else if (params.value === 'STOPPED OUT') {
              return `<span class="cell-renderer-red">${params.value}</span>`;
            } else if (params.value === 'MARGIN CALL') {
              return `<span class="cell-renderer-brown">${params.value}</span>`;
            }
            return `<span style='font:inherit'>${params.value}</span>`;
          },
        },
      ],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      getRowNodeId(data) {
        return data.name;
      },
    };
    this.onGridReadyAccountBalanceView = this.onGridReadyAccountBalanceView.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApiAccountBalance) {
      const { literals } = this.props;
      if (literals.tables.account_balance_table.balance !== prevProps.literals.tables.account_balance_table.balance) {
        this.gridColumnApiAccountBalance.getColumn('balance').getColDef().headerName
          = literals.tables.account_balance_table.balance;
        this.gridColumnApiAccountBalance.getColumn('available_withdraw')
          .getColDef().headerName
          = literals.tables.account_balance_table.available_withdraw;
        this.gridColumnApiAccountBalance.getColumn('equity')
          .getColDef().headerName
          = literals.tables.account_balance_table.equity;
        this.gridColumnApiAccountBalance.getColumn('pl')
          .getColDef().headerName
          = literals.tables.account_balance_table.pl;
        this.gridColumnApiAccountBalance.getColumn('margin')
          .getColDef().headerName
          = literals.tables.account_balance_table.margin;
        this.gridColumnApiAccountBalance.getColumn('margin_level')
          .getColDef().headerName
          = literals.tables.account_balance_table.margin_level;
        this.gridColumnApiAccountBalance.getColumn('commission')
          .getColDef().headerName
          = literals.tables.account_balance_table.commission;
        this.gridColumnApiAccountBalance.getColumn('swaps')
          .getColDef().headerName
          = literals.tables.account_balance_table.swaps;
        this.gridColumnApiAccountBalance.getColumn('notional')
          .getColDef().headerName
          = literals.tables.account_balance_table.notional;
        this.gridColumnApiAccountBalance.getColumn('credit')
          .getColDef().headerName
          = literals.tables.account_balance_table.credit;
        this.gridColumnApiAccountBalance.getColumn('trading_state_value')
          .getColDef().headerName
          = literals.tables.account_balance_table.trading_state;
        this.gridApiAccountBalance.refreshHeader();
      }
    }
  }
  onGridReadyAccountBalanceView(params) {
    this.gridApiAccountBalance = params.api;
    this.gridColumnApiAccountBalance = params.columnApi;
    this.onGridSizeChangedAccountBalanceView(params);
  }

  onGridSizeChangedAccountBalanceView=(params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };
  onColumnResizedAccountBalanceView=(params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };
  render() {
    const theme = this.props.theme.className;
    const accountBalanceWatchData = this.props.accountBalanceWatch ?
      this.props.accountBalanceWatch : [];
    let accountBalanceData = [];
    if (this.props.activeTab === '1') {
      accountBalanceData = accountBalanceWatchData;
    } else {
      accountBalanceData = [];
    }
    const agHeader = $(`#${this.myGridID} .ag-header`);
    let agHeaderHeight = 56;
    if (agHeader) {
      agHeaderHeight = agHeader.height();
    }
    const height = Math.min(((accountBalanceData.length + 1) * 28) + agHeaderHeight, 200);
    return (
      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%' }}>
        <div
          id={this.myGridID}
          style={{
            boxSizing: 'border-box',
            height,
            width: '100%',
          }}
          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            immutableData
            suppressCellSelection
            suppressContextMenu
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResizedAccountBalanceView}
            rowData={accountBalanceData}
            getRowNodeId={this.state.getRowNodeId}
            onGridReady={this.onGridReadyAccountBalanceView}
            onGridSizeChanged={this.onGridSizeChangedAccountBalanceView}
          />
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(AccountBalanceView);
