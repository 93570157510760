/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import {
  LiteralENGlobalProps,
  LiteralProps
} from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import {
  regExSignedDecimalNumber,
  shallowEqual,
  validateSessions,
  parseSessions
} from '../../shared/helper';
import UploadData from '../../shared/components/UploadData';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};
class NodeFeedSymbolUpload extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
    resetNodeFeed: PropTypes.func.isRequired
  };

  getHeaderName = (header) => {
    const { literalsENGlobal } = this.props;
    switch (header) {
      case literalsENGlobal.forms.node_feed_symbol_form.symbol:
        return { col: 'symbol_val', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.node:
        return { col: 'node', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.node_feed:
        return { col: 'node_feed', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.party_symbol:
        return { col: 'party_symbol', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.aggregate_group:
        return { col: 'aggregate_group', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.markup_group:
        return { col: 'markup_group', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.feed_mode:
        return { col: 'feed_mode_value', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.levels:
        return { col: 'levels', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.min_volume:
        return { col: 'min_volume', type: 'number' };
      case literalsENGlobal.forms.node_feed_symbol_form.depth:
        return { col: 'depth', type: 'number' };
      case literalsENGlobal.forms.node_feed_symbol_form.feed_source:
        return { col: 'feed_source_value', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.feed_tob_volume:
        return { col: 'feed_tob_volume', type: 'number' };
      case literalsENGlobal.forms.node_feed_symbol_form.feed_mult:
        return { col: 'feed_mult', type: 'number' };
      case literalsENGlobal.forms.node_feed_symbol_form.price_mode:
        return { col: 'price_mode_value', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.interval:
        return { col: 'interval', type: 'number' };
      case literalsENGlobal.forms.node_feed_symbol_form.sessions:
        return { col: 'sessions', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.state:
        return { col: 'state_value', type: 'text' };
      case literalsENGlobal.forms.node_feed_symbol_form.description:
        return { col: 'description', type: 'text' };

      default:
        return { col: header, type: 'text' };
    }
  };

  postMultiple = (files, allFiles, rawData, showLoader, hideLoader) => {
    try {
      showLoader();
      const bulkData = [];
      const errors = [];
      const dataForSymbolByFeed = rawData.map((item) => ({
        node_feed: String(item.node_feed),
        symbol_val: String(item.symbol_val)
      }));
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/node/feed/symbol_by_feed`,
          JSON.stringify(dataForSymbolByFeed)
        )
        .then((responseSymbolByFeed) => {
          const rowData = responseSymbolByFeed.data;
          rawData.forEach((values) => {
            const symbolAndNodeFeed = `${_.trim(values.symbol_val)}->${_.trim(
              values.node_feed
            )}`;
            const nodeFeed = this.props.formData.NodeFeedObj[values.node_feed];
            if (!nodeFeed) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.node_feed}`
              });
            }
            if (_.trim(values.party_symbol).length === 0) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.party_symbol}`
              });
            }
            const aggregateGroup =
              this.props.formData.AggregateGroupObj[
                _.trim(values.aggregate_group)
              ];
            if (!aggregateGroup && _.trim(values.aggregate_group).length > 0) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.aggregate_group}`
              });
            }
            const markupGroup =
              this.props.formData.MarkupGroupObj[_.trim(values.markup_group)];
            if (!markupGroup && _.trim(values.markup_group).length > 0) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.markup_group}`
              });
            }
            const feedModeVal =
              this.props.formData.FeedModeObj[values.feed_mode_value];
            if (!feedModeVal) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.feed_mode}`
              });
            }
            const feedMode = feedModeVal ? feedModeVal.value : 0;
            if (!regExSignedDecimalNumber.test(values.min_volume)) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.min_volume}`
              });
            }
            if (!regExSignedDecimalNumber.test(values.depth)) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_feed_symbol_form.depth}`
              });
            }
            const feedSourceVal =
              this.props.formData.FeedSourceObj[values.feed_source_value];
            if (!feedSourceVal) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.feed_source}`
              });
            }
            const feedSource = feedSourceVal ? feedSourceVal.value : 0;
            if (!regExSignedDecimalNumber.test(values.feed_tob_volume)) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.feed_tob_volume}`
              });
            }
            if (!regExSignedDecimalNumber.test(values.feed_mult)) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.feed_mult}`
              });
            }
            const priceModeVal =
              this.props.formData.PriceModeObj[values.price_mode_value];
            if (!priceModeVal) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.price_mode}`
              });
            }
            const priceMode = priceModeVal ? priceModeVal.value : 0;
            if (!regExSignedDecimalNumber.test(values.interval)) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.interval}`
              });
            }
            if (!validateSessions(values.sessions)) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.node_feed_symbol_form.sessions}`
              });
            } else {
              values.sessions = parseSessions(_.trim(values.sessions));
            }
            const stateVal = this.props.formData.StateObj[values.state_value];
            if (!stateVal) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_feed_symbol_form.state}`
              });
            }
            const state = stateVal ? stateVal.value : 0;
            const oldRow = rowData[values.node_feed + values.symbol_val];
            if (!oldRow) {
              errors.push({
                symbol: symbolAndNodeFeed,
                error: `${this.props.literals.forms.invalid}`
              });
            } else {
              const newData = {
                symbol_val: _.trim(values.symbol_val),
                security: _.trim(oldRow.security),
                node: oldRow.node,
                node_feed: _.trim(values.node_feed),
                party_symbol: _.trim(values.party_symbol),
                base: oldRow.base,
                quote: oldRow.quote,
                aggregate_group: _.trim(values.aggregate_group),
                markup_group: _.trim(values.markup_group),
                feed_mode: parseInt(feedMode, 10),
                levels: _.trim(values.levels),
                min_volume: parseInt(values.min_volume, 10),
                depth: parseInt(values.depth, 10),
                feed_source: parseInt(feedSource, 10),
                feed_tob_volume: parseInt(values.feed_tob_volume, 10),
                feed_mult: parseFloat(values.feed_mult),
                price_mode: parseInt(priceMode, 10),
                interval: parseInt(values.interval, 10),
                sessions: _.trim(values.sessions),
                state: parseInt(state, 10)
              };
              const oldData = {
                symbol_val: oldRow.symbol_val,
                security: oldRow.security,
                node: oldRow.node,
                node_feed: oldRow.node_feed,
                party_symbol: oldRow.party_symbol,
                base: oldRow.base,
                quote: oldRow.quote,
                aggregate_group: oldRow.aggregate_group,
                markup_group: oldRow.markup_group,
                feed_mode: parseInt(oldRow.feed_mode, 10),
                levels: oldRow.levels,
                min_volume: parseInt(oldRow.min_volume, 10),
                depth: parseInt(oldRow.depth, 10),
                feed_source: parseInt(oldRow.feed_source, 10),
                feed_tob_volume: parseInt(oldRow.feed_tob_volume, 10),
                feed_mult: parseFloat(oldRow.feed_mult),
                price_mode: parseInt(oldRow.price_mode, 10),
                interval: parseInt(oldRow.interval, 10),
                sessions: oldRow.sessions,
                state: parseInt(oldRow.state, 10)
              };
              const dataToUpdate = {
                ...newData,
                old_data: {
                  ...oldData
                }
              };
              if (!shallowEqual(newData, oldData)) {
                bulkData.push(dataToUpdate);
              }
            }
          });
          if (errors.length > 0) {
            showNotification(
              this.props.literals.sidebar.node_feed_symbol,
              'danger',
              JSON.stringify(errors),
              null
            );
            hideLoader();
            return;
          }
          axios
            .put(
              `${process.env.REACT_APP_BACKEND_API}/node/feed/symbol_bulk`,
              JSON.stringify(bulkData)
            )
            .then((response) => {
              showNotification(
                this.props.literals.sidebar.node_feed_symbol,
                'primary',
                this.props.literals.forms.completed_message,
                3
              );
              if (response.data.length > 0) {
                showNotification(
                  this.props.literals.sidebar.node_feed_symbol,
                  'danger',
                  `${
                    this.props.literals.forms.uni_symbol_form.symbols_failed
                  } ${response.data.toString()} `,
                  null
                );
              }
              allFiles.forEach((f) => f.remove());
              this.props.resetNodeFeed();
              hideLoader();
            })
            .catch(() => {
              allFiles.forEach((f) => f.remove());
              showNotification(
                this.props.literals.sidebar.node_feed_symbol,
                'danger',
                this.props.literals.forms.fail_message,
                3
              );
              this.props.resetNodeFeed();
              hideLoader();
            });
        })
        .catch((e) => {
          showNotification(
            this.props.literals.sidebar.node_feed_symbol,
            'danger',
            e.message,
            null
          );
          hideLoader();
        });
    } catch (e) {
      showNotification(
        this.props.literals.sidebar.node_feed_symbol,
        'danger',
        e.message,
        null
      );
      hideLoader();
    }
  };

  render() {
    return (
      <UploadData
        tableID="sidebar_node_feed_symbol_import"
        title={this.props.literals.sidebar.node_feed_symbol}
        getHeaderName={this.getHeaderName}
        postMultiple={this.postMultiple}
      />
    );
  }
}
export default connect((state) => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal
}))(NodeFeedSymbolUpload);
