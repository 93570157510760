/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import _ from 'lodash';
import MasterRiskAccountActionRenderer from './actionRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import MasterRiskAccountForm from './form';
import masterRiskAccountAction from './action';
import Loader from '../../shared/components/Loader';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import RAEditor from './RAEditor';

const REDUCE_HEIGHT = 200;
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class MasterRiskAccount extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.stylesToUpdate = {};
      const { literals } = this.props;
      const tableID = 'sidebar_master_risk_account';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.master_risk_account_form.name,
            field: 'name',
            editable: false,
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
          },
          {
            headerName: literals.forms.master_risk_account_form.risk_accounts,
            field: 'combined_risk_accounts',
            cellEditor: 'raEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.master_risk_account_form.description,
            field: 'description',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
          },
        ],
        frameworkComponents: {
          raEditor: RAEditor,
          actionRenderer: MasterRiskAccountActionRenderer,
        },
        rowData: [],
        rowOldData: [],
        formData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.name;
        },
        cellEditUpdate: false,
        activeTab: '1',
        height: window.innerHeight,
        loaderShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.master_risk_account_form.name !== prevProps.literals.forms.master_risk_account_form.name) {
          this.gridColumnApi.getColumn('name').getColDef().headerName = literals.forms.master_risk_account_form.name;
          this.gridColumnApi.getColumn('combined_risk_accounts').getColDef().headerName
            = literals.forms.master_risk_account_form.risk_accounts;
          this.gridColumnApi.getColumn('description').getColDef().headerName
            = literals.forms.master_risk_account_form.description;
          this.gridColumnApi.getColumn('actions').getColDef().headerName = literals.tables.ag_table.actions;
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.onGridSizeChanged(params);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/account/master_risk_account`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.MasterRiskAccount)) });
        })
        .catch(() => {
        });
    }

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridApi.refreshCells({ rowNodes: [params.node], columns: ['name'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridApi.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['name', 'combined_risk_accounts', 'description'],
        fileName: 'MasterRiskAccount.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['name', 'combined_risk_accounts', 'description'],
        fileName: 'MasterRiskAccount.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    updateData = (data) => {
      this.setState({ rowData: data.MasterRiskAccount });
      this.setState({
        formData: {
          RiskAccount: data.RiskAccount,
        },
      });
    };

    updateMasterRiskAccount=() => {
      this.showLoader();
      const len = Object.keys(this.rowsToUpdate).length;
      let i = 0;
      let j = 0;
      const successItems = [];
      const failItems = [];
      const errorItems = [];
      const rowData = _.keyBy(this.state.rowOldData, 'name');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.name];
        const ra = [];
        const mra = [];
        if (values.combined_risk_accounts) {
          values.combined_risk_accounts.split(',').forEach((val) => {
            if (val.startsWith('master->')) {
              mra.push(val.replace('master->', ''));
            } else {
              ra.push(val);
            }
          });
        }
        const dataToUpdate = {
          name: values.name,
          risk_accounts: ra ? ra.join(',') : '',
          master_risk_accounts: mra ? mra.join(',') : '',
          description: values.description,
          old_data: {
            name: oldRow.name,
            risk_accounts: oldRow.risk_accounts,
            master_risk_accounts: oldRow.master_risk_accounts,
            description: oldRow.description,
          },
        };
        axios.put(`${process.env.REACT_APP_BACKEND_API}/account/master_risk_account`, JSON.stringify(dataToUpdate))
          .then((response) => {
            i += 1;
            if (response.data.status) {
              successItems.push(values.name);
            } else {
              failItems.push(values.name);
            }
            if (len === i) {
              if (successItems.length > 0) {
                showNotification(
                  this.props.literals.forms.master_risk_account_form.title_update, 'primary',
                  `${this.props.literals.forms.success_message} - ${successItems.join(', ')}`,
                );
              }
              if (failItems.length > 0) {
                showNotification(
                  this.props.literals.forms.master_risk_account_form.title_update, 'danger',
                  `${this.props.literals.forms.fail_message} - ${failItems.join(', ')}`,
                );
              }
              this.resetLocalMasterRiskAccount();
              this.resetMasterRiskAccount();
            }
          })
          .catch((error) => {
            j += 1;
            errorItems.push(values.name);
            if (len === j) {
              if (errorItems.length > 0) {
                showNotification(
                  this.props.literals.forms.master_risk_account_form.title_update,
                  'danger', `${error.message} - ${errorItems.join(', ')}`,
                );
              }
              this.resetLocalMasterRiskAccount();
              this.resetMasterRiskAccount();
            }
          });
      });
    };

    resetLocalMasterRiskAccount=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({ rowNodes: [node], columns: ['name'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridApi.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    resetMasterRiskAccount=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/account/master_risk_account`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.MasterRiskAccount)) });
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--bordered-top tabs__custom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                                        this.toggle('1');
                                    }}
                      >
                        {literals.sidebar.master_risk_account}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                                        this.toggle('2');
                                    }}
                      >
                        {literals.forms.master_risk_account_form.title_create}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="outer-grid-label">
                        {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                      </div>
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={this.updateMasterRiskAccount}
                              className="icon"
                              color="primary"
                            ><p className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={() => {
                              this.showLoader();
                              this.resetLocalMasterRiskAccount(); this.resetMasterRiskAccount();
                              }}
                              className="icon"
                              color="primary"
                            ><p
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_excel}>
                            <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                              <p className="fa fa-file-excel" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_csv}>
                            <Button
                              onClick={this.onBtExportCSV}
                              className="icon"
                              color="primary"
                            >
                              <p className="fa fa-file-alt" />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>
                      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                        <div
                          id={this.myGridID}
                          style={{
                                    boxSizing: 'border-box',
                                    height: this.state.height - REDUCE_HEIGHT,
                                    width: '100%',
                                }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state.rowData}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                            context={{
                              resetMasterRiskAccount: this.resetMasterRiskAccount,
                              showLoader: this.showLoader,
                              hideLoader: this.hideLoader,
                              formData: this.state.formData,
                              theme: this.props.theme,
                            }}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <MasterRiskAccountForm
                        {...this.props}
                        state={{
                          formData: this.state.formData,
                          resetMasterRiskAccount: this.resetMasterRiskAccount,
                          toggle: this.toggle,
                          showLoader: this.showLoader,
                          hideLoader: this.hideLoader,
                        }}
                        onSubmit={masterRiskAccountAction}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(MasterRiskAccount);
