import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { change, clearFields, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import accountValidate from './accountValidate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input, placeholder, type, disabled, step, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class AccountForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      change: PropTypes.func.isRequired,
    };

    componentDidMount() {
      this.props.change('tm_models', this.props.state.taker_name);
    }

    render() {
      const {
        handleSubmit, literals,
      } = this.props;
      const accountGroupValues = this.props.state.formData.RiskAccountGroup;
      const typeValues = this.props.state.formData.RiskAccountType;
      const squareOffModeValues = this.props.state.formData.RiskAccountSquareOffMode;
      const disabled = false;
      const { submit } = literals.forms.account_form;
      return (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.account_form.name}</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.account_form.name}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.account_form.group}</span>
              <div className="form__form-group-field">
                <Field
                  name="group"
                  component={renderSelectField}
                  type="text"
                  options={accountGroupValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.account_form.currency}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="currency"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.account_form.currency}
                  disabled
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.account_form.taker}</span>
              <div className="form__form-group-field">
                <Field
                  name="tm_models"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.account_form.taker}
                  disabled
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.account_form.type}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="type"
                  component={renderSelectField}
                  type="text"
                  options={typeValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.account_form.stopout}</span>
              <div className="form__form-group-field">
                <Field
                  name="stopout"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.account_form.stopout}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.account_form.square_off_mode}</span>
              <div className="form__form-group-field">
                <Field
                  name="square_off_mode"
                  component={renderSelectField}
                  type="text"
                  options={squareOffModeValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.account_form.state}</span>
              <div className="form__form-group-field">
                <Field
                  name="state"
                  component={renderCheckBoxField}
                  disabled={disabled}
                  defaultChecked
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.account_form.exposure_limit}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="exposure_limit"
                  component={renderField}
                  type="number"
                  step="any"
                  placeholder={literals.forms.account_form.exposure_limit}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.account_form.warn_levels}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="warn_levels"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.account_form.warn_levels}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.account_form.margin_call_level}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="margin_call_level"
                  component={renderField}
                  type="number"
                  step="any"
                  placeholder={literals.forms.account_form.margin_call_level}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.account_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.account_form.description}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('risk_node_account_form');
function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    initialValues: {
      currency: 'USD',
      type: { label: 'MARGIN', value: 0 },
      stopout: 50,
      square_off_mode: { label: 'DISABLED', value: 0 },
      state: true,
      exposure_limit: -1,
      warn_levels: ',,',
      margin_call_level: 100,
    },
    tm_models: selector(state, 'tm_models'),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change, clearFields }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'risk_node_account_form', // a unique identifier for this form
  validate: accountValidate,
})(AccountForm));
