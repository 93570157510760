/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dialog from 'rc-dialog';
import UniSymbolActionRenderer from './actionRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import UniSymbolForm from './form';
import uniSymbolAction from './action';
import StateRenderer from '../../shared/components/StateRenderer';
import { numberFormatter, onGridSizeChanged, onColumnResized, regExDigits,
  validateSessions, parseSessions } from '../../shared/helper';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import Loader from '../../shared/components/Loader';
import SessionRenderer from '../../shared/components/session/SessionRenderer';
import SessionHeaderComponent from '../../shared/components/multi_edit/SessionHeaderComponent';
import UniSymbolUpload from './upload';

const exportColumns = ['name', 'security', 'base', 'quote',
  'category_value', 'digits', 'vol_digits', 'exposure_multiplier',
  'state_value', 'sessions', 'isin', 'description'];

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

function isFirstColumn(params) {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  const thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

@inject('rootStore')
class UniSymbol extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.stylesToUpdate = {};
      const { literals } = this.props;
      const tableID = 'sidebar_uni_symbol';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      const { role } = props.rootStore.authStore;
      const clientCode = props.rootStore.clientStore.CC;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.uni_symbol_form.name,
            field: 'name',
            editable: false,
            cellClassRules: {
              'arrow-right-chk':
                      params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined'),
            },
            width: 60,
            minWidth: 60,
            type: 'text',
          },
          {
            headerName: literals.forms.uni_symbol_form.security,
            field: 'security',
            editable: role === 'admin',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: [],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: role === 'admin' ? 'cellHeaderComponent' : '',
            headerComponentParams: {
              selectValueLiteral: 'Security',
            },
            type: 'text',
          },
          {
            headerName: literals.forms.uni_symbol_form.base,
            field: 'base',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            type: 'text',
          },
          {
            headerName: literals.forms.uni_symbol_form.quote,
            field: 'quote',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            type: 'text',
          },
          {
            headerName: literals.forms.uni_symbol_form.category,
            field: 'category_value',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: [],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
            headerComponentParams: {
              selectValueLiteral: 'Category',
            },
            type: 'text',
          },
          {
            headerName: literals.forms.uni_symbol_form.isin,
            field: 'isin',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
            type: 'text',
          },
          {
            headerName: literals.forms.uni_symbol_form.description,
            field: 'description',
            cellEditor: 'agLargeTextCellEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'cellHeaderComponent',
            type: 'text',
          },
          {
            headerName: literals.forms.uni_symbol_form.digits,
            field: 'digits',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
            type: 'number',
          },
          {
            headerName: literals.forms.uni_symbol_form.vol_digits,
            field: 'vol_digits',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            type: 'number',
            editable: role === 'admin',
            headerComponent: role === 'admin' ? 'cellHeaderComponent' : '',
            hide: clientCode === 'SahamHolding_btguq4vvuaijetmjbm20',
          },
          {
            headerName: literals.forms.uni_symbol_form.exposure_multiplier,
            field: 'exposure_multiplier',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
            type: 'number',
          },
          {
            headerName: literals.forms.uni_symbol_form.state,
            field: 'state_value',
            editable: false,
            cellRenderer: 'stateRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
            type: 'text',
          },
          {
            headerName: literals.forms.uni_symbol_form.sessions,
            field: 'sessions',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'sessionHeaderComponent',
            editable: false,
            cellRenderer: 'sessionRenderer',
            type: 'text',
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
            suppressMenu: true,
          },
        ],
        frameworkComponents: {
          actionRenderer: UniSymbolActionRenderer,
          stateRenderer: StateRenderer,
          stateHeaderComponent: StateHeaderComponent,
          cellHeaderComponent: HeaderComponent,
          sessionRenderer: SessionRenderer,
          sessionHeaderComponent: SessionHeaderComponent,
        },
        rowData: [],
        rowOldData: [],
        formData: [],
        defaultColDef: {
          editable: true,
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
          menuTabs: ['filterMenuTab'],
          headerCheckboxSelection: isFirstColumn,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: isFirstColumn,
          visibleDelete: false,
        },
        columnTypes: {
          text: { filter: 'agTextColumnFilter' },
          number: { filter: 'agNumberColumnFilter' },
          numberWithFilterReset: {
            filter: 'agNumberColumnFilter',
          },
        },
        getRowNodeId(data) {
          return data.name;
        },
        cellEditUpdate: false,
        activeTab: '1',
        height: window.innerHeight,
        loaderShow: false,
        deleteShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
      if (clientCode === 'SahamHolding_btguq4vvuaijetmjbm20') {
        const index = exportColumns.indexOf('vol_digits');
        exportColumns.splice(index, 1);
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.uni_symbol_form.name !== prevProps.literals.forms.uni_symbol_form.name) {
          this.gridColumnApi.getColumn('name').getColDef().headerName = literals.forms.uni_symbol_form.name;
          this.gridColumnApi.getColumn('security').getColDef().headerName = literals.forms.uni_symbol_form.security;
          this.gridColumnApi.getColumn('base').getColDef().headerName = literals.forms.uni_symbol_form.base;
          this.gridColumnApi.getColumn('quote').getColDef().headerName = literals.forms.uni_symbol_form.quote;
          this.gridColumnApi.getColumn('category_value').getColDef().headerName
              = literals.forms.uni_symbol_form.category;
          this.gridColumnApi.getColumn('digits').getColDef().headerName = literals.forms.uni_symbol_form.digits;
          this.gridColumnApi.getColumn('vol_digits').getColDef().headerName =
              literals.forms.uni_symbol_form.vol_digits;
          this.gridColumnApi.getColumn('exposure_multiplier').getColDef().headerName
            = literals.forms.uni_symbol_form.exposure_multiplier;
          this.gridColumnApi.getColumn('state_value').getColDef().headerName = literals.forms.uni_symbol_form.state;
          this.gridColumnApi.getColumn('sessions').getColDef().headerName = literals.forms.uni_symbol_form.sessions;
          this.gridColumnApi.getColumn('isin').getColDef().headerName = literals.forms.uni_symbol_form.isin;
          this.gridColumnApi.getColumn('description').getColDef().headerName
              = literals.forms.uni_symbol_form.description;
          this.gridColumnApi.getColumn('actions').getColDef().headerName = literals.tables.ag_table.actions;
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.onGridSizeChanged(params);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/security/uni_symbol`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.UniSymbol)) });
          this.gridColumnApi.getColumn('category_value').getColDef().cellEditorParams.values =
            response.data.Category ? response.data.Category.map(a => a.label) : [];
          this.gridColumnApi.getColumn('security').getColDef().cellEditorParams.values =
              response.data.Security ? response.data.Security.map(a => a.label) : [];
        })
        .catch(() => {
        });
    }

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridApi.refreshCells({ rowNodes: [params.node], columns: ['name'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridApi.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'category_value') {
          params.data.category = this.state.formData.CategoryObj[params.newValue].value;
        }
        if (params.colDef.field === 'state_value') {
          params.data.state = this.state.formData.StateObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onSelectionChanged = (event) => {
      this.setState({ deleteShow: event.api.getSelectedRows().length > 0 });
    }

    onBtExportExcel=() => {
      const params = {
        columnKeys: exportColumns,
        fileName: 'UniversalSymbol.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: exportColumns,
        fileName: 'UniversalSymbol.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    updateData = (data) => {
      this.setState({ rowData: data.UniSymbol });
      this.setState({
        formData: {
          Security: data.Security,
          Category: data.Category,
          CategoryObj: arrayToObject(data.Category, 'label'),
          State: data.State,
          StateObj: arrayToObject(data.State, 'label'),
        },
      });
    };

    updateUniSymbol=() => {
      this.showLoader();
      const bulkData = [];
      const errors = [];
      const rowData = _.keyBy(this.state.rowOldData, 'name');
      Object.values(this.rowsToUpdate).forEach((values) => {
        if (!regExDigits.test(values.digits)) {
          errors.push({
            symbol: _.trim(values.name),
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.digits}`,
          });
        }
        if (!validateSessions(values.sessions)) {
          errors.push({
            symbol: _.trim(values.name),
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.sessions}`
          });
        } else {
          values.sessions = parseSessions(_.trim(values.sessions));
        }
        const oldRow = rowData[values.name];
        let volDigits = parseInt(values.vol_digits, 10);
        if (Number.isNaN(volDigits)) {
          volDigits = 2;
        }
        const dataToUpdate = {
          name: _.trim(values.name),
          security: values.security,
          base: _.trim(values.base),
          quote: _.trim(values.quote),
          category: parseInt(values.category, 10),
          digits: parseInt(values.digits, 10),
          vol_digits: volDigits,
          state: parseInt(values.state, 10),
          sessions: _.trim(values.sessions),
          isin: _.trim(values.isin),
          description: _.trim(values.description),
          exposure_multiplier: parseFloat(values.exposure_multiplier),
          old_data: {
            name: oldRow.name,
            security: oldRow.security,
            base: oldRow.base,
            quote: oldRow.quote,
            category: parseInt(oldRow.category, 10),
            digits: parseInt(oldRow.digits, 10),
            vol_digits: parseInt(oldRow.vol_digits, 10),
            state: parseInt(oldRow.state, 10),
            sessions: oldRow.sessions,
            isin: oldRow.isin,
            description: oldRow.description,
            exposure_multiplier: parseFloat(oldRow.exposure_multiplier),
          },
        };
        if (errors.length > 0) {
          showNotification(
            this.props.literals.forms.uni_symbol_form.name, 'danger',
            JSON.stringify(errors), null,
          );
          this.hideLoader();
          return;
        }
        bulkData.push(dataToUpdate);
      });
      axios.put(`${process.env.REACT_APP_BACKEND_API}/security/uni_symbol_bulk_update`, JSON.stringify(bulkData))
        .then((response) => {
          this.resetLocalUniSymbol();
          this.resetUniSymbol();
          showNotification(
            this.props.literals.sidebar.uni_symbol, 'primary',
            this.props.literals.forms.completed_message,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.uni_symbol, 'danger',
              `${this.props.literals.forms.fail_message} - ${response.data.toString()}}`,
            );
          }
        })
        .catch((error) => {
          this.resetLocalUniSymbol();
          this.resetUniSymbol();
          showNotification(
            this.props.literals.sidebar.uni_symbol, 'danger',
            `${this.props.literals.forms.fail_message} - ${error.message}}`,
          );
        });
    };

    resetLocalUniSymbol=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({ rowNodes: [node], columns: ['name'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridApi.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    resetUniSymbol=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/security/uni_symbol`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.UniSymbol)) });
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    clickDeleteBulkUniSymbol = () => {
      this.setState({
        visibleDelete: true,
      });
    };
    closeDeleteBulkUniSymbol = () => {
      this.setState({
        visibleDelete: false,
      });
    };

    deleteBulkUniSymbol = () => {
      this.closeDeleteBulkUniSymbol();
      this.showLoader();
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/security/uni_symbol/delete/bulk`,
        JSON.stringify(this.gridApi.getSelectedRows()),
      )
        .then(() => {
          showNotification(
            this.props.literals.forms.uni_symbol_form.title_delete, 'primary',
            this.props.literals.forms.completed_message, 3,
          );
          this.resetUniSymbol();
          this.hideLoader();
        })
        .catch((error) => {
          showNotification(this.props.literals.forms.uni_symbol_form.title_delete, 'danger', error.message, 3);
          this.hideLoader();
        });
    }

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      let dialog;
      if (this.state.visibleDelete) {
        const deleteSymbols = this.gridApi.getSelectedRows().map(row => row.name);
        dialog = (
          <Dialog
            visible={this.state.visibleDelete}
            wrapClassName="center"
            animation="slide-fade"
            maskAnimation="fade"
            onClose={this.closeDeleteBulkUniSymbol}
            style={{ width: 276 }}
            title={literals.forms.confirm_delete}
          >
            <p>{literals.forms.confirm_delete_note} {deleteSymbols ? deleteSymbols.length : 0} symbol(s)?</p>
            <p style={{ maxHeight: 100, overflow: 'auto' }}>{JSON.stringify(deleteSymbols)}</p>
            <div style={{
                marginTop: 10,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '68%',
              }}
            >
              <ButtonToolbar>
                <Button
                  className="icon"
                  color="primary"
                  key="close"
                  onClick={this.closeDeleteBulkUniSymbol}
                >
                  {literals.forms.cancel}
                </Button>
                <Button
                  className="icon"
                  color="primary"
                  key="save"
                  onClick={() => { this.deleteBulkUniSymbol(); }}
                >
                  {literals.forms.delete}
                </Button>
              </ButtonToolbar>
            </div>
          </Dialog>
        );
      }
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--bordered-top tabs__custom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                                        this.toggle('1');
                                    }}
                      >
                        {literals.sidebar.uni_symbol}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                                        this.toggle('2');
                                    }}
                      >
                        {literals.forms.uni_symbol_form.title_create}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => {
                          this.toggle('3');
                        }}
                      >
                        {`${literals.forms.title_import} ${literals.sidebar.uni_symbol}`}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="outer-grid-label">
                        {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                      </div>
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.delete}>
                            <Button
                              style={{
                                  display: this.state.deleteShow ? '' : 'none',
                                }}
                              onClick={() => { this.clickDeleteBulkUniSymbol(); }}
                              className="icon"
                              color="primary"
                            ><p
                              className="fa fa-trash-alt"
                            />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={this.updateUniSymbol}
                              className="icon"
                              color="primary"
                            ><p className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={() => { this.showLoader(); this.resetLocalUniSymbol(); this.resetUniSymbol(); }}
                              className="icon"
                              color="primary"
                            ><p
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_excel}>
                            <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                              <p className="fa fa-file-excel" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_csv}>
                            <Button
                              onClick={this.onBtExportCSV}
                              className="icon"
                              color="primary"
                            >
                              <p className="fa fa-file-alt" />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>
                      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                        <div
                          id={this.myGridID}
                          style={{
                                    boxSizing: 'border-box',
                                    height: this.state.height - REDUCE_HEIGHT,
                                    width: '100%',
                                }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state.rowData}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                            columnTypes={this.state.columnTypes}
                            rowSelection="multiple"
                            suppressRowClickSelection
                            onSelectionChanged={this.onSelectionChanged}
                            context={{
                              formData: this.state.formData,
                              resetUniSymbol: this.resetUniSymbol,
                              showLoader: this.showLoader,
                              hideLoader: this.hideLoader,
                              theme: this.props.theme,
                              onCellValueChanged: this.onCellValueChanged,
                          }}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <UniSymbolForm
                        {...this.props}
                        state={{
                                        formData: this.state.formData,
                                        resetUniSymbol: this.resetUniSymbol,
                                        toggle: this.toggle,
                                        showLoader: this.showLoader,
                                        hideLoader: this.hideLoader,
                                    }}
                        onSubmit={uniSymbolAction}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <UniSymbolUpload
                        formData={this.state.formData}
                        resetUniSymbol={this.resetUniSymbol}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              {dialog}
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(UniSymbol);
