/* eslint-disable  no-useless-concat */
/* eslint-disable  no-bitwise */
import $ from 'jquery';
import _ from 'lodash';

const numberFormatter = (number) => {
  if (typeof number === 'undefined') {
    return '';
  }
  return number.toLocaleString('en', { maximumSignificantDigits: 21 });
};

const addCommas = (nStr) => {
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

const decimalPlaces = (num) => {
  const match = `${num}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 1;
  }
  return Math.max(
    1,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
};

const isInt = (n) => n % 1 === 0;

const MIN_HEIGHT = 12;
const onColumnResized = (params, myGridID) => {
  params.api.setHeaderHeight(MIN_HEIGHT);
  const headerCells = $(`#${myGridID} .ag-header .ag-header-cell-text`);
  let minHeight = MIN_HEIGHT;
  headerCells.each((i, cell) => {
    minHeight = Math.max(minHeight, cell.scrollHeight);
  });
  minHeight += 10;
  params.api.setHeaderHeight(minHeight);
  return minHeight;
};

const onGridSizeChanged = (params, gridWrapperID, myGridID) => {
  const gridWidth = $(`#${gridWrapperID}`).width();
  let totalColsWidth = 0;
  const allColumns = params.columnApi.getAllDisplayedColumns();
  for (let i = 0; i < allColumns.length; i += 1) {
    const column = allColumns[i];
    totalColsWidth += column.getMinWidth();
  }
  if (totalColsWidth < gridWidth) {
    params.api.sizeColumnsToFit();
  }
  onColumnResized(params, myGridID);
};

const autoSuggestEscapedValue = (value, replaceWords) => {
  let escapedValue = value;
  replaceWords.forEach((replaceWord) => {
    const re = new RegExp(replaceWord, 'g');
    escapedValue = escapedValue
      .replace(/\s/g, '')
      .toUpperCase()
      .replace(re, '')
      .replace(/\s/g, '');
  });
  return escapedValue;
};

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item
    }),
    initialValue
  );
};

const getFileExtension = (filename) =>
  filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);

const createFilterSql = (mapper, key, item) => {
  if (item.operator) {
    let operator = '&&';
    if (item.operator === 'OR') {
      operator = '||';
    }
    const condition1 = mapper(key, item.condition1);
    const condition2 = mapper(key, item.condition2);

    return `(${condition1} ${operator} ${condition2})`;
  }

  return mapper(key, item);
};

const textFilterMapper = (key, item) => {
  switch (item.type) {
    case 'equals':
      return `_.lowerCase(obj.${key}) == _.lowerCase('${item.filter}')`;
    case 'notEqual':
      return `_.lowerCase(obj.${key}) != _.lowerCase('${item.filter}')`;
    case 'contains':
      return `_.lowerCase(obj.${key}).includes(_.lowerCase('${item.filter}'))`;
    case 'notContains':
      return `!_.lowerCase(obj.${key}).includes(_.lowerCase('${item.filter}'))`;
    case 'startsWith':
      return `_.lowerCase(obj.${key}).startsWith(_.lowerCase('${item.filter}'))`;
    case 'endsWith':
      return `_.lowerCase(obj.${key}).endsWith(_.lowerCase('${item.filter}'))`;
    default:
      return ''; // unknown text filter type
  }
};

const numberFilterMapper = (key, item) => {
  switch (item.type) {
    case 'equals':
      return `obj.${key} == ${item.filter}`;
    case 'notEqual':
      return `obj.${key} != ${item.filter}`;
    case 'greaterThan':
      return `obj.${key} > ${item.filter}`;
    case 'greaterThanOrEqual':
      return `obj.${key} >= ${item.filter}`;
    case 'lessThan':
      return `obj.${key} < ${item.filter}`;
    case 'lessThanOrEqual':
      return `obj.${key} <= ${item.filter}`;
    case 'inRange':
      return `(obj.${key} >= ${item.filter} && obj.${key} <= ${item.filterTo})`;
    default:
      return ''; // unknown number filter type
  }
};

const setFilterMapper = (key, item) => {
  const setValues = item.values.map(
    (val) => `_.lowerCase(obj.${key}) == _.lowerCase('${val}')`
  );
  return setValues.join('||');
};

const whereSql = (request) => {
  const whereParts = [];
  const { filterModel } = request;

  if (filterModel) {
    Object.keys(filterModel).forEach((key) => {
      const item = filterModel[key];

      switch (item.filterType) {
        case 'text':
          whereParts.push(createFilterSql(textFilterMapper, key, item));
          break;
        case 'number':
          whereParts.push(createFilterSql(numberFilterMapper, key, item));
          break;
        case 'set':
          whereParts.push(createFilterSql(setFilterMapper, key, item));
          break;
        default:
          // unknown filter type
          break;
      }
    });
  }

  if (whereParts.length > 0) {
    return ` ${whereParts.join(' && ')}`;
  }

  return '';
};

const orderBySql = (request) => {
  const { sortModel } = request;
  const res = { colId: [], sort: [] };
  if (sortModel.length === 0) return res;

  for (let i = 0; i < sortModel.length; i += 1) {
    res.colId.push(sortModel[i].colId);
    res.sort.push(sortModel[i].sort);
  }

  return res;
};
const isDoingGrouping = (rowGroupCols, groupKeys) =>
  // we are not doing grouping if at the lowest level
  rowGroupCols.length > groupKeys.length;

const serverSideDatasourceGetRows = (params, rowData) => {
  const blockSize = params.request.endRow - params.request.startRow;
  const limit = blockSize + 1;
  const offset = params.request.startRow;
  let res1 = rowData;
  if (Object.keys(params.request.filterModel).length > 0) {
    const fn = whereSql(params.request);
    // eslint-disable-next-line no-unused-vars,no-eval
    res1 = _.filter(rowData, (obj) => eval(fn));
  }
  if (params.request.sortModel.length > 0) {
    const orderBy = orderBySql(params.request);
    res1 = _.orderBy(res1, orderBy.colId, orderBy.sort);
  }
  if (params.tableFeature === 'myGrid_sidebar_maker_rejection') {
    const { rowGroupCols, groupKeys } = params.request;
    if (isDoingGrouping(rowGroupCols, groupKeys)) {
      const rowGroupCol = rowGroupCols[groupKeys.length];
      if (groupKeys.length > 0) {
        res1 = _.filter(res1, [rowGroupCols[0].id, groupKeys[0]]);
      }
      res1 = _(res1)
        .groupBy(rowGroupCol.id)
        .map((objs, key) => ({
          [rowGroupCol.id]: key,
          volume_value:
            rowGroupCols[0].id === 'lpsid' && groupKeys.length === 0
              ? 0
              : Math.round(
                  (_.sumBy(objs, 'volume_value') + Number.EPSILON) * 100
                ) / 100
        }))
        .value();
    }
  }
  const res = _(res1).drop(offset).take(limit).value();
  const response = {
    success: true,
    rows: res,
    lastRow: res1.length,
    allRows: res1
  };
  return response;
};

const regExDigits = /^([0-9]|10)$/;

const regExSessions =
  /^(?:MON|TUE|WED|THU|FRI|SAT|SUN),(?:[01][0-9]|2[0-3]):[0-5][0-9]-(?:[01][0-9]|2[0-3]):[0-5][0-9](?:-(?:[01][0-9]|2[0-3]):[0-5][0-9]-(?:[01][0-9]|2[0-3]):[0-5][0-9])*;(?:(?:MON|TUE|WED|THU|FRI|SAT|SUN),(?:[01][0-9]|2[0-3]):[0-5][0-9]-(?:[01][0-9]|2[0-3]):[0-5][0-9](?:-(?:[01][0-9]|2[0-3]):[0-5][0-9]-(?:[01][0-9]|2[0-3]):[0-5][0-9])*;)*$/;

const validateSessions = (sessions) => {
  if (!sessions) return false;
  if (!regExSessions.test(sessions)) return false;
  const items = sessions.split(';');
  const dow = {};
  items.forEach((session) => {
    const dayW = session.split(',');
    if (dow[dayW[0]] !== undefined) {
      dow[dayW[0]] = `${dow[dayW[0]]}-${dayW[1]}`;
    } else {
      [, dow[dayW[0]]] = dayW;
    }
  });
  let isError = false;
  Object.values(dow).every((val) => {
    if (!val) return false;
    const myRe =
      /([01][0-9]|2[0-3]):([0-5][0-9])-([01][0-9]|2[0-3]):([0-5][0-9])/g;
    const myArray = val.match(myRe);
    if (!myArray) return false;
    const sessionsDuplicate = {};
    myArray.every((session) => {
      if (sessionsDuplicate[session] === true) {
        isError = true; // duplicated session
        return false;
      }
      sessionsDuplicate[session] = true;
      const times = session.split('-');
      if (times[0] > times[1]) {
        isError = true; // from time greater
        return false;
      }
      myArray.every((existingSession) => {
        const newRange = session.split('-');
        const existingRange = existingSession.split('-');
        if (session !== existingSession) {
          const overlaps =
            (existingRange[0] >= newRange[0] &&
              existingRange[0] <= newRange[1] &&
              existingSession !== '00:00-00:00') ||
            (existingRange[1] >= newRange[0] &&
              existingRange[1] <= newRange[1] &&
              existingSession !== '00:00-00:00');
          if (overlaps) {
            // time overlaps
            isError = true;
            return false;
          }
        }
        return true;
      });
      return !isError;
    });
    return !isError;
  });
  return !isError;
};

const parseSessionToArray = (sessions) => {
  const session = [];
  if (sessions) {
    sessions
      .replace(/;$/, '')
      .split(';')
      .forEach((dayOfTheWeek) => {
        const day = dayOfTheWeek.split(',');
        let i = 0;
        let lastTime = '';
        day[1].split('-').forEach((time) => {
          if (i % 2 !== 0) {
            session.push(`${day[0]},${lastTime}-${time}`);
          }
          lastTime = time;
          i += 1;
        });
      });
  }
  return session;
};

const parseSessions = (sessions) => {
  const session = parseSessionToArray(sessions);
  const group1 = session.reduce((r, a) => {
    const day = a.split(',');
    r[day[0]] = [...(r[day[0]] || []), a];
    return r;
  }, {});
  let str = '';
  const map = {
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
    SUN: 7
  };
  Object.keys(map).forEach((item) => {
    if (!(item in group1)) {
      group1[item] = [`${item},00:00-00:00`];
    }
  });
  Object.keys(group1)
    .sort((a, b) => {
      return map[a] - map[b];
    })
    .forEach((masterItem) => {
      let strItem = `${masterItem},`;
      group1[masterItem].sort();
      group1[masterItem].forEach((item) => {
        const day = item.split(',');
        strItem += `${day[1]}-`;
      });
      strItem = strItem.replace(/-$/, '');
      strItem += ';';
      str += strItem;
    });
  return str;
};

const regExSignedDecimalNumber = /^[+-]?[0-9]+(?:\.[0-9]+)?$/;

const shallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    // eslint-disable-next-line eqeqeq
    if (object1[key] != object2[key]) {
      return false;
    }
  }
  return true;
};

const copyObject = (object) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    newObject[key] = object[key];
  });
  return newObject;
};

const regExDepthLevels =
  /^((-?[0-9]+):(-?[0-9]+):(-?[0-9]+):(-?[0-9]+):(-?[0-9]+):(-?[0-9]+)(:1)?),?((-?[0-9]+):(-?[0-9]+):(-?[0-9]+):(-?[0-9]+):(-?[0-9]+):(-?[0-9]+),?)*$/;
const regExZeroOrOne = /^[01]$/;
const regExUnSignedNumber = /^[0-9]+$/;

const arrayToObject = (arr, keyField) => {
  if (!Array.isArray(arr)) {
    return {};
  }
  return Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));
};

export {
  numberFormatter,
  addCommas,
  decimalPlaces,
  isInt,
  onGridSizeChanged,
  onColumnResized,
  autoSuggestEscapedValue,
  convertArrayToObject,
  getFileExtension,
  serverSideDatasourceGetRows,
  regExDigits,
  regExSessions,
  validateSessions,
  regExSignedDecimalNumber,
  shallowEqual,
  copyObject,
  regExDepthLevels,
  regExZeroOrOne,
  regExUnSignedNumber,
  parseSessionToArray,
  parseSessions,
  arrayToObject
};
