/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values) => {
  values.showLoader();
  if (values.togglePopover) {
    values.togglePopover();
  }
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const data = {
    risk_account: values.risk_account ? values.risk_account.value : '',
    type: values.type ? parseInt(values.type.value, 10) : null,
    direction: values.direction ? parseInt(values.direction.value, 10) : null,
    amount: parseFloat(values.amount),
    open_cen_order: values.open_cen_order,
    close_cen_order: values.close_cen_order,
    cen_volume: parseInt(values.cen_volume, 10),
    comment: values.comment,
  };

  axios.post(`${process.env.REACT_APP_BACKEND_API}/account/balance_transaction`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          values.literals.forms.balance_transaction_form.title_create, 'primary',
          values.literals.forms.success_message,
        );
        values.hideLoader();
      } else {
        showNotification(
          values.literals.forms.balance_transaction_form.title_create, 'danger',
          values.literals.forms.fail_message,
        );
        values.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(values.literals.forms.balance_transaction_form.title_create, 'danger', error.message);
      values.hideLoader();
    });
};
