/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham-dark.css';
import { withRouter } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LPActionRenderer from './actionRenderer';
import { ThemeProps, LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import LPForm from './form';
import lpAction from './action';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import Loader from '../../shared/components/Loader';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';

const REDUCE_HEIGHT = 200;
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class LP extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.stylesToUpdate = {};
      const { literals } = this.props;
      const tableID = 'sidebar_lp';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.lp_form.name,
            field: 'name',
            editable: false,
            pinned: 'left',
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.lp_form.securities,
            field: 'sec_types',
            editable: false,
          },
          {
            headerName: literals.forms.lp_form.protocol,
            field: 'protocol',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_form.version,
            field: 'version',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_form.description,
            field: 'description',
            cellEditor: 'agLargeTextCellEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
          },
        ],
        frameworkComponents: {
          actionRenderer: LPActionRenderer,
          cellHeaderComponent: HeaderComponent,
        },
        rowData: [],
        rowOldData: [],
        formData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.name;
        },
        cellEditUpdate: false,
        activeTab: '1',
        height: window.innerHeight,
        loaderShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.lp_form.name !== prevProps.literals.forms.lp_form.name) {
          this.gridColumnApi.getColumn('name').getColDef().headerName = literals.forms.lp_form.name;
          this.gridColumnApi.getColumn('sec_types').getColDef().headerName = literals.forms.lp_form.securities;
          this.gridColumnApi.getColumn('protocol').getColDef().headerName = literals.forms.lp_form.protocol;
          this.gridColumnApi.getColumn('version').getColDef().headerName = literals.forms.lp_form.version;
          this.gridColumnApi.getColumn('description').getColDef().headerName = literals.forms.lp_form.description;
          this.gridColumnApi.getColumn('actions').getColDef().headerName = literals.tables.ag_table.actions;
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.onGridSizeChanged(params);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/lp`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.LP)) });
        })
        .catch(() => {
        });
    }

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridApi.refreshCells({ rowNodes: [params.node], columns: ['name'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridApi.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['name', 'sec_types', 'protocol', 'version', 'description'],
        fileName: 'Maker.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['name', 'sec_types', 'protocol', 'version', 'description'],
        fileName: 'Maker.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    updateData = (data) => {
      this.setState({ rowData: data.LP });
      this.setState({
        formData: {
          Security: data.Security,
        },
      });
    };

    updateLP=() => {
      this.showLoader();
      const bulkData = [];
      const rowData = _.keyBy(this.state.rowOldData, 'name');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.name];
        const dataToUpdate = {
          name: values.name,
          sec_types: values.sec_types,
          protocol: values.protocol,
          version: values.version,
          description: values.description,
          old_data: {
            name: oldRow.name,
            sec_types: oldRow.sec_types,
            protocol: oldRow.protocol,
            version: oldRow.version,
            description: oldRow.description,
          },
        };
        bulkData.push(dataToUpdate);
      });
      axios.put(`${process.env.REACT_APP_BACKEND_API}/admin/lp_bulk`, JSON.stringify(bulkData))
        .then((response) => {
          this.resetLocalLP();
          this.resetLP();
          showNotification(
            this.props.literals.sidebar.lp, 'primary',
            this.props.literals.forms.completed_message,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.lp, 'danger',
              `${this.props.literals.forms.fail_message} - ${response.data.toString()}}`,
            );
          }
        })
        .catch((error) => {
          this.resetLocalLP();
          this.resetLP();
          showNotification(
            this.props.literals.sidebar.lp, 'danger',
            `${this.props.literals.forms.fail_message} - ${error.message}}`,
          );
        });
    };

    resetLocalLP=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({ rowNodes: [node], columns: ['name'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridApi.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    resetLP=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/lp`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.LP)) });
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--bordered-top tabs__custom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                                        this.toggle('1');
                                    }}
                      >
                        {literals.sidebar.lp}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                                        this.toggle('2');
                                    }}
                      >
                        {literals.forms.lp_form.title_create}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="outer-grid-label">
                        {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                      </div>
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={this.updateLP}
                              className="icon"
                              color="primary"
                            ><p className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={() => { this.showLoader(); this.resetLocalLP(); this.resetLP(); }}
                              className="icon"
                              color="primary"
                            ><p
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_excel}>
                            <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                              <p className="fa fa-file-excel" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_csv}>
                            <Button
                              onClick={this.onBtExportCSV}
                              className="icon"
                              color="primary"
                            >
                              <p className="fa fa-file-alt" />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>

                      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                        <div
                          id={this.myGridID}
                          style={{
                                        boxSizing: 'border-box',
                                        height: this.state.height - REDUCE_HEIGHT,
                                        width: '100%',
                                    }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state.rowData}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                            context={{
                              formData: this.state.formData,
                              resetLP: this.resetLP,
                              showLoader: this.showLoader,
                              hideLoader: this.hideLoader,
                              onCellValueChanged: this.onCellValueChanged,
                            }}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <LPForm
                        {...this.props}
                        state={{
                                        formData: this.state.formData,
                                        resetLP: this.resetLP,
                                        toggle: this.toggle,
                                        showLoader: this.showLoader,
                                        hideLoader: this.hideLoader,
                                    }}
                        onSubmit={lpAction}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default withRouter(connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(LP));
