import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { Tooltip } from 'react-tippy';
import FileDownload from 'js-file-download';
import moment from 'moment-timezone';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderDateTimePickerDailyField from '../../shared/components/form/DateTimePickerCriteria';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class ChartMarketImpactSearchForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    login: PropTypes.instanceOf(Object),
    criteria: PropTypes.instanceOf(Object),
    search_values: PropTypes.instanceOf(Object),
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
  };
  static defaultProps = {
    login: {},
    criteria: {},
    search_values: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      login: [],
      symbol: [],
      isValidDate() {
        return true;
      },
      isDateDisabled: false,
    };
  }
  componentDidMount() {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/report/market_impact`)
      .then((response) => {
        if (this.props.login.value) {
          this.symbolManage(this.props.login);
        }
        if (this.props.criteria.value) {
          this.criteriaManage(this.props.criteria);
        }
        this.setState({ login: response.data });
      })
      .catch(() => {
      });
  }
  onBtExportExcel=() => {
    this.props.showLoader();
    const dataToSend = this.filtersToSend(this.props.search_values);
    axios.post(
      `${process.env.REACT_APP_BACKEND_API}/report/market_impact/export`, JSON.stringify(dataToSend),
      { responseType: 'blob' },
    )
      .then((response) => {
        FileDownload(response.data, 'MarketImpact.xlsx');
        this.props.hideLoader();
      })
      .catch(() => {
        this.props.hideLoader();
      });
  };
  filtersToSend = values => ({
    login: values.login.value ? parseInt(values.login.value, 10) : null,
    symbol: values.symbol.value || null,
    criteria: values.criteria.value ? parseInt(values.criteria.value, 10) : null,
    search_date: values.search_date ? moment.tz(values.search_date, 'DD-MM-YYYY', 'UTC').unix() : null,
  });
  handleChangeLogin=(login) => {
    this.props.change('symbol', { label: '', value: '' });
    this.symbolManage(login);
  };
  symbolManage=(login) => {
    const loginInput = login ? parseInt(login.value, 10) : 0;
    axios.get(`${process.env.REACT_APP_BACKEND_API}/report/market_impact/symbol?login=${loginInput}`)
      .then((response) => {
        this.setState({ symbol: response.data });
      })
      .catch(() => {
      });
  };
  handleChangeCriteria=(criteria) => {
    this.props.change('search_date', '');
    this.criteriaManage(criteria);
  };
  criteriaManage=(criteria) => {
    const criteriaInput = parseInt(criteria.value, 10);
    if (criteriaInput === 1) {
      this.setState({ isValidDate: () => true });
      this.setState({ isDateDisabled: false });
    } else if (criteriaInput === 2) {
      this.setState({ isValidDate: current => current.day() === 0 });
      this.setState({ isDateDisabled: false });
    } else if (criteriaInput === 3) {
      this.setState({ isValidDate: current => current.date() === current.daysInMonth() });
      this.setState({ isDateDisabled: false });
    } else if (criteriaInput === 4) {
      this.setState({ isValidDate: current => current.month() + 1 === 12 && current.date() === 31 });
      this.setState({ isDateDisabled: false });
    } else {
      this.setState({ isDateDisabled: true });
    }
  };
  render() {
    const {
      handleSubmit, literals,
    } = this.props;
    const criteria = [{
      label: 'ALL',
      value: 'ALL',
    }, {
      label: 'Daily',
      value: 1,
    }, {
      label: 'Weekly',
      value: 2,
    }, {
      label: 'Monthly',
      value: 3,
    }, {
      label: 'Yearly',
      value: 4,
    }];
    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__half form__search">
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.tables.order_table.ext_login}</span>
            <div className="form__form-group-field">
              <Field
                name="login"
                component={renderSelectField}
                type="text"
                options={this.state.login}
                onChange={this.handleChangeLogin}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.tables.market_impact_table.criteria}</span>
            <div className="form__form-group-field">
              <Field
                name="criteria"
                component={renderSelectField}
                type="text"
                options={criteria}
                onChange={this.handleChangeCriteria}
              />
            </div>
          </div>
        </div>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.tables.order_table.symbol}</span>
            <div className="form__form-group-field">
              <Field
                name="symbol"
                component={renderSelectField}
                type="text"
                options={this.state.symbol}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.tables.market_impact_table.search_date}</span>
            <div className="form__form-group-field">
              <Field
                name="search_date"
                component={renderDateTimePickerDailyField}
                timeFormat={false}
                isValidDate={this.state.isValidDate}
                disabled={this.state.isDateDisabled}
              />
              <div className="form__form-group-icon">
                <TimetableIcon />
              </div>
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Button color="primary" type="submit">{literals.tables.ag_table.search}</Button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector('chart_market_impact_search_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    login: selector(state, 'login'),
    criteria: selector(state, 'criteria'),
    search_values: getFormValues('chart_market_impact_search_form')(state),
    initialValues: {
      login: { label: 'ALL', value: 'ALL' },
      symbol: { label: 'ALL', value: 'ALL' },
      criteria: { label: 'ALL', value: 'ALL' },
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'chart_market_impact_search_form', // a unique identifier for this form
  destroyOnUnmount: false,
})(ChartMarketImpactSearchForm));
