import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clientValidate from './clientValidate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error }
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false
};

class ClientForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    state: PropTypes.instanceOf(Object).isRequired
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, literals } = this.props;
    const disabled = false;
    const { submit } = literals.forms.client_form;

    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.client_form.name}
            </span>
            <div className="form__form-group-field">
              <Field
                name="name"
                component={renderField}
                type="text"
                placeholder={literals.forms.client_form.name}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.client_form.company_name}
            </span>
            <div className="form__form-group-field">
              <Field
                name="company_name"
                component={renderField}
                type="text"
                placeholder={literals.forms.client_form.company_name}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.client_form.zmq_address}
            </span>
            <div className="form__form-group-field">
              <Field
                name="zmq_address"
                component={renderField}
                type="text"
                placeholder={literals.forms.client_form.zmq_address}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.client_form.server_address}
            </span>
            <div className="form__form-group-field">
              <Field
                name="server_address"
                component={renderField}
                type="text"
                placeholder={literals.forms.client_form.server_address}
                disabled={disabled}
              />
            </div>
          </div>

          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">
              {submit}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => this.props.state.toggle('1')}
            >
              {literals.forms.client_form.cancel}
            </Button>
            <Button
              type="button"
              onClick={reset}
              disabled={pristine || submitting}
            >
              {literals.forms.client_form.reset}
            </Button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.state) {
    if (ownProps.state.detail) {
      return {
        literals: state.literals,
        initialValues: {
          name: ownProps.state.detail.name,
          server_address: ownProps.state.detail.server_address,
          zmq_address: ownProps.state.detail.zmq_address,
          stateMode: ownProps.state.mode
        }
      };
    }
    return {
      literals: state.literals,
      initialValues: {
        stateMode: ownProps.state.mode
      }
    };
  }
  return { literals: state.literals };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'client_form', // a unique identifier for this form
    validate: clientValidate
  })(ClientForm)
);
