import React, { PureComponent } from 'react';
import { inject } from 'mobx-react';
import ChangePasswordForm from './form';
import changePasswordAction from './action';

@inject('rootStore')
class ChangePassword extends PureComponent {
  render() {
    return (
      <ChangePasswordForm
        {...this.props}
        onSubmit={changePasswordAction}
      />
    );
  }
}
export default ChangePassword;
