/* eslint-disable no-param-reassign */
/* eslint-disable  consistent-return */
/* eslint-disable no-shadow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import moment from 'moment';
import FileDownload from 'js-file-download';
import * as csv from 'csvtojson';
import { parseAsync } from 'json2csv';
import _ from 'lodash';
import FitToPageIcon from 'mdi-react/FitToPageIcon';
import ResizeIcon from 'mdi-react/ResizeIcon';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import TakerNotionalSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import Loader from '../../shared/components/Loader';
import { numberFormatter, onColumnResized, serverSideDatasourceGetRows } from '../../shared/helper';

const PAGE_SIZE = 50;

class MakerSymbolStatusReport extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
  };
  constructor(props) {
    super(props);
    const tableID = 'sidebar_maker_symbol_status_report';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    const { literals } = props;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.maker_symbol_status_table.shared_id,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.shared_id'),
          field: 'shared_id',
          headerTooltip: literals.tables.maker_symbol_status_table.shared_id,
          type: 'text',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.symbol_val,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.symbol_val'),
          field: 'symbol_val',
          headerTooltip: literals.tables.maker_symbol_status_table.symbol_val,
          type: 'text',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.time_ms_value,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.time_ms_value'),
          field: 'time_ms_value',
          headerTooltip: literals.tables.maker_symbol_status_table.time_ms_value,
          suppressMenu: true,
        },
        {
          headerName: literals.tables.maker_symbol_status_table.sub_id,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.sub_id'),
          field: 'sub_id',
          headerTooltip: literals.tables.maker_symbol_status_table.sub_id,
          hide: true,
          type: 'text',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.subscribed_value,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.subscribed_value'),
          field: 'subscribed_value',
          headerTooltip: literals.tables.maker_symbol_status_table.subscribed_value,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: [],
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.ticks_count,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.ticks_count'),
          field: 'ticks_count',
          headerTooltip: literals.tables.maker_symbol_status_table.ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_spread,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.avg_spread'),
          field: 'avg_spread',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_spread,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_spread_points,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.avg_spread_points'),
          field: 'avg_spread_points',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_spread_points,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.spread_ticks_count,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.spread_ticks_count'),
          field: 'spread_ticks_count',
          headerTooltip: literals.tables.maker_symbol_status_table.spread_ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.delayed_ticks_count,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.delayed_ticks_count'),
          field: 'delayed_ticks_count',
          headerTooltip: literals.tables.maker_symbol_status_table.delayed_ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName:
          literals.tables.maker_symbol_status_table.skipped_ticks_count,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.maker_symbol_status_table.skipped_ticks_count'),
          field: 'skipped_ticks_count',
          headerTooltip:
          literals.tables.maker_symbol_status_table.skipped_ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName:
          literals.tables.maker_symbol_status_table.consumed_ticks_count,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.maker_symbol_status_table.consumed_ticks_count'),
          field: 'consumed_ticks_count',
          headerTooltip:
          literals.tables.maker_symbol_status_table.consumed_ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.orders_count,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.orders_count'),
          field: 'orders_count',
          headerTooltip: literals.tables.maker_symbol_status_table.orders_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.long_orders,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.long_orders'),
          field: 'long_orders',
          headerTooltip: literals.tables.maker_symbol_status_table.long_orders,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.short_orders,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.short_orders'),
          field: 'short_orders',
          headerTooltip: literals.tables.maker_symbol_status_table.short_orders,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_fill_time,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.avg_fill_time'),
          field: 'avg_fill_time',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_fill_time,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_travel_time,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.avg_travel_time'),
          field: 'avg_travel_time',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_travel_time,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.rejected_count,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.rejected_count'),
          field: 'rejected_count',
          headerTooltip: literals.tables.maker_symbol_status_table.rejected_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.partial_fills_count,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.partial_fills_count'),
          field: 'partial_fills_count',
          headerTooltip: literals.tables.maker_symbol_status_table.partial_fills_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.fully_fills_count,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.fully_fills_count'),
          field: 'fully_fills_count',
          headerTooltip: literals.tables.maker_symbol_status_table.fully_fills_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.total_fill_volume,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.total_fill_volume'),
          field: 'total_fill_volume_value',
          headerTooltip: literals.tables.maker_symbol_status_table.total_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.total_volume_value,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.total_volume_value'),
          field: 'total_volume_value',
          headerTooltip: literals.tables.maker_symbol_status_table.total_volume_value,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_slippage,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.avg_slippage'),
          field: 'avg_slippage',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_slippage,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_slippage_points,
          headerValueGetter: () => this.localizeHeader('literals.tables.maker_symbol_status_table.avg_slippage_points'),
          field: 'avg_slippage_points',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_slippage_points,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          hide: true,
          type: 'number',
        },
      ],
      collapse: '',
      defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: ['filterMenuTab'],
        suppressMovable: true,
      },
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter',
        },
      },
      rowModelType: 'serverSide',
      paginationPageSize: PAGE_SIZE,
      cacheBlockSize: PAGE_SIZE,
      loaderShow: false,
      resize: 'fit',
      rowData: [],
      rowCount: 0,
      gridColumns: [],
      gridDisplayedColumns: [],
      dataAfterFilter: [],
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (literals.forms.security_form.name !== prevProps.literals.forms.security_form.name) {
        this.gridApi.refreshHeader();
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const columns = this.gridColumnApi.getAllColumns().map(col => ({
      title: col.colDef.headerName,
      value: col.colDef.field,
    }));
    const displayedColumns = this.gridColumnApi.getAllDisplayedColumns().map(col => col.colDef.field);
    this.setState({ gridColumns: columns });
    this.setState({ gridDisplayedColumns: displayedColumns });
    this.gridApi.refreshHeader();
  }

  onColumnResized=(params) => {
    onColumnResized(params, this.myGridID);
  };

  onGridSizeChanged=() => {
    if (this.state.resize === 'fit') {
      this.sizeToFit();
    } else {
      this.autoSizeAll();
    }
  };

  onBtExport=(type) => {
    this.showLoader();
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
    const headers = [];
    this.gridColumnApi.getAllDisplayedColumns().forEach((col) => {
      headers.push({
        label: col.colDef.headerName,
        value: col.colDef.field,
      });
    });
    let fileName = 'MakerSymbolStatusReport';
    parseAsync(this.state.dataAfterFilter, { fields: headers })
      .then((csv) => {
        const formData = new FormData();
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        formData.append('file', blob);
        formData.append('export_type', type);
        formData.append('report_name', fileName);
        axios.post(
          `${process.env.REACT_APP_BACKEND_API}/report/export_report`,
          formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
            responseType: 'blob',
          },
        ).then((response) => {
          if (type === 1) {
            fileName = `${fileName}.xlsx`;
          } else {
            fileName = `${fileName}.csv`;
          }
          FileDownload(response.data, fileName);
          this.hideLoader();
        });
      }).catch(() => {
        this.hideLoader();
      });
  };

  setGridFilterValues=(response) => {
    this.gridApi.getFilterInstance('subscribed_value')
      .setFilterValues(response.data.subscribed.map(a => a.label) || []);
  }

  handleSubmit=(values) => {
    this.showLoader();
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
    this.gridColumnApi.setColumnsVisible(this.gridColumnApi.getAllDisplayedColumns(), false);
    this.gridColumnApi.setColumnsVisible(values.grid_columns, true);
    const dataToSend = this.filtersToSend(values);
    axios.post(`${process.env.REACT_APP_BACKEND_API}/report/maker_symbol_status`, JSON.stringify(dataToSend))
      .then((response) => {
        csv({ checkType: true })
          .fromString(response.data)
          .then((jsonObj) => {
            this.setState({ rowData: jsonObj });
            this.gridApi.setServerSideDatasource(this.ServerSideDatasource());
          }).on('done', () => {
            this.hideLoader();
          });
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  filtersToSend = (values) => {
    const dataToSend = {
      start_date: values.start_date ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss')
        .valueOf()
        : values.start_date,
      end_date: values.end_date ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss')
        .valueOf()
        : values.end_date,
      shared_id: values.shared_id,
      symbol_val: values.symbol_val,
      displayed_columns: this.gridColumnApi.getAllDisplayedColumns().map(col => col.colDef.field),
    };
    return dataToSend;
  };

  ServerSideDatasource=() => ({
    getRows: (params) => {
      const response = serverSideDatasourceGetRows(params, this.state.rowData);
      const {
        allRows, success, rows, lastRow,
      } = response;
      this.setState({ dataAfterFilter: allRows });
      this.setState({ rowCount: allRows.length });
      if (success) {
        // supply rows for requested block to grid
        params.successCallback(rows, lastRow);
      } else {
        params.failCallback();
      }
    },
  })

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  sizeToFit = () => {
    this.setState({ resize: 'fit' });
    this.gridApi.sizeColumnsToFit();
  };

  autoSizeAll = () => {
    this.setState({ resize: 'auto' });
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
  };

  localizeHeader=header => _.get(this.props, header)

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <Collapse
              title={literals.tables.ag_table.toggle_search}
              className="with-shadow"
              collapse={this.state.collapse}
            >
              <TakerNotionalSearchForm
                onSubmit={this.handleSubmit}
                showLoader={this.showLoader}
                hideLoader={this.hideLoader}
                gridColumns={this.state.gridColumns}
                gridDisplayedColumns={this.state.gridDisplayedColumns}
                setGridFilterValues={this.setGridFilterValues}
                onBtExport={this.onBtExport}
              />
            </Collapse>
            <div className="outer-grid-label">
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.maker_symbol_status}
                </h5>
              </div> {literals.tables.ag_table.total_records}: {this.state.rowCount}
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip
                  arrow
                  arrowSize="small"
                  size="small"
                  theme="light"
                  title={literals.tables.ag_table.size_to_fit}
                >
                  <button className="topbar__btn btnIcon" onClick={() => this.sizeToFit()}>
                    <FitToPageIcon className="topbar__icon" />
                  </button>
                </Tooltip>
                <Tooltip
                  arrow
                  arrowSize="small"
                  size="small"
                  theme="light"
                  title={literals.tables.ag_table.auto_size_all}
                >
                  <button className="topbar__btn btnIcon" onClick={() => this.autoSizeAll()}>
                    <ResizeIcon className="topbar__icon" />
                  </button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
              <div
                id={this.myGridID}
                style={{
                  boxSizing: 'border-box',
                  height: '85vh',
                  width: '100%',
                }}
                className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.columnDefs}
                  suppressCellSelection
                  suppressContextMenu
                  defaultColDef={this.state.defaultColDef}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.onGridSizeChanged}
                  rowHeight={20}
                  columnTypes={this.state.columnTypes}
                  rowModelType={this.state.rowModelType}
                  pagination
                  paginationPageSize={this.state.paginationPageSize}
                  cacheBlockSize={this.state.cacheBlockSize}
                  onColumnResized={this.onColumnResized}
                  debounceVerticalScrollbar
                  groupHeaderHeight={25}
                  serverSideStoreType="partial"
                />
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(MakerSymbolStatusReport);
