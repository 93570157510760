/* eslint-disable no-alert */
/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 2,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
};

export default (async function showResults(values) {
  if (values.toggle) {
    values.toggle();
  }
  const data = {
    symbol_val: values.symbol,
    maker_session: values.makerSession,
    bid: parseFloat(values.bid),
    bid_vol: values.bid_vol,
    ask: parseFloat(values.ask),
    ask_vol: values.ask_vol,
  };
  axios.post(`${process.env.REACT_APP_BACKEND_API}/trading/push_ticks`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          values.literals.sidebar.push_ticks, 'primary',
          values.literals.tables.trading_table.push_ticks_created,
        );
      } else {
        showNotification(
          values.literals.sidebar.push_ticks, 'danger',
          values.literals.tables.trading_table.push_ticks_failed,
        );
      }
    })
    .catch((error) => {
      showNotification(values.literals.sidebar.push_ticks, 'danger', error.message);
    });
});
