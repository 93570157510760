import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderTreeSelectField from './TreeSelect';
import renderInputNumberField from '../../shared/components/form/NumberInputCommon';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import giveUpRuleValidate from './validate';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class GiveUpRuleForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      takers_pattern: PropTypes.bool,
      tems_pattern: PropTypes.bool,
      securities_pattern: PropTypes.bool,
      symbols_pattern: PropTypes.bool,
      ratio_custom: PropTypes.bool,
      gur_mode: PropTypes.instanceOf(Object),
      change: PropTypes.func.isRequired,
    };
    static defaultProps = {
      takers_pattern: false,
      tems_pattern: false,
      securities_pattern: false,
      symbols_pattern: false,
      ratio_custom: false,
      gur_mode: { label: 'ALL', value: 0 },
    }

    onGurModeChange=(gurMode) => {
      if (gurMode.value !== 1) {
        this.props.change('makers', ['*,']);
      }
    }

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals, theme,
      } = this.props;
      const disabled = false;
      const { submit } = literals.forms.give_up_rule_form;
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.id}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="id"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  precision={0}
                  placeholder={literals.forms.give_up_rule_form.id}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.takers}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="takers_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.takers_pattern &&
                <Field
                  name="takers_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Taker}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
                }
                {this.props.takers_pattern &&
                <Field
                  name="takers_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.give_up_rule_form.takers}
                />
                }
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.tems}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="tems_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.tems_pattern &&
                <Field
                  name="tems_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.TakerExecutionModel}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
                }
                {this.props.tems_pattern &&
                <Field
                  name="tems_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.give_up_rule_form.tems}
                />
                }
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.securities}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="securities_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.securities_pattern &&
                <Field
                  name="securities_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Security}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
                }
                {this.props.securities_pattern &&
                <Field
                  name="securities_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.give_up_rule_form.securities}
                />
                }
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.symbols}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="symbols_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.symbols_pattern &&
                <Field
                  name="symbols_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Symbol}
                  treeDefaultExpandAll={false}
                  treeDefaultExpandedKeys={['*,']}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
                }
                {this.props.symbols_pattern &&
                <Field
                  name="symbols_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.give_up_rule_form.symbols}
                />
                }
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.sides}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="sides"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.Side}
                  placeholder={literals.forms.give_up_rule_form.sides}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.source_extgroup}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="source_extgroup_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                    defaultChecked
                    disabled
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                <Field
                  name="source_extgroup"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.give_up_rule_form.source_extgroup}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.source_extlogin}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="source_extlogin"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  precision={0}
                  placeholder={literals.forms.give_up_rule_form.source_extlogin}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="borderValuesForm">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.give_up_rule_form.target_taker}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="target_taker"
                    component={renderSelectField}
                    type="text"
                    options={this.props.state.formData.TargetTaker}
                    placeholder={literals.forms.give_up_rule_form.sides}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.give_up_rule_form.target_login}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="target_login"
                    component={renderInputNumberField}
                    type="number"
                    min={0}
                    precision={0}
                    placeholder={literals.forms.give_up_rule_form.target_login}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.orig_markup}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="orig_markup"
                  component={renderCheckBoxField}
                  label={literals.forms.give_up_rule_form.orig_markup}
                  disabled={disabled}
                  defaultChecked
                />
              </div>
            </div>
            <div className="borderValuesForm">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.give_up_rule_form.markup_model}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="markup_model"
                    component={renderSelectField}
                    type="text"
                    options={this.props.state.formData.MarkupModel}
                    placeholder={literals.forms.give_up_rule_form.markup_model}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.give_up_rule_form.ratio}
                </span>
                <div className="form__form-group-field">
                  <span className="marginLeftAuto">
                    <Field
                      name="ratio_custom"
                      component={renderCheckBoxField}
                      label={literals.forms.give_up_rule_form.custom}
                    />
                  </span>
                </div>
                <span
                  className="form__form-group-label"
                />
                <div className="form__form-group-field">
                  <span style={{ color: 'orange' }}>
                    {literals.forms.give_up_rule_form.ratio_note}
                  </span>
                  {!this.props.ratio_custom &&
                  <Field
                    name="ratio"
                    component={renderSelectField}
                    type="text"
                    options={this.props.state.formData.Ratio}
                    placeholder={literals.forms.give_up_rule_form.ratio}
                    disabled={disabled}
                  />
                  }
                  {this.props.ratio_custom &&
                  <Field
                    name="ratio_text"
                    component={renderInputNumberField}
                    type="number"
                    min={-10000}
                    max={10000}
                    precision={0}
                    step={5}
                    placeholder={literals.forms.give_up_rule_form.ratio}
                    disabled={disabled}
                  />
                  }
                </div>
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.give_up_rule_form.gur_mode}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="gur_mode"
                    component={renderSelectField}
                    type="text"
                    options={this.props.state.formData.GurMode}
                    placeholder={literals.forms.give_up_rule_form.gur_mode_value}
                    disabled={disabled}
                    onChange={this.onGurModeChange}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.filtration_pool_form.makers}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="makers"
                    component={renderTreeSelectField}
                    treeData={this.props.state.formData.Maker}
                    treeDefaultExpandAll={false}
                    treeDefaultExpandedKeys={['*,']}
                    className={theme.className === 'theme-light' ?
                      'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                    dropdownClassName={theme.className === 'theme-light' ?
                      'rc-tree-select-dropdown-light' :
                      'rc-tree-select-dropdown-dark'}
                    disabled={this.props.gur_mode.value !== 1}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.give_up_rule_form.gur_stl_mode}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="gur_stl_mode"
                    component={renderSelectField}
                    type="text"
                    options={this.props.state.formData.GurSTLMode}
                    placeholder={literals.forms.give_up_rule_form.gur_mode_value}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.priority}
              </span>
              <div className="form__form-group-field">
                <span style={{ color: 'orange' }}>
                  {literals.forms.reverse_trade_copier_form.priority_note}
                </span>
                <Field
                  name="priority"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.Priority}
                  placeholder={literals.forms.give_up_rule_form.priority}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.symbol_format}
              </span>
              <div className="form__form-group-field">
                <span style={{ color: 'orange' }}>
                  {literals.forms.reverse_trade_copier_form.symbol_format_note}
                </span>
                <Field
                  name="symbol_format_prefix"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.SymbolFormatPrefix}
                  placeholder={literals.forms.give_up_rule_form.priority}
                  disabled={disabled}
                  style={{ width: '50%', paddingRight: 10 }}
                />
                <Field
                  name="symbol_format"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.reverse_trade_copier_form.optional_string}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.give_up_rule_form.description}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.give_up_rule_form.state}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="state"
                  component={renderCheckBoxField}
                  label={literals.forms.give_up_rule_form.state}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.give_up_rule_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.give_up_rule_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('give_up_rule_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    takers_pattern: selector(state, 'takers_pattern'),
    tems_pattern: selector(state, 'tems_pattern'),
    securities_pattern: selector(state, 'securities_pattern'),
    symbols_pattern: selector(state, 'symbols_pattern'),
    ratio_custom: selector(state, 'ratio_custom'),
    gur_mode: selector(state, 'gur_mode'),
    initialValues: {
      id: 0,
      takers_text: '*,',
      tems_text: '*,',
      target_taker: { label: 'Select Value', value: '' },
      securities_text: '*,',
      symbols_text: '*,',
      sides: { label: '*,', value: 0 },
      source_extlogin: 0,
      target_login: 0,
      gur_mode: { label: 'ALL', value: 0 },
      gur_stl_mode: { label: 'NONE', value: 0 },
      ratio: { label: '100% Volume Ratio', value: 1.0 },
      ratio_text: 100,
      orig_markup: true,
      markup_model: { label: 'Select Value', value: '' },
      symbol_format_prefix: { label: '#ts#', value: '#ts#' },
      symbol_format: '',
      priority: { label: '1', value: '1' },
      state: false,
      source_extgroup_pattern: true,
      source_extgroup: '*,',
    },
  };
}


export default connect(mapStateToProps)(reduxForm({
  form: 'give_up_rule_form', // a unique identifier for this form
  validate: giveUpRuleValidate,
})(GiveUpRuleForm));
