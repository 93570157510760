/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OTValidate from './validateOT';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input, placeholder, type, disabled, step, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class OpenTradeCloseForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      change: PropTypes.func.isRequired,
    };

    componentDidMount() {
      this.props.change('vol', this.props.state.node.data.vol_value);
      this.props.change('price', this.props.state.node.data.price);
      this.props.change('closeOID', '');
      this.props.change('recalcPos', true);
    }

    render() {
      const {
        handleSubmit, literals,
      } = this.props;
      const disabled = false;
      const { submit } = literals.forms.uni_symbol_form;
      return (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <span
                className="form__form-group-label"
                style={{ width: '25%' }}
              >{literals.tables.open_trade_table.vol}
              </span>
              <Field
                name="vol"
                component={renderField}
                type="number"
                step="any"
                placeholder={literals.tables.open_trade_table.vol}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <span
                className="form__form-group-label"
                style={{ width: '25%' }}
              >{literals.tables.open_trade_table.price}
              </span>
              <Field
                name="price"
                component={renderField}
                type="number"
                step="any"
                placeholder={literals.tables.open_trade_table.price}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <span
                className="form__form-group-label"
                style={{ width: '25%' }}
              >{literals.tables.open_trade_table.closeOID}
              </span>
              <Field
                name="closeOID"
                component={renderField}
                type="text"
                placeholder={literals.tables.open_trade_table.closeOID}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <span
                className="form__form-group-label"
                style={{ width: '25%' }}
              >{literals.tables.open_trade_table.recalcPos}
              </span>
              <Field
                name="recalcPos"
                component={renderCheckBoxField}
                label={literals.tables.order_table.recalcPos}
                defaultChecked
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar form__button-toolbar-remove-top-padding">
            <Button color="primary" type="submit" >{submit}</Button>
            <Button
              color="primary"
              type="button"
              onClick={() => this.props.state.toggle()}
            >{literals.forms.aggregator_restart_form.cancel}
            </Button>
          </ButtonToolbar>
        </form>
      );
    }
}
function mapStateToProps(state) {
  return {
    literals: state.literals,
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'ot_close_form', // a unique identifier for this form
  validate: OTValidate,
})(OpenTradeCloseForm));
