/* eslint-disable no-return-assign */
/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  ButtonToolbar,
  Modal,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import ReactDOM from 'react-dom';
import { getFormValues } from 'redux-form';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import OpenOrderUpdateForm from './openOrderUpdateForm';

@inject('rootStore')
class OpenOrderActionRenderer extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    search_values: PropTypes.instanceOf(Object),
    context: PropTypes.instanceOf(Object).isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  static defaultProps = {
    search_values: {},
  };
  constructor(props) {
    super(props);
    let notification = null;
    NotificationSystem.newInstance({}, n => (notification = n));
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: (
          <BasicNotification title={title} message={message} color={color} />
        ),
        duration: 3,
        closable: true,
        style: { top: 0 },
        className: 'left-up',
      });
    };
  }
  state = {
    popoverOpenDelete: false,
    popoverOpenUpdate: false,
  };

  showPopoverDelete = () => {
    window.scrollTo(0, document.body.scrollHeight);
    this.setState({
      popoverOpenDelete: true,
    });
  };

  hidePopoverDelete = () => {
    this.setState({
      popoverOpenDelete: false,
    });
  };

  toggleUpdate = () => {
    window.scrollTo(0, document.body.scrollHeight);
    this.setState({
      popoverOpenUpdate: !this.state.popoverOpenUpdate,
    });
  };

  handleDeleteAction = () => {
    const { oneClickTrading } = this.props.rootStore.reportStore.reportState;
    if (oneClickTrading) {
      this.handleSubmitDelete();
    } else {
      this.showPopoverDelete();
    }
  };

  handleSubmitDelete = () => {
    this.hidePopoverDelete();
    const { id } = this.props.node.data;
    const taker = this.props.search_values.node
      ? this.props.search_values.node.value
      : '';
    const tem = this.props.search_values.account
      ? this.props.search_values.account.value
      : '';
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/trading/delete_open_order?id=${id}&taker=${taker}&tem=${tem}`)
      .then((response) => {
        if (response.data.status) {
          this.showNotification(
            this.props.literals.tables.open_order_table.delete_open_order,
            'primary',
            this.props.literals.forms.success_message,
          );
        } else {
          this.showNotification(
            this.props.literals.tables.open_order_table.delete_open_order,
            'danger',
            this.props.literals.forms.fail_message,
          );
        }
      })
      .catch((error) => {
        this.showNotification(
          this.props.literals.tables.open_order_table.delete_open_order,
          'danger',
          error.message,
        );
      });
  };

  handleSubmitUpdate = (values) => {
    const data = {
      id: this.props.node.data.id,
      taker: this.props.search_values.node
        ? this.props.search_values.node.value
        : '',
      account: this.props.search_values.account
        ? this.props.search_values.account.value
        : '',
      vol_value: parseFloat(values.quantity),
      price: parseFloat(values.price),
      ord_state: parseInt(values.ord_state.value, 10),
      tif: parseInt(values.tif.value, 10),
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/trading/update_open_order`,
        JSON.stringify(data),
      )
      .then((response) => {
        if (response.data.status) {
          this.showNotification(
            this.props.literals.tables.open_order_table.update_open_order,
            'primary',
            this.props.literals.forms.success_message,
          );
        } else {
          this.showNotification(
            this.props.literals.tables.open_order_table.update_open_order,
            'danger',
            this.props.literals.forms.fail_message,
          );
        }
        this.toggleUpdate();
      })
      .catch((error) => {
        this.showNotification(
          this.props.literals.tables.open_order_table.update_open_order,
          'danger',
          error.message,
        );
        this.toggleUpdate();
      });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    let btnDeleteRef = null;
    return (
      <span>
        <ButtonToolbar>
          <Tooltip title={literals.tables.ag_table.delete} position="left">
            <Button
              style={{ padding: '4px 4px' }}
              ref={(c) => {
                btnDeleteRef = c && ReactDOM.findDOMNode(c);
              }}
              onClick={this.handleDeleteAction}
              className="icon"
              color="primary"
            >
              <p className="far fa-trash-alt" />
            </Button>
          </Tooltip>
          <Tooltip title={literals.tables.ag_table.update} position="left">
            <Button
              style={{ padding: '4px 4px' }}
              onClick={this.toggleUpdate}
              className="icon"
              color="primary"
            >
              <p className="fas fa-pencil-alt" />
            </Button>
          </Tooltip>
        </ButtonToolbar>
        <Popover
          placement="top"
          isOpen={this.state.popoverOpenDelete}
          target={() => btnDeleteRef}
          className={
            theme === 'theme-light'
              ? `trading-light ${theme}`
              : `trading-dark  ${theme}`
          }
        >
          <PopoverHeader>
            {literals.tables.open_order_table.confirm_delete_open_order}
          </PopoverHeader>
          <PopoverBody>
            <span>
              {`${literals.tables.open_order_table.confirm_delete_open_order_message} 
              ${literals.tables.open_order_table.id} ${this.props.node.data.id} ?`}
            </span>
            <ButtonToolbar className="btn-toolbar--center">
              <Button className="btn btn-blue" onClick={this.hidePopoverDelete}>
                {literals.forms.cancel}
              </Button>
              <Button
                className="btn btn-blue"
                onClick={this.handleSubmitDelete}
              >
                {literals.forms.confirm}
              </Button>
            </ButtonToolbar>
          </PopoverBody>
        </Popover>
        <Modal
          isOpen={this.state.popoverOpenUpdate}
          toggle={() => this.toggleUpdate}
          className={`modal-dialog--primary modal-dialog--header ${theme}`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              onClick={() => this.toggleUpdate()}
            />
            <h5 className="bold-text  modal__title">
              {`${literals.tables.open_order_table.update_open_order} ${this.props.node.data.id}`}
            </h5>
          </div>
          <div className="modal__body">
            <OpenOrderUpdateForm
              node={this.props.node}
              toggleUpdate={this.toggleUpdate}
              onSubmit={this.handleSubmitUpdate}
              formData={this.props.context.formData}
            />
          </div>
        </Modal>
      </span>
    );
  }
}

export default connect(state => ({
  literals: state.literals,
  theme: state.theme,
  search_values: getFormValues('trading_search_form')(state),
}))(withRouter(OpenOrderActionRenderer));
