/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import SessionEdit from './SessionEdit';
import SessionEditForm from './SessionEditForm';
import { LiteralProps } from '../../prop-types/ReducerProps';

class SessionRenderer extends PureComponent {
    static propTypes = {
      context: PropTypes.instanceOf(Object).isRequired,
      value: PropTypes.string.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
      literals: LiteralProps.isRequired,
    };
    state = {
      sessionModalShow: false,
      selectedOption: 'session_interface',
    };
    toggleModalSession = () => {
      this.setState({ sessionModalShow: !this.state.sessionModalShow });
    };
    showModalSession = () => {
      if (!this.state.sessionModalShow) {
        this.setState({ sessionModalShow: true });
      }
    };
    submitSession=(session) => {
      const rowNode = this.props.node;
      rowNode.setDataValue(this.props.colDef.field, session);
      this.toggleModalSession();
    };
    resetSession=() => {
      this.toggleModalSession();
    };
    handleChange = (selectedOption) => {
      this.setState({ selectedOption: selectedOption.target.value });
    };
    render() {
      const theme = this.props.context.theme.className;
      const { selectedOption } = this.state;
      return (
        <div onDoubleClick={this.showModalSession}>
          <label>
            {this.props.value}
          </label>
          <Modal
            isOpen={this.state.sessionModalShow}
            toggle={() => this.toggleModalSession}
            className={`modal-dialog--primary modal-dialog--header crp-modal session-modal ${theme}`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleModalSession()} />
              <h4 className="bold-text  modal__title">Sessions</h4>
            </div>
            <div className="modal__body risk_account_modal_body">
              <label
                className="radio-btn"
              >
                <input
                  className="radio-btn__radio"
                  type="radio"
                  name="session_edit"
                  value="session_interface"
                  checked={selectedOption === 'session_interface'}
                  onChange={this.handleChange}
                />
                <span className="radio-btn__radio-custom" />
                <span className="radio-btn__label">
                  {this.props.literals.tables.ag_table.interface}
                </span>
              </label>
              <label
                className="radio-btn"
              >
                <input
                  className="radio-btn__radio"
                  type="radio"
                  name="session_edit"
                  value="session_text"
                  checked={selectedOption === 'session_text'}
                  onChange={this.handleChange}
                />
                <span className="radio-btn__radio-custom" />
                <span className="radio-btn__label">
                  {this.props.literals.tables.ag_table.free_text}
                </span>
              </label>
              {this.state.selectedOption === 'session_text' &&
              <SessionEditForm
                sessions={this.props.value}
                initialSessionValue={this.props.value}
                submitSession={this.submitSession}
                resetSession={this.resetSession}
              />
              }
              {this.state.selectedOption === 'session_interface' &&
              <SessionEdit
                sessions={this.props.value}
                initialSessionValue={this.props.value}
                submitSession={this.submitSession}
                resetSession={this.resetSession}
              />
              }
            </div>
            <div className="modal__footer" />
          </Modal>
        </div>
      );
    }
}
export default connect(state => ({
  literals: state.literals,
}))(SessionRenderer);
