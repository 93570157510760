import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderTreeSelectField from './TreeSelect';
import renderInputNumberField from '../../shared/components/form/NumberInputCommon';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import ValuesForm from './valuesForm';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error }
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false
};

class MakerAPILinkForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    state: PropTypes.instanceOf(Object).isRequired,
    valuesData: PropTypes.instanceOf(Array).isRequired,
    onChangeValues: PropTypes.func.isRequired,
    takers_pattern: PropTypes.bool,
    tems_pattern: PropTypes.bool,
    hams_pattern: PropTypes.bool,
    securities_pattern: PropTypes.bool,
    shared_ids_pattern: PropTypes.bool
  };
  static defaultProps = {
    takers_pattern: false,
    tems_pattern: false,
    hams_pattern: false,
    securities_pattern: false,
    shared_ids_pattern: false
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, literals, theme } =
      this.props;
    const disabled = false;
    const { submit } = literals.forms.maker_api_link_form;
    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.id}
            </span>
            <div className="form__form-group-field">
              <Field
                name="id"
                component={renderInputNumberField}
                type="number"
                min={0}
                precision={0}
                placeholder={literals.forms.maker_api_link_form.id}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.takers}
            </span>
            <div className="form__form-group-field">
              <span className="marginLeftAuto">
                <Field
                  name="takers_pattern"
                  component={renderCheckBoxField}
                  label={literals.forms.maker_api_link_form.pattern}
                />
              </span>
            </div>
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              {!this.props.takers_pattern && (
                <Field
                  name="takers_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Taker}
                  className={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                  dropdownClassName={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                />
              )}
              {this.props.takers_pattern && (
                <Field
                  name="takers_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.maker_api_link_form.takers}
                />
              )}
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.tems}
            </span>
            <div className="form__form-group-field">
              <span className="marginLeftAuto">
                <Field
                  name="tems_pattern"
                  component={renderCheckBoxField}
                  label={literals.forms.maker_api_link_form.pattern}
                />
              </span>
            </div>
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              {!this.props.tems_pattern && (
                <Field
                  name="tems_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.TakerExecutionModel}
                  className={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                  dropdownClassName={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                />
              )}
              {this.props.tems_pattern && (
                <Field
                  name="tems_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.maker_api_link_form.tems}
                />
              )}
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.hams}
            </span>
            <div className="form__form-group-field">
              <span className="marginLeftAuto">
                <Field
                  name="hams_pattern"
                  component={renderCheckBoxField}
                  label={literals.forms.maker_api_link_form.pattern}
                />
              </span>
            </div>
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              {!this.props.hams_pattern && (
                <Field
                  name="hams_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.HubAggregationModel}
                  className={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                  dropdownClassName={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                />
              )}
              {this.props.hams_pattern && (
                <Field
                  name="hams_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.maker_api_link_form.hams}
                />
              )}
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.securities}
            </span>
            <div className="form__form-group-field">
              <span className="marginLeftAuto">
                <Field
                  name="securities_pattern"
                  component={renderCheckBoxField}
                  label={literals.forms.maker_api_link_form.pattern}
                />
              </span>
            </div>
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              {!this.props.securities_pattern && (
                <Field
                  name="securities_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Security}
                  className={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                  dropdownClassName={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                />
              )}
              {this.props.securities_pattern && (
                <Field
                  name="securities_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.maker_api_link_form.securities}
                />
              )}
            </div>
          </div>
        </div>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.symbols}
            </span>
            <div className="form__form-group-field">
              <Field
                name="symbols_tree"
                component={renderTreeSelectField}
                treeData={this.props.state.formData.Symbol}
                treeDefaultExpandAll={false}
                treeDefaultExpandedKeys={['*,']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.shared_ids}
            </span>
            <div className="form__form-group-field">
              <span className="marginLeftAuto">
                <Field
                  name="shared_ids_pattern"
                  component={renderCheckBoxField}
                  label={literals.forms.maker_api_link_form.pattern}
                />
              </span>
            </div>
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              {!this.props.shared_ids_pattern && (
                <Field
                  name="shared_ids_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.SharedID}
                  className={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                  dropdownClassName={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                />
              )}
              {this.props.shared_ids_pattern && (
                <Field
                  name="shared_ids_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.maker_api_link_form.shared_ids}
                />
              )}
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.sides}
            </span>
            <div className="form__form-group-field">
              <Field
                name="sides"
                component={renderSelectField}
                type="text"
                options={this.props.state.formData.Side}
                placeholder={literals.forms.maker_api_link_form.sides}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.ord_types}
            </span>
            <div className="form__form-group-field">
              <Field
                name="ord_types"
                component={renderSelectField}
                type="text"
                options={this.props.state.formData.OrdType}
                placeholder={literals.forms.maker_api_link_form.ord_types}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.priority}
            </span>
            <div className="form__form-group-field">
              <span style={{ color: 'orange' }}>
                {literals.forms.reverse_trade_copier_form.priority_note}
              </span>
              <Field
                name="priority"
                component={renderSelectField}
                type="text"
                options={this.props.state.formData.Priority}
                placeholder={literals.forms.maker_api_link_form.priority}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.maker_api_link_form.state}
            </span>
            <div className="form__form-group-field">
              <Field
                name="state"
                component={renderCheckBoxField}
                label={literals.forms.maker_api_link_form.state}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.security_form.description}
            </span>
            <div className="form__form-group-field">
              <Field
                name="description"
                component={renderField}
                type="text"
                placeholder={literals.forms.security_form.description}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="borderValuesForm">
            <ValuesForm
              {...this.props}
              values={this.props.valuesData}
              onChangeValues={this.props.onChangeValues}
            />
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">
              {submit}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => this.props.state.toggle('1')}
            >
              {literals.forms.maker_api_link_form.cancel}
            </Button>
            <Button
              type="button"
              onClick={reset}
              disabled={pristine || submitting}
            >
              {literals.forms.maker_api_link_form.reset}
            </Button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector('maker_api_link_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    takers_pattern: selector(state, 'takers_pattern'),
    tems_pattern: selector(state, 'tems_pattern'),
    hams_pattern: selector(state, 'hams_pattern'),
    securities_pattern: selector(state, 'securities_pattern'),
    initialValues: {
      id: 0,
      takers_text: '*,',
      tems_text: '*,',
      hams_text: '*,',
      securities_text: '*,',
      shared_ids_text: '*,',
      sides: { label: '*,', value: 0 },
      ord_types: { label: '*,', value: 0 },
      priority: { label: '1', value: '1' },
      state: false
    }
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'maker_api_link_form' // a unique identifier for this form
  })(MakerAPILinkForm)
);
