/* eslint-disable arrow-parens */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import cssAnimation from 'css-animation';
import Websocket from 'react-websocket';
import ReactExport from 'react-data-export';
import Dialog from 'rc-dialog';
import _ from 'lodash';
import { WithContext as ReactTags } from 'react-tag-input';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import Loader from '../../shared/components/Loader';
import { getFileExtension } from '../../shared/helper';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

function animate(node, show, done) {
  let height = node.offsetHeight;
  return cssAnimation(node, 'collapse', {
    start() {
      if (!show) {
        node.style.height = `${node.offsetHeight}px`;
      } else {
        height = node.offsetHeight;
        node.style.height = 0;
      }
    },
    active() {
      node.style.height = `${show ? height : 0}px`;
    },
    end() {
      node.style.height = '';
      done();
    },
  });
}
const animation = {
  enter(node, done) {
    return animate(node, true, done);
  },
  leave(node, done) {
    return animate(node, false, done);
  },
};

@inject('rootStore')
class MonitoringLog extends Component {
    static propTypes = {
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.listRef = React.createRef();
      this.listOuterRef = React.createRef();
      this.listInnerRef = React.createRef();
      this.state = {
        monitoringLog: [],
        filteredMonitoringLog: [],
        start: true,
        stop: false,
        follow_tail: true,
        log_providers: [],
        selectedKey: '',
        open: false,
        file: '',
        file_name: '',
        seek: 2,
        loaderShow: false,
        tags: [],
        suggestions: [],
      };
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.handleAddition = this.handleAddition.bind(this);
    }

    componentDidMount() {
      this.onGetLogProviders();
    }

    onGetLogProviders=() => {
      this.showLoader();
      axios.get(
        `${process.env.REACT_APP_BACKEND_API}/monitoring/log_providers`,
        { headers: { 'x-forward-client': this.props.rootStore.clientStore.CC } },
      )
        .then((response) => {
          this.setState({ log_providers: response.data });
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    }

  onSelect = (selectedKeys, info) => {
    const fileExt = getFileExtension(info.node.props.title);
    const key = selectedKeys[0];
    this.setState({ monitoringLog: [] });
    this.setState({ filteredMonitoringLog: [] });
    this.setState({ start: true });
    this.setState({ stop: false });
    this.setState({ follow_tail: true });
    this.setState({ seek: 2 });
    if (info.selected) {
      if (fileExt !== 'zip' && fileExt !== 'html' && fileExt !== '') {
        const keyToSend = `${key}||${info.node.props.seek}`;
        this.setState({ selectedKey: keyToSend });
        this.setState({ seek: info.node.props.seek });
        this.sendMessage(JSON.stringify({
          key: 'monitoring_log',
          value: keyToSend,
        }));
        if (info.node.props.seek == 0 || info.node.props.seek == 2) {
          const dataToSend = {
            file_name: keyToSend,
          };
          axios.post(
            `${process.env.REACT_APP_BACKEND_API}/monitoring/tail_log_start`, JSON.stringify(dataToSend),
            { headers: { 'x-forward-client': this.props.rootStore.clientStore.CC } },
          )
            .then(() => {
            })
            .catch(() => {
            });
        }
      }
    } else if (fileExt !== 'zip') {
      this.setState({ selectedKey: '' });
      this.sendMessage(JSON.stringify({
        key: 'monitoring_log',
        value: 'exit||exit||exit',
      }));
    }
  };
  onRightClick = (info) => {
    if (info.node.props.selectable) {
      const key = info.node.props.eventKey;
      this.setState({ file: info.node.props.title });
      this.setState({ file_name: key });
      this.openModal();
    }
  };
  onStart=() => {
    this.setState({ start: !this.state.start });
    this.setState({ stop: !this.state.stop });
    this.setState({ monitoringLog: [] });
    this.setState({ filteredMonitoringLog: [] });
    if (this.state.selectedKey) {
      this.sendMessage(JSON.stringify({ key: 'monitoring_log', value: this.state.selectedKey }));
      if (this.state.seek == 0 || this.state.seek == 2) {
        const dataToSend = {
          file_name: this.state.selectedKey,
        };
        axios.post(
          `${process.env.REACT_APP_BACKEND_API}/monitoring/tail_log_start`, JSON.stringify(dataToSend),
          { headers: { 'x-forward-client': this.props.rootStore.clientStore.CC } },
        )
          .then(() => {
          })
          .catch(() => {
          });
      }
    }
  };
  onStop=() => {
    this.setState({ start: !this.state.start });
    this.setState({ stop: !this.state.stop });
    this.sendMessage(JSON.stringify({ key: 'monitoring_log', value: 'exit||exit||exit' }));
  };
  onFollowTail=() => {
    this.setState({ follow_tail: !this.state.follow_tail });
  };

  lastItemsFromArray = (array, n) => {
    if (array == null) { return []; }
    if (n == null) { return array[array.length - 1]; }
    return array.slice(Math.max(array.length - n, 0));
  };

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    if ('monitoring_log' in data) {
      if (data.monitoring_log) {
        let logs = [];
        if (data.seek_start) {
          logs = [...data.monitoring_log];
          this.setState({ monitoringLog: logs });
        } else {
          logs = [...this.lastItemsFromArray(this.state.monitoringLog, 1000), ...data.monitoring_log];
          this.setState({ monitoringLog: logs });
        }
        let filteredRows = [];
        const tagsFilter = _.map(this.state.tags, 'text');
        if (tagsFilter.length > 0) {
          filteredRows = logs.filter((a) => tagsFilter.some((b) => a.text.toLowerCase().includes(b.toLowerCase())));
        } else {
          filteredRows = logs;
        }
        this.setState({ filteredMonitoringLog: filteredRows });
        if (this.listRef.current) {
          this.listRef.current.scrollTo(this.state.follow_tail ?
            Math.max(0, this.listInnerRef.current.clientHeight -
              this.listOuterRef.current.clientHeight) : 0);
        }
      }
    }
  };
  socketOpenListener = () => {
    if (this.state.selectedKey && this.state.start) {
      this.sendMessage(JSON.stringify({ key: 'monitoring_log', value: this.state.selectedKey }));
      if (this.state.seek == 0 || this.state.seek == 2) {
        const dataToSend = {
          file_name: this.state.selectedKey,
        };
        axios.post(
          `${process.env.REACT_APP_BACKEND_API}/monitoring/tail_log_start`, JSON.stringify(dataToSend),
          { headers: { 'x-forward-client': this.props.rootStore.clientStore.CC } },
        )
          .then(() => {
          })
          .catch(() => {
          });
      }
    }
  };
  sendMessage=(message) => {
    this.refWebSocket.sendMessage(message);
  };

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }
  downloadLogFile=() => {
    this.closeModal();
    this.showLoader();
    const fileExt = getFileExtension(this.state.file);
    axios({
      url: `${process.env.REACT_APP_BACKEND_API}/monitoring/download_log`,
      data: JSON.stringify({
        file_name: this.state.file_name,
      }),
      headers: { 'x-forward-client': this.props.rootStore.clientStore.CC },
      method: 'POST',
      responseType: 'blob', // important
    }).then((response) => {
      this.hideLoader();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileExt === 'zip' || fileExt === 'html' ?
        this.state.file : `${this.state.file}.zip`);
      document.body.appendChild(link);
      link.click();
    })
      .catch(() => {
        this.hideLoader();
      });
  };

  nodeIcon=(props) => {
    let color = '#70bbfd';
    if (props.selected) {
      color = '#000';
    }
    if (props.isLeaf) {
      const fileExt = getFileExtension(props.title);
      if (fileExt === 'zip') {
        return <span className="fa fa-file-archive" style={{ color }} />;
      }
      return <span className="fa fa-file-alt" style={{ color }} />;
    }
    if (props.expanded) {
      return <span className="fa fa-folder-open" style={{ color }} />;
    }
    return <span className="fa fa-folder" style={{ color }} />;
  };

  switcherIcon = (props) => {
    if (props.isLeaf || props.children.length === 0) {
      return '';
    }
    const color = '#70bbfd';
    if (props.expanded) {
      return <span className="fa fa-caret-down" style={{ color, fontSize: 16 }} />;
    }
    return <span className="fa fa-caret-right" style={{ color, fontSize: 16 }} />;
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  Row = ({ index, style, data }) => (
    <React.Fragment>
      <div className={index % 2 ? 'ListItemOdd' : 'ListItemEven'} style={style}>
        {data[index].text}
      </div>
      <br />
    </React.Fragment>
  );
  render() {
    const { literals } = this.props;
    const { tags, suggestions } = this.state;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title card__title_custom">
              <h5 className="bold-text">{literals.sidebar.logs}
                <Tooltip
                  arrow
                  arrowSize="small"
                  size="small"
                  theme="light"
                  title={literals.tables.monitoring_table.refresh_folders_files}
                >
                  <button className="topbar__btn btnIcon" style={{ top: 4 }} onClick={() => this.onGetLogProviders()}>
                    <RefreshIcon className="topbar__icon" />
                  </button>
                </Tooltip>
              </h5>
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip title={literals.tables.ag_table.start}>
                  <Button onClick={this.onStart} className="icon" color="primary" disabled={this.state.start}>
                    <p className="fas fa-play" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.stop}>
                  <Button
                    onClick={this.onStop}
                    className="icon"
                    color="primary"
                    disabled={this.state.stop}
                  >
                    <p className="fas fa-stop" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.follow_tail}>
                  <Button
                    onClick={this.onFollowTail}
                    className="icon btn-primary-follow-tail"
                    color="primary"
                    style={this.state.follow_tail ?
                      { backgroundColor: '#0066b4', borderColor: '#3ea3fc' } : {}}
                  >
                    <p className="fas fa-arrow-down" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.export_excel}>
                  <ExcelFile element={
                    <Button className="icon" color="primary"> <p className="fa fa-file-excel" /></Button>
                  }
                  >
                    <ExcelSheet data={this.state.filteredMonitoringLog} name="Logs">
                      <ExcelColumn label="Log" value="text" />
                    </ExcelSheet>
                  </ExcelFile>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div style={{ clear: 'both' }}>
              <div id="grid-wrapper-left" className="monitoring-tree">
                <Tree
                  defaultExpandAll={false}
                  openAnimation={animation}
                  treeData={this.state.log_providers}
                  onSelect={this.onSelect}
                  onRightClick={this.onRightClick}
                  icon={this.nodeIcon}
                  switcherIcon={this.switcherIcon}
                />
                <p style={{ fontStyle: 'italic' }}>{literals.tables.monitoring_table.file_to_stream}</p>
                <p style={{ fontStyle: 'italic' }}>{literals.tables.monitoring_table.file_to_download}</p>
              </div>
              <div id="grid-wrapper" className="monitoring-tree-table form">
                <ReactTags
                  tags={tags}
                  suggestions={suggestions}
                  handleDelete={this.handleDelete}
                  handleAddition={this.handleAddition}
                  delimiters={delimiters}
                  allowDragDrop={false}
                  placeholder={literals.tables.monitoring_table.tag_to_filter}
                />
                <div style={{ height: '83vh' }} className="monitoring-log-list">
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        height={height}
                        itemCount={this.state.filteredMonitoringLog.length}
                        itemSize={23}
                        width={width}
                        itemData={this.state.filteredMonitoringLog}
                        ref={this.listRef}
                        innerRef={this.listInnerRef}
                        outerRef={this.listOuterRef}
                      >
                        {this.Row}
                      </List>
                    )}
                  </AutoSizer>
                </div>
              </div>
            </div>
            <Websocket
              url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
              reconnectIntervalInMilliSeconds={1000}
              onMessage={this.socketMessageListener}
              onOpen={this.socketOpenListener}
              ref={WS => {
                this.refWebSocket = WS;
              }}
            />
            <Dialog
              visible={this.state.open}
              wrapClassName="center"
              animation="slide-fade"
              maskAnimation="fade"
              onClose={this.closeModal}
              style={{ width: 276 }}
              title={literals.tables.monitoring_table.confirm_download}
            >
              <p>{literals.tables.monitoring_table.want_to_download} {this.state.file} ?</p>
              <div style={{
                marginTop: 10,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '70%',
              }}
              >
                <ButtonToolbar>
                  <Button
                    className="icon"
                    color="primary"
                    key="close"
                    onClick={this.closeModal}
                  >
                    {literals.forms.cancel}
                  </Button>
                  <Button
                    className="icon"
                    color="primary"
                    key="save"
                    onClick={this.downloadLogFile}
                  >
                    {literals.forms.download}
                  </Button>
                </ButtonToolbar>
              </div>
            </Dialog>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(MonitoringLog);
