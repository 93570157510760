import { Field } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import renderSelectField from '../../shared/components/form/SelectBox';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

const namePrefix = 'tem_';
const TEMForm = ({
  literals, renderField, hamSingle, mmSingle, llAction, dir,
}) => (
  <div className="borderValuesForm">
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.aggregate_group}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}ham`}
          component={renderSelectField}
          type="text"
          options={hamSingle}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.markup_group}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}mm`}
          component={renderSelectField}
          type="text"
          options={mmSingle}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.b_perc}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}b_perc`}
          component={renderField}
          type="number"
          step="any"
          placeholder={literals.forms.node_account_symbol_form.b_perc}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.b_fix}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}b_fix`}
          component={renderField}
          type="number"
          step="any"
          placeholder={literals.forms.node_account_symbol_form.b_fix}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.b_boost}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}b_boost`}
          component={renderField}
          type="number"
          step="any"
          placeholder={literals.forms.node_account_symbol_form.b_boost}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.b_delay}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}b_delay`}
          component={renderField}
          type="number"
          step="any"
          placeholder={literals.forms.node_account_symbol_form.b_delay}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.b_delay_jitter}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}b_delay_jitter`}
          component={renderField}
          type="number"
          step="any"
          placeholder={literals.forms.node_account_symbol_form.b_delay_jitter}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.gain_perc}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}gain_perc`}
          component={renderField}
          type="number"
          step="any"
          placeholder={literals.forms.node_account_symbol_form.gain_perc}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.ll_variation}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}ll_variation`}
          component={renderField}
          type="number"
          step="any"
          placeholder={literals.forms.node_account_symbol_form.ll_variation}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_account_symbol_form.ll_action}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}ll_action`}
          component={renderSelectField}
          type="text"
          options={llAction}
        />
      </div>
    </div>
  </div>
);
TEMForm.propTypes = {
  literals: LiteralProps.isRequired,
  renderField: PropTypes.instanceOf(Object).isRequired,
  hamSingle: PropTypes.instanceOf(Array),
  mmSingle: PropTypes.instanceOf(Array),
  llAction: PropTypes.instanceOf(Array),
  dir: PropTypes.string.isRequired,
};
TEMForm.defaultProps = {
  hamSingle: [],
  mmSingle: [],
  llAction: [],
};
export default TEMForm;
