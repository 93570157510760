const headerValidate = (values, props) => {
  const errors = {};
  const spaceRegExp = /^\S*$/;
  const atTheRateRegExp = /^[^@]*$/;
  if (!values.session) {
    errors.session = props.literals.forms.required_validate;
  }
  if (!spaceRegExp.test(values.character)) {
    errors.character =
        `${props.literals.forms.lp_session_form.char_base_quote} ${props.literals.forms.no_space_validate}`;
  }
  if (!atTheRateRegExp.test(values.character)) {
    errors.character = `${props.literals.forms.lp_session_form.char_base_quote} ${props.literals.forms.no_at_validate}`;
  }
  if (!spaceRegExp.test(values.extension)) {
    errors.extension = `${props.literals.forms.lp_session_form.ext_end} ${props.literals.forms.no_space_validate}`;
  }
  if (!atTheRateRegExp.test(values.extension)) {
    errors.extension = `${props.literals.forms.lp_session_form.ext_end} ${props.literals.forms.no_at_validate}`;
  }
  return errors;
};

export default headerValidate;
