import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import moment from 'moment';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import FileDownload from 'js-file-download';
import { getFormValues } from 'redux-form';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import BalanceTransactionReportSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import PositionView from './position';
import AccountBalanceView from './account_balance';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';
import Loader from '../../shared/components/Loader';
import TotalStatusBarComponent from './../balance_transaction_raw_report/totalStatusBarComponent';

const windowHeight = window.screen.height;

@inject('rootStore')
class BalanceTransactionReport extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
      search_values: PropTypes.instanceOf(Object),
    };
    static defaultProps = {
      search_values: {},
    };
    constructor(props) {
      super(props);
      const tableID = 'sidebar_balance_transaction_report';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.gridWrapperIDTotal = `grid-wrapper-${tableID}-total`;
      this.myGridIDTotal = `myGrid_${tableID}-total`;
      const { literals } = props;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.tables.balance_transaction_table.ticket,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.ticket'),
            field: 'ticket',
            headerTooltip: literals.tables.balance_transaction_table.ticket,
            pinned: 'left',
            width: 85,
            minWidth: 85,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.open_time_msc,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.open_time_msc'),
            field: 'open_time_msc_value',
            headerTooltip: literals.tables.balance_transaction_table.open_time_msc,
            width: 90,
            minWidth: 90,
            suppressMenu: true,
          },
          {
            headerName: literals.tables.balance_transaction_table.volume,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.volume'),
            field: 'volume',
            headerTooltip: literals.tables.balance_transaction_table.volume,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.notional,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.notional'),
            field: 'notional',
            headerTooltip: literals.tables.balance_transaction_table.notional,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.amount,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.amount'),
            field: 'amount',
            headerTooltip: literals.tables.balance_transaction_table.amount,
            width: 40,
            minWidth: 40,
            cellClass: 'number',
            cellRenderer(params) {
              if (params.value < 0) {
                return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
              }
              return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.type,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.type'),
            field: 'type_value',
            headerTooltip: literals.tables.balance_transaction_table.type,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.symbol,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.symbol'),
            field: 'symbol',
            headerTooltip: literals.tables.balance_transaction_table.symbol,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.currency,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.currency'),
            field: 'currency',
            headerTooltip: literals.tables.balance_transaction_table.currency,
            width: 33,
            minWidth: 33,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.open_price,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.open_price'),
            field: 'open_price',
            headerTooltip: literals.tables.balance_transaction_table.open_price,
            width: 40,
            minWidth: 40,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.open_cen_order,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.open_cen_order'),
            field: 'open_cen_order',
            headerTooltip: literals.tables.balance_transaction_table.open_cen_order,
            width: 85,
            minWidth: 85,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.close_time_msc,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.close_time_msc'),
            field: 'close_time_msc_value',
            headerTooltip: literals.tables.balance_transaction_table.close_time_msc,
            width: 90,
            minWidth: 90,
            suppressMenu: true,
          },
          {
            headerName: literals.tables.balance_transaction_table.close_price,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.close_price'),
            field: 'close_price',
            headerTooltip: literals.tables.balance_transaction_table.close_price,
            width: 40,
            minWidth: 40,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.close_cen_order,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.close_cen_order'),
            field: 'close_cen_order',
            headerTooltip: literals.tables.balance_transaction_table.close_cen_order,
            width: 85,
            minWidth: 85,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.comment,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.comment'),
            field: 'comment',
            headerTooltip: literals.tables.balance_transaction_table.comment,
            width: 90,
            minWidth: 90,
            type: 'text',
          },
        ],
        rowData: [],
        rowDataPosition: [],
        rowDataAccountBalance: [],
        collapse: '',
        collapse_closed_transaction: '',
        collapse_open_position: '',
        defaultColDef: {
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
          menuTabs: ['filterMenuTab'],
          suppressMovable: true,
        },
        columnTypes: {
          text: { filter: 'agTextColumnFilter' },
          number: { filter: 'agNumberColumnFilter' },
          numberWithFilterReset: {
            filter: 'agNumberColumnFilter',
          },
        },
        getRowNodeId(data) {
          return data.ticket;
        },
        rowDataTotal: [],
        rowDataPositionTotal: [],
        loaderShow: false,
        frameworkComponents: {
          totalStatusBarComponent: TotalStatusBarComponent,
        },
        statusBar: {
          statusPanels: [
            { statusPanel: 'totalStatusBarComponent' },
          ],
        },
      };
      this.onGridReady = this.onGridReady.bind(this);
      this.onGridReadyTotal = this.onGridReadyTotal.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.security_form.name !== prevProps.literals.forms.security_form.name) {
          this.gridApi.refreshHeader();
        }
      }
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.onGridSizeChanged(params);
      this.gridApi.refreshHeader();
    }

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onGridReadyTotal(params) {
      this.gridApiTotal = params.api;
      this.gridColumnApiTotal = params.columnApi;
      this.onGridSizeChangedTotal(params);
    }

    onGridSizeChangedTotal=(params) => {
      onGridSizeChanged(params, this.gridWrapperIDTotal, this.myGridIDTotal);
    };

    onColumnResizedTotal=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridIDTotal);
      }, 0);
    };

    onBtExportExcel=() => {
      this.showLoader();
      const values = this.props.search_values;
      const { reportState } = this.props.rootStore.reportStore;
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/report/risk_account_statement_export`,
        JSON.stringify({
          start_date: values.start_date ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
            : values.start_date,
          end_date: values.end_date ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
            : values.end_date,
          risk_account: reportState.riskAccount ? reportState.riskAccount : 'null',
        }), { headers: { 'x-forward-client-name': this.props.rootStore.authStore.user.client_name } },
      )
        .then((response) => {
          FileDownload(response.data, 'RiskAccountStatement.html');
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    handleSubmit=(values) => {
      this.gridApi.showLoadingOverlay();
      const { reportState } = this.props.rootStore.reportStore;
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/report/raw_balance_transaction?page=trade_statement`,
        JSON.stringify({
          start_date: values.start_date ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
            : values.start_date,
          end_date: values.end_date ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
            : values.end_date,
          account_alias: reportState.alias,
        }),
      )
        .then((response) => {
          this.setState({ rowData: response.data.BalanceTransaction });
          const total =
            {
              total_commission: response.data.TotalCommission,
              total_swaps: response.data.TotalSwaps,
              total_realized_pl: response.data.TotalRealizedPL,
              total_deposits: response.data.TotalDeposits,
              total_withdrawal: response.data.TotalWithdrawal,
              total_credit: response.data.TotalCredit,
            };
          this.setState({ rowDataTotal: total });
          this.gridApi.hideOverlay();
        })
        .catch(() => {
          this.gridApi.hideOverlay();
        });
      this.setState({ collapse: 'force-close' });
      setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
      axios.post(`${process.env.REACT_APP_BACKEND_API}/report/position`, JSON.stringify({
        position_account: reportState.alias ? [`RISK||${reportState.alias}`] : [],
        type: 'risk_account',
      }))
        .then((response) => {
          this.setState({ rowDataPosition: response.data.Position });
          const total =
            {
              total_unrealized_commission: response.data.TotalUnrealizedCommission,
              total_unrealized_swaps: response.data.TotalUnrealizedSwaps,
              total_unrealized_pl: response.data.TotalUnrealizedPL,
              total_notional: response.data.TotalNotional,
            };
          this.setState({ rowDataPositionTotal: total });
        })
        .catch(() => {
        });
      axios.post(`${process.env.REACT_APP_BACKEND_API}/report/account_balance`, JSON.stringify({
        account_alias: reportState.alias,
      }))
        .then((response) => {
          this.setState({ rowDataAccountBalance: response.data });
        })
        .catch(() => {
        });
    };

    localizeHeader=header => _.get(this.props, header)

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      let height = ((this.state.rowData ? this.state.rowData.length : 0) * 28) + 56 + 34;
      const wHeight = windowHeight - 550;
      if (height > wHeight) {
        height = wHeight;
      }
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.balance_transaction_report}
                </h5>
              </div>
              <Collapse
                title={literals.tables.ag_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <BalanceTransactionReportSearchForm onSubmit={this.handleSubmit} />
              </Collapse>
              <div className="outer-grid-button" style={{ marginTop: 5, marginBottom: 5 }}>
                <ButtonToolbar>
                  <Tooltip title={literals.tables.ag_table.export_html}>
                    <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                      <p className="fas fa-file-export" />
                    </Button>
                  </Tooltip>
                </ButtonToolbar>
              </div>
              <Collapse
                title={literals.sidebar.closed_transaction}
                className="with-shadow collapse-left"
                collapse={this.state.collapse_closed_transaction}
              >
                <React.Fragment>
                  <div className="outer-grid-label">
                    {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                  </div>
                  <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                    <div
                      id={this.myGridID}
                      style={{
                                    boxSizing: 'border-box',
                                    height,
                                    width: '100%',
                                }}
                      className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                    >
                      <AgGridReact
                        modules={this.state.modules}
                        columnDefs={this.state.columnDefs}
                        immutableData
                        suppressCellSelection
                        suppressContextMenu
                        defaultColDef={this.state.defaultColDef}
                        onColumnResized={this.onColumnResized}
                        getRowNodeId={this.state.getRowNodeId}
                        rowData={this.state.rowData}
                        onGridReady={this.onGridReady}
                        onGridSizeChanged={this.onGridSizeChanged}
                        columnTypes={this.state.columnTypes}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        context={{
                          rowDataTotal: this.state.rowDataTotal,
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              </Collapse>
              <br />
              <Collapse
                title={literals.sidebar.open_position}
                className="with-shadow collapse-left"
                collapse={this.state.collapse_open_position}
              >
                <PositionView rowData={this.state.rowDataPosition} rowDataTotal={this.state.rowDataPositionTotal} />
              </Collapse>
              <br />
              <AccountBalanceView rowData={this.state.rowDataAccountBalance} />
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  search_values: getFormValues('balance_transaction_report_broker_user_search_form')(state),
}))(BalanceTransactionReport);
