/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { connect } from 'react-redux';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import $ from 'jquery';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';
import PositionActionRenderer from './positionActionRenderer';

class PositionView extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    activeTab: PropTypes.string.isRequired,
    positionWatch: PropTypes.instanceOf(Array),
  };
  static defaultProps = {
    positionWatch: [],
  }
  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_trading_platform_position';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: 'Pos ID',
          field: 'position_id',
          width: 40,
          minWidth: 40,
        },
        {
          headerName: literals.tables.position_table.symbol,
          field: 'symbol_val',
          headerTooltip: literals.tables.position_table.symbol,
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.tables.position_table.party_symbol,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.position_table.party_symbol'),
          field: 'party_symbol',
          headerTooltip: literals.tables.position_table.party_symbol,
          width: 70,
          minWidth: 70,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.account,
          field: 'account',
          headerTooltip: literals.tables.position_table.account,
        },
        {
          headerName: literals.tables.position_table.taker,
          field: 'taker',
          headerTooltip: literals.tables.position_table.taker,
        },
        {
          headerName: literals.tables.position_table.taker_feed,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.position_table.taker_feed'),
          field: 'taker_feed',
          headerTooltip: literals.tables.position_table.taker_feed,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.risk_account,
          field: 'riskaccount',
          headerTooltip: literals.tables.position_table.risk_account,
        },
        {
          headerName: literals.tables.position_table.aggregate_group,
          field: 'aggregate_group',
          headerTooltip: literals.tables.position_table.aggregate_group,
        },
        {
          headerName: literals.tables.position_table.net_volume,
          field: 'net_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.net_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
        },
        {
          headerName: literals.tables.position_table.anet_volume,
          field: 'anet_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.anet_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
        },
        {
          headerName: literals.tables.position_table.bnet_volume,
          field: 'bnet_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.bnet_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
        },
        {
          headerName: literals.tables.position_table.avg_price,
          field: 'avg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.avg_price,
        },
        {
          headerName: literals.tables.position_table.aavg_price,
          field: 'aavg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.aavg_price,
        },
        {
          headerName: literals.tables.position_table.bavg_price,
          field: 'bavg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.bavg_price,
        },
        {
          headerName: literals.tables.position_table.last_time,
          field: 'last_time_value',
          headerTooltip: literals.tables.position_table.last_time,
          tooltipField: 'last_time_value',
          width: 100,
          minWidth: 100,
        },
        {
          headerName: literals.tables.position_table.close_price,
          field: 'close_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.close_price,
        },
        {
          headerName: literals.tables.position_table.pl,
          field: 'pl',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.pl,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
        },
        {
          headerName: literals.tables.position_table.margin,
          field: 'margin',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.margin,
        },
        {
          headerName: literals.tables.position_table.notional,
          field: 'notional',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.notional,
        },
        {
          headerName: literals.tables.position_table.swaps,
          field: 'swaps',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.swaps,
        },
        {
          headerName: literals.tables.position_table.commission,
          field: 'commission',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.commission,
        },
        {
          headerName: literals.tables.position_table.comment,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.position_table.comment'),
          field: 'comment',
          headerTooltip: literals.tables.position_table.comment,
          width: 60,
          minWidth: 60,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.sl_price,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.position_table.sl_price'),
          field: 'sl_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.sl_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.tp_price,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.position_table.tp_price'),
          field: 'tp_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.tp_price,
          type: 'number',
        },
        {
          headerName: literals.tables.ag_table.actions,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.ag_table.actions'),
          field: 'actions',
          cellRenderer: 'actionRenderer',
          width: 70,
          minWidth: 70,
          resizable: false,
          filter: false,
          editable: false,
          suppressMenu: true,
        },
      ],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      frameworkComponents: {
        actionRenderer: PositionActionRenderer,
      },
      getRowNodeId(data) {
        return data.position_id + data.symbol_val + data.riskaccount;
      },
    };
    this.onGridReadyPositionView = this.onGridReadyPositionView.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApiPositionView) {
      const { literals } = this.props;
      if (literals.tables.position_table.symbol !== prevProps.literals.tables.position_table.symbol) {
        this.gridColumnApiPositionView.getColumn('symbol_val').getColDef().headerName
          = literals.tables.position_table.symbol;
        this.gridColumnApiPositionView.getColumn('account').getColDef().headerName
          = literals.tables.position_table.account;
        this.gridColumnApiPositionView.getColumn('taker').getColDef().headerName
          = literals.tables.position_table.taker;
        this.gridColumnApiPositionView.getColumn('riskaccount').getColDef().headerName
          = literals.tables.position_table.risk_account;
        this.gridColumnApiPositionView.getColumn('aggregate_group').getColDef().headerName
          = literals.tables.position_table.aggregate_group;
        this.gridColumnApiPositionView.getColumn('net_volume_value').getColDef().headerName
          = literals.tables.position_table.net_volume;
        this.gridColumnApiPositionView.getColumn('anet_volume_value').getColDef().headerName
            = literals.tables.position_table.anet_volume;
        this.gridColumnApiPositionView.getColumn('bnet_volume_value').getColDef().headerName
            = literals.tables.position_table.bnet_volume;
        this.gridColumnApiPositionView.getColumn('avg_price').getColDef().headerName
          = literals.tables.position_table.avg_price;
        this.gridColumnApiPositionView.getColumn('aavg_price').getColDef().headerName
            = literals.tables.position_table.aavg_price;
        this.gridColumnApiPositionView.getColumn('bavg_price').getColDef().headerName
            = literals.tables.position_table.bavg_price;
        this.gridColumnApiPositionView.getColumn('last_time_value').getColDef().headerName
          = literals.tables.position_table.last_time;
        this.gridColumnApiPositionView.getColumn('close_price').getColDef().headerName
            = literals.tables.position_table.close_price;
        this.gridColumnApiPositionView.getColumn('pl').getColDef().headerName = literals.tables.position_table.pl;
        this.gridColumnApiPositionView.getColumn('margin').getColDef().headerName
          = literals.tables.position_table.margin;
        this.gridColumnApiPositionView.getColumn('notional').getColDef().headerName
          = literals.tables.position_table.notional;
        this.gridColumnApiPositionView.getColumn('swaps').getColDef().headerName
            = literals.tables.position_table.swaps;
        this.gridColumnApiPositionView.getColumn('commission').getColDef().headerName
            = literals.tables.position_table.commission;

        this.gridColumnApiPositionView.getColumn('symbol_val').getColDef().headerTooltip
          = literals.tables.position_table.symbol;
        this.gridColumnApiPositionView.getColumn('account').getColDef().headerTooltip
          = literals.tables.position_table.account;
        this.gridColumnApiPositionView.getColumn('taker').getColDef().headerTooltip
          = literals.tables.position_table.taker;
        this.gridColumnApiPositionView.getColumn('riskaccount').getColDef().headerTooltip
          = literals.tables.position_table.risk_account;
        this.gridColumnApiPositionView.getColumn('aggregate_group').getColDef().headerTooltip
          = literals.tables.position_table.aggregate_group;
        this.gridColumnApiPositionView.getColumn('net_volume_value').getColDef().headerTooltip
          = literals.tables.position_table.net_volume;
        this.gridColumnApiPositionView.getColumn('avg_price').getColDef().headerTooltip
          = literals.tables.position_table.avg_price;
        this.gridColumnApiPositionView.getColumn('last_time_value').getColDef().headerTooltip
          = literals.tables.position_table.last_time;
        this.gridColumnApiPositionView.getColumn('pl').getColDef().headerTooltip = literals.tables.position_table.pl;
        this.gridColumnApiPositionView.getColumn('margin').getColDef().headerTooltip
          = literals.tables.position_table.margin;
        this.gridColumnApiPositionView.getColumn('notional').getColDef().headerTooltip
          = literals.tables.position_table.notional;
        this.gridApiPositionView.refreshHeader();
      }
    }
  }
  onGridReadyPositionView(params) {
    this.gridApiPositionView = params.api;
    this.gridColumnApiPositionView = params.columnApi;
    this.onGridSizeChangedPositionView(params);
  }

  onGridSizeChangedPositionView=(params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };
  onColumnResizedPositionView=(params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };
  localizeHeader = header => _.get(this.props, header);
  render() {
    const theme = this.props.theme.className;
    const positionWatchData = this.props.positionWatch ?
      this.props.positionWatch : [];
    let positionData = [];
    if (this.props.activeTab === '1') {
      positionData = positionWatchData;
    } else {
      positionData = [];
    }
    const agHeader = $(`#${this.myGridID} .ag-header`);
    let agHeaderHeight = 56;
    if (agHeader) {
      agHeaderHeight = agHeader.height();
    }
    const height = Math.min(((positionData.length + 1) * 28) + agHeaderHeight, 200);
    return (
      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%' }}>
        <div
          id={this.myGridID}
          style={{
            boxSizing: 'border-box',
            height,
            width: '100%',
          }}
          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            immutableData
            suppressCellSelection
            suppressContextMenu
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResizedPositionView}
            rowData={positionData}
            getRowNodeId={this.state.getRowNodeId}
            onGridReady={this.onGridReadyPositionView}
            onGridSizeChanged={this.onGridSizeChangedPositionView}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(PositionView);
