/* eslint-disable  no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import Select from 'react-select';
import { getFormValues } from 'redux-form';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import ChartMakerSymbolSpreadHistorySearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import Loader from '../../shared/components/Loader';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
  HighchartsOfflineExporting(Highcharts);
}
class ChartMakerSymbolSpreadHistory extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    search_values: PropTypes.instanceOf(Object),
  };
  static defaultProps = {
    search_values: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      collapse: '',
      loaderShow: false,
      chartType: 'column',
    };
  }

  handleSubmit=(values) => {
    this.showLoader();
    const dataToSend = this.filtersToSend(values);
    axios.post(`${process.env.REACT_APP_BACKEND_API}/report/maker_symbol_spread_history`, JSON.stringify(dataToSend))
      .then((response) => {
        this.setState({ rowData: response.data });
        window.dispatchEvent(new Event('resize'));
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
  };

  filtersToSend = values => ({
    start_date: values.start_date ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf()
      : values.start_date,
    end_date: values.end_date ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf()
      : values.end_date,
    symbol: values.symbol,
    shared_id: values.shared_id,
  });

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  handleChangeChartType = (option) => {
    if (option && option.value) {
      this.setState({ chartType: option.value });
    }
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    let options = {
      chart: {
        type: this.state.chartType,
      },
      credits: { enabled: false },
      exporting: {
        buttons: {
          contextButton: {
            menuItems:
              ['downloadPNG', 'downloadJPEG', 'downloadSVG'],
          },
        },
        fallbackToExportServer: false,
        chartOptions: { // specific options for the exported image
          scrollbar: {
            enabled: false,
          },
          navigator: {
            enabled: false,
          },
        },
      },
      title: {
        text: literals.charts.maker_symbol_spread_history,
      },
      caption: {
        text: `<b>Symbol:</b> ${this.props.search_values.symbol} 
        <b>Start:</b> ${this.props.search_values.start_date} 
        <b>End:</b> ${this.props.search_values.end_date}`,
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Time',
        },
      },
      yAxis: {
        title: {
          text: literals.charts.avg_spread_in_points,
        },
      },
      tooltip: {
        shared: true,
      },
      series: this.state.rowData,
    };
    if (theme === 'theme-dark') {
      options = {
        ...options,
        ...{
          colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
            '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
          chart: {
            ...options.chart,
            backgroundColor: {
              linearGradient: {
                x1: 0, y1: 0, x2: 1, y2: 1,
              },
              stops: [
                [0, '#2a2a2b'],
                [1, '#3e3e40'],
              ],
            },
            plotBorderColor: '#606063',
          },
          title: {
            ...options.title,
            style: {
              color: '#E0E0E3',
              textTransform: 'capitalize',
            },
          },
          subtitle: {
            ...options.subtitle,
            style: {
              color: '#E0E0E3',
              textTransform: 'capitalize',
            },
          },
          caption: {
            ...options.caption,
            style: {
              color: '#E0E0E3',
            },
          },
          xAxis: {
            ...options.xAxis,
            gridLineColor: '#707073',
            labels: {
              style: {
                color: '#E0E0E3',
              },
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            title: {
              ...options.xAxis.title,
              style: {
                color: '#A0A0A3',

              },
            },
          },
          yAxis: {
            gridLineColor: '#707073',
            labels: {
              style: {
                color: '#E0E0E3',
              },
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            tickWidth: 1,
            title: {
              ...options.yAxis.title,
              style: {
                color: '#A0A0A3',
              },
            },
          },
          tooltip: {
            ...options.tooltip,
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            style: {
              color: '#F0F0F0',
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                color: '#F0F0F3',
              },
              marker: {
                lineColor: '#333',
              },
            },
            boxplot: {
              fillColor: '#505053',
            },
            candlestick: {
              lineColor: 'white',
            },
            errorbar: {
              color: 'white',
            },
          },
          legend: {
            enabled: true,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            itemStyle: {
              color: '#E0E0E3',
            },
            itemHoverStyle: {
              color: '#FFF',
            },
            itemHiddenStyle: {
              color: '#606063',
            },
            title: {
              style: {
                color: '#C0C0C0',
              },
            },
          },
          credits: {
            ...options.credits,
            style: {
              color: '#666',
            },
          },
          labels: {
            style: {
              color: '#707073',
            },
          },
          drilldown: {
            activeAxisLabelStyle: {
              color: '#F0F0F3',
            },
            activeDataLabelStyle: {
              color: '#F0F0F3',
            },
          },
          navigation: {
            buttonOptions: {
              symbolStroke: '#DDDDDD',
              theme: {
                fill: '#505053',
              },
            },
          },
          // scroll charts
          rangeSelector: {
            enabled: false,
            buttonTheme: {
              fill: '#505053',
              stroke: '#000000',
              style: {
                color: '#CCC',
              },
              states: {
                hover: {
                  fill: '#707073',
                  stroke: '#000000',
                  style: {
                    color: 'white',
                  },
                },
                select: {
                  fill: '#000003',
                  stroke: '#000000',
                  style: {
                    color: 'white',
                  },
                },
              },
            },
            inputBoxBorderColor: '#505053',
            inputStyle: {
              backgroundColor: '#333',
              color: 'silver',
            },
            labelStyle: {
              color: 'silver',
            },
          },
          navigator: {
            handles: {
              backgroundColor: '#666',
              borderColor: '#AAA',
            },
            outlineColor: '#CCC',
            maskFill: 'rgba(255,255,255,0.1)',
            series: {
              color: '#7798BF',
              lineColor: '#A6C7ED',
            },
            xAxis: {
              gridLineColor: '#505053',
            },
          },
          scrollbar: {
            barBackgroundColor: '#808083',
            barBorderColor: '#808083',
            buttonArrowColor: '#CCC',
            buttonBackgroundColor: '#606063',
            buttonBorderColor: '#606063',
            rifleColor: '#FFF',
            trackBackgroundColor: '#404043',
            trackBorderColor: '#404043',
          },
        },
      };
    } else {
      options = {
        ...options,
        ...{
          colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9',
            '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
          chart: {
            ...options.chart,
            backgroundColor: '#ffffff',
            plotBorderColor: '#cccccc',
          },
          title: {
            ...options.title,
            style: {
              color: '#333333',
              textTransform: 'capitalize',
            },
          },
          subtitle: {
            ...options.subtitle,
            style: {
              color: '#666666',
              textTransform: 'capitalize',
            },
          },
          caption: {
            ...options.caption,
            style: {
              color: '#666666',
            },
          },
          xAxis: {
            ...options.xAxis,
            gridLineColor: '#e6e6e6',
            labels: {
              style: {
                color: '#666666',
              },
            },
            lineColor: '#e6e6e6',
            minorGridLineColor: '#e6e6e6',
            tickColor: '#e6e6e6',
            title: {
              ...options.xAxis.title,
              style: {
                color: '#666666',

              },
            },
          },
          yAxis: {
            gridLineColor: '#e6e6e6',
            labels: {
              style: {
                color: '#666666',
              },
            },
            lineColor: '#e6e6e6',
            minorGridLineColor: '#e6e6e6',
            tickColor: '#e6e6e6',
            tickWidth: 1,
            title: {
              ...options.yAxis.title,
              style: {
                color: '#666666',
              },
            },
          },
          tooltip: {
            ...options.tooltip,
            backgroundColor: 'rgba(247,247,247,0.85)',
            style: {
              color: '#333333',
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                color: '#333333',
              },
              marker: {
                lineColor: '#F0F0F0',
              },
            },
            boxplot: {
              fillColor: '#e6e6e6',
            },
            candlestick: {
              lineColor: 'black',
            },
            errorbar: {
              color: 'black',
            },
          },
          legend: {
            enabled: true,
            backgroundColor: 'transparent',
            itemStyle: {
              color: '#333333',
            },
            itemHoverStyle: {
              color: '#000000',
            },
            itemHiddenStyle: {
              color: '#cccccc',
            },
            title: {
              style: {
                color: '#333333',
              },
            },
          },
          credits: {
            ...options.credits,
            style: {
              color: '#999999',
            },
          },
          labels: {
            style: {
              color: '#333333',
            },
          },
          drilldown: {
            activeAxisLabelStyle: {
              color: '#333333',
            },
            activeDataLabelStyle: {
              color: '#333333',
            },
          },
          navigation: {
            buttonOptions: {
              symbolStroke: '#333333',
              theme: {
                fill: '#e6e6e6',
              },
            },
          },
          // scroll charts
          rangeSelector: {
            enabled: false,
            buttonTheme: {
              fill: '#e6e6e6',
              stroke: '#ffffff',
              style: {
                color: '#333333',
              },
              states: {
                hover: {
                  fill: '#333333',
                  stroke: '#ffffff',
                  style: {
                    color: 'black',
                  },
                },
                select: {
                  fill: '#ffffff',
                  stroke: '#ffffff',
                  style: {
                    color: 'black',
                  },
                },
              },
            },
            inputBoxBorderColor: '#e6e6e6',
            inputStyle: {
              backgroundColor: '#F0F0F0',
              color: 'white',
            },
            labelStyle: {
              color: 'white',
            },
          },
          navigator: {
            handles: {
              backgroundColor: '#999999',
              borderColor: '#333333',
            },
            outlineColor: '#333333',
            maskFill: 'rgba(0.1,255,255,255)',
            series: {
              color: '#7798BF',
              lineColor: '#A6C7ED',
            },
            xAxis: {
              gridLineColor: '#e6e6e6',
            },
          },
          scrollbar: {
            barBackgroundColor: '#cccccc',
            barBorderColor: '#cccccc',
            buttonArrowColor: '#333333',
            buttonBackgroundColor: '#cccccc',
            buttonBorderColor: '#cccccc',
            rifleColor: '#000',
            trackBackgroundColor: '#cccccc',
            trackBorderColor: '#cccccc',
          },
        },
      };
    }
    const colourStyles = {
      control: styles => ({
        ...styles, minHeight: 22, height: 22, borderRadius: 0,
      }),
      indicatorsContainer: styles => ({ ...styles, height: 22 }),
      valueContainer: styles => ({ ...styles, height: 22, position: 'unset' }),
    };
    const optionsChartType = [
      { value: 'column', label: 'Column' },
      { value: 'line', label: 'Line' },
      { value: 'area', label: 'Area' },
      { value: 'spline', label: 'Spline' },
      { value: 'areaspline', label: 'Area Spline' },
    ];
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title card__title_custom">
              <h5 className="bold-text">{literals.sidebar.chart_maker_symbol_spread_history}
              </h5>
            </div>
            <Collapse
              title={literals.tables.ag_table.toggle_search}
              className="with-shadow"
              collapse={this.state.collapse}
            >
              <ChartMakerSymbolSpreadHistorySearchForm onSubmit={this.handleSubmit} />
            </Collapse>
            <div className="form" style={{ float: 'right' }}>
              <span className="form__form-group-label" style={{ paddingRight: 20 }}>{literals.charts.chart_type}</span>
              <Select
                styles={colourStyles}
                onChange={this.handleChangeChartType}
                options={optionsChartType}
                className="form__form-group-select"
                classNamePrefix="Select"
                defaultValue={{ value: 'column', label: 'Column' }}
              />
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              constructorType="stockChart"
              options={options}
              containerProps={{ style: { clear: 'both', height: '83vh' } }}
            />
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  search_values: getFormValues('maker_symbol_spread_history_chart_search_form')(state),
}))(ChartMakerSymbolSpreadHistory);
