import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class SidebarCategory extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconStyle: PropTypes.string,
    isNew: PropTypes.bool,
    children: PropTypes.instanceOf(Object).isRequired,
    sidebar: PropTypes.instanceOf(Object).isRequired,
    changeSidebarCategoryCollapse: PropTypes.func.isRequired,
    keyCategory: PropTypes.string.isRequired,
    display: PropTypes.bool,
  };

  static defaultProps = {
    icon: '',
    iconStyle: 'fa',
    isNew: false,
    display: true,
  };

  toggle = () => {
    this.props.changeSidebarCategoryCollapse(this.props.keyCategory);
  };

  render() {
    let collapseCategory = false;
    if (this.props.sidebar.collapseCategory) {
      if (this.props.sidebar.collapseCategory[this.props.keyCategory]) {
        collapseCategory = this.props.sidebar.collapseCategory[this.props.keyCategory];
      }
    }
    const {
      title, icon, iconStyle, isNew, children, display,
    } = this.props;
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapseCategory,
    });
    if (display) {
      return (
        <div className={categoryClass} >
          <button className="sidebar__link sidebar__category" onClick={this.toggle}>
            {icon ? <i className={`${iconStyle} fa-${icon} media-icon-parent`} /> : ''}
            <p className="sidebar__link-title">{title}
              {isNew && <span className="sidebar__category-new" />}
            </p>
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
          </button>
          <Collapse
            isOpen={collapseCategory}
            className="sidebar__submenu-wrap"
          >
            <ul className="sidebar__submenu">
              <div>
                {children}
              </div>
            </ul>
          </Collapse>
        </div>
      );
    }
    return (null);
  }
}
