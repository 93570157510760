import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import GoldenLayoutComponent from './goldenLayoutComponent';
import DummyComponent from './dummyComponent';
import Security from '../security';
import UniSymbol from '../uni_symbol';
import AggregateGroup from '../aggregate_group';
import MarkupGroup from '../markup_group';
import LPSession from '../lp_session';
import MakerAPILink from '../maker_api_link';
import Node from '../node';
import NodeFeed from '../node_feed';
import NodeAccount from '../node_account';
import AccountGroup from '../account_group';
import AssetClassGroup from '../asset_class_group';
import AssetClassSymbol from '../asset_class_symbol';
import BalanceTransaction from '../balance_transaction';
import LimitSymbolGroup from '../limit_symbol_group';
import MasterRiskAccount from '../master_risk_account';
import BrokerUser from '../broker_user';
import ReverseTradeCopier from '../reverse_trade_copier';
import GiveUpRule from '../give_up_rule';
import FiltrationPool from '../filtration_pool';
import MarketWatch from '../market_watch';
import MarketWatchBrokerUser from '../market_watch_broker_user';
import Trading from '../trading';
import TradingBrokerUser from '../trading_broker_user';
import PushTicks from '../push_ticks';
import Position from '../position';
import Order from '../order';
import OrderBrokerUser from '../order_broker_user';
import OrderMaker from '../order_maker';
import GiveupOrderReport from '../report_giveup_order';
import Response from '../response';
import MT4Report from '../mt4_report';
import MT5Report from '../mt5_report';
import BalanceTransactionReport from '../balance_transaction_report';
import BalanceTransactionBrokerUser from '../balance_transaction_report_broker_user';
import RawBalanceTransactionReport from '../balance_transaction_raw_report';
import LevelsAdvantage from '../levels_advantage';
import ChartMakerNotional from '../chart_maker_notional';
import ChartMakerSymbolNotional from '../chart_maker_symbol_notional';
import ChartSymbolNotional from '../chart_symbol_notional';
import ChartSymbolDetailNotional from '../chart_symbol_detail_notional';
import TakerNotional from '../taker_notional';
import MakerNotional from '../maker_notional';
import SymbolNotional from '../symbol_notional';
import MarkupReport from '../report_markup';
import MarkupLegReport from '../report_markup_leg';
import SlippageReport from '../report_slippage';
import SlippageLegReport from '../report_slippage_leg';
import NetPerLoginReport from '../report_net_per_login';
import MakerSymbolStatusReport from '../report_maker_symbol_status';
import ChartMakerSymbolSpreadHistory from '../chart_maker_symbol_spread_history';
import MakerRejectionReport from '../report_maker_rejection';
import ClientRiskProfiling from '../client_risk_profiling';
import ChartBarLimit from '../chart_bar_limit';
import ChartMarketImpact from '../chart_market_impact';
import MonitoringLog from '../monitoring_log';
import TakerStatus from '../taker_status';
import MakerStatus from '../maker_status';
import AuditLog from '../audit_log';
import AccessLog from '../access_log';
import UrgencyHeadlineNews from '../urgency_headline_news';
import Client from '../client';
import ServerInstance from '../server_instance';
import Config from '../config';
import LP from '../lp';
import AdminPosition from '../admin_position';
import Commands from '../commands';
import LegReport from '../report_leg';

const REDUCE_HEIGHT = 150;
@inject('rootStore')
class MultiWindow extends PureComponent {
  static propTypes = {
    rootStore: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.instanceOf(Object),
  };
  static defaultProps = {
    match: {},
  };

  componentDidMount() {

  }

  render() {
    const { role } = this.props.rootStore.authStore;
    return (
      <GoldenLayoutComponent
        htmlAttrs={{
          style: {
            height: window.outerHeight - REDUCE_HEIGHT,
          },
        }}
        config={{
          settings: {
            showPopoutIcon: false,
            showMaximiseIcon: false,
          },
          content: [{
            type: 'row',
            content: [{
              title: ' ',
              type: 'react-component',
              component: 'dummy',
            }],
          }],
        }}
        registerComponents={(myLayout) => {
          myLayout.registerComponent('dummy', DummyComponent);
          if (role === 'admin' || role === 'broker') {
            myLayout.registerComponent('sidebar_security', Security);
            myLayout.registerComponent('sidebar_uni_symbol', UniSymbol);
            myLayout.registerComponent('sidebar_aggregate_group', AggregateGroup);
            myLayout.registerComponent('sidebar_markup_group', MarkupGroup);
            myLayout.registerComponent('sidebar_lp_session', LPSession);
            myLayout.registerComponent('sidebar_maker_api_link', MakerAPILink);
            myLayout.registerComponent('sidebar_node', Node);
            myLayout.registerComponent('sidebar_node_feed', NodeFeed);
            myLayout.registerComponent('sidebar_node_account', NodeAccount);
            myLayout.registerComponent('sidebar_account_group', AccountGroup);
            myLayout.registerComponent('sidebar_asset_class_group', AssetClassGroup);
            myLayout.registerComponent('sidebar_asset_class_symbol', AssetClassSymbol);
            myLayout.registerComponent('sidebar_balance_transaction', BalanceTransaction);
            myLayout.registerComponent('sidebar_limit_symbol_group', LimitSymbolGroup);
            myLayout.registerComponent('sidebar_master_risk_account', MasterRiskAccount);
            myLayout.registerComponent('sidebar_user', BrokerUser);
            myLayout.registerComponent('sidebar_reverse_trade_copier', ReverseTradeCopier);
            myLayout.registerComponent('sidebar_give_up_rule', GiveUpRule);
            myLayout.registerComponent('sidebar_filtration_pool', FiltrationPool);
            myLayout.registerComponent('sidebar_market_watch', MarketWatch);
            myLayout.registerComponent('sidebar_trading_platform', Trading);
            myLayout.registerComponent('sidebar_push_ticks', PushTicks);
            myLayout.registerComponent('sidebar_position', Position);
            myLayout.registerComponent('sidebar_order', Order);
            myLayout.registerComponent('sidebar_order_maker', OrderMaker);
            myLayout.registerComponent('sidebar_giveup_order', GiveupOrderReport);
            myLayout.registerComponent('sidebar_response', Response);
            myLayout.registerComponent('sidebar_mt4reports', MT4Report);
            myLayout.registerComponent('sidebar_mt5reports', MT5Report);
            myLayout.registerComponent('sidebar_balance_transaction_report', BalanceTransactionReport);
            myLayout.registerComponent('sidebar_raw_balance_transaction', RawBalanceTransactionReport);
            myLayout.registerComponent('sidebar_levels_advantage', LevelsAdvantage);
            myLayout.registerComponent('sidebar_chart_maker_notional', ChartMakerNotional);
            myLayout.registerComponent('sidebar_chart_maker_symbol_notional', ChartMakerSymbolNotional);
            myLayout.registerComponent('sidebar_chart_symbol_notional', ChartSymbolNotional);
            myLayout.registerComponent('sidebar_chart_symbol_detail_notional', ChartSymbolDetailNotional);
            myLayout.registerComponent('sidebar_taker_notional', TakerNotional);
            myLayout.registerComponent('sidebar_maker_notional', MakerNotional);
            myLayout.registerComponent('sidebar_symbol_notional', SymbolNotional);
            myLayout.registerComponent('sidebar_markup_report', MarkupReport);
            myLayout.registerComponent('sidebar_markup_leg_report', MarkupLegReport);
            myLayout.registerComponent('sidebar_slippage_report', SlippageReport);
            myLayout.registerComponent('sidebar_slippage_leg_report', SlippageLegReport);
            myLayout.registerComponent('sidebar_net_per_login', NetPerLoginReport);
            myLayout.registerComponent('sidebar_maker_symbol_status', MakerSymbolStatusReport);
            myLayout.registerComponent('sidebar_chart_maker_symbol_spread_history', ChartMakerSymbolSpreadHistory);
            myLayout.registerComponent('sidebar_maker_rejection', MakerRejectionReport);
            myLayout.registerComponent('sidebar_quant_based_hybrid', ClientRiskProfiling);
            myLayout.registerComponent('sidebar_chart_bar_limit', ChartBarLimit);
            myLayout.registerComponent('sidebar_chart_market_impact', ChartMarketImpact);
            myLayout.registerComponent('sidebar_logs', MonitoringLog);
            myLayout.registerComponent('sidebar_taker_status', TakerStatus);
            myLayout.registerComponent('sidebar_maker_status', MakerStatus);
            myLayout.registerComponent('sidebar_urgency_headline_news', UrgencyHeadlineNews);
            myLayout.registerComponent('sidebar_leg', LegReport);
          }
          if (role === 'client') {
            myLayout.registerComponent('sidebar_market_watch', MarketWatchBrokerUser);
            myLayout.registerComponent('sidebar_trading_platform', TradingBrokerUser);
            myLayout.registerComponent('sidebar_order', OrderBrokerUser);
            myLayout.registerComponent('sidebar_balance_transaction_report', BalanceTransactionBrokerUser);
          }
          if (role === 'admin') {
            myLayout.registerComponent('sidebar_audit_log', AuditLog);
            myLayout.registerComponent('sidebar_access_log', AccessLog);
            myLayout.registerComponent('sidebar_client', Client);
            myLayout.registerComponent('sidebar_server_instance', ServerInstance);
            myLayout.registerComponent('sidebar_config', Config);
            myLayout.registerComponent('sidebar_lp', LP);
            myLayout.registerComponent('sidebar_admin_position', AdminPosition);
            myLayout.registerComponent('sidebar_commands', Commands);
          }
        }}
        key={this.props.match.params.workspace}
        workspace={this.props.match.params.workspace}
      />
    );
  }
}
export default MultiWindow;
