/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import MultiEditorForm from './MultiEditorForm';
import InOutSessionValuesEditorForm from './InOutSessionValuesEditorForm';
import DateCalendarEditorForm from './DateCalendarEditorForm';

class SessionRenderer extends PureComponent {
  static propTypes = {
    context: PropTypes.instanceOf(Object).isRequired,
    value: PropTypes.string.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    colDef: PropTypes.instanceOf(Object).isRequired
  };
  state = {
    sessionModalShow: false
  };
  parseMultiPattern = (values, pattern, text, tree, securitySymbol) => {
    let result = '*,';
    if (values[pattern] && values[text]) {
      result = values[text].trim() || '*,';
    } else if (!values[pattern] && values[tree]) {
      if (this.props.colDef.field === 'symbols') {
        if (values[tree].length > 0) {
          const symbols = [];
          if (!Array.isArray(values[tree])) {
            values[tree] = [values[tree]];
          }
          values[tree].forEach((item) => {
            if (item in securitySymbol) {
              symbols.push(...securitySymbol[item]);
            } else {
              symbols.push(item);
            }
          });
          result = symbols.join(',');
        } else {
          result = '*,';
        }
      } else {
        result = values[tree].length > 0 ? values[tree].join(',') : '*,';
      }
    }
    return result;
  };

  valCheck = (values, dir, name) => {
    const valToCheck = values[dir + name];
    if (!valToCheck) {
      if (valToCheck === 0) {
        return valToCheck;
      }
      return '';
    }
    return valToCheck;
  };
  valCheckSelect = (values, dir, name, valueOrLabel) => {
    const valToCheck = values[dir + name];
    if (!valToCheck) {
      return '';
    }
    if (valToCheck.value === '') {
      return '';
    }
    return valToCheck[valueOrLabel];
  };
  valCheckSelectMul = (values, dir, name) => {
    const valToCheck = values[dir + name];
    if (!valToCheck) {
      return '';
    }
    return valToCheck.join(';');
  };
  parseMM = (values, dir, valueOrLabel) => `${this.valCheck(
    values,
    dir,
    'mm_markup_bid'
  )},\
${this.valCheck(values, dir, 'mm_markup_bid_var')},\
${this.valCheck(values, dir, 'mm_markup_ask')},${this.valCheck(
    values,
    dir,
    'mm_markup_ask_var'
  )},\
${this.valCheck(values, dir, 'mm_spread_min')},\
${this.valCheck(values, dir, 'mm_spread_max')},${this.valCheckSelect(
    values,
    dir,
    'mm_spread_exec',
    valueOrLabel
  )}`;
  parseHAM = (values, dir, valueOrLabel) => `${this.valCheckSelectMul(
    values,
    dir,
    'ham_lp_sessions'
  )},\
${this.valCheckSelect(
  values,
  dir,
  'ham_exec_mode',
  valueOrLabel
)},${this.valCheck(values, dir, 'ham_filter_factor')}`;
  parseTF = (values, dir, valueOrLabel) => `${this.valCheckSelect(
    values,
    dir,
    'tf_ham',
    valueOrLabel
  )},\
${this.valCheckSelect(values, dir, 'tf_mm', valueOrLabel)}`;
  parseTEM = (values, dir, valueOrLabel) => `${this.valCheckSelect(
    values,
    dir,
    'tem_ham',
    valueOrLabel
  )},\
${this.valCheckSelect(values, dir, 'tem_mm', valueOrLabel)},\
${this.valCheck(values, dir, 'tem_b_perc')},${this.valCheck(
    values,
    dir,
    'tem_b_fix'
  )},\
${this.valCheck(values, dir, 'tem_b_boost')},\
${this.valCheck(values, dir, 'tem_b_delay')},${this.valCheck(
    values,
    dir,
    'tem_b_delay_jitter'
  )},\
${this.valCheck(values, dir, 'tem_gain_perc')},\
${this.valCheck(values, dir, 'tem_ll_variation')},${this.valCheckSelect(
    values,
    dir,
    'tem_ll_action',
    valueOrLabel
  )}`;

  parseDateCalendar = (dateCalendar) =>
    dateCalendar?.map((date) => date.format('YYYYMMDD')).join(',');
  toggleModalSession = () => {
    this.setState({ sessionModalShow: !this.state.sessionModalShow });
  };
  showModalSession = () => {
    if (!this.state.sessionModalShow) {
      this.setState({ sessionModalShow: true });
    }
  };
  handleSubmit = (values) => {
    const rowNode = this.props.node;
    let data = '';
    if (this.props.colDef.field === 'date_calendar') {
      data = this.parseDateCalendar(values.date_calendar);
    } else if (this.props.colDef.field === 'in_session_values_value') {
      let inSessionValues = '';
      if (this.props.node.data.type === 1) {
        inSessionValues = this.parseMM(values, 'in_session_', 'value');
        data = this.parseMM(values, 'in_session_', 'label');
      } else if (this.props.node.data.type === 2) {
        inSessionValues = this.parseHAM(values, 'in_session_', 'value');
        data = this.parseHAM(values, 'in_session_', 'label');
      } else if (this.props.node.data.type === 3) {
        inSessionValues = this.parseTF(values, 'in_session_', 'value');
        data = this.parseTF(values, 'in_session_', 'label');
      } else if (this.props.node.data.type === 4) {
        inSessionValues = this.parseTEM(values, 'in_session_', 'value');
        data = this.parseTEM(values, 'in_session_', 'label');
      }
      this.props.node.data.in_session_values = inSessionValues;
    } else if (this.props.colDef.field === 'out_of_session_values_value') {
      let outSessionValues = '';
      if (!values.out_session_revert_old) {
        if (this.props.node.data.type === 1) {
          outSessionValues = this.parseMM(values, 'out_session_', 'value');
          data = this.parseMM(values, 'out_session_', 'label');
        } else if (this.props.node.data.type === 2) {
          outSessionValues = this.parseHAM(values, 'out_session_', 'value');
          data = this.parseHAM(values, 'out_session_', 'label');
        } else if (this.props.node.data.type === 3) {
          outSessionValues = this.parseTF(values, 'out_session_', 'value');
          data = this.parseTF(values, 'out_session_', 'label');
        } else if (this.props.node.data.type === 4) {
          outSessionValues = this.parseTEM(values, 'out_session_', 'value');
          data = this.parseTEM(values, 'out_session_', 'label');
        }
      }
      this.props.node.data.out_of_session_values = outSessionValues;
    } else {
      data = this.parseMultiPattern(
        values,
        'pattern',
        'text',
        'tree',
        this.props.context.formData.SecuritySymbol
      );
    }
    rowNode.setDataValue(this.props.colDef.field, data);
    this.toggleModalSession();
  };
  render() {
    const theme = this.props.context.theme.className;
    return (
      <div onDoubleClick={this.showModalSession}>
        <label>{this.props.value}</label>
        <Modal
          isOpen={this.state.sessionModalShow}
          toggle={() => this.toggleModalSession}
          className={`modal-dialog--primary modal-dialog--header crp-modal ${theme}`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              onClick={() => this.toggleModalSession()}
            />
            <h4 className="bold-text  modal__title">
              {this.props.colDef.headerName}
            </h4>
          </div>
          <div className="modal__body">
            {this.props.colDef.field !== 'in_session_values_value' &&
              this.props.colDef.field !== 'out_of_session_values_value' &&
              this.props.colDef.field !== 'date_calendar' && (
                <MultiEditorForm
                  {...this.props}
                  onSubmit={this.handleSubmit}
                  toggleModalSession={this.toggleModalSession}
                />
              )}
            {this.props.colDef.field === 'in_session_values_value' && (
              <InOutSessionValuesEditorForm
                {...this.props}
                onSubmit={this.handleSubmit}
                toggleModalSession={this.toggleModalSession}
                dir="in_session_"
              />
            )}
            {this.props.colDef.field === 'out_of_session_values_value' && (
              <InOutSessionValuesEditorForm
                {...this.props}
                onSubmit={this.handleSubmit}
                toggleModalSession={this.toggleModalSession}
                dir="out_session_"
              />
            )}
            {this.props.colDef.field === 'date_calendar' && (
              <DateCalendarEditorForm
                {...this.props}
                onSubmit={this.handleSubmit}
                toggleModalSession={this.toggleModalSession}
              />
            )}
          </div>
          <div className="modal__footer" />
        </Modal>
      </div>
    );
  }
}
export default SessionRenderer;
