/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { connect } from 'react-redux';
import HeaderForm from './headerForm';
import { LiteralProps, ThemeProps } from '../../prop-types/ReducerProps';
import { copyObject } from '../../helper';

class HeaderComponent extends PureComponent {
  static propTypes = {
    frameworkComponentWrapper: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    column: PropTypes.instanceOf(Object).isRequired,
    setSort: PropTypes.func.isRequired,
    selectValueLiteral: PropTypes.string,
    Editor: PropTypes.string,
    formData: PropTypes.instanceOf(Object),
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    customName: PropTypes.string,
    treeDefaultExpandAll: PropTypes.bool,
    treeDefaultExpandedKeys: PropTypes.instanceOf(Array),
    treeDefaultVal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      )
    ]),
    showColumnMenu: PropTypes.func.isRequired,
    enableMenu: PropTypes.bool.isRequired,
    context: PropTypes.instanceOf(Object).isRequired
  };
  static defaultProps = {
    selectValueLiteral: '',
    Editor: '',
    formData: {},
    customName: '',
    treeDefaultExpandAll: true,
    treeDefaultExpandedKeys: [],
    treeDefaultVal: ''
  };
  state = {
    popoverOpen: false,
    ascSort: 'inactive',
    descSort: 'inactive',
    noSort: 'inactive'
  };
  componentDidMount() {
    this.onSortChanged();
  }
  onSortChanged = () => {
    this.setState({
      ascSort: this.props.column.isSortAscending() ? 'active' : 'inactive',
      descSort: this.props.column.isSortDescending() ? 'active' : 'inactive',
      noSort:
        !this.props.column.isSortAscending() &&
        !this.props.column.isSortDescending()
          ? 'active'
          : 'inactive'
    });
  };
  onSortRequested = (order) => {
    this.props.setSort(order, false);
    this.onSortChanged();
  };
  onFilterRequested = (e) => {
    this.props.showColumnMenu(e.target);
  };
  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };
  handleSubmit = (values) => {
    this.props.frameworkComponentWrapper.agGridReact.props.context.showLoader();
    if (
      values[this.props.column.colId] ||
      this.props.customName === 'Symbol' ||
      this.props.customName === 'ReverseTradeCopierSymbol'
    ) {
      let count = 0;
      const totalRows =
        this.props.api.getModel().rootNode.childrenAfterFilter.length;
      this.props.api.forEachNodeAfterFilter((node) => {
        let valColumn;
        if (
          this.props.column.colDef.cellEditor === 'agLargeTextCellEditor' ||
          this.props.column.colDef.cellEditor === 'agRichSelectCellEditor' ||
          this.props.Editor === 'TreeSelect' ||
          this.props.column.colDef.cellEditor === '' ||
          typeof this.props.column.colDef.cellEditor === 'undefined'
        ) {
          let val = '';
          if (this.props.customName === 'Points') {
            if (values.radio_points === 'Points') {
              const parsedVal = parseInt(values[this.props.column.colId], 10);
              const digits = parseInt(node.data.digits, 10);
              const dividend = parseInt('1'.padEnd(digits + 1, '0'), 10);
              val = (parsedVal / dividend).toString();
            } else {
              const parsedVal = parseFloat(values[this.props.column.colId]);
              val = parsedVal.toString();
            }
          } else if (this.props.customName === 'Symbol') {
            const character = values.character ? values.character.trim() : '';
            const extension = values.extension ? values.extension.trim() : '';
            if (node.data.security === 'FX') {
              val = node.data.base + character + node.data.quote + extension;
            } else {
              val = (node.data.symbol_val ?? node.data.unisymbol) + extension;
            }
            if (values.uppercase) {
              val = val ? val.toUpperCase() : '';
            }
          } else if (this.props.customName === 'ReverseTradeCopierSymbol') {
            let symbolsVal = ['*,'];
            if (
              values[this.props.column.colId] &&
              values[this.props.column.colId].length > 0 &&
              values[this.props.column.colId] !== 'Select All' &&
              values[this.props.column.colId].length !==
                this.props.frameworkComponentWrapper.agGridReact.props.context
                  .formData.SymbolCount
            ) {
              symbolsVal = values[this.props.column.colId];
            }
            val = symbolsVal;
          } else {
            val = values[this.props.column.colId];
          }
          if (this.props.Editor === 'TreeSelect') {
            val = val.join(',');
          }
          valColumn = val;
        } else {
          valColumn = values[this.props.column.colId]
            ? values[this.props.column.colId].map((x) => x.value).join(',')
            : null;
        }
        count += 1;
        const newItem = copyObject(node.data);
        newItem[this.props.column.colId] = valColumn;
        const obj = {
          oldValue: node.data[this.props.column.colId],
          value: valColumn,
          newValue: valColumn,
          node,
          colDef: this.props.column.colDef,
          column: this.props.column,
          data: newItem
        };
        this.props.context.onCellValueChanged(obj);
        this.props.api.applyTransactionAsync(
          { update: [newItem] },
          this.resultCallback(totalRows, count)
        );
      });
      this.setState({
        popoverOpen: !this.state.popoverOpen
      });
    } else {
      this.props.frameworkComponentWrapper.agGridReact.props.context.hideLoader();
    }
  };
  resultCallback = (totalRows, count) => {
    if (count === totalRows) {
      this.props.context.hideLoader();
    }
  };
  render() {
    // eslint-disable-next-line prefer-destructuring
    const formData =
      this.props.frameworkComponentWrapper.agGridReact.props.context.formData;
    const theme = this.props.theme.className;
    return (
      <span className="ag-header-cell-label ag-header-cell-label-custom">
        <span className="ag-header-cell-text">
          <div
            id={`PopoverTop${this.props.column.colId}`}
            ref={(c) => {
              this.target = c && ReactDOM.findDOMNode(c);
            }}
            onClick={this.toggle}
            className="fa fa-edit fa-400 customSortDownLabel"
            style={{ cursor: 'pointer' }}
          />
          <Popover
            placement="bottom-end"
            isOpen={this.state.popoverOpen}
            target={() => this.target}
            toggle={this.toggle}
            className={
              theme === 'theme-light'
                ? 'theme-light trading-light'
                : 'theme-dark trading-dark'
            }
          >
            <PopoverHeader>
              {this.props.literals.tables.ag_table.edit}{' '}
              {this.props.column.colDef.headerName}
            </PopoverHeader>
            <PopoverBody>
              <HeaderForm
                state={{
                  toggle: this.toggle,
                  columnId: this.props.column.colId,
                  headerName: this.props.column.colDef.headerName,
                  cellEditor: this.props.column.colDef.cellEditor,
                  customName: this.props.customName,
                  selectValues: formData
                    ? formData[this.props.selectValueLiteral]
                    : [],
                  showLoader:
                    this.props.frameworkComponentWrapper.agGridReact.props
                      .context.showLoader,
                  editor: this.props.Editor,
                  treeDefaultExpandAll: this.props.treeDefaultExpandAll,
                  treeDefaultExpandedKeys: this.props.treeDefaultExpandedKeys,
                  treeDefaultVal: this.props.treeDefaultVal
                }}
                onSubmit={this.handleSubmit}
              />
            </PopoverBody>
          </Popover>
          <div
            onClick={() => {
              this.onSortRequested('asc');
            }}
            onTouchEnd={() => {
              this.onSortRequested('asc');
            }}
            className={`customSortDownLabel ${this.state.ascSort}`}
          >
            <i
              className="fa fa-long-arrow-alt-down"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div
            onClick={() => {
              this.onSortRequested('desc');
            }}
            onTouchEnd={() => {
              this.onSortRequested('desc');
            }}
            className={`customSortDownLabel ${this.state.descSort}`}
          >
            <i
              className="fa fa-long-arrow-alt-up"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div
            onClick={() => {
              this.onSortRequested('');
            }}
            onTouchEnd={() => {
              this.onSortRequested('');
            }}
            className={`customSortDownLabel ${this.state.noSort}`}
          >
            <i className="fa fa-times" style={{ cursor: 'pointer' }} />
          </div>
          {this.props.enableMenu && (
            <div
              onClick={(e) => {
                this.onFilterRequested(e);
              }}
              className="customSortDownLabel"
            >
              <i className="fa fa-bars" style={{ cursor: 'pointer' }} />
            </div>
          )}
          <div style={{ clear: 'both' }}>
            {this.props.column.colDef.headerName}
          </div>
        </span>
      </span>
    );
  }
}
export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(HeaderComponent);
