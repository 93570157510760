import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as MobXProvider } from 'mobx-react';
import { HashRouter } from 'react-router-dom';
import App from './containers/_app/App';
import store from './redux/store';
import ScrollToTop from './containers/_app/ScrollToTop';
import RootStore from './mobx/store';

render(
  <Provider store={store}>
    <MobXProvider rootStore={RootStore}>
      <HashRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </HashRouter>
    </MobXProvider>
  </Provider>,
  document.getElementById('root'),
);
