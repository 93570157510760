/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };

  const dataToClose = {
    seqID: stateObj.node.data.sequence,
    vol: values.vol,
    price: values.price,
    closeOID: values.closeOID,
    recalcPos: values.recalcPos ? 1 : 0,
  };
  axios.post(
    `${process.env.REACT_APP_BACKEND_API}/report/close_open_trade?pos_id=${stateObj.node.data.positionid}`,
    JSON.stringify(dataToClose),
    { headers: { 'x-forward-client': stateObj.client_name } },
  )
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.tables.open_trade_table.title_close_open_trade, 'primary',
          props.literals.forms.success_message,
        );
        stateObj.toggle('1');
        const parentNode = stateObj.parent.getRowNode(stateObj.node.data.positionid);
        parentNode.setExpanded(false);
        parentNode.setExpanded(true);
      } else {
        showNotification(
          props.literals.tables.open_trade_table.title_close_open_trade, 'danger',
          props.literals.forms.fail_message,
        );
      }
    })
    .catch((error) => {
      showNotification(props.literals.tables.open_trade_table.title_close_open_trade, 'danger', error.message);
    });
};
