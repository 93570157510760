/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import MMForm from './MMForm';
import HAMForm from './HAMForm';
import TFForm from './TFForm';
import TEMForm from './TEMForm';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input, placeholder, type, disabled, step, min, max, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    {type === 'textarea' &&
    <textarea {...input} placeholder={placeholder} disabled={disabled} />
      }
    {type !== 'textarea' &&
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} min={min} max={max} />
      }
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class SessionEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    dir: PropTypes.string.isRequired,
    outSessionRevertOld: PropTypes.bool,
    colDef: PropTypes.instanceOf(Object).isRequired,
  };
  static defaultProps = {
    outSessionRevertOld: false,
  }

  componentDidMount() {
    const { value, dir } = this.props;
    if (!value) {
      this.props.change('out_session_revert_old', true);
    }
    const values = value.split(',');
    const { type } = this.props.node.data;
    if (type === 1) {
      this.props.change(`${dir}mm_markup_bid`, values[0]);
      this.props.change(`${dir}mm_markup_bid_var`, values[1]);
      this.props.change(`${dir}mm_markup_ask`, values[2]);
      this.props.change(`${dir}mm_markup_ask_var`, values[3]);
      this.props.change(`${dir}mm_spread_min`, values[4]);
      this.props.change(`${dir}mm_spread_max`, values[5]);
      this.props.change(`${dir}mm_spread_exec`, this.props.context.formData.SpreadExecObj[values[6]]);
    } else if (type === 2) {
      if (values[0] !== '') {
        this.props.change(`${dir}ham_lp_sessions`, values[0].split(';'));
      }
      this.props.change(`${dir}ham_exec_mode`, this.props.context.formData.ExecModeObj[values[1]]);
      this.props.change(`${dir}ham_filter_factor`, values[2]);
    } else if (type === 3) {
      this.props.change(`${dir}tf_ham`, this.props.context.formData.HAMSingleObj[values[0]]);
      this.props.change(`${dir}tf_mm`, this.props.context.formData.MMSingleObj[values[1]]);
    } else if (type === 4) {
      this.props.change(`${dir}tem_ham`, this.props.context.formData.HAMSingleObj[values[0]]);
      this.props.change(`${dir}tem_mm`, this.props.context.formData.MMSingleObj[values[1]]);
      this.props.change(`${dir}tem_b_perc`, values[2]);
      this.props.change(`${dir}tem_b_fix`, values[3]);
      this.props.change(`${dir}tem_b_boost`, values[4]);
      this.props.change(`${dir}tem_b_delay`, values[5]);
      this.props.change(`${dir}tem_b_delay_jitter`, values[6]);
      this.props.change(`${dir}tem_gain_perc`, values[7]);
      this.props.change(`${dir}tem_ll_variation`, values[8]);
      this.props.change(`${dir}tem_ll_action`, this.props.context.formData.LLActionObj[values[9]]);
    }
  }

  render() {
    const { literals, theme, dir } = this.props;
    const { type } = this.props.node.data;
    return (
      <div>
        <form className="form form--vertical" onSubmit={this.props.handleSubmit}>
          {this.props.colDef.field === 'out_of_session_values_value' &&
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="out_session_revert_old"
                component={renderCheckBoxField}
                label={literals.forms.action_scheduler_form.session_revert_old}
              />
            </div>
          </div>
          }
          <div className={this.props.outSessionRevertOld ? 'disabled-ui' : ''}>
            {type === 1 &&
            <MMForm
              literals={literals}
              renderField={renderField}
              spreadExec={this.props.context.formData.SpreadExec}
              dir={dir}
            />
          }
            {type === 2 &&
            <HAMForm
              literals={literals}
              theme={theme}
              renderField={renderField}
              execMode={this.props.context.formData.ExecMode}
              lpSession={this.props.context.formData.LPSession}
              dir={dir}
            />
          }
            {type === 3 &&
            <TFForm
              literals={literals}
              hamSingle={this.props.context.formData.HAMSingle}
              mmSingle={this.props.context.formData.MMSingle}
              dir={dir}
            />
          }
            {type === 4 &&
            <TEMForm
              literals={literals}
              renderField={renderField}
              hamSingle={this.props.context.formData.HAMSingle}
              mmSingle={this.props.context.formData.MMSingle}
              llAction={this.props.context.formData.LLAction}
              dir={dir}
            />
          }
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" type="submit">{literals.forms.submit}</Button>
              <Button color="primary" onClick={this.props.toggleModalSession}>
                {literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('action_scheduler_in_out_session_values_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    outSessionRevertOld: selector(state, 'out_session_revert_old'),
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(reduxForm({
  form: 'action_scheduler_in_out_session_values_form', // a unique identifier for this form
})(SessionEdit));
