import moment from 'moment';

const validate = (values, props) => {
  const errors = {};
  if (!values.start_date) {
    errors.start_date = `${props.literals.tables.ag_table.start_date} ${props.literals.forms.required_validate}`;
  }
  if (!values.end_date) {
    errors.end_date = `${props.literals.tables.ag_table.end_date} ${props.literals.forms.required_validate}`;
  }
  if (values.start_date && values.end_date) {
    const startDate = moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf();
    const endDate = moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf();
    if (endDate <= startDate) {
      errors.end_date =
      `${props.literals.tables.ag_table.end_date} ${props.literals.forms.bigger_compare_validate}
      ${props.literals.tables.ag_table.start_date}`;
    }
  }
  if (!values.symbol) {
    errors.symbol =
        `${props.literals.tables.maker_symbol_status_table.symbol_val} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default validate;
