import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, getFormValues, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import lpSessionValidate from './validate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import autoSuggestInput from './AutoSuggestInput';
import { autoSuggestEscapedValue } from '../../shared/helper';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};
const refLPSessionType = React.createRef();
const replaceSharedIDs = ['_LIVE', '_DEMO'];
const replaceSessionQualifiers = ['_TD_MD', '_MD', '_TD'];

@inject('rootStore')
class LPSessionForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      form_values: PropTypes.instanceOf(Object),
      rootStore: PropTypes.instanceOf(Object).isRequired,
      dispatch: PropTypes.func.isRequired,
      change: PropTypes.func.isRequired,
      form: PropTypes.string.isRequired,
    };
  static defaultProps = {
    form_values: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      persistMessagesHide: false,
      takerSpanExists: false,
    };
  }
  handleChangeLPSessionType = (lpSessionType) => {
    // lpSessionType value 1=>BOTH 2=>FEEDING 3=>TRADING
    if (this.props.rootStore.authStore.role !== 'admin') {
      if (lpSessionType.value === '2') {
        this.setState({ persistMessagesHide: true });
      } else {
        this.setState({ persistMessagesHide: false });
      }
    }
    this.props.change('reset_on_logon', lpSessionType.value === '1' || lpSessionType.value === '2');
    this.props.change('persist_messages', lpSessionType.value === '3');
    this.props.change('ssl_protocol', lpSessionType.value === '3');
    if (this.props.form === 'lp_session_form') {
      if (lpSessionType.value === '2') {
        const val = { label: 'TRADING', value: '3' };
        this.props.dispatch(change('lp_session_form_r', 'lp_session_type', val));
        refLPSessionType.current.props.onChange(val);
      } else if (lpSessionType.value === '3') {
        const val = { label: 'FEEDING', value: '2' };
        this.props.dispatch(change('lp_session_form_r', 'lp_session_type', val));
        refLPSessionType.current.props.onChange(val);
      } else {
        this.props.dispatch(change('lp_session_form_r', 'lp_session_type', null));
        refLPSessionType.current.props.onChange({ label: '', value: '' });
      }
    }
  };
  handleChangeLPCategory=(lpCategory) => {
    // lpSessionType value 1=>BOTH 2=>FEEDING 3=>TRADING
    if (this.props.form === 'lp_session_form' && this.props.form_values.lp_session_type &&
      ['2', '3'].includes(this.props.form_values.lp_session_type.value)) {
      this.props.dispatch(change(
        'lp_session_form_r', 'lp_category',
        { label: lpCategory.label, value: lpCategory.value },
      ));
    }
  };
  handleChangeForm=(e) => {
    // lpSessionType value 1=>BOTH 2=>FEEDING 3=>TRADING
    const { name, value } = e.target;
    if (this.props.form === 'lp_session_form' && this.props.form_values.lp_session_type &&
      ['2', '3'].includes(this.props.form_values.lp_session_type.value)) {
      if (name === 'server_address' || name === 'sender_comp_id' ||
        name === 'target_comp_id' || name === 'shared_id' ||
        name === 'username' || name === 'password' ||
        name === 'account' || name === 'session_qualifier') {
        this.props.dispatch(change('lp_session_form_r', name, value));
        if (name === 'shared_id') {
          const escapedValue = autoSuggestEscapedValue(value, replaceSharedIDs);
          if (escapedValue !== '') {
            const suggest = this.props.form_values.lp_session_type.value === '2' ? '_MD' : '_TD';
            const suggestR = this.props.form_values.lp_session_type.value === '2' ? '_TD' : '_MD';
            this.props.change('session_qualifier', escapedValue + suggest);
            this.props.dispatch(change('lp_session_form_r', 'session_qualifier', escapedValue + suggestR));
          }
        }
        if (name === 'session_qualifier') {
          const escapedValue = autoSuggestEscapedValue(value, replaceSessionQualifiers);
          if (escapedValue !== '') {
            const suggestR = this.props.form_values.lp_session_type.value === '2' ? '_TD' : '_MD';
            this.props.dispatch(change('lp_session_form_r', 'session_qualifier', escapedValue + suggestR));
          }
        }
      }
    }
    if (this.props.form_values.lp_session_type &&
  this.props.form_values.lp_session_type.value === '1' && name === 'shared_id') {
      const escapedValue = autoSuggestEscapedValue(value, replaceSharedIDs);
      if (escapedValue !== '') {
        const suggest = '_TD_MD';
        this.props.change('session_qualifier', escapedValue + suggest);
      }
    }
  };
  handleSuggestionSelectedSharedID=(sharedID) => {
    // lpSessionType value 1=>BOTH 2=>FEEDING 3=>TRADING
    if (this.props.form === 'lp_session_form' && this.props.form_values.lp_session_type &&
      ['2', '3'].includes(this.props.form_values.lp_session_type.value)) {
      this.props.dispatch(change('lp_session_form_r', 'shared_id', sharedID));
    }
  };
  handleSuggestionSelectedSessionQualifier=(sessionQualifier) => {
    // lpSessionType value 1=>BOTH 2=>FEEDING 3=>TRADING
    if (this.props.form === 'lp_session_form' && this.props.form_values.lp_session_type &&
      ['2', '3'].includes(this.props.form_values.lp_session_type.value)) {
      const escapedValue = autoSuggestEscapedValue(sessionQualifier, replaceSessionQualifiers);
      if (escapedValue !== '') {
        const suggestR = this.props.form_values.lp_session_type.value === '2' ? '_TD' : '_MD';
        this.props.dispatch(change('lp_session_form_r', 'session_qualifier', escapedValue + suggestR));
      }
    }
  };
  handleTakerChange=(taker) => {
    const takers = this.props.state.formData.Taker;
    if (takers[taker.target.value] === true) {
      this.setState({ takerSpanExists: true });
    } else {
      this.setState({ takerSpanExists: false });
    }
  }

  render() {
    const {
      handleSubmit, pristine, reset, submitting, literals,
    } = this.props;
    const lpCategoryValues = this.props.state.formData.LPCategory || [];
    const lpSessionTypeValues = this.props.state.formData.LPSessionType || [];
    const lpSessionsArr = this.props.state.formData.LPSession || [];
    const lpSessions = lpSessionsArr.filter(lpSession => lpSession.value);
    const disabled = false;
    const { submit } = literals.forms.lp_session_form;
    const { role } = this.props.rootStore.authStore;
    let suggestSessionQualifier = '';
    const lpSessionType = this.props.form_values.lp_session_type;
    if (lpSessionType) {
      if (lpSessionType.value === '1') {
        suggestSessionQualifier = '_TD_MD';
      } else
      if (lpSessionType.value === '2') {
        suggestSessionQualifier = '_MD';
      } else if (lpSessionType.value === '3') {
        suggestSessionQualifier = '_TD';
      }
    }
    const days = [{ label: 'Friday', value: 'Friday' }, { label: 'Saturday', value: 'Saturday' },
      { label: 'Sunday', value: 'Sunday' }, { label: 'Monday', value: 'Monday' },
      { label: 'Tuesday', value: 'Tuesday' }, { label: 'Wednesday', value: 'Wednesday' },
      { label: 'Thursday', value: 'Thursday' },
    ];
    return (
      <form
        className="form form--vertical"
        onSubmit={handleSubmit}
        onChange={this.handleChangeForm}
      >
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.forms.lp_session_form.lp_session_type}</span>
            <div className="form__form-group-field">
              <Field
                ref={this.props.form === 'lp_session_form_r' ? refLPSessionType : null}
                name="lp_session_type"
                component={renderSelectField}
                type="text"
                options={lpSessionTypeValues}
                disabled={disabled}
                onChange={this.handleChangeLPSessionType}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.forms.lp_session_form.lp_category}</span>
            <div className="form__form-group-field">
              <Field
                name="lp_category"
                component={renderSelectField}
                type="text"
                options={lpCategoryValues}
                disabled={disabled}
                onChange={this.handleChangeLPCategory}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.lp_session_form.server_address}
            </span>
            <div className="form__form-group-field">
              <Field
                name="server_address"
                component={renderField}
                type="text"
                placeholder={literals.forms.lp_session_form.server_address}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.forms.lp_session_form.sender_comp_id}</span>
            <div className="form__form-group-field">
              <Field
                name="sender_comp_id"
                component={renderField}
                type="text"
                placeholder={literals.forms.lp_session_form.sender_comp_id}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.lp_session_form.target_comp_id}
            </span>
            <div className="form__form-group-field">
              <Field
                name="target_comp_id"
                component={renderField}
                type="text"
                placeholder={literals.forms.lp_session_form.target_comp_id}
                disabled={disabled}
              />
            </div>
          </div>
          {role === 'admin' &&
          <React.Fragment>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                <Field
                  name="external_maker_api"
                  component={renderCheckBoxField}
                  label={literals.forms.lp_session_form.external_maker_api}
                />
              </div>
            </div>
            {this.props.form_values.external_maker_api &&
            <div className="borderValuesForm">
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.forms.lp_session_form.external_maker}</span>
                <div className="form__form-group-field">
                  <Field
                    name="external_maker"
                    component={renderField}
                    type="text"
                    placeholder={literals.forms.lp_session_form.external_maker}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.forms.lp_session_form.taker}</span>
                <div className="form__form-group-field">
                  <Field
                    name="taker"
                    component={renderField}
                    type="text"
                    placeholder={literals.forms.lp_session_form.taker}
                    onChange={this.handleTakerChange}
                  />
                </div>
                {this.state.takerSpanExists &&
                <span className="form__form-group-error" style={{ color: '#ff9966' }}>
                Taker already exists!
                </span>
                }
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.forms.lp_session_form.protocol}</span>
                <div className="form__form-group-field">
                  <Field
                    name="protocol"
                    component={renderField}
                    type="text"
                    placeholder={literals.forms.lp_session_form.protocol}
                  />
                </div>
              </div>
            </div>
            }
          </React.Fragment>
              }
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.forms.lp_session_form.shared_id}</span>
            <div className="form__form-group-field">
              <Field
                name="shared_id"
                component={autoSuggestInput}
                placeholder={literals.forms.lp_session_form.shared_id}
                suggest={process.env.REACT_APP_ENV === 'production' ? '_LIVE' : '_DEMO'}
                replace={replaceSharedIDs}
                onSuggestionSelected={this.handleSuggestionSelectedSharedID}
                lpSessions={lpSessions}
                lpSessionType={lpSessionType ? lpSessionType.value : ''}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.forms.lp_session_form.currency}</span>
            <div className="form__form-group-field">
              <Field
                name="currency"
                component={renderField}
                type="text"
                placeholder={literals.forms.lp_session_form.currency}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="form__half">
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.lp_session_form.username}
            </span>
            <div className="form__form-group-field">
              <Field
                name="username"
                component={renderField}
                type="text"
                placeholder={literals.forms.lp_session_form.username}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.lp_session_form.password}
            </span>
            <div className="form__form-group-field">
              <Field
                name="password"
                component={renderField}
                type="text"
                placeholder={literals.forms.lp_session_form.password}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.lp_session_form.account}
            </span>
            <div className="form__form-group-field">
              <Field
                name="account"
                component={renderField}
                type="text"
                placeholder={literals.forms.lp_session_form.account}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.lp_session_form.session_qualifier}
            </span>
            <div className="form__form-group-field">
              <Field
                name="session_qualifier"
                component={autoSuggestInput}
                placeholder={literals.forms.lp_session_form.session_qualifier}
                suggest={suggestSessionQualifier}
                replace={replaceSessionQualifiers}
                onSuggestionSelected={this.handleSuggestionSelectedSessionQualifier}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.lp_session_form.session_values}
            </span>
            <div className="form__form-group-field multiple-checkboxes">
              <Field
                name="disable_log"
                component={renderCheckBoxField}
                label={literals.forms.lp_session_form.disable_log}
                className={role !== 'admin' ? ' hide' : ' show'}
              />
              <Field
                name="enabled"
                component={renderCheckBoxField}
                label={literals.forms.lp_session_form.enabled}
                defaultChecked
                className={role !== 'admin' ? ' hide' : ' show'}
              />
              <Field
                name="reset_on_logon"
                component={renderCheckBoxField}
                label={literals.forms.lp_session_form.reset_on_logon}
              />
              <Field
                name="persist_messages"
                component={renderCheckBoxField}
                label={literals.forms.lp_session_form.persist_messages}
                className={this.state.persistMessagesHide ? ' hide' : ' show'}
              />
              <Field
                name="ssl_protocol"
                component={renderCheckBoxField}
                label={literals.forms.lp_session_form.ssl_protocol}
              />
            </div>
          </div>
          <div className="borderValuesForm">
            <div className="form__form-group" style={{ width: '40%', display: 'inline-block', paddingRight: 5 }}>
              <span
                className="form__form-group-label"
              >{literals.forms.lp_session_form.start_day}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="start_day"
                  component={renderSelectField}
                  type="text"
                  options={days}
                />
              </div>
            </div>
            <div className="form__form-group" style={{ width: '40%', display: 'inline-block', verticalAlign: 'top' }}>
              <span
                className="form__form-group-label"
              >{literals.forms.lp_session_form.start_time}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="start_time"
                  component={renderField}
                  type="text"
                />
              </div>
            </div>
            <span style={{ width: '20%', display: 'inline-block', color: 'orange' }}>UTC+0</span>
            <div className="form__form-group" style={{ width: '40%', display: 'inline-block', paddingRight: 5 }}>
              <span
                className="form__form-group-label"
              >{literals.forms.lp_session_form.end_day}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="end_day"
                  component={renderSelectField}
                  type="text"
                  options={days}
                />
              </div>
            </div>
            <div className="form__form-group" style={{ width: '40%', display: 'inline-block', verticalAlign: 'top' }}>
              <span
                className="form__form-group-label"
              >{literals.forms.lp_session_form.end_time}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="end_time"
                  component={renderField}
                  type="text"
                />
              </div>
            </div>
            <span style={{ width: '20%', display: 'inline-block', color: 'orange' }}>UTC+0</span>
          </div>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{literals.forms.node_account_form.copy_from}
            </span>
            <div className="form__form-group-field">
              <Field
                name="copy_from"
                component={renderSelectField}
                type="text"
                options={this.props.state.formData.LPSession}
                disabled={disabled}
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">{submit}</Button>
            <Button
              color="primary"
              type="button"
              onClick={() => this.props.state.toggle('1')}
            >{literals.forms.lp_session_form.cancel}
            </Button>
            <Button type="button" onClick={reset} disabled={pristine || submitting}>
              {literals.forms.lp_session_form.reset}
            </Button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.state) {
    if (ownProps.state.detail) {
      return {
        literals: state.literals,
        form_values: getFormValues(ownProps.form)(state),
        initialValues: {
          lp_category: ownProps.state.detail.lp_category,
          lp_session_type: ownProps.state.detail.lp_session_type,
          server_address: ownProps.state.detail.server_address,
          sender_comp_id: ownProps.state.detail.sender_comp_id,
          target_comp_id: ownProps.state.detail.target_comp_id,
          shared_id: ownProps.state.detail.shared_id,
          currency: ownProps.state.detail.currency,
          username: ownProps.state.detail.username,
          password: ownProps.state.detail.password,
          account: ownProps.state.detail.account,
          session_qualifier: ownProps.state.detail.session_qualifier,
          disable_log: ownProps.state.detail.disable_log,
          enabled: ownProps.state.detail.enabled,
          reset_on_logon: ownProps.state.detail.reset_on_logon,
          persist_messages: ownProps.state.detail.persist_messages,
          ssl_protocol: ownProps.state.detail.ssl_protocol,
          stateMode: ownProps.state.mode,
          formData: ownProps.state.formData,
        },
        form: ownProps.form,
      };
    }
    return {
      literals: state.literals,
      form_values: getFormValues(ownProps.form)(state),
      initialValues: {
        stateMode: ownProps.state.mode,
        formData: ownProps.state.formData,
        enabled: true,
        currency: 'USD',
        start_day: { label: 'Friday', value: 'Friday' },
        start_time: '00:00:00',
        end_day: { label: 'Friday', value: 'Friday' },
        end_time: '00:00:00',
      },
      form: ownProps.form,
    };
  }
  return { literals: state.literals, form_values: getFormValues(ownProps.form)(state), form: ownProps.form };
}


export default connect(mapStateToProps)(reduxForm({
  validate: lpSessionValidate,
})(LPSessionForm));
