import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

export default class TypeEditor extends PureComponent {
    static propTypes = {
      context: PropTypes.instanceOf(Object).isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      value: PropTypes.string,
    };
    static defaultProps = {
      value: null,
    };
    state = {
      selectedOption: this.props.value ? _.find(
        this.props.context.formData.Type,
        ['label', this.props.value],
      ) : null,
    };
    getValue() {
      return this.state.selectedOption ? this.state.selectedOption.label : null;
    }
    isPopup=() => true;
    handleChange = (selectedOption) => {
      this.setState({ selectedOption });
    };
    render() {
      const { selectedOption } = this.state;
      const colourStyles = {
        control: styles => ({
          ...styles, minHeight: 22, height: 22, borderRadius: 0,
        }),
        indicatorsContainer: styles => ({ ...styles, height: 22 }),
        valueContainer: styles => ({ ...styles, height: 22, position: 'unset' }),
      };
      return (
        <Select
          styles={colourStyles}
          value={selectedOption}
          onChange={this.handleChange}
          options={this.props.node.data.category === 0 ?
            this.props.context.formData.Type :
            _.filter(this.props.context.formData.Type, tp => tp.value !== 2)}
          className="form__form-group-select"
          classNamePrefix="Select"
          placeholder="Type"
        />
      );
    }
}
