/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import Websocket from 'react-websocket';
import { getFormValues } from 'redux-form';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import BalanceTransactionForm from './form';
import balanceTransactionAction from './action';
import AccountBalance from './account_balance';
import AccountBalanceState from './account_balance_state';
import Loader from '../../shared/components/Loader';

@inject('rootStore')
class BalanceTransaction extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
      search_values: PropTypes.instanceOf(Object),
    };
    static defaultProps = {
      search_values: {},
    };
    constructor(props) {
      super(props);
      this.state = {
        formData: [],
        activeTab: '1',
        rowDataBalance: [],
        rowDataState: [],
        loaderShow: false,
      };
    }
    componentDidMount() {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/account/balance_transaction`)
        .then((response) => {
          this.setState({
            formData: {
              RiskAccount: response.data.RiskAccount,
              Type: response.data.Type,
              TypeBroker: response.data.TypeBroker,
              Direction: response.data.Direction,
            },
          });
        })
        .catch(() => {
        });
    }

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    socketMessageListener = (event) => {
      const data = JSON.parse(event);
      if ('account_balance_report' in data && !data.api_error) {
        if (data.account_balance_report && data.account_balance_report.length > 0) {
          this.setState({ rowDataBalance: data.account_balance_report });
        }
      }
      if ('account_balance_report_state' in data && !data.api_error) {
        if (data.account_balance_report_state && data.account_balance_report_state.length > 0) {
          this.setState({ rowDataState: data.account_balance_report_state });
        }
      }
    };
    socketOpenListener = () => {
      const values = this.props.search_values;
      this.sendMessage(JSON.stringify({
        key: 'account_balance_report',
        value: JSON.stringify({
          risk_account: values.risk_account,
        }),
      }));
    };
    sendMessage=(message) => {
      this.refWebSocket.sendMessage(message);
    };

    render() {
      const { literals } = this.props;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.balance_transaction}
                </h5>
              </div>
              <div>
                <div className="balance_transaction_form">
                  <BalanceTransactionForm
                    {...this.props}
                    state={{
                  formData: this.state.formData,
                  showLoader: this.showLoader,
                  hideLoader: this.hideLoader,
                }}
                    onSubmit={balanceTransactionAction}
                  />
                </div>
                <div className="balance_transaction_state">
                  <AccountBalanceState rowDataState={this.state.rowDataState} />
                </div>
              </div>
              <AccountBalance rowDataBalance={this.state.rowDataBalance} sendMessage={this.sendMessage} />
              <Websocket
                url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
                reconnectIntervalInMilliSeconds={1000}
                onMessage={this.socketMessageListener}
                onOpen={this.socketOpenListener}
                ref={(WS) => {
                  this.refWebSocket = WS;
                }}
              />
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  search_values: getFormValues('account_balance_search_form')(state),
}))(BalanceTransaction);
