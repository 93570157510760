import React, { PureComponent } from 'react';
import { Button, Popover, PopoverBody, PopoverHeader, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import pushTickValidate from './validate';
import handlePushTickSubmit from './submit';
import renderInputNumberField from '../../shared/components/form/NumberInput';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';

const renderField = ({
  input, placeholder, type, disabled, step, min, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} min={min} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class PushTickActionForm extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      uniSymbol: PropTypes.string,
      makerSession: PropTypes.string,
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
    };
    static defaultProps = {
      uniSymbol: '',
      makerSession: '',
    };
    constructor(props) {
      super(props);
      this.state = {
        popoverOpen: false,
      };
    }

    togglePopover = () => {
      this.setState({
        popoverOpen: !this.state.popoverOpen,
      });
    };

    numberParser=(value) => {
      const reg = /^[0-9\d,]*\.?[0-9\d,]*$/;
      let input = '';
      if (reg.test(value)) {
        input = value;
      }
      return input;
    };

    render() {
      const {
        handleSubmit, literals,
      } = this.props;
      const theme = this.props.theme.className;
      return (
        <form className="form form--vertical" onSubmit={handleSubmit}>
          <div className="form__half form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.symbol}</span>
              <div className="form__form-group-field">
                <span className="form__form-group-label input-style" style={{ padding: '0 10px' }}>
                  {this.props.uniSymbol}
                </span>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.bid}</span>
              <div className="form__form-group-field">
                <Field
                  name="bid"
                  component={renderInputNumberField}
                  step={0.1}
                  parser={this.numberParser}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.ask}</span>
              <div className="form__form-group-field">
                <Field
                  name="ask"
                  component={renderInputNumberField}
                  step={0.1}
                  parser={this.numberParser}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.lp_symbol_form.lp_session}</span>
              <div className="form__form-group-field">
                <span className="form__form-group-label input-style" style={{ padding: '0 10px' }}>
                  {this.props.makerSession}
                </span>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.bid_volume}</span>
              <div className="form__form-group-field">
                <Field
                  name="bid_vol"
                  component={renderInputNumberField}
                  step={10}
                  parser={this.numberParser}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.ask_volume}</span>
              <div className="form__form-group-field">
                <Field
                  name="ask_vol"
                  component={renderInputNumberField}
                  step={10}
                  parser={this.numberParser}
                />
              </div>
            </div>
          </div>
          <div className="form__half trading__button__last">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Button
                  id="PopoverTop"
                  color="blue"
                  type="button"
                  onClick={this.togglePopover}
                >{literals.forms.submit.toUpperCase()}
                </Button>
                <Popover
                  placement="top"
                  isOpen={this.state.popoverOpen}
                  target="PopoverTop"
                  toggle={this.togglePopover}
                  className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
                >
                  <PopoverHeader>{literals.tables.trading_table.confirm_push_ticks}</PopoverHeader>
                  <PopoverBody>
                    <span>{literals.tables.trading_table.push_ticks_message}
                    </span>
                    <ButtonToolbar className="btn-toolbar--center">
                      <Button className="btn btn-blue" onClick={this.togglePopover}>
                        {literals.forms.cancel}
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={handleSubmit(values => handlePushTickSubmit({
                            ...values,
                            symbol: this.props.uniSymbol,
                            makerSession: this.props.makerSession,
                            toggle: this.togglePopover,
                            literals,
                        }))}
                      >
                        {literals.forms.confirm}
                      </Button>
                    </ButtonToolbar>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
          </div>
        </form>
      );
    }
}

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    initialValues: {
      bid: 0,
      ask: 0,
      bid_vol: 0,
      ask_vol: 0,
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'push_tick_form', // a unique identifier for this form
  validate: pushTickValidate,
})(PushTickActionForm));
