/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import uniSymbolValidate from './validate';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/Select';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import renderInputNumberField from '../../shared/components/form/NumberInputCommon';
import SessionEdit from '../../shared/components/session/SessionEdit';
import SessionEditForm from '../../shared/components/session/SessionEditForm';

const renderField = ({
  input, placeholder, type, disabled, step, min, max, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    {type === 'textarea' &&
    <textarea {...input} placeholder={placeholder} disabled={disabled} />
    }
    {type !== 'textarea' &&
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} min={min} max={max} />
    }
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class UniSymbolForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      change: PropTypes.func.isRequired,
      security: PropTypes.string,
      name: PropTypes.string,
      sessions: PropTypes.string,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
  static defaultProps = {
    security: '',
    name: '',
    sessions: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      sessionModalShow: false,
      selectedOption: 'session_interface',
    };
  }
  onChangeName=(e, value) => {
    if (this.props.security) {
      if (this.props.security === 'FX') {
        if (value.length > 5) {
          this.props.change('base', value.substring(0, 3));
          const v = value.substring(3);
          const { index } = /[a-z0-9]/i.exec(v);
          this.props.change('quote', v.substring(index));
        } else {
          this.props.change('base', '');
          this.props.change('quote', '');
        }
      } else {
        this.props.change('base', value);
      }
    }
  };
  onChangeSecurity=(e, value) => {
    if (value === 'FX') {
      if (this.props.name.length > 5) {
        this.props.change('base', this.props.name.substring(0, 3));
        const v = this.props.name.substring(3);
        const { index } = /[a-z0-9]/i.exec(v);
        this.props.change('quote', v.substring(index));
      }
    } else {
      this.props.change('base', this.props.name);
    }
  };
  toggleModalSession = () => {
    this.setState({ sessionModalShow: !this.state.sessionModalShow });
  };
  submitSession=(session) => {
    this.props.change('sessions', session);
    this.toggleModalSession();
  };
  resetSession=() => {
    this.toggleModalSession();
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.target.value });
  };
  render() {
    const {
      handleSubmit, pristine, reset, submitting, literals, theme,
    } = this.props;
    let securityValues = [];
    let categoryValues = [];
    if (this.props.state) {
      securityValues = this.props.state.formData.Security;
      categoryValues = this.props.state.formData.Category;
    } else {
      securityValues = JSON.parse(JSON.parse(localStorage.getItem('persist:form')).uni_symbol_form)
        .values.formData.Security;
      categoryValues = JSON.parse(JSON.parse(localStorage.getItem('persist:form')).uni_symbol_form)
        .values.formData.Category;
    }
    const disabled = false;
    const { submit } = literals.forms.uni_symbol_form;
    const { selectedOption } = this.state;
    return (
      <div>
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.uni_symbol_form.name}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.uni_symbol_form.name}
                  disabled={disabled}
                  onChange={this.onChangeName}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.uni_symbol_form.security}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="security"
                  component={renderSelectField}
                  type="text"
                  options={securityValues}
                  disabled={disabled}
                  onChange={this.onChangeSecurity}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.uni_symbol_form.base}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="base"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.uni_symbol_form.base}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.uni_symbol_form.quote}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="quote"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.uni_symbol_form.quote}
                  disabled={disabled}
                />
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.uni_symbol_form.digits}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="digits"
                    component={renderInputNumberField}
                    type="number"
                    min={0}
                    max={10}
                    placeholder={literals.forms.uni_symbol_form.digits}
                    disabled={disabled}
                  />
                </div>
              </div>
              {this.props.rootStore.authStore.role === 'admin'
              && this.props.rootStore.clientStore.CC !== 'SahamHolding_btguq4vvuaijetmjbm20' &&
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {literals.forms.uni_symbol_form.vol_digits}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="vol_digits"
                    component={renderInputNumberField}
                    type="number"
                    min={0}
                    max={10}
                    placeholder={literals.forms.uni_symbol_form.vol_digits}
                    disabled={disabled}
                  />
                </div>
              </div>
              }
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.uni_symbol_form.state}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="state"
                    component={renderCheckBoxField}
                    label={literals.forms.uni_symbol_form.state}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.uni_symbol_form.exposure_multiplier}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="exposure_multiplier"
                    component={renderInputNumberField}
                    type="number"
                    min={0}
                    step="0.01"
                    placeholder={literals.forms.uni_symbol_form.exposure_multiplier}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.uni_symbol_form.category}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="category"
                  component={renderSelectField}
                  type="text"
                  options={categoryValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.uni_symbol_form.isin}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="isin"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.uni_symbol_form.isin}
                  disabled={disabled}
                />
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.uni_symbol_form.sessions} (
                  <Button
                    style={{
                      marginBottom: 0,
                      padding: '0 10px',
                    }}
                    color="link"
                    onClick={this.toggleModalSession}
                  >Edit
                  </Button>)
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="sessions"
                    component={renderField}
                    type="textarea"
                    placeholder={literals.forms.uni_symbol_form.sessions}
                    disabled
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.uni_symbol_form.description}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="description"
                    component="textarea"
                    type="text"
                    placeholder={literals.forms.uni_symbol_form.description}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.uni_symbol_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.uni_symbol_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
        <Modal
          isOpen={this.state.sessionModalShow}
          toggle={() => this.toggleModalSession}
          className={`modal-dialog--primary modal-dialog--header crp-modal session-modal ${theme.className}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleModalSession()} />
            <h4 className="bold-text  modal__title">{literals.forms.uni_symbol_form.sessions}</h4>
          </div>
          <div className="modal__body risk_account_modal_body">
            <label
              className="radio-btn"
            >
              <input
                className="radio-btn__radio"
                type="radio"
                name="session_edit"
                value="session_interface"
                checked={selectedOption === 'session_interface'}
                onChange={this.handleChange}
              />
              <span className="radio-btn__radio-custom" />
              <span className="radio-btn__label">
                {literals.tables.ag_table.interface}
              </span>
            </label>
            <label
              className="radio-btn"
            >
              <input
                className="radio-btn__radio"
                type="radio"
                name="session_edit"
                value="session_text"
                checked={selectedOption === 'session_text'}
                onChange={this.handleChange}
              />
              <span className="radio-btn__radio-custom" />
              <span className="radio-btn__label">
                {literals.tables.ag_table.free_text}
              </span>
            </label>
            {this.state.selectedOption === 'session_text' &&
            <SessionEditForm
              sessions={this.props.sessions}
              initialSessionValue={this.props.sessions}
              submitSession={this.submitSession}
              resetSession={this.resetSession}
            />
            }
            {this.state.selectedOption === 'session_interface' &&
            <SessionEdit
              sessions={this.props.sessions}
              initialSessionValue={this.props.sessions}
              submitSession={this.submitSession}
              resetSession={this.resetSession}
            />
            }
          </div>
          <div className="modal__footer" />
        </Modal>
      </div>
    );
  }
}
const selector = formValueSelector('uni_symbol_form');
function mapStateToProps(state, ownProps) {
  if (ownProps.state) {
    if (ownProps.state.detail) {
      return {
        literals: state.literals,
        theme: state.theme,
        name: selector(state, 'name'),
        security: selector(state, 'security'),
        sessions: selector(state, 'sessions'),
        initialValues: {
          name: ownProps.state.detail.name,
          security: ownProps.state.detail.security,
          base: ownProps.state.detail.base,
          quote: ownProps.state.detail.quote,
          category: ownProps.state.detail.category,
          digits: ownProps.state.detail.digits,
          vol_digits: ownProps.state.detail.vol_digits,
          state: ownProps.state.detail.state,
          sessions: ownProps.state.detail.sessions,
          isin: ownProps.state.detail.isin,
          description: ownProps.state.detail.description,
          stateMode: ownProps.state.mode,
          formData: ownProps.state.formData,
        },
      };
    }
    return {
      literals: state.literals,
      theme: state.theme,
      name: selector(state, 'name'),
      security: selector(state, 'security'),
      sessions: selector(state, 'sessions'),
      initialValues: {
        category: 0,
        state: false,
        sessions: 'MON,00:00-23:59;TUE,00:00-23:59;WED,00:00-23:59;' +
            'THU,00:00-23:59;FRI,00:00-23:59;SAT,00:00-23:59;SUN,00:00-23:59;',
        stateMode: ownProps.state.mode,
        formData: ownProps.state.formData,
        exposure_multiplier: 1,
        vol_digits: 2,
      },
    };
  }
  return {
    literals: state.literals,
    theme: state.theme,
    name: selector(state, 'name'),
    security: selector(state, 'security'),
    sessions: selector(state, 'sessions'),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'uni_symbol_form', // a unique identifier for this form
  validate: uniSymbolValidate,
})(UniSymbolForm));
