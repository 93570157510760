import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import renderInputNumberField from '../form/NumberInputCommon';
import { LiteralProps } from '../../prop-types/ReducerProps';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class DepthLevelForm extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      isUpdateDepthLevel: PropTypes.bool.isRequired,
      cancelDepthLevelEdit: PropTypes.func.isRequired,
      literals: LiteralProps.isRequired,
    };

    render() {
      const {
        handleSubmit, literals,
      } = this.props;
      let submitText = this.props.literals.tables.ag_table.add;
      if (this.props.isUpdateDepthLevel > 0) {
        submitText = this.props.literals.tables.ag_table.update;
      }
      return (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__quarter form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.aggregate_group_form.bid_vol_from}</span>
              <div className="form__form-group-field">
                <Field
                  name="bid_vol_from"
                  component={renderInputNumberField}
                  type="number"
                  min={-1}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.aggregate_group_form.ask_vol_from}</span>
              <div className="form__form-group-field">
                <Field
                  name="ask_vol_from"
                  component={renderInputNumberField}
                  type="number"
                  min={-1}
                />
              </div>
            </div>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.aggregate_group_form.bid_vol_to}</span>
              <div className="form__form-group-field">
                <Field
                  name="bid_vol_to"
                  component={renderInputNumberField}
                  type="number"
                  min={-1}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.aggregate_group_form.ask_vol_to}</span>
              <div className="form__form-group-field">
                <Field
                  name="ask_vol_to"
                  component={renderInputNumberField}
                  type="number"
                  min={-1}
                />
              </div>
            </div>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.aggregate_group_form.bid_markup}</span>
              <div className="form__form-group-field">
                <Field
                  name="bid_markup"
                  component={renderInputNumberField}
                  type="number"
                  precision={0}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.aggregate_group_form.ask_markup}</span>
              <div className="form__form-group-field">
                <Field
                  name="ask_markup"
                  component={renderInputNumberField}
                  type="number"
                  precision={0}
                />
              </div>
            </div>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <Button
                color="primary"
                onClick={this.props.cancelDepthLevelEdit}
                style={{ marginTop: 20 }}
              >
                {this.props.literals.forms.cancel}
              </Button>
            </div>
            <div className="form__form-group">
              <Button color="primary" type="submit" style={{ marginTop: 10 }}>
                {submitText === 'Add' ? <span style={{ paddingLeft: 8, paddingRight: 8 }}>{submitText}</span>
                    : submitText }
              </Button>
            </div>
          </div>
        </form>
      );
    }
}
function mapStateToProps(state) {
  return { literals: state.literals };
}
export default connect(mapStateToProps)(reduxForm({
  form: 'depth_level_form', // a unique identifier for this form
})(DepthLevelForm));
