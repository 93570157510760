/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import makerScalingSymbolValidate from './validate';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/Select';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import renderInputNumberField from '../../shared/components/form/NumberInputCommon';
import renderTreeSelectFieldSingle from '../../shared/components/form/TreeSelectSingle';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  step,
  min,
  max,
  meta: { touched, error }
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    {type === 'textarea' && (
      <textarea {...input} placeholder={placeholder} disabled={disabled} />
    )}
    {type !== 'textarea' && (
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        step={step}
        min={min}
        max={max}
      />
    )}
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false
};

class MakerScalingSymbolForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    state: PropTypes.instanceOf(Object).isRequired,
    change: PropTypes.func.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, literals, theme } =
      this.props;
    const disabled = false;
    const { submit } = literals.forms.maker_scaling_symbol_form;
    return (
      <div>
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {literals.forms.maker_scaling_symbol_form.unisymbol}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="unisymbol"
                  component={renderTreeSelectFieldSingle}
                  treeData={this.props.state.formData.UniSymbol}
                  treeDefaultExpandedKeys={['Select All']}
                  className={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                  dropdownClassName={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {literals.forms.maker_scaling_symbol_form.shared_id}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="shared_id"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.MakerSession}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {literals.forms.maker_scaling_symbol_form.price_scale_in}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="price_scale_in"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  placeholder={
                    literals.forms.maker_scaling_symbol_form.price_scale_in
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {literals.forms.maker_scaling_symbol_form.price_scale_out}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="price_scale_out"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  placeholder={
                    literals.forms.maker_scaling_symbol_form.price_scale_out
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {literals.forms.maker_scaling_symbol_form.volume_scale_in}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="volume_scale_in"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  placeholder={
                    literals.forms.maker_scaling_symbol_form.volume_scale_in
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {literals.forms.maker_scaling_symbol_form.volume_scale_out}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="volume_scale_out"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  placeholder={
                    literals.forms.maker_scaling_symbol_form.volume_scale_out
                  }
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {literals.forms.maker_scaling_symbol_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component="textarea"
                  type="text"
                  placeholder={
                    literals.forms.maker_scaling_symbol_form.description
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {literals.forms.uni_symbol_form.state}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="state"
                  component={renderCheckBoxField}
                  label={literals.forms.uni_symbol_form.state}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">
                {submit}
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >
                {literals.forms.maker_scaling_symbol_form.cancel}
              </Button>
              <Button
                type="button"
                onClick={reset}
                disabled={pristine || submitting}
              >
                {literals.forms.maker_scaling_symbol_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    theme: state.theme,
    initialValues: {
      state: false,
      formData: ownProps.state.formData
    }
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'maker_scaling_symbol_form', // a unique identifier for this form
    validate: makerScalingSymbolValidate
  })(MakerScalingSymbolForm)
);
