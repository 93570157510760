import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { reducer as reduxFormReducer } from 'redux-form';
import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import languageReducer from './languageReducer';
import literalReducer from './literalReducer';
import literalENGlobalReducer from './literalENGlobalReducer';
import marketWatchReducer from './marketWatchReducer';
import actionScheduler from './action_scheduler';

const themePersistConfig = {
  key: 'theme',
  storage,
};
const sidebarPersistConfig = {
  key: 'sidebar',
  storage,
};
const languagePersistConfig = {
  key: 'language',
  storage,
};
const formPersistConfig = {
  key: 'form',
  storage,
};
const rootReducer = combineReducers({
  theme: persistReducer(themePersistConfig, themeReducer),
  sidebar: persistReducer(sidebarPersistConfig, sidebarReducer),
  language: persistReducer(languagePersistConfig, languageReducer),
  form: persistReducer(formPersistConfig, reduxFormReducer),
  literals: literalReducer,
  literalsENGlobal: literalENGlobalReducer,
  marketWatchProp: marketWatchReducer,
  actionScheduler,
});
export default rootReducer;
