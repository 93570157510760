/* eslint-disable arrow-parens */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized
} from '../../shared/helper';
import Collapse from '../../shared/components/Collapse';
import AccountBalanceSearchForm from './search';

const exportColumns = [
  'name',
  'currency',
  'balance',
  'available_withdraw',
  'equity',
  'pl',
  'margin',
  'margin_level',
  'commission',
  'swaps',
  'notional',
  'credit',
  'trading_state_value'
];

class AccountBalance extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rowDataBalance: PropTypes.instanceOf(Array).isRequired,
    sendMessage: PropTypes.instanceOf(Object).isRequired
  };

  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_balance_transaction';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.account_balance_table.name,
          field: 'name',
          headerTooltip: literals.tables.account_balance_table.name,
          width: 60,
          minWidth: 60
        },
        {
          headerName: literals.tables.account_balance_table.currency,
          field: 'currency',
          headerTooltip: literals.tables.account_balance_table.currency,
          width: 60,
          minWidth: 60
        },
        {
          headerName: literals.tables.account_balance_table.balance,
          field: 'balance',
          headerTooltip: literals.tables.account_balance_table.balance,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.available_withdraw,
          field: 'available_withdraw',
          headerTooltip:
            literals.tables.account_balance_table.available_withdraw,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.equity,
          field: 'equity',
          headerTooltip: literals.tables.account_balance_table.equity,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.pl,
          field: 'pl',
          headerTooltip: literals.tables.account_balance_table.pl,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(
                params.value
              )}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(
              params.value
            )}</span>`;
          }
        },
        {
          headerName: literals.tables.account_balance_table.margin,
          field: 'margin',
          headerTooltip: literals.tables.account_balance_table.margin,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.margin_level,
          field: 'margin_level',
          headerTooltip: literals.tables.account_balance_table.margin_level,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.commission,
          field: 'commission',
          headerTooltip: literals.tables.account_balance_table.commission,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.swaps,
          field: 'swaps',
          headerTooltip: literals.tables.account_balance_table.swaps,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.notional,
          field: 'notional',
          headerTooltip: literals.tables.account_balance_table.notional,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.credit,
          field: 'credit',
          headerTooltip: literals.tables.account_balance_table.credit,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.account_balance_table.trading_state,
          field: 'trading_state_value',
          headerTooltip: literals.tables.account_balance_table.trading_state,
          width: 60,
          minWidth: 60,
          cellRenderer(params) {
            if (params.value === 'REACHED NOP') {
              return `<span class="cell-renderer-blue">${params.value}</span>`;
            } else if (params.value === 'STOPPED OUT') {
              return `<span class="cell-renderer-red">${params.value}</span>`;
            } else if (params.value === 'MARGIN CALL') {
              return `<span class="cell-renderer-brown">${params.value}</span>`;
            }
            return `<span style='font:inherit'>${params.value}</span>`;
          }
        }
      ],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50
      },
      getRowNodeId(data) {
        return data.name;
      }
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (
        literals.tables.account_balance_table.name !==
        prevProps.literals.tables.account_balance_table.name
      ) {
        this.gridColumnApi.getColumn('name').getColDef().headerName =
          literals.tables.account_balance_table.name;
        this.gridColumnApi.getColumn('balance').getColDef().headerName =
          literals.tables.account_balance_table.balance;
        this.gridColumnApi.getColumn('currency').getColDef().headerName =
          literals.tables.account_balance_table.currency;
        this.gridColumnApi
          .getColumn('available_withdraw')
          .getColDef().headerName =
          literals.tables.account_balance_table.available_withdraw;
        this.gridColumnApi.getColumn('equity').getColDef().headerName =
          literals.tables.account_balance_table.equity;
        this.gridColumnApi.getColumn('pl').getColDef().headerName =
          literals.tables.account_balance_table.pl;
        this.gridColumnApi.getColumn('margin').getColDef().headerName =
          literals.tables.account_balance_table.margin;
        this.gridColumnApi.getColumn('margin_level').getColDef().headerName =
          literals.tables.account_balance_table.margin_level;
        this.gridColumnApi.getColumn('commission').getColDef().headerName =
          literals.tables.account_balance_table.commission;
        this.gridColumnApi.getColumn('swaps').getColDef().headerName =
          literals.tables.account_balance_table.swaps;
        this.gridColumnApi.getColumn('notional').getColDef().headerName =
          literals.tables.account_balance_table.notional;
        this.gridColumnApi.getColumn('credit').getColDef().headerName =
          literals.tables.account_balance_table.credit;
        this.gridColumnApi
          .getColumn('trading_state_value')
          .getColDef().headerName =
          literals.tables.account_balance_table.trading_state;
        this.gridApi.refreshHeader();
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onGridSizeChanged(params);
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'AccountBalance.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'AccountBalance.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  };

  handleSubmit = (values) => {
    this.props.sendMessage(
      JSON.stringify({
        key: 'account_balance_report',
        value: JSON.stringify({
          risk_account: values.risk_account
        })
      })
    );
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <div>
        <div className="card__title card__title_custom">
          <h5 className="bold-text">{literals.sidebar.account_balance}</h5>
        </div>
        <Collapse
          title={literals.tables.ag_table.toggle_search}
          className="with-shadow"
          collapse={this.state.collapse}
        >
          <AccountBalanceSearchForm onSubmit={this.handleSubmit} />
        </Collapse>
        <div className="outer-grid-label">
          {literals.tables.ag_table.total_records}:{' '}
          {this.props.rowDataBalance ? this.props.rowDataBalance.length : 0}
        </div>
        <div className="outer-grid-button">
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button
                onClick={this.onBtExportExcel}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_csv}>
              <Button
                onClick={this.onBtExportCSV}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-alt" />
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </div>
        <div
          id={this.gridWrapperID}
          style={{
            width: '100%',
            height: '100%',
            clear: 'both'
          }}
        >
          <div
            id={this.myGridID}
            style={{
              boxSizing: 'border-box',
              height: '55vh',
              width: '100%'
            }}
            className={
              theme === 'theme-light'
                ? 'ag-theme-balham'
                : 'ag-theme-balham-dark'
            }
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              immutableData
              suppressCellSelection
              suppressContextMenu
              defaultColDef={this.state.defaultColDef}
              onColumnResized={this.onColumnResized}
              getRowNodeId={this.state.getRowNodeId}
              rowData={this.props.rowDataBalance}
              onGridReady={this.onGridReady}
              onGridSizeChanged={this.onGridSizeChanged}
              rowHeight={20}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(AccountBalance);
