const userValidate = (values, props) => {
  const errors = {};
  const spaceRegExp = /^\S*$/;
  if (!values.username) {
    errors.username = `${props.literals.forms.user_form.username} ${props.literals.forms.required_validate}`;
  }
  if (!spaceRegExp.test(values.username)) {
    errors.username = `${props.literals.forms.user_form.username} ${props.literals.forms.no_space_validate}`;
  }
  if (!values.password) {
    errors.password = `${props.literals.forms.user_form.password} ${props.literals.forms.required_validate}`;
  }
  if (!values.confirm_password) {
    errors.confirm_password =
        `${props.literals.forms.user_form.confirm_password} ${props.literals.forms.required_validate}`;
  }
  if (!values.first_name) {
    errors.first_name = `${props.literals.forms.user_form.first_name} ${props.literals.forms.required_validate}`;
  }
  if (!values.last_name) {
    errors.last_name = `${props.literals.forms.user_form.last_name} ${props.literals.forms.required_validate}`;
  }
  if (!values.email) {
    errors.email = `${props.literals.forms.user_form.email} ${props.literals.forms.required_validate}`;
  }
  if (values.password && values.confirm_password) {
    if (values.password !== values.confirm_password) {
      errors.confirm_password = props.literals.forms.user_form.passwords_equal_validate;
    }
  }
  if (!values.riskAccounts || !values.riskAccounts.length) {
    errors.riskAccounts = { _error: props.literals.forms.user_form.min_risk_account_validate };
  } else {
    const riskAccountsArrayErrors = [];
    const aliasObj = {};
    values.riskAccounts.forEach((riskAccount, riskAccountIndex) => {
      const riskAccountErrors = {};
      if (!riskAccount || !riskAccount.alias) {
        riskAccountErrors.alias = props.literals.forms.required;
        riskAccountsArrayErrors[riskAccountIndex] = riskAccountErrors;
      }
      if (riskAccount && riskAccount.alias) {
        if (aliasObj[riskAccount.alias]) {
          riskAccountErrors.alias = props.literals.forms.duplicated;
          riskAccountsArrayErrors[riskAccountIndex] = riskAccountErrors;
        }
        aliasObj[riskAccount.alias] = true;
      }
      if (!riskAccount || !riskAccount.risk_account) {
        riskAccountErrors.risk_account = props.literals.forms.required;
        riskAccountsArrayErrors[riskAccountIndex] = riskAccountErrors;
      }
      if (!riskAccount || !riskAccount.taker) {
        riskAccountErrors.taker = props.literals.forms.required;
        riskAccountsArrayErrors[riskAccountIndex] = riskAccountErrors;
      }
      if (!riskAccount || !riskAccount.tem) {
        riskAccountErrors.tem = props.literals.forms.required;
        riskAccountsArrayErrors[riskAccountIndex] = riskAccountErrors;
      }
      if (!riskAccount || !riskAccount.taker_feed) {
        riskAccountErrors.taker_feed = props.literals.forms.required;
        riskAccountsArrayErrors[riskAccountIndex] = riskAccountErrors;
      }
    });
    if (riskAccountsArrayErrors.length) {
      errors.riskAccounts = riskAccountsArrayErrors;
    }
  }
  return errors;
};

export default userValidate;
