/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Dialog from 'rc-dialog';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import { inject } from 'mobx-react';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';

@inject('rootStore')
class UserActionRenderer extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      context: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.deleteUser = this.deleteUser.bind(this);
      let notification = null;
      NotificationSystem.newInstance({}, n => notification = n);
      this.showNotification = (title, color, message) => {
        notification.notice({
          content: <BasicNotification
            title={title}
            message={message}
            color={color}
          />,
          duration: 3,
          closable: true,
          style: { top: 0 },
          className: 'left-up',
        });
      };
    }
    state = {
      visible: false,
      destroyOnClose: false,
    };
    onClick = () => {
      this.setState({
        visible: true,
      });
    };
    onClose = () => {
      this.setState({
        visible: false,
      });
    };

    deleteUser() {
      this.props.context.showLoader();
      this.onClose();
      const dataToDelete = {
        username: this.props.node.data.username,
        // Below fields are only for Audit Log (not for Delete)
        first_name: this.props.node.data.first_name,
        last_name: this.props.node.data.last_name,
        client: this.props.node.data.client,
        role: this.props.node.data.role,
        email: this.props.node.data.email,
        phone: this.props.node.data.phone,
        email_notify: this.props.node.data.email_notify,
        client_value: this.props.node.data.client_value,
      };
      axios.post(`${process.env.REACT_APP_BACKEND_API}/admin/user/delete`, JSON.stringify(dataToDelete))
        .then((response) => {
          if (response.data.status) {
            this.showNotification(
              this.props.literals.forms.user_form.title_delete, 'primary',
              this.props.literals.forms.success_message,
            );
            this.props.context.resetClient();
          } else {
            this.showNotification(
              this.props.literals.forms.user_form.title_delete, 'danger',
              this.props.literals.forms.fail_message,
            );
            this.props.context.hideLoader();
          }
        })
        .catch((error) => {
          this.showNotification(this.props.literals.forms.user_form.title_delete, 'danger', error.message);
          this.props.context.hideLoader();
        });
    }

    render() {
      const { literals } = this.props;
      let dialog;
      if (this.state.visible || !this.state.destroyOnClose) {
        dialog = (
          <Dialog
            visible={this.state.visible}
            wrapClassName="center"
            animation="slide-fade"
            maskAnimation="fade"
            onClose={this.onClose}
            style={{ width: 276 }}
            title={literals.forms.confirm_delete}
          >
            <p>{literals.forms.confirm_delete_note} {this.props.node.data.username}?</p>
            <div style={{
                        marginTop: 10,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '68%',
                    }}
            >
              <ButtonToolbar>
                <Button
                  className="icon"
                  color="primary"
                  key="close"
                  onClick={this.onClose}
                >
                  {literals.forms.cancel}
                </Button>
                <Button
                  className="icon"
                  color="primary"
                  key="save"
                  onClick={this.deleteUser}
                >
                  {literals.forms.delete}
                </Button>
              </ButtonToolbar>
            </div>
          </Dialog>
        );
      }
      return (
        <span>
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.delete}>
              <Button
                style={{ height: 14 }}
                id="UserDeleteClick"
                onClick={this.onClick}
                className="icon"
                color="primary"
              ><p
                style={{ lineHeight: 0 }}
                className="fa fa-trash-alt"
              />
              </Button>
            </Tooltip>
          </ButtonToolbar>
          {dialog}
        </span>


      );
    }
}

export default connect(state => ({
  literals: state.literals,
}))(withRouter(UserActionRenderer));
