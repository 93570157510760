/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

const parseMultiPattern = (values, pattern, text, tree, securitySymbol) => {
  let result = '*,';
  if (values[pattern] && values[text]) {
    result = values[text].trim() || '*,';
  } else if (!values[pattern] && values[tree]) {
    if (tree === 'symbols_tree') {
      if (values[tree].length > 0) {
        const symbols = [];
        values[tree].forEach((item) => {
          if (item in securitySymbol) {
            symbols.push(...securitySymbol[item]);
          } else {
            symbols.push(item);
          }
        });
        result = symbols.join(',');
      } else {
        result = '*,';
      }
    } else {
      result = values[tree].length > 0 ? values[tree].join(',') : '*,';
    }
  }
  return result;
};

const valCheck = (values, dir, name) => {
  const valToCheck = values[dir + name];
  if (!valToCheck) {
    if (valToCheck === 0) {
      return valToCheck;
    }
    return '';
  }
  return valToCheck;
};
const valCheckSelect = (values, dir, name) => {
  const valToCheck = values[dir + name];
  if (!valToCheck) {
    return '';
  }
  return valToCheck.value;
};
const valCheckSelectMul = (values, dir, name) => {
  const valToCheck = values[dir + name];
  if (!valToCheck) {
    return '';
  }
  return valToCheck.join(';');
};

const parseMM = (values, dir) => `${valCheck(values, dir, 'mm_markup_bid')},\
${valCheck(values, dir, 'mm_markup_bid_var')},\
${valCheck(values, dir, 'mm_markup_ask')},${valCheck(
  values,
  dir,
  'mm_markup_ask_var'
)},\
${valCheck(values, dir, 'mm_spread_min')},\
${valCheck(values, dir, 'mm_spread_max')},${valCheckSelect(
  values,
  dir,
  'mm_spread_exec'
)}`;
const parseHAM = (values, dir) => `${valCheckSelectMul(
  values,
  dir,
  'ham_lp_sessions'
)},\
${valCheckSelect(values, dir, 'ham_exec_mode')},${valCheck(
  values,
  dir,
  'ham_filter_factor'
)}`;
const parseTF = (values, dir) =>
  `${valCheckSelect(values, dir, 'tf_ham')},${valCheckSelect(
    values,
    dir,
    'tf_mm'
  )}`;
const parseTEM = (values, dir) => `${valCheckSelect(
  values,
  dir,
  'tem_ham'
)},${valCheckSelect(values, dir, 'tem_mm')},\
${valCheck(values, dir, 'tem_b_perc')},${valCheck(
  values,
  dir,
  'tem_b_fix'
)},${valCheck(values, dir, 'tem_b_boost')},\
${valCheck(values, dir, 'tem_b_delay')},${valCheck(
  values,
  dir,
  'tem_b_delay_jitter'
)},\
${valCheck(values, dir, 'tem_gain_perc')},\
${valCheck(values, dir, 'tem_ll_variation')},${valCheckSelect(
  values,
  dir,
  'tem_ll_action'
)}`;

const parseDateCalendar = (dateCalendar) =>
  dateCalendar?.map((date) => date.format('YYYYMMDD')).join(',');

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, (n) => (notification = n));
  const showNotification = (title, color, message) => {
    notification.notice({
      content: (
        <BasicNotification title={title} message={message} color={color} />
      ),
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up'
    });
  };
  let inSessionValues = '';
  let outSessionValues = '';
  if (values.type.value === 1) {
    inSessionValues = parseMM(values, 'in_session_');
    if (!values.out_session_revert_old) {
      outSessionValues = parseMM(values, 'out_session_');
    }
  } else if (values.type.value === 2) {
    inSessionValues = parseHAM(values, 'in_session_');
    if (!values.out_session_revert_old) {
      outSessionValues = parseHAM(values, 'out_session_');
    }
  } else if (values.type.value === 3) {
    inSessionValues = parseTF(values, 'in_session_');
    if (!values.out_session_revert_old) {
      outSessionValues = parseTF(values, 'out_session_');
    }
  } else if (values.type.value === 4) {
    inSessionValues = parseTEM(values, 'in_session_');
    if (!values.out_session_revert_old) {
      outSessionValues = parseTEM(values, 'out_session_');
    }
  }
  const data = {
    id: parseInt(values.id, 10),
    type: values.type.value,
    groups: parseMultiPattern(
      values,
      'groups_pattern',
      'groups_text',
      'groups_tree'
    ),
    symbols: parseMultiPattern(
      values,
      'symbols_pattern',
      'symbols_text',
      'symbols_tree',
      stateObj.formData.SecuritySymbol
    ),
    securities: parseMultiPattern(
      values,
      'securities_pattern',
      'securities_text',
      'securities_tree'
    ),
    in_session_values: inSessionValues,
    out_of_session_values: outSessionValues,
    date_calendar: parseDateCalendar(values.date_calendar),
    priority: parseInt(values.priority.value, 10),
    description: values.description,
    sessions: values.sessions,
    state: values.state ? 1 : 0
  };
  axios
    .post(
      `${process.env.REACT_APP_BACKEND_API}/action_scheduler`,
      JSON.stringify(data)
    )
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.action_scheduler_form.title_create,
          'primary',
          props.literals.forms.success_message
        );
        stateObj.resetActionScheduler();
        stateObj.toggle('1');
      } else {
        showNotification(
          props.literals.forms.action_scheduler_form.title_create,
          'danger',
          props.literals.forms.fail_message
        );
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(
        props.literals.forms.action_scheduler_form.title_create,
        'danger',
        error.message
      );
      stateObj.hideLoader();
    });
};
