/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const data = {
    name: values.name,
    type: parseInt(values.type.value, 10),
    state: values.state ? 1 : 0,
    description: values.description,
    ip_list: values.ip_list,
  };
  if (values.risk_account && values.risk_account.risk_account && data.type === 0) {
    const valuesRA = values.risk_account.risk_account;
    axios.post(`${process.env.REACT_APP_BACKEND_API}/node`, JSON.stringify(data))
      .then((response) => {
        if (response.data.status) {
          showNotification(
            props.literals.forms.node_form.title_create, 'primary',
            props.literals.forms.success_message,
          );
          stateObj.resetNode();
          stateObj.toggle('1');
          if (valuesRA.tm_models === `${values.name}@*`) {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/account`, JSON.stringify(valuesRA))
              .then((response1) => {
                if (response1.data.status) {
                  showNotification(
                    props.literals.forms.account_form.title_create, 'primary',
                    props.literals.forms.success_message,
                  );
                } else {
                  showNotification(
                    props.literals.forms.account_form.title_create, 'danger',
                    props.literals.forms.fail_message,
                  );
                }
              })
              .catch((error) => {
                showNotification(props.literals.forms.account_form.title_create, 'danger', error.message);
              });
          } else {
            const dataRA = {
              name: valuesRA.name,
              group: valuesRA.group ? valuesRA.group : null,
              currency: valuesRA.currency,
              category: valuesRA.category,
              tm_models: valuesRA.tm_models ? `${valuesRA.tm_models},${values.name}@*` : `${values.name}@*`,
              type: valuesRA.type ? parseInt(valuesRA.type, 10) : null,
              stopout: parseInt(valuesRA.stopout, 10),
              exposure_limit: parseFloat(valuesRA.exposure_limit),
              exposure_delta: -1,
              exposure_delta_span: -1,
              underflow: -1,
              parent: valuesRA.parent,
              warn_levels: valuesRA.warn_levels,
              description: valuesRA.description,
              state: valuesRA.state ? 1 : 0,
              square_off_mode: valuesRA.square_off_mode ? parseInt(valuesRA.square_off_mode.value, 10) : null,
              creation_date_sec: parseInt(valuesRA.creation_date_sec, 10),
              margin_call_level: parseFloat(valuesRA.margin_call_level),
              old_data: {
                name: valuesRA.name,
                group: valuesRA.group ? valuesRA.group : null,
                currency: valuesRA.currency,
                category: valuesRA.category,
                tm_models: valuesRA.tm_models,
                type: valuesRA.type ? parseInt(valuesRA.type, 10) : null,
                stopout: parseInt(valuesRA.stopout, 10),
                exposure_limit: parseFloat(valuesRA.exposure_limit),
                exposure_delta: parseFloat(valuesRA.exposure_delta),
                exposure_delta_span: parseInt(valuesRA.exposure_delta_span, 10),
                underflow: parseInt(valuesRA.underflow, 10),
                parent: valuesRA.parent,
                warn_levels: valuesRA.warn_levels,
                description: valuesRA.description,
                state: valuesRA.state ? 1 : 0,
                square_off_mode: valuesRA.square_off_mode ? parseInt(valuesRA.square_off_mode.value, 10) : null,
                creation_date_sec: parseInt(valuesRA.creation_date_sec, 10),
                margin_call_level: parseFloat(valuesRA.margin_call_level),
              },
            };
            axios.put(`${process.env.REACT_APP_BACKEND_API}/account`, JSON.stringify(dataRA))
              .then((responseRA) => {
                if (responseRA.data.status) {
                  showNotification(
                    props.literals.forms.account_form.title_update, 'primary',
                    props.literals.forms.success_message,
                  );
                } else {
                  showNotification(
                    props.literals.forms.account_form.title_update, 'danger',
                    props.literals.forms.fail_message,
                  );
                }
              })
              .catch((error) => {
                showNotification(props.literals.forms.account_form.title_update, 'danger', error.message);
              });
          }
        } else {
          showNotification(
            props.literals.forms.node_form.title_create, 'danger',
            props.literals.forms.fail_message,
          );
          stateObj.hideLoader();
        }
      })
      .catch((error) => {
        showNotification(props.literals.forms.node_form.title_create, 'danger', error.message);
        stateObj.hideLoader();
      });
  } else {
    axios.post(`${process.env.REACT_APP_BACKEND_API}/node`, JSON.stringify(data))
      .then((response) => {
        if (response.data.status) {
          showNotification(
            props.literals.forms.node_form.title_create, 'primary',
            props.literals.forms.success_message,
          );
          stateObj.resetNode();
          stateObj.toggle('1');
        } else {
          showNotification(
            props.literals.forms.node_form.title_create, 'danger',
            props.literals.forms.fail_message,
          );
          stateObj.hideLoader();
        }
      })
      .catch((error) => {
        showNotification(props.literals.forms.node_form.title_create, 'danger', error.message);
        stateObj.hideLoader();
      });
  }
};
