const ot = (values) => {
  const errors = {};
  if (!values.vol) {
    errors.vol = 'Shouldn’t be empty';
  }
  if (!values.price) {
    errors.price = 'Shouldn’t be empty';
  }
  if (!values.closeOID) {
    errors.closeOID = 'Shouldn’t be empty';
  }
  return errors;
};

export default ot;
