import { action, observable } from 'mobx';

class AuthStore {
  @observable isAuthenticated;
  @observable expire;
  @observable token;
  @observable role;
  @observable user;
  constructor() {
    this.reset();
  }
  @action
  reset() {
    this.isAuthenticated = false;
    this.expire = '';
    this.token = '';
    this.role = '';
    this.user = {};
  }
}
export default new AuthStore();

