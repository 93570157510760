const markupGroupValidate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${props.literals.forms.markup_group_form.name} ${props.literals.forms.required_validate}`;
  }
  if (!values.enable_multiplier) {
    errors.enable_multiplier =
        `${props.literals.forms.markup_group_form.enable_multiplier} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default markupGroupValidate;
