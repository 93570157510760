import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lpValidate from './validate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderMultiSelectField from '../../shared/components/form/MultiSelect';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class LPForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals,
      } = this.props;
      let securityValues = [];
      if (this.props.state) {
        securityValues = this.props.state.formData.Security;
      } else {
        securityValues = JSON.parse(JSON.parse(localStorage.getItem('persist:form')).lp_form)
          .values.formData.Security;
      }
      const disabled = false;
      const { submit } = literals.forms.lp_form;

      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.lp_form.name}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.lp_form.name}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.lp_form.securities}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="sec_types"
                  component={renderMultiSelectField}
                  type="text"
                  options={securityValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.lp_form.protocol}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="protocol"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.lp_form.protocol}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.lp_form.version}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="version"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.lp_form.version}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.lp_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.lp_form.description}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.lp_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.lp_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.state) {
    if (ownProps.state.detail) {
      return {
        literals: state.literals,
        initialValues: {
          name: ownProps.state.detail.name,
          sec_types: ownProps.state.detail.sec_types,
          protocol: ownProps.state.detail.protocol,
          version: ownProps.state.detail.version,
          description: ownProps.state.detail.description,
          stateMode: ownProps.state.mode,
          formData: ownProps.state.formData,
        },
      };
    }
    return {
      literals: state.literals,
      initialValues: {
        stateMode: ownProps.state.mode,
        formData: ownProps.state.formData,
      },
    };
  }
  return { literals: state.literals };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'lp_form', // a unique identifier for this form
  validate: lpValidate,
})(LPForm));
