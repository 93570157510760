import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class MultiSelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })),
    ]).isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: '',
    options: [],
    disabled: false,
  };

  handleChange = (value) => {
    // const values = value.map(a => a.value).join(',');
    this.props.onChange(value);
  };

  render() {
    const {
      value, name, placeholder, options, disabled,
    } = this.props;

    return (
      <Select
        isMulti
        name={name}
        value={value}
        onChange={this.handleChange}
        options={options}
        className="form__form-group-select"
        classNamePrefix="Select"
        placeholder={placeholder}
        isDisabled={disabled}
        maxMenuHeight={150}
        closeMenuOnSelect={false}
      />
    );
  }
}

const renderMultiSelectField = props => (
  <div className="form__form-group-input-wrap">
    <MultiSelectField
      {...props.input}
      options={props.options}
      disabled={props.disabled}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderMultiSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  disabled: PropTypes.bool,
};

renderMultiSelectField.defaultProps = {
  meta: null,
  options: [],
  disabled: false,
};

export default renderMultiSelectField;

