/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized
} from '../../shared/helper';
import { BasicNotification } from '../../shared/components/Notification';
import BoolRenderer from '../../shared/components/BoolRenderer';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import SessionRenderer from '../../shared/components/session/SessionRenderer';
import SessionHeaderComponent from '../../shared/components/multi_edit/SessionHeaderComponent';
import MakerScalingSymbolActionRenderer from './actionRenderer';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

const exportColumns = [
  'unisymbol',
  'shared_id',
  'price_scale_in',
  'price_scale_out',
  'volume_scale_in',
  'volume_scale_out',
  'description',
  'state_value'
];

@inject('rootStore')
class MakerScalingDetailCellRenderer extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    data: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    this.rowsToUpdate = props.context.rowsToUpdateDetail[props.node.id] || {};
    this.stylesToUpdate =
      props.context.stylesToUpdateDetail[props.node.id] || {};
    const { literals } = this.props;
    this.state = {
      modules: AllModules,
      colDefs: [
        {
          headerName: literals.forms.maker_scaling_symbol_form.unisymbol,
          field: 'unisymbol',
          editable: false,
          pinned: 'left',
          cellClassRules: {
            'arrow-right': (params) =>
              typeof this.stylesToUpdate[params.node.id] !== 'undefined'
          },
          width: 60,
          minWidth: 60,
          sortable: true
        },
        {
          headerName: literals.forms.maker_scaling_symbol_form.shared_id,
          field: 'shared_id',
          editable: false,
          sortable: true
        },
        {
          headerName: literals.forms.maker_scaling_symbol_form.price_scale_in,
          field: 'price_scale_in',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.maker_scaling_symbol_form.price_scale_out,
          field: 'price_scale_out',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.maker_scaling_symbol_form.volume_scale_in,
          field: 'volume_scale_in',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.maker_scaling_symbol_form.volume_scale_out,
          field: 'volume_scale_out',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.maker_scaling_symbol_form.description,
          field: 'description',
          cellEditor: 'agLargeTextCellEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.maker_scaling_symbol_form.state,
          field: 'state_value',
          editable: false,
          cellRenderer: 'stateRenderer',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'stateHeaderComponent'
        },
        {
          headerName: literals.tables.ag_table.actions,
          field: 'actions',
          cellRenderer: 'actionRenderer',
          filter: false,
          editable: false
        }
      ],
      frameworkComponents: {
        boolRenderer: BoolRenderer,
        stateRenderer: StateRenderer,
        stateHeaderComponent: StateHeaderComponent,
        cellHeaderComponent: HeaderComponent,
        sessionRenderer: SessionRenderer,
        sessionHeaderComponent: SessionHeaderComponent,
        actionRenderer: MakerScalingSymbolActionRenderer
      },
      rowData: [],
      rowOldData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: false,
        resizable: true,
        width: 50,
        minWidth: 50
      },
      cellEditUpdate: props.context.cellEditUpdateDetail[props.node.id],
      getRowNodeId(data) {
        return data.unisymbol;
      }
    };

    this.state.rowID = props.node.id
      ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff')
      : props.node.id;
    this.gridWrapperID = 'grid-wrapper-sidebar_maker_scaling';
    this.myGridID = `myGrid_sidebar_maker_scaling_symbol_${this.state.rowID}`;
    this.state.masterGridApi = props.api;
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridInfo.columnApi) {
      const { literals } = this.props;
      if (
        literals.forms.maker_scaling_symbol_form.unisymbol !==
        prevProps.literals.forms.maker_scaling_symbol_form.unisymbol
      ) {
        this.gridInfo.columnApi.getColumn('unisymbol').getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.unisymbol;
        this.gridInfo.columnApi.getColumn('shared_id').getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.shared_id;
        this.gridInfo.columnApi
          .getColumn('price_scale_in')
          .getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.price_scale_in;
        this.gridInfo.columnApi
          .getColumn('price_scale_out')
          .getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.price_scale_out;
        this.gridInfo.columnApi
          .getColumn('volume_scale_in')
          .getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.volume_scale_in;
        this.gridInfo.columnApi
          .getColumn('volume_scale_out')
          .getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.volume_scale_out;
        this.gridInfo.columnApi
          .getColumn('state_value')
          .getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.state;
        this.gridInfo.columnApi
          .getColumn('description')
          .getColDef().headerName =
          literals.forms.maker_scaling_symbol_form.description;
        this.gridInfo.api.refreshHeader();
      }
    }
  }

  componentWillUnmount = () => {
    if (this.gridInfo) {
      window[`colStateMakerScaling${this.state.rowID}`] =
        this.gridInfo.columnApi.getColumnState();
      window[`groupStateMakerScaling${this.state.rowID}`] =
        this.gridInfo.columnApi.getColumnGroupState();
      window[`filterStateMakerScaling${this.state.rowID}`] =
        this.gridInfo.api.getFilterModel();
    }

    const detailGridId = this.createDetailGridId();

    // ag-Grid is automatically destroyed
    this.state.masterGridApi.removeDetailGridInfo(detailGridId);
  };

  onCellClass = (params) =>
    typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
    typeof this.stylesToUpdate[params.node.id][params.colDef.field] !==
      'undefined';

  onCellValueChanged = (params) => {
    if (params.oldValue != params.value) {
      if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
        this.stylesToUpdate[params.node.id] = {};
        this.gridInfo.api.refreshCells({
          rowNodes: [params.node],
          columns: ['unisymbol'],
          force: true
        });
      }
      this.stylesToUpdate[params.node.id][params.colDef.field] = {};
      this.gridInfo.api.refreshCells({
        rowNodes: [params.node],
        columns: [params.column],
        force: true
      });

      if (params.colDef.field === 'state_value') {
        params.data.state =
          this.props.context.formData.StateObj[params.newValue].value;
      }
      this.rowsToUpdate[`${params.node.id}`] = params.data;
      this.setState({ cellEditUpdate: true });
      this.props.context.updateStateDetail(
        this.state.rowID,
        true,
        this.rowsToUpdate,
        this.stylesToUpdate
      );
    }
  };

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onGridReady(params) {
    const detailGridId = this.createDetailGridId();

    this.gridInfo = {
      id: detailGridId,
      api: params.api,
      columnApi: params.columnApi
    };
    this.gridInfo.api.showLoadingOverlay();
    this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfo);
    if (window[`colStateMakerScaling${this.state.rowID}`]) {
      this.gridInfo.columnApi.setColumnState(
        window[`colStateMakerScaling${this.state.rowID}`]
      );
    }
    if (window[`groupStateMakerScaling${this.state.rowID}`]) {
      this.gridInfo.columnApi.setColumnGroupState(
        window[`groupStateMakerScaling${this.state.rowID}`]
      );
    }
    if (window[`filterStateMakerScaling${this.state.rowID}`]) {
      this.gridInfo.api.setFilterModel(
        window[`filterStateMakerScaling${this.state.rowID}`]
      );
    }
    this.setState({ rowData: this.props.data.maker_scaling_symbols });
    this.setState({
      rowOldData: JSON.parse(
        JSON.stringify(this.props.data.maker_scaling_symbols)
      )
    });
    this.onGridSizeChanged(params);
  }

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'MakerScalingSymbol.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridInfo.api.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'MakerScalingSymbol.csv'
    };
    this.gridInfo.api.exportDataAsCsv(params);
  };

  createDetailGridId = () => `detail_${this.state.rowID}`;

  updateMakerScalingSymbol = () => {
    this.props.context.showLoader();
    const bulkData = [];
    const rowData = _.keyBy(this.state.rowOldData, 'unisymbol');
    Object.values(this.rowsToUpdate).forEach((values) => {
      const oldRow = rowData[values.unisymbol];
      const dataToUpdate = {
        unisymbol: values.unisymbol,
        shared_id: values.shared_id,
        price_scale_in: parseFloat(values.price_scale_in),
        price_scale_out: parseFloat(values.price_scale_out),
        volume_scale_in: parseFloat(values.volume_scale_in),
        volume_scale_out: parseFloat(values.volume_scale_out),
        state: parseInt(values.state, 10),
        description: values.description,
        old_data: {
          unisymbol: oldRow.unisymbol,
          shared_id: oldRow.shared_id,
          price_scale_in: parseFloat(oldRow.price_scale_in),
          price_scale_out: parseFloat(oldRow.price_scale_out),
          volume_scale_in: parseFloat(oldRow.volume_scale_in),
          volume_scale_out: parseFloat(oldRow.volume_scale_out),
          state: parseInt(oldRow.state, 10),
          description: oldRow.description
        }
      };
      bulkData.push(dataToUpdate);
    });
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/maker/scaling_symbol`,
        JSON.stringify(bulkData)
      )
      .then((response) => {
        this.resetMakerScalingSymbol();
        showNotification(
          this.props.literals.sidebar.maker_scaling_symbol,
          'primary',
          this.props.literals.forms.completed_message
        );
        if (response.data.length > 0) {
          showNotification(
            this.props.literals.sidebar.maker_scaling_symbol,
            'danger',
            `${
              this.props.literals.forms.fail_message
            } - ${response.data.toString()}}`
          );
        }
      })
      .catch((error) => {
        this.resetMakerScalingSymbol();
        showNotification(
          this.props.literals.sidebar.maker_scaling_symbol,
          'danger',
          `${this.props.literals.forms.fail_message} - ${error.message}}`
        );
      });
  };

  resetMakerScalingSymbol = () => {
    this.rowsToUpdate = {};
    this.setState({ cellEditUpdate: false });
    this.props.context.resetMakerScaling();
    window[`colStateMakerScaling${this.state.rowID}`] =
      this.gridInfo.columnApi.getColumnState();
    window[`groupStateMakerScaling${this.state.rowID}`] =
      this.gridInfo.columnApi.getColumnGroupState();
    window[`filterStateMakerScaling${this.state.rowID}`] =
      this.gridInfo.api.getFilterModel();
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate).forEach((keys) => {
      const node = this.gridInfo.api.getRowNode(keys);
      if (node) {
        this.gridInfo.api.refreshCells({
          rowNodes: [node],
          columns: ['unisymbol'],
          force: true
        });
        Object.keys(stylesToUpdate[keys]).forEach((values) => {
          this.gridInfo.api.refreshCells({
            rowNodes: [node],
            columns: [values],
            force: true
          });
        });
      }
    });
    this.props.context.updateStateDetail(
      this.state.rowID,
      false,
      this.rowsToUpdate,
      this.stylesToUpdate
    );
  };

  render() {
    const { literals } = this.props;
    return (
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          height: '100%'
        }}
      >
        <div className="outer-grid-label">
          {literals.sidebar.maker_scaling_symbol} (
          {literals.tables.ag_table.total_records}: {this.state.rowData.length})
        </div>
        <div className="outer-grid-button">
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.save}>
              <Button
                style={{
                  display: this.state.cellEditUpdate ? '' : 'none'
                }}
                onClick={this.updateMakerScalingSymbol}
                className="icon"
                color="primary"
              >
                <p className="fa fa-save" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.undo}>
              <Button
                style={{
                  display: this.state.cellEditUpdate ? '' : 'none'
                }}
                onClick={() => {
                  this.props.context.showLoader();
                  this.resetMakerScalingSymbol();
                }}
                className="icon"
                color="primary"
              >
                <p className="fas fa-undo" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button
                onClick={this.onBtExportExcel}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_csv}>
              <Button
                onClick={this.onBtExportCSV}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-alt" />
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </div>
        <div
          id={this.myGridID}
          style={{
            width: '100%',
            height: this.props.node.rowHeight - REDUCE_HEIGHT,
            clear: 'both'
          }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.colDefs}
            stopEditingWhenCellsLoseFocus
            suppressCellSelection
            suppressContextMenu
            immutableData
            getRowNodeId={this.state.getRowNodeId}
            frameworkComponents={this.state.frameworkComponents}
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResized}
            onCellValueChanged={this.onCellValueChanged}
            rowData={this.state.rowData}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.onGridSizeChanged}
            rowHeight={20}
            context={{
              formData: this.props.context.formData,
              showLoader: this.props.context.showLoader,
              hideLoader: this.props.context.hideLoader,
              theme: this.props.theme,
              onCellValueChanged: this.onCellValueChanged,
              resetMakerScalingSymbol: this.resetMakerScalingSymbol
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  literals: state.literals,
  theme: state.theme
}))(withRouter(MakerScalingDetailCellRenderer));
