/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderInputNumberField from '../../shared/components/form/NumberInput';
import renderSelectField from '../../shared/components/form/SelectBox';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class OpenOrderUpdateForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleUpdate: PropTypes.func.isRequired,
    formData: PropTypes.instanceOf(Object),
  };
  static defaultProps = {
    formData: {},
  };
  render() {
    const { handleSubmit, literals } = this.props;
    const { submit } = literals.forms.uni_symbol_form;
    return (
      <form className="form  form--vertical" onSubmit={handleSubmit}>
        <div className="form__half form__search">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.open_order_table.volume}
            </span>
            <div className="form__form-group-field">
              <Field
                name="quantity"
                component={renderInputNumberField}
                step={1}
                min={0}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.open_order_table.price}
            </span>
            <div className="form__form-group-field">
              <Field
                name="price"
                component={renderInputNumberField}
                step={1}
                min={0}
              />
            </div>
          </div>
        </div>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.open_order_table.ord_state}
            </span>
            <div className="form__form-group-field">
              <Field
                name="ord_state"
                component={renderSelectField}
                type="text"
                options={this.props.formData.OrdState}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.open_order_table.time_in_force}
            </span>
            <div className="form__form-group-field">
              <Field
                name="tif"
                component={renderSelectField}
                type="text"
                options={this.props.formData.TimeInForce}
              />
            </div>
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar form__button-toolbar-remove-top-padding">
          <Button color="primary" type="submit">
            {submit}
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => this.props.toggleUpdate()}
          >
            {literals.forms.uni_symbol_form.cancel}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    theme: state.theme,
    initialValues: {
      quantity: ownProps.node.data.volume,
      price: ownProps.node.data.price,
      ord_state: ownProps.formData.OrdStateObj[ownProps.node.data.ord_state],
      tif: ownProps.formData.TimeInForceObj[ownProps.node.data.time_in_force],
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'open_order_update_form', // a unique identifier for this form
})(OpenOrderUpdateForm));
