/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const data = {
    username: values.username,
    password: values.password,
    first_name: values.first_name,
    last_name: values.last_name,
    client: values.client,
    role: values.role,
    email: values.email,
    phone: values.phone,
    email_notify: values.email_notify,
  };

  axios.post(`${process.env.REACT_APP_BACKEND_API}/admin/user`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.user_form.title_create, 'primary',
          props.literals.forms.success_message,
        );
        stateObj.resetClient();
        stateObj.toggle('1');
      } else {
        showNotification(props.literals.forms.user_form.title_create, 'danger', props.literals.forms.fail_message);
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.user_form.title_create, 'danger', error.message);
      stateObj.hideLoader();
    });
};
