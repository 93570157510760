import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';

import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility, changeSidebarCategoryCollapse }
  from '../../redux/actions/sidebarActions';
import { changeLanguageToEN, changeLanguageToCN,
  changeLanguageToJA, changeLanguageToHI } from '../../redux/actions/languageActions';
import { loadLiteralsEN, loadLiteralsCN, loadLiteralsJA, loadLiteralsHI } from '../../redux/actions/literalActions';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';

class Layout extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
  };

  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  changeSidebarCategoryCollapse = (keyCategory) => {
    this.props.dispatch(changeSidebarCategoryCollapse(keyCategory));
  };

  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };

  changeLanguageToEN = () => {
    this.props.dispatch(changeLanguageToEN());
  };

  changeLanguageToCN = () => {
    this.props.dispatch(changeLanguageToCN());
  };

  changeLanguageToJA = () => {
    this.props.dispatch(changeLanguageToJA());
  };

  changeLanguageToHI = () => {
    this.props.dispatch(changeLanguageToHI());
  };

  loadLiteralsEN = () => {
    this.props.dispatch(loadLiteralsEN());
  };

  loadLiteralsCN = () => {
    this.props.dispatch(loadLiteralsCN());
  };

  loadLiteralsJA = () => {
    this.props.dispatch(loadLiteralsJA());
  };

  loadLiteralsHI = () => {
    this.props.dispatch(loadLiteralsHI());
  };

  render() {
    return (
      <div>
        <Topbar
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
          changeLanguageToEN={this.changeLanguageToEN}
          changeLanguageToCN={this.changeLanguageToCN}
          changeLanguageToJA={this.changeLanguageToJA}
          changeLanguageToHI={this.changeLanguageToHI}
          loadLiteralsEN={this.loadLiteralsEN}
          loadLiteralsCN={this.loadLiteralsCN}
          loadLiteralsJA={this.loadLiteralsJA}
          loadLiteralsHI={this.loadLiteralsHI}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
        />
        <Sidebar
          sidebar={this.props.sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarCategoryCollapse={this.changeSidebarCategoryCollapse}
        />
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
}))(Layout));
