const resetPasswordValidate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Password field shouldn’t be empty';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Confirm Password field shouldn’t be empty';
  }
  if (values.password && values.confirm_password) {
    if (values.password !== values.confirm_password) {
      errors.confirm_password = 'Passwords should be equal';
    }
  }
  return errors;
};

export default resetPasswordValidate;
