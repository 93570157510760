/* eslint-disable no-alert */
/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 2,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
};

export default (async function showResults(values) {
  if (values.toggle) {
    values.toggle();
  }
  const data = {
    symbol: values.symbol,
    account_alias: values.account_alias,
    side: values.side,
    order_type: values.order_type.value,
    quantity: values.quantity ? values.quantity.toString() : '0',
    price: values.price ? values.price.toString() : '0',
    deviation: values.deviation ? values.deviation.toString() : '',
    validity: values.validity.value,
    digits: parseInt(values.digits, 10),
    bid_price: values.bid_price.toString(),
    ask_price: values.ask_price.toString(),
    order_id: parseInt(values.order_id, 10),
    client_login: parseInt(values.client_login, 10),
    client_group: values.client_group,
  };
  axios.post(`${process.env.REACT_APP_BACKEND_API}/trading/action`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          values.literals.tables.trading_table.new_order, 'primary',
          values.literals.tables.trading_table.order_created,
        );
      } else {
        showNotification(
          values.literals.tables.trading_table.new_order, 'danger',
          values.literals.tables.trading_table.order_failed,
        );
      }
    })
    .catch((error) => {
      showNotification(values.literals.tables.trading_table.new_order, 'danger', error.message);
    });
});
