import axios from 'axios';

import memoizedRefreshToken from './refreshToken';

const axiosPrivate = (props) => {
  axios.defaults.headers.common = {
    'x-forward-client': props.rootStore.clientStore.CC,
    'x-forward-user': props.rootStore.authStore.user.username,
  };
  axios.interceptors.request.use(
    async (config) => {
      const session = { accessToken: props.rootStore.authStore.token };

      if (session?.accessToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${session?.accessToken}`
        };
      }
      // Check if the data is a stringified JSON and try to parse it
      if (typeof config.data === 'string') {
        try {
          const parsedData = JSON.parse(config.data);
          // eslint-disable-next-line no-param-reassign
          config.data = parsedData;
        } catch (e) {
          // Data is not a JSON string, leave it as is
        }
      }
      return config;
    },
    error => Promise.reject(error),
  );

  axios.interceptors.response.use(
    response => response,
    async (error) => {
      const config = error?.config;
      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true;

        const result = await memoizedRefreshToken(props);

        if (result?.accessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${result?.accessToken}`,
          };
        }

        return axios(config);
      }
      return Promise.reject(error);
    },
  );
};
export default axiosPrivate;
