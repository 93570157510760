/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, formValueSelector, clearFields, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderMultiSelectField from '../../shared/components/form/MultiSelect';
import renderTreeSelectField from './TreeSelect';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

class MarketWatchSearchForm extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      feed: PropTypes.instanceOf(Object),
      symbol: PropTypes.instanceOf(Array),
      change: PropTypes.func.isRequired,
      showLoader: PropTypes.func.isRequired,
      hideLoader: PropTypes.func.isRequired,
      sendMessage: PropTypes.func.isRequired,
      handleSelectedSymbolsChange: PropTypes.func.isRequired,
    };
    static defaultProps = {
      feed: {},
      symbol: [],
    };
    constructor(props) {
      super(props);
      this.state = {
        feed: [],
        symbol: [],
      };
    }
    componentDidMount() {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/trading/market_watch/node_feed/search`)
        .then((response) => {
          this.setState({ feed: response.data.feed || [] });
          this.onChangeFeed(this.props.feed);
        })
        .catch(() => {
        });
    }
    onChangeFeed=(selectedOption) => {
      this.props.showLoader();
      selectedOption = selectedOption || [];
      if (Object.keys(selectedOption).length === 100) { // preventDefault
        this.props.change('symbol', []);
        const selectedSymbols = [];
        this.props.handleSelectedSymbolsChange(selectedSymbols);
        this.props.sendMessage(JSON.stringify({ key: 'symbol', value: [] }));
      }
      const route = `${process.env.REACT_APP_BACKEND_API}/trading/market_watch/node_feed_symbol/search`;
      const nodeFeeds = [];
      Object.keys(selectedOption).forEach((item) => {
        if (selectedOption[item].value) {
          nodeFeeds.push(selectedOption[item].value);
        }
      });
      axios.post(`${route}?node_feed`, JSON.stringify({
        node_feed: nodeFeeds,
      }))
        .then((response) => {
          this.setState({ symbol: response.data.feed_symbol });
          const feeds = {};
          response.data.feed_symbol.forEach((feed) => {
            feeds[feed.value] = [];
            feed.children.forEach((security) => {
              feeds[feed.value].push(...security.children);
            });
          });
          const symbolItems = [];
          Object.keys(selectedOption).forEach((item) => {
            if (selectedOption[item].value) {
              selectedOption[item].symbol = feeds[selectedOption[item].value];
              symbolItems.push(...selectedOption[item].symbol);
            }
          });
          const symbolItem = arrayToObject(symbolItems, 'value');
          const selectedSymbols = [];
          const selectedSymbolNames = [];
          if (this.props.symbol) {
            this.props.symbol.forEach((item) => {
              if (symbolItem[item]) {
                selectedSymbols.push(symbolItem[item]);
                selectedSymbolNames.push(item);
              }
            });
          }
          this.props.change('symbol', selectedSymbolNames);
          this.handleSelectedSymbolsChange(selectedSymbols);
          this.props.hideLoader();
        })
        .catch(() => {
          this.props.hideLoader();
        });
    };
    render() {
      const {
        handleSubmit, literals, theme,
      } = this.props;
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.feed}</span>
              <div className="form__form-group-field">
                <Field
                  name="feed"
                  component={renderMultiSelectField}
                  type="text"
                  options={this.state.feed}
                  onChange={this.onChangeFeed}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label" style={{ margin: '7px 0' }}>
                {literals.tables.position_table.symbol}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="symbol"
                  component={renderTreeSelectField}
                  treeData={this.state.symbol}
                  className={theme.className === 'theme-light' ?
                      'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                      'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{literals.tables.trading_table.search}</Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('market_watch_search_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    feed: selector(state, 'feed'),
    symbol: selector(state, 'symbol'),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change, clearFields }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'market_watch_search_form', // a unique identifier for this form
  destroyOnUnmount: false,
})(MarketWatchSearchForm));
