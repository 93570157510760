/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import Websocket from 'react-websocket';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import AggregatorRestartForm from './AggregatorRestartForm';
import ReactDOM from 'react-dom';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';
import Loader from '../../shared/components/Loader';

const exportColumns = [
  'name',
  'code',
  'version',
  'conn_status',
  'hn',
  'db_host',
  'db_name',
  'pmmb',
  'pmpmb',
  'pcpup',
  'scpup',
  'server_address',
  'company_name'
];

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

@inject('rootStore')
class ClientInfo extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_client_info';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          field: 'name',
          width: 60,
          minWidth: 60,
          sort: 'desc',
          type: 'text',
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        {
          field: 'code',
          width: 60,
          minWidth: 60,
          type: 'text'
        },
        {
          field: 'version',
          width: 60,
          minWidth: 60,
          type: 'text'
        },
        {
          field: 'conn_status',
          width: 60,
          minWidth: 60,
          type: 'text',
          cellRenderer: (params) => {
            if (params.data.conn_status === true) {
              return `<span class="cell-renderer-green">CONNECTED</span>`;
            }
            return `<span class="cell-renderer-red">DISCONNECTED</span>`;
          },
          valueGetter: (params) => {
            if (params.data.conn_status === true) {
              return `CONNECTED`;
            }
            return `DISCONNECTED`;
          }
        },
        {
          field: 'hn',
          width: 60,
          minWidth: 60,
          type: 'text'
        },
        {
          field: 'db_host',
          width: 60,
          minWidth: 60,
          type: 'text'
        },
        {
          field: 'db_name',
          width: 60,
          minWidth: 60,
          type: 'text'
        },
        {
          field: 'pmmb',
          width: 60,
          minWidth: 60,
          type: 'number',
          valueFormatter: (params) => {
            return `${params.data.pmmb} MB`;
          }
        },
        {
          field: 'pmpmb',
          width: 60,
          minWidth: 60,
          type: 'number',
          valueFormatter: (params) => {
            return `${params.data.pmpmb} MB`;
          }
        },
        {
          field: 'pcpup',
          width: 60,
          minWidth: 60,
          type: 'number',
          valueFormatter: (params) => {
            return `${params.data.pcpup} %`;
          }
        },
        {
          field: 'scpup',
          width: 60,
          minWidth: 60,
          type: 'number',
          valueFormatter: (params) => {
            return `${params.data.scpup} %`;
          }
        },
        {
          field: 'server_address',
          width: 60,
          minWidth: 60,
          type: 'text'
        },
        {
          field: 'company_name',
          width: 60,
          minWidth: 60,
          type: 'text'
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: ['filterMenuTab'],
        suppressMovable: true,
        width: 50,
        minWidth: 50
      },
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter'
        }
      },
      getRowNodeId(data) {
        return data.code;
      },
      rowData: [],
      frameworkComponents: {},
      restartShow: false,
      popoverOpen: false,
      loaderShow: false
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onGridSizeChanged(params);
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onSelectionChanged = (event) => {
    this.setState({ restartShow: event.api.getSelectedRows().length > 0 });
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'ClientInfo.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'ClientInfo.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  };

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  aggregatorRestart = (val) => {
    this.showLoader();
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const data = { client_info: selectedData, restart_input: val };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/system/aggregator_bulk_restart`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response.data.failed_instances?.length > 0) {
          showNotification(
            this.props.literals.forms.aggregator_restart_form.title_create,
            'danger',
            `failed instances: ${response.data.failed_instances.join(', ')}`
          );
        } else {
          showNotification(
            this.props.literals.forms.aggregator_restart_form.title_create,
            'primary',
            this.props.literals.forms.aggregator_restart_form.success_message
          );
        }
      })
      .catch((error) => {
        showNotification(
          this.props.literals.forms.aggregator_restart_form.title_create,
          'danger',
          error.message
        );
      })
      .finally(() => {
        this.hideLoader();
        this.toggle();
      });
  };

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    if ('client_info' in data) {
      this.setState({ rowData: data.client_info });
    }
  };
  socketOpenListener = () => {
    this.sendMessage(
      JSON.stringify({
        key: 'client_info',
        value: []
      })
    );
  };
  sendMessage = (message) => {
    this.refWebSocket.sendMessage(message);
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="outer-grid-label">
              {literals.tables.ag_table.total_records}:{' '}
              {this.state.rowData ? this.state.rowData.length : 0}
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip title={literals.topbar.restart}>
                  <Button
                    style={{
                      display: this.state.restartShow ? '' : 'none'
                    }}
                    onClick={() => {
                      this.toggle();
                    }}
                    className="icon"
                    color="primary"
                    ref={(c) => {
                      this.target = c && ReactDOM.findDOMNode(c);
                    }}
                  >
                    <p className="fas fa-redo" />
                  </Button>
                </Tooltip>
                <Popover
                  placement="bottom-end"
                  isOpen={this.state.popoverOpen}
                  target={() => this.target}
                  toggle={this.toggle}
                  className={
                    theme === 'theme-light'
                      ? 'theme-light trading-light'
                      : 'theme-dark trading-dark'
                  }
                >
                  <PopoverHeader>
                    {
                      this.props.literals.forms.aggregator_restart_form
                        .title_create
                    }
                  </PopoverHeader>
                  <PopoverBody>
                    <AggregatorRestartForm
                      state={{
                        toggle: this.toggle,
                        client_name:
                          this.props.rootStore.authStore.user.client_name,
                        role: this.props.rootStore.authStore.role
                      }}
                      onSubmit={(val) => {
                        this.aggregatorRestart(val);
                      }}
                    />
                  </PopoverBody>
                </Popover>
                <Tooltip title={literals.tables.ag_table.export_excel}>
                  <Button
                    onClick={this.onBtExportExcel}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-excel" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.export_csv}>
                  <Button
                    onClick={this.onBtExportCSV}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-alt" />
                  </Button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div
              id={this.gridWrapperID}
              style={{
                width: '100%',
                height: '100%',
                clear: 'both'
              }}
            >
              <div
                id={this.myGridID}
                style={{
                  boxSizing: 'border-box',
                  height: '87vh',
                  width: '100%'
                }}
                className={
                  theme === 'theme-light'
                    ? 'ag-theme-balham'
                    : 'ag-theme-balham-dark'
                }
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.columnDefs}
                  immutableData
                  suppressCellSelection
                  suppressContextMenu
                  defaultColDef={this.state.defaultColDef}
                  onColumnResized={this.onColumnResized}
                  getRowNodeId={this.state.getRowNodeId}
                  rowData={this.state.rowData}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.onGridSizeChanged}
                  rowHeight={20}
                  accentedSort
                  frameworkComponents={this.state.frameworkComponents}
                  columnTypes={this.state.columnTypes}
                  rowSelection="multiple"
                  onSelectionChanged={this.onSelectionChanged}
                />
              </div>
            </div>
            <Websocket
              url={
                `${process.env.REACT_APP_FRONTEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`
              }
              reconnectIntervalInMilliSeconds={1000}
              onMessage={this.socketMessageListener}
              onOpen={this.socketOpenListener}
              ref={(WS) => {
                this.refWebSocket = WS;
              }}
            />
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(ClientInfo);
