/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import { addCommas, decimalPlaces } from '../../shared/helper';
import TradingPanel from './TradingPanel';
import TradingActionForm from './action';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

@inject('rootStore')
class MarketWatchView extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    search_values: PropTypes.instanceOf(Object),
    trading_action_form_values: PropTypes.instanceOf(Object),
    rootStore: PropTypes.instanceOf(Object).isRequired,
    selectedSymbols: PropTypes.instanceOf(Object),
    marketWatch: PropTypes.instanceOf(Array),
    marketWatchLast: PropTypes.instanceOf(Array),
    selectedAccount: PropTypes.instanceOf(Object),
    selectedNode: PropTypes.instanceOf(Object),
    literals: LiteralProps.isRequired,
  };
  static defaultProps = {
    search_values: {},
    trading_action_form_values: {},
    selectedSymbols: {},
    marketWatch: [],
    marketWatchLast: [],
    selectedAccount: {},
    selectedNode: {},
  };
  onPriceClick=(value) => {
    if (this.props.trading_action_form_values.order_type) {
      if (this.props.trading_action_form_values.order_type.value !== 'Market') {
        this.props.dispatch(change('trading_action_form', 'price', value));
      }
    }
  };
  priceSplit=(input) => {
    const strOut = [];
    const strIn = input;
    let last = strIn.substr(strIn.length - 3);
    let first = strIn.substr(0, strIn.length - 3);
    if (last.indexOf('.') >= 0) {
      last = strIn.substr(strIn.length - 4);
      first = strIn.substr(0, strIn.length - 4);
    }
    strOut.push(first);
    strOut.push(last.substr(0, last.length - 1));
    strOut.push(last[last.length - 1]);
    return strOut;
  };
  render() {
    const { literals } = this.props;
    const readOnly = this.props.rootStore.reportStore.reportState.readOnly || 0;
    let j = 0;
    let k = 0;
    const tt = this.props.marketWatch ?
      this.props.marketWatch : [];
    const ttLast = this.props.marketWatchLast ?
      this.props.marketWatchLast : [];
    const selectedSymbol = this.props.selectedSymbols;
    const symbolItem = arrayToObject(tt, 'symbol');
    const symbolItemLast = arrayToObject(ttLast, 'symbol');
    let panels1;
    if (typeof selectedSymbol === 'object' && typeof selectedSymbol.value !== 'undefined'
      && typeof selectedSymbol.digits !== 'undefined') {
      j += 1;
      let BidPrice = '0.00000';
      let AskPrice = '0.00000';
      let BidPriceSplit = this.priceSplit(addCommas(parseFloat(BidPrice)
        .toFixed(selectedSymbol.digits || decimalPlaces(BidPrice)).toString()));
      let AskPriceSplit = this.priceSplit(addCommas(parseFloat(AskPrice)
        .toFixed(selectedSymbol.digits || decimalPlaces(AskPrice)).toString()));
      let bidPriceChange = 'neutral';
      let askPriceChange = 'neutral';
      let bidVolume = 0;
      let askVolume = 0;
      let depthListValues = [];
      let lastUpdated = '-';
      let lastUpdatedTooltip = '-';
      let ltpPrice = '0.00000';
      let ltpVolume = 0;
      const symbolItemValue = symbolItem[selectedSymbol.value] || symbolItemLast[selectedSymbol.value];
      if (symbolItemValue) {
        BidPrice = symbolItemValue.bid_price || 0;
        AskPrice = symbolItemValue.ask_price || 0;
        BidPriceSplit = this.priceSplit(addCommas(parseFloat(BidPrice)
          .toFixed(selectedSymbol.digits || decimalPlaces(BidPrice)).toString()));
        AskPriceSplit = this.priceSplit(addCommas(parseFloat(AskPrice)
          .toFixed(selectedSymbol.digits || decimalPlaces(AskPrice)).toString()));
        bidPriceChange = symbolItemValue.bid_price_change;
        askPriceChange = symbolItemValue.ask_price_change;
        bidVolume = new Intl.NumberFormat('en-GB', {
          notation: 'compact',
          maximumFractionDigits: selectedSymbol.vol_digits,
        }).format(symbolItemValue.bid_volume);
        askVolume = new Intl.NumberFormat('en-GB', {
          notation: 'compact',
          maximumFractionDigits: selectedSymbol.vol_digits,
        }).format(symbolItemValue.ask_volume);
        ltpPrice = addCommas(parseFloat(symbolItemValue.ltp_price)
          .toFixed(selectedSymbol.digits || decimalPlaces(symbolItemValue.ltp_price))
          .toString());
        ltpVolume = new Intl.NumberFormat('en-GB', {
          notation: 'compact',
          maximumFractionDigits: selectedSymbol.vol_digits,
        }).format(symbolItemValue.ltp_volume);
        depthListValues = symbolItemValue.depth_symbols;
        if (symbolItemValue.last_updated) {
          const lastUpdatedLocalTime = moment.utc(symbolItemValue.last_updated, 'DD-MM-YYYY HH:mm:ss');
          lastUpdated = lastUpdatedLocalTime.format('HH:mm:ss');
          lastUpdatedTooltip = lastUpdatedLocalTime.format('DD-MM-YYYY HH:mm:ss');
        }
      }
      const depthLength = (5 - depthListValues.length);
      for (let i = 0; i < depthLength; i += 1) {
        depthListValues.push({
          bid_price: '0.00000', ask_price: '0.00000', bid_volume: 0, ask_volume: 0,
        });
      }
      const rawSpread = Math.abs(AskPrice - BidPrice);
      let spreadPoints = 0;
      if (parseFloat(AskPrice || 0) !== 0 && parseFloat(BidPrice || 0) !== 0) {
        spreadPoints = Math.round(rawSpread * (10 ** selectedSymbol.digits));
      }
      const spread = addCommas(parseFloat(rawSpread.toString())
        .toFixed(selectedSymbol.digits || decimalPlaces(AskPrice)).toString());
      const depthList = depthListValues.map((depth) => {
        k += 1;
        let spreadPointsDepth = 0;
        if (parseFloat(depth.ask_price || 0) !== 0 && parseFloat(depth.bid_price || 0) !== 0) {
          spreadPointsDepth = Math.round(Math.abs(depth.ask_price - depth.bid_price) * (10 ** selectedSymbol.digits));
        }
        const bid = parseFloat(depth.bid_price || 0).toFixed(selectedSymbol.digits
          || decimalPlaces(depth.bid_price)).toString();
        const ask = parseFloat(depth.ask_price || 0).toFixed(selectedSymbol.digits
          || decimalPlaces(depth.ask_price)).toString();
        return (
          <tr key={k}>
            <td>{new Intl.NumberFormat('en-GB', {
              notation: 'compact',
              maximumFractionDigits: selectedSymbol.vol_digits,
            }).format(depth.bid_volume)}
            </td>
            <td style={{ display: 'none' }} />
            <td className="link" onClick={() => this.onPriceClick(bid)}>{addCommas(bid)}</td>
            <td>
              {addCommas(spreadPointsDepth.toString())}
            </td>
            <td className="link" onClick={() => this.onPriceClick(ask)}>{addCommas(ask)}</td>
            <td style={{ display: 'none' }} />
            <td>{new Intl.NumberFormat('en-GB', {
              notation: 'compact',
              maximumFractionDigits: selectedSymbol.vol_digits,
            }).format(depth.ask_volume)}
            </td>
          </tr>
        );
      });
      panels1 = (
        <TradingPanel md={3} divider title={selectedSymbol.value} className="depth depth-mw depth-trading" key={j}>
          <div className="half-table">
            <Table responsive>
              <tbody>
                <tr>
                  <td>{literals.tables.trading_table.qty}</td>
                  <td className={`price-change-${bidPriceChange}`}>{literals.tables.trading_table.bid}</td>
                  <td className={`price-change-before-${askPriceChange}`}>{literals.tables.trading_table.ask}</td>
                  <td>{literals.tables.trading_table.qty}</td>
                </tr>
                <tr>
                  <td>{bidVolume}</td>
                  <td className="link" onClick={() => this.onPriceClick(BidPriceSplit.join(''))}>{BidPriceSplit[0]}
                    <span style={{ fontSize: '25px' }}>{BidPriceSplit[1]}</span>
                    <span style={{ verticalAlign: 'top' }}>{BidPriceSplit[2]}</span>
                  </td>
                  <td className="link" onClick={() => this.onPriceClick(AskPriceSplit.join(''))}>{AskPriceSplit[0]}
                    <span style={{ fontSize: '25px' }}>{AskPriceSplit[1]}</span>
                    <span style={{ verticalAlign: 'top' }}>{AskPriceSplit[2]}</span>
                  </td>
                  <td>{askVolume}</td>
                </tr>
                <tr>
                  <td />
                  <td>{literals.tables.trading_table.spread}</td>
                  <td>{spread}</td>
                </tr>
                <tr>
                  <td>{literals.tables.trading_table.ltp_price}</td>
                  <td>{literals.tables.trading_table.points}</td>
                  <td>{addCommas(spreadPoints.toString())}</td>
                  <td>{literals.tables.trading_table.ltp_volume}</td>
                </tr>
                <tr>
                  <td>{ltpPrice}</td>
                  <td>{literals.tables.trading_table.time}</td>
                  <td>
                    <Tooltip title={lastUpdatedTooltip}>{lastUpdated}
                    </Tooltip>
                  </td>
                  <td>{ltpVolume}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Table striped responsive>
            <tbody>
              {depthList}
            </tbody>
          </Table>
          {readOnly !== 1 &&
          <TradingActionForm
            symbol={selectedSymbol.value}
            account={this.props.selectedAccount}
            node={this.props.selectedNode}
            digits={selectedSymbol.digits || decimalPlaces(symbolItemValue ? symbolItemValue.ask_price : '0')}
            minVolume={selectedSymbol.min_volume}
            maxVolume={selectedSymbol.max_volume}
            priceFill={parseFloat(symbolItemValue ? symbolItemValue.ask_price : '0')
              .toFixed(selectedSymbol.digits
                || decimalPlaces(symbolItemValue ? symbolItemValue.ask_price : '0')).toString()}
            oneClickTradingStatus={this.props.search_values.oneClickTrading}
            bidPrice={BidPrice}
            askPrice={AskPrice}
          />}
        </TradingPanel>
      );
    }
    return (
      <span>
        { panels1 }
      </span>
    );
  }
}
export default connect(state => ({
  search_values: getFormValues('trading_broker_user_search_form')(state),
  trading_action_form_values: getFormValues('trading_action_form')(state),
  literals: state.literals,
}))(MarketWatchView);
