import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { inject } from 'mobx-react';
import _ from 'lodash';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';
import TopbarConnectionStatus from './TopbarConnectionStatus';
import TopbarClientName from './TopbarClientName';
import TopbarAggregatorRestart from './TopbarAggregatorRestart';
import TopbarVersion from './TopbarVersion';
import TopbarWorkSpace from './TopbarWorkSpace';
import TopbarNotification from './TopbarNotification';
import TopbarRTInfo from './TopbarRTInfo';
import TopbarRiskAccount from './TopbarRiskAccount';
import TopbarNewUI from './TopbarNewUI';

@inject('rootStore')
class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    changeLanguageToEN: PropTypes.func.isRequired,
    changeLanguageToCN: PropTypes.func.isRequired,
    changeLanguageToJA: PropTypes.func.isRequired,
    changeLanguageToHI: PropTypes.func.isRequired,
    loadLiteralsEN: PropTypes.func.isRequired,
    loadLiteralsCN: PropTypes.func.isRequired,
    loadLiteralsJA: PropTypes.func.isRequired,
    loadLiteralsHI: PropTypes.func.isRequired,
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      changeLanguageToEN,
      changeLanguageToCN,
      changeLanguageToJA,
      changeLanguageToHI,
      loadLiteralsEN,
      loadLiteralsCN,
      loadLiteralsJA,
      loadLiteralsHI,
      changeToDark,
      changeToLight
    } = this.props;
    const { role, user } = this.props.rootStore.authStore;
    const userRiskAccounts = user.user_risk_accounts || [];
    const riskAccount = _.find(userRiskAccounts, ['default_risk', 1]);
    let defaultRiskAccount = {};
    if (riskAccount) {
      defaultRiskAccount = {
        label: riskAccount.alias,
        value: riskAccount.alias,
        riskAccount
      };
      if (!this.props.rootStore.reportStore.reportState.riskAccount) {
        this.props.rootStore.reportStore.reportState.alias = riskAccount.alias;
        this.props.rootStore.reportStore.reportState.riskAccount =
          riskAccount.risk_account;
        this.props.rootStore.reportStore.reportState.taker = riskAccount.taker;
        this.props.rootStore.reportStore.reportState.execModel =
          riskAccount.tem;
        this.props.rootStore.reportStore.reportState.feed =
          riskAccount.taker_feed;
        this.props.rootStore.reportStore.reportState.readOnly =
          riskAccount.read_only;
        this.props.rootStore.reportStore.reportState.extOrderInfo =
          riskAccount.ext_order_info;
      }
    }
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/" />
            <TopbarWorkSpace />
          </div>
          <div className="topbar__right">
            {role === 'client' && (
              <TopbarRiskAccount
                initialValues={{ user_risk_account: defaultRiskAccount }}
              />
            )}
            <TopbarNewUI />
            <TopbarClientName />
            {role === 'admin' && (
              <React.Fragment>
                <TopbarVersion />
                <TopbarRTInfo />
              </React.Fragment>
            )}
            <TopbarNotification />
            <TopbarConnectionStatus />
            {(role === 'admin' || role === 'broker') && (
              <TopbarAggregatorRestart />
            )}
            <TopbarProfile
              changeToDark={changeToDark}
              changeToLight={changeToLight}
            />
            <TopbarLanguage
              changeLanguageToEN={changeLanguageToEN}
              changeLanguageToCN={changeLanguageToCN}
              changeLanguageToJA={changeLanguageToJA}
              changeLanguageToHI={changeLanguageToHI}
              loadLiteralsEN={loadLiteralsEN}
              loadLiteralsCN={loadLiteralsCN}
              loadLiteralsJA={loadLiteralsJA}
              loadLiteralsHI={loadLiteralsHI}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  literals: state.literals
}))(Topbar);
