/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const riskAccounts = values.riskAccounts.map((risk, i) => ({
    alias: risk.alias,
    risk_account: risk.risk_account.value,
    taker: risk.taker.value,
    tem: risk.tem.value,
    taker_feed: risk.taker_feed.value,
    default_risk: parseInt(values.default_risk, 10) === i ? 1 : 0,
    read_only: risk.read_only ? 1 : 0,
    daily_statement: risk.daily_statement ? 1 : 0,
    ext_order_info: risk.ext_order_info ? 1 : 0,
    master_risk_account: risk.risk_account.master_risk_account,
  }));
  const data = {
    username: values.username,
    password: values.password,
    first_name: values.first_name,
    last_name: values.last_name,
    user_risk_accounts: riskAccounts,
    email: values.email,
    phone: values.phone,
    email_notify: values.email_notify,
  };

  axios.post(`${process.env.REACT_APP_BACKEND_API}/broker/user`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.user_form.title_create, 'primary',
          props.literals.forms.success_message,
        );
        stateObj.resetUser();
        stateObj.toggle('1');
      } else {
        showNotification(props.literals.forms.user_form.title_create, 'danger', props.literals.forms.fail_message);
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.user_form.title_create, 'danger', error.message);
      stateObj.hideLoader();
    });
};
