import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import CommandsForm from './form';
import Loader from '../../shared/components/Loader';

class Commands extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      commands_form_values: PropTypes.instanceOf(Object),
    };
    static defaultProps = {
      commands_form_values: {},
    }
    constructor(props) {
      super(props);
      this.state = {
        loaderShow: false,
        message: '',
      };
    }

    onCommandClick=(type) => {
      this.showLoader();
      let addr = '';
      switch (type) {
        case 'ping':
          addr = this.props.commands_form_values.ping_address;
          break;
        case 'traceroute':
          addr = this.props.commands_form_values.traceroute_address;
          break;
        case 'telnet':
          addr = this.props.commands_form_values.telnet_ip;
          if (this.props.commands_form_values.telnet_port) {
            addr = `${addr} ${this.props.commands_form_values.telnet_port}`;
          }
          break;
        case 'openssl':
          addr = this.props.commands_form_values.openssl_ip;
          if (this.props.commands_form_values.openssl_port) {
            addr = `${addr}:${this.props.commands_form_values.openssl_port}`;
          }
          break;
        default:
          addr = '';
      }
      const data = {
        command_type: type,
        address: addr,
      };
      axios.post(`${process.env.REACT_APP_BACKEND_API}/admin/commands`, JSON.stringify(data))
        .then((response) => {
          this.setState({ message: response.data.message });
          this.hideLoader();
        })
        .catch((error) => {
          this.setState({ message: error.message });
          this.hideLoader();
        });
    }

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    render() {
      const { literals } = this.props;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.commands}
                </h5>
              </div>
              <div className="container commands-container">
                <div className="row">
                  <div className="col-sm">
                    <CommandsForm commandClick={this.onCommandClick} />
                  </div>
                  <div className="col-sm">
                    <CardText>{this.state.message}</CardText>
                  </div>
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default withRouter(connect(state => ({
  literals: state.literals,
  commands_form_values: getFormValues('commands_form')(state),
}))(Commands));

