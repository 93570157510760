import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Websocket from 'react-websocket';

@inject('rootStore')
class TopbarRTInfo extends Component {
  static propTypes = {
    marginLeft: PropTypes.number,
    rootStore: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string,
  };
  static defaultProps = {
    marginLeft: 5,
    className: 'conn__status',
  };
  constructor(props) {
    super(props);
    this.state = {
      rtInfo: {},
    };
  }
  componentDidMount() {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/rt_info`)
      .then((response) => {
        const data = response.data[0];

        this.setState({ rtInfo: data });
      })
      .catch(() => {
      });
  }

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    if ('aggregator_rt_info' in data && data.aggregator_rt_info) {
      this.setState({ rtInfo: data.aggregator_rt_info });
    }
  };
  socketOpenListener = () => {
    this.sendMessage(JSON.stringify({
      key: 'aggregator_rt_info',
      value: [],
    }));
  };
  sendMessage=(message) => {
    this.refWebSocket.sendMessage(message);
  };

  render() {
    const { rtInfo } = this.state;
    return (
      <React.Fragment key="sss">
        {this.props.className === 'conn__status__mobile' && rtInfo &&
        <React.Fragment key="ttt">
          <div className="topbar__profile">
            <div style={{ lineHeight: '20px' }}>
              <p />
              <p style={{
              display: 'inline-block',
              marginLeft: this.props.marginLeft,
            }}
              >
              pm. {rtInfo.pmmb}MB
              </p>
            </div>
            <div style={{ lineHeight: 0 }}>
              <p />
              <p style={{
              display: 'inline-block',
              marginLeft: this.props.marginLeft,
            }}
              >
              pmp. {rtInfo.pmpmb}MB
              </p>
            </div>
          </div>
          <div className="topbar__profile">
            <div style={{ lineHeight: '20px' }}>
              <p />
              <p style={{
          display: 'inline-block',
          marginLeft: this.props.marginLeft,
        }}
              >
          p.cpu. {rtInfo.pcpup}%
              </p>
            </div>
            <div style={{ lineHeight: 0 }}>
              <p />
              <p style={{
          display: 'inline-block',
          marginLeft: this.props.marginLeft,
        }}
              >
          s.cpu. {rtInfo.scpup}%
              </p>
            </div>
          </div>
        </React.Fragment>
        }
        <Websocket
          url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
          reconnectIntervalInMilliSeconds={1000}
          onMessage={this.socketMessageListener}
          onOpen={this.socketOpenListener}
          ref={(WS) => {
              this.refWebSocket = WS;
            }}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(TopbarRTInfo);
