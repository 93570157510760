import {
  SET_MARKET_WATCH_COLOR,
} from '../actions/marketWatchActions';


const defaultState = {};

const MarketWatchReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_MARKET_WATCH_COLOR:
      return {
        ...state,
        marketWatchColor: payload,
      };
    default:
      return state;
  }
};

export default MarketWatchReducer;
