import { Field } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import renderSelectField from '../../shared/components/form/SelectBox';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

const namePrefix = 'mm_';
const MMForm = ({
  literals, renderField, spreadExec, dir,
}) => (
  <div className="borderValuesForm">
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{`${literals.forms.markup_symbol_form.markup_bid} (${literals.forms.markup_group_form.points})`}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}markup_bid`}
          component={renderField}
          type="number"
          step="any"
          placeholder={`${literals.forms.markup_symbol_form.markup_bid} (${literals.forms.markup_group_form.points})`}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{`${literals.forms.markup_symbol_form.markup_bid_var} (${literals.forms.markup_group_form.points})`}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}markup_bid_var`}
          component={renderField}
          type="number"
          step="any"
          placeholder={`${literals.forms.markup_symbol_form.markup_bid_var}
          (${literals.forms.markup_group_form.points})`}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{`${literals.forms.markup_symbol_form.markup_ask} (${literals.forms.markup_group_form.points})`}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}markup_ask`}
          component={renderField}
          type="number"
          step="any"
          placeholder={`${literals.forms.markup_symbol_form.markup_ask} (${literals.forms.markup_group_form.points})`}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{`${literals.forms.markup_symbol_form.markup_ask_var} (${literals.forms.markup_group_form.points})`}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}markup_ask_var`}
          component={renderField}
          type="number"
          step="any"
          placeholder={`${literals.forms.markup_symbol_form.markup_ask_var}
          (${literals.forms.markup_group_form.points})`}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{`${literals.forms.markup_symbol_form.spread_min} (${literals.forms.markup_group_form.points})`}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}spread_min`}
          component={renderField}
          type="number"
          step="any"
          placeholder={`${literals.forms.markup_symbol_form.spread_min} (${literals.forms.markup_group_form.points})`}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{`${literals.forms.markup_symbol_form.spread_max} (${literals.forms.markup_group_form.points})`}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}spread_max`}
          component={renderField}
          type="number"
          step="any"
          placeholder={`${literals.forms.markup_symbol_form.spread_max} (${literals.forms.markup_group_form.points})`}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{literals.forms.markup_symbol_form.spread_exec}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}spread_exec`}
          component={renderSelectField}
          type="text"
          options={spreadExec}
        />
      </div>
    </div>
  </div>
);
MMForm.propTypes = {
  literals: LiteralProps.isRequired,
  renderField: PropTypes.instanceOf(Object).isRequired,
  spreadExec: PropTypes.instanceOf(Array),
  dir: PropTypes.string.isRequired,
};
MMForm.defaultProps = {
  spreadExec: [],
};

export default MMForm;
