/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized
} from '../../shared/helper';
import { BasicNotification } from '../../shared/components/Notification';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import NodeEditor from './NodeEditor';
import SessionRenderer from '../../shared/components/session/SessionRenderer';
import SessionHeaderComponent from '../../shared/components/multi_edit/SessionHeaderComponent';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

@inject('rootStore')
class NodeAccountDetailCellRenderer extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    data: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    this.rowsToUpdate = props.context.rowsToUpdateDetail[props.node.id] || {};
    this.stylesToUpdate =
      props.context.stylesToUpdateDetail[props.node.id] || {};
    const { literals } = this.props;
    const { role } = props.rootStore.authStore;
    this.state = {
      modules: AllModules,
      colDefs: [
        {
          headerName: literals.forms.node_account_symbol_form.symbol,
          field: 'symbol_val',
          editable: false,
          pinned: 'left',
          cellClassRules: {
            'arrow-right': (params) =>
              typeof this.stylesToUpdate[params.node.id] !== 'undefined'
          },
          width: 60,
          minWidth: 60
        },
        {
          headerName: literals.forms.node_account_symbol_form.security,
          field: 'security',
          editable: role === 'admin',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: role === 'admin' ? 'cellHeaderComponent' : '',
          headerComponentParams: {
            selectValueLiteral: 'Security'
          }
        },
        {
          headerName: literals.forms.node_account_symbol_form.node,
          field: 'node',
          editable: role === 'admin',
          cellEditor: 'nodeEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: role === 'admin' ? 'cellHeaderComponent' : '',
          headerComponentParams: {
            selectValueLiteral: 'Node'
          }
        },
        {
          headerName: literals.forms.node_account_symbol_form.node_account,
          field: 'node_account',
          editable: false,
          width: 60,
          minWidth: 60
        },
        {
          headerName: literals.forms.node_account_symbol_form.aggregate_group,
          field: 'aggregate_group',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'AggregateGroup'
          }
        },
        {
          headerName: literals.forms.node_account_symbol_form.markup_group,
          field: 'markup_group',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'MarkupGroup'
          }
        },
        {
          headerName: literals.forms.node_account_symbol_form.exec_mode,
          field: 'exec_mode_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'ExecMode'
          }
        },
        {
          headerName: literals.forms.node_account_symbol_form.min_volume,
          field: 'min_volume',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.max_volume,
          field: 'max_volume',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.ttl,
          field: 'ttl',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.a_min,
          field: 'a_min',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.a_step,
          field: 'a_step',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.b_perc,
          field: 'b_perc',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.b_fix,
          field: 'b_fix',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.sessions,
          field: 'sessions',
          cellEditor: 'agLargeTextCellEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: 'sessionHeaderComponent',
          editable: false,
          cellRenderer: 'sessionRenderer'
        },
        {
          headerName: literals.forms.node_account_symbol_form.b_boost,
          field: 'b_boost',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.b_delay,
          field: 'b_delay',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.b_delay_jitter,
          field: 'b_delay_jitter',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.gain_perc,
          field: 'gain_perc',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.contract_size,
          field: 'contract_size',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.ll_variation,
          field: 'll_variation',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.node_account_symbol_form.ll_action,
          field: 'll_action_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'LLAction'
          }
        },
        {
          headerName: literals.forms.node_account_symbol_form.state,
          field: 'state_value',
          editable: false,
          cellRenderer: 'stateRenderer',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'stateHeaderComponent'
        },
        {
          headerName: literals.forms.lp_symbol_form.description,
          field: 'description',
          editable: false,
          tooltipField: 'description'
        }
      ],
      frameworkComponents: {
        nodeEditor: NodeEditor,
        stateRenderer: StateRenderer,
        stateHeaderComponent: StateHeaderComponent,
        cellHeaderComponent: HeaderComponent,
        sessionRenderer: SessionRenderer,
        sessionHeaderComponent: SessionHeaderComponent
      },
      rowData: [],
      rowOldData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50
      },
      cellEditUpdate: props.context.cellEditUpdateDetail[props.node.id],
      getRowNodeId(data) {
        return data.symbol_val;
      }
    };

    this.state.rowID = props.node.id
      ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff')
      : props.node.id;
    this.gridWrapperID = 'grid-wrapper-sidebar_node_account';
    this.myGridID = `myGrid_sidebar_node_account_symbol_${this.state.rowID}`;
    this.state.masterGridApi = props.api;
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridInfo.columnApi) {
      const { literals } = this.props;
      if (
        literals.forms.node_account_symbol_form.symbol !==
        prevProps.literals.forms.node_account_symbol_form.symbol
      ) {
        this.gridInfo.columnApi.getColumn('symbol_val').getColDef().headerName =
          literals.forms.node_account_symbol_form.symbol;
        this.gridInfo.columnApi.getColumn('security').getColDef().headerName =
          literals.forms.node_account_symbol_form.security;
        this.gridInfo.columnApi.getColumn('node').getColDef().headerName =
          literals.forms.node_account_symbol_form.node;
        this.gridInfo.columnApi
          .getColumn('node_account')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.node_account;
        this.gridInfo.columnApi
          .getColumn('aggregate_group')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.aggregate_group;
        this.gridInfo.columnApi
          .getColumn('markup_group')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.markup_group;
        this.gridInfo.columnApi
          .getColumn('exec_mode_value')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.exec_mode;
        this.gridInfo.columnApi.getColumn('min_volume').getColDef().headerName =
          literals.forms.node_account_symbol_form.min_volume;
        this.gridInfo.columnApi.getColumn('max_volume').getColDef().headerName =
          literals.forms.node_account_symbol_form.max_volume;
        this.gridInfo.columnApi.getColumn('a_min').getColDef().headerName =
          literals.forms.node_account_symbol_form.a_min;
        this.gridInfo.columnApi.getColumn('a_step').getColDef().headerName =
          literals.forms.node_account_symbol_form.a_step;
        this.gridInfo.columnApi.getColumn('b_perc').getColDef().headerName =
          literals.forms.node_account_symbol_form.b_perc;
        this.gridInfo.columnApi.getColumn('b_fix').getColDef().headerName =
          literals.forms.node_account_symbol_form.b_fix;
        this.gridInfo.columnApi.getColumn('sessions').getColDef().headerName =
          literals.forms.node_account_symbol_form.sessions;
        this.gridInfo.columnApi.getColumn('b_boost').getColDef().headerName =
          literals.forms.node_account_symbol_form.b_boost;
        this.gridInfo.columnApi.getColumn('b_delay').getColDef().headerName =
          literals.forms.node_account_symbol_form.b_delay;
        this.gridInfo.columnApi
          .getColumn('b_delay_jitter')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.b_delay_jitter;
        this.gridInfo.columnApi.getColumn('gain_perc').getColDef().headerName =
          literals.forms.node_account_symbol_form.gain_perc;
        this.gridInfo.columnApi
          .getColumn('contract_size')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.contract_size;
        this.gridInfo.columnApi
          .getColumn('ll_variation')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.ll_variation;
        this.gridInfo.columnApi
          .getColumn('ll_action_value')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.ll_action;
        this.gridInfo.columnApi
          .getColumn('state_value')
          .getColDef().headerName =
          literals.forms.node_account_symbol_form.state;
        this.gridInfo.columnApi
          .getColumn('description')
          .getColDef().headerName = literals.forms.lp_symbol_form.description;
        this.gridInfo.api.refreshHeader();
      }
    }
  }

  componentWillUnmount = () => {
    if (this.gridInfo) {
      window[`colStateNodeAccount${this.state.rowID}`] =
        this.gridInfo.columnApi.getColumnState();
      window[`groupStateNodeAccount${this.state.rowID}`] =
        this.gridInfo.columnApi.getColumnGroupState();
      window[`filterStateNodeAccount${this.state.rowID}`] =
        this.gridInfo.api.getFilterModel();
    }

    const detailGridId = this.createDetailGridId();

    // ag-Grid is automatically destroyed
    this.state.masterGridApi.removeDetailGridInfo(detailGridId);
  };

  onCellClass = (params) =>
    typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
    typeof this.stylesToUpdate[params.node.id][params.colDef.field] !==
      'undefined';

  onCellValueChanged = (params) => {
    if (params.oldValue != params.value) {
      if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
        this.stylesToUpdate[params.node.id] = {};
        this.gridInfo.api.refreshCells({
          rowNodes: [params.node],
          columns: ['symbol_val'],
          force: true
        });
      }
      this.stylesToUpdate[params.node.id][params.colDef.field] = {};
      this.gridInfo.api.refreshCells({
        rowNodes: [params.node],
        columns: [params.column],
        force: true
      });

      if (params.colDef.field === 'state_value') {
        params.data.state =
          this.props.context.formData.StateObj[params.newValue].value;
      }
      if (params.colDef.field === 'exec_mode_value') {
        params.data.exec_mode =
          this.props.context.formData.ExecModeObj[params.newValue].value;
      }
      if (params.colDef.field === 'll_action_value') {
        params.data.ll_action =
          this.props.context.formData.LLActionObj[params.newValue].value;
      }
      this.rowsToUpdate[`${params.node.id}`] = params.data;
      this.setState({ cellEditUpdate: true });
      this.props.context.updateStateDetail(
        this.state.rowID,
        true,
        this.rowsToUpdate,
        this.stylesToUpdate
      );
    }
  };

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onGridReady(params) {
    const detailGridId = this.createDetailGridId();

    this.gridInfo = {
      id: detailGridId,
      api: params.api,
      columnApi: params.columnApi
    };
    this.gridInfo.api.showLoadingOverlay();
    this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfo);

    this.gridInfo.columnApi
      .getColumn('security')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .Security
      ? this.props.context.formData.Security.map((a) => a.label)
      : [];
    this.gridInfo.columnApi
      .getColumn('aggregate_group')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .AggregateGroup
      ? this.props.context.formData.AggregateGroup.map((a) => a.value)
      : [];
    this.gridInfo.columnApi
      .getColumn('markup_group')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .MarkupGroup
      ? this.props.context.formData.MarkupGroup.map((a) => a.value)
      : [];
    this.gridInfo.columnApi
      .getColumn('exec_mode_value')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .ExecMode
      ? this.props.context.formData.ExecMode.map((a) => a.label)
      : [];
    this.gridInfo.columnApi
      .getColumn('ll_action_value')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .LLAction
      ? this.props.context.formData.LLAction.map((a) => a.label)
      : [];
    if (window[`colStateNodeAccount${this.state.rowID}`]) {
      this.gridInfo.columnApi.setColumnState(
        window[`colStateNodeAccount${this.state.rowID}`]
      );
    }
    if (window[`groupStateNodeAccount${this.state.rowID}`]) {
      this.gridInfo.columnApi.setColumnGroupState(
        window[`groupStateNodeAccount${this.state.rowID}`]
      );
    }
    if (window[`filterStateNodeAccount${this.state.rowID}`]) {
      this.gridInfo.api.setFilterModel(
        window[`filterStateNodeAccount${this.state.rowID}`]
      );
    }
    this.setState({ rowData: this.props.data.node_account_symbols });
    this.setState({
      rowOldData: JSON.parse(
        JSON.stringify(this.props.data.node_account_symbols)
      )
    });
    this.props.node.setDataValue(
      'markup_group',
      _.uniq(_.map(this.props.data.node_account_symbols, 'markup_group'))
    );
    this.props.node.setDataValue(
      'aggregate_group',
      _.uniq(_.map(this.props.data.node_account_symbols, 'aggregate_group'))
    );
    this.onGridSizeChanged(params);
  }

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: [
        'symbol_val',
        'security',
        'node',
        'node_account',
        'aggregate_group',
        'markup_group',
        'exec_mode_value',
        'min_volume',
        'max_volume',
        'ttl',
        'a_min',
        'a_step',
        'b_perc',
        'b_fix',
        'sessions',
        'b_boost',
        'b_delay',
        'b_delay_jitter',
        'gain_perc',
        'contract_size',
        'll_variation',
        'll_action_value',
        'state_value',
        'description'
      ],
      fileName: 'TakerSymbolExecution.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridInfo.api.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: [
        'symbol_val',
        'security',
        'node',
        'node_account',
        'aggregate_group',
        'markup_group',
        'exec_mode_value',
        'min_volume',
        'max_volume',
        'ttl',
        'a_min',
        'a_step',
        'b_perc',
        'b_fix',
        'sessions',
        'b_boost',
        'b_delay',
        'b_delay_jitter',
        'gain_perc',
        'contract_size',
        'll_variation',
        'll_action_value',
        'state_value',
        'description'
      ],
      fileName: 'TakerSymbolExecution.csv'
    };
    this.gridInfo.api.exportDataAsCsv(params);
  };

  createDetailGridId = () => `detail_${this.state.rowID}`;

  updateNodeAccountSymbol = () => {
    this.props.context.showLoader();
    const bulkData = [];
    const rowData = _.keyBy(this.state.rowOldData, 'symbol_val');
    Object.values(this.rowsToUpdate).forEach((values) => {
      const oldRow = rowData[values.symbol_val];
      const dataToUpdate = {
        symbol_val: values.symbol_val,
        security: values.security,
        node: values.node,
        node_account: values.node_account,
        aggregate_group: values.aggregate_group,
        markup_group: values.markup_group,
        exec_mode: parseInt(values.exec_mode, 10),
        min_volume: parseFloat(values.min_volume),
        max_volume: parseFloat(values.max_volume),
        ttl: parseInt(values.ttl, 10),
        a_min: parseFloat(values.a_min),
        a_step: parseFloat(values.a_step),
        b_perc: parseInt(values.b_perc, 10),
        b_fix: parseInt(values.b_fix, 10),
        sessions: values.sessions,
        b_boost: parseFloat(values.b_boost),
        b_delay: parseInt(values.b_delay, 10),
        b_delay_jitter: parseInt(values.b_delay_jitter, 10),
        gain_perc: parseInt(values.gain_perc, 10),
        contract_size: parseFloat(values.contract_size),
        ll_variation: parseFloat(values.ll_variation),
        ll_action: parseInt(values.ll_action, 10),
        state: parseInt(values.state, 10),
        old_data: {
          symbol_val: oldRow.symbol_val,
          security: oldRow.security,
          node: oldRow.node,
          node_account: oldRow.node_account,
          aggregate_group: oldRow.aggregate_group,
          markup_group: oldRow.markup_group,
          exec_mode: parseInt(oldRow.exec_mode, 10),
          min_volume: parseFloat(oldRow.min_volume),
          max_volume: parseFloat(oldRow.max_volume),
          ttl: parseInt(oldRow.ttl, 10),
          a_min: parseFloat(oldRow.a_min),
          a_step: parseFloat(oldRow.a_step),
          b_perc: parseInt(oldRow.b_perc, 10),
          b_fix: parseInt(oldRow.b_fix, 10),
          sessions: oldRow.sessions,
          b_boost: parseFloat(oldRow.b_boost),
          b_delay: parseInt(oldRow.b_delay, 10),
          b_delay_jitter: parseInt(oldRow.b_delay_jitter, 10),
          gain_perc: parseInt(oldRow.gain_perc, 10),
          contract_size: parseFloat(oldRow.contract_size),
          ll_variation: parseFloat(oldRow.ll_variation),
          ll_action: parseInt(oldRow.ll_action, 10),
          state: parseInt(oldRow.state, 10)
        }
      };
      bulkData.push(dataToUpdate);
    });
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/node/account/symbol_bulk`,
        JSON.stringify(bulkData)
      )
      .then((response) => {
        this.resetNodeAccountSymbol();
        showNotification(
          this.props.literals.sidebar.node_account_symbol,
          'primary',
          this.props.literals.forms.completed_message
        );
        if (response.data.length > 0) {
          showNotification(
            this.props.literals.sidebar.node_account_symbol,
            'danger',
            `${
              this.props.literals.forms.fail_message
            } - ${response.data.toString()}}`
          );
        }
      })
      .catch((error) => {
        this.resetNodeAccountSymbol();
        showNotification(
          this.props.literals.sidebar.node_account_symbol,
          'danger',
          `${this.props.literals.forms.fail_message} - ${error.message}}`
        );
      });
  };

  resetNodeAccountSymbol = () => {
    this.rowsToUpdate = {};
    this.setState({ cellEditUpdate: false });
    this.props.context.resetNodeAccount();
    window[`colStateNodeAccount${this.state.rowID}`] =
      this.gridInfo.columnApi.getColumnState();
    window[`groupStateNodeAccount${this.state.rowID}`] =
      this.gridInfo.columnApi.getColumnGroupState();
    window[`filterStateNodeAccount${this.state.rowID}`] =
      this.gridInfo.api.getFilterModel();
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate).forEach((keys) => {
      const node = this.gridInfo.api.getRowNode(keys);
      if (node) {
        this.gridInfo.api.refreshCells({
          rowNodes: [node],
          columns: ['symbol_val'],
          force: true
        });
        Object.keys(stylesToUpdate[keys]).forEach((values) => {
          this.gridInfo.api.refreshCells({
            rowNodes: [node],
            columns: [values],
            force: true
          });
        });
      }
    });
    this.props.context.updateStateDetail(
      this.state.rowID,
      false,
      this.rowsToUpdate,
      this.stylesToUpdate
    );
  };

  render() {
    const { literals } = this.props;
    return (
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          height: '100%'
        }}
      >
        <div className="outer-grid-label">
          {literals.sidebar.node_account_symbol} (
          {literals.tables.ag_table.total_records}: {this.state.rowData.length})
        </div>
        <div className="outer-grid-button">
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.save}>
              <Button
                style={{
                  display: this.state.cellEditUpdate ? '' : 'none'
                }}
                onClick={this.updateNodeAccountSymbol}
                className="icon"
                color="primary"
              >
                <p className="fa fa-save" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.undo}>
              <Button
                style={{
                  display: this.state.cellEditUpdate ? '' : 'none'
                }}
                onClick={() => {
                  this.props.context.showLoader();
                  this.resetNodeAccountSymbol();
                }}
                className="icon"
                color="primary"
              >
                <p className="fas fa-undo" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button
                onClick={this.onBtExportExcel}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_csv}>
              <Button
                onClick={this.onBtExportCSV}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-alt" />
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </div>
        <div
          id={this.myGridID}
          style={{
            width: '100%',
            height: this.props.node.rowHeight - REDUCE_HEIGHT,
            clear: 'both'
          }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.colDefs}
            stopEditingWhenCellsLoseFocus
            suppressCellSelection
            suppressContextMenu
            immutableData
            getRowNodeId={this.state.getRowNodeId}
            frameworkComponents={this.state.frameworkComponents}
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResized}
            onCellValueChanged={this.onCellValueChanged}
            rowData={this.state.rowData}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.onGridSizeChanged}
            rowHeight={20}
            context={{
              formData: this.props.context.formData,
              theme: this.props.theme,
              showLoader: this.props.context.showLoader,
              hideLoader: this.props.context.hideLoader,
              onCellValueChanged: this.onCellValueChanged
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  literals: state.literals,
  theme: state.theme
}))(withRouter(NodeAccountDetailCellRenderer));
