const configValidate = (values, props) => {
  const errors = {};
  if (!values.owner) {
    errors.owner = `${props.literals.forms.config_form.owner} ${props.literals.forms.required_validate}`;
  }
  if (!values.key) {
    errors.key = `${props.literals.forms.config_form.key} ${props.literals.forms.required_validate}`;
  }
  if (!values.value_param) {
    errors.value_param = `${props.literals.forms.config_form.value} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default configValidate;
