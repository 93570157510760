/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import {
  LiteralENGlobalProps,
  LiteralProps
} from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import { regExSignedDecimalNumber, shallowEqual } from '../../shared/helper';
import UploadData from '../../shared/components/UploadData';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};
class LimitSymbolUpload extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
    resetLimitSymbolGroup: PropTypes.func.isRequired,
    rowOldData: PropTypes.instanceOf(Object).isRequired
  };

  getHeaderName = (header) => {
    const { literalsENGlobal } = this.props;
    switch (header) {
      case literalsENGlobal.forms.limit_symbol_form.symbol_val:
        return { col: 'symbol_val', type: 'text' };
      case literalsENGlobal.forms.limit_symbol_form.limit_symbol_group:
        return { col: 'limit_symbol_group', type: 'text' };
      case literalsENGlobal.forms.limit_symbol_form.limit:
        return { col: 'limit', type: 'number' };
      case literalsENGlobal.forms.limit_symbol_form.margin_percentage:
        return { col: 'margin_percentage', type: 'number' };
      case literalsENGlobal.forms.limit_symbol_form.commission:
        return { col: 'commission', type: 'number' };
      case literalsENGlobal.forms.limit_symbol_form.commission_type:
        return { col: 'commission_type_value', type: 'text' };
      case literalsENGlobal.forms.limit_symbol_form.swaps_type:
        return { col: 'swaps_type_value', type: 'text' };
      case literalsENGlobal.forms.limit_symbol_form.contract_size:
        return { col: 'contract_size', type: 'number' };
      case literalsENGlobal.forms.limit_symbol_form.long_value:
        return { col: 'long_value', type: 'number' };
      case literalsENGlobal.forms.limit_symbol_form.short_value:
        return { col: 'short_value', type: 'number' };
      case literalsENGlobal.forms.limit_symbol_form.pl_multiplier:
        return { col: 'pl_multiplier', type: 'number' };
      case literalsENGlobal.forms.limit_symbol_form.three_days_swaps:
        return { col: 'three_days_swaps_value', type: 'text' };
      case literalsENGlobal.forms.limit_symbol_form.settlement_time:
        return { col: 'settlement_time', type: 'text' };
      default:
        return { col: header, type: 'text' };
    }
  };

  postMultiple = (files, allFiles, rawData, showLoader, hideLoader) => {
    try {
      showLoader();
      const bulkData = [];
      const errors = [];
      const arr = this.props.rowOldData.reduce(
        (pV, cV) => [...pV, ...cV.limit_symbols],
        []
      );
      const rowData = _.keyBy(arr, (o) => o.limit_symbol_group + o.symbol_val);
      rawData.forEach((values) => {
        const symbolAndLimitGroup = `${_.trim(values.symbol_val)}->${_.trim(
          values.limit_symbol_group
        )}`;
        const commissionTypeVal =
          this.props.formData.CommissionTypeObj[values.commission_type_value];
        if (
          !commissionTypeVal &&
          !_.isEmpty(this.props.formData.CommissionTypeObj)
        ) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.commission_type}`
          });
        }
        const commissionType = commissionTypeVal ? commissionTypeVal.value : 0;
        const swapsTypeVal =
          this.props.formData.SwapsTypeObj[values.swaps_type_value];
        if (!swapsTypeVal) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.swaps_type}`
          });
        }
        const swapsType = swapsTypeVal ? swapsTypeVal.value : 0;
        const threeDaysSwapsVal =
          this.props.formData.ThreeDaysSwapsObj[values.three_days_swaps_value];
        if (!threeDaysSwapsVal) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.three_days_swaps}`
          });
        }
        const threeDaysSwaps = threeDaysSwapsVal ? threeDaysSwapsVal.value : 0;
        if (!regExSignedDecimalNumber.test(values.limit)) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.limit}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.margin_percentage)) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.margin_percentage}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.commission)) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.commission}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.contract_size)) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.contract_size}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.long_value)) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.long_value}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.short_value)) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.short_value}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.pl_multiplier)) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.limit_symbol_form.pl_multiplier}`
          });
        }
        const oldRow = rowData[values.limit_symbol_group + values.symbol_val];
        if (!oldRow) {
          errors.push({
            symbol: symbolAndLimitGroup,
            error: `${this.props.literals.forms.invalid}`
          });
        } else {
          const newData = {
            symbol_val: _.trim(values.symbol_val),
            limit_symbol_group: _.trim(values.limit_symbol_group),
            limit: parseFloat(values.limit),
            margin_percentage: parseFloat(values.margin_percentage),
            commission: parseFloat(values.commission),
            commission_type: parseInt(commissionType, 10),
            swaps_type: parseInt(swapsType, 10),
            contract_size: parseFloat(values.contract_size),
            long_value: parseFloat(values.long_value),
            short_value: parseFloat(values.short_value),
            pl_multiplier: parseFloat(values.pl_multiplier),
            three_days_swaps: parseInt(threeDaysSwaps, 10),
            settlement_time: _.trim(values.settlement_time)
          };
          const oldData = {
            symbol_val: oldRow.symbol_val,
            limit_symbol_group: oldRow.limit_symbol_group,
            limit: parseFloat(oldRow.limit),
            margin_percentage: parseFloat(oldRow.margin_percentage),
            commission: parseFloat(oldRow.commission),
            commission_type: parseInt(oldRow.commission_type || 0, 10),
            swaps_type: parseInt(oldRow.swaps_type, 10),
            contract_size: parseFloat(oldRow.contract_size),
            long_value: parseFloat(oldRow.long_value),
            short_value: parseFloat(oldRow.short_value),
            pl_multiplier: parseFloat(oldRow.pl_multiplier),
            three_days_swaps: parseInt(oldRow.three_days_swaps, 10),
            settlement_time: oldRow.settlement_time
          };
          const dataToUpdate = {
            ...newData,
            old_data: {
              ...oldData
            }
          };
          if (!shallowEqual(newData, oldData)) {
            bulkData.push(dataToUpdate);
          }
        }
      });
      if (errors.length > 0) {
        showNotification(
          this.props.literals.sidebar.limit_symbol,
          'danger',
          JSON.stringify(errors),
          null
        );
        hideLoader();
        return;
      }
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}/account/limit_symbol_bulk`,
          JSON.stringify(bulkData)
        )
        .then((response) => {
          showNotification(
            this.props.literals.sidebar.limit_symbol,
            'primary',
            this.props.literals.forms.completed_message,
            3
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.limit_symbol,
              'danger',
              `${
                this.props.literals.forms.uni_symbol_form.symbols_failed
              } ${response.data.toString()} `,
              null
            );
          }
          allFiles.forEach((f) => f.remove());
          this.props.resetLimitSymbolGroup();
          hideLoader();
        })
        .catch(() => {
          allFiles.forEach((f) => f.remove());
          showNotification(
            this.props.literals.sidebar.limit_symbol,
            'danger',
            this.props.literals.forms.fail_message,
            3
          );
          this.props.resetLimitSymbolGroup();
          hideLoader();
        });
    } catch (e) {
      showNotification(
        this.props.literals.sidebar.limit_symbol,
        'danger',
        e.message,
        null
      );
      hideLoader();
    }
  };

  render() {
    return (
      <UploadData
        tableID="sidebar_limit_symbol_import"
        title={this.props.literals.sidebar.limit_symbol}
        getHeaderName={this.getHeaderName}
        postMultiple={this.postMultiple}
      />
    );
  }
}
export default connect((state) => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal
}))(LimitSymbolUpload);
