const changePasswordValidate = (values, props) => {
  const errors = {};
  if (!values.current_password) {
    errors.current_password =
        `${props.literals.forms.user_form.current_password} ${props.literals.forms.required_validate}`;
  }
  if (!values.new_password) {
    errors.new_password =
        `${props.literals.forms.user_form.new_password} ${props.literals.forms.required_validate}`;
  }
  if (!values.confirm_password) {
    errors.confirm_password =
        `${props.literals.forms.user_form.confirm_new_password} ${props.literals.forms.required_validate}`;
  }
  if (values.new_password && values.confirm_password) {
    if (values.new_password !== values.confirm_password) {
      errors.confirm_password = props.literals.forms.user_form.passwords_equal_validate;
    }
  }
  return errors;
};

export default changePasswordValidate;
