import { action, observable } from 'mobx';

class ReportStore {
  @observable reportState;
  constructor() {
    this.reset();
  }
  @action
  reset() {
    this.reportState = {};
  }
}
export default new ReportStore();
