import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class SelectField extends PureComponent {
    static propTypes = {
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        ]),
        label: PropTypes.string,
      })),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
          ]),
          label: PropTypes.string,
        }),
      ]).isRequired,
      disabled: PropTypes.bool,
      maxMenuHeight: PropTypes.number,
    };

    static defaultProps = {
      placeholder: '',
      options: [],
      disabled: false,
      maxMenuHeight: 200,
    };

    handleChange = (selectedOption) => {
      this.props.onChange(selectedOption);
    };

    render() {
      const {
        value, name, placeholder, options, disabled, maxMenuHeight,
      } = this.props;
      const colourStyles = {
        control: styles => ({
          ...styles, minHeight: 22, height: 22, borderRadius: 0,
        }),
        indicatorsContainer: styles => ({ ...styles, height: 22 }),
        valueContainer: styles => ({ ...styles, height: 22, position: 'unset' }),
      };
      return (
        <Select
          styles={colourStyles}
          name={name}
          value={value}
          onChange={this.handleChange}
          options={options}
          className="form__form-group-select"
          classNamePrefix="Select"
          placeholder={placeholder}
          isDisabled={disabled}
          menuPlacement="auto"
          maxMenuHeight={maxMenuHeight}
        />
      );
    }
}

const renderSelectField = props => (
  <div className="form__form-group-input-wrap" style={props.style}>
    <SelectField
      {...props.input}
      options={props.options}
      disabled={props.disabled}
      maxMenuHeight={props.maxMenuHeight}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    label: PropTypes.string,
  })),
  disabled: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  maxMenuHeight: PropTypes.number,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  disabled: false,
  style: {},
  maxMenuHeight: 200,
};

export default renderSelectField;
