const clientValidate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${props.literals.forms.client_form.name} ${props.literals.forms.required_validate}`;
  }
  if (!values.company_name) {
    errors.company_name = `${props.literals.forms.client_form.company_name} ${props.literals.forms.required_validate}`;
  }
  if (!values.server_address) {
    errors.server_address = `${props.literals.forms.client_form.server_address} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default clientValidate;
