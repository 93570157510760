import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from '../../shared/components/form/TreeSelect';
import renderDateTimePickerField from '../../shared/components/form/DateTimePicker';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error }
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false
};

@inject('rootStore')
class LoginSpreadSearchForm extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    onUpdateRiskAccountTEM: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    props.showLoader();
    super(props);
    this.state = {
      symbol: [],
      taker: [],
      tem: [],
      ext_group: [],
      risk_account: []
    };
  }
  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/report/login_spread_search`)
      .then((response) => {
        this.setState({ symbol: response.data.symbol });
        this.setState({ taker: response.data.taker });
        this.setState({ tem: response.data.tem });
        this.setState({ ext_group: response.data.cover_group });
        this.setState({ risk_account: response.data.risk_account });
        this.props.onUpdateRiskAccountTEM(response.data.risk_account_tem);
        this.props.hideLoader();
      })
      .catch(() => {
        this.props.hideLoader();
      });
  }
  render() {
    const { handleSubmit, theme, literals } = this.props;
    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__quarter form__search">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.ag_table.start_date}
            </span>
            <div className="form__form-group-field">
              <Field name="start_date" component={renderDateTimePickerField} />
              <div className="form__form-group-icon">
                <TimetableIcon />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.ag_table.end_date}
            </span>
            <div className="form__form-group-field">
              <Field name="end_date" component={renderDateTimePickerField} />
              <div className="form__form-group-icon">
                <TimetableIcon />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">
                  {literals.tables.ag_table.search}
                </Button>
              </ButtonToolbar>
            </div>
          </div>
        </div>
        <div className="form__quarter">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.login_spread_table.ext_login}
            </span>
            <div className="form__form-group-field">
              <Field
                name="login"
                component={renderField}
                type="text"
                placeholder="comma separated for multiple values"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.login_spread_table.ext_group}
            </span>
            <div className="form__form-group-field">
              {(this.props.rootStore.clientStore.CC ===
                'AmanaCapitalGroup_bk7obluff063koif5qa0' ||
                this.props.rootStore.clientStore.CC ===
                  'amana_second_cb8kocum04vb99r213ag') && (
                <Field
                  name="group"
                  component={renderTreeSelectField}
                  treeData={this.state.ext_group}
                  treeDefaultExpandedKeys={['Select All']}
                  className={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                  dropdownClassName={
                    theme.className === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                />
              )}
              {this.props.rootStore.clientStore.CC !==
                'AmanaCapitalGroup_bk7obluff063koif5qa0' &&
                this.props.rootStore.clientStore.CC !==
                  'amana_second_cb8kocum04vb99r213ag' && (
                  <Field
                    name="group"
                    component={renderField}
                    type="text"
                    placeholder="comma separated for multiple values"
                  />
                )}
            </div>
          </div>
        </div>
        <div className="form__quarter">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.login_spread_table.symbol}
            </span>
            <div className="form__form-group-field">
              <Field
                name="symbol"
                component={renderTreeSelectField}
                treeData={this.state.symbol}
                treeDefaultExpandedKeys={['Select All']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.node_account}
            </span>
            <div className="form__form-group-field">
              <Field
                name="execution"
                component={renderTreeSelectField}
                treeData={this.state.tem}
                treeDefaultExpandedKeys={['Select All']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
        </div>
        <div className="form__quarter">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.node}
            </span>
            <div className="form__form-group-field">
              <Field
                name="account"
                component={renderTreeSelectField}
                treeData={this.state.taker}
                treeDefaultExpandedKeys={['Select All']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.risk_account}
            </span>
            <div className="form__form-group-field">
              <Field
                name="risk_account"
                component={renderTreeSelectField}
                treeData={this.state.risk_account}
                treeDefaultExpandedKeys={['Select All']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return { literals: state.literals, theme: state.theme };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'login_spread_cover_search_form', // a unique identifier for this form
    destroyOnUnmount: false
  })(LoginSpreadSearchForm)
);
