/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import AutoSuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { autoSuggestEscapedValue } from '../../shared/helper';

class AutoSuggestInput extends PureComponent {
  static propTypes = {
    input: PropTypes.instanceOf(Object).isRequired,
    placeholder: PropTypes.string,
    suggest: PropTypes.string,
    replace: PropTypes.instanceOf(Array),
    onSuggestionSelected: PropTypes.func,
    lpSessions: PropTypes.instanceOf(Array),
    lpSessionType: PropTypes.string,
  }
  static defaultProps = {
    placeholder: '',
    suggest: '',
    replace: [],
    onSuggestionSelected: () => {},
    lpSessions: [],
    lpSessionType: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
    };
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestionValue, method }) => {
    this.props.input.onChange(suggestionValue);
    this.props.onSuggestionSelected(suggestionValue);
    if (method === 'enter') {
      event.preventDefault();
    }
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  getSuggestions=(value) => {
    const escapedValue = autoSuggestEscapedValue(value, this.props.replace);
    if (escapedValue === '') {
      return [];
    }
    let suggestName = escapedValue + this.props.suggest;
    if (this.props.input.name === 'shared_id') {
      suggestName = this.checkDuplicateSharedID(suggestName, suggestName, 0);
    }
    return [{ name: suggestName }];
  }

  getSuggestionValue=suggestion => suggestion.name;

  checkDuplicateSharedID=(originalSuggestName, suggestName, i) => {
    if (this.props.lpSessions && this.props.lpSessions.some(lp => lp.value === suggestName &&
        ((lp.session_type !== 'MD' && lp.session_type !== 'TD') ||
            // eslint-disable-next-line eqeqeq
            (this.props.lpSessionType == '1') ||
            // eslint-disable-next-line eqeqeq
            (lp.session_type === 'MD' && this.props.lpSessionType == '2') ||
            // eslint-disable-next-line eqeqeq
            (lp.session_type === 'TD' && this.props.lpSessionType == '3')))) {
      i += 1;
      suggestName = `${originalSuggestName}_${i}`;
      return this.checkDuplicateSharedID(originalSuggestName, suggestName, i);
    }
    return suggestName;
  }

  renderSuggestion=suggestion => (
    <span>{suggestion.name}</span>
  )

  render() {
    const { input } = this.props;
    this.props.input.placeholder = this.props.placeholder;
    this.props.input.onKeyDown = this.onKeyDown;
    const { suggestions } = this.state;
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={input}
      />
    );
  }
}

const renderAutoSuggestField = props => (
  <div className="form__form-group-input-wrap">
    <AutoSuggestInput
      disabled={props.disabled}
      placeholder={props.placeholder}
      suggest={props.suggest}
      replace={props.replace}
      input={props.input}
      onSuggestionSelected={props.onSuggestionSelected}
      lpSessions={props.lpSessions}
      lpSessionType={props.lpSessionType}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderAutoSuggestField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  suggest: PropTypes.string,
  replace: PropTypes.instanceOf(Array),
  onSuggestionSelected: PropTypes.func,
  lpSessions: PropTypes.instanceOf(Array),
  lpSessionType: PropTypes.string,
};

renderAutoSuggestField.defaultProps = {
  meta: null,
  disabled: false,
  placeholder: '',
  suggest: '',
  replace: [],
  onSuggestionSelected: () => {},
  lpSessions: [],
  lpSessionType: '',
};

export default renderAutoSuggestField;
