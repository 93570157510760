export const CHANGE_LANGUAGE_TO_EN = 'CHANGE_LANGUAGE_TO_EN';
export const CHANGE_LANGUAGE_TO_CN = 'CHANGE_LANGUAGE_TO_CN';
export const CHANGE_LANGUAGE_TO_JA = 'CHANGE_LANGUAGE_TO_JA';
export const CHANGE_LANGUAGE_TO_HI = 'CHANGE_LANGUAGE_TO_HI';

export function changeLanguageToEN() {
  return {
    type: CHANGE_LANGUAGE_TO_EN,
  };
}

export function changeLanguageToCN() {
  return {
    type: CHANGE_LANGUAGE_TO_CN,
  };
}

export function changeLanguageToJA() {
  return {
    type: CHANGE_LANGUAGE_TO_JA,
  };
}

export function changeLanguageToHI() {
  return {
    type: CHANGE_LANGUAGE_TO_HI,
  };
}
