/* eslint-disable no-return-assign */
/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  ButtonToolbar,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import ReactDOM from 'react-dom';
import { getFormValues } from 'redux-form';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import PositionCloseForm from './positionCloseForm';
import PositionModifySLTPForm from './positionModifySLTPForm';

@inject('rootStore')
class PositionActionRenderer extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    search_values: PropTypes.instanceOf(Object),
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  static defaultProps = {
    search_values: {},
  };
  constructor(props) {
    super(props);
    let notification = null;
    NotificationSystem.newInstance({}, n => (notification = n));
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: (
          <BasicNotification title={title} message={message} color={color} />
        ),
        duration: 3,
        closable: true,
        style: { top: 0 },
        className: 'left-up',
      });
    };
  }
  state = {
    popoverOpenClose: false,
    popoverOpenModifySLTP: false,
  };

  showPopoverClose = () => {
    window.scrollTo(0, document.body.scrollHeight);
    this.setState({
      popoverOpenClose: true,
    });
  };

  hidePopoverClose = () => {
    this.setState({
      popoverOpenClose: false,
    });
  };

  toggleModifySLTP = () => {
    this.setState({
      popoverOpenModifySLTP: !this.state.popoverOpenModifySLTP,
    });
  };

  handleCloseAction = () => {
    const { oneClickTrading } = this.props.rootStore.reportStore.reportState;
    if (oneClickTrading) {
      this.handleSubmitClose();
    } else {
      this.showPopoverClose();
    }
  };

  handleSubmitClose = (values) => {
    this.hidePopoverClose();
    const data = {
      position_id: this.props.node.data.position_id,
      symbol: this.props.node.data.symbol_val,
      risk_account: this.props.node.data.riskaccount,
      node: this.props.search_values.node
        ? this.props.search_values.node.value
        : '',
      account: this.props.search_values.account
        ? this.props.search_values.account.value
        : '',
      feed: this.props.search_values.feed
        ? this.props.search_values.feed.value
        : '',
    };
    const nodeData = this.props.node.data;
    if (values) {
      data.order_type = values.order_type.value;
      data.quantity = values.quantity ? values.quantity.toString() : '0';
      data.price = values.price ? values.price.toString() : '0';
      data.deviation = values.deviation ? values.deviation.toString() : '';
      data.validity = values.validity.value;
      data.bid_price = values.bid_price ? values.bid_price.toString() : '0';
      data.ask_price = values.ask_price ? values.ask_price.toString() : '0';
      data.order_id = parseInt(values.order_id, 10);
      data.client_login = parseInt(values.client_login, 10);
      data.client_group = values.client_group;
    } else {
      data.order_type = 'Market';
      data.quantity = Math.abs(nodeData.net_volume_value).toString();
      data.price = 'Best Available';
      data.deviation = '';
      data.validity = 'IOC';
      data.bid_price = '0';
      data.ask_price = '0';
      data.order_id = null;
      data.client_login = null;
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/trading/close_position`,
        JSON.stringify(data),
      )
      .then((response) => {
        if (response.data.status) {
          this.showNotification(
            this.props.literals.tables.position_table.close_position,
            'primary',
            this.props.literals.forms.success_message,
          );
        } else {
          this.showNotification(
            this.props.literals.tables.position_table.close_position,
            'danger',
            this.props.literals.forms.fail_message,
          );
        }
      })
      .catch((error) => {
        this.showNotification(
          this.props.literals.tables.position_table.close_position,
          'danger',
          error.message,
        );
      });
  };

  handleSubmitModifySLTP = (values) => {
    const data = {
      position_id: this.props.node.data.position_id,
      symbol: this.props.node.data.symbol_val,
      risk_account: this.props.node.data.riskaccount,
      node: this.props.search_values.node
        ? this.props.search_values.node.value
        : '',
      account: this.props.search_values.account
        ? this.props.search_values.account.value
        : '',
    };
    if (values.update_sl_price) {
      data.sl_price = values.sl_price;
    }
    if (values.update_tp_price) {
      data.tp_price = values.tp_price;
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/trading/modify_position_sl_tp`,
        JSON.stringify(data),
      )
      .then((response) => {
        if (response.data.status) {
          this.showNotification(
            this.props.literals.tables.position_table.modify_position_sl_tp,
            'primary',
            this.props.literals.forms.success_message,
          );
        } else {
          this.showNotification(
            this.props.literals.tables.position_table.modify_position_sl_tp,
            'danger',
            this.props.literals.forms.fail_message,
          );
        }
        this.toggleModifySLTP();
      })
      .catch((error) => {
        this.showNotification(
          this.props.literals.tables.position_table.modify_position_sl_tp,
          'danger',
          error.message,
        );
        this.toggleModifySLTP();
      });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    let btnModifySLTPRef = null;
    let btnCloseRef = null;
    return (
      <span>
        <ButtonToolbar>
          <Tooltip
            title={literals.tables.position_table.close_position}
            position="left"
          >
            <Button
              style={{ padding: '4px 4px' }}
              ref={(c) => {
                btnCloseRef = c && ReactDOM.findDOMNode(c);
              }}
              onClick={this.handleCloseAction}
              className="icon"
              color="primary"
            >
              <p className="fas fa-times" />
            </Button>
          </Tooltip>
          <Tooltip
            title={literals.tables.position_table.modify_position_sl_tp}
            position="left"
          >
            <Button
              style={{ padding: '4px 4px' }}
              ref={(c) => {
                btnModifySLTPRef = c && ReactDOM.findDOMNode(c);
              }}
              onClick={this.toggleModifySLTP}
              className="icon"
              color="primary"
            >
              <p className="fas fa-edit" />
            </Button>
          </Tooltip>
        </ButtonToolbar>
        <Popover
          placement="top"
          isOpen={this.state.popoverOpenClose}
          target={() => btnCloseRef}
          className={
            theme === 'theme-light'
              ? `trading-light ${theme}`
              : `trading-dark  ${theme}`
          }
        >
          <PopoverHeader>
            {literals.tables.position_table.close_position}
          </PopoverHeader>
          <PopoverBody>
            <PositionCloseForm
              node={this.props.node}
              hidePopoverClose={this.hidePopoverClose}
              onSubmit={this.handleSubmitClose}
            />
          </PopoverBody>
        </Popover>
        <Popover
          placement="top"
          isOpen={this.state.popoverOpenModifySLTP}
          target={() => btnModifySLTPRef}
          toggle={this.toggleModifySLTP}
          className={
            theme === 'theme-light'
              ? `trading-light ${theme}`
              : `trading-dark  ${theme}`
          }
        >
          <PopoverHeader>
            {literals.tables.position_table.modify_position_sl_tp}
          </PopoverHeader>
          <PopoverBody>
            <PositionModifySLTPForm
              node={this.props.node}
              toggleModifySLTP={this.toggleModifySLTP}
              onSubmit={this.handleSubmitModifySLTP}
            />
          </PopoverBody>
        </Popover>
      </span>
    );
  }
}

export default connect(state => ({
  literals: state.literals,
  theme: state.theme,
  search_values: getFormValues('trading_search_form')(state),
}))(withRouter(PositionActionRenderer));
