import PropTypes from 'prop-types';

const {
  string, shape, object,
} = PropTypes;

export const SidebarProps = shape({
  show: PropTypes.bool,
  collapse: PropTypes.bool,
  collapseCategory: PropTypes.instanceOf(Object),
});

export const ThemeProps = shape({
  className: string,
});

export const LanguageProps = shape({
  languageName: string,
});

export const LiteralProps = shape({
  literals: object,
});

export const MarketWatchProps = shape({
  marketWatchProp: object,
});

export const LiteralENGlobalProps = shape({
  literalsENGlobal: object,
});
