/* eslint-disable  no-nested-ternary */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRouter = ({ component: Component, ...props }) => (
  <Route
    render={() => (
      props.rootStore.authStore.isAuthenticated === true
      ? (props.rootStore.authStore.role === 'client' ? <Redirect to={{
        pathname: '/report/balance_transaction',
        state: { from: props.location },
      }}
      /> : <Redirect to={{
          pathname: '/security/all',
          state: { from: props.location },
        }}
      />) : <Component {...props} />
  )}
  />
);
export default PublicRouter;
