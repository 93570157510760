/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

const parseMultiPattern = (values, pattern, text, tree, securitySymbol) => {
  let result = '*,';
  if (values[pattern] && values[text]) {
    result = values[text].trim() || '*,';
  } else if (!values[pattern] && values[tree]) {
    if (tree === 'symbols_tree') {
      if (values[tree].length > 0) {
        const symbols = [];
        values[tree].forEach((item) => {
          if (item in securitySymbol) {
            symbols.push(...securitySymbol[item]);
          } else {
            symbols.push(item);
          }
        });
        result = symbols.join(',');
      } else {
        result = '*,';
      }
    } else {
      result = values[tree].length > 0 ? values[tree].join(',') : '*,';
    }
  }
  return result;
};

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const data = {
    id: parseInt(values.id, 10),
    takers: parseMultiPattern(values, 'takers_pattern', 'takers_text', 'takers_tree'),
    tems: parseMultiPattern(values, 'tems_pattern', 'tems_text', 'tems_tree'),
    target_taker: values.target_taker.value,
    securities: parseMultiPattern(values, 'securities_pattern', 'securities_text', 'securities_tree'),
    symbols: parseMultiPattern(
      values, 'symbols_pattern', 'symbols_text', 'symbols_tree',
      stateObj.formData.SecuritySymbol,
    ),
    sides: parseInt(values.sides.value, 10),
    source_extlogin: parseInt(values.source_extlogin, 10),
    source_extgroup: values.source_extgroup,
    target_login: parseInt(values.target_login, 10),
    gur_mode: parseInt(values.gur_mode.value, 10),
    makers: parseMultiPattern(values, '', '', 'makers'),
    gur_stl_mode: parseInt(values.gur_stl_mode.value, 10),
    symbol_format: values.symbol_format_prefix.value + values.symbol_format,
    ratio: !values.ratio_custom ? parseFloat(values.ratio.value) : values.ratio_text / 100,
    orig_markup: values.orig_markup ? 1 : 0,
    markup_model: values.markup_model.value,
    priority: parseInt(values.priority.value, 10),
    description: values.description,
    state: values.state ? 1 : 0,
  };
  axios.post(`${process.env.REACT_APP_BACKEND_API}/give_up_rule`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.give_up_rule_form.title_create, 'primary',
          props.literals.forms.success_message,
        );
        stateObj.resetGiveUpRule();
        stateObj.toggle('1');
      } else {
        showNotification(
          props.literals.forms.give_up_rule_form.title_create, 'danger',
          props.literals.forms.fail_message,
        );
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.give_up_rule_form.title_create, 'danger', error.message);
      stateObj.hideLoader();
    });
};
