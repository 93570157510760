/* eslint-disable no-return-assign */
import React from 'react';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import ForgotPasswordForm from './form';
import { BasicNotification } from '../../shared/components/Notification';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 2,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up'
  });
};

const handleSubmit = (values, dispatch, props) => {
  axios
    .post(`${process.env.REACT_APP_FRONTEND_API}/forgot_password`, {
      email: values.email
    })
    .then((response) => {
      if (!response.data.status) {
        showNotification('Forgot Password', 'danger', 'Something went wrong!');
      } else {
        showNotification(
          'Forgot Password',
          'primary',
          'An email has been sent with a password reset link!'
        );
      }
      props.history.push('/');
    })
    .catch(() => {
      showNotification('Forgot Password', 'danger', 'Something went wrong!');
    });
};

const ForgotPassword = (props) => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            <span
              style={{ display: 'inline-block' }}
              className="topbar__logo"
            />
          </h3>
        </div>
        <ForgotPasswordForm {...props} onSubmit={handleSubmit} />
      </div>
    </div>
  </div>
);

export default ForgotPassword;
