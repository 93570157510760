/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import { Field, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import ReactDOM from 'react-dom';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import renderTreeSelectField from './TreeSelect';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';

class SessionEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    colDef: PropTypes.instanceOf(Object).isRequired,
    pattern: PropTypes.bool,
    context: PropTypes.instanceOf(Object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    literals: LiteralProps.isRequired,
    search_values: PropTypes.instanceOf(Object),
  };
  static defaultProps = {
    pattern: false,
    search_values: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
    };
    let notification = null;
    NotificationSystem.newInstance({}, n => notification = n);
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: <BasicNotification
          title={title}
          message={message}
          color={color}
        />,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: 'right-up',
      });
    };
  }

  onGroupsChange=(groups) => {
    if (groups && (groups?.[0] === '*,' || groups?.target?.value === '*,')) {
      const { literals } = this.props;
      this.showNotification(
        `${literals.sidebar.action_scheduler} ${literals.forms.alert}`, 'orange',
        literals.forms.action_scheduler_form.groups_alert_note,
      );
    }
  }

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  showGroupAlert=() => {
    const values = this.props.search_values;
    if (values?.pattern && (values?.text === undefined || values?.text === '*,')) {
      return true;
    } else if (!values?.pattern && (values?.tree === undefined ||
        values?.tree?.[0] === undefined || values?.tree?.[0] === '*,')) {
      return true;
    }
    return false;
  };

  render() {
    const theme = this.props.context.theme.className;
    let treeData = [];
    if (this.props.colDef.field === 'groups') {
      const { type } = this.props.node.data;
      let groups = [];
      if (type === 1) {
        groups = this.props.context.formData.MM;
      } else if (type === 2) {
        groups = this.props.context.formData.HAM;
      } else if (type === 3) {
        groups = this.props.context.formData.TF;
      } else if (type === 4) {
        groups = this.props.context.formData.TEM;
      }
      treeData = groups;
    } else if (this.props.colDef.field === 'securities') {
      treeData = this.props.context.formData.Security;
    } else if (this.props.colDef.field === 'symbols') {
      treeData = this.props.context.formData.Symbol;
    }

    const { literals, handleSubmit } = this.props;
    const { submit } = literals.forms.action_scheduler_form;
    let button;
    let btnSubmitRef = null;
    if (this.props.colDef.field === 'groups' && this.showGroupAlert()) {
      button = (
        <span>
          <Button
            id="PopoverTop"
            color="primary"
            type="button"
            onClick={this.togglePopover}
            ref={(c) => {
                btnSubmitRef = c && ReactDOM.findDOMNode(c);
              }}
          >{submit}
          </Button>
          <Popover
            placement="top"
            isOpen={this.state.popoverOpen}
            target={() => btnSubmitRef}
            toggle={this.togglePopover}
            className={theme === 'theme-light' ? 'trading-light modal-in-modal' : 'trading-dark modal-in-modal'}
          >
            <PopoverHeader>
              {`${literals.forms.confirm} ${literals.sidebar.action_scheduler}`}
            </PopoverHeader>
            <PopoverBody>
              <span>
                {`${literals.forms.action_scheduler_form.groups_alert_note}. \n` +
                `${literals.forms.confirm_create_note} ${literals.sidebar.action_scheduler} ?`}
              </span>
              <ButtonToolbar className="btn-toolbar--center">
                <Button className="btn btn-blue" onClick={this.togglePopover}>
                  {literals.forms.cancel}
                </Button>
                <Button color="primary" onClick={() => { this.togglePopover(); handleSubmit(); }}>
                  {literals.forms.submit}
                </Button>
              </ButtonToolbar>
            </PopoverBody>
          </Popover>
        </span>);
    } else {
      button = <Button color="primary" type="submit">{submit}</Button>;
    }

    return (
      <div>
        <form className="form form--vertical" onSubmit={handleSubmit}>
          <div className="form__form-group">
            {this.props.colDef.field !== 'makers' &&
            <React.Fragment>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="pattern"
                    component={renderCheckBoxField}
                    label={this.props.literals.forms.maker_api_link_form.pattern}
                    defaultChecked={this.props.colDef.field === 'source_extgroup'}
                    disabled={this.props.colDef.field === 'source_extgroup'}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
            </React.Fragment>
            }
            <div className="form__form-group-field">
              {!this.props.pattern &&
              <Field
                name="tree"
                component={renderTreeSelectField}
                treeData={treeData}
                treeDefaultExpandAll={false}
                treeDefaultExpandedKeys={['*,']}
                className={theme === 'theme-light' ?
                  'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                dropdownClassName={theme === 'theme-light' ?
                  'rc-tree-select-dropdown-light' :
                  'rc-tree-select-dropdown-dark'}
                disabled={this.props.colDef.field === 'makers' && this.props.node.data.gur_mode !== 1}
                onChange={this.props.colDef.field === 'groups' ? this.onGroupsChange : () => undefined}
              />
              }
              {this.props.pattern &&
              <Field
                name="text"
                component="textarea"
                type="text"
                placeholder={this.props.colDef.headerName}
                onChange={this.props.colDef.field === 'groups' ? this.onGroupsChange : () => undefined}
              />
              }
            </div>
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              {button}
              <Button color="primary" onClick={this.props.toggleModalSession}>
                {this.props.literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}
const selector = formValueSelector('action_scheduler_table_form');

function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    pattern: selector(state, 'pattern'),
    initialValues: {
      tree: ownProps.value === '*,' ? ['*,'] : ownProps.value.split(','),
      text: ownProps.value,
    },
    search_values: getFormValues('action_scheduler_table_form')(state),
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(reduxForm({
  form: 'action_scheduler_table_form', // a unique identifier for this form
})(SessionEdit));
