import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, clearFields, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import { bindActionCreators } from 'redux';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from '../../shared/components/form/TreeSelect';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

const types = [
  { label: 'All Models', value: 'all_account' },
  { label: 'Risk Account', value: 'risk_account' },
  { label: 'Taker Execution Model', value: 'normal_account' },
];

@inject('rootStore')
class PositionSearchForm extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
      clearFields: PropTypes.func.isRequired,
      change: PropTypes.func.isRequired,
      type: PropTypes.instanceOf(Object),
    };
    static defaultProps = {
      type: {},
    };
    constructor(props) {
      super(props);
      this.state = {
        position_account: [],
        risk_account: [],
        symbol: [],
        account: [],
        position_risk_account: [],
      };
    }
    componentDidMount() {
      if (!Object.keys(this.props.type).length) {
        this.props.change('type', { label: 'Taker Execution Model', value: 'normal_account' });
      }
      axios.get(
        `${process.env.REACT_APP_BACKEND_API}/report/position_search`,
        { headers: { 'x-forward-client': this.props.rootStore.clientStore.CC } },
      )
        .then((response) => {
          this.setState({ position_account: response.data.position_account });
          this.setState({ risk_account: response.data.risk_account });
          this.setState({ symbol: response.data.symbol });
          this.setState({ position_risk_account: response.data.position_risk_account });
          this.setState({ taker: response.data.account });
          this.onChangeTypeDefault(this.props.type);
        })
        .catch(() => {
        });
    }
    onChangeTypeDefault=(selectedOption) => {
      if (selectedOption.value === 'normal_account') {
        this.setState({ account: this.state.position_account });
      } else if (selectedOption.value === 'risk_account') {
        this.setState({ account: this.state.risk_account });
      } else {
        this.setState({ account: this.state.position_risk_account });
      }
    };
    onChangeType=(selectedOption) => {
      this.props.clearFields('position_search_form', false, false, 'account');
      this.onChangeTypeDefault(selectedOption);
    };
    render() {
      const {
        handleSubmit, theme, literals,
      } = this.props;
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.symbol}</span>
              <div className="form__form-group-field">
                <Field
                  name="symbol"
                  component={renderTreeSelectField}
                  treeData={this.state.symbol}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                            'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                            'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.taker}</span>
              <div className="form__form-group-field">
                <Field
                  name="taker"
                  component={renderTreeSelectField}
                  treeData={this.state.taker}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.type}</span>
              <div className="form__form-group-field">
                <Field
                  name="type"
                  component={renderSelectField}
                  type="text"
                  options={types}
                  onChange={this.onChangeType}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.account}</span>
              <div className="form__form-group-field">
                <Field
                  name="account"
                  component={renderTreeSelectField}
                  treeData={this.state.account}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                            'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                            'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.zero_net_view}</span>
              <div className="form__form-group-field">
                <Field
                  name="zero_net_view"
                  component={renderCheckBoxField}
                  label={literals.tables.position_table.zero_net_view}
                />
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">{literals.tables.ag_table.search}</Button>
              </ButtonToolbar>
            </div>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('admin_position_search_form');
function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    type: selector(state, 'type'),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change, clearFields }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'admin_position_search_form', // a unique identifier for this form
})(PositionSearchForm));
