/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { LiteralProps } from '../../prop-types/ReducerProps';

class DepthLevelEdit extends PureComponent {
  static propTypes = {
    depthLevels: PropTypes.string,
    submitDepthLevel: PropTypes.func.isRequired,
    resetDepthLevel: PropTypes.func,
    depthLevelsVal: PropTypes.string,
    change: PropTypes.func.isRequired,
    headerName: PropTypes.string,
    literals: LiteralProps.isRequired,
  };
  static defaultProps = {
    depthLevels: '',
    resetDepthLevel: () => {},
    depthLevelsVal: '',
    headerName: '',
  };
  componentDidMount() {
    this.props.change('depthLevelsVal', this.props.depthLevels);
  }

  submitDepthLevel=() => {
    this.props.submitDepthLevel(this.props.depthLevelsVal);
  };
  resetDepthLevel=() => {
    this.props.resetDepthLevel();
  };

  render() {
    return (
      <div>
        <form className="form form--vertical">
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{this.props.headerName}
            </span>
            <div className="form__form-group-field">
              <Field
                name="depthLevelsVal"
                component="textarea"
                type="text"
                placeholder={this.props.headerName}
              />
            </div>
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" onClick={this.submitDepthLevel}>{this.props.literals.forms.submit}</Button>
              <Button color="primary" onClick={this.resetDepthLevel}>{this.props.literals.forms.cancel}</Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}
const selector = formValueSelector('depth_level_edit_form');

function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    depthLevelsVal: selector(state, 'depthLevelsVal'),
    initialValues: {
      depthLevelsVal: ownProps.depthLevels,
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'depth_level_edit_form', // a unique identifier for this form
})(DepthLevelEdit));
