const makerScalingSymbolValidate = (values, props) => {
  const errors = {};
  if (!values.unisymbol) {
    errors.unisymbol = `${props.literals.forms.maker_scaling_symbol_form.unisymbol} ${props.literals.forms.required_validate}`;
  }
  if (!values.shared_id) {
    errors.shared_id = `${props.literals.forms.maker_scaling_symbol_form.shared_id} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default makerScalingSymbolValidate;
