import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class SelectField extends PureComponent {
    static propTypes = {
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        ]),
        label: PropTypes.string,
      })),
      disabled: PropTypes.bool,
    };

    static defaultProps = {
      placeholder: '',
      options: [],
      disabled: false,
    };

    constructor(props) {
      super(props);
      this.state = {
        value: null,
      };
    }

    handleChange = (selectedOption) => {
      this.props.onChange(selectedOption.value);
      this.setState({ value: selectedOption });
    };

    render() {
      const {
        name, placeholder, options, disabled,
      } = this.props;
      const colourStyles = {
        control: styles => ({
          ...styles, minHeight: 22, height: 22, borderRadius: 0,
        }),
        indicatorsContainer: styles => ({ ...styles, height: 22 }),
        valueContainer: styles => ({ ...styles, height: 22, position: 'unset' }),
      };
      return (
        <Select
          styles={colourStyles}
          name={name}
          value={this.state.value}
          onChange={this.handleChange}
          options={options}
          className="form__form-group-select"
          classNamePrefix="Select"
          placeholder={placeholder}
          isDisabled={disabled}
        />
      );
    }
}

const renderSelectField = props => (
  <div className="form__form-group-input-wrap">
    <SelectField
      {...props.input}
      options={props.options}
      disabled={props.disabled}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    label: PropTypes.string,
  })),
  disabled: PropTypes.bool,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  disabled: false,
};

export default renderSelectField;
