/* eslint-disable no-param-reassign */
/* eslint-disable  consistent-return */
/* eslint-disable no-shadow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import moment from 'moment';
import FileDownload from 'js-file-download';
import * as csv from 'csvtojson';
import { parseAsync } from 'json2csv';
import _ from 'lodash';
import FitToPageIcon from 'mdi-react/FitToPageIcon';
import ResizeIcon from 'mdi-react/ResizeIcon';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import TakerNotionalSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import Loader from '../../shared/components/Loader';
import {
  numberFormatter,
  onColumnResized,
  serverSideDatasourceGetRows
} from '../../shared/helper';

const PAGE_SIZE = 50;

class Order extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired
  };
  constructor(props) {
    super(props);
    const tableID = 'sidebar_order';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    const { literals } = props;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.order_table.client_ord_id,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.client_ord_id'),
          field: 'client_ord_id',
          headerTooltip: literals.tables.order_table.client_ord_id,
          type: 'text'
        },
        {
          headerName: literals.tables.order_table.cen_ord_id,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.cen_ord_id'),
          field: 'cen_ord_id',
          headerTooltip: literals.tables.order_table.cen_ord_id,
          type: 'text'
        },
        {
          headerName: literals.tables.order_table.ext_login,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ext_login'),
          field: 'ext_login',
          headerTooltip: literals.tables.order_table.ext_login,
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.ext_group,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ext_group'),
          field: 'ext_group',
          headerTooltip: literals.tables.order_table.ext_group,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.ext_order,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ext_order'),
          field: 'ext_order',
          headerTooltip: literals.tables.order_table.ext_order,
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.recv_time_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.recv_time_value'),
          field: 'recv_time_value',
          headerTooltip: literals.tables.order_table.recv_time_value,
          suppressMenu: true
        },
        {
          headerName: literals.tables.order_table.node,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.node'),
          field: 'node',
          headerTooltip: literals.tables.order_table.node,
          type: 'text'
        },
        {
          headerName: literals.tables.order_table.node_account,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.node_account'),
          field: 'node_account',
          headerTooltip: literals.tables.order_table.node_account,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.symbol,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.symbol'),
          field: 'symbol',
          headerTooltip: literals.tables.order_table.symbol,
          type: 'text'
        },
        {
          headerName: literals.tables.order_table.party_symbol,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.party_symbol'),
          field: 'party_symbol',
          headerTooltip: literals.tables.order_table.party_symbol,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.symbol_group,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.symbol_group'),
          field: 'symbol_group',
          headerTooltip: literals.tables.order_table.symbol_group,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.side_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.side_value'),
          field: 'side_value',
          headerTooltip: literals.tables.order_table.side_value,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: []
          }
        },
        {
          headerName: literals.tables.order_table.ord_type_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ord_type_value'),
          field: 'ord_type_value',
          headerTooltip: literals.tables.order_table.ord_type_value,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: []
          },
          hide: true
        },
        {
          headerName: literals.tables.order_table.time_in_force_value,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.order_table.time_in_force_value'
            ),
          field: 'time_in_force_value',
          headerTooltip: literals.tables.order_table.time_in_force_value,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: []
          },
          hide: true
        },
        {
          headerName: literals.tables.order_table.volume_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.volume_value'),
          field: 'volume_value',
          headerTooltip: literals.tables.order_table.volume_value,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.order_table.price,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.price'),
          field: 'price',
          headerTooltip: literals.tables.order_table.price,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.order_table.fill_volume_value,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.order_table.fill_volume_value'
            ),
          field: 'fill_volume_value',
          headerTooltip: literals.tables.order_table.fill_volume_value,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.order_table.notional,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.notional'),
          field: 'notional',
          headerTooltip: literals.tables.order_table.notional,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.order_table.avg_price,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.avg_price'),
          field: 'avg_price',
          headerTooltip: literals.tables.order_table.avg_price,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.order_table.state,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.state'),
          field: 'state',
          headerTooltip: literals.tables.order_table.state,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: []
          }
        },
        {
          headerName: literals.tables.order_table.res_state_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.res_state_value'),
          field: 'res_state_value',
          headerTooltip: literals.tables.order_table.res_state_value,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: []
          },
          hide: true
        },
        {
          headerName: literals.tables.order_table.reason_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.reason_value'),
          field: 'reason_value',
          headerTooltip: literals.tables.order_table.reason_value,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: []
          },
          hide: true
        },
        {
          headerName: literals.tables.order_table.res_text_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.res_text_value'),
          field: 'res_text_value',
          headerTooltip: literals.tables.order_table.res_text_value,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.exec_time,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.exec_time'),
          field: 'exec_time',
          headerTooltip: literals.tables.order_table.exec_time,
          type: 'number',
          hide: true,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          }
        },
        {
          headerName: literals.tables.order_table.total_markup,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.total_markup'),
          field: 'total_markup',
          headerTooltip: literals.tables.order_table.total_markup,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.bid,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.bid'),
          field: 'bid',
          headerTooltip: literals.tables.order_table.bid,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.ask,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ask'),
          field: 'ask',
          headerTooltip: literals.tables.order_table.ask,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.base_conv_rate,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.base_conv_rate'),
          field: 'base_conv_rate',
          headerTooltip: literals.tables.order_table.base_conv_rate,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.quote_conv_rate,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.quote_conv_rate'),
          field: 'quote_conv_rate',
          headerTooltip: literals.tables.order_table.quote_conv_rate,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.bperc,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.bperc'),
          field: 'bperc',
          headerTooltip: literals.tables.order_table.bperc,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.volume_abook_value,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.order_table.volume_abook_value'
            ),
          field: 'volume_abook_value',
          headerTooltip: literals.tables.order_table.volume_abook_value,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.volume_bbook_value,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.order_table.volume_bbook_value'
            ),
          field: 'volume_bbook_value',
          headerTooltip: literals.tables.order_table.volume_bbook_value,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.afill_volume_value,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.order_table.afill_volume_value'
            ),
          field: 'afill_volume_value',
          headerTooltip: literals.tables.order_table.afill_volume_value,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.bfill_volume_value,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.order_table.bfill_volume_value'
            ),
          field: 'bfill_volume_value',
          headerTooltip: literals.tables.order_table.bfill_volume_value,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.party_send_time_mcs_value,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.order_table.party_send_time_mcs_value'
            ),
          field: 'party_send_time_mcs_value',
          headerTooltip: literals.tables.order_table.party_send_time_mcs_value,
          suppressMenu: true,
          hide: true
        },
        {
          headerName: literals.tables.order_table.ttl,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ttl'),
          field: 'ttl',
          headerTooltip: literals.tables.order_table.ttl,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.gain_perc,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.gain_perc'),
          field: 'gain_perc',
          headerTooltip: literals.tables.order_table.gain_perc,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.ext_dealid,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ext_dealid'),
          field: 'ext_dealid',
          headerTooltip: literals.tables.order_table.ext_dealid,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.ext_posid,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ext_posid'),
          field: 'ext_posid',
          headerTooltip: literals.tables.order_table.ext_posid,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.ext_bid,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ext_bid'),
          field: 'ext_bid',
          headerTooltip: literals.tables.order_table.ext_bid,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.ext_ask,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ext_ask'),
          field: 'ext_ask',
          headerTooltip: literals.tables.order_table.ext_ask,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.contract_size,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.contract_size'),
          field: 'contract_size',
          headerTooltip: literals.tables.order_table.contract_size,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.ext_markup,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.ext_markup'),
          field: 'ext_markup',
          headerTooltip: literals.tables.order_table.ext_markup,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.taker_account_currency,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.order_table.taker_account_currency'
            ),
          field: 'taker_account_currency',
          headerTooltip: literals.tables.order_table.taker_account_currency,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.rej_volume_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.rej_volume_value'),
          field: 'rej_volume_value',
          headerTooltip: literals.tables.order_table.rej_volume_value,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.fix_session,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.fix_session'),
          field: 'fix_session',
          headerTooltip: literals.tables.order_table.fix_session,
          type: 'text',
          hide: true
        },
        {
          headerName: literals.tables.order_table.sent_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.sent_value'),
          field: 'sent_value',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: []
          },
          hide: true
        },
        {
          headerName: literals.tables.order_table.conc_a_vol_value,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.conc_a_vol_value'),
          field: 'conc_a_vol_value',
          headerTooltip: literals.tables.order_table.conc_a_vol_value,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          hide: true
        },
        {
          headerName: literals.tables.order_table.comment,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.order_table.comment'),
          field: 'comment',
          headerTooltip: literals.tables.order_table.comment,
          type: 'text',
          hide: true
        }
      ],
      collapse: '',
      defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: ['filterMenuTab'],
        suppressMovable: true
      },
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter'
        }
      },
      rowModelType: 'serverSide',
      paginationPageSize: PAGE_SIZE,
      cacheBlockSize: PAGE_SIZE,
      loaderShow: false,
      resize: 'fit',
      rowData: [],
      rowCount: 0,
      pinnedBottomRowData: [],
      riskAccountTem: {},
      gridColumns: [],
      gridDisplayedColumns: [],
      dataAfterFilter: []
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (
        literals.forms.security_form.name !==
        prevProps.literals.forms.security_form.name
      ) {
        this.gridApi.refreshHeader();
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const columns = this.gridColumnApi.getAllColumns().map((col) => ({
      title: col.colDef.headerName,
      value: col.colDef.field
    }));
    const displayedColumns = this.gridColumnApi
      .getAllDisplayedColumns()
      .map((col) => col.colDef.field);
    this.setState({ gridColumns: columns });
    this.setState({ gridDisplayedColumns: displayedColumns });
    this.gridApi.refreshHeader();
  }

  onColumnResized = (params) => {
    onColumnResized(params, this.myGridID);
  };

  onGridSizeChanged = () => {
    if (this.state.resize === 'fit') {
      this.sizeToFit();
    } else {
      this.autoSizeAll();
    }
  };

  onBtExport = (type) => {
    this.showLoader();
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
    const headers = [];
    this.gridColumnApi.getAllDisplayedColumns().forEach((col) => {
      headers.push({
        label: col.colDef.headerName,
        value: col.colDef.field
      });
    });
    let fileName = 'Orders';
    parseAsync(this.state.dataAfterFilter, { fields: headers })
      .then((csv) => {
        const formData = new FormData();
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        formData.append('file', blob);
        formData.append('export_type', type);
        formData.append('report_name', fileName);
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API}/report/export_report`,
            formData,
            {
              headers: {
                'content-type': 'multipart/form-data'
              },
              responseType: 'blob'
            }
          )
          .then((response) => {
            if (type === 1) {
              fileName = `${fileName}.xlsx`;
            } else {
              fileName = `${fileName}.csv`;
            }
            FileDownload(response.data, fileName);
            this.hideLoader();
          });
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  setGridFilterValues = (response) => {
    this.gridApi
      .getFilterInstance('side_value')
      .setFilterValues(response.data.side.map((a) => a.label) || []);
    this.gridApi
      .getFilterInstance('ord_type_value')
      .setFilterValues(response.data.ord_type.map((a) => a.label) || []);
    this.gridApi
      .getFilterInstance('time_in_force_value')
      .setFilterValues(response.data.time_in_force.map((a) => a.label) || []);
    this.gridApi
      .getFilterInstance('state')
      .setFilterValues(response.data.state.map((a) => a.label) || []);
    this.gridApi
      .getFilterInstance('res_state_value')
      .setFilterValues(response.data.res_state.map((a) => a.label) || []);
    this.gridApi
      .getFilterInstance('reason_value')
      .setFilterValues(response.data.reason.map((a) => a.label) || []);
    this.gridApi
      .getFilterInstance('sent_value')
      .setFilterValues(response.data.sent.map((a) => a.label) || []);
  };

  handleSubmit = (values) => {
    this.showLoader();
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
    this.gridColumnApi.setColumnsVisible(
      this.gridColumnApi.getAllDisplayedColumns(),
      false
    );
    this.gridColumnApi.setColumnsVisible(values.grid_columns, true);
    const dataToSend = this.filtersToSend(values);
    dataToSend.order_report = true;
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/report/order`,
        JSON.stringify(dataToSend)
      )
      .then((response) => {
        csv({ checkType: true })
          .fromString(response.data)
          .then((jsonObj) => {
            this.setState({ rowData: jsonObj });
            this.gridApi.setServerSideDatasource(this.ServerSideDatasource());
          })
          .on('done', () => {
            this.hideLoader();
          });
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  filtersToSend = (values) => {
    const tem = [];
    if (values.risk_account && values.risk_account.length > 0) {
      const raTEM = this.state.riskAccountTem;
      values.risk_account.forEach((ra) => {
        if (ra in raTEM) {
          tem.push(...raTEM[ra]);
        }
      });
    }
    const dataToSend = {
      start_date: values.start_date
        ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
        : values.start_date,
      end_date: values.end_date
        ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
        : values.end_date,
      symbol: values.symbol,
      account: values.account,
      execution: [...(values.execution || []), ...tem],
      login: values.login
        ? values.login.split(',').map((item) => item.trim())
        : undefined,
      group: values.group
        ? values.group.split(',').map((item) => item.trim())
        : undefined,
      order: values.order
        ? values.order.split(',').map((item) => item.trim())
        : undefined,
      cen_ord_id: values.cen_ord_id
        ? values.cen_ord_id.split(',').map((item) => item.trim())
        : undefined,
      displayed_columns: this.gridColumnApi
        .getAllDisplayedColumns()
        .map((col) => col.colDef.field)
    };
    return dataToSend;
  };

  ServerSideDatasource = () => ({
    getRows: (params) => {
      const response = serverSideDatasourceGetRows(params, this.state.rowData);
      const { allRows, success, rows, lastRow } = response;
      this.setState({ dataAfterFilter: allRows });
      let i = allRows.length;
      let totNotional = 0;
      // eslint-disable-next-line no-plusplus
      while (i--) {
        totNotional += allRows[i].notional;
      }
      const rowTotal = {
        notional: Math.round((totNotional + Number.EPSILON) * 100) / 100
      };
      this.setState({ pinnedBottomRowData: [rowTotal] });
      this.setState({ rowCount: allRows.length });
      if (success) {
        // supply rows for requested block to grid
        params.successCallback(rows, lastRow);
      } else {
        params.failCallback();
      }
    }
  });

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  sizeToFit = () => {
    this.setState({ resize: 'fit' });
    this.gridApi.sizeColumnsToFit();
  };

  autoSizeAll = () => {
    this.setState({ resize: 'auto' });
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
  };

  updateRiskAccountTEM = (riskAccountTem) => {
    this.setState({ riskAccountTem });
  };

  localizeHeader = (header) => _.get(this.props, header);

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <Collapse
              title={literals.tables.ag_table.toggle_search}
              className="with-shadow"
              collapse={this.state.collapse}
            >
              <TakerNotionalSearchForm
                onSubmit={this.handleSubmit}
                showLoader={this.showLoader}
                hideLoader={this.hideLoader}
                onUpdateRiskAccountTEM={this.updateRiskAccountTEM}
                gridColumns={this.state.gridColumns}
                gridDisplayedColumns={this.state.gridDisplayedColumns}
                setGridFilterValues={this.setGridFilterValues}
                onBtExport={this.onBtExport}
              />
            </Collapse>
            <div className="outer-grid-label">
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.order}</h5>
              </div>{' '}
              {literals.tables.ag_table.total_records}: {this.state.rowCount}
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip
                  arrow
                  arrowSize="small"
                  size="small"
                  theme="light"
                  title={literals.tables.ag_table.size_to_fit}
                >
                  <button
                    className="topbar__btn btnIcon"
                    onClick={() => this.sizeToFit()}
                  >
                    <FitToPageIcon className="topbar__icon" />
                  </button>
                </Tooltip>
                <Tooltip
                  arrow
                  arrowSize="small"
                  size="small"
                  theme="light"
                  title={literals.tables.ag_table.auto_size_all}
                >
                  <button
                    className="topbar__btn btnIcon"
                    onClick={() => this.autoSizeAll()}
                  >
                    <ResizeIcon className="topbar__icon" />
                  </button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div
              id={this.gridWrapperID}
              style={{ width: '100%', height: '100%', clear: 'both' }}
            >
              <div
                id={this.myGridID}
                style={{
                  boxSizing: 'border-box',
                  height: '85vh',
                  width: '100%'
                }}
                className={
                  theme === 'theme-light'
                    ? 'ag-theme-balham'
                    : 'ag-theme-balham-dark'
                }
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.columnDefs}
                  suppressCellSelection
                  suppressContextMenu
                  defaultColDef={this.state.defaultColDef}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.onGridSizeChanged}
                  rowHeight={20}
                  columnTypes={this.state.columnTypes}
                  rowModelType={this.state.rowModelType}
                  pagination
                  paginationPageSize={this.state.paginationPageSize}
                  cacheBlockSize={this.state.cacheBlockSize}
                  pinnedBottomRowData={this.state.pinnedBottomRowData}
                  onColumnResized={this.onColumnResized}
                  debounceVerticalScrollbar
                  groupHeaderHeight={25}
                  serverSideStoreType="partial"
                />
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(Order);
