const actionSchedulerValidate = (values, props) => {
  const errors = {};
  if (!values.type) {
    errors.type =
        `${props.literals.forms.action_scheduler_form.type} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default actionSchedulerValidate;
