/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LanguageProps } from '../../../shared/prop-types/ReducerProps';

const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const cn = `${process.env.PUBLIC_URL}/img/language/cn.png`;
const ja = `${process.env.PUBLIC_URL}/img/language/ja.png`;
const hi = `${process.env.PUBLIC_URL}/img/language/hi.png`;

const GbLng = () => (
  <span className="topbar__language-btn-title">
    <img src={gb} alt="gb" />
    <span>EN</span>
  </span>
);

const CnLng = () => (
  <span className="topbar__language-btn-title">
    <img src={cn} alt="cn" />
    <span>CN</span>
  </span>
);

const JaLng = () => (
  <span className="topbar__language-btn-title">
    <img src={ja} alt="ja" />
    <span>JA</span>
  </span>
);

const HiLng = () => (
  <span className="topbar__language-btn-title">
    <img src={hi} alt="hi" />
    <span>HI</span>
  </span>
);

class TopbarLanguage extends PureComponent {
  static propTypes = {
    changeLanguageToEN: PropTypes.func.isRequired,
    changeLanguageToCN: PropTypes.func.isRequired,
    changeLanguageToJA: PropTypes.func.isRequired,
    changeLanguageToHI: PropTypes.func.isRequired,
    loadLiteralsEN: PropTypes.func.isRequired,
    loadLiteralsCN: PropTypes.func.isRequired,
    loadLiteralsJA: PropTypes.func.isRequired,
    loadLiteralsHI: PropTypes.func.isRequired,
    language: LanguageProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      mainButtonContent: this.props.language.languageName === 'en' ? <GbLng /> : <CnLng />,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.language.languageName !== prevProps.language.languageName) {
      switch (this.props.language.languageName) {
        case 'en':
          this.props.loadLiteralsEN();
          this.setState({ mainButtonContent: <GbLng /> });
          break;
        case 'cn':
          this.props.loadLiteralsCN();
          this.setState({ mainButtonContent: <CnLng /> });
          break;
        case 'ja':
          this.props.loadLiteralsJA();
          this.setState({ mainButtonContent: <JaLng /> });
          break;
        case 'hi':
          this.props.loadLiteralsHI();
          this.setState({ mainButtonContent: <HiLng /> });
          break;
        default:
          this.props.loadLiteralsEN();
          this.setState({ mainButtonContent: <GbLng /> });
          break;
      }
    }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  changeLanguage = (lng) => {
    this.setState({ collapse: false });
    switch (lng) {
      case 'en':
        this.props.changeLanguageToEN();
        this.props.loadLiteralsEN();
        break;
      case 'cn':
        this.props.changeLanguageToCN();
        this.props.loadLiteralsCN();
        break;
      case 'ja':
        this.props.changeLanguageToJA();
        this.props.loadLiteralsJA();
        break;
      case 'hi':
        this.props.changeLanguageToHI();
        this.props.loadLiteralsHI();
        break;
      default:
        this.props.changeLanguageToEN();
        this.props.loadLiteralsEN();
        break;
    }
  };

  render() {
    return (
      <div className="topbar__collapse topbar__collapse--language">
        <button className="topbar__btn" onClick={this.toggle}>
          {this.state.mainButtonContent}
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse
          isOpen={this.state.collapse}
          className="topbar__collapse-content topbar__collapse-content--language"
        >
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('en')}
          >
            <GbLng />
          </button>
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('cn')}
          >
            <CnLng />
          </button>
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('ja')}
            style={{ display: 'none' }}
          >
            <JaLng />
          </button>
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('hi')}
          >
            <HiLng />
          </button>
        </Collapse>
      </div>
    );
  }
}

export default connect(state => ({
  language: state.language,
}))(TopbarLanguage);
