const userValidate = (values, props) => {
  const errors = {};
  const spaceRegExp = /^\S*$/;
  if (!values.username) {
    errors.username = `${props.literals.forms.user_form.username} ${props.literals.forms.required_validate}`;
  }
  if (!spaceRegExp.test(values.username)) {
    errors.username = `${props.literals.forms.user_form.username} ${props.literals.forms.no_space_validate}`;
  }
  if (!values.password) {
    errors.password = `${props.literals.forms.user_form.password} ${props.literals.forms.required_validate}`;
  }
  if (!values.confirm_password) {
    errors.confirm_password =
        `${props.literals.forms.user_form.confirm_password} ${props.literals.forms.required_validate}`;
  }
  if (!values.first_name) {
    errors.first_name = `${props.literals.forms.user_form.first_name} ${props.literals.forms.required_validate}`;
  }
  if (!values.last_name) {
    errors.last_name = `${props.literals.forms.user_form.last_name} ${props.literals.forms.required_validate}`;
  }
  if (!values.client) {
    errors.client = `${props.literals.forms.user_form.client} ${props.literals.forms.required_validate}`;
  }
  if (!values.role) {
    errors.role = `${props.literals.forms.user_form.role} ${props.literals.forms.required_validate}`;
  }
  if (!values.email) {
    errors.email = `${props.literals.forms.user_form.email} ${props.literals.forms.required_validate}`;
  }
  if (values.password && values.confirm_password) {
    if (values.password !== values.confirm_password) {
      errors.confirm_password = props.literals.forms.user_form.passwords_equal_validate;
    }
  }
  return errors;
};

export default userValidate;
