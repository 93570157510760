/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import {
  LiteralENGlobalProps,
  LiteralProps
} from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import {
  regExSignedDecimalNumber,
  shallowEqual,
  validateSessions,
  parseSessions
} from '../../shared/helper';
import UploadData from '../../shared/components/UploadData';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};
class NodeAccountSymbolUpload extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
    resetNodeAccount: PropTypes.func.isRequired,
    rowOldData: PropTypes.instanceOf(Object).isRequired
  };

  getHeaderName = (header) => {
    const { literalsENGlobal } = this.props;
    switch (header) {
      case literalsENGlobal.forms.node_account_symbol_form.symbol:
        return { col: 'symbol_val', type: 'text' };
      case literalsENGlobal.forms.node_account_symbol_form.node:
        return { col: 'node', type: 'text' };
      case literalsENGlobal.forms.node_account_symbol_form.node_account:
        return { col: 'node_account', type: 'text' };
      case literalsENGlobal.forms.node_account_symbol_form.aggregate_group:
        return { col: 'aggregate_group', type: 'text' };
      case literalsENGlobal.forms.node_account_symbol_form.markup_group:
        return { col: 'markup_group', type: 'text' };
      case literalsENGlobal.forms.node_account_symbol_form.exec_mode:
        return { col: 'exec_mode_value', type: 'text' };
      case literalsENGlobal.forms.node_account_symbol_form.min_volume:
        return { col: 'min_volume', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.max_volume:
        return { col: 'max_volume', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.ttl:
        return { col: 'ttl', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.a_min:
        return { col: 'a_min', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.a_step:
        return { col: 'a_step', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.b_perc:
        return { col: 'b_perc', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.b_fix:
        return { col: 'b_fix', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.sessions:
        return { col: 'sessions', type: 'text' };
      case literalsENGlobal.forms.node_account_symbol_form.b_boost:
        return { col: 'b_boost', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.b_delay:
        return { col: 'b_delay', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.b_delay_jitter:
        return { col: 'b_delay_jitter', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.gain_perc:
        return { col: 'gain_perc', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.contract_size:
        return { col: 'contract_size', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.ll_variation:
        return { col: 'll_variation', type: 'number' };
      case literalsENGlobal.forms.node_account_symbol_form.ll_action:
        return { col: 'll_action_value', type: 'text' };
      case literalsENGlobal.forms.node_account_symbol_form.state:
        return { col: 'state_value', type: 'text' };

      default:
        return { col: header, type: 'text' };
    }
  };

  postMultiple = (files, allFiles, rawData, showLoader, hideLoader) => {
    try {
      showLoader();
      const bulkData = [];
      const errors = [];
      const arr = this.props.rowOldData.reduce(
        (pV, cV) => [...pV, ...cV.node_account_symbols],
        []
      );
      const rowData = _.keyBy(arr, (o) => o.node_account + o.symbol_val);
      rawData.forEach((values) => {
        const symbolAndNodeAccount = `${_.trim(values.symbol_val)}->${_.trim(
          values.node_account
        )}`;
        const nodeAccount =
          this.props.formData.NodeAccountObj[values.node_account];
        if (!nodeAccount) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error:
              `${this.props.literals.forms.invalid} ` +
              `${this.props.literals.forms.node_account_symbol_form.node_account}`
          });
        }
        const aggregateGroup =
          this.props.formData.AggregateGroupObj[_.trim(values.aggregate_group)];
        if (!aggregateGroup && _.trim(values.aggregate_group).length > 0) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error:
              `${this.props.literals.forms.invalid} ` +
              `${this.props.literals.forms.node_account_symbol_form.aggregate_group}`
          });
        }
        const markupGroup =
          this.props.formData.MarkupGroupObj[_.trim(values.markup_group)];
        if (!markupGroup && _.trim(values.markup_group).length > 0) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error:
              `${this.props.literals.forms.invalid} ` +
              `${this.props.literals.forms.node_account_symbol_form.markup_group}`
          });
        }
        const execModeVal =
          this.props.formData.ExecModeObj[values.exec_mode_value];
        if (!execModeVal) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.exec_mode}`
          });
        }
        const execMode = execModeVal ? execModeVal.value : 0;
        if (!regExSignedDecimalNumber.test(values.min_volume)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.min_volume}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.max_volume)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.max_volume}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.ttl)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.ttl}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.a_min)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.a_min}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.a_step)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.a_step}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.b_perc)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.b_perc}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.b_fix)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.b_fix}`
          });
        }
        if (!validateSessions(values.sessions)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.sessions}`
          });
        } else {
          values.sessions = parseSessions(_.trim(values.sessions));
        }
        if (!regExSignedDecimalNumber.test(values.b_boost)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.b_boost}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.b_delay)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.b_delay}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.b_delay_jitter)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error:
              `${this.props.literals.forms.invalid} ` +
              `${this.props.literals.forms.node_account_symbol_form.b_delay_jitter}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.gain_perc)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.gain_perc}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.contract_size)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error:
              `${this.props.literals.forms.invalid} ` +
              `${this.props.literals.forms.node_account_symbol_form.contract_size}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.ll_variation)) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error:
              `${this.props.literals.forms.invalid} ` +
              `${this.props.literals.forms.node_account_symbol_form.ll_variation}`
          });
        }
        const llActionVal =
          this.props.formData.LLActionObj[values.ll_action_value];
        if (!llActionVal) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.ll_action}`
          });
        }
        const llAction = llActionVal ? llActionVal.value : 0;
        const stateVal = this.props.formData.StateObj[values.state_value];
        if (!stateVal) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.node_account_symbol_form.state}`
          });
        }
        const state = stateVal ? stateVal.value : 0;
        const oldRow = rowData[values.node_account + values.symbol_val];
        if (!oldRow) {
          errors.push({
            symbol: symbolAndNodeAccount,
            error: `${this.props.literals.forms.invalid}`
          });
        } else {
          const newData = {
            symbol_val: _.trim(values.symbol_val),
            security: oldRow.security,
            node: oldRow.node,
            node_account: _.trim(values.node_account),
            aggregate_group: _.trim(values.aggregate_group),
            markup_group: _.trim(values.markup_group),
            exec_mode: parseInt(execMode, 10),
            min_volume: parseFloat(values.min_volume),
            max_volume: parseFloat(values.max_volume),
            ttl: parseInt(values.ttl, 10),
            a_min: parseFloat(values.a_min),
            a_step: parseFloat(values.a_step),
            b_perc: parseInt(values.b_perc, 10),
            b_fix: parseInt(values.b_fix, 10),
            sessions: _.trim(values.sessions),
            b_boost: parseFloat(values.b_boost),
            b_delay: parseInt(values.b_delay, 10),
            b_delay_jitter: parseInt(values.b_delay_jitter, 10),
            gain_perc: parseInt(values.gain_perc, 10),
            contract_size: parseFloat(values.contract_size),
            ll_variation: parseFloat(values.ll_variation),
            ll_action: parseInt(llAction, 10),
            state: parseInt(state, 10)
          };
          const oldData = {
            symbol_val: oldRow.symbol_val,
            security: oldRow.security,
            node: oldRow.node,
            node_account: oldRow.node_account,
            aggregate_group: oldRow.aggregate_group,
            markup_group: oldRow.markup_group,
            exec_mode: parseInt(oldRow.exec_mode, 10),
            min_volume: parseFloat(oldRow.min_volume),
            max_volume: parseFloat(oldRow.max_volume),
            ttl: parseInt(oldRow.ttl, 10),
            a_min: parseFloat(oldRow.a_min),
            a_step: parseFloat(oldRow.a_step),
            b_perc: parseInt(oldRow.b_perc, 10),
            b_fix: parseInt(oldRow.b_fix, 10),
            sessions: oldRow.sessions,
            b_boost: parseFloat(oldRow.b_boost),
            b_delay: parseInt(oldRow.b_delay, 10),
            b_delay_jitter: parseInt(oldRow.b_delay_jitter, 10),
            gain_perc: parseInt(oldRow.gain_perc, 10),
            contract_size: parseFloat(oldRow.contract_size),
            ll_variation: parseFloat(oldRow.ll_variation),
            ll_action: parseInt(oldRow.ll_action, 10),
            state: parseInt(oldRow.state, 10)
          };
          const dataToUpdate = {
            ...newData,
            old_data: {
              ...oldData
            }
          };
          if (!shallowEqual(newData, oldData)) {
            bulkData.push(dataToUpdate);
          }
        }
      });
      if (errors.length > 0) {
        showNotification(
          this.props.literals.sidebar.node_account_symbol,
          'danger',
          JSON.stringify(errors),
          null
        );
        hideLoader();
        return;
      }
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}/node/account/symbol_bulk`,
          JSON.stringify(bulkData)
        )
        .then((response) => {
          showNotification(
            this.props.literals.sidebar.node_account_symbol,
            'primary',
            this.props.literals.forms.completed_message,
            3
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.node_account_symbol,
              'danger',
              `${
                this.props.literals.forms.uni_symbol_form.symbols_failed
              } ${response.data.toString()} `,
              null
            );
          }
          allFiles.forEach((f) => f.remove());
          this.props.resetNodeAccount();
          hideLoader();
        })
        .catch(() => {
          allFiles.forEach((f) => f.remove());
          showNotification(
            this.props.literals.sidebar.node_account_symbol,
            'danger',
            this.props.literals.forms.fail_message,
            3
          );
          this.props.resetNodeAccount();
          hideLoader();
        });
    } catch (e) {
      showNotification(
        this.props.literals.sidebar.node_account_symbol,
        'danger',
        e.message,
        null
      );
      hideLoader();
    }
  };

  render() {
    return (
      <UploadData
        tableID="sidebar_node_account_symbol_import"
        title={this.props.literals.sidebar.node_account_symbol}
        getHeaderName={this.getHeaderName}
        postMultiple={this.postMultiple}
      />
    );
  }
}
export default connect((state) => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal
}))(NodeAccountSymbolUpload);
