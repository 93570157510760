/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import NotificationSystem from 'rc-notification';
import LogInForm from './components/LogInForm';
import { BasicNotification } from '../../shared/components/Notification';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 2,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up'
  });
};

const handleSubmit = (values, dispatch, props) => {
  axios
    .post(`${process.env.REACT_APP_FRONTEND_API}/login`, {
      username: values.username,
      password: values.password
    })
    .then((response) => {
      if (response.data.status) {
        props.rootStore.clientStore.CC = response.data.message.client_code;
        props.rootStore.authStore.isAuthenticated = true;
        props.rootStore.authStore.expire = response.data.expire;
        props.rootStore.authStore.token = response.data.token;
        props.rootStore.authStore.role = response.data.message.role;
        props.rootStore.authStore.user = response.data.message;
        if (props.rootStore.authStore.role === 'client') {
          props.history.push('/report/balance_transaction');
        } else {
          props.history.push('/security/all');
        }
      } else {
        showNotification(
          'Sign In',
          'danger',
          'Something went wrong, check your credentials!'
        );
      }
    })
    .catch(() => {
      showNotification(
        'Sign In',
        'danger',
        'Something went wrong, check your credentials!'
      );
    });
};

const LogIn = inject('rootStore')((props) => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            <span
              style={{ display: 'inline-block' }}
              className="topbar__logo"
            />
          </h3>
        </div>
        <LogInForm {...props} onSubmit={handleSubmit} />
      </div>
    </div>
  </div>
));

export default withRouter(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
