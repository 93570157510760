import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from '../../shared/components/form/TreeSelect';
import renderDateTimePickerField from '../../shared/components/form/DateTimePicker';
import validate from '../../shared/components/form/StartEndDateValidate';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class ChartSymbolNotionalSearchForm extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
    };
    constructor(props) {
      super(props);
      this.state = {
        symbol: [],
      };
    }
    componentDidMount() {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/report/report_search`)
        .then((response) => {
          this.setState({ symbol: response.data.symbol });
        })
        .catch(() => {
        });
    }
    render() {
      const {
        handleSubmit, theme, literals,
      } = this.props;
      return (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__triad form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.ag_table.start_date}</span>
              <div className="form__form-group-field">
                <Field
                  name="start_date"
                  component={renderDateTimePickerField}
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{literals.tables.ag_table.search}</Button>
            </ButtonToolbar>
          </div>
          <div className="form__triad">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.ag_table.end_date}</span>
              <div className="form__form-group-field">
                <Field
                  name="end_date"
                  component={renderDateTimePickerField}
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="form__triad">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.symbol}</span>
              <div className="form__form-group-field">
                <Field
                  name="symbol"
                  component={renderTreeSelectField}
                  treeData={this.state.symbol}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                            'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                            'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
        </form>
      );
    }
}

function mapStateToProps(state) {
  return { literals: state.literals, theme: state.theme };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'chart_symbol_notional_search_form', // a unique identifier for this form
  destroyOnUnmount: false,
  validate,
})(ChartSymbolNotionalSearchForm));
