/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderValidate from './headerValidate';
import { LiteralProps, ThemeProps } from '../../prop-types/ReducerProps';
import renderSelectField from '../../components/form/Select';
import renderMultiSelectField from '../form/MultiSelect';
import renderCheckBoxField from '../form/CheckBox';
import renderTreeSelectField from '../form/TreeSelect';
import renderRadioButtonField from '../form/RadioButton';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class HeaderForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
    };
    state = {
      points: 'Points',
    };
    onChangePoints=(val) => {
      this.setState({ points: val });
    };
    render() {
      const {
        handleSubmit, literals, theme,
      } = this.props;
      const disabled = false;
      const { submit } = literals.forms.uni_symbol_form;
      return (
        <form className="form" onSubmit={handleSubmit}>
          {this.props.state.customName === 'Points' &&
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="radio_points"
                component={renderRadioButtonField}
                label={literals.forms.markup_group_form.points}
                radioValue="Points"
                defaultChecked
                onChange={this.onChangePoints}
              />
              <Field
                name="radio_points"
                component={renderRadioButtonField}
                label={literals.forms.markup_group_form.number}
                radioValue="Number"
                onChange={this.onChangePoints}
              />
            </div>
          </div>
          }
          {this.props.state.customName !== 'Symbol' &&
          <div className="form__form-group">
            <div className="form__form-group-field">
              {this.props.state.cellEditor === 'agLargeTextCellEditor' &&
                <Field
                  name={this.props.state.columnId}
                  component={this.props.state.cellEditor === 'agLargeTextCellEditor' ? 'textarea' :
                        (this.props.state.cellEditor === 'agRichSelectCellEditor' ? renderSelectField :
                            (this.props.state.cellEditor ? (this.props.state.editor === 'TreeSelect' ?
                                renderTreeSelectField : renderMultiSelectField) : renderField))}
                  type="text"
                  placeholder={(this.props.state.customName === 'Points' ?
                        this.state.points : this.props.state.customName) || this.props.state.headerName}
                  disabled={disabled}
                />
                }
              {this.props.state.cellEditor !== 'agLargeTextCellEditor' &&
                <Field
                  name={this.props.state.columnId}
                  component={this.props.state.cellEditor === 'agRichSelectCellEditor' ? renderSelectField :
                            (this.props.state.cellEditor ? (this.props.state.editor === 'TreeSelect' ?
                                renderTreeSelectField : renderMultiSelectField) : renderField)}
                  type="text"
                  placeholder={(this.props.state.customName === 'Points' ?
                        this.state.points : this.props.state.customName) || this.props.state.headerName}
                  disabled={disabled}
                  options={this.props.state.selectValues ?
                        this.props.state.selectValues.map(a => ({ ...a, value: a.label })) : []}
                  treeData={this.props.state.selectValues}
                  treeDefaultExpandAll={this.props.state.treeDefaultExpandAll}
                  treeDefaultExpandedKeys={this.props.state.treeDefaultExpandedKeys}
                  treeDefaultVal={this.props.state.treeDefaultVal}
                  className={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light header-tree' :
                        'rc-tree-select-dropdown-dark header-tree'}
                />
                }
            </div>
          </div>
          }
          {this.props.state.customName === 'Symbol' &&
            <>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="character"
                    component={renderField}
                    type="text"
                    placeholder={literals.forms.lp_session_form.char_base_quote}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="extension"
                    component={renderField}
                    type="text"
                    placeholder={literals.forms.lp_session_form.ext_end}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="uppercase"
                    component={renderCheckBoxField}
                    label={literals.forms.lp_session_form.uppercase}
                    disabled={disabled}
                    defaultChecked
                  />
                </div>
              </div>
            </>
          }
          <ButtonToolbar className="form__button-toolbar form__button-toolbar-remove-top-padding">
            <Button color="primary" type="submit" >{submit}</Button>
            <Button
              color="primary"
              type="button"
              onClick={() => this.props.state.toggle()}
            >{literals.forms.uni_symbol_form.cancel}
            </Button>
          </ButtonToolbar>
        </form>
      );
    }
}
function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    initialValues: {
      uppercase: true,
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'header_form', // a unique identifier for this form
  validate: HeaderValidate,
})(HeaderForm));
