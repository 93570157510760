/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import Loader from '../../shared/components/Loader';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import AccountGroupActionRenderer from './actionRenderer';

// const REDUCE_HEIGHT = 200;
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};
const unLinked = 'DETACHED';

class AccountGroupList extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      formData: PropTypes.instanceOf(Object).isRequired,
      rowOldData: PropTypes.instanceOf(Object).isRequired,
      showLoader: PropTypes.func.isRequired,
      hideLoader: PropTypes.func.isRequired,
      resetAccountGroup: PropTypes.func.isRequired,
      setGridApiAccountGroup: PropTypes.func.isRequired,
      setRiskAccountData: PropTypes.func.isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.stylesToUpdate = {};
      const { literals } = this.props;
      const tableID = 'sidebar_account_group';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.account_group_form.name,
            field: 'name',
            editable: false,
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.account_group_form.asset_class_group,
            field: 'assetclass_group',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: () => ({
              values: this.props.formData.AssetClassGroup ?
                this.props.formData.AssetClassGroup.map(a => a.value) : [],
            }),
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'cellHeaderComponent',
            headerComponentParams: {
              selectValueLiteral: 'AssetClassGroup',
            },
            editable(params) {
              return params.data.name !== unLinked;
            },
          },
          {
            headerName: literals.forms.account_group_form.limit_symbol_group,
            field: 'limit_symbol_group',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: () => ({
              values: this.props.formData.LimitSymbolGroup ?
                this.props.formData.LimitSymbolGroup.map(a => a.value) : [],
            }),
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'cellHeaderComponent',
            headerComponentParams: {
              selectValueLiteral: 'LimitSymbolGroup',
            },
            editable(params) {
              return params.data.name !== unLinked;
            },
          },
          {
            headerName: literals.forms.account_group_form.state,
            field: 'state_value',
            editable: false,
            cellRenderer: 'stateRenderer',
            cellRendererParams(params) {
              return { empty: params.data.name === unLinked };
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.forms.account_group_form.description,
            field: 'description',
            cellEditor: 'agLargeTextCellEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'cellHeaderComponent',
            editable(params) {
              return params.data.name !== unLinked;
            },
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
          },
        ],
        frameworkComponents: {
          actionRenderer: AccountGroupActionRenderer,
          stateRenderer: StateRenderer,
          stateHeaderComponent: StateHeaderComponent,
          cellHeaderComponent: HeaderComponent,
        },
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.name;
        },
        cellEditUpdate: false,
        activeTab: '1',
        // height: window.innerHeight,
        loaderShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.account_group_form.name !== prevProps.literals.forms.account_group_form.name) {
          this.gridColumnApi.getColumn('name').getColDef().headerName = literals.forms.account_group_form.name;
          this.gridColumnApi.getColumn('assetclass_group').getColDef().headerName =
              literals.forms.account_group_form.asset_class_group;
          this.gridColumnApi.getColumn('limit_symbol_group').getColDef().headerName =
              literals.forms.account_group_form.limit_symbol_group;
          this.gridColumnApi.getColumn('state_value').getColDef().headerName
              = literals.forms.account_group_form.state;
          this.gridColumnApi.getColumn('description').getColDef().headerName
              = literals.forms.account_group_form.description;
          this.gridColumnApi.getColumn('actions').getColDef().headerName = literals.tables.ag_table.actions;
          this.gridApi.refreshHeader();
        }
      }
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.onGridSizeChanged(params);
      this.props.setGridApiAccountGroup(params.api);
    }

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
        typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.node.id === unLinked) {
        params.node.setDataValue(params.column.colId, '');
      }
      if (params.oldValue != params.value && params.node.id !== unLinked) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridApi.refreshCells({ rowNodes: [params.node], columns: ['name'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridApi.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'state_value') {
          params.data.state = this.props.formData.StateObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['name', 'assetclass_group', 'limit_symbol_group',
          'state_value', 'description'],
        fileName: 'AccountGroup.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['name', 'assetclass_group', 'limit_symbol_group',
          'state_value', 'description'],
        fileName: 'AccountGroup.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    onFirstDataRendered=() => {
      const selectedRows = this.gridApi.getSelectedRows();
      if (!selectedRows || (selectedRows && selectedRows.length === 0)) {
        this.gridApi.getDisplayedRowAtIndex(0).setSelected(true);
      }
    }

    onSelectionChanged=() => {
      const selectedRows = this.gridApi.getSelectedRows();
      const riskAccounts = [];
      selectedRows.forEach((selectedRow) => {
        if (selectedRow.name === unLinked) {
          riskAccounts.push(...this.props.formData.UnLinkedRiskAccount || []);
        } else {
          riskAccounts.push(...this.props.formData.RiskAccountObj[selectedRow.name] || []);
        }
      });
      this.props.setRiskAccountData(riskAccounts);
    }

    updateAccountGroup=() => {
      this.props.showLoader();
      const bulkData = [];
      const rowData = _.keyBy(this.props.rowOldData, 'name');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.name];
        const dataToUpdate = {
          name: values.name,
          assetclass_group: values.assetclass_group,
          limit_symbol_group: values.limit_symbol_group,
          state: parseInt(values.state, 10),
          description: values.description,
          old_data: {
            name: oldRow.name,
            assetclass_group: oldRow.assetclass_group,
            limit_symbol_group: oldRow.limit_symbol_group,
            state: parseInt(oldRow.state, 10),
            description: oldRow.description,
          },
        };
        bulkData.push(dataToUpdate);
      });
      axios.put(`${process.env.REACT_APP_BACKEND_API}/account/group_bulk`, JSON.stringify(bulkData))
        .then((response) => {
          this.resetLocalAccountGroup();
          this.props.resetAccountGroup();
          showNotification(
            this.props.literals.sidebar.account_group, 'primary',
            this.props.literals.forms.completed_message,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.account_group, 'danger',
              `${this.props.literals.forms.fail_message} - ${response.data.toString()}}`,
            );
          }
        })
        .catch((error) => {
          this.resetLocalAccountGroup();
          this.props.resetAccountGroup();
          showNotification(
            this.props.literals.sidebar.account_group, 'danger',
            `${this.props.literals.forms.fail_message} - ${error.message}}`,
          );
        });
    };

    resetLocalAccountGroup=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({ rowNodes: [node], columns: ['name'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridApi.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="outer-grid-label">
                {literals.sidebar.account_group} ({literals.tables.ag_table.total_records}: {
                this.props.formData.AccountGroupList ? this.props.formData.AccountGroupList.length : 0})
              </div>
              <div className="outer-grid-button">
                <ButtonToolbar>
                  <Tooltip title={literals.tables.ag_table.save}>
                    <Button
                      style={{
                          display: this.state.cellEditUpdate ? '' : 'none',
                        }}
                      onClick={this.updateAccountGroup}
                      className="icon"
                      color="primary"
                    ><p className="fa fa-save" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.undo}>
                    <Button
                      style={{
                          display: this.state.cellEditUpdate ? '' : 'none',
                        }}
                      onClick={() => {
                          this.props.showLoader();
                          this.resetLocalAccountGroup(); this.props.resetAccountGroup();
                        }}
                      className="icon"
                      color="primary"
                    ><p
                      className="fas fa-undo"
                    />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.export_excel}>
                    <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                      <p className="fa fa-file-excel" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.export_csv}>
                    <Button
                      onClick={this.onBtExportCSV}
                      className="icon"
                      color="primary"
                    >
                      <p className="fa fa-file-alt" />
                    </Button>
                  </Tooltip>
                </ButtonToolbar>
              </div>
              <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                <div
                  id={this.myGridID}
                  style={{
                      boxSizing: 'border-box',
                      height: '35vh',
                      width: '100%',
                    }}
                  className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnDefs}
                    stopEditingWhenCellsLoseFocus
                    suppressCellSelection
                    suppressContextMenu
                    immutableData
                    getRowNodeId={this.state.getRowNodeId}
                    defaultColDef={this.state.defaultColDef}
                    onColumnResized={this.onColumnResized}
                    onCellValueChanged={this.onCellValueChanged}
                    rowData={this.props.formData.AccountGroupList}
                    frameworkComponents={this.state.frameworkComponents}
                    onGridReady={this.onGridReady}
                    onGridSizeChanged={this.onGridSizeChanged}
                    rowHeight={20}
                    rowSelection="multiple"
                    onSelectionChanged={this.onSelectionChanged}
                    onFirstDataRendered={this.onFirstDataRendered}
                    context={{
                      formData: this.props.formData,
                      resetAccountGroup: this.props.resetAccountGroup,
                      showLoader: this.props.showLoader,
                      hideLoader: this.props.hideLoader,
                      onCellValueChanged: this.onCellValueChanged,
                    }}
                  />
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(AccountGroupList);
