/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import SessionForm from './SessionForm';
import { BasicNotification } from '../Notification';
import { LiteralProps } from '../../prop-types/ReducerProps';
import { validateSessions, parseSessionToArray } from '../../helper';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  step,
  min,
  max,
  meta: { touched, error }
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      step={step}
      min={min}
      max={max}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false
};

class SessionEdit extends PureComponent {
  static propTypes = {
    change: PropTypes.func.isRequired,
    sessions: PropTypes.string.isRequired,
    initialSessionValue: PropTypes.string.isRequired,
    submitSession: PropTypes.func.isRequired,
    resetSession: PropTypes.func,
    literals: LiteralProps.isRequired
  };
  static defaultProps = {
    resetSession: () => {}
  };

  constructor(props) {
    super(props);
    let notification = null;
    NotificationSystem.newInstance({}, (n) => (notification = n));
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: (
          <BasicNotification title={title} message={message} color={color} />
        ),
        duration: 3,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: 'right-up'
      });
    };
    this.state = {
      isUpdateSession: false,
      updateSessionValue: '',
      sessions: this.props.sessions
    };
  }
  onSessionEdit = (item) => {
    this.setState({ isUpdateSession: true });
    this.setState({ updateSessionValue: item });
    const dayW = item.split(',');
    const times = dayW[1].split('-');
    this.props.change('session_form', 'day_of_the_week', {
      label: dayW[0],
      value: dayW[0]
    });
    this.props.change('session_form', 'from_time', times[0]);
    this.props.change('session_form', 'to_time', times[1]);
  };
  onSessionDelete = (val) => {
    const session = parseSessionToArray(this.state.sessions);
    let str = '';
    const group = session.reduce((r, a) => {
      const day = a.split(',');
      r[day[0]] = [...(r[day[0]] || []), a];
      return r;
    }, {});
    const dayW = val.split(',');
    const dow = group[dayW[0]];
    if (dow.length > 1) {
      session.splice(session.indexOf(val), 1);
    } else {
      const checkIndex = session.indexOf(val);
      if (checkIndex !== -1) {
        session[checkIndex] = `${dayW[0]},00:00-00:00`;
      }
    }
    const group1 = session.reduce((r, a) => {
      const day = a.split(',');
      r[day[0]] = [...(r[day[0]] || []), a];
      return r;
    }, {});
    Object.keys(group1).forEach((masterItem) => {
      let strItem = `${masterItem},`;
      group1[masterItem].sort();
      group1[masterItem].forEach((item) => {
        const day = item.split(',');
        strItem += `${day[1]}-`;
      });
      strItem = strItem.replace(/-$/, '');
      strItem += ';';
      str += strItem;
    });
    this.setState({ sessions: str });
    this.showNotification(
      this.props.literals.forms.uni_symbol_form.session_edit,
      'primary',
      this.props.literals.forms.uni_symbol_form.session_delete_message
    );
  };
  cancelSessionEdit = () => {
    this.setState({ isUpdateSession: false });
    this.setState({ updateSessionValue: '' });
    this.props.change('session_form', 'day_of_the_week', {
      label: '',
      value: ''
    });
    this.props.change('session_form', 'from_time', '');
    this.props.change('session_form', 'to_time', '');
  };
  submitSession = () => {
    if (!validateSessions(this.state.sessions)) {
      this.showNotification(
        this.props.literals.forms.uni_symbol_form.session_edit,
        'danger',
        `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.sessions}`
      );
      return;
    } else {
      this.props.submitSession(this.state.sessions);
    }
  };
  resetSession = () => {
    this.setState({ sessions: this.props.initialSessionValue });
    this.props.resetSession();
  };
  addSession = (values, dispatch, props) => {
    const regHHMM = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (
      !regHHMM.test(values.from_time) ||
      !regHHMM.test(values.to_time) ||
      !values.day_of_the_week
    ) {
      this.showNotification(
        this.props.literals.forms.uni_symbol_form.session_edit,
        'danger',
        this.props.literals.forms.uni_symbol_form.session_input_message
      );
      return;
    }
    if (values.from_time > values.to_time) {
      this.showNotification(
        this.props.literals.forms.uni_symbol_form.session_edit,
        'danger',
        this.props.literals.forms.uni_symbol_form.session_time_message
      );
      return;
    }
    const session = parseSessionToArray(this.state.sessions);
    let str = '';
    const group = session.reduce((r, a) => {
      const day = a.split(',');
      r[day[0]] = [...(r[day[0]] || []), a];
      return r;
    }, {});
    const dow = group[values.day_of_the_week.value];
    let failed = false;
    for (let i = 0; i < dow.length; i += 1) {
      if (props.updateSessionValue === dow[i]) {
        continue;
      }
      const dayW = dow[i].split(',');
      const times = dayW[1].split('-');
      if (dayW[1] === `${values.from_time}-${values.to_time}`) {
        this.showNotification(
          this.props.literals.forms.uni_symbol_form.session_edit,
          'danger',
          this.props.literals.forms.uni_symbol_form.session_exists_message
        );
        failed = true;
        break;
      }
      if (
        (values.from_time >= times[0] &&
          values.from_time <= times[1] &&
          dayW[1] !== '00:00-00:00') ||
        (values.to_time >= times[0] &&
          values.to_time <= times[1] &&
          dayW[1] !== '00:00-00:00')
      ) {
        this.showNotification(
          this.props.literals.forms.uni_symbol_form.session_edit,
          'danger',
          `${this.props.literals.forms.uni_symbol_form.session_overlaps_message} ${dow[i]}`
        );
        failed = true;
        break;
      }
    }
    if (!failed) {
      const newVal = `${values.day_of_the_week.value},${values.from_time}-${values.to_time}`;
      const zeroCheck = `${values.day_of_the_week.value},00:00-00:00`;
      const zeroCheckIndex = session.indexOf(zeroCheck);
      let needPush = true;
      if (zeroCheckIndex !== -1) {
        session[zeroCheckIndex] = newVal;
        needPush = false;
      }
      if (props.isUpdateSession) {
        const updateCheckIndex = session.indexOf(props.updateSessionValue);
        if (updateCheckIndex !== -1) {
          session[updateCheckIndex] = newVal;
          needPush = false;
        }
      }
      if (needPush) {
        session.push(newVal);
      }
      const group1 = session.reduce((r, a) => {
        const day = a.split(',');
        r[day[0]] = [...(r[day[0]] || []), a];
        return r;
      }, {});
      Object.keys(group1).forEach((masterItem) => {
        let strItem = `${masterItem},`;
        group1[masterItem].sort();
        group1[masterItem].forEach((item) => {
          const day = item.split(',');
          strItem += `${day[1]}-`;
        });
        strItem = strItem.replace(/-$/, '');
        strItem += ';';
        str += strItem;
      });
      this.setState({ sessions: str });
      if (props.isUpdateSession) {
        this.showNotification(
          this.props.literals.forms.uni_symbol_form.session_edit,
          'primary',
          this.props.literals.forms.uni_symbol_form.session_update_message
        );
      } else {
        this.showNotification(
          this.props.literals.forms.uni_symbol_form.session_edit,
          'primary',
          this.props.literals.forms.uni_symbol_form.session_create_message
        );
      }
      if (props.isUpdateSession) {
        this.setState({ isUpdateSession: false });
        this.setState({ updateSessionValue: '' });
        this.props.change('session_form', 'day_of_the_week', {
          label: '',
          value: ''
        });
        this.props.change('session_form', 'from_time', '');
        this.props.change('session_form', 'to_time', '');
      }
    }
  };

  render() {
    const session = parseSessionToArray(this.state.sessions);
    let k = 0;
    const depthList = session.map((item) => {
      k += 1;
      return (
        <tr key={k}>
          <td style={{ width: '80%' }}>{item}</td>
          <td style={{ width: '10%' }}>
            <Button
              style={{ height: 14 }}
              id="SessionEditClick"
              onClick={() => this.onSessionEdit(item)}
              className="icon"
              color="primary"
            >
              <p style={{ lineHeight: 0 }} className="fa fa-edit" />
            </Button>
          </td>
          <td style={{ width: '10%' }}>
            <Button
              style={{ height: 14 }}
              id="SessionDeleteClick"
              onClick={() => this.onSessionDelete(item)}
              className="icon"
              color="primary"
            >
              <p style={{ lineHeight: 0 }} className="fa fa-trash-alt" />
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div className="session-edit">
        <SessionForm
          sessions={this.state.sessions}
          onSubmit={this.addSession}
          isUpdateSession={this.state.isUpdateSession}
          updateSessionValue={this.state.updateSessionValue}
          cancelSessionEdit={this.cancelSessionEdit}
        />
        <table className="session-table">
          <tbody>{depthList}</tbody>
        </table>
        <div style={{ overflow: 'hidden' }}>
          <ButtonToolbar className="float-right">
            <Button color="primary" onClick={this.submitSession}>
              {this.props.literals.forms.submit}
            </Button>
            <Button color="primary" onClick={this.resetSession}>
              {this.props.literals.forms.cancel}
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { literals: state.literals };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionEdit);
