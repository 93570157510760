/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import Websocket from 'react-websocket';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';

const exportColumns = ['name', 'enabled_value', 'stime_value', 'logged_in_value',
  'inc_msg', 'out_msg'];

@inject('rootStore')
class MakerStatus extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_maker_status';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.fix_status_table.maker,
          field: 'name',
          headerTooltip: literals.tables.fix_status_table.maker,
          width: 60,
          minWidth: 60,
          sort: 'asc',
        },
        {
          headerName: literals.tables.fix_status_table.enabled,
          field: 'enabled_value',
          headerTooltip: literals.tables.fix_status_table.enabled,
          width: 60,
          minWidth: 60,
          cellRenderer(params) {
            if (params.data.enabled === 0) {
              return `<span class="cell-renderer-red">${params.value}</span>`;
            }
            return `<span class="cell-renderer-green">${params.value}</span>`;
          },
        },
        {
          headerName: literals.tables.fix_status_table.stime,
          field: 'stime_value',
          headerTooltip: literals.tables.fix_status_table.stime,
          width: 60,
          minWidth: 60,
          cellRenderer(params) {
            if (params.data.stime === 0) {
              return `<span class="cell-renderer-red">${params.value}</span>`;
            }
            return `<span class="cell-renderer-green">${params.value}</span>`;
          },
        },
        {
          headerName: literals.tables.fix_status_table.logged_in,
          field: 'logged_in_value',
          headerTooltip: literals.tables.fix_status_table.logged_in,
          width: 60,
          minWidth: 60,
          cellRenderer(params) {
            if (params.data.logged_in === 0) {
              return `<span class="cell-renderer-red">${params.value}</span>`;
            }
            return `<span class="cell-renderer-green">${params.value}</span>`;
          },
        },
        {
          headerName: literals.tables.fix_status_table.inc_msg,
          field: 'inc_msg',
          headerTooltip: literals.tables.fix_status_table.inc_msg,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.fix_status_table.out_msg,
          field: 'out_msg',
          headerTooltip: literals.tables.fix_status_table.out_msg,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
      ],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      getRowNodeId(data) {
        return data.name;
      },
      rowData: [],
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (literals.tables.fix_status_table.maker !== prevProps.literals.tables.fix_status_table.maker) {
        this.gridColumnApi.getColumn('name')
          .getColDef().headerName
          = literals.tables.fix_status_table.maker;
        this.gridColumnApi.getColumn('enabled_value')
          .getColDef().headerName
          = literals.tables.fix_status_table.enabled;
        this.gridColumnApi.getColumn('stime_value')
          .getColDef().headerName
          = literals.tables.fix_status_table.stime;
        this.gridColumnApi.getColumn('logged_in_value')
          .getColDef().headerName
          = literals.tables.fix_status_table.logged_in;
        this.gridColumnApi.getColumn('inc_msg')
          .getColDef().headerName
          = literals.tables.fix_status_table.inc_msg;
        this.gridColumnApi.getColumn('out_msg')
          .getColDef().headerName
          = literals.tables.fix_status_table.out_msg;
        this.gridApi.refreshHeader();
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onGridSizeChanged(params);
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'MakerStatus.xlsx',
      suppressTextAsCDATA: true,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'MakerStatus.csv',
    };
    this.gridApi.exportDataAsCsv(params);
  };

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    if ('maker_status' in data) {
      this.setState({ rowData: data.maker_status });
    }
  };
  socketOpenListener = () => {
    this.sendMessage(JSON.stringify({
      key: 'maker_status',
      value: [],
    }));
  };
  sendMessage=(message) => {
    this.refWebSocket.sendMessage(message);
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="outer-grid-label">
              {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip title={literals.tables.ag_table.export_excel}>
                  <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                    <p className="fa fa-file-excel" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.export_csv}>
                  <Button
                    onClick={this.onBtExportCSV}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-alt" />
                  </Button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div
              id={this.gridWrapperID}
              style={{
                width: '100%', height: '100%', clear: 'both',
              }}
            >
              <div
                id={this.myGridID}
                style={{
                  boxSizing: 'border-box',
                  height: '85vh',
                  width: '100%',
                }}
                className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.columnDefs}
                  immutableData
                  suppressCellSelection
                  suppressContextMenu
                  defaultColDef={this.state.defaultColDef}
                  onColumnResized={this.onColumnResized}
                  getRowNodeId={this.state.getRowNodeId}
                  rowData={this.state.rowData}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.onGridSizeChanged}
                  rowHeight={20}
                  accentedSort
                />
              </div>
            </div>
            <Websocket
              url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
              reconnectIntervalInMilliSeconds={1000}
              onMessage={this.socketMessageListener}
              onOpen={this.socketOpenListener}
              ref={(WS) => {
                this.refWebSocket = WS;
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(MakerStatus);
