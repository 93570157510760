/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import {
  LiteralENGlobalProps,
  LiteralProps
} from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import { regExSignedDecimalNumber, shallowEqual } from '../../shared/helper';
import UploadData from '../../shared/components/UploadData';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};
class MakerSymbolScalingUpload extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
    resetMakerScaling: PropTypes.func.isRequired,
    rowOldData: PropTypes.instanceOf(Object).isRequired
  };

  getHeaderName = (header) => {
    const { literalsENGlobal } = this.props;
    switch (header) {
      case literalsENGlobal.forms.maker_scaling_symbol_form.unisymbol:
        return { col: 'unisymbol', type: 'text' };
      case literalsENGlobal.forms.maker_scaling_symbol_form.shared_id:
        return { col: 'shared_id', type: 'text' };
      case literalsENGlobal.forms.maker_scaling_symbol_form.price_scale_in:
        return { col: 'price_scale_in', type: 'number' };
      case literalsENGlobal.forms.maker_scaling_symbol_form.price_scale_out:
        return { col: 'price_scale_out', type: 'number' };
      case literalsENGlobal.forms.maker_scaling_symbol_form.volume_scale_in:
        return { col: 'volume_scale_in', type: 'number' };
      case literalsENGlobal.forms.maker_scaling_symbol_form.volume_scale_out:
        return { col: 'volume_scale_out', type: 'number' };
      case literalsENGlobal.forms.maker_scaling_symbol_form.description:
        return { col: 'description', type: 'text' };
      case literalsENGlobal.forms.maker_scaling_symbol_form.state:
        return { col: 'state_value', type: 'text' };

      default:
        return { col: header, type: 'text' };
    }
  };

  postMultiple = (files, allFiles, rawData, showLoader, hideLoader) => {
    try {
      showLoader();
      const bulkData = [];
      const errors = [];
      const arr = this.props.rowOldData.reduce(
        (pV, cV) => [...pV, ...cV.maker_scaling_symbols],
        []
      );
      const rowData = _.keyBy(arr, (o) => o.shared_id + o.unisymbol);
      rawData.forEach((values) => {
        const symbolAndMakerSession = `${_.trim(values.unisymbol)}->${_.trim(
          values.shared_id
        )}`;
        const makerSession =
          this.props.formData.MakerSessionObj[values.shared_id];
        if (!makerSession) {
          errors.push({
            symbol: symbolAndMakerSession,
            error:
              `${this.props.literals.forms.invalid} ` +
              `${this.props.literals.forms.maker_scaling_symbol_form.shared_id}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.price_scale_in)) {
          errors.push({
            symbol: symbolAndMakerSession,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.maker_scaling_symbol_form.price_scale_in}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.price_scale_out)) {
          errors.push({
            symbol: symbolAndMakerSession,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.maker_scaling_symbol_form.price_scale_out}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.volume_scale_in)) {
          errors.push({
            symbol: symbolAndMakerSession,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.maker_scaling_symbol_form.volume_scale_in}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.volume_scale_out)) {
          errors.push({
            symbol: symbolAndMakerSession,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.maker_scaling_symbol_form.volume_scale_out}`
          });
        }
        const stateVal = this.props.formData.StateObj[values.state_value];
        if (!stateVal) {
          errors.push({
            symbol: symbolAndMakerSession,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.maker_scaling_symbol_form.state}`
          });
        }
        const state = stateVal ? stateVal.value : 0;

        const newData = {
          unisymbol: _.trim(values.unisymbol),
          shared_id: _.trim(values.shared_id),
          price_scale_in: parseFloat(values.price_scale_in),
          price_scale_out: parseFloat(values.price_scale_out),
          volume_scale_in: parseFloat(values.volume_scale_in),
          volume_scale_out: parseFloat(values.volume_scale_out),
          state: parseInt(state, 10),
          description: _.trim(values.description)
        };

        const oldRow = rowData[values.shared_id + values.unisymbol];
        if (!oldRow) {
          bulkData.push({
            ...newData
          });
        } else {
          const oldData = {
            unisymbol: oldRow.unisymbol,
            shared_id: oldRow.shared_id,
            price_scale_in: parseFloat(oldRow.price_scale_in),
            price_scale_out: parseFloat(oldRow.price_scale_out),
            volume_scale_in: parseFloat(oldRow.volume_scale_in),
            volume_scale_out: parseFloat(oldRow.volume_scale_out),
            state: parseInt(oldRow.state, 10),
            description: oldRow.description
          };
          const dataToUpdate = {
            ...newData,
            old_data: {
              ...oldData
            }
          };
          if (!shallowEqual(newData, oldData)) {
            bulkData.push(dataToUpdate);
          }
        }
      });
      if (errors.length > 0) {
        showNotification(
          this.props.literals.sidebar.maker_scaling_symbol,
          'danger',
          JSON.stringify(errors),
          null
        );
        hideLoader();
        return;
      }
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}/maker/scaling_symbol`,
          JSON.stringify(bulkData)
        )
        .then((response) => {
          showNotification(
            this.props.literals.sidebar.maker_scaling_symbol,
            'primary',
            this.props.literals.forms.completed_message,
            3
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.maker_scaling_symbol,
              'danger',
              `${
                this.props.literals.forms.uni_symbol_form.symbols_failed
              } ${response.data.toString()} `,
              null
            );
          }
          allFiles.forEach((f) => f.remove());
          this.props.resetMakerScaling();
          hideLoader();
        })
        .catch(() => {
          allFiles.forEach((f) => f.remove());
          showNotification(
            this.props.literals.sidebar.maker_scaling_symbol,
            'danger',
            this.props.literals.forms.fail_message,
            3
          );
          this.props.resetMakerScaling();
          hideLoader();
        });
    } catch (e) {
      showNotification(
        this.props.literals.sidebar.maker_scaling_symbol,
        'danger',
        e.message,
        null
      );
      hideLoader();
    }
  };

  render() {
    return (
      <UploadData
        tableID="sidebar_maker_scaling_symbol_import"
        title={this.props.literals.sidebar.maker_scaling_symbol}
        getHeaderName={this.getHeaderName}
        postMultiple={this.postMultiple}
      />
    );
  }
}
export default connect((state) => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal
}))(MakerSymbolScalingUpload);
