/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const data = {
    reason: values.reason,
    restart_type: values.restart_type,
  };
  if (stateObj.role === 'broker') {
    data.restart_type = 'no_logs';
  }
  axios.post(
    `${process.env.REACT_APP_BACKEND_API}/system/aggregator_restart`, JSON.stringify(data),
    { headers: { 'x-forward-client-name': stateObj.client_name } },
  )
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.aggregator_restart_form.title_create, 'primary',
          props.literals.forms.aggregator_restart_form.success_message,
        );
        stateObj.toggle('1');
      } else {
        showNotification(
          props.literals.forms.aggregator_restart_form.title_create, 'danger',
          props.literals.forms.fail_message,
        );
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.aggregator_restart_form.title_create, 'danger', error.message);
    });
};
