const nodeAccountValidate = (values, props) => {
  const errors = {};
  const spaceRegExp = /^\S*$/;
  const atTheRateRegExp = /^[^@]*$/;
  if (!values.name) {
    errors.name = `${props.literals.forms.node_account_form.name} ${props.literals.forms.required_validate}`;
  }
  if (!spaceRegExp.test(values.name)) {
    errors.name = `${props.literals.forms.node_account_form.name} ${props.literals.forms.no_space_validate}`;
  }
  if (!atTheRateRegExp.test(values.name)) {
    errors.name = `${props.literals.forms.node_account_form.name} ${props.literals.forms.no_at_validate}`;
  }
  if (values.name && values.name.length > 32) {
    errors.name = `${props.literals.forms.node_account_form.name} ${props.literals.forms.max_32_validate}`;
  }
  if (!values.node) {
    errors.node = `${props.literals.forms.node_account_form.node} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default nodeAccountValidate;
