/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderInputNumberField from '../../shared/components/form/NumberInputCommon';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

class SessionEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    colDef: PropTypes.instanceOf(Object).isRequired,
    ratio_custom: PropTypes.bool,
    context: PropTypes.instanceOf(Object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    literals: LiteralProps.isRequired,
  };
  static defaultProps = {
    ratio_custom: false,
  };

  componentDidMount() {
    if (this.props.context.formData.RatioObj[this.props.value]) {
      this.props.change('ratio', { label: this.props.value, value: this.props.node.data.ratio });
    } else {
      this.props.change('ratio_custom', true);
      this.props.change('ratio_text', this.props.node.data.ratio * 100);
    }
  }

  render() {
    return (
      <div>
        <form className="form form--vertical" onSubmit={this.props.handleSubmit}>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <span className="marginLeftAuto">
                <Field
                  name="ratio_custom"
                  component={renderCheckBoxField}
                  label={this.props.literals.forms.give_up_rule_form.custom}
                />
              </span>
            </div>
            <span
              className="form__form-group-label"
            />
            <div className="form__form-group-field">
              <span style={{ color: 'orange' }}>
                {this.props.literals.forms.give_up_rule_form.ratio_note}
              </span>
              {!this.props.ratio_custom &&
              <Field
                name="ratio"
                component={renderSelectField}
                type="text"
                options={this.props.context.formData.Ratio}
                placeholder={this.props.colDef.ratio}
              />
              }
              {this.props.ratio_custom &&
              <Field
                name="ratio_text"
                component={renderInputNumberField}
                type="number"
                min={-10000}
                max={10000}
                precision={0}
                step={5}
                placeholder={this.props.colDef.ratio}
              />
              }
            </div>
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" type="submit">{this.props.literals.forms.submit}</Button>
              <Button color="primary" onClick={this.props.toggleModalSession}>
                {this.props.literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}
const selector = formValueSelector('give_up_rule_ratio_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    ratio_custom: selector(state, 'ratio_custom'),
    initialValues: {
      ratio: { label: '100% Volume Ratio', value: 1.0 },
      ratio_text: 100,
    },
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(reduxForm({
  form: 'give_up_rule_ratio_form', // a unique identifier for this form
})(SessionEdit));
