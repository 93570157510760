import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import axios from 'axios';
import moment from 'moment';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import AccessLogForm from './search';
import Collapse from '../../shared/components/Collapse';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import { Tooltip } from 'react-tippy';

const GRID_DETAIL_OFFSET = 160;

const exportColumns = [
  'table',
  'action',
  'created_at_value',
  'username',
  'ip_address',
  'location',
  'coordinates',
  'os',
  'browser',
  'browser_version'
];

class AccessLog extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired
  };
  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_access_log';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.audit_log_table.table,
          field: 'table',
          cellRenderer: 'agGroupCellRenderer',
          width: 70,
          minWidth: 70
        },
        {
          headerName: literals.tables.audit_log_table.action,
          field: 'action',
          width: 40,
          minWidth: 40
        },
        {
          headerName: literals.tables.audit_log_table.created_at,
          field: 'created_at_value',
          width: 60,
          minWidth: 60
        },
        {
          headerName: literals.tables.audit_log_table.username,
          field: 'username'
        },
        {
          headerName: literals.tables.audit_log_table.ip_address,
          field: 'ip_address'
        },
        {
          headerName: literals.tables.audit_log_table.location,
          field: 'location',
          valueGetter(params) {
            if (params.data.geolocation.country_name) {
              return `${params.data.geolocation.city}, ${params.data.geolocation.country_name}, ${params.data.geolocation.country_iso_code}`;
            }
            return null;
          },
          width: 80,
          minWidth: 80
        },
        {
          headerName: literals.tables.audit_log_table.coordinates,
          field: 'coordinates',
          valueGetter(params) {
            if (params.data.geolocation.latitude !== 0) {
              return `${params.data.geolocation.latitude}, ${params.data.geolocation.longitude}`;
            }
            return null;
          }
        },
        {
          headerName: literals.tables.audit_log_table.os,
          field: 'os'
        },
        {
          headerName: literals.tables.audit_log_table.browser,
          field: 'browser'
        },
        {
          headerName: literals.tables.audit_log_table.browser_version,
          field: 'browser_version'
        }
      ],
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: literals.tables.audit_log_table.row_id,
              field: 'row_id',
              cellStyle: { 'white-space': 'normal' },
              valueFormatter(params) {
                return JSON.stringify(params.value);
              }
            }
          ],
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
          },
          rowHeight: 100,
          suppressCellSelection: true,
          suppressContextMenu: true,
          defaultColDef: {
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            resizable: true
          }
        },
        getDetailRowData(params) {
          params.successCallback(params.data.audit_log_detail);
        }
      },
      isRowMaster(dataItem) {
        return dataItem ? dataItem.show_audit_log_detail : false;
      },
      rowData: [],
      collapse: '',
      defaultColDef: {
        menuTabs: ['filterMenuTab'],
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
        type: 'text'
      },
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter'
        }
      },
      getRowNodeId(data) {
        return data.id;
      },
      rowGroupOpened: {},
      childHeight: 0,
      height: 200,
      headerHeight: 16
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (
        literals.tables.audit_log_table.table !==
        prevProps.literals.tables.audit_log_table.table
      ) {
        this.gridColumnApi.getColumn('table').getColDef().headerName =
          literals.tables.audit_log_table.table;
        this.gridColumnApi.getColumn('action').getColDef().headerName =
          literals.tables.audit_log_table.action;
        this.gridColumnApi
          .getColumn('created_at_value')
          .getColDef().headerName = literals.tables.audit_log_table.created_at;
        this.gridColumnApi.getColumn('username').getColDef().headerName =
          literals.tables.audit_log_table.username;
        this.gridColumnApi.getColumn('ip_address').getColDef().headerName =
          literals.tables.audit_log_table.ip_address;
        this.gridColumnApi.getColumn('ip_address').getColDef().headerName =
          literals.tables.audit_log_table.ip_address;
        this.gridColumnApi.getColumn('location').getColDef().headerName =
          literals.tables.audit_log_table.location;
        this.gridColumnApi.getColumn('coordinates').getColDef().headerName =
          literals.tables.audit_log_table.coordinates;
        this.gridColumnApi.getColumn('os').getColDef().headerName =
          literals.tables.audit_log_table.os;
        this.gridColumnApi.getColumn('browser').getColDef().headerName =
          literals.tables.audit_log_table.browser;
        this.gridColumnApi.getColumn('browser_version').getColDef().headerName =
          literals.tables.audit_log_table.browser_version;
        this.gridApi.refreshHeader();
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onGridSizeChanged(params);
  }

  onGridSizeChanged = (params) => {
    let height =
      (this.state.rowData ? this.state.rowData.length : 0) * 20 +
      50 +
      this.state.headerHeight +
      this.state.childHeight;
    const wHeight = window.innerHeight / 1.2;
    if (height > wHeight && this.state.childHeight === 0) {
      height = wHeight;
    }
    this.setState({ height });
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      const minHeight = onColumnResized(params, this.myGridID);
      this.setState({ headerHeight: minHeight });
    }, 0);
  };

  onRowGroupOpened = (params) => {
    const obj = this.state.rowGroupOpened;
    const childID = params.node.id;
    obj[childID] = !obj[childID];
    let cHeightNew =
      params.data.audit_log_detail.length * 20 + GRID_DETAIL_OFFSET;
    if (params.data.audit_log_detail.length > 20) {
      cHeightNew = 20 * 20 + GRID_DETAIL_OFFSET;
    }
    let cHeight = this.state.childHeight;
    if (obj[childID]) {
      cHeight += cHeightNew;
    } else {
      cHeight -= cHeightNew;
    }
    this.setState({ childHeight: cHeight });
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'AccessLogs.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'AccessLogs.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  };

  handleSubmit = (values) => {
    this.gridApi.showLoadingOverlay();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/access_log`,
        JSON.stringify({
          start_date: values.start_date
            ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf()
            : values.start_date,
          end_date: values.end_date
            ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf()
            : values.end_date,
          table: values.table,
          action: ['Read']
        })
      )
      .then((response) => {
        this.setState({ rowData: response.data });
        this.gridApi.hideOverlay();
      })
      .catch(() => {
        this.gridApi.hideOverlay();
      });
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    let height =
      (this.state.rowData ? this.state.rowData.length : 0) * 20 +
      50 +
      this.state.headerHeight +
      this.state.childHeight;
    const wHeight = window.innerHeight / 1.2;
    if (height > wHeight && this.state.childHeight === 0) {
      height = wHeight;
    }
    this.setState({ height });
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title card__title_custom">
              <h5 className="bold-text">{literals.sidebar.access_log}</h5>
            </div>
            <Collapse
              title={literals.tables.ag_table.toggle_search}
              className="with-shadow"
              collapse={this.state.collapse}
            >
              <AccessLogForm onSubmit={this.handleSubmit} />
            </Collapse>
            <div className="outer-grid-label">
              {literals.tables.ag_table.total_records}:{' '}
              {this.state.rowData ? this.state.rowData.length : 0}
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip title={literals.tables.ag_table.export_excel}>
                  <Button
                    onClick={this.onBtExportExcel}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-excel" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.export_csv}>
                  <Button
                    onClick={this.onBtExportCSV}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-alt" />
                  </Button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div
              id={this.gridWrapperID}
              style={{ width: '100%', height: '100%', clear: 'both' }}
            >
              <div
                id={this.myGridID}
                style={{
                  boxSizing: 'border-box',
                  height: this.state.height,
                  width: '100%'
                }}
                className={
                  theme === 'theme-light'
                    ? 'ag-theme-balham'
                    : 'ag-theme-balham-dark'
                }
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.columnDefs}
                  immutableData
                  suppressCellSelection
                  suppressContextMenu
                  defaultColDef={this.state.defaultColDef}
                  onColumnResized={this.onColumnResized}
                  getRowNodeId={this.state.getRowNodeId}
                  rowData={this.state.rowData}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.onGridSizeChanged}
                  rowHeight={20}
                  detailRowHeight={180}
                  masterDetail
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  onRowGroupOpened={this.onRowGroupOpened}
                  isRowMaster={this.state.isRowMaster}
                  columnTypes={this.state.columnTypes}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(AccessLog);
