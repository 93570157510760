/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import Highlighter from 'react-highlight-words';
import 'css.escape';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';

@inject('rootStore')
class SubListRenderer extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
    };
    state = {
      sessionModalShow: false,
      subList: '',
      searchText: '',
    };

    onChange=(event) => {
      const { value } = event.target;
      this.setState({ searchText: value });
      setTimeout(() => {
        const searchWords = value.split(/\s/).filter(word => word);
        if (searchWords.length > 0) {
          const val = searchWords[searchWords.length - 1];
          const element =
            document.querySelector(`.feeder_sub_list .highlighted-text-${CSS.escape(val.toLowerCase())}`);
          if (element) {
            element.scrollIntoView();
          }
        }
      }, 0);
    };

    toggleModalSession = () => {
      this.setState({ sessionModalShow: !this.state.sessionModalShow });
    };
    showModalSession = () => {
      if (!this.state.sessionModalShow) {
        this.setState({ sessionModalShow: true });
        const { id, taker } = this.props.node.data;
        axios.get(`${process.env.REACT_APP_BACKEND_API}/monitoring/feeder_status_sub_list?id=${id}&taker=${taker}`)
          .then((response) => {
            this.setState({ subList: response.data });
          })
          .catch(() => {
          });
      }
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      const searchWords = this.state.searchText.split(/\s/).filter(word => word);
      const Highlight = ({ children }) => (
        <mark className={`highlighted-text-${children.toLowerCase()}`}>{children}</mark>
      );
      return (
        <span>
          <ButtonToolbar>
            <Tooltip title={`${literals.tables.ag_table.view} ${this.props.colDef.headerName}`} position="left">
              <Button
                style={{ height: 14 }}
                id="SecurityDeleteClick"
                onClick={this.showModalSession}
                className="icon"
                color="primary"
              ><p
                style={{ lineHeight: 0 }}
                className="fa fa-eye"
              />
              </Button>
            </Tooltip>
          </ButtonToolbar>
          <Modal
            isOpen={this.state.sessionModalShow}
            toggle={() => this.toggleModalSession}
            className={`modal-dialog--primary modal-dialog--header crp-modal ${theme} feeder_sub_list`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleModalSession()} />
              <h4 className="bold-text  modal__title">{this.props.colDef.headerName}</h4>
            </div>
            <div className="modal__body">
              <input
                className="input-css"
                placeholder={literals.tables.fix_status_table.filter_symbol}
                value={this.state.searchText}
                onChange={this.onChange}
              />
              <div style={{ wordBreak: 'break-all', maxHeight: 300, overflow: 'auto' }}>
                <Highlighter
                  searchWords={searchWords}
                  textToHighlight={this.state.subList}
                  highlightTag={Highlight}
                />
              </div>
            </div>
            <div className="modal__footer" />
          </Modal>
        </span>

      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(withRouter(SubListRenderer));
