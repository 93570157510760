/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import 'rc-tree-select/assets/index.css';
import TreeSelect from 'rc-tree-select';
import PropTypes from 'prop-types';

class TreeSelectField extends PureComponent {
    static propTypes = {
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        ]),
        label: PropTypes.string,
      })),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]).isRequired,
      className: PropTypes.string.isRequired,
      dropdownClassName: PropTypes.string.isRequired,
      treeData: PropTypes.instanceOf(Array),
      showCheckedStrategy: PropTypes.string,
      treeDefaultExpandAll: PropTypes.bool,
      treeDefaultExpandedKeys: PropTypes.instanceOf(Array),
      treeDefaultVal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]),
    };

    static defaultProps = {
      treeData: [],
      options: [],
      showCheckedStrategy: TreeSelect.SHOW_CHILD,
      treeDefaultExpandAll: false,
      treeDefaultExpandedKeys: [],
      treeDefaultVal: '',
    };

  switcherIcon = (props) => {
    if (props.isLeaf) {
      return '';
    }
    const color = '#70bbfd';
    if (props.expanded) {
      return <span className="fa fa-caret-down" style={{ color, fontSize: 16 }} />;
    }
    return <span className="fa fa-caret-right" style={{ color, fontSize: 16 }} />;
  };

  clearIcon = () => {
    const color = '#70bbfd';
    return <span className="fa fa-times" style={{ color, fontSize: 16 }} />;
  };

    handleChange = (selectedOption) => {
      this.props.onChange(selectedOption);
    };

    render() {
      const {
        value, name, options, treeData, className, dropdownClassName,
        showCheckedStrategy, treeDefaultExpandAll, treeDefaultExpandedKeys, treeDefaultVal,
      } = this.props;

      return (
        <TreeSelect
          name={name}
          value={value !== '' ? value : (treeDefaultVal !== '' ? treeDefaultVal : null)}
          options={options}
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
          treeData={treeData}
          treeLine
          treeCheckable
          onChange={this.handleChange}
          className={className}
          dropdownClassName={dropdownClassName}
          treeDefaultExpandAll={treeDefaultExpandAll}
          treeDefaultExpandedKeys={treeDefaultExpandedKeys}
          treeNodeFilterProp="title"
          switcherIcon={this.switcherIcon}
          showCheckedStrategy={showCheckedStrategy}
          autoClearSearchValue={false}
          allowClear
          clearIcon={this.clearIcon}
        />
      );
    }
}

const renderTreeSelectField = props => (
  <div className="form__form-group-input-wrap">
    <TreeSelectField
      {...props.input}
      treeData={props.treeData}
      className={props.className}
      dropdownClassName={props.dropdownClassName}
      options={props.options}
      disabled={props.disabled}
      showCheckedStrategy={props.showCheckedStrategy}
      treeDefaultExpandAll={props.treeDefaultExpandAll}
      treeDefaultExpandedKeys={props.treeDefaultExpandedKeys}
      treeDefaultVal={props.treeDefaultVal}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderTreeSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    label: PropTypes.string,
  })),
};

renderTreeSelectField.defaultProps = {
  meta: null,
  options: [],
};

export default renderTreeSelectField;
