import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from '../../shared/components/form/TreeSelect';
import renderDateTimePickerField from '../../shared/components/form/DateTimePicker';
import validate from '../../shared/components/form/StartEndDateValidate';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class LevelsAdvantageSearchForm extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      onUpdateRiskAccountTEM: PropTypes.instanceOf(Object).isRequired,
      showLoader: PropTypes.func.isRequired,
      hideLoader: PropTypes.func.isRequired,
      gridColumns: PropTypes.instanceOf(Array),
      gridDisplayedColumns: PropTypes.instanceOf(Array),
      gridColumnsField: PropTypes.instanceOf(Array),
      change: PropTypes.func.isRequired,
      setGridFilterValues: PropTypes.func,
      onBtExport: PropTypes.func,
    };
    static defaultProps = {
      gridColumns: [],
      gridDisplayedColumns: [],
      gridColumnsField: [],
      setGridFilterValues: () => {},
      onBtExport: () => {},
    }
    constructor(props) {
      super(props);
      this.state = {
        taker: [],
        tem: [],
        symbol: [],
        risk_account: [],
      };
    }
    componentDidMount() {
      this.props.showLoader();
      axios.get(`${process.env.REACT_APP_BACKEND_API}/report/report_search`)
        .then((response) => {
          this.setState({ taker: response.data.taker });
          this.setState({ tem: response.data.tem });
          this.setState({ symbol: response.data.symbol });
          this.setState({ risk_account: response.data.risk_account });
          this.props.onUpdateRiskAccountTEM(response.data.risk_account_tem);
          if (this.props.gridColumnsField.length === 0) {
            this.props.change('grid_columns', this.props.gridDisplayedColumns);
          }
          this.props.setGridFilterValues(response);
          this.props.hideLoader();
        })
        .catch(() => {
          this.props.hideLoader();
        });
    }
    render() {
      const {
        handleSubmit, theme, literals,
      } = this.props;
      return (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__quarter form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.ag_table.start_date}</span>
              <div className="form__form-group-field">
                <Field
                  name="start_date"
                  component={renderDateTimePickerField}
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.ag_table.end_date}</span>
              <div className="form__form-group-field">
                <Field
                  name="end_date"
                  component={renderDateTimePickerField}
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.ext_group}</span>
              <div className="form__form-group-field">
                <Field
                  name="group"
                  component={renderField}
                  type="text"
                  placeholder="comma separated for multiple values"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label" />
              <div className="form__form-group-field">
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" className="icon-text-button">
                    {literals.tables.ag_table.view_results}
                  </Button>
                  <Button color="primary" className="icon-text-button" onClick={() => this.props.onBtExport(2)}>
                    {literals.tables.ag_table.export_csv}
                  </Button>
                  <Button color="primary" className="icon-text-button" onClick={() => this.props.onBtExport(1)}>
                    {literals.tables.ag_table.export_excel}
                  </Button>
                </ButtonToolbar>
              </div>
            </div>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.ext_login}</span>
              <div className="form__form-group-field">
                <Field
                  name="login"
                  component={renderField}
                  type="text"
                  placeholder="comma separated for multiple values"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.ext_order}</span>
              <div className="form__form-group-field">
                <Field
                  name="order"
                  component={renderField}
                  type="text"
                  placeholder="comma separated for multiple values"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.cen_ord_id}</span>
              <div className="form__form-group-field">
                <Field
                  name="cen_ord_id"
                  component={renderField}
                  type="text"
                  placeholder="comma separated for multiple values"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.ext_bid_ask}</span>
              <div className="form__form-group-field">
                <Field
                  name="ext_bid_ask"
                  component={renderCheckBoxField}
                  label={literals.tables.order_table.ext_bid_ask}
                  defaultChecked
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.ag_table.grid_columns}</span>
              <div className="form__form-group-field">
                <Field
                  name="grid_columns"
                  component={renderTreeSelectField}
                  treeData={this.props.gridColumns}
                  treeDefaultExpandAll
                  className={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.symbol}</span>
              <div className="form__form-group-field">
                <Field
                  name="symbol"
                  component={renderTreeSelectField}
                  treeData={this.state.symbol}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                          'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                          'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.node_account}</span>
              <div className="form__form-group-field">
                <Field
                  name="execution"
                  component={renderTreeSelectField}
                  treeData={this.state.tem}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.node}</span>
              <div className="form__form-group-field">
                <Field
                  name="account"
                  component={renderTreeSelectField}
                  treeData={this.state.taker}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.order_table.risk_account}</span>
              <div className="form__form-group-field">
                <Field
                  name="risk_account"
                  component={renderTreeSelectField}
                  treeData={this.state.risk_account}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                        'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('levels_advantage_search_form');

function mapStateToProps(state) {
  return { literals: state.literals, theme: state.theme, gridColumnsField: selector(state, 'grid_columns') };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'levels_advantage_search_form', // a unique identifier for this form
  destroyOnUnmount: false,
  validate,
})(LevelsAdvantageSearchForm));
