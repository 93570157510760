import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from '../../shared/components/form/TreeSelect';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error }
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false
};

@inject('rootStore')
class NetPerLoginSearchForm extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    onGroupByTakerChange: PropTypes.func.isRequired,
    onGroupByTEMChange: PropTypes.func.isRequired
  };
  constructor(props) {
    props.showLoader();
    super(props);
    this.state = {
      symbol: [],
      partySymbol: [],
      taker: [],
      tem: []
    };
  }
  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/report/net_per_login_search`)
      .then((response) => {
        this.setState({ symbol: response.data.symbol });
        this.setState({ partySymbol: response.data.party_symbol });
        this.setState({ taker: response.data.taker });
        this.setState({ tem: response.data.tem });
        this.props.hideLoader();
      })
      .catch(() => {
        this.props.hideLoader();
      });
  }
  render() {
    const { handleSubmit, theme, literals } = this.props;
    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__fifth form__search">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.net_per_login_table.ext_login}
            </span>
            <div className="form__form-group-field">
              <Field
                name="login"
                component={renderField}
                type="text"
                placeholder="comma separated for multiple values"
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">
              {literals.tables.ag_table.search}
            </Button>
          </ButtonToolbar>
        </div>
        <div className="form__fifth">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.net_per_login_table.party_symbol}
            </span>
            <div className="form__form-group-field">
              <Field
                name="party_symbol"
                component={renderTreeSelectField}
                treeData={this.state.partySymbol}
                treeDefaultExpandedKeys={['Select All']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
        </div>
        <div className="form__fifth">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.net_per_login_table.symbol}
            </span>
            <div className="form__form-group-field">
              <Field
                name="symbol"
                component={renderTreeSelectField}
                treeData={this.state.symbol}
                treeDefaultExpandedKeys={['Select All']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
        </div>
        <div className="form__fifth">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.node}
            </span>
            <div className="form__form-group-field">
              <Field
                name="taker"
                component={renderTreeSelectField}
                treeData={this.state.taker}
                treeDefaultExpandedKeys={['Select All']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
        </div>
        <div className="form__fifth">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.node_account}
            </span>
            <div className="form__form-group-field">
              <Field
                name="tem"
                component={renderTreeSelectField}
                treeData={this.state.tem}
                treeDefaultExpandedKeys={['Select All']}
                className={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
                dropdownClassName={
                  theme.className === 'theme-light'
                    ? 'rc-tree-select-dropdown-light'
                    : 'rc-tree-select-dropdown-dark'
                }
              />
            </div>
          </div>
        </div>
        <div className="form__fifth" />
        <div className="form__fifth">
          <div className="form__form-group">
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              <span style={{ paddingRight: 50 }}>
                <Field
                  name="hide_zero_a_volume"
                  component={renderCheckBoxField}
                  label={literals.tables.net_per_login_table.hide_zero_a_volume}
                  defaultChecked
                />
              </span>
              <span>
                <Field
                  name="hide_zero_b_volume"
                  component={renderCheckBoxField}
                  label={literals.tables.net_per_login_table.hide_zero_b_volume}
                  defaultChecked
                />
              </span>
            </div>
          </div>
        </div>
        <div className="form__fifth">
          <div className="form__form-group">
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              <span style={{ paddingRight: 50 }}>
                <Field
                  name="hide_giveup"
                  component={renderCheckBoxField}
                  label={literals.tables.net_per_login_table.hide_giveup}
                  defaultChecked
                />
              </span>
              <span>
                <Field
                  name="hide_drop_copy"
                  component={renderCheckBoxField}
                  label={literals.tables.net_per_login_table.hide_drop_copy}
                  defaultChecked
                />
              </span>
            </div>
          </div>
        </div>
        <div className="form__fifth">
          <div className="form__form-group">
            <span className="form__form-group-label" />
            <div className="form__form-group-field">
              <span style={{ paddingRight: 50 }}>
                <Field
                  name="group_by_taker"
                  component={renderCheckBoxField}
                  label={literals.tables.net_per_login_table.group_by_taker}
                  onChange={this.props.onGroupByTakerChange}
                />
              </span>
              <span>
                <Field
                  name="group_by_tem"
                  component={renderCheckBoxField}
                  label={literals.tables.net_per_login_table.group_by_tem}
                  onChange={this.props.onGroupByTEMChange}
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return { literals: state.literals, theme: state.theme };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'net_per_login_search_form', // a unique identifier for this form
    destroyOnUnmount: false
  })(NetPerLoginSearchForm)
);
