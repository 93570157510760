/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Button,
  ButtonGroup
} from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { connect } from 'react-redux';
import { BasicNotification } from '../../../shared/components/Notification';
import { LiteralProps } from '../../../shared/prop-types/ReducerProps';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

@inject('rootStore')
class TopbarWorkSpace extends PureComponent {
  static propTypes = {
    rootStore: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    literals: LiteralProps.isRequired
  };

  constructor() {
    super();
    this.state = {
      activeTab: 0,
      names: [],
      dropdownOpen: false,
      dropdownOpenEdit: false,
      workspaceName: '',
      currentWorkspaceName: '',
      currentWorkspaceKey: 0,
      cancelBlur: false
    };
  }
  componentDidMount() {
    const href = window.location.href.split('/');
    const { username } = this.props.rootStore.authStore.user;
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/multi_window/workspaces?username=${username}`
      )
      .then((response) => {
        const data =
          response.data.length > 0 ? response.data : ['My Workspace'];
        this.setState({ names: data });
        if (window.location.href.includes('/workspace/')) {
          const currentWorkspaceName = atob(href[href.length - 1]);
          this.setState({ currentWorkspaceName });
          const index = data.findIndex((name) => name === currentWorkspaceName);
          const currentWorkspaceKey = index + 1;
          this.setState({ currentWorkspaceKey });
          this.toggle(currentWorkspaceKey);
        }
      })
      .catch(() => {});
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleDropdownEdit = () => {
    this.setState({ dropdownOpenEdit: !this.state.dropdownOpenEdit });
  };

  appendData = () => {
    const data = this.state.names.filter((name) =>
      /^My Workspace \d+$/.test(name)
    );
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    const dataSorted = data.sort(collator.compare).reverse();
    let key = 1;
    if (dataSorted.length > 0) {
      const matches = dataSorted[0].match(/\d+$/);
      key = parseInt(matches[0], 10) + 1;
    }
    const name = `My Workspace ${key}`;
    this.setState({ names: [...this.state.names, name] });
    setTimeout(() => {
      const domKey = this.state.names.length;
      this[`nav${domKey}`].onClick();
      this.btnWorkspace.props.onDoubleClick();
    }, 0);
  };

  handleChange = (event) => {
    this.setState({ workspaceName: event.target.value });
  };

  handleBlur = (event) => {
    if (this.state.cancelBlur) {
      this.setState({ cancelBlur: false });
      return;
    }
    const newName = event.target.value;
    const oldName = this.labelWorkspace.innerText;
    if (!newName.trim()) {
      this.setState({ workspaceName: oldName });
      this.textWorkspace.style.display = 'none';
      this.labelWorkspace.style.display = 'block';
      return;
    }
    const data = {
      username: this.props.rootStore.authStore.user.username,
      workspace: newName,
      old_workspace: oldName
    };
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/multi_window/workspace/name`,
        JSON.stringify(data)
      )
      .then(() => {
        this.setState((state) => {
          const names = state.names.map((name) => {
            if (name === oldName) {
              return newName;
            }
            return name;
          });
          return {
            names
          };
        });
        this.textWorkspace.style.display = 'none';
        this.labelWorkspace.style.display = 'block';
        setTimeout(() => {
          this[`nav${this.state.currentWorkspaceKey}`].onClick();
        }, 0);
      })
      .catch((error) => {
        showNotification(
          this.props.literals.topbar.workspace_edit,
          'danger',
          error.response.data || this.props.literals.topbar.server_error
        );
      });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.textWorkspace.style.display = 'none';
      this.labelWorkspace.style.display = 'block';
    } else if (event.key === 'Escape') {
      this.setState({ cancelBlur: true });
      const oldName = this.labelWorkspace.innerText;
      this.setState({ workspaceName: oldName });
      this.textWorkspace.style.display = 'none';
      this.labelWorkspace.style.display = 'block';
    }
  };

  handleRename = (key, value) => {
    if (!this.state.currentWorkspaceName) {
      return;
    }
    this.textWorkspace.style.display = 'block';
    this.labelWorkspace.style.display = 'none';
    this.textWorkspace.focus();
    this.setState({ workspaceName: value });
  };

  handleDelete = (name) => {
    const data = {
      data: JSON.stringify({
        username: this.props.rootStore.authStore.user.username,
        workspace: name
      })
    };
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_API}/multi_window/workspace`,
        data
      )
      .then(() => {
        this.setState((state) => {
          const names = state.names.filter((item) => name !== item);
          return {
            names
          };
        });
        setTimeout(() => {
          this[`nav${this.state.names.length}`].onClick();
        }, 0);
      })
      .catch(() => {});
  };

  handleDropdownItemClick = (key, name) => {
    this.setState({ currentWorkspaceName: name });
    this.setState({ currentWorkspaceKey: key });
    this.toggle(key);
    this.toggleDropdown();
    this.props.history.push(`/workspace/${btoa(name)}`);
  };

  render() {
    const items = this.state.names.map((name, index) => {
      const key = index + 1;
      return (
        <DropdownItem
          key={key}
          ref={(r) => {
            this[`nav${key}`] = r;
          }}
          className={classnames(
            { active: this.state.activeTab === key },
            `nav${key}`
          )}
          onClick={() => {
            this.handleDropdownItemClick(key, name);
          }}
        >
          {name}
        </DropdownItem>
      );
    });
    return (
      <>
        <div className="multi_window form">
          <Dropdown
            isOpen={this.state.dropdownOpen}
            toggle={this.toggleDropdown}
            className="main"
          >
            <ButtonGroup>
              <Button
                ref={(r) => {
                  this.btnWorkspace = r;
                }}
                color="primary"
                outline
                onDoubleClick={() => {
                  this.handleRename(
                    this.state.currentWorkspaceKey,
                    this.state.currentWorkspaceName
                  );
                }}
              >
                <input
                  ref={(r) => {
                    this.textWorkspace = r;
                  }}
                  type="text"
                  value={this.state.workspaceName}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  onKeyDown={this.handleKeyDown}
                  style={{ display: 'none' }}
                />
                <span
                  ref={(r) => {
                    this.labelWorkspace = r;
                  }}
                >
                  {this.state.currentWorkspaceName ||
                    this.props.literals.topbar.select_workspace}
                </span>
              </Button>
              <DropdownToggle color="primary" className="icon icon--right">
                <ChevronDownIcon />
              </DropdownToggle>
            </ButtonGroup>
            <DropdownMenu right className="dropdown__menu">
              {items}
            </DropdownMenu>
          </Dropdown>
          <Dropdown
            isOpen={this.state.dropdownOpenEdit}
            toggle={this.toggleDropdownEdit}
            className="sub"
          >
            <ButtonGroup>
              <DropdownToggle color="primary" className="icon icon--right">
                <ChevronDownIcon />
              </DropdownToggle>
            </ButtonGroup>
            <DropdownMenu right className="dropdown__menu">
              <DropdownItem
                onClick={() => {
                  this.appendData();
                }}
              >
                {this.props.literals.topbar.add_new_workspace}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.handleRename(
                    this.state.currentWorkspaceKey,
                    this.state.currentWorkspaceName
                  );
                }}
                disabled={!this.state.currentWorkspaceName}
              >
                {this.props.literals.topbar.edit_this_workspace}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.handleDelete(this.state.currentWorkspaceName);
                }}
                disabled={
                  !this.state.currentWorkspaceName ||
                  this.state.names.length <= 1
                }
              >
                {this.props.literals.topbar.delete_this_workspace}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </>
    );
  }
}

export default connect((state) => ({
  literals: state.literals
}))(withRouter(TopbarWorkSpace));
