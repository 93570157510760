import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import rootReducer from './../reducers'; // the value from combineReducers

/* const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['literals', 'realtime'],
}; */

// const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(rootReducer);
export default store;
export const persistor = persistStore(store);
