/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import { connect } from 'react-redux';
import { addCommas, decimalPlaces } from '../../shared/helper';
import MarketWatchPanel from './MarketWatchPanel';
import { LiteralProps, MarketWatchProps } from '../../shared/prop-types/ReducerProps';

const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

class MarketWatchView extends PureComponent {
  static propTypes = {
    selectedSymbols: PropTypes.instanceOf(Array),
    marketWatch: PropTypes.instanceOf(Array),
    marketWatchLast: PropTypes.instanceOf(Array),
    activeTab: PropTypes.string.isRequired,
    marketWatchProp: MarketWatchProps,
    literals: LiteralProps.isRequired,
  };
  static defaultProps = {
    selectedSymbols: [],
    marketWatch: [],
    marketWatchLast: [],
    marketWatchProp: {},
  }
  priceSplit=(input) => {
    const strOut = [];
    const strIn = input;
    let last = strIn.substr(strIn.length - 3);
    let first = strIn.substr(0, strIn.length - 3);
    if (last.indexOf('.') >= 0) {
      last = strIn.substr(strIn.length - 4);
      first = strIn.substr(0, strIn.length - 4);
    }
    strOut.push(first);
    strOut.push(last.substr(0, last.length - 1));
    strOut.push(last[last.length - 1]);
    return strOut;
  };
  render() {
    const { literals } = this.props;
    let panels1 = [];
    if (this.props.activeTab === '1') {
      let j = 0;
      let k = 0;
      const tt = this.props.marketWatch ?
        this.props.marketWatch : [];
      const ttLast = this.props.marketWatchLast ?
        this.props.marketWatchLast : [];
      const vt = this.props.selectedSymbols ? this.props.selectedSymbols : [];
      const symbolItem = arrayToObject(toJS(tt), 'symbol');
      const symbolItemLast = arrayToObject(toJS(ttLast), 'symbol');
      panels1 = vt.map((item) => {
        if (typeof item === 'object' && typeof item.value !== 'undefined' && typeof item.digits !== 'undefined') {
          j += 1;
          let BidPrice = '0.00000';
          let AskPrice = '0.00000';
          let BidPriceSplit = this.priceSplit(addCommas(parseFloat(BidPrice)
            .toFixed(item.digits || decimalPlaces(BidPrice))
            .toString()));
          let AskPriceSplit = this.priceSplit(addCommas(parseFloat(AskPrice)
            .toFixed(item.digits || decimalPlaces(AskPrice))
            .toString()));
          let bidPriceChange = 'neutral';
          let askPriceChange = 'neutral';
          let bidVolume = 0;
          let askVolume = 0;
          let depthListValues = [];
          let lastUpdated = '-';
          let lastUpdatedTooltip = '-';
          let ltpPrice = '0.00000';
          let ltpVolume = 0;
          const symbolItemValue = symbolItem[item.value] || symbolItemLast[item.value];
          if (symbolItemValue) {
            BidPrice = symbolItemValue.bid_price || 0;
            AskPrice = symbolItemValue.ask_price || 0;
            BidPriceSplit = this.priceSplit(addCommas(parseFloat(BidPrice)
              .toFixed(item.digits || decimalPlaces(BidPrice))
              .toString()));
            AskPriceSplit = this.priceSplit(addCommas(parseFloat(AskPrice)
              .toFixed(item.digits || decimalPlaces(AskPrice))
              .toString()));
            bidPriceChange = symbolItemValue.bid_price_change;
            askPriceChange = symbolItemValue.ask_price_change;
            bidVolume = new Intl.NumberFormat('en-GB', {
              notation: 'compact',
              maximumFractionDigits: item.vol_digits,
            }).format(symbolItemValue.bid_volume);
            askVolume = new Intl.NumberFormat('en-GB', {
              notation: 'compact',
              maximumFractionDigits: item.vol_digits,
            }).format(symbolItemValue.ask_volume);
            ltpPrice = addCommas(parseFloat(symbolItemValue.ltp_price)
              .toFixed(item.digits || decimalPlaces(symbolItemValue.ltp_price))
              .toString());
            ltpVolume = new Intl.NumberFormat('en-GB', {
              notation: 'compact',
              maximumFractionDigits: item.vol_digits,
            }).format(symbolItemValue.ltp_volume);
            depthListValues = symbolItemValue.depth_symbols;
            if (symbolItemValue.last_updated) {
              const lastUpdatedLocalTime = moment.utc(symbolItemValue.last_updated, 'DD-MM-YYYY HH:mm:ss');
              lastUpdated = lastUpdatedLocalTime.format('HH:mm:ss');
              lastUpdatedTooltip = lastUpdatedLocalTime.format('DD-MM-YYYY HH:mm:ss');
            }
          }
          let classNameColor = '';
          if (this.props.marketWatchProp.marketWatchColor ||
            !this.props.marketWatchProp.marketWatchColor) {
            const diff = moment.utc()
              .diff(moment.utc(lastUpdatedTooltip, 'DD-MM-YYYY HH:mm:ss'), 'minutes');
            if (diff > 5 || isNaN(diff)) {
              classNameColor = 'greyed';
            }
          }
          const depthLength = (5 - depthListValues.length);
          for (let i = 0; i < depthLength; i += 1) {
            depthListValues.push({
              bid_price: '0.00000',
              ask_price: '0.00000',
              bid_volume: 0,
              ask_volume: 0,
            });
          }
          const rawSpread = Math.abs(AskPrice - BidPrice);
          let spreadPoints = 0;
          if (parseFloat(AskPrice || 0) !== 0 && parseFloat(BidPrice || 0) !== 0) {
            spreadPoints = Math.round(rawSpread * (10 ** item.digits));
          }
          const spread = addCommas(parseFloat(rawSpread
            .toString())
            .toFixed(item.digits || decimalPlaces(AskPrice))
            .toString());
          const depthList = depthListValues.map((depth) => {
            k += 1;
            let spreadPointsDepth = 0;
            if (parseFloat(depth.ask_price || 0) !== 0 && parseFloat(depth.bid_price || 0) !== 0) {
              spreadPointsDepth = Math.round(Math.abs(depth.ask_price - depth.bid_price) * (10 ** item.digits));
            }
            return (
              <tr key={k}>
                <td>
                  {new Intl.NumberFormat('en-GB', {
                    notation: 'compact',
                    maximumFractionDigits: item.vol_digits,
                  }).format(depth.bid_volume)}
                </td>
                <td style={{ display: 'none' }} />
                <td>{addCommas(parseFloat(depth.bid_price || 0)
                  .toFixed(item.digits || decimalPlaces(depth.bid_price))
                  .toString())}
                </td>
                <td>
                  {addCommas(spreadPointsDepth.toString())}
                </td>
                <td>{addCommas(parseFloat(depth.ask_price || 0)
                  .toFixed(item.digits || decimalPlaces(depth.ask_price))
                  .toString())}
                </td>
                <td style={{ display: 'none' }} />
                <td>
                  {new Intl.NumberFormat('en-GB', {
                    notation: 'compact',
                    maximumFractionDigits: item.vol_digits,
                  }).format(depth.ask_volume)}
                </td>
              </tr>
            );
          });
          return (
            <MarketWatchPanel md={2} divider title={item.value} className="depth depth-mw" key={j}>
              <div className={`half-table ${classNameColor}`}>
                <Table responsive className="left">
                  <tbody>
                    <tr>
                      <td>{literals.tables.trading_table.qty}</td>
                      <td className={`price-change-${bidPriceChange}`}>{literals.tables.trading_table.bid}</td>
                      <td className={`price-change-before-${askPriceChange}`}>{literals.tables.trading_table.ask}</td>
                      <td>{literals.tables.trading_table.qty}</td>
                    </tr>
                    <tr>
                      <td>{bidVolume}</td>
                      <td>{BidPriceSplit[0]}<span style={{ fontSize: '25px' }}>{BidPriceSplit[1]}</span>
                        <span style={{ verticalAlign: 'top' }}>{BidPriceSplit[2]}</span>
                      </td>
                      <td>{AskPriceSplit[0]}<span style={{ fontSize: '25px' }}>{AskPriceSplit[1]}</span>
                        <span style={{ verticalAlign: 'top' }}>{AskPriceSplit[2]}</span>
                      </td>
                      <td>{askVolume}</td>
                    </tr>
                    <tr>
                      <td />
                      <td>{literals.tables.trading_table.spread}</td>
                      <td>{spread}</td>
                    </tr>
                    <tr>
                      <td>{literals.tables.trading_table.ltp_price}</td>
                      <td>{literals.tables.trading_table.points}</td>
                      <td>{addCommas(spreadPoints.toString())}</td>
                      <td>{literals.tables.trading_table.ltp_volume}</td>
                    </tr>
                    <tr>
                      <td>{ltpPrice}</td>
                      <td>{literals.tables.trading_table.time}</td>
                      <td>
                        <Tooltip title={lastUpdatedTooltip}>{lastUpdated}
                        </Tooltip>
                      </td>
                      <td>{ltpVolume}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <Table striped responsive>
                <tbody>
                  {depthList}
                </tbody>
              </Table>
            </MarketWatchPanel>
          );
        }
        return null;
      });
    }
    return (
      <span>
        { panels1 }
      </span>
    );
  }
}
export default connect(state => ({
  marketWatchProp: state.marketWatchProp,
  literals: state.literals,
}))(MarketWatchView);
