import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

@inject('rootStore')
class TopbarNewUI extends PureComponent {
  static propTypes = {
    marginLeft: PropTypes.number,
    rootStore: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string
  };
  static defaultProps = {
    marginLeft: 5,
    className: 'conn__status'
  };

  render() {
    return (
      <div className={`topbar__profile ${this.props.className}`}>
        <div style={{ height: 36 }}>
          <span
            onClick={() => {
              if (process.env.REACT_APP_ENV === 'production') {
                window.location.href = 'https://bridge.centroidsol.com/';
              } else {
                axios
                  .get(`${process.env.REACT_APP_FRONTEND_API}/logout`)
                  .then(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    document.cookie = 'gui=new ; path=/ ; max-age=31536000';
                    window.location.replace(
                      `${
                        window.location.origin
                      }?nocache=" + ${new Date().getTime()}`
                    );
                  })
                  .catch(() => {});
              }
            }}
            role="presentation"
            style={{ cursor: 'pointer' }}
          >
            <p
              className="fa fa-external-link-alt"
              style={{
                color: '#70bbfd',
                fontWeight: 'bold',
                float: 'left',
                lineHeight: 5.5
              }}
            />
            <p
              style={{
                float: 'left',
                lineHeight: 3.6,
                marginLeft: this.props.marginLeft
              }}
            >
              Switch to new Gateways
            </p>
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(TopbarNewUI);
