/* eslint-disable arrow-parens */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';

const windowHeight = window.screen.height;

class AccountBalance extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rowData: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    const tableID = 'sidebar_balance_transaction_report_account_balance';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    const { literals } = props;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.account_balance_table.name,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.name'),
          field: 'name',
          headerTooltip: literals.tables.account_balance_table.name,
          pinned: 'left',
          width: 60,
          minWidth: 60,
          type: 'text',
        },
        {
          headerName: literals.tables.account_balance_table.balance,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.balance'),
          field: 'balance',
          headerTooltip: literals.tables.account_balance_table.balance,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.available_withdraw,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.available_withdraw'),
          field: 'available_withdraw',
          headerTooltip: literals.tables.account_balance_table.available_withdraw,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.equity,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.equity'),
          field: 'equity',
          headerTooltip: literals.tables.account_balance_table.equity,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.pl,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.pl'),
          field: 'pl',
          headerTooltip: literals.tables.account_balance_table.pl,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.margin,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.margin'),
          field: 'margin',
          headerTooltip: literals.tables.account_balance_table.margin,
          pinned: 'left',
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.margin_level,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.margin_level'),
          field: 'margin_level',
          headerTooltip: literals.tables.account_balance_table.margin_level,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.commission,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.commission'),
          field: 'commission',
          headerTooltip: literals.tables.account_balance_table.commission,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.swaps,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.swaps'),
          field: 'swaps',
          headerTooltip: literals.tables.account_balance_table.swaps,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.notional,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.notional'),
          field: 'notional',
          headerTooltip: literals.tables.account_balance_table.notional,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.credit,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.credit'),
          field: 'credit',
          headerTooltip: literals.tables.account_balance_table.credit,
          width: 60,
          minWidth: 60,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
        },
        {
          headerName: literals.tables.account_balance_table.trading_state,
          headerValueGetter: () => this.localizeHeader('literals.tables.account_balance_table.trading_state'),
          field: 'trading_state_value',
          headerTooltip: literals.tables.account_balance_table.trading_state,
          width: 60,
          minWidth: 60,
          cellRenderer(params) {
            if (params.value === 'REACHED NOP') {
              return `<span class="cell-renderer-blue">${params.value}</span>`;
            } else if (params.value === 'STOPPED OUT') {
              return `<span class="cell-renderer-red">${params.value}</span>`;
            } else if (params.value === 'MARGIN CALL') {
              return `<span class="cell-renderer-brown">${params.value}</span>`;
            }
            return `<span style='font:inherit'>${params.value}</span>`;
          },
          type: 'text',
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
        menuTabs: ['filterMenuTab'],
        suppressMovable: true,
      },
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter',
        },
      },
      getRowNodeId(data) {
        return data.name;
      },
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (literals.forms.security_form.name !== prevProps.literals.forms.security_form.name) {
        this.gridApi.refreshHeader();
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onGridSizeChanged(params);
    this.gridApi.refreshHeader();
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  localizeHeader=header => _.get(this.props, header)

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    let height = ((this.props.rowData ? this.props.rowData.length : 0) * 28) + 56 + 34;
    const wHeight = windowHeight - 400;
    if (height > wHeight) {
      height = wHeight;
    }
    return (
      <div>
        <h5 className="bold-text" style={{ textTransform: 'uppercase' }}>{literals.sidebar.account_balance}
        </h5>
        <div className="outer-grid-label">
          {literals.tables.ag_table.total_records}: {this.props.rowData ? this.props.rowData.length : 0}
        </div>
        <div
          id={this.gridWrapperID}
          style={{
 width: '100%', height: '100%', clear: 'both',
}}
        >
          <div
            id={this.myGridID}
            style={{
                                    boxSizing: 'border-box',
                                    height,
                                    width: '100%',
                                }}
            className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              immutableData
              suppressCellSelection
              suppressContextMenu
              defaultColDef={this.state.defaultColDef}
              onColumnResized={this.onColumnResized}
              getRowNodeId={this.state.getRowNodeId}
              rowData={this.props.rowData}
              onGridReady={this.onGridReady}
              onGridSizeChanged={this.onGridSizeChanged}
              columnTypes={this.state.columnTypes}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(AccountBalance);
