/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import classnames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import LPSessionDetailCellRenderer from './detailCellRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import LPSessionForm from './form';
import lpSessionAction from './action';
import Loader from '../../shared/components/Loader';
import LPSessionActionRenderer from './actionRenderer';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import LPSymbolUpload from '../lp_session/upload';

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

@inject('rootStore')
class LPSession extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_lp_session';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.forms.lp_session_form.name,
          field: 'name',
          cellRenderer: 'agGroupCellRenderer',
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.forms.lp_session_form.lp_session_type,
          field: 'session_type',
          width: 60,
          minWidth: 60,
        },
        {
          field: 'owner',
          hide: true,
        },
        {
          field: 'key',
          hide: true,
        },
        {
          field: 'type',
          hide: true,
        },
        {
          field: 'value_param',
          hide: true,
        },
        {
          field: 'description',
          hide: true,
        },
        {
          headerName: literals.tables.ag_table.actions,
          field: 'actions',
          cellRenderer: 'actionRenderer',
          filter: false,
          editable: false,
        },
      ],
      detailCellRenderer: 'myDetailCellRenderer',
      frameworkComponents: {
        myDetailCellRenderer: LPSessionDetailCellRenderer,
        actionRenderer: LPSessionActionRenderer,
      },
      rowData: [],
      rowOldData: [],
      formData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      getRowNodeId(data) {
        return data.name;
      },
      cellEditUpdateDetail: {},
      rowsToUpdateDetail: {},
      stylesToUpdateDetail: {},
      activeTab: '1',
      height: window.innerHeight,
      loaderShow: false,
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (literals.forms.lp_session_form.name !== prevProps.literals.forms.lp_session_form.name) {
        this.gridColumnApi.getColumn('name')
          .getColDef().headerName = literals.forms.lp_session_form.name;
        this.gridColumnApi.getColumn('session_type')
          .getColDef().headerName = literals.forms.lp_session_form.lp_session_type;
        this.gridColumnApi.getColumn('actions')
          .getColDef().headerName = literals.tables.ag_table.actions;
        this.gridApi.refreshHeader();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.onGridSizeChanged(params);
    axios.get(`${process.env.REACT_APP_BACKEND_API}/security/lp_session`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.LPSession)) });
      })
      .catch(() => {
      });
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized=(params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: ['name', 'session_type'],
      fileName: 'MakerSession.xlsx',
      suppressTextAsCDATA: true,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: ['name', 'session_type'],
      fileName: 'MakerSession.csv',
    };
    this.gridApi.exportDataAsCsv(params);
  };

  updateDimensions=() => {
    this.setState({ height: window.innerHeight });
  };

  updateData = (data) => {
    this.setState({ rowData: data.LPSession });
    let lpCategoryValues = [];
    if (this.props.rootStore.authStore.role === 'admin') {
      lpCategoryValues = [{ label: 'Select Category', value: '' }, ...data.LPCategory || []];
    } else {
      lpCategoryValues = data.LPCategory || [];
    }
    this.setState({
      formData: {
        LPSession: data.LPSessionEnum,
        LPSessionObj: arrayToObject(data.LPSessionEnum, 'label'),
        UniSymbol: data.UniSymbol,
        Security: data.Security,
        LPCategory: lpCategoryValues,
        LPSessionType: data.LPSessionType,
        State: data.State,
        StateObj: arrayToObject(data.State, 'label'),
        Bool: data.Bool,
        BoolObj: arrayToObject(data.Bool, 'label'),
        QueueAction: data.QueueAction,
        QueueActionObj: arrayToObject(data.QueueAction, 'label'),
        FeedSide: data.FeedSide,
        FeedSideObj: arrayToObject(data.FeedSide, 'label'),
        Taker: data.Taker,
      },
    });
  };

  resetLPSession=() => {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/security/lp_session`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.LPSession)) });
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  updateStateDetail=(key, cellEditUpdate, rowsToUpdate, stylesToUpdate) => {
    const cellEditUpdateDetail = { ...this.state.cellEditUpdateDetail };
    cellEditUpdateDetail[key] = cellEditUpdate;
    this.setState({ cellEditUpdateDetail });
    const rowsToUpdateDetail = { ...this.state.rowsToUpdateDetail };
    rowsToUpdateDetail[key] = rowsToUpdate;
    this.setState({ rowsToUpdateDetail });
    const stylesToUpdateDetail = { ...this.state.stylesToUpdateDetail };
    stylesToUpdateDetail[key] = stylesToUpdate;
    this.setState({ stylesToUpdateDetail });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="tabs tabs--bordered-top tabs__custom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {literals.sidebar.lp_session}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {literals.forms.lp_session_form.title_create}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => {
                          this.toggle('3');
                        }}
                    >
                      {`${literals.forms.title_import} ${literals.sidebar.lp_symbol}`}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="outer-grid-label">
                      {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                    </div>
                    <div className="outer-grid-button">
                      <ButtonToolbar>
                        <Tooltip title={literals.tables.ag_table.export_excel}>
                          <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                            <p className="fa fa-file-excel" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.export_csv}>
                          <Button
                            onClick={this.onBtExportCSV}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-file-alt" />
                          </Button>
                        </Tooltip>
                      </ButtonToolbar>
                    </div>
                    <div
                      id={this.gridWrapperID}
                      style={{
                      width: '100%',
                      height: '100%',
                      clear: 'both',
                    }}
                    >
                      <div
                        id={this.myGridID}
                        style={{
                          boxSizing: 'border-box',
                          height: this.state.height - REDUCE_HEIGHT,
                          width: '100%',
                        }}
                        className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                      >
                        <AgGridReact
                          modules={this.state.modules}
                          columnDefs={this.state.columnDefs}
                          suppressCellSelection
                          suppressContextMenu
                          immutableData
                          getRowNodeId={this.state.getRowNodeId}
                          defaultColDef={this.state.defaultColDef}
                          onColumnResized={this.onColumnResized}
                          rowData={this.state.rowData}
                          masterDetail
                          getRowHeight={this.state.getRowHeight}
                          detailCellRenderer={this.state.detailCellRenderer}
                          detailRowHeight={340}
                          frameworkComponents={this.state.frameworkComponents}
                          onGridReady={this.onGridReady}
                          onGridSizeChanged={this.onGridSizeChanged}
                          rowHeight={20}
                          context={{
                            formData: this.state.formData,
                            resetLPSession: this.resetLPSession,
                            updateStateDetail: this.updateStateDetail,
                            cellEditUpdateDetail: this.state.cellEditUpdateDetail,
                            rowsToUpdateDetail: this.state.rowsToUpdateDetail,
                            stylesToUpdateDetail: this.state.stylesToUpdateDetail,
                            showLoader: this.showLoader,
                            hideLoader: this.hideLoader,
                          }}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div style={{ width: '45%', display: 'inline-block', marginRight: '10%' }}>
                      <LPSessionForm
                        {...this.props}
                        state={{
                        formData: this.state.formData,
                        resetLPSession: this.resetLPSession,
                        toggle: this.toggle,
                        showLoader: this.showLoader,
                        hideLoader: this.hideLoader,
                        form: 'lp_session_form',
                      }}
                        onSubmit={lpSessionAction}
                        form="lp_session_form"
                      />
                    </div>
                    <div style={{ width: '45%', display: 'inline-block' }}>
                      <LPSessionForm
                        {...this.props}
                        state={{
                          formData: this.state.formData,
                          resetLPSession: this.resetLPSession,
                          toggle: this.toggle,
                          showLoader: this.showLoader,
                          hideLoader: this.hideLoader,
                          form: 'lp_session_form_r',
                        }}
                        onSubmit={lpSessionAction}
                        form="lp_session_form_r"
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <LPSymbolUpload
                      formData={this.state.formData}
                      resetLPSession={this.resetLPSession}
                      rowOldData={this.state.rowOldData}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(LPSession);
