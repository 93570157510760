/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';
import { inject } from 'mobx-react';

@inject('rootStore')
class StateRenderer extends PureComponent {
    static propTypes = {
      value: PropTypes.string.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    state = {
      selectedOption: this.props.value === 'ENABLED',
    };
    refresh(params) {
      if ((params.value === 'ENABLED') !== this.state.selectedOption) {
        this.setState({
          selectedOption: params.value === 'ENABLED',
        });
      }
      return true;
    }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.target.checked });
    const rowNode = this.props.node;
    if (selectedOption.target.checked === true) {
      rowNode.setDataValue(this.props.colDef.field, 'ENABLED');
    } else {
      rowNode.setDataValue(this.props.colDef.field, 'DISABLED');
    }
  };
  render() {
    const { selectedOption } = this.state;
    const { role } = this.props.rootStore.authStore;
    const disabled = role !== 'admin' && this.props.node.data.type_value !== 'FIX'
      && this.props.node.data.type_value !== 'DROPCOPY';
    return (
      <label
        className={`checkbox-btn ag-checkbox-btn ${disabled === true ? 'disabled' : ''}`}
      >
        <input
          className="checkbox-btn__checkbox"
          type="checkbox"
          name="checkboxAG"
          checked={selectedOption}
          onChange={this.handleChange}
          disabled={disabled}
        />
        <span
          className="checkbox-btn__checkbox-custom"
        >
          <CheckIcon />
        </span>
      </label>
    );
  }
}
export default StateRenderer;
