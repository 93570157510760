const profileValidate = (values, props) => {
  const errors = {};
  if (!values.username) {
    errors.username = `${props.literals.forms.user_form.username} ${props.literals.forms.required_validate}`;
  }
  if (!values.first_name) {
    errors.first_name = `${props.literals.forms.user_form.first_name} ${props.literals.forms.required_validate}`;
  }
  if (!values.last_name) {
    errors.last_name = `${props.literals.forms.user_form.last_name} ${props.literals.forms.required_validate}`;
  }
  if (!values.email) {
    errors.email = `${props.literals.forms.user_form.email} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default profileValidate;
