/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Modal, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Dialog from 'rc-dialog';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import { inject } from 'mobx-react';
import classnames from 'classnames';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham-dark.css';
import _ from 'lodash';
import FileDownload from 'js-file-download';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';

@inject('rootStore')
class NodeActionRenderer extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      context: PropTypes.instanceOf(Object).isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.rowsToUpdate[1] = {};
      this.rowsToUpdate[2] = {};
      this.rowsToUpdate[3] = {};
      this.rowsToUpdate[4] = {};
      this.stylesToUpdate = {};
      this.stylesToUpdate[1] = {};
      this.stylesToUpdate[2] = {};
      this.stylesToUpdate[3] = {};
      this.stylesToUpdate[4] = {};
      const { literals } = this.props;
      const tableID = 'sidebar_node_config';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      const { role } = props.rootStore.authStore;
      this.deleteNode = this.deleteNode.bind(this);
      let notification = null;
      NotificationSystem.newInstance({}, n => notification = n);
      this.showNotification = (title, color, message) => {
        notification.notice({
          content: <BasicNotification
            title={title}
            message={message}
            color={color}
          />,
          duration: 3,
          closable: true,
          style: { top: 0 },
          className: 'left-up',
        });
      };
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.config_form.key,
            field: 'key',
            editable: false,
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.config_form.value,
            field: 'value_param',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            editable(params) {
              return params.node.data.key !== 'Host';
            },
            width: 60,
            minWidth: 60,
          },
        ],
        columnDefsSystem: [
          {
            headerName: literals.forms.config_form.key,
            field: 'key',
            editable: false,
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.config_form.value,
            field: 'value_param',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            editable(params) {
              return (role === 'admin' && params.node.data.key === 'IP access') || params.node.data.key === 'Password';
            },
            width: 60,
            minWidth: 60,
          },
        ],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.owner + data.key + data.type_value;
        },
        cellEditUpdate1: false,
        cellEditUpdate2: false,
        cellEditUpdate3: false,
        height1: 200,
        height2: 200,
        height3: 200,
        headerHeight: 16,
        visible: false,
        destroyOnClose: false,
        nodeConfigShow: false,
        activeTab: '1',
        rowData1: [],
        rowData2: [],
        rowData3: [],
        rowOldData1: [],
        rowOldData2: [],
        rowOldData3: [],
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this[`gridColumnApi${this.state.activeTab}`]) {
        const { literals } = this.props;
        if (literals.forms.config_form.key !== prevProps.literals.forms.config_form.key) {
          this[`gridColumnApi${this.state.activeTab}`].getColumn('key').getColDef().headerName
            = literals.forms.config_form.key;
          this[`gridColumnApi${this.state.activeTab}`].getColumn('value_param').getColDef().headerName
            = literals.forms.config_form.value;
          this[`gridApi${this.state.activeTab}`].refreshHeader();
        }
      }
    }

    onGridReady(params) {
      this[`gridApi${this.state.activeTab}`] = params.api;
      this[`gridColumnApi${this.state.activeTab}`] = params.columnApi;
      this[`gridApi${this.state.activeTab}`].showLoadingOverlay();
      this.onGridSizeChanged(params);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/security/config?page=taker&name=${this.props.node.data.name}`)
        .then((response) => {
          this.setState({ rowData1: response.data.FeedingConfig });
          this.setState({ rowData2: response.data.TradingConfig });
          this.setState({ rowData3: response.data.BothConfig });
          this.setState({ rowData4: response.data.SystemConfig });
          this.setState({ rowOldData1: JSON.parse(JSON.stringify(response.data.FeedingConfig)) });
          this.setState({ rowOldData2: JSON.parse(JSON.stringify(response.data.TradingConfig)) });
          this.setState({ rowOldData3: JSON.parse(JSON.stringify(response.data.BothConfig)) });
          this.setState({ rowOldData4: JSON.parse(JSON.stringify(response.data.SystemConfig)) });
        })
        .catch(() => {
        });
    }

    onCellClass=params => typeof this.stylesToUpdate[this.state.activeTab][params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[this.state.activeTab][params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[this.state.activeTab][params.node.id] === 'undefined') {
          this.stylesToUpdate[this.state.activeTab][params.node.id] = {};
          this[`gridApi${this.state.activeTab}`].refreshCells({
            rowNodes: [params.node],
            columns: ['owner'],
            force: true,
          });
        }
        this.stylesToUpdate[this.state.activeTab][params.node.id][params.colDef.field] = {};
        this[`gridApi${this.state.activeTab}`].refreshCells({
          rowNodes: [params.node],
          columns: [params.column],
          force: true,
        });

        this.rowsToUpdate[this.state.activeTab][`${params.node.id}`] = params.data;
        this.setState({ [`cellEditUpdate${this.state.activeTab}`]: true });
      }
    };

    onGridSizeChanged=(params) => {
      let height = ((this.state[`rowData${this.state.activeTab}`] ?
        this.state[`rowData${this.state.activeTab}`].length : 0) * 20) + 50 + this.state.headerHeight;
      const wHeight = window.innerHeight / 1.2;
      if (height > wHeight) {
        height = wHeight;
      }
      this.setState({ [`height${this.state.activeTab}`]: height });
      onGridSizeChanged(params, this.gridWrapperID + this.state.activeTab, this.myGridID + this.state.activeTab);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        const minHeight = onColumnResized(params, this.myGridID + this.state.activeTab);
        this.setState({ headerHeight: minHeight });
      }, 0);
    };

    onClick = () => {
      this.setState({
        visible: true,
      });
    };
    onClose = () => {
      this.setState({
        visible: false,
      });
    };

    updateConfig=() => {
      this.props.context.showLoader();
      let i = 0;
      let j = 0;
      const successItems = [];
      const failItems = [];
      const errorItems = [];
      const rowData = _.keyBy(this.state[`rowOldData${this.state.activeTab}`], o => o.owner + o.key + o.type);
      if (this.state.activeTab === '4') { // System@UniFeeder
        if (Object.keys(this.rowsToUpdate[this.state.activeTab]).length > 0) {
          const newValues = Object.assign({}, Object.values(this.rowsToUpdate[this.state.activeTab])[0]);
          Object.values(this.rowsToUpdate[this.state.activeTab])
            .forEach((values) => {
              if (values.key === 'IP access') {
                newValues.value_param = newValues.value_param_dummy;
                newValues.description = values.value_param;
              } else if (values.key === 'Password') {
                newValues.value_param = values.value_param;
                newValues.value_param_dummy = values.value_param;
              }
            });
          this.rowsToUpdate[this.state.activeTab] = { [newValues.owner + newValues.key + newValues.type]: newValues };
        }
      }
      const len = Object.keys(this.rowsToUpdate[this.state.activeTab]).length;
      Object.values(this.rowsToUpdate[this.state.activeTab]).forEach((values) => {
        const oldRow = rowData[values.owner + values.key + values.type];
        const dataToUpdate = {
          owner: values.owner,
          key: this.state.activeTab === '4' ? this.props.node.data.name : values.key,
          type: parseInt(values.type, 10),
          value_param: values.value_param,
          description: values.description,
          hidden: parseInt(values.hidden, 10),
          old_data: {
            owner: oldRow.owner,
            key: this.state.activeTab === '4' ? this.props.node.data.name : oldRow.key,
            type: parseInt(oldRow.type, 10),
            value_param: this.state.activeTab === '4' ? oldRow.value_param_dummy : oldRow.value_param,
            description: oldRow.description,
            hidden: parseInt(oldRow.hidden, 10),
          },
        };
        axios.put(`${process.env.REACT_APP_BACKEND_API}/security/config`, JSON.stringify(dataToUpdate))
          .then((response) => {
            i += 1;
            if (response.data.status) {
              successItems.push(values.owner);
            } else {
              failItems.push(values.owner);
            }
            if (len === i) {
              if (successItems.length > 0) {
                this.showNotification(
                  this.props.literals.forms.config_form.title_update, 'primary',
                  `${this.props.literals.forms.success_message} - ${successItems.join(', ')}`,
                );
              }
              if (failItems.length > 0) {
                this.showNotification(
                  this.props.literals.forms.config_form.title_update, 'danger',
                  `${this.props.literals.forms.fail_message} - ${failItems.join(', ')}`,
                );
              }
              this.resetLocalConfig();
              this.resetConfig();
            }
          })
          .catch((error) => {
            j += 1;
            errorItems.push(values.owner);
            if (len === j) {
              if (errorItems.length > 0) {
                this.showNotification(
                  this.props.literals.forms.config_form.title_update,
                  'danger', `${error.message} - ${errorItems.join(', ')}`,
                );
              }
              this.resetLocalConfig();
              this.resetConfig();
            }
          });
      });
    };

    resetLocalConfig=() => {
      this.rowsToUpdate[this.state.activeTab] = {};
      this.setState({ [`cellEditUpdate${this.state.activeTab}`]: false });
      const stylesToUpdate = { ...this.stylesToUpdate[this.state.activeTab] };
      this.stylesToUpdate[this.state.activeTab] = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this[`gridApi${this.state.activeTab}`].getRowNode(keys);
        if (node) {
          this[`gridApi${this.state.activeTab}`].refreshCells({ rowNodes: [node], columns: ['owner'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this[`gridApi${this.state.activeTab}`].refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    resetConfig=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/security/config?page=taker&name=${this.props.node.data.name}`)
        .then((response) => {
          this.setState({ rowData1: response.data.FeedingConfig });
          this.setState({ rowData2: response.data.TradingConfig });
          this.setState({ rowData3: response.data.BothConfig });
          this.setState({ rowData4: response.data.SystemConfig });
          this.setState({ rowOldData1: JSON.parse(JSON.stringify(response.data.FeedingConfig)) });
          this.setState({ rowOldData2: JSON.parse(JSON.stringify(response.data.TradingConfig)) });
          this.setState({ rowOldData3: JSON.parse(JSON.stringify(response.data.BothConfig)) });
          this.setState({ rowOldData4: JSON.parse(JSON.stringify(response.data.SystemConfig)) });
          this.props.context.hideLoader();
        })
        .catch(() => {
          this.props.context.hideLoader();
        });
    };

    toggleNodeConfig = () => {
      this.setState({ nodeConfigShow: !this.state.nodeConfigShow });
    };

    deleteNode() {
      this.props.context.showLoader();
      this.onClose();
      const dataToDelete = {
        name: this.props.node.data.name,
        // Below fields are only for Audit Log (not for Delete)
        type: parseInt(this.props.node.data.type, 10),
        state: parseInt(this.props.node.data.state, 10),
        description: this.props.node.data.description,
        ip_list: this.props.node.data.ip_list,
      };
      axios.post(`${process.env.REACT_APP_BACKEND_API}/node/delete`, JSON.stringify(dataToDelete))
        .then((response) => {
          if (response.data.status) {
            this.showNotification(
              this.props.literals.forms.node_form.title_delete, 'primary',
              this.props.literals.forms.success_message,
            );
            this.props.context.resetNode();
          } else {
            this.showNotification(
              this.props.literals.forms.node_form.title_delete, 'danger',
              this.props.literals.forms.fail_message,
            );
            this.props.context.hideLoader();
          }
        })
        .catch((error) => {
          this.showNotification(this.props.literals.forms.node_form.title_delete, 'danger', error.message);
          this.props.context.hideLoader();
        });
    }

    nodeConfig=() => {
      this.toggleNodeConfig();
    };

    exportFixConfig=() => {
      this.props.context.showLoader();
      const qs = `node=${this.props.node.data.name}&type=${this.props.node.data.type}`;
      axios.get(
        `${process.env.REACT_APP_BACKEND_API}/node/export_fix_config?${qs}`,
        { responseType: 'blob' },
      )
        .then((response) => {
          FileDownload(response.data, `${this.props.node.data.name}_FIX.txt`);
          this.props.context.hideLoader();
        })
        .catch(() => {
          this.props.context.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    render() {
      const { literals, theme } = this.props;
      let height = ((this.state[`rowData${this.state.activeTab}`] ?
        this.state[`rowData${this.state.activeTab}`].length : 0) * 20) + 50 + this.state.headerHeight;
      const wHeight = window.innerHeight / 1.2;
      if (height > wHeight) {
        height = wHeight;
      }
      this.setState({ [`height${this.state.activeTab}`]: height });
      const gridWrapper = this.gridWrapperID + this.state.activeTab;
      const myGrid = this.myGridID + this.state.activeTab;
      let dialog;
      if (this.state.visible || !this.state.destroyOnClose) {
        dialog = (
          <Dialog
            visible={this.state.visible}
            wrapClassName="center"
            animation="slide-fade"
            maskAnimation="fade"
            onClose={this.onClose}
            style={{ width: 276 }}
            title={literals.forms.confirm_delete}
          >
            <p>{literals.forms.confirm_delete_note} {this.props.node.data.name}?</p>
            <div style={{
                        marginTop: 10,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '68%',
                    }}
            >
              <ButtonToolbar>
                <Button
                  className="icon"
                  color="primary"
                  key="close"
                  onClick={this.onClose}
                >
                  {literals.forms.cancel}
                </Button>
                <Button
                  className="icon"
                  color="primary"
                  key="save"
                  onClick={this.deleteNode}
                >
                  {literals.forms.delete}
                </Button>
              </ButtonToolbar>
            </div>
          </Dialog>
        );
      }
      const { role } = this.props.rootStore.authStore;
      const showDelete = role === 'admin' || (role !== 'admin' && (this.props.node.data.type_value === 'FIX'
        || this.props.node.data.type_value === 'DROPCOPY'));
      const showExportFixConfig = this.props.node.data.type_value === 'FIX'
        || this.props.node.data.type_value === 'DROPCOPY';
      return (
        <span>
          <ButtonToolbar>
            {showDelete &&
            <Tooltip title={literals.tables.ag_table.delete}>
              <Button
                style={{ height: 14 }}
                id="NodeDeleteClick"
                onClick={this.onClick}
                className="icon"
                color="primary"
              ><p
                style={{ lineHeight: 0 }}
                className="fa fa-trash-alt"
              />
              </Button>
            </Tooltip>
            }
            <Tooltip title={literals.sidebar.config}>
              <Button
                style={{ height: 14 }}
                id="NodeConfigClick"
                onClick={this.nodeConfig}
                className="icon"
                color="primary"
              ><p
                style={{ lineHeight: 0 }}
                className="fas fa-sliders-h"
              />
              </Button>
            </Tooltip>
            {showExportFixConfig &&
            <Tooltip title={literals.forms.node_form.export_fix_config}>
              <Button
                style={{ height: 14 }}
                onClick={this.exportFixConfig}
                className="icon"
                color="primary"
              ><p
                style={{ lineHeight: 0 }}
                className="far fa-file-alt"
              />
              </Button>
            </Tooltip>
            }
          </ButtonToolbar>
          {dialog}
          <Modal
            isOpen={this.state.nodeConfigShow}
            toggle={() => this.toggleNodeConfig}
            className={`modal-dialog--primary modal-dialog--header crp-modal ${theme.className}`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleNodeConfig()} />
              <h4 className="bold-text  modal__title">{literals.sidebar.config} - {this.props.node.data.name}</h4>
            </div>
            <div className="modal__body risk_account_modal_body">
              <div className="tabs tabs--bordered-top tabs__custom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        {literals.forms.node_form.feeding}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                         this.toggle('2');
                       }}
                      >
                        {literals.forms.node_form.trading}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => {
                         this.toggle('3');
                       }}
                      >
                        {literals.forms.node_form.both}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => {
                          this.toggle('4');
                        }}
                      >
                        {literals.forms.node_form.feeder}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    {this.state.activeTab === '1' &&
                    <TabPane tabId="1">
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                 height: 20,
                                 marginTop: 13,
                                 display: this.state[`cellEditUpdate${this.state.activeTab}`] ? '' : 'none',
                               }}
                              onClick={this.updateConfig}
                              className="icon"
                              color="primary"
                            ><p style={{ lineHeight: 0 }} className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                 height: 20,
                                 marginTop: 13,
                                 display: this.state[`cellEditUpdate${this.state.activeTab}`] ? '' : 'none',
                               }}
                              onClick={() => {
                                 this.props.context.showLoader();
                                 this.resetLocalConfig();
                                 this.resetConfig();
                               }}
                              className="icon"
                              color="primary"
                            ><p
                              style={{ lineHeight: 0 }}
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>
                      <div
                        id={gridWrapper}
                        style={{
                         width: '100%',
                         height: '100%',
                         clear: 'both',
                       }}
                      >
                        <div
                          id={myGrid}
                          style={{
                             boxSizing: 'border-box',
                             height: this.state[`height${this.state.activeTab}`],
                             width: '100%',
                           }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state[`rowData${this.state.activeTab}`]}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                          />
                        </div>
                      </div>
                    </TabPane>
                     }
                    {this.state.activeTab === '2' &&
                    <TabPane tabId="2">
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                height: 20,
                                marginTop: 13,
                                display: this.state[`cellEditUpdate${this.state.activeTab}`] ? '' : 'none',
                              }}
                              onClick={this.updateConfig}
                              className="icon"
                              color="primary"
                            ><p style={{ lineHeight: 0 }} className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                height: 20,
                                marginTop: 13,
                                display: this.state[`cellEditUpdate${this.state.activeTab}`] ? '' : 'none',
                              }}
                              onClick={() => {
                                this.props.context.showLoader();
                                this.resetLocalConfig();
                                this.resetConfig();
                              }}
                              className="icon"
                              color="primary"
                            ><p
                              style={{ lineHeight: 0 }}
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>
                      <div
                        id={gridWrapper}
                        style={{
                        width: '100%',
                        height: '100%',
                        clear: 'both',
                      }}
                      >
                        <div
                          id={myGrid}
                          style={{
                            boxSizing: 'border-box',
                            height: this.state[`height${this.state.activeTab}`],
                            width: '100%',
                          }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state[`rowData${this.state.activeTab}`]}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                          />
                        </div>
                      </div>
                    </TabPane>
                    }
                    {this.state.activeTab === '3' &&
                    <TabPane tabId="3">
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                height: 20,
                                marginTop: 13,
                                display: this.state[`cellEditUpdate${this.state.activeTab}`] ? '' : 'none',
                              }}
                              onClick={this.updateConfig}
                              className="icon"
                              color="primary"
                            ><p style={{ lineHeight: 0 }} className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                height: 20,
                                marginTop: 13,
                                display: this.state[`cellEditUpdate${this.state.activeTab}`] ? '' : 'none',
                              }}
                              onClick={() => {
                                this.props.context.showLoader();
                                this.resetLocalConfig();
                                this.resetConfig();
                              }}
                              className="icon"
                              color="primary"
                            ><p
                              style={{ lineHeight: 0 }}
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>
                      <div
                        id={gridWrapper}
                        style={{
                        width: '100%',
                        height: '100%',
                        clear: 'both',
                      }}
                      >
                        <div
                          id={myGrid}
                          style={{
                            boxSizing: 'border-box',
                            height: this.state[`height${this.state.activeTab}`],
                            width: '100%',
                          }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state[`rowData${this.state.activeTab}`]}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                          />
                        </div>
                      </div>
                    </TabPane>
                    }
                    {this.state.activeTab === '4' &&
                    <TabPane tabId="4">
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                height: 20,
                                marginTop: 13,
                                display: this.state[`cellEditUpdate${this.state.activeTab}`] ? '' : 'none',
                              }}
                              onClick={this.updateConfig}
                              className="icon"
                              color="primary"
                            ><p style={{ lineHeight: 0 }} className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                height: 20,
                                marginTop: 13,
                                display: this.state[`cellEditUpdate${this.state.activeTab}`] ? '' : 'none',
                              }}
                              onClick={() => {
                                this.props.context.showLoader();
                                this.resetLocalConfig();
                                this.resetConfig();
                              }}
                              className="icon"
                              color="primary"
                            ><p
                              style={{ lineHeight: 0 }}
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>
                      <div
                        id={gridWrapper}
                        style={{
                          width: '100%',
                          height: '100%',
                          clear: 'both',
                        }}
                      >
                        <div
                          id={myGrid}
                          style={{
                            boxSizing: 'border-box',
                            height: this.state[`height${this.state.activeTab}`],
                            width: '100%',
                          }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefsSystem}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state[`rowData${this.state.activeTab}`]}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                          />
                        </div>
                      </div>
                    </TabPane>
                    }
                  </TabContent>
                </div>
              </div>
            </div>
            <div className="modal__footer" />
          </Modal>
        </span>
      );
    }
}

export default connect(state => ({
  literals: state.literals,
  theme: state.theme,
}))(withRouter(NodeActionRenderer));
