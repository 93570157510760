import React, { PureComponent } from 'react';
import 'rc-tree-select/assets/index.css';
import TreeSelect from 'rc-tree-select';
import PropTypes from 'prop-types';

export default class TakerMakerEditor extends PureComponent {
    static propTypes = {
      context: PropTypes.instanceOf(Object).isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      value: PropTypes.string,
    };
    static defaultProps = {
      value: null,
    };
    state = {
      selectedOption: this.props.value ? this.props.value.split(',') : null,
    };
    getValue() {
      return this.state.selectedOption ? this.state.selectedOption.join() : null;
    }
    isPopup=() => true;
    handleChange = (selectedOption) => {
      this.setState({ selectedOption });
    };
    switcherIcon = (props) => {
      if (props.isLeaf) {
        return '';
      }
      const color = '#70bbfd';
      if (props.expanded) {
        return <span className="fa fa-caret-down" style={{ color, fontSize: 16 }} />;
      }
      return <span className="fa fa-caret-right" style={{ color, fontSize: 16 }} />;
    };
    clearIcon = () => {
      const color = '#70bbfd';
      return <span className="fa fa-times" style={{ color, fontSize: 16 }} />;
    };
    render() {
      const { selectedOption } = this.state;
      const theme = this.props.context.theme.className;
      return (
        <TreeSelect
          getPopupContainer={triggerNode => triggerNode.parentNode}
          placeholder="Click to open the list"
          value={selectedOption}
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 100, overflow: 'auto' }}
          treeData={this.props.node.data.category === 0 ?
            this.props.context.formData.Taker :
            this.props.context.formData.Maker}
          treeLine
          treeCheckable
          onChange={this.handleChange}
          className={theme === 'theme-light' ?
            'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
          dropdownClassName={theme === 'theme-light' ?
            'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          switcherIcon={this.switcherIcon}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          autoClearSearchValue={false}
          allowClear
          clearIcon={this.clearIcon}
        />
      );
    }
}
