/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import { LiteralENGlobalProps, LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import { regExSignedDecimalNumber, shallowEqual, validateSessions, regExDepthLevels, parseSessions } from '../../shared/helper';
import UploadData from '../../shared/components/UploadData';

let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};
class AggregateSymbolUpload extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
    resetAggregateGroup: PropTypes.func.isRequired,
    rowOldData: PropTypes.instanceOf(Object).isRequired,
  };

  getHeaderName=(header) => {
    const { literalsENGlobal } = this.props;
    switch (header) {
      case literalsENGlobal.forms.aggregate_symbol_form.symbol:
        return { col: 'symbol_val', type: 'text' };
      case literalsENGlobal.forms.aggregate_symbol_form.aggregate_group:
        return { col: 'aggregate_group', type: 'text' };
      case literalsENGlobal.forms.aggregate_symbol_form.lp_sessions:
        return { col: 'lp_sessions', type: 'text' };
      case literalsENGlobal.forms.aggregate_symbol_form.sessions:
        return { col: 'sessions', type: 'text' };
      case literalsENGlobal.forms.aggregate_symbol_form.exec_mode:
        return { col: 'exec_mode_value', type: 'text' };
      case literalsENGlobal.forms.aggregate_symbol_form.exec_boost:
        return { col: 'exec_boost_value', type: 'text' };
      case literalsENGlobal.forms.aggregate_symbol_form.state:
        return { col: 'state_value', type: 'text' };
      case literalsENGlobal.forms.aggregate_symbol_form.filter_factor:
        return { col: 'filter_factor', type: 'number' };
      case literalsENGlobal.forms.aggregate_symbol_form.depth_levels:
        return { col: 'depth_levels', type: 'text' };

      default:
        return { col: header, type: 'text' };
    }
  };

  postMultiple = (files, allFiles, rawData, showLoader, hideLoader) => {
    try {
      showLoader();
      const bulkData = [];
      const errors = [];
      const arr = this.props.rowOldData.reduce((pV, cV) => [...pV, ...cV.aggregate_symbols], []);
      const rowData = _.keyBy(arr, o => o.aggregate_group + o.symbol_val);
      rawData.forEach((values) => {
        const symbolAndAggregateGroup = `${_.trim(values.symbol_val)}->${_.trim(values.aggregate_group)}`;
        const aggregateGroup = this.props.formData.AggregateGroupObj[values.aggregate_group];
        if (!aggregateGroup) {
          errors.push({
            symbol: symbolAndAggregateGroup,
            error:
                `${this.props.literals.forms.invalid} ` +
                `${this.props.literals.forms.aggregate_symbol_form.aggregate_group}`,
          });
        }
        const lpSessionsVal = values.lp_sessions.split(',').map(item => item.trim());
        lpSessionsVal.forEach((value) => {
          const lpSession = this.props.formData.LPSessionObj[value];
          if (!lpSession) {
            errors.push({
              symbol: symbolAndAggregateGroup,
              error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.aggregate_symbol_form.lp_sessions}`,
            });
          }
        });
        const lpSessions = lpSessionsVal.join();
        if (!validateSessions(values.sessions)) {
          errors.push({
            symbol: symbolAndAggregateGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.aggregate_symbol_form.sessions}`
          });
        } else {
          values.sessions = parseSessions(_.trim(values.sessions));
        }
        const execModeVal = this.props.formData.ExecModeObj[values.exec_mode_value];
        if (!execModeVal) {
          errors.push({
            symbol: symbolAndAggregateGroup,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.aggregate_symbol_form.exec_mode}`,
          });
        }
        const execMode = execModeVal ? execModeVal.value : 0;
        const execBoostVal = this.props.formData.BoolObj[values.exec_boost_value];
        if (!execBoostVal) {
          errors.push({
            symbol: symbolAndAggregateGroup,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.aggregate_symbol_form.exec_boost}`,
          });
        }
        const execBoost = execBoostVal ? execBoostVal.value : 0;
        const stateVal = this.props.formData.StateObj[values.state_value];
        if (!stateVal) {
          errors.push({
            symbol: symbolAndAggregateGroup,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.aggregate_symbol_form.state}`,
          });
        }
        const state = stateVal ? stateVal.value : 0;
        if (!regExSignedDecimalNumber.test(values.filter_factor)) {
          errors.push({
            symbol: symbolAndAggregateGroup,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.aggregate_symbol_form.filter_factor}`,
          });
        }
        if (values.depth_levels !== '' && !regExDepthLevels.test(values.depth_levels)) {
          errors.push({
            symbol: symbolAndAggregateGroup,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.aggregate_symbol_form.depth_levels}`,
          });
        }
        const oldRow = rowData[values.aggregate_group + values.symbol_val];
        if (!oldRow) {
          errors.push({
            symbol: symbolAndAggregateGroup,
            error:
                `${this.props.literals.forms.invalid}`,
          });
        } else {
          const newData = {
            symbol_val: _.trim(values.symbol_val),
            security: oldRow.security,
            aggregate_group: _.trim(values.aggregate_group),
            lp_sessions: lpSessions,
            sessions: _.trim(values.sessions),
            exec_mode: parseInt(execMode, 10),
            exec_boost: parseInt(execBoost, 10),
            state: parseInt(state, 10),
            filter_factor: parseFloat(values.filter_factor),
            depth_levels: _.trim(values.depth_levels),
          };
          const oldData = {
            symbol_val: oldRow.symbol_val,
            security: oldRow.security,
            aggregate_group: oldRow.aggregate_group,
            lp_sessions: oldRow.lp_sessions,
            sessions: oldRow.sessions,
            exec_mode: parseInt(oldRow.exec_mode, 10),
            exec_boost: parseInt(oldRow.exec_boost, 10),
            state: parseInt(oldRow.state, 10),
            filter_factor: parseFloat(oldRow.filter_factor),
            depth_levels: oldRow.depth_levels,
          };
          const dataToUpdate = {
            ...newData,
            old_data: {
              ...oldData,
            },
          };
          if (!shallowEqual(newData, oldData)) {
            bulkData.push(dataToUpdate);
          }
        }
      });
      if (errors.length > 0) {
        showNotification(
          this.props.literals.sidebar.aggregate_symbol, 'danger',
          JSON.stringify(errors), null,
        );
        hideLoader();
        return;
      }
      axios.put(
        `${process.env.REACT_APP_BACKEND_API}/security/aggregate_symbol_bulk`,
        JSON.stringify(bulkData),
      )
        .then((response) => {
          showNotification(
            this.props.literals.sidebar.aggregate_symbol, 'primary',
            this.props.literals.forms.completed_message, 3,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.aggregate_symbol, 'danger',
              `${this.props.literals.forms.uni_symbol_form.symbols_failed} ${response.data.toString()} `, null,
            );
          }
          allFiles.forEach(f => f.remove());
          this.props.resetAggregateGroup();
          hideLoader();
        })
        .catch(() => {
          allFiles.forEach(f => f.remove());
          showNotification(
            this.props.literals.sidebar.aggregate_symbol, 'danger',
            this.props.literals.forms.fail_message, 3,
          );
          this.props.resetAggregateGroup();
          hideLoader();
        });
    } catch (e) {
      showNotification(
        this.props.literals.sidebar.aggregate_symbol, 'danger',
        e.message, null,
      );
      hideLoader();
    }
  }

  render() {
    return (
      <UploadData
        tableID="sidebar_aggregate_symbol_import"
        title={this.props.literals.sidebar.aggregate_symbol}
        getHeaderName={this.getHeaderName}
        postMultiple={this.postMultiple}
      />
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal,
}))(AggregateSymbolUpload);
