import { SyncTrunk } from 'mobx-sync';
import { action } from 'mobx';
import ClientStore from './ClientStore';
import AuthStore from './AuthStore';
import SettingStore from './SettingStore';
import ReportStore from './ReportStore';

class RootStore {
    clientStore = ClientStore;
    authStore = AuthStore;
    settingStore = SettingStore;
    reportStore = ReportStore;
    @action
    reset() {
      this.reportStore.reset();
      this.settingStore.reset();
      this.authStore.reset();
      this.clientStore.reset();
    }
}
const rootStore = new RootStore();
export default rootStore;
const trunk = new SyncTrunk(rootStore, { storage: localStorage });
trunk.init();
