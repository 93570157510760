import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({
  title, titleEN, id, icon, iconStyle, newLink, route, onClick, display,
}) => {
  if (display) {
    return (
      <NavLink
        to={route}
        onClick={onClick}
        activeClassName="sidebar__link-active"
      >
        <li className="sidebar__link">
          {icon ? <i className={`${iconStyle} fa-${icon} media-icon-child`} /> : ''}

          <p id={id} data-title={titleEN} className="sidebar__link-title icon-link">
            {title}
            {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
          </p>
        </li>
      </NavLink>);
  }
  return (null);
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  titleEN: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  display: PropTypes.bool,
};

SidebarLink.defaultProps = {
  icon: '',
  iconStyle: 'fa',
  newLink: false,
  route: '/',
  onClick: () => {},
  display: true,
};

export default SidebarLink;
