/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ConfigActionRenderer from './actionRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import ConfigForm from './form';
import configAction from './action';
import Loader from '../../shared/components/Loader';
import StateRenderer from '../../shared/components/StateRenderer';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class Config extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.stylesToUpdate = {};
      const { literals } = this.props;
      const tableID = 'sidebar_config';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.config_form.owner,
            field: 'owner',
            editable: false,
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 90,
            minWidth: 90,
          },
          {
            headerName: literals.forms.config_form.key,
            field: 'key',
            editable: false,
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.config_form.type,
            field: 'type_value',
            editable: false,
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.config_form.value,
            field: 'value_param',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.config_form.description,
            field: 'description',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.config_form.hidden,
            field: 'hidden_value',
            editable: false,
            cellRenderer: 'stateRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 30,
            minWidth: 30,
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
            width: 30,
            minWidth: 30,
          },
        ],
        frameworkComponents: {
          actionRenderer: ConfigActionRenderer,
          stateRenderer: StateRenderer,
        },
        rowData: [],
        rowOldData: [],
        formData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.owner + data.key + data.type_value;
        },
        cellEditUpdate: false,
        activeTab: '1',
        height: window.innerHeight,
        loaderShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.config_form.owner !== prevProps.literals.forms.config_form.owner) {
          this.gridColumnApi.getColumn('owner').getColDef().headerName = literals.forms.config_form.owner;
          this.gridColumnApi.getColumn('key').getColDef().headerName = literals.forms.config_form.key;
          this.gridColumnApi.getColumn('value_param').getColDef().headerName = literals.forms.config_form.value;
          this.gridColumnApi.getColumn('type_value').getColDef().headerName
              = literals.forms.config_form.type;
          this.gridColumnApi.getColumn('description').getColDef().headerName
              = literals.forms.config_form.description;
          this.gridColumnApi.getColumn('hidden_value').getColDef().headerName
            = literals.forms.config_form.hidden;
          this.gridColumnApi.getColumn('actions').getColDef().headerName = literals.tables.ag_table.actions;
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.onGridSizeChanged(params);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/config`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.Config)) });
        })
        .catch(() => {
        });
    }

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridApi.refreshCells({ rowNodes: [params.node], columns: ['owner'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridApi.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'hidden_value') {
          params.data.hidden = this.state.formData.StateObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['owner', 'key', 'value_param', 'type_value', 'description', 'hidden_value'],
        fileName: 'Config.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['owner', 'key', 'value_param', 'type_value', 'description', 'hidden_value'],
        fileName: 'Config.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    updateData = (data) => {
      this.setState({ rowData: data.Config });
      this.setState({
        formData: {
          Type: data.Type,
          TypeObj: arrayToObject(data.Type, 'value'),
          State: data.State,
          StateObj: arrayToObject(data.State, 'label'),
        },
      });
    };

    updateConfig=() => {
      this.showLoader();
      const len = Object.keys(this.rowsToUpdate).length;
      let i = 0;
      let j = 0;
      const successItems = [];
      const failItems = [];
      const errorItems = [];
      const rowData = _.keyBy(this.state.rowOldData, o => o.owner + o.key + o.type);
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.owner + values.key + values.type];
        const dataToUpdate = {
          owner: values.owner,
          key: values.key,
          type: parseInt(values.type, 10),
          value_param: values.value_param,
          description: values.description,
          hidden: parseInt(values.hidden, 10),
          old_data: {
            owner: oldRow.owner,
            key: oldRow.key,
            type: parseInt(oldRow.type, 10),
            value_param: oldRow.value_param,
            description: oldRow.description,
            hidden: parseInt(oldRow.hidden, 10),
          },
        };
        axios.put(`${process.env.REACT_APP_BACKEND_API}/admin/config`, JSON.stringify(dataToUpdate))
          .then((response) => {
            i += 1;
            if (response.data.status) {
              successItems.push(values.owner);
            } else {
              failItems.push(values.owner);
            }
            if (len === i) {
              if (successItems.length > 0) {
                showNotification(
                  this.props.literals.forms.config_form.title_update, 'primary',
                  `${this.props.literals.forms.success_message} - ${successItems.join(', ')}`,
                );
              }
              if (failItems.length > 0) {
                showNotification(
                  this.props.literals.forms.config_form.title_update, 'danger',
                  `${this.props.literals.forms.fail_message} - ${failItems.join(', ')}`,
                );
              }
              this.resetLocalConfig();
              this.resetConfig();
            }
          })
          .catch((error) => {
            j += 1;
            errorItems.push(values.owner);
            if (len === j) {
              if (errorItems.length > 0) {
                showNotification(
                  this.props.literals.forms.config_form.title_update,
                  'danger', `${error.message} - ${errorItems.join(', ')}`,
                );
              }
              this.resetLocalConfig();
              this.resetConfig();
            }
          });
      });
    };

    resetLocalConfig=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({ rowNodes: [node], columns: ['owner'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridApi.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    resetConfig=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/config`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.Config)) });
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--bordered-top tabs__custom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                                        this.toggle('1');
                                    }}
                      >
                        {literals.sidebar.config}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                                        this.toggle('2');
                                    }}
                      >
                        {literals.forms.config_form.title_create}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="outer-grid-label">
                        {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                      </div>
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={this.updateConfig}
                              className="icon"
                              color="primary"
                            ><p className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={() => { this.showLoader(); this.resetLocalConfig(); this.resetConfig(); }}
                              className="icon"
                              color="primary"
                            ><p
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_excel}>
                            <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                              <p className="fa fa-file-excel" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_csv}>
                            <Button
                              onClick={this.onBtExportCSV}
                              className="icon"
                              color="primary"
                            >
                              <p className="fa fa-file-alt" />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>
                      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                        <div
                          id={this.myGridID}
                          style={{
                                    boxSizing: 'border-box',
                                    height: this.state.height - REDUCE_HEIGHT,
                                    width: '100%',
                                }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state.rowData}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                            context={{
                              formData: this.state.formData,
                              resetConfig: this.resetConfig,
                              showLoader: this.showLoader,
                              hideLoader: this.hideLoader,
                            }}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <ConfigForm
                        {...this.props}
                        state={{
                                        formData: this.state.formData,
                                        resetConfig: this.resetConfig,
                                        toggle: this.toggle,
                                        showLoader: this.showLoader,
                                        hideLoader: this.hideLoader,
                                    }}
                        onSubmit={configAction}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(Config);
