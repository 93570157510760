const pushTickValidate = (values, props) => {
  const errors = {};
  if (!values.bid && values.bid !== 0) {
    errors.bid = `${props.literals.tables.trading_table.bid} ${props.literals.forms.required_validate}`;
  }
  if (!values.ask && values.ask !== 0) {
    errors.ask = `${props.literals.tables.trading_table.ask} ${props.literals.forms.required_validate}`;
  }
  if (!values.bid_vol && values.bid_vol !== 0) {
    errors.bid_vol = `${props.literals.tables.trading_table.bid_volume} ${props.literals.forms.required_validate}`;
  }
  if (!values.ask_vol && values.ask_vol !== 0) {
    errors.ask_vol = `${props.literals.tables.trading_table.ask_vol} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default pushTickValidate;
