import React, { Component } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import storage from 'redux-persist/lib/storage';
import { LiteralProps } from '../../../shared/prop-types/ReducerProps';
import TopbarConnectionStatus from './TopbarConnectionStatus';
import TopbarClientName from './TopbarClientName';
import TopbarAggregatorRestart from './TopbarAggregatorRestart';
import TopbarVersion from './TopbarVersion';
import TopbarRTInfo from './TopbarRTInfo';
import TopbarNewUI from './TopbarNewUI';

const Ava = `${process.env.PUBLIC_URL}/user.png`;

@inject('rootStore')
@observer
class TopbarProfile extends Component {
  static propTypes = {
    literals: LiteralProps.isRequired,
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  logout = () => {
    axios
      .get(`${process.env.REACT_APP_FRONTEND_API}/logout`)
      .then(() => {
        this.props.rootStore.reset();
        storage.removeItem('persist:form');
        localStorage.clear();
        window.location.reload();
      })
      .catch(() => {});
  };

  ucFirst = (string) => {
    if (!string) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  profileClick = () => {
    this.props.history.push('/profile/user');
  };

  changePasswordClick = () => {
    this.props.history.push('/profile/change_password');
  };

  render() {
    const { literals } = this.props;
    const { role } = this.props.rootStore.authStore;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">
            {`${this.ucFirst(
              this.props.rootStore.authStore.user.first_name
            )} ${this.ucFirst(this.props.rootStore.authStore.user.last_name)}`}
          </p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && (
          <button className="topbar__back" onClick={this.toggle} />
        )}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <button
              className="topbar__link topbar__link__custom"
              onClick={() => {
                this.profileClick();
                this.toggle();
              }}
            >
              <span className="topbar__link-icon fa fa-user" />
              <p className="topbar__link-title">{literals.topbar.profile}</p>
            </button>
            <button
              className="topbar__link topbar__link__custom"
              onClick={() => {
                this.changePasswordClick();
                this.toggle();
              }}
            >
              <span className="topbar__link-icon fa fa-key" />
              <p className="topbar__link-title">
                {literals.topbar.change_password}
              </p>
            </button>
            <button
              className="topbar__link topbar__link__custom"
              onClick={() => {
                this.props.changeToLight();
                this.toggle();
              }}
            >
              <span className="topbar__link-icon fa fa-fill" />
              <p className="topbar__link-title">
                {literals.topbar.light_theme}
              </p>
            </button>
            <button
              className="topbar__link topbar__link__custom"
              onClick={() => {
                this.props.changeToDark();
                this.toggle();
              }}
            >
              <span className="topbar__link-icon fa fa-fill-drip" />
              <p className="topbar__link-title">{literals.topbar.dark_theme}</p>
            </button>
            <div className="topbar__menu-divider" />
            <button
              className="topbar__link topbar__link__custom"
              onClick={() => {
                this.logout();
                this.toggle();
              }}
            >
              <span className="topbar__link-icon fa fa-sign-out-alt" />
              <p className="topbar__link-title">{literals.topbar.log_out}</p>
            </button>
            <div className="topbar__menu-divider" />
            <TopbarClientName
              marginLeft={20}
              className="conn__status__mobile"
            />
            <TopbarNewUI marginLeft={20} className="conn__status__mobile" />
            {role === 'admin' && (
              <React.Fragment>
                <TopbarVersion
                  marginLeft={20}
                  className="conn__status__mobile"
                />
                <TopbarRTInfo
                  marginLeft={20}
                  className="conn__status__mobile"
                />
              </React.Fragment>
            )}
            <TopbarConnectionStatus
              marginLeft={11}
              className="conn__status__mobile"
            />
            {(role === 'admin' || role === 'broker') && (
              <TopbarAggregatorRestart
                marginLeft={11}
                className="conn__status__mobile"
              />
            )}
            <p
              className="topbar__link-title conn__status__mobile"
              style={{ marginLeft: 40 }}
            >
              {`${this.ucFirst(
                this.props.rootStore.authStore.user.first_name
              )} ${this.ucFirst(
                this.props.rootStore.authStore.user.last_name
              )}`}
            </p>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default connect((state) => ({
  literals: state.literals
}))(withRouter(TopbarProfile));
