/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import Websocket from 'react-websocket';
import _ from 'lodash';
import XLSX from 'xlsx';
import NotificationSystem from 'rc-notification';
import FileDownload from 'js-file-download';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';
import PositionSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import PositionActionRenderer from './actionRenderer';
import OTActionRenderer from './actionRendererOT';
import { BasicNotification } from '../../shared/components/Notification';
import Loader from '../../shared/components/Loader';

const exportColumnsAllAccount = ['position_id', 'symbol_val', 'party_symbol', 'account',
  'taker', 'taker_feed', 'riskaccount',
  'account_type_value', 'aggregate_group', 'net_volume_value', 'anet_volume_value', 'bnet_volume_value', 'avg_price',
  'aavg_price', 'bavg_price', 'last_time_value', 'close_price', 'pl', 'margin', 'notional',
  'base_exposure', 'quote_exposure', 'base_cur_exposure', 'quote_cur_exposure',
  'b_base_exposure', 'b_quote_exposure', 'b_base_cur_exposure', 'b_quote_cur_exposure',
  'swaps', 'commission', 'markup', 'margin_conv_rate', 'pl_conv_rate', 'sl_price', 'tp_price'];
const exportColumnsNormalAccount = ['position_id', 'symbol_val', 'party_symbol', 'account',
  'taker', 'taker_feed', 'account_type_value',
  'aggregate_group', 'net_volume_value', 'anet_volume_value', 'bnet_volume_value', 'avg_price',
  'aavg_price', 'bavg_price', 'last_time_value', 'close_price', 'pl', 'margin', 'notional',
  'base_exposure', 'quote_exposure', 'base_cur_exposure', 'quote_cur_exposure',
  'b_base_exposure', 'b_quote_exposure', 'b_base_cur_exposure', 'b_quote_cur_exposure',
  'markup', 'margin_conv_rate', 'pl_conv_rate', 'sl_price', 'tp_price'];
const exportColumnsRiskAccount = ['position_id', 'symbol_val', 'party_symbol', 'account',
  'taker', 'taker_feed', 'account_type_value', 'riskaccount',
  'aggregate_group', 'net_volume_value', 'anet_volume_value', 'bnet_volume_value', 'avg_price',
  'aavg_price', 'bavg_price', 'last_time_value', 'close_price', 'pl', 'margin', 'notional',
  'base_exposure', 'quote_exposure', 'base_cur_exposure', 'quote_cur_exposure',
  'b_base_exposure', 'b_quote_exposure', 'b_base_cur_exposure', 'b_quote_cur_exposure',
  'swaps', 'commission', 'markup', 'margin_conv_rate', 'pl_conv_rate', 'sl_price', 'tp_price'];
let exportColumns = [];
const detailExportColumns = ['sequence', 'positionid', 'cen_ord_id', 'price', 'vol_value', 'time_value'];
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class Position extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    search_values: PropTypes.instanceOf(Object),
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  static defaultProps = {
    search_values: {},
  };

  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_position';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: 'Pos ID',
          field: 'position_id',
          width: 40,
          minWidth: 40,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.symbol,
          field: 'symbol_val',
          headerTooltip: literals.tables.position_table.symbol,
          width: 70,
          minWidth: 70,
          cellRenderer: 'agGroupCellRenderer',
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.party_symbol,
          field: 'party_symbol',
          headerTooltip: literals.tables.position_table.party_symbol,
          width: 70,
          minWidth: 70,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.account,
          field: 'account',
          headerTooltip: literals.tables.position_table.account,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.taker,
          field: 'taker',
          headerTooltip: literals.tables.position_table.taker,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.taker_feed,
          field: 'taker_feed',
          headerTooltip: literals.tables.position_table.taker_feed,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.risk_account,
          field: 'riskaccount',
          headerTooltip: literals.tables.position_table.risk_account,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.account_type,
          field: 'account_type_value',
          headerTooltip: literals.tables.position_table.account_type,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.aggregate_group,
          field: 'aggregate_group',
          headerTooltip: literals.tables.position_table.aggregate_group,
          type: 'text',
        },
        {
          headerName: literals.tables.position_table.net_volume,
          field: 'net_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.net_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.anet_volume,
          field: 'anet_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.anet_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.bnet_volume,
          field: 'bnet_volume_value',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.bnet_volume,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.avg_price,
          field: 'avg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.avg_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.aavg_price,
          field: 'aavg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.aavg_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.bavg_price,
          field: 'bavg_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.bavg_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.last_time,
          field: 'last_time_value',
          headerTooltip: literals.tables.position_table.last_time,
          tooltipField: 'last_time_value',
          width: 105,
          minWidth: 105,
        },
        {
          headerName: literals.tables.position_table.close_price,
          field: 'close_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.close_price,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.pl,
          field: 'pl',
          cellClass: 'number',
          headerTooltip: literals.tables.position_table.pl,
          cellRenderer(params) {
            if (params.value < 0) {
              return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
            }
            return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
          },
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.margin,
          field: 'margin',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.margin,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.notional,
          field: 'notional',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.notional,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.base_exposure,
          field: 'base_exposure',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.base_exposure,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.quote_exposure,
          field: 'quote_exposure',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.quote_exposure,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.base_cur_exposure,
          field: 'base_cur_exposure',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.base_cur_exposure,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.quote_cur_exposure,
          field: 'quote_cur_exposure',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.quote_cur_exposure,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.b_base_exposure,
          field: 'b_base_exposure',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.b_base_exposure,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.b_quote_exposure,
          field: 'b_quote_exposure',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.b_quote_exposure,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.b_base_cur_exposure,
          field: 'b_base_cur_exposure',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.b_base_cur_exposure,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.b_quote_cur_exposure,
          field: 'b_quote_cur_exposure',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.b_quote_cur_exposure,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.swaps,
          field: 'swaps',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.swaps,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.commission,
          field: 'commission',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.commission,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.markup,
          field: 'markup',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.markup,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.margin_conv_rate,
          field: 'margin_conv_rate',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.margin_conv_rate,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.pl_conv_rate,
          field: 'pl_conv_rate',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.pl_conv_rate,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.sl_price,
          field: 'sl_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.sl_price,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.position_table.tp_price,
          field: 'tp_price',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerTooltip: literals.tables.position_table.tp_price,
          hide: true,
          type: 'number',
        },
        {
          headerName: literals.tables.ag_table.actions,
          field: 'actions',
          cellRenderer: 'actionRenderer',
          filter: false,
          sortable: false,
          width: 25,
          minWidth: 25,
        },
      ],
      detailCellRendererParams: {
        refreshStrategy: 'nothing',
        detailGridOptions: {
          suppressCellSelection: true,
          suppressContextMenu: true,
          frameworkComponents: {
            actionRenderer: OTActionRenderer,
          },
          defaultColDef: {
            sortable: true,
            resizable: true,
            width: 50,
            minWidth: 50,
            menuTabs: ['filterMenuTab'],
          },
          columnTypes: {
            text: { filter: 'agTextColumnFilter' },
            number: { filter: 'agNumberColumnFilter' },
            numberWithFilterReset: {
              filter: 'agNumberColumnFilter',
            },
          },
          columnDefs: [
            {
              headerName: 'Seq ID',
              field: 'sequence',
              type: 'text',
            },
            {
              headerName: 'Position ID',
              field: 'positionid',
              hide: true,
              type: 'text',
            },
            {
              headerName: literals.tables.open_trade_table.cen_ord_id,
              field: 'cen_ord_id',
              type: 'text',
            },
            {
              headerName: literals.tables.open_trade_table.price,
              field: 'price',
              cellClass: 'number',
              valueFormatter(params) {
                return numberFormatter(params.value);
              },
              type: 'number',
            },
            {
              headerName: literals.tables.open_trade_table.vol,
              field: 'vol_value',
              cellClass: 'number',
              cellRenderer(params) {
                if (params.data.net_position_vol_value < 0) {
                  return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
                }
                return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
              },
              type: 'number',
            },
            {
              headerName: literals.tables.open_trade_table.time,
              field: 'time_value',
            },
            {
              headerName: literals.tables.ag_table.actions,
              field: 'actions',
              cellRenderer: 'actionRenderer',
              filter: false,
              sortable: false,
            },
          ],
          onGridReady(params) {
            params.api.sizeColumnsToFit();
          },
        },
        getDetailRowData(params) {
          const positionID = params.node.data.position_id;
          const netPositionVolValue = params.node.parent.data.net_volume_value;
          const query = `pos_id=${positionID}&net_position_vol_value=${netPositionVolValue}`;
          axios.get(
            `${process.env.REACT_APP_BACKEND_API}/report/open_trades?${query}`,
            { headers: { 'x-forward-client': props.rootStore.clientStore.CC } },
          )
            .then((response) => {
              params.successCallback(response.data);
            })
            .catch(() => {
              params.successCallback([]);
            });
        },
        template:
          '<div style="height: 100%; padding: 10px; box-sizing: border-box;">' +
          '  <div style="height: 10%;">Open Trades</div>' +
          '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
          '</div>',
      },
      isRowMaster(dataItem) {
        return dataItem.riskaccount.length > 0;
      },
      frameworkComponents: {
        actionRenderer: PositionActionRenderer,
      },
      rowData: [],
      collapse: '',
      defaultColDef: {
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
        menuTabs: ['filterMenuTab'],
      },
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter',
        },
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressSideButtons: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
      },
      getRowNodeId(data) {
        return data.position_id + data.symbol_val + data.riskaccount;
      },
      height: 200,
      loaderShow: false,
      popoverOpenFixMakerPositions: false,
      popoverOpenFixTakerPositions: false,
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    const values = this.props.search_values;
    axios.post(`${process.env.REACT_APP_BACKEND_API}/report/position/audit_log`, JSON.stringify({
      symbol: values.symbol,
      taker: values.taker,
      position_account: values.account,
      type: values.type ? values.type.value : 'normal_account',
    }))
      .then(() => {
      })
      .catch(() => {
      });
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (literals.tables.position_table.symbol !== prevProps.literals.tables.position_table.symbol) {
        this.gridColumnApi.getColumn('symbol_val')
          .getColDef().headerName
          = literals.tables.position_table.symbol;
        this.gridColumnApi.getColumn('party_symbol').getColDef().headerName =
            literals.tables.position_table.party_symbol;
        this.gridColumnApi.getColumn('account')
          .getColDef().headerName
          = literals.tables.position_table.account;
        this.gridColumnApi.getColumn('account_type_value')
          .getColDef().headerName
          = literals.tables.position_table.account_type;
        this.gridColumnApi.getColumn('taker')
          .getColDef().headerName
          = literals.tables.position_table.taker;
        this.gridColumnApi.getColumn('taker_feed').getColDef().headerName =
            literals.tables.position_table.taker_feed;
        this.gridColumnApi.getColumn('riskaccount')
          .getColDef().headerName
          = literals.tables.position_table.risk_account;
        this.gridColumnApi.getColumn('aggregate_group')
          .getColDef().headerName
          = literals.tables.position_table.aggregate_group;
        this.gridColumnApi.getColumn('net_volume_value')
          .getColDef().headerName
          = literals.tables.position_table.net_volume;
        this.gridColumnApi.getColumn('anet_volume_value')
          .getColDef().headerName
          = literals.tables.position_table.anet_volume;
        this.gridColumnApi.getColumn('bnet_volume_value')
          .getColDef().headerName
          = literals.tables.position_table.bnet_volume;
        this.gridColumnApi.getColumn('avg_price')
          .getColDef().headerName
          = literals.tables.position_table.avg_price;
        this.gridColumnApi.getColumn('aavg_price')
          .getColDef().headerName
          = literals.tables.position_table.aavg_price;
        this.gridColumnApi.getColumn('bavg_price')
          .getColDef().headerName
          = literals.tables.position_table.bavg_price;
        this.gridColumnApi.getColumn('last_time_value')
          .getColDef().headerName
          = literals.tables.position_table.last_time;
        this.gridColumnApi.getColumn('pl')
          .getColDef().headerName = literals.tables.position_table.pl;
        this.gridColumnApi.getColumn('margin')
          .getColDef().headerName = literals.tables.position_table.margin;
        this.gridColumnApi.getColumn('notional')
          .getColDef().headerName = literals.tables.position_table.notional;
        this.gridColumnApi.getColumn('close_price')
          .getColDef().headerName
          = literals.tables.position_table.close_price;
        this.gridColumnApi.getColumn('base_exposure')
          .getColDef().headerName = literals.tables.position_table.base_exposure;
        this.gridColumnApi.getColumn('quote_exposure')
          .getColDef().headerName = literals.tables.position_table.quote_exposure;
        this.gridColumnApi.getColumn('base_cur_exposure')
          .getColDef().headerName = literals.tables.position_table.base_cur_exposure;
        this.gridColumnApi.getColumn('quote_cur_exposure')
          .getColDef().headerName = literals.tables.position_table.quote_cur_exposure;
        this.gridColumnApi.getColumn('b_base_exposure')
          .getColDef().headerName = literals.tables.position_table.b_base_exposure;
        this.gridColumnApi.getColumn('b_quote_exposure')
          .getColDef().headerName = literals.tables.position_table.b_quote_exposure;
        this.gridColumnApi.getColumn('b_base_cur_exposure')
          .getColDef().headerName = literals.tables.position_table.b_base_cur_exposure;
        this.gridColumnApi.getColumn('b_quote_cur_exposure')
          .getColDef().headerName = literals.tables.position_table.b_quote_cur_exposure;
        this.gridColumnApi.getColumn('swaps')
          .getColDef().headerName = literals.tables.position_table.swaps;
        this.gridColumnApi.getColumn('commission')
          .getColDef().headerName = literals.tables.position_table.commission;
        this.gridColumnApi.getColumn('markup')
          .getColDef().headerName = literals.tables.position_table.markup;
        this.gridColumnApi.getColumn('margin_conv_rate')
          .getColDef().headerName = literals.tables.position_table.margin_conv_rate;
        this.gridColumnApi.getColumn('pl_conv_rate')
          .getColDef().headerName = literals.tables.position_table.pl_conv_rate;
        this.gridColumnApi.getColumn('sl_price').getColDef().headerName =
            literals.tables.position_table.sl_price;
        this.gridColumnApi.getColumn('tp_price').getColDef().headerName =
            literals.tables.position_table.tp_price;

        this.gridColumnApi.getColumn('symbol_val')
          .getColDef().headerTooltip
          = literals.tables.position_table.symbol;
        this.gridColumnApi.getColumn('party_symbol').getColDef().headerTooltip =
            literals.tables.position_table.party_symbol;
        this.gridColumnApi.getColumn('account')
          .getColDef().headerTooltip
          = literals.tables.position_table.account;
        this.gridColumnApi.getColumn('account_type_value')
          .getColDef().headerTooltip
          = literals.tables.position_table.account_type;
        this.gridColumnApi.getColumn('taker')
          .getColDef().headerTooltip
          = literals.tables.position_table.taker;
        this.gridColumnApi.getColumn('taker_feed').getColDef().headerTooltip =
            literals.tables.position_table.taker_feed;
        this.gridColumnApi.getColumn('riskaccount')
          .getColDef().headerTooltip = literals.tables.position_table.risk_account;
        this.gridColumnApi.getColumn('aggregate_group')
          .getColDef().headerTooltip
          = literals.tables.position_table.aggregate_group;
        this.gridColumnApi.getColumn('net_volume_value')
          .getColDef().headerTooltip
          = literals.tables.position_table.net_volume;
        this.gridColumnApi.getColumn('anet_volume_value')
          .getColDef().headerTooltip
          = literals.tables.position_table.anet_volume;
        this.gridColumnApi.getColumn('bnet_volume_value')
          .getColDef().headerTooltip
          = literals.tables.position_table.bnet_volume;
        this.gridColumnApi.getColumn('avg_price')
          .getColDef().headerTooltip
          = literals.tables.position_table.avg_price;
        this.gridColumnApi.getColumn('aavg_price')
          .getColDef().headerTooltip
          = literals.tables.position_table.aavg_price;
        this.gridColumnApi.getColumn('bavg_price')
          .getColDef().headerTooltip
          = literals.tables.position_table.bavg_price;
        this.gridColumnApi.getColumn('last_time_value')
          .getColDef().headerTooltip
          = literals.tables.position_table.last_time;
        this.gridColumnApi.getColumn('pl')
          .getColDef().headerTooltip = literals.tables.position_table.pl;
        this.gridColumnApi.getColumn('margin')
          .getColDef().headerTooltip = literals.tables.position_table.margin;
        this.gridColumnApi.getColumn('notional')
          .getColDef().headerTooltip = literals.tables.position_table.notional;
        this.gridColumnApi.getColumn('close_price')
          .getColDef().headerTooltip = literals.tables.position_table.close_price;
        this.gridColumnApi.getColumn('base_exposure')
          .getColDef().headerTooltip = literals.tables.position_table.base_exposure;
        this.gridColumnApi.getColumn('quote_exposure')
          .getColDef().headerTooltip = literals.tables.position_table.quote_exposure;
        this.gridColumnApi.getColumn('base_cur_exposure')
          .getColDef().headerTooltip = literals.tables.position_table.base_cur_exposure;
        this.gridColumnApi.getColumn('quote_cur_exposure')
          .getColDef().headerTooltip = literals.tables.position_table.quote_cur_exposure;
        this.gridColumnApi.getColumn('b_base_exposure')
          .getColDef().headerTooltip = literals.tables.position_table.b_base_exposure;
        this.gridColumnApi.getColumn('b_quote_exposure')
          .getColDef().headerTooltip = literals.tables.position_table.b_quote_exposure;
        this.gridColumnApi.getColumn('b_base_cur_exposure')
          .getColDef().headerTooltip = literals.tables.position_table.b_base_cur_exposure;
        this.gridColumnApi.getColumn('b_quote_cur_exposure')
          .getColDef().headerTooltip = literals.tables.position_table.b_quote_cur_exposure;
        this.gridColumnApi.getColumn('swaps')
          .getColDef().headerTooltip = literals.tables.position_table.swaps;
        this.gridColumnApi.getColumn('commission')
          .getColDef().headerTooltip = literals.tables.position_table.commission;
        this.gridColumnApi.getColumn('markup')
          .getColDef().headerTooltip = literals.tables.position_table.markup;
        this.gridColumnApi.getColumn('margin_conv_rate')
          .getColDef().headerTooltip = literals.tables.position_table.margin_conv_rate;
        this.gridColumnApi.getColumn('pl_conv_rate')
          .getColDef().headerTooltip = literals.tables.position_table.pl_conv_rate;
        this.gridColumnApi.getColumn('sl_price').getColDef().headerTooltip =
            literals.tables.position_table.sl_price;
        this.gridColumnApi.getColumn('tp_price').getColDef().headerTooltip =
            literals.tables.position_table.tp_price;
        this.gridApi.refreshHeader();
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridParams = params;
    this.onGridSizeChanged(params);
    this.columnsVisibility();
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onToolPanelVisibleChanged=(params) => {
    if (!params.api.isToolPanelShowing()) {
      this.onGridSizeChanged(params);
    }
  };

  onBtExportExcel = () => {
    const master = [];
    const detail = [];
    const mHeaders = _.filter(this.gridColumnApi.getAllColumns(), (v) => _.includes(exportColumns, v.colId));
    const masterHeaders = _.map(mHeaders, 'colDef.headerName');
    let detailHeaders = [];
    this.gridApi.forEachNodeAfterFilter((node) => {
      master.push(_.pick(node.data, exportColumns));
    });
    let j = 0;
    this.gridApi.forEachDetailGridInfo((detailGridInfo) => {
      if (j === 0) {
        const dHeaders = _.filter(
          detailGridInfo.columnApi.getAllColumns(),
          (v) => _.includes(detailExportColumns, v.colId),
        );
        detailHeaders = _.map(dHeaders, 'colDef.headerName');
      }
      detailGridInfo.api.forEachNodeAfterFilter((node) => {
        detail.push(_.pick(node.data, detailExportColumns));
      });
      j += 1;
    });
    if (master.length === 0) {
      master.push(_.zipObject(masterHeaders));
    }
    if (detail.length === 0) {
      detail.push(_.zipObject(detailHeaders));
    }
    const masterWS = XLSX.utils.json_to_sheet(master);
    for (let i = 0; i < masterHeaders.length; i += 1) {
      masterWS[`${this.getExcelColumnName(i + 1)}1`].v = masterHeaders[i];
    }
    const detailWS = XLSX.utils.json_to_sheet(detail);
    for (let i = 0; i <= detailHeaders.length - 1; i += 1) {
      detailWS[`${this.getExcelColumnName(i + 1)}1`].v = detailHeaders[i];
    }
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, masterWS, 'Positions');
    XLSX.utils.book_append_sheet(wb, detailWS, 'Open Trades');
    XLSX.writeFile(wb, 'Position.xlsx');
  };

  onBtExportCSV=() => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'Position.csv',
    };
    this.gridApi.exportDataAsCsv(params);
  };

  getExcelColumnName=(columnNumber) => {
    let dividend = columnNumber;
    let columnName = '';
    let modulo = 0;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = parseInt((dividend - modulo) / 26, 10);
    }

    return columnName;
  };

  columnsVisibility = (selectedOption) => {
    let typeValue = 'normal_account';
    if (selectedOption) {
      typeValue = selectedOption.value;
    } else {
      const { type } = this.props.search_values;
      typeValue = type ? type.value : 'normal_account';
    }
    if (typeValue === 'normal_account') {
      exportColumns = exportColumnsNormalAccount;
    } else if (typeValue === 'risk_account') {
      exportColumns = exportColumnsRiskAccount;
    } else {
      exportColumns = exportColumnsAllAccount;
    }
    this.gridColumnApi.setColumnsVisible(
      ['riskaccount', 'swaps', 'commission', 'actions'],
      typeValue === 'risk_account' || typeValue === 'all_account',
    );
    this.gridColumnApi.getColumn('account').getColDef().suppressColumnsToolPanel = typeValue === 'risk_account';
    this.gridColumnApi.getColumn('taker').getColDef().suppressColumnsToolPanel = typeValue === 'risk_account';
    this.gridColumnApi.getColumn('account_type_value').getColDef().suppressColumnsToolPanel
      = typeValue === 'risk_account';
    this.gridColumnApi.getColumn('riskaccount').getColDef().suppressColumnsToolPanel = typeValue === 'normal_account';
    this.gridColumnApi.getColumn('swaps').getColDef().suppressColumnsToolPanel = typeValue === 'normal_account';
    this.gridColumnApi.getColumn('commission').getColDef().suppressColumnsToolPanel = typeValue === 'normal_account';
    this.gridColumnApi.getColumn('actions').getColDef().suppressColumnsToolPanel = typeValue === 'normal_account';
    this.gridApi.refreshToolPanel();
    this.onGridSizeChanged(this.gridParams);
  };

    handleSubmit=(values) => {
      this.columnsVisibility();
      this.sendMessage(JSON.stringify({
        key: 'position_report',
        value: JSON.stringify({
          symbol: values.symbol,
          taker: values.taker,
          position_account: values.account,
          type: values.type ? values.type.value : 'normal_account',
        }),
      }));
      this.setState({ collapse: 'force-close' });
      setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
      axios.post(`${process.env.REACT_APP_BACKEND_API}/report/position/audit_log`, JSON.stringify({
        symbol: values.symbol,
        taker: values.taker,
        position_account: values.account,
        type: values.type ? values.type.value : 'normal_account',
      }))
        .then(() => {
        })
        .catch(() => {
        });
    };

    socketMessageListener = (event) => {
      const data = JSON.parse(event);
      if ('position_report' in data && !data.api_error) {
        this.setState({ rowData: data.position_report });
      }
    };
    socketOpenListener = () => {
      const values = this.props.search_values;
      this.sendMessage(JSON.stringify({
        key: 'position_report',
        value: JSON.stringify({
          symbol: values.symbol,
          taker: values.taker,
          position_account: values.account,
          type: values.type ? values.type.value : 'normal_account',
        }),
      }));
    };
    sendMessage=(message) => {
      this.refWebSocket.sendMessage(message);
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    togglePopoverFixMakerPositions = () => {
      this.setState({
        popoverOpenFixMakerPositions: !this.state.popoverOpenFixMakerPositions,
      });
    };

    togglePopoverFixTakerPositions = () => {
      this.setState({
        popoverOpenFixTakerPositions: !this.state.popoverOpenFixTakerPositions,
      });
    };

    fixMakerPositions = () => {
      this.togglePopoverFixMakerPositions();
      this.showLoader();
      const values = this.props.search_values;
      axios.post(`${process.env.REACT_APP_BACKEND_API}/report/fix_maker_position`, JSON.stringify({
        symbol: values.symbol,
        taker: values.taker,
        position_account: values.account,
        type: 'normal_account',
        account_type_bool: true,
        account_type: 1,
      }))
        .then(() => {
          showNotification(
            this.props.literals.tables.position_table.title_update, 'primary',
            `${this.props.literals.forms.success_message}`,
          );
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    fixMakerPositionsExport = () => {
      this.showLoader();
      const values = this.props.search_values;
      axios.post(`${process.env.REACT_APP_BACKEND_API}/report/fix_maker_position/export`, JSON.stringify({
        symbol: values.symbol,
        taker: values.taker,
        position_account: values.account,
        type: 'normal_account',
        account_type_bool: true,
        account_type: 1,
      }), { responseType: 'blob' })
        .then((response) => {
          FileDownload(response.data, 'FixMakerPositions.xlsx');
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    fixTakerPositions = () => {
      this.togglePopoverFixTakerPositions();
      this.showLoader();
      const values = this.props.search_values;
      axios.post(`${process.env.REACT_APP_BACKEND_API}/report/fix_taker_position`, JSON.stringify({
        symbol: values.symbol,
        taker: values.taker,
        position_account: values.account,
        type: 'normal_account',
        account_type_bool: true,
        account_type: 0,
      }))
        .then(() => {
          showNotification(
            this.props.literals.tables.position_table.title_update, 'primary',
            `${this.props.literals.forms.success_message}`,
          );
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    fixTakerPositionsExport = () => {
      this.showLoader();
      const values = this.props.search_values;
      axios.post(`${process.env.REACT_APP_BACKEND_API}/report/fix_taker_position/export`, JSON.stringify({
        symbol: values.symbol,
        taker: values.taker,
        position_account: values.account,
        type: 'normal_account',
        account_type_bool: true,
        account_type: 0,
      }), { responseType: 'blob' })
        .then((response) => {
          FileDownload(response.data, 'FixTakerPositions.xlsx');
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      const wHeight = window.innerHeight / 1.2;
      this.setState({ height: wHeight });
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.position}
                </h5>
              </div>
              <Collapse
                title={literals.tables.ag_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <PositionSearchForm
                  onSubmit={this.handleSubmit}
                />
              </Collapse>
              <div className="outer-grid-label">
                {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
              </div>
              <div className="outer-grid-button">
                <ButtonToolbar>
                  <Tooltip title={`${literals.tables.ag_table.export}
                  ${literals.tables.position_table.fix_maker_positions}`}
                  >
                    <Button onClick={this.fixMakerPositionsExport} className="icon two-icon-button" color="primary">
                      <p className="fa fa-file-excel two-icon-button" />
                      <p className="fas fa-compress-arrows-alt two-icon-button two-icon-button-second" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.position_table.fix_maker_positions}>
                    <Button
                      ref={(c) => {
                        this.popoverFixMakerPositions = c && ReactDOM.findDOMNode(c);
                      }}
                      onClick={this.togglePopoverFixMakerPositions}
                      className="icon"
                      color="primary"
                    >
                      <p className="fas fa-compress-arrows-alt" />
                    </Button>
                    <Popover
                      placement="bottom"
                      isOpen={this.state.popoverOpenFixMakerPositions}
                      target={() => this.popoverFixMakerPositions}
                      toggle={this.togglePopoverFixMakerPositions}
                      className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
                    >
                      <PopoverHeader>{literals.tables.position_table.fix_maker_positions}</PopoverHeader>
                      <PopoverBody>
                        <span>{literals.tables.position_table.fix_maker_positions_question}
                        </span>
                        <ButtonToolbar className="btn-toolbar--center">
                          <Button className="btn btn-blue" onClick={this.togglePopoverFixMakerPositions}>
                            {literals.forms.cancel}
                          </Button>
                          <Button
                            className="btn btn-blue"
                            onClick={this.fixMakerPositions}
                          >
                            {literals.forms.confirm}
                          </Button>
                        </ButtonToolbar>
                      </PopoverBody>
                    </Popover>
                  </Tooltip>
                  <Tooltip title={`${literals.tables.ag_table.export}
                  ${literals.tables.position_table.fix_taker_positions}`}
                  >
                    <Button onClick={this.fixTakerPositionsExport} className="icon two-icon-button" color="primary">
                      <p className="fa fa-file-excel two-icon-button" />
                      <p className="fas fa-stream two-icon-button two-icon-button-second" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.position_table.fix_taker_positions}>
                    <Button
                      ref={(c) => {
                        this.popoverFixTakerPositions = c && ReactDOM.findDOMNode(c);
                      }}
                      onClick={this.togglePopoverFixTakerPositions}
                      className="icon"
                      color="primary"
                    >
                      <p className="fas fa-stream" />
                    </Button>
                    <Popover
                      placement="bottom"
                      isOpen={this.state.popoverOpenFixTakerPositions}
                      target={() => this.popoverFixTakerPositions}
                      toggle={this.togglePopoverFixTakerPositions}
                      className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
                    >
                      <PopoverHeader>{literals.tables.position_table.fix_taker_positions}</PopoverHeader>
                      <PopoverBody>
                        <span>{literals.tables.position_table.fix_taker_positions_question}
                        </span>
                        <ButtonToolbar className="btn-toolbar--center">
                          <Button className="btn btn-blue" onClick={this.togglePopoverFixTakerPositions}>
                            {literals.forms.cancel}
                          </Button>
                          <Button
                            className="btn btn-blue"
                            onClick={this.fixTakerPositions}
                          >
                            {literals.forms.confirm}
                          </Button>
                        </ButtonToolbar>
                      </PopoverBody>
                    </Popover>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.export_excel}>
                    <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                      <p className="fa fa-file-excel" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.export_csv}>
                    <Button onClick={this.onBtExportCSV} className="icon" color="primary">
                      <p className="fa fa-file-alt" />
                    </Button>
                  </Tooltip>
                </ButtonToolbar>
              </div>
              <div
                id={this.gridWrapperID}
                style={{
 width: '100%', height: '100%', clear: 'both',
}}
              >
                <div
                  id={this.myGridID}
                  style={{
                                    boxSizing: 'border-box',
                                    height: this.state.height,
                                    width: '100%',
                                }}
                  className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnDefs}
                    immutableData
                    suppressCellSelection
                    suppressContextMenu
                    defaultColDef={this.state.defaultColDef}
                    onColumnResized={this.onColumnResized}
                    getRowNodeId={this.state.getRowNodeId}
                    rowData={this.state.rowData}
                    onGridReady={this.onGridReady}
                    onGridSizeChanged={this.onGridSizeChanged}
                    masterDetail
                    isRowMaster={this.state.isRowMaster}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    frameworkComponents={this.state.frameworkComponents}
                    sideBar={this.state.sideBar}
                    onToolPanelVisibleChanged={this.onToolPanelVisibleChanged}
                    columnTypes={this.state.columnTypes}
                  />
                </div>
              </div>
              <Websocket
                url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
                reconnectIntervalInMilliSeconds={1000}
                onMessage={this.socketMessageListener}
                onOpen={this.socketOpenListener}
                ref={WS => {
                  this.refWebSocket = WS;
                }}
              />
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  search_values: getFormValues('position_search_form')(state),
}))(Position);
