/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import renderSelectField from '../../shared/components/form/SelectBox';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class SessionEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    colDef: PropTypes.instanceOf(Object).isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    literals: LiteralProps.isRequired,
  };

  componentDidMount() {
    const val = this.props.value;
    const match = val.match(/#[A-z]{2}#/g);
    let toReplace = '';
    if (match && match.length > 0) {
      [toReplace] = match;
      this.props.change('sym_format_prefix', { label: toReplace, value: toReplace });
    }
    this.props.change('sym_format', val.replace(toReplace, ''));
  }

  render() {
    return (
      <div>
        <form className="form form--vertical" onSubmit={this.props.handleSubmit}>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <span style={{ color: 'orange' }}>
                {this.props.literals.forms.reverse_trade_copier_form.symbol_format_note}
              </span>
              <Field
                name="sym_format_prefix"
                component={renderSelectField}
                type="text"
                options={this.props.context.formData.SymbolFormatPrefix}
                placeholder={this.props.colDef.priority}
                style={{ width: '50%', paddingRight: 10 }}
              />
              <Field
                name="sym_format"
                component={renderField}
                type="text"
                placeholder={this.props.literals.forms.reverse_trade_copier_form.optional_string}
              />
            </div>
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" type="submit">{this.props.literals.forms.submit}</Button>
              <Button color="primary" onClick={this.props.toggleModalSession}>
                {this.props.literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    literals: state.literals,
    initialValues: {
      sym_format_prefix: { label: '#ts#', value: '#ts#' },
      sym_format: '',
    },
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(reduxForm({
  form: 'reverse_trade_copier_sym_format_form', // a unique identifier for this form
})(SessionEdit));
