import { action, observable } from 'mobx';
import { ignore } from 'mobx-sync';

class SettingStore {
  @ignore
  @observable CRP;
  @ignore
  @observable ClientServerInstances;
  @ignore
  @observable Symbols;
  @ignore
  @observable CRPOthers;
  @ignore
  CRP__INIT = {
    instrument: {},
    login: {},
    arbitrage: {},
    clientAOI: {},
    commissionGenerator: {},
    companyAOI: {},
    currentLeverage: {},
    expertAdvisor: {},
    highEquity: {},
    winningPercentage: {},
    hfTrader: {},
    intradayTrader: {},
    largeLeverage: {},
    largerLeverage: {},
    lfTrader: {},
    mtGroups: {},
    outstanding: {},
    profitable: {},
    realizedPL1: {},
    realizedPL2: {},
    tt1Hour: {},
    tt5Minutes: {},
    tradeSize: {},
    tradingVelocity: {},
    unrealizedPL1: {},
    unrealizedPL2: {},
    stpRuleVolumePercentage: {},
    stpRuleVolumeOver: {},
  };
  @ignore
  CRP_OTHERS__INIT ={
    instrumentOthers: {},
    loginOthers: {},
    STP: { stpRuleVolumeOver: {}, stpRuleVolumePercentage: {} },
    clientServerInstance: '',
    rowVisible: '',
    popoverOpen: false,
    isRecaptchaVerified: false,
    loaderShow: false,
  };
  constructor() {
    this.reset();
  }
  @action
  reset() {
    this.CRP = this.CRP__INIT;
    this.ClientServerInstances = [];
    this.Symbols = [];
    this.CRPOthers = this.CRP_OTHERS__INIT;
  }
}
export default new SettingStore();

