/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonToolbar } from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

@inject('rootStore')
class PositionActionRenderer extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.refreshOT = this.refreshOT.bind(this);
    }

    refreshOT() {
      if (this.props.node.detailNode && this.props.node.detailNode.detailGridInfo
            && this.props.node.detailNode.detailGridInfo.api) {
        const positionID = this.props.node.data.position_id;
        const netPositionVolValue = this.props.node.data.net_volume_value;
        const query = `pos_id=${positionID}&net_position_vol_value=${netPositionVolValue}`;
        axios.get(`${process.env.REACT_APP_BACKEND_API}/report/open_trades?${query}`)
          .then((response) => {
            this.props.node.detailNode.detailGridInfo.api.setRowData(response.data);
          })
          .catch(() => {
            this.props.node.detailNode.detailGridInfo.api.setRowData([]);
          });
      }
    }

    render() {
      const { literals } = this.props;
      return (
        <span>{this.props.node.data.riskaccount ?
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.refresh_open_trade}>
              <Button
                style={{ height: 14, padding: '4px 5px' }}
                id="OTRefreshClick"
                onClick={this.refreshOT}
                className="icon"
                color="primary"
              ><p
                style={{ lineHeight: 0 }}
                className="fas fa-sync"
              />
              </Button>
            </Tooltip>
          </ButtonToolbar> : ''
        }
        </span>


      );
    }
}

export default connect(state => ({
  literals: state.literals,
}))(withRouter(PositionActionRenderer));
