import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import Layout from '../_layout/index';
import MainWrapper from './MainWrapper';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';

import LogIn from '../log_in/index';
import ForgotPassword from '../forgot_password/index';
import ResetPassword from '../reset_password/index';
import Node from '../node/index';
import NodeFeed from '../node_feed/index';
import NodeAccount from '../node_account/index';
import Security from '../security/index';
import Client from '../client/index';
import ServerInstance from '../server_instance/index';
import Config from '../config/index';
import UniSymbol from '../uni_symbol/index';
import LP from '../lp/index';
import LPSession from '../lp_session/index';
import AggregateGroup from '../aggregate_group/index';
import MarkupGroup from '../markup_group/index';
import Position from '../position/index';
import Order from '../order/index';
import Response from '../response/index';
import Trading from '../trading/index';
import MarketWatch from '../market_watch/index';
import Profile from '../profile/index';
import ChangePassword from '../change_password/index';
import ClientRiskProfiling from '../client_risk_profiling/index';
import MonitoringLog from '../monitoring_log/index';
import MT4Report from '../mt4_report/index';
import MT5Report from '../mt5_report/index';
import AccountGroup from '../account_group/index';
import LimitSymbolGroup from '../limit_symbol_group/index';
import BalanceTransaction from '../balance_transaction/index';
import BalanceTransactionReport from '../balance_transaction_report/index';
import RawBalanceTransactionReport from '../balance_transaction_raw_report/index';
import BrokerUser from '../broker_user/index';
import TradingBrokerUser from '../trading_broker_user/index';
import MarketWatchBrokerUser from '../market_watch_broker_user/index';
import OrderBrokerUser from '../order_broker_user/index';
import BalanceTransactionBrokerUser from '../balance_transaction_report_broker_user/index';
import LevelsAdvantage from '../levels_advantage/index';
import OrderMaker from '../order_maker/index';
import AdminPosition from '../admin_position/index';
import AuditLog from '../audit_log/index';
import AccessLog from '../access_log/index';
import TakerStatus from '../taker_status/index';
import MakerStatus from '../maker_status/index';
import ChartMakerNotional from '../chart_maker_notional/index';
import ChartMakerSymbolNotional from '../chart_maker_symbol_notional/index';
import ChartSymbolNotional from '../chart_symbol_notional/index';
import ChartSymbolDetailNotional from '../chart_symbol_detail_notional/index';
import TakerNotional from '../taker_notional/index';
import MakerNotional from '../maker_notional/index';
import SymbolNotional from '../symbol_notional/index';
import MarkupReport from '../report_markup/index';
import SlippageReport from '../report_slippage/index';
import ChartBarLimit from '../chart_bar_limit/index';
import ChartMarketImpact from '../chart_market_impact/index';
import AssetClassGroup from '../asset_class_group/index';
import AssetClassSymbol from '../asset_class_symbol/index';
import PushTicks from '../push_ticks/index';
import NetPerLoginReport from '../report_net_per_login/index';
import MultiWindow from '../multi_window/index';
import ReverseTradeCopier from '../reverse_trade_copier/index';
import UrgencyHeadlineNews from '../urgency_headline_news/index';
import MakerAPILink from '../maker_api_link/index';
import GiveUpRule from '../give_up_rule/index';
import FiltrationPool from '../filtration_pool/index';
import MasterRiskAccount from '../master_risk_account/index';
import MakerSymbolStatusReport from '../report_maker_symbol_status/index';
import ChartMakerSymbolSpreadHistory from '../chart_maker_symbol_spread_history/index';
import MarkupLegReport from '../report_markup_leg/index';
import SlippageLegReport from '../report_slippage_leg/index';
import GiveupOrderReport from '../report_giveup_order/index';
import MakerRejectionReport from '../report_maker_rejection/index';
import Commands from '../commands/index';
import LegReport from '../report_leg/index';
import MarginLevelWarn from '../margin_level_warn/index';
import ActionScheduler from '../action_scheduler/index';
import TakerAPILink from '../taker_api_link/index';
import axiosPrivate from '../../shared/axiosPrivate';
import ConcentrationPerLogin from '../concentration_per_login/index';
import MakerScaling from '../maker_scaling/index';
import LoginSpreadClientReport from '../report_login_spread_client/index';
import LoginSpreadCoverReport from '../report_login_spread_cover/index';
import BrandedStatement from '../branded_statement/index';
import ClientInfo from '../client_info/index';

const NodePages = () => (
  <Switch>
    <Route path="/node/all" component={Node} />
    <Route exact path="/node/feed" component={NodeFeed} />
    <Route exact path="/node/account" component={NodeAccount} />
    <Route exact path="/node/taker_api_link" component={TakerAPILink} />
  </Switch>
);

const LPPages = () => (
  <Switch>
    <Route exact path="/security/lp_session" component={LPSession} />
    <Route exact path="/security/maker_api_link" component={MakerAPILink} />
    <Route exact path="/security/maker_scaling" component={MakerScaling} />
  </Switch>
);

const RiskAccountPages = () => (
  <Switch>
    <Route exact path="/account/group" component={AccountGroup} />
    <Route
      exact
      path="/account/asset_class/group"
      component={AssetClassGroup}
    />
    <Route
      exact
      path="/account/asset_class/symbol"
      component={AssetClassSymbol}
    />
    <Route
      exact
      path="/account/balance_transaction"
      component={BalanceTransaction}
    />
    <Route
      exact
      path="/account/limit_symbol_group"
      component={LimitSymbolGroup}
    />
    <Route
      exact
      path="/account/master_risk_account"
      component={MasterRiskAccount}
    />
  </Switch>
);

const SecurityPages = () => (
  <Switch>
    <Route path="/security/all" component={Security} />
    <Route exact path="/security/uni_symbol" component={UniSymbol} />
    <Route exact path="/security/aggregate_group" component={AggregateGroup} />
    <Route exact path="/security/markup_group" component={MarkupGroup} />
  </Switch>
);

const ReportPages = () => (
  <Switch>
    <Route path="/report/position" component={Position} />
    <Route path="/report/order" component={Order} />
    <Route path="/report/order_maker" component={OrderMaker} />
    <Route path="/report/response" component={Response} />
    <Route path="/report/mt4_report" component={MT4Report} />
    <Route path="/report/mt5_report" component={MT5Report} />
    <Route
      path="/report/balance_transaction"
      component={BalanceTransactionReport}
    />
    <Route
      path="/report/raw_balance_transaction"
      component={RawBalanceTransactionReport}
    />
    <Route path="/report/levels_advantage" component={LevelsAdvantage} />
    <Route path="/report/chart_maker_notional" component={ChartMakerNotional} />
    <Route
      path="/report/chart_maker_symbol_notional"
      component={ChartMakerSymbolNotional}
    />
    <Route
      path="/report/chart_symbol_notional"
      component={ChartSymbolNotional}
    />
    <Route
      path="/report/chart_symbol_detail_notional"
      component={ChartSymbolDetailNotional}
    />
    <Route path="/report/chart_bar_limit" component={ChartBarLimit} />
    <Route path="/report/chart_market_impact" component={ChartMarketImpact} />
    <Route path="/report/taker_notional" component={TakerNotional} />
    <Route path="/report/maker_notional" component={MakerNotional} />
    <Route path="/report/symbol_notional" component={SymbolNotional} />
    <Route path="/report/markup" component={MarkupReport} />
    <Route path="/report/slippage" component={SlippageReport} />
    <Route path="/report/net_per_login" component={NetPerLoginReport} />
    <Route
      path="/report/maker_symbol_status"
      component={MakerSymbolStatusReport}
    />
    <Route
      path="/report/chart_maker_symbol_spread_history"
      component={ChartMakerSymbolSpreadHistory}
    />
    <Route path="/report/markup_leg" component={MarkupLegReport} />
    <Route path="/report/slippage_leg" component={SlippageLegReport} />
    <Route path="/report/giveup_order" component={GiveupOrderReport} />
    <Route path="/report/maker_rejection" component={MakerRejectionReport} />
    <Route path="/report/leg" component={LegReport} />
    <Route
      path="/report/login_spread_client"
      component={LoginSpreadClientReport}
    />
    <Route
      path="/report/login_spread_cover"
      component={LoginSpreadCoverReport}
    />
  </Switch>
);

const ReportBrokerUserPages = () => (
  <Switch>
    <Route path="/report/order" component={OrderBrokerUser} />
    <Route
      path="/report/balance_transaction"
      component={BalanceTransactionBrokerUser}
    />
  </Switch>
);

const TradingPages = () => (
  <Switch>
    <Route exact path="/trading/all" component={Trading} />
    <Route exact path="/trading/market_watch" component={MarketWatch} />
    <Route exact path="/trading/push_ticks" component={PushTicks} />
  </Switch>
);

const TradingBrokerUserPages = () => (
  <Switch>
    <Route exact path="/trading/all" component={TradingBrokerUser} />
    <Route
      exact
      path="/trading/market_watch"
      component={MarketWatchBrokerUser}
    />
  </Switch>
);

const ProfilePages = () => (
  <Switch>
    <Route exact path="/profile/user" component={Profile} />
    <Route exact path="/profile/change_password" component={ChangePassword} />
  </Switch>
);

const SettingsPages = () => (
  <Switch>
    <Route
      exact
      path="/settings/client_risk_profiling"
      component={ClientRiskProfiling}
    />
  </Switch>
);

const MonitoringPages = () => (
  <Switch>
    <Route exact path="/monitoring/log" component={MonitoringLog} />
    <Route exact path="/monitoring/taker_status" component={TakerStatus} />
    <Route exact path="/monitoring/maker_status" component={MakerStatus} />
    <Route
      exact
      path="/monitoring/urgency_headline_news"
      component={UrgencyHeadlineNews}
    />
    <Route
      exact
      path="/monitoring/margin_level_warn"
      component={MarginLevelWarn}
    />
  </Switch>
);

const BrokerUserPages = () => (
  <Switch>
    <Route exact path="/broker/user" component={BrokerUser} />
    <Route
      exact
      path="/broker/branded_statement"
      component={BrandedStatement}
    />
  </Switch>
);

const MultiWindowPages = () => (
  <Switch>
    <Route exact path="/workspace/:workspace" component={MultiWindow} />
  </Switch>
);

const ReverseTradeCopierPages = () => (
  <Switch>
    <Route
      path="/trade_copier/reverse_trade_copier"
      component={ReverseTradeCopier}
    />
  </Switch>
);

const GiveUpRulePages = () => (
  <Switch>
    <Route path="/give_up_rule/all" component={GiveUpRule} />
  </Switch>
);

const FiltrationPoolPages = () => (
  <Switch>
    <Route path="/filtration_pool/all" component={FiltrationPool} />
  </Switch>
);

const ActionSchedulerPages = () => (
  <Switch>
    <Route path="/action_scheduler/all" component={ActionScheduler} />
  </Switch>
);

const RiskManagementPages = () => (
  <Switch>
    <Route
      path="/risk/concentration_per_login"
      component={ConcentrationPerLogin}
    />
  </Switch>
);

const AdminPages = () => (
  <Switch>
    <Route exact path="/admin/client" component={Client} />
    <Route exact path="/admin/client_info" component={ClientInfo} />
    <Route exact path="/admin/server_instance" component={ServerInstance} />
    <Route exact path="/admin/config" component={Config} />
    <Route exact path="/admin/lp" component={LP} />
    <Route exact path="/admin/position" component={AdminPosition} />
    <Route exact path="/admin/audit_log" component={AuditLog} />
    <Route exact path="/admin/access_log" component={AccessLog} />
    <Route exact path="/admin/commands" component={Commands} />
  </Switch>
);

const wrappedRoutes = (props) => {
  axiosPrivate(props);
  const { role } = props.rootStore.authStore;
  if (role === 'admin') {
    return (
      <div>
        <Layout />
        <div className="container__wrap lm_not">
          <Route path="/node" render={() => <NodePages {...props} />} />
          <Route path="/security" render={() => <LPPages {...props} />} />
          <Route path="/account" component={RiskAccountPages} />
          <Route path="/security" component={SecurityPages} />
          <Route path="/report" component={ReportPages} />
          <Route path="/trading" component={TradingPages} />
          <Route path="/profile" component={ProfilePages} />
          <Route path="/settings" component={SettingsPages} />
          <Route path="/monitoring" component={MonitoringPages} />
          <Route path="/admin" component={AdminPages} />
          <Route path="/broker" component={BrokerUserPages} />
          <Route path="/workspace" component={MultiWindowPages} />
          <Route path="/trade_copier" component={ReverseTradeCopierPages} />
          <Route path="/give_up_rule" component={GiveUpRulePages} />
          <Route path="/filtration_pool" component={FiltrationPoolPages} />
          <Route path="/action_scheduler" component={ActionSchedulerPages} />
          <Route path="/risk" component={RiskManagementPages} />
        </div>
      </div>
    );
  } else if (role === 'broker') {
    return (
      <div>
        <Layout />
        <div className="container__wrap lm_not">
          <Route path="/node" render={() => <NodePages {...props} />} />
          <Route path="/security" render={() => <LPPages {...props} />} />
          <Route path="/account" component={RiskAccountPages} />
          <Route path="/security" component={SecurityPages} />
          <Route path="/report" component={ReportPages} />
          <Route path="/trading" component={TradingPages} />
          <Route path="/profile" component={ProfilePages} />
          <Route path="/settings" component={SettingsPages} />
          <Route path="/monitoring" component={MonitoringPages} />
          <Route path="/broker" component={BrokerUserPages} />
          <Route path="/workspace" component={MultiWindowPages} />
          <Route path="/trade_copier" component={ReverseTradeCopierPages} />
          <Route path="/give_up_rule" component={GiveUpRulePages} />
          <Route path="/filtration_pool" component={FiltrationPoolPages} />
          <Route path="/action_scheduler" component={ActionSchedulerPages} />
          <Route path="/risk" component={RiskManagementPages} />
        </div>
      </div>
    );
  } else if (role === 'client') {
    return (
      <div>
        <Layout />
        <div className="container__wrap lm_not">
          <Route path="/report" component={ReportBrokerUserPages} />
          <Route path="/trading" component={TradingBrokerUserPages} />
          <Route path="/profile" component={ProfilePages} />
          <Route path="/workspace" component={MultiWindowPages} />
        </div>
      </div>
    );
  }
  return (
    <div>
      <Layout />
      <div className="container__wrap lm_not" />
    </div>
  );
};

const Router = inject('rootStore')(
  withRouter((props) => (
    <MainWrapper>
      <main>
        <Switch>
          <PublicRouter exact path="/" component={LogIn} {...props} />
          <PublicRouter
            exact
            path="/forgot_password"
            component={ForgotPassword}
            {...props}
          />
          <PublicRouter
            exact
            path="/reset_password"
            component={ResetPassword}
            {...props}
          />
          <PrivateRouter path="/" component={wrappedRoutes} {...props} />
        </Switch>
      </main>
    </MainWrapper>
  ))
);

export default Router;
