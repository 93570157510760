/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { LiteralProps } from '../../prop-types/ReducerProps';
import { validateSessions, parseSessions } from '../../helper';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../Notification';

class SessionEdit extends PureComponent {
  static propTypes = {
    sessions: PropTypes.string,
    submitSession: PropTypes.func.isRequired,
    resetSession: PropTypes.func,
    sessionsVal: PropTypes.string,
    change: PropTypes.func.isRequired,
    literals: LiteralProps.isRequired
  };
  static defaultProps = {
    sessions: '',
    resetSession: () => {},
    sessionsVal: ''
  };
  constructor(props) {
    super(props);
    let notification = null;
    NotificationSystem.newInstance({}, (n) => (notification = n));
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: (
          <BasicNotification title={title} message={message} color={color} />
        ),
        duration: 3,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: 'right-up'
      });
    };
  }
  componentDidMount() {
    this.props.change('sessionsVal', this.props.sessions);
  }

  submitSession = () => {
    if (!validateSessions(this.props.sessionsVal)) {
      this.showNotification(
        this.props.literals.forms.uni_symbol_form.session_edit,
        'danger',
        `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.sessions}`
      );
      return;
    }
    this.props.submitSession(parseSessions(this.props.sessionsVal));
  };
  resetSession = () => {
    this.props.resetSession();
  };

  render() {
    return (
      <div>
        <form className="form form--vertical">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {this.props.literals.forms.uni_symbol_form.sessions}
            </span>
            <div className="form__form-group-field">
              <Field
                name="sessionsVal"
                component="textarea"
                type="text"
                placeholder="Sessions"
              />
            </div>
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" onClick={this.submitSession}>
                {this.props.literals.forms.submit}
              </Button>
              <Button color="primary" onClick={this.resetSession}>
                {this.props.literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}
const selector = formValueSelector('session_edit_form');

function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    sessionsVal: selector(state, 'sessionsVal'),
    initialValues: {
      sessionsVal: ownProps.sessions
    }
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(
  reduxForm({
    form: 'session_edit_form' // a unique identifier for this form
  })(SessionEdit)
);
