import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { inject } from 'mobx-react';
import { Button, ButtonToolbar } from 'reactstrap';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class PushTickSearchForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      maker_sessions: [],
      uni_symbols: [],
      taker_feeds: [],
    };
  }
  componentDidMount() {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/trading/push_tick_search`)
      .then((response) => {
        this.setState({ maker_sessions: response.data.maker_session });
        this.setState({ uni_symbols: response.data.uni_symbol });
        this.setState({ taker_feeds: response.data.taker_feed });
      })
      .catch(() => {
      });
  }

  render() {
    const {
      handleSubmit, literals,
    } = this.props;
    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__half form__search">
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.forms.lp_symbol_form.lp_session}</span>
            <div className="form__form-group-field">
              <Field
                name="maker_session"
                component={renderSelectField}
                type="text"
                options={this.state.maker_sessions}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.tables.trading_table.feed}</span>
            <div className="form__form-group-field">
              <Field
                name="feed"
                component={renderSelectField}
                type="text"
                options={this.state.taker_feeds}
              />
            </div>
          </div>
        </div>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">{literals.tables.position_table.symbol}</span>
            <div className="form__form-group-field">
              <Field
                name="symbol"
                component={renderSelectField}
                type="text"
                options={this.state.uni_symbols}
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">{literals.tables.trading_table.search}</Button>
          </ButtonToolbar>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'push_tick_search_form', // a unique identifier for this form
  destroyOnUnmount: false,
})(PushTickSearchForm));
