import React, { PureComponent } from 'react';
import 'rc-input-number/assets/index.css';
import InputNumber from 'rc-input-number';
import PropTypes from 'prop-types';

class InputNumberField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    step: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    min: PropTypes.number,
    max: PropTypes.number,
    precision: PropTypes.number,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    step: 1,
    min: -10000000000000000000000,
    max: 10000000000000000000000,
    precision: undefined,
  };

  render() {
    const {
      value, name, disabled, onChange, step, min, max, precision,
    } = this.props;

    return (
      <InputNumber
        name={name}
        step={step}
        value={value}
        min={min}
        max={max}
        precision={precision}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }
}

const renderInputNumberField = props => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <InputNumberField
      {...props.input}
      step={props.step}
      min={props.min}
      max={props.max}
      precision={props.precision}
      disabled={props.disabled}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderInputNumberField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  step: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  min: PropTypes.number,
  max: PropTypes.number,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
};

renderInputNumberField.defaultProps = {
  meta: null,
  disabled: false,
  step: 1,
  min: -10000000000000000000000,
  max: 10000000000000000000000,
  precision: undefined,
};

export default renderInputNumberField;
