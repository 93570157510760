/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import renderTreeSelectField from './TreeSelect';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

class SessionEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    colDef: PropTypes.instanceOf(Object).isRequired,
    pattern: PropTypes.bool,
    context: PropTypes.instanceOf(Object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    literals: LiteralProps.isRequired
  };
  static defaultProps = {
    pattern: false
  };

  render() {
    const theme = this.props.context.theme.className;
    let treeData = [];
    if (this.props.colDef.field === 'takers') {
      treeData = this.props.context.formData.Taker;
    } else if (this.props.colDef.field === 'tems') {
      treeData = this.props.context.formData.TakerExecutionModel;
    } else if (this.props.colDef.field === 'securities') {
      treeData = this.props.context.formData.Security;
    } else if (this.props.colDef.field === 'symbols') {
      treeData = this.props.context.formData.Symbol;
    }
    return (
      <div>
        <form
          className="form form--vertical"
          onSubmit={this.props.handleSubmit}
        >
          <div className="form__form-group">
            {this.props.colDef.field !== 'symbols' && (
              <React.Fragment>
                <div className="form__form-group-field">
                  <span className="marginLeftAuto">
                    <Field
                      name="pattern"
                      component={renderCheckBoxField}
                      label={
                        this.props.literals.forms.concentration_per_login_form
                          .pattern
                      }
                    />
                  </span>
                </div>
                <span className="form__form-group-label" />
              </React.Fragment>
            )}
            <div className="form__form-group-field">
              {!this.props.pattern && (
                <Field
                  name="tree"
                  component={renderTreeSelectField}
                  treeData={treeData}
                  treeDefaultExpandAll={false}
                  treeDefaultExpandedKeys={['*,']}
                  className={
                    theme === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                  dropdownClassName={
                    theme === 'theme-light'
                      ? 'rc-tree-select-dropdown-light'
                      : 'rc-tree-select-dropdown-dark'
                  }
                />
              )}
              {this.props.pattern && (
                <Field
                  name="text"
                  component="textarea"
                  type="text"
                  placeholder={this.props.colDef.headerName}
                />
              )}
            </div>
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" type="submit">
                {this.props.literals.forms.submit}
              </Button>
              <Button color="primary" onClick={this.props.toggleModalSession}>
                {this.props.literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}
const selector = formValueSelector('concentration_per_login_table_form');

function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    pattern: selector(state, 'pattern'),
    initialValues: {
      tree: ownProps.value === '*,' ? ['*,'] : ownProps.value.split(','),
      text: ownProps.value
    }
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(
  reduxForm({
    form: 'concentration_per_login_table_form' // a unique identifier for this form
  })(SessionEdit)
);
