/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const data = {
    name: values.name,
    group: values.group ? values.group.value : null,
    currency: values.currency ? values.currency.value : 'USD',
    category: values.category ? parseInt(values.category.value, 10) : null,
    tm_models: values.tm_models ? values.tm_models.join() : null,
    type: values.type ? parseInt(values.type.value, 10) : null,
    stopout: parseInt(values.stopout, 10),
    exposure_limit: parseFloat(values.exposure_limit),
    exposure_delta: -1,
    exposure_delta_span: -1,
    underflow: -1,
    warn_levels: values.warn_levels,
    description: values.description,
    state: values.state ? 1 : 0,
    square_off_mode: values.square_off_mode ? parseInt(values.square_off_mode.value, 10) : null,
    margin_call_level: parseFloat(values.margin_call_level),
  };

  axios.post(`${process.env.REACT_APP_BACKEND_API}/account`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.account_form.title_create, 'primary',
          props.literals.forms.success_message,
        );
        stateObj.resetAccountGroup();
        stateObj.toggle('1');
      } else {
        showNotification(
          props.literals.forms.account_form.title_create, 'danger',
          props.literals.forms.fail_message,
        );
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.account_form.title_create, 'danger', error.message);
      stateObj.hideLoader();
    });
};
