/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { change, clearFields, Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TreeSelect from 'rc-tree-select';
import { bindActionCreators } from 'redux';
import NotificationSystem from 'rc-notification';
import accountValidate from './accountValidate';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderTreeSelectField from '../../shared/components/form/TreeSelect';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import { BasicNotification } from '../../shared/components/Notification';

const renderField = ({
  input, placeholder, type, disabled, step, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

let notificationStay = null;
NotificationSystem.newInstance({}, n => notificationStay = n);
const showNotificationStay = (title, color, message) => {
  notificationStay.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 60,
    maxCount: 2,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
    key: '111',
  });
};

class AccountForm extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      clearFields: PropTypes.func.isRequired,
      search_values: PropTypes.instanceOf(Object),
      change: PropTypes.func.isRequired,
    };
    static defaultProps = {
      search_values: {},
    };
    constructor(props) {
      super(props);
      this.state = {
        takerMaker: [],
        popoverOpen: false,
      };
    }
  onChangeCategory=(selectedOption) => {
    this.props.clearFields('account_form', false, false, 'tm_models');
    if (selectedOption.value === 0) {
      this.setState({ takerMaker: this.props.state.formData.Taker });
    } else {
      this.setState({ takerMaker: this.props.state.formData.Maker });
    }
    this.props.change('type', { label: 'MARGIN', value: 0 });
  };

  onShowNotificationStay=() => {
    notificationStay.removeNotice('111');
    showNotificationStay('Risk Account Alert', 'danger', 'Please note that enabling the ' +
      'risk account or changing the square off more may result in automatic trading activities ' +
      'or stop trading activities due to the application of the risk account rules on the trading stream.');
  };
  onShowNotificationStayCheckbox=(event) => {
    if (event.target ? event.target.checked : false) {
      this.onShowNotificationStay();
    }
  };

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting, literals, theme,
    } = this.props;
    const accountGroupValues = this.props.state.formData.AccountGroup;
    const categoryValues = this.props.state.formData.Category;
    const squareOffModeValues = this.props.state.formData.SquareOffMode;
    const disabled = false;
    const { submit } = literals.forms.account_form;
    const currencyValues = [{ label: 'USD', value: 'USD' }, { label: 'EUR', value: 'EUR' },
      { label: 'GBP', value: 'GBP' }, { label: 'JPY', value: 'JPY' },
      { label: 'PLN', value: 'PLN' }, { label: 'BTC', value: 'BTC' }];
    let typeValues = [];
    if (this.props.search_values.category) {
      if (this.props.search_values.category.value === 0) {
        typeValues = this.props.state.formData.Type;
      } else if (this.props.search_values.category.value === 1) {
        typeValues = this.props.state.formData.Type ?
          this.props.state.formData.Type.filter(item => item.value !== 2) : [];
      }
    }

    let button;
    if (this.props.search_values.state || (this.props.search_values.square_off_mode ?
      this.props.search_values.square_off_mode.value > 0 : false)) {
      button = (
        <span>
          <Button
            id="PopoverTop"
            color="primary"
            type="button"
            onClick={this.togglePopover}
          >{submit}
          </Button>
          <Popover
            placement="top"
            isOpen={this.state.popoverOpen}
            target="PopoverTop"
            toggle={this.togglePopover}
            className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
          >
            <PopoverHeader>{literals.forms.account_form.confirm_risk_account}</PopoverHeader>
            <PopoverBody>
              <span>{literals.forms.account_form.confirm_risk_account_note}
              </span>
              <ButtonToolbar className="btn-toolbar--center">
                <Button className="btn btn-blue" onClick={this.togglePopover}>
                  {literals.forms.cancel}
                </Button>
                <Button color="primary" onClick={() => { this.togglePopover(); handleSubmit(); }}>{submit}</Button>
              </ButtonToolbar>
            </PopoverBody>
          </Popover>
        </span>);
    } else {
      button = <Button color="primary" type="submit">{submit}</Button>;
    }
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form className="form form--horizontal" onSubmit={handleSubmit}>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.account_form.name}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      placeholder={literals.forms.account_form.name}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.account_form.group}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="group"
                      component={renderSelectField}
                      type="text"
                      options={accountGroupValues}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                  >{literals.forms.account_form.currency}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="currency"
                      component={renderSelectField}
                      type="text"
                      options={currencyValues}
                      placeholder={literals.forms.account_form.currency}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                  >{literals.forms.account_form.category}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="category"
                      component={renderSelectField}
                      type="text"
                      options={categoryValues}
                      disabled={disabled}
                      onChange={this.onChangeCategory}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.account_form.taker_maker}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="tm_models"
                      component={renderTreeSelectField}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                      treeData={this.state.takerMaker}
                      className={theme.className === 'theme-light' ?
                          'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                      dropdownClassName={theme.className === 'theme-light' ?
                          'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                  >{literals.forms.account_form.type}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="type"
                      component={renderSelectField}
                      type="text"
                      options={typeValues}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.account_form.stopout}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="stopout"
                      component={renderField}
                      type="number"
                      placeholder={literals.forms.account_form.stopout}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.account_form.square_off_mode}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="square_off_mode"
                      component={renderSelectField}
                      type="text"
                      options={squareOffModeValues}
                      disabled={disabled}
                      onChange={this.onShowNotificationStay}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{literals.forms.account_form.state}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="state"
                      component={renderCheckBoxField}
                      label={literals.forms.account_form.state}
                      disabled={disabled}
                      onChange={this.onShowNotificationStayCheckbox}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                  >{literals.forms.account_form.exposure_limit}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="exposure_limit"
                      component={renderField}
                      type="number"
                      step="any"
                      placeholder={literals.forms.account_form.exposure_limit}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                  >{literals.forms.account_form.warn_levels}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="warn_levels"
                      component={renderField}
                      type="text"
                      placeholder={literals.forms.account_form.warn_levels}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                  >{literals.forms.account_form.margin_call_level}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="margin_call_level"
                      component={renderField}
                      type="number"
                      step="any"
                      placeholder={literals.forms.account_form.margin_call_level}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                  >{literals.forms.account_form.description}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="description"
                      component={renderField}
                      type="text"
                      placeholder={literals.forms.account_form.description}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  {button}
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => this.props.state.toggle('1')}
                  >{literals.forms.account_form.cancel}
                  </Button>
                  <Button type="button" onClick={reset} disabled={pristine || submitting}>
                    {literals.forms.account_form.reset}
                  </Button>
                </ButtonToolbar>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    search_values: getFormValues('account_form')(state),
    initialValues: {
      currency: { label: 'USD', value: 'USD' },
      stopout: 50,
      square_off_mode: { label: 'DISABLED', value: 0 },
      state: false,
      exposure_limit: -1,
      warn_levels: ',,',
      margin_call_level: 100,
    },
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change, clearFields }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'account_form', // a unique identifier for this form
  validate: accountValidate,
})(AccountForm));
