import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Websocket from 'react-websocket';
import { setMarketWatchColor } from '../../../redux/actions/marketWatchActions';
import { LiteralProps } from '../../../shared/prop-types/ReducerProps';
import memoizedRefreshToken from '../../../shared/refreshToken';

@inject('rootStore')
class TopbarConnectionStatus extends PureComponent {
  static propTypes = {
    marginLeft: PropTypes.number,
    className: PropTypes.string,
    rootStore: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    literals: LiteralProps.isRequired,
  };
  static defaultProps = {
    marginLeft: 5,
    className: 'conn__status',
  };

  constructor(props) {
    super(props);
    this.state = {
      fixStatus: true,
    };
  }

  componentDidMount() {
    this.refreshTokenCheck = setInterval(this.refreshToken, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTokenCheck);
  }

  refreshToken=() => {
    const expire = moment(this.props.rootStore.authStore.expire);
    const now = moment();
    const duration = moment.duration(expire.diff(now));
    const minutes = duration.asSeconds();
    if (minutes < 10) {
      memoizedRefreshToken(this.props);
    }
  }

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    if ('fix_conn_status' in data) {
      if (!data.fix_conn_status.status) {
        this.props.dispatch(setMarketWatchColor(false));
        this.props.dispatch(setMarketWatchColor(true));
      }
      this.setState({ fixStatus: data.fix_conn_status.status });
    }
  };
  socketOpenListener = () => {
    this.sendMessage(JSON.stringify({
      key: 'fix_conn_status',
      value: [],
    }));
  };
  sendMessage=(message) => {
    this.refWebSocket.sendMessage(message);
  };

  render() {
    let className = 'fa fa-link';
    let style = '#009c3a';
    let status = this.props.literals.topbar.connected;
    if (!this.state.fixStatus) {
      className = 'fa fa-unlink';
      style = '#e53935';
      status = this.props.literals.topbar.disconnected;
    }

    return (
      <div className={`topbar__profile ${this.props.className}`}>
        <div style={{ lineHeight: '20px' }}>
          <p />
          <p style={{
            display: 'inline-block',
            marginLeft: this.props.marginLeft + 15,
            backgroundColor: '#2f84ff',
            borderRadius: 5,
            padding: '0 5px',
            color: '#ffffff',
            fontWeight: 'bold',
          }}
          >
            {process.env.REACT_APP_ENV === 'production' ? 'LIVE' : 'DEMO'}
          </p>{this.state.collapse}
        </div>
        <div style={{ lineHeight: 0 }}>
          <p
            className={className}
            style={{
                color: style,
                fontWeight: 'bold',
              }}
          />
          <p style={{
            display: 'inline-block',
            marginLeft: this.props.marginLeft,
          }}
          >
            {status}
          </p>{this.state.collapse}
        </div>
        <Websocket
          url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
          reconnectIntervalInMilliSeconds={1000}
          onMessage={this.socketMessageListener}
          onOpen={this.socketOpenListener}
          ref={(WS) => {
            this.refWebSocket = WS;
          }}
        />
      </div>
    );
  }
}

export default (connect(state => ({
  literals: state.literals,
}))(withRouter(TopbarConnectionStatus)));
