/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Nav, NavItem, NavLink,
  TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import { getFormValues, reset } from 'redux-form';
import classnames from 'classnames';
import axios from 'axios';
import { inject } from 'mobx-react';
import _ from 'lodash';
import Websocket from 'react-websocket';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import TradingSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import MarketWatchView from './MarketWatchView';
import OrderLogView from './OrderLogView';
import PositionView from './PositionView';
import AccountBalanceView from './AccountBalanceView';
import Loader from '../../shared/components/Loader';
import OrderStatusFilledAlertAudio from '../../audio/filled.mp3';
import OrderStatusRejectedAlertAudio from '../../audio/rejected.mp3';
import OpenOrderView from './OpenOrderView';

const renderField = ({
  input, placeholder, type, disabled, step, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class Trading extends PureComponent {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      literals: LiteralProps.isRequired,
      search_values: PropTypes.instanceOf(Object),
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    static defaultProps = {
      search_values: {},
    };
    constructor(props) {
      super(props);
      this.state = {
        activeTab: '1',
        selectedSymbols: {},
        selectedNode: {},
        selectedAccount: {},
        loaderShow: false,
        collapse: '',
        marketWatch: [],
        marketWatchLast: [],
        positionWatch: [],
        orderLogWatch: [],
        accountBalanceWatch: [],
      };
      this.orderStatusFilledAlertAudio = new Audio(OrderStatusFilledAlertAudio);
      this.orderStatusRejectedAlertAudio = new Audio(OrderStatusRejectedAlertAudio);
    }

    componentDidMount() {
      setTimeout(() => {
        const symbols = this.props.search_values.symbol ? [this.props.search_values.symbol] : [];
        const { feed } = this.props.search_values;
        const feedSymbols = symbols.reduce((result, symbol) => {
          if (typeof feed === 'object' && symbol && typeof feed.value !== 'undefined') {
            result.push(`${feed.value}||${symbol}`);
          } return result;
        }, []);
        const dataToSend = {
          feed_symbols: feedSymbols,
        };
        axios.post(
          `${process.env.REACT_APP_BACKEND_API}/trading/market_data?page=trading_platform`,
          JSON.stringify(dataToSend),
        )
          .then((response) => {
            this.setState({ marketWatchLast: response.data });
          })
          .catch(() => {
          });
      }, 0);
    }

    componentWillUnmount() {
      clearTimeout(this.timerCollapse);
    }

    handleSelectedSymbolsChange=(selectedSymbols) => {
      this.setState({ selectedSymbols });
    }

    handleSelectedNodeChange=(selectedNode) => {
      this.setState({ selectedNode });
    }

    handleSelectedAccountChange=(selectedAccount) => {
      this.setState({ selectedAccount });
    }

    handleSubmit=(values) => {
      const symbolItems = [];
      if (values.feed) {
        values.feed.symbol.forEach((item) => {
          symbolItems.push(...item.children);
        });
      }
      const selectedSymbols = _.find(symbolItems, ['value', values.symbol]);
      this.setState({ selectedSymbols });
      this.setState({ selectedNode: values.node });
      this.setState({ selectedAccount: values.account });
      const symbols = selectedSymbols ? [selectedSymbols] : [];
      const riskAccounts = values.risk_account ? [values.risk_account.value] : [];
      const normalAccounts = values.account ? [values.account.value] : [];
      const { feed } = values;
      const feedSymbols = symbols.reduce((result, symbol) => {
        if (typeof feed === 'object' && typeof symbol === 'object' && typeof feed.value !== 'undefined'
          && typeof symbol.value !== 'undefined' && typeof symbol.uni_symbol !== 'undefined') {
          result.push(`${feed.value}||${symbol.value}||${symbol.uni_symbol}`);
        } return result;
      }, []);
      this.sendMessage(JSON.stringify({ key: 'symbol', value: feedSymbols.join(',') }));
      this.sendMessage(JSON.stringify({
        key: 'position',
        value: JSON.stringify({
          position_account: values.radio_ra == 1 ? riskAccounts : normalAccounts,
          symbol: ['ALL'],
          type: values.radio_ra == 1 ? 'risk_account' : 'normal_account',
        }),
      }));
      this.sendMessage(JSON.stringify({ key: 'order_log', value: [] }));
      this.sendMessage(JSON.stringify({
        key: 'account_balance',
        value: JSON.stringify({ risk_account: riskAccounts }),
      }));
      this.props.dispatch(reset('trading_action_form'));
      const feedSymbolsToSend = symbols.reduce((result, symbol) => {
        if (typeof feed === 'object' && typeof symbol === 'object' && typeof feed.value !== 'undefined'
          && typeof symbol.value !== 'undefined') {
          result.push(`${feed.value}||${symbol.value}`);
        } return result;
      }, []);
      const dataToSend = {
        feed_symbols: feedSymbolsToSend,
      };
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/trading/market_data?page=trading_platform`,
        JSON.stringify(dataToSend),
      )
        .then((response) => {
          this.setState({ marketWatchLast: response.data });
        })
        .catch(() => {
        });

      this.setState({ collapse: 'force-close' });
      setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    socketMessageListener = (event) => {
      const data = JSON.parse(event);
      if ('feed_symbols' in data) {
        if (data.feed_symbols && data.feed_symbols.length) {
          this.setState({ marketWatch: data.feed_symbols });
        }
      }
      if ('feed_positions' in data && !data.api_error) {
        this.setState({ positionWatch: data.feed_positions });
      }
      if ('feed_order_logs' in data) {
        if (data.feed_order_logs && data.feed_order_logs.length) {
          this.setState({ orderLogWatch: data.feed_order_logs.sort((a, b) => b.id - a.id) });
        }
      }
      if ('account_balances' in data && !data.api_error) {
        this.setState({ accountBalanceWatch: data.account_balances });
      }
      if ('sound_alert_order_status' in data) {
        if (data.sound_alert_order_status.ord_status && data.sound_alert_order_status.ord_status.length) {
          if (data.sound_alert_order_status.ord_status === 'REJECTED') {
            this.orderStatusRejectedAlertAudio.play();
          } else if (data.sound_alert_order_status.ord_status === 'FILLED'
            || data.sound_alert_order_status.ord_status === 'PARTIALLY_FILLED') {
            this.orderStatusFilledAlertAudio.play();
          }
        }
      }
      if ('open_orders' in data) {
        this.setState({ openOrderWatch: data.open_orders });
      }
    };
    socketOpenListener = () => {
      // this.props.dispatch(reset('trading_action_form'));
      const symbolItems = [];
      if (this.props.search_values.feed) {
        this.props.search_values.feed.symbol.forEach((item) => {
          symbolItems.push(...item.children);
        });
      }
      const selectedSymbols = _.find(symbolItems, ['value', this.props.search_values.symbol]);
      this.setState({ selectedSymbols });
      this.setState({ selectedNode: this.props.search_values.node });
      this.setState({ selectedAccount: this.props.search_values.account });
      const symbols = selectedSymbols ? [selectedSymbols] : [];
      const riskAccounts = this.props.search_values.risk_account ?
        [this.props.search_values.risk_account.value] : [];
      const normalAccounts = this.props.search_values.account ?
        [this.props.search_values.account.value] : [];
      const { feed } = this.props.search_values;
      const feedSymbols = symbols.reduce((result, symbol) => {
        if (typeof feed === 'object' && typeof symbol === 'object' && typeof feed.value !== 'undefined'
          && typeof symbol.value !== 'undefined' && typeof symbol.uni_symbol !== 'undefined') {
          result.push(`${feed.value}||${symbol.value}||${symbol.uni_symbol}`);
        } return result;
      }, []);
      this.sendMessage(JSON.stringify({ key: 'symbol', value: feedSymbols.join(',') }));
      this.sendMessage(JSON.stringify({
        key: 'position',
        value: JSON.stringify({
          position_account: this.props.search_values.radio_ra == 1 ? riskAccounts : normalAccounts,
          symbol: ['ALL'],
          type: this.props.search_values.radio_ra == 1 ? 'risk_account' : 'normal_account',
        }),
      }));
      this.sendMessage(JSON.stringify({ key: 'order_log', value: [] }));
      this.sendMessage(JSON.stringify({
        key: 'account_balance',
        value: JSON.stringify({ risk_account: riskAccounts }),
      }));
      this.sendMessage(JSON.stringify({ key: 'order_status_sound_alert', value: [] }));
      this.sendMessage(JSON.stringify({
        key: 'open_orders',
        value: JSON.stringify({
          open_order_search: [
            {
              taker: this.props.search_values.node
                ? this.props.search_values.node.value
                : '',
              tem: this.props.search_values.account
                ? this.props.search_values.account.value
                : '',
            },
          ],
        }),
      }));
    };
    sendMessage=(message) => {
      this.refWebSocket.sendMessage(message);
    };

    render() {
      const { literals } = this.props;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.trading_platform}
                </h5>
              </div>
              <Collapse
                title={literals.tables.trading_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <TradingSearchForm
                  onSubmit={this.handleSubmit}
                  showLoader={this.showLoader}
                  hideLoader={this.hideLoader}
                  sendMessage={this.sendMessage}
                  handleSelectedSymbolsChange={this.handleSelectedSymbolsChange}
                  handleSelectedNodeChange={this.handleSelectedNodeChange}
                  handleSelectedAccountChange={this.handleSelectedAccountChange}
                />
              </Collapse>
              <MarketWatchView
                selectedSymbols={this.state.selectedSymbols}
                marketWatch={this.state.marketWatch}
                marketWatchLast={this.state.marketWatchLast}
                selectedAccount={this.state.selectedAccount}
                selectedNode={this.state.selectedNode}
              />
              <OrderLogView
                orderLogWatch={this.state.orderLogWatch}
              />
              <div className="tabs tabs--bordered-top">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                                        this.toggle('1');
                                    }}
                      >
                        {literals.sidebar.position}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <PositionView
                        activeTab={this.state.activeTab}
                        positionWatch={this.state.positionWatch}
                      />
                      <h5 className="bold-text">{literals.sidebar.account_balance}
                      </h5>
                      <AccountBalanceView
                        activeTab={this.state.activeTab}
                        accountBalanceWatch={this.state.accountBalanceWatch}
                      />
                      <h5 className="bold-text">{literals.sidebar.open_order}</h5>
                      <OpenOrderView
                        activeTab={this.state.activeTab}
                        openOrderWatch={this.state.openOrderWatch}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
              <Websocket
                url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
                reconnectIntervalInMilliSeconds={1000}
                onMessage={this.socketMessageListener}
                onOpen={this.socketOpenListener}
                ref={(WS) => {
                  this.refWebSocket = WS;
                }}
              />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  literals: state.literals,
  search_values: getFormValues('trading_search_form')(state),
}))(Trading);
