/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { change, getFormValues } from 'redux-form';
import ActionSchedulerActionRenderer from './actionRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import ActionSchedulerForm from './form';
import actionSchedulerAction from './action';
import Loader from '../../shared/components/Loader';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import StateRenderer from '../../shared/components/StateRenderer';
import MultiEditor from './MultiEditor';
import { ACTION_SCHEDULER_ADD_GROUPS } from '../../redux/actions/actionTypes';
import SessionRenderer from '../../shared/components/session/SessionRenderer';

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class ActionScheduler extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
      dispatch: PropTypes.func.isRequired,
      search_values: PropTypes.instanceOf(Object),
    };
    static defaultProps = {
      search_values: {},
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.stylesToUpdate = {};
      const { literals } = this.props;
      const tableID = 'sidebar_action_scheduler';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.action_scheduler_form.id,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.id'),
            field: 'id',
            cellClass: 'number',
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            editable: false,
          },
          {
            headerName: literals.forms.action_scheduler_form.type,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.type'),
            field: 'type_value',
            editable: false,
          },
          {
            headerName: literals.forms.action_scheduler_form.groups,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.groups'),
            field: 'groups',
            editable: false,
            cellRenderer: 'multiEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.symbols,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.symbols'),
            field: 'symbols',
            editable: false,
            cellRenderer: 'multiEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.securities,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.securities'),
            field: 'securities',
            editable: false,
            cellRenderer: 'multiEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.in_session_values,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.in_session_values'),
            field: 'in_session_values_value',
            editable: false,
            cellRenderer: 'multiEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.out_session_values,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.out_session_values'),
            field: 'out_of_session_values_value',
            editable: false,
            cellRenderer: 'multiEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.date_calendar,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.date_calendar'),
            field: 'date_calendar',
            editable: false,
            cellRenderer: 'multiEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.priority,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.priority'),
            field: 'priority',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: [],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.description,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.description'),
            field: 'description',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.sessions,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.sessions'),
            field: 'sessions',
            editable: false,
            cellRenderer: 'sessionRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.action_scheduler_form.state,
            headerValueGetter: () => this.localizeHeader('literals.forms.action_scheduler_form.state'),
            field: 'state_value',
            editable: false,
            cellRenderer: 'stateRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.tables.ag_table.actions,
            headerValueGetter: () => this.localizeHeader('literals.tables.ag_table.actions'),
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
          },
        ],
        frameworkComponents: {
          actionRenderer: ActionSchedulerActionRenderer,
          multiEditor: MultiEditor,
          stateRenderer: StateRenderer,
          sessionRenderer: SessionRenderer,
        },
        rowData: [],
        rowOldData: [],
        formData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.id;
        },
        cellEditUpdate: false,
        activeTab: '1',
        height: window.innerHeight,
        loaderShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.security_form.name !== prevProps.literals.forms.security_form.name) {
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.onGridSizeChanged(params);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/action_scheduler`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.ActionScheduler)) });
          this.gridColumnApi.getColumn('priority')
            .getColDef().cellEditorParams.values =
              response.data.Priority ? response.data.Priority.map(a => parseInt(a.label, 10)) : [];
        })
        .catch(() => {
        });
    }

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridApi.refreshCells({ rowNodes: [params.node], columns: ['id'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridApi.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'type_value') {
          params.data.type = this.state.formData.TypeObj[params.newValue].value;
        }
        if (params.colDef.field === 'state_value') {
          params.data.state = this.state.formData.StateObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['id', 'type_value', 'groups', 'symbols', 'securities', 'in_session_values_value',
          'out_of_session_values_value', 'date_calendar', 'priority', 'description', 'sessions', 'state_value'],
        fileName: 'ActionScheduler.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['id', 'type_value', 'groups', 'symbols', 'securities', 'in_session_values_value',
          'out_of_session_values_value', 'date_calendar', 'priority', 'description', 'sessions', 'state_value'],
        fileName: 'ActionScheduler.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    updateData = (data) => {
      this.setState({ rowData: data.ActionScheduler });
      const selectObj = { label: 'Select Value', value: '' };
      const spreadExecValues = [
        selectObj,
        ...(data.SpreadExec || []),
      ];
      const execModeValues = [
        selectObj,
        ...(data.ExecMode || []),
      ];
      const hamSingleValues = [
        selectObj,
        ...(data.HAMSingle || []),
      ];
      const mmSingleValues = [
        selectObj,
        ...(data.MMSingle || []),
      ];
      const llActionValues = [
        selectObj,
        ...(data.LLAction || []),
      ];
      this.setState({
        formData: {
          Type: data.Type,
          TypeObj: arrayToObject(data.Type, 'label'),
          Symbol: data.Symbol,
          State: data.State,
          StateObj: arrayToObject(data.State, 'label'),
          Security: data.Security,
          Priority: data.Priority,
          SecuritySymbol: data.SecuritySymbol,
          MM: data.MM,
          HAM: data.HAM,
          TF: data.TF,
          TEM: data.TEM,
          SpreadExec: spreadExecValues,
          SpreadExecObj: arrayToObject(spreadExecValues, 'label'),
          LPSession: data.LPSession,
          ExecMode: execModeValues,
          ExecModeObj: arrayToObject(execModeValues, 'label'),
          HAMSingle: hamSingleValues,
          HAMSingleObj: arrayToObject(hamSingleValues, 'label'),
          MMSingle: mmSingleValues,
          MMSingleObj: arrayToObject(mmSingleValues, 'label'),
          LLAction: llActionValues,
          LLActionObj: arrayToObject(llActionValues, 'label'),
        },
      });
      const { type } = this.props.search_values;
      this.props.dispatch(change('action_scheduler_form', 'symbols_tree', ['*,']));
      this.props.dispatch(change('action_scheduler_form', 'securities_tree', ['*,']));
      let groups = [];
      if (type.value === 1) {
        groups = data.MM;
      } else if (type.value === 2) {
        groups = data.HAM;
      } else if (type.value === 3) {
        groups = data.TF;
      } else if (type.value === 4) {
        groups = data.TEM;
      }
      this.props.dispatch({ type: ACTION_SCHEDULER_ADD_GROUPS, payload: { groups } });
    };

    updateActionScheduler=() => {
      this.showLoader();
      const len = Object.keys(this.rowsToUpdate).length;
      let i = 0;
      let j = 0;
      const successItems = [];
      const failItems = [];
      const errorItems = [];
      const rowData = _.keyBy(this.state.rowOldData, 'id');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.id];
        const dataToUpdate = {
          id: parseInt(values.id, 10),
          type: parseInt(values.type, 10),
          groups: values.groups,
          symbols: values.symbols,
          securities: values.securities,
          in_session_values: values.in_session_values,
          out_of_session_values: values.out_of_session_values,
          date_calendar: values.date_calendar,
          priority: parseInt(values.priority, 10),
          description: values.description,
          sessions: values.sessions,
          state: parseInt(values.state, 10),
          old_data: {
            id: parseInt(oldRow.id, 10),
            type: parseInt(oldRow.type, 10),
            groups: oldRow.groups,
            symbols: oldRow.symbols,
            securities: oldRow.securities,
            in_session_values: oldRow.in_session_values,
            out_of_session_values: oldRow.out_of_session_values,
            date_calendar: oldRow.date_calendar,
            priority: parseInt(oldRow.priority, 10),
            description: oldRow.description,
            sessions: oldRow.sessions,
            state: parseInt(oldRow.state, 10),
          },
        };
        axios.put(
          `${process.env.REACT_APP_BACKEND_API}/action_scheduler`,
          JSON.stringify(dataToUpdate),
        )
          .then((response) => {
            i += 1;
            if (response.data.status) {
              successItems.push(values.id);
            } else {
              failItems.push(values.id);
            }
            if (len === i) {
              if (successItems.length > 0) {
                showNotification(
                  this.props.literals.forms.action_scheduler_form.title_update, 'primary',
                  `${this.props.literals.forms.success_message} - ${successItems.join(', ')}`,
                );
              }
              if (failItems.length > 0) {
                showNotification(
                  this.props.literals.forms.action_scheduler_form.title_update, 'danger',
                  `${this.props.literals.forms.fail_message} - ${failItems.join(', ')}`,
                );
              }
              this.resetLocalActionScheduler();
              this.resetActionScheduler();
            }
          })
          .catch((error) => {
            j += 1;
            errorItems.push(values.id);
            if (len === j) {
              if (errorItems.length > 0) {
                showNotification(
                  this.props.literals.forms.action_scheduler_form.title_update,
                  'danger', `${error.message} - ${errorItems.join(', ')}`,
                );
              }
              this.resetLocalActionScheduler();
              this.resetActionScheduler();
            }
          });
      });
    };

    resetLocalActionScheduler=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({ rowNodes: [node], columns: ['id'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridApi.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    resetActionScheduler=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/action_scheduler`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.ActionScheduler)) });
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    localizeHeader=header => _.get(this.props, header)

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--bordered-top tabs__custom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                                        this.toggle('1');
                                    }}
                      >
                        {literals.sidebar.action_scheduler}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                                        this.toggle('2');
                                    }}
                      >
                        {literals.forms.action_scheduler_form.title_create}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="outer-grid-label">
                        {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                      </div>
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={this.updateActionScheduler}
                              className="icon"
                              color="primary"
                            ><p className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={() => {
 this.showLoader();
                              this.resetLocalActionScheduler(); this.resetActionScheduler();
}}
                              className="icon"
                              color="primary"
                            ><p
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_excel}>
                            <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                              <p className="fa fa-file-excel" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_csv}>
                            <Button
                              onClick={this.onBtExportCSV}
                              className="icon"
                              color="primary"
                            >
                              <p className="fa fa-file-alt" />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>
                      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                        <div
                          id={this.myGridID}
                          style={{
                                    boxSizing: 'border-box',
                                    height: this.state.height - REDUCE_HEIGHT,
                                    width: '100%',
                                }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state.rowData}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                            context={{
                              formData: this.state.formData,
                              theme: this.props.theme,
                              literals: this.props.literals,
                              resetActionScheduler: this.resetActionScheduler,
                              showLoader: this.showLoader,
                              hideLoader: this.hideLoader,
                            }}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <ActionSchedulerForm
                        {...this.props}
                        state={{
                          formData: this.state.formData,
                          resetActionScheduler: this.resetActionScheduler,
                          toggle: this.toggle,
                          showLoader: this.showLoader,
                          hideLoader: this.hideLoader,
                        }}
                        onSubmit={actionSchedulerAction}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  search_values: getFormValues('action_scheduler_form')(state),
}))(ActionScheduler);
