/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable  no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import CheckIcon from 'mdi-react/CheckIcon';
import { connect } from 'react-redux';
import DepthLevelEdit from './DepthLevelEdit';
import DepthLevelEditForm from './DepthLevelEditForm';
import { LiteralProps } from '../../prop-types/ReducerProps';

class DepthLevelRenderer extends PureComponent {
    static propTypes = {
      context: PropTypes.instanceOf(Object).isRequired,
      value: PropTypes.string.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
      literals: LiteralProps.isRequired,
    };
    state = {
      depthLevelModalShow: false,
      selectedOption: 'depth_level_interface',
      selectedMidPoint: false,
    };
    componentDidMount() {
      this.loadMidPoint();
    }
    loadMidPoint=() => {
      let depthLevel = this.props.value.trim() ?
        this.props.value.replace(/,$/, '').split(',') : [];
      if (typeof depthLevel[0] !== 'undefined') {
        depthLevel = depthLevel[0].split(':');
        this.setState({
          selectedMidPoint:
              typeof depthLevel[6] !== 'undefined' && (depthLevel[6] === '1' || depthLevel[6] === 1),
        });
      }
    };
    toggleModalDepthLevel = () => {
      this.setState({ depthLevelModalShow: !this.state.depthLevelModalShow });
    };
    showModalDepthLevel = () => {
      if (!this.state.depthLevelModalShow) {
        this.setState({ depthLevelModalShow: true });
      }
    };
    submitDepthLevel=(depthLevel) => {
      if (this.state.selectedOption === 'depth_level_interface') {
        const depthLevelRaw = depthLevel.trim() ? depthLevel.replace(/,$/, '').split(',') : [];
        if (typeof depthLevelRaw[0] !== 'undefined') {
          const depthLevelFirst = depthLevelRaw[0].split(':');
          if (this.state.selectedMidPoint) {
            if (typeof depthLevelFirst[6] !== 'undefined') {
              depthLevelFirst[6] = '1';
            } else {
              depthLevelFirst.push('1');
            }
          } else if (typeof depthLevelFirst[6] !== 'undefined') {
            depthLevelFirst[6] = '0';
          }
          if (depthLevelFirst.length > 0) {
            depthLevelRaw[0] = depthLevelFirst.join(':');
          }
          depthLevel = depthLevelRaw.join(',');
        }
      }
      const rowNode = this.props.node;
      rowNode.setDataValue(this.props.colDef.field, depthLevel);
      this.toggleModalDepthLevel();
    };
    resetDepthLevel=() => {
      this.toggleModalDepthLevel();
    };
    handleChange = (selectedOption) => {
      this.setState({ selectedOption: selectedOption.target.value });
    };
    handleChangeMidPoint = (selectedOption) => {
      this.setState({ selectedMidPoint: selectedOption.target.checked });
    };
    render() {
      const theme = this.props.context.theme.className;
      const { selectedOption, selectedMidPoint } = this.state;
      return (
        <div onDoubleClick={this.showModalDepthLevel}>
          <label>
            {this.props.value}
          </label>
          <Modal
            isOpen={this.state.depthLevelModalShow}
            toggle={() => this.toggleModalDepthLevel}
            className={`modal-dialog--primary modal-dialog--header crp-modal session-modal ${theme}`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleModalDepthLevel()} />
              <h4 className="bold-text  modal__title">{this.props.colDef.headerName}</h4>
            </div>
            <div className="modal__body risk_account_modal_body">
              <label
                className="radio-btn"
              >
                <input
                  className="radio-btn__radio"
                  type="radio"
                  name="depth_level_edit"
                  value="depth_level_interface"
                  checked={selectedOption === 'depth_level_interface'}
                  onChange={this.handleChange}
                />
                <span className="radio-btn__radio-custom" />
                <span className="radio-btn__label">
                  {this.props.literals.tables.ag_table.interface}
                </span>
              </label>
              <label
                className="radio-btn"
              >
                <input
                  className="radio-btn__radio"
                  type="radio"
                  name="depth_level_edit"
                  value="depth_level_text"
                  checked={selectedOption === 'depth_level_text'}
                  onChange={this.handleChange}
                />
                <span className="radio-btn__radio-custom" />
                <span className="radio-btn__label">
                  {this.props.literals.tables.ag_table.free_text}
                </span>
              </label>
              {this.state.selectedOption === 'depth_level_interface' &&
                <span style={{ display: 'inline-block', position: 'absolute' }}>
                  <label
                    className="checkbox-btn"
                  >
                    <input
                      className="checkbox-btn__checkbox"
                      type="checkbox"
                      name="mid_point"
                      checked={selectedMidPoint}
                      onChange={this.handleChangeMidPoint}
                    />
                    <span
                      className="checkbox-btn__checkbox-custom"
                    >
                      <CheckIcon />
                    </span>
                    <span className="checkbox-btn__label">
                 MidPoint
                    </span>
                  </label>
                </span>
                }
              {this.state.selectedOption === 'depth_level_text' &&
              <DepthLevelEditForm
                depthLevels={this.props.value}
                initialDepthLevelValue={this.props.value}
                submitDepthLevel={this.submitDepthLevel}
                resetDepthLevel={this.resetDepthLevel}
                headerName={this.props.colDef.headerName}
              />
              }
              {this.state.selectedOption === 'depth_level_interface' &&
              <DepthLevelEdit
                depthLevels={this.props.value}
                initialDepthLevelValue={this.props.value}
                submitDepthLevel={this.submitDepthLevel}
                resetDepthLevel={this.resetDepthLevel}
                headerName={this.props.colDef.headerName}
              />
              }
            </div>
            <div className="modal__footer" />
          </Modal>
        </div>
      );
    }
}
export default connect(state => ({
  literals: state.literals,
}))(DepthLevelRenderer);

