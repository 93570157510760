/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, (n) => (notification = n));
  const showNotification = (title, color, message) => {
    notification.notice({
      content: (
        <BasicNotification title={title} message={message} color={color} />
      ),
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up'
    });
  };
  const data = {
    unisymbol: _.trim(values.unisymbol),
    shared_id: _.trim(values.shared_id),
    price_scale_in: parseFloat(values.price_scale_in),
    price_scale_out: parseFloat(values.price_scale_out),
    volume_scale_in: parseFloat(values.volume_scale_in),
    volume_scale_out: parseFloat(values.volume_scale_out),
    description: _.trim(values.description),
    state: values.state ? 1 : 0
  };

  axios
    .post(
      `${process.env.REACT_APP_BACKEND_API}/maker/scaling_symbol`,
      JSON.stringify(data)
    )
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.maker_scaling_symbol_form.title_create,
          'primary',
          props.literals.forms.success_message
        );
        stateObj.resetMakerScaling();
        stateObj.toggle('1');
      } else {
        showNotification(
          props.literals.forms.maker_scaling_symbol_form.title_create,
          'danger',
          props.literals.forms.fail_message
        );
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(
        props.literals.forms.maker_scaling_symbol_form.title_create,
        'danger',
        error.message
      );
      stateObj.hideLoader();
    });
};
