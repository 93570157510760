/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, formValueSelector, clearFields, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderTreeSelectField from './TreeSelect';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class TradingSearchForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      risk_account: PropTypes.instanceOf(Object),
      node: PropTypes.instanceOf(Object),
      account: PropTypes.instanceOf(Object),
      feed: PropTypes.instanceOf(Object),
      symbol: PropTypes.string,
      radio_ra: PropTypes.string,
      change: PropTypes.func.isRequired,
      clearFields: PropTypes.func.isRequired,
      showLoader: PropTypes.func.isRequired,
      hideLoader: PropTypes.func.isRequired,
      sendMessage: PropTypes.func.isRequired,
      handleSelectedSymbolsChange: PropTypes.func.isRequired,
      handleSelectedNodeChange: PropTypes.func.isRequired,
      handleSelectedAccountChange: PropTypes.func.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    static defaultProps = {
      risk_account: {},
      node: {},
      account: {},
      feed: {},
      symbol: '',
      radio_ra: '',
    };
    constructor(props) {
      super(props);
      this.state = {
        risk_account: [],
        node: [],
        account: [],
        feed: [],
        symbol: [],
        taker: [],
        riskAccountDisable: false,
      };
    }
    componentDidMount() {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/trading/platform/search`)
        .then((response) => {
          this.setState({ risk_account: response.data.risk_account || [] });
          if (this.props.radio_ra == 1) {
            this.setState({ node: this.props.risk_account.node });
          } else {
            this.setState({ node: response.data.taker || [] });
          }
          this.setState({ account: this.props.node.account });
          this.setState({ feed: this.props.node.feed });
          this.setState({ taker: response.data.taker || [] });

          const riskAccountItem = _.find(response.data.risk_account, ['value', this.props.risk_account.value]);
          this.props.change('risk_account', riskAccountItem);
          let nodeItem;
          if (this.props.radio_ra == 1) {
            nodeItem = _.find(riskAccountItem.node, ['value', this.props.node.value]);
          } else {
            this.setState({ riskAccountDisable: true });
            nodeItem = _.find(response.data.taker, ['value', this.props.node.value]);
          }
          this.props.change('node', nodeItem);
          const accountItem = _.find(nodeItem.account, ['value', this.props.account.value]);
          this.props.change('account', accountItem);
          const feedItem = _.find(nodeItem.feed, ['value', this.props.feed.value]);
          if (feedItem) {
            this.props.change('feed', feedItem);
            this.onChangeFeed(feedItem);
          }
        })
        .catch(() => {
        });
    }
    onChangeRiskAccount=(selectedOption) => {
      this.props.clearFields('trading_search_form', false, false, 'node');
      this.props.clearFields('trading_search_form', false, false, 'account');
      this.props.clearFields('trading_search_form', false, false, 'feed');
      this.props.clearFields('trading_search_form', false, false, 'symbol');
      this.setState({ node: selectedOption.node });
      this.setState({ account: [] });
      this.setState({ feed: [] });
      this.setState({ symbol: [] });
    };
    onChangeNode=(selectedOption) => {
      this.props.clearFields('trading_search_form', false, false, 'account');
      this.props.clearFields('trading_search_form', false, false, 'feed');
      this.props.clearFields('trading_search_form', false, false, 'symbol');
      this.setState({ account: selectedOption.account });
      this.setState({ feed: selectedOption.feed });
      this.setState({ symbol: [] });
    };
    onChangeFeed=(selectedOption) => {
      this.props.showLoader();
      const route = `${process.env.REACT_APP_BACKEND_API}/trading/platform/node_feed_symbol/search`;
      const nodeFeed = encodeURIComponent(selectedOption.value);
      axios.get(`${route}?node_feed=${nodeFeed}&node_account=${this.props.account.value}`)
        .then((response) => {
          this.setState({ symbol: response.data.feed_symbol });
          const feedValue = selectedOption;
          feedValue.symbol = response.data.feed_symbol;
          this.props.change('feed', feedValue);
          const allSymbols = [];
          response.data.feed_symbol.forEach((item) => {
            allSymbols.push(...item.children);
          });
          const symbolItem = _.find(allSymbols, ['value', this.props.symbol]);
          if (symbolItem) {
            this.props.change('symbol', symbolItem.value);
          } else {
            this.props.clearFields('trading_search_form', false, false, 'symbol');
          }
          this.props.handleSelectedSymbolsChange(symbolItem);
          this.props.handleSelectedNodeChange(this.props.node);
          this.props.handleSelectedAccountChange(this.props.account);
          const { feed } = this.props;
          const symbols = symbolItem ? [symbolItem] : [];
          const feedSymbols = symbols.reduce((result, symbol) => {
            if (typeof feed === 'object' && typeof symbol === 'object' && typeof feed.value !== 'undefined'
              && typeof symbol.value !== 'undefined' && typeof symbol.uni_symbol !== 'undefined') {
              result.push(`${feed.value}||${symbol.value}||${symbol.uni_symbol}`);
            } return result;
          }, []);
          this.props.sendMessage(JSON.stringify({ key: 'symbol', value: feedSymbols.join(',') }));
          this.props.sendMessage(JSON.stringify({
            key: 'open_orders',
            value: JSON.stringify({
              open_order_search: [
                {
                  taker: this.props.node ? this.props.node.value : '',
                  tem: this.props.account ? this.props.account.value : '',
                },
              ],
            }),
          }));
          this.props.hideLoader();
        })
        .catch(() => {
          this.props.hideLoader();
        });
    };
  onChangeSelectRiskAccount=() => {
    this.props.clearFields('trading_search_form', false, false, 'risk_account');
    this.props.clearFields('trading_search_form', false, false, 'node');
    this.props.clearFields('trading_search_form', false, false, 'account');
    this.props.clearFields('trading_search_form', false, false, 'feed');
    this.props.clearFields('trading_search_form', false, false, 'symbol');
    this.setState({ riskAccountDisable: false });
    this.setState({ node: [] });
    this.setState({ account: [] });
    this.setState({ feed: [] });
    this.setState({ symbol: [] });
  };
    onChangeSelectTakerExecutionModel=() => {
      this.props.clearFields('trading_search_form', false, false, 'risk_account');
      this.props.clearFields('trading_search_form', false, false, 'node');
      this.props.clearFields('trading_search_form', false, false, 'account');
      this.props.clearFields('trading_search_form', false, false, 'feed');
      this.props.clearFields('trading_search_form', false, false, 'symbol');
      this.setState({ riskAccountDisable: true });
      this.setState({ node: [] });
      this.setState({ account: [] });
      this.setState({ feed: [] });
      this.setState({ symbol: [] });
      this.setState({ node: this.state.taker });
    };
    onChangeOneClickTrading=(val) => {
      this.props.rootStore.reportStore.reportState.oneClickTrading = val === 'Enable';
    }
    render() {
      const {
        handleSubmit, literals, theme,
      } = this.props;
      return (
        <form className="form form--horizontal trading-search" onSubmit={handleSubmit}>
          <div className="form__half form__search">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="radio_ra"
                  component={renderRadioButtonField}
                  label={literals.tables.position_table.risk_account}
                  radioValue="1"
                  onChange={this.onChangeSelectRiskAccount}
                />
                <Field
                  name="radio_ra"
                  component={renderRadioButtonField}
                  label={literals.tables.position_table.account}
                  radioValue="2"
                  onChange={this.onChangeSelectTakerExecutionModel}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.risk_account}</span>
              <div className="form__form-group-field">
                <Field
                  name="risk_account"
                  component={renderSelectField}
                  type="text"
                  options={this.state.risk_account}
                  onChange={this.onChangeRiskAccount}
                  disabled={this.state.riskAccountDisable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.taker}</span>
              <div className="form__form-group-field">
                <Field
                  name="node"
                  component={renderSelectField}
                  type="text"
                  options={this.state.node}
                  onChange={this.onChangeNode}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.account}</span>
              <div className="form__form-group-field">
                <Field
                  name="account"
                  component={renderSelectField}
                  type="text"
                  options={this.state.account}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.feed}</span>
              <div className="form__form-group-field">
                <Field
                  name="feed"
                  component={renderSelectField}
                  type="text"
                  options={this.state.feed}
                  onChange={this.onChangeFeed}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.position_table.symbol}</span>
              <div className="form__form-group-field">
                <Field
                  name="symbol"
                  component={renderTreeSelectField}
                  treeData={this.state.symbol}
                  className={theme.className === 'theme-light' ?
                      'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                      'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.one_click_trading}</span>
              <div className="form__form-group-field">
                <Field
                  name="oneClickTrading"
                  component={renderRadioButtonField}
                  label={literals.tables.ag_table.enable}
                  radioValue="Enable"
                  onChange={this.onChangeOneClickTrading}
                />
                <Field
                  name="oneClickTrading"
                  component={renderRadioButtonField}
                  label={literals.tables.ag_table.disable}
                  radioValue="Disable"
                  defaultChecked
                  onChange={this.onChangeOneClickTrading}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.trading_table.tob_price}</span>
              <div className="form__form-group-field">
                <Field
                  name="tobPrice"
                  component={renderRadioButtonField}
                  label="Enable"
                  radioValue="Enable"
                />
                <Field
                  name="tobPrice"
                  component={renderRadioButtonField}
                  label="Disable"
                  radioValue="Disable"
                  defaultChecked
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{literals.tables.trading_table.search}</Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('trading_search_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    risk_account: selector(state, 'risk_account'),
    node: selector(state, 'node'),
    account: selector(state, 'account'),
    feed: selector(state, 'feed'),
    symbol: selector(state, 'symbol'),
    radio_ra: selector(state, 'radio_ra'),
    initialValues: {
      oneClickTrading: 'Disable',
      tobPrice: 'Disable',
      radio_ra: '1',
    },
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change, clearFields }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'trading_search_form', // a unique identifier for this form
  destroyOnUnmount: false,
})(TradingSearchForm));
