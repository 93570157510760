import React, { PureComponent } from 'react';
import { inject } from 'mobx-react';
import { connect } from 'react-redux';
import GoldenLayoutComponent from './goldenLayoutComponent';
import MakerStatus from './makerStatus';
import MakerSymbolStatus from './makerSymbolStatus';

@inject('rootStore')
class MultiWindow extends PureComponent {
  render() {
    return (
      <GoldenLayoutComponent
        htmlAttrs={{
          style: {
            height: '92vh',
          },
        }}
        config={{
          settings: {
            showPopoutIcon: false,
            showMaximiseIcon: false,
            showCloseIcon: false,
          },
          content: [{
            type: 'column',
            content: [{
              title: 'Maker Status',
              type: 'react-component',
              component: 'MakerStatus',
              isClosable: false,
            }, {
              title: 'Maker Symbol Status',
              type: 'react-component',
              component: 'MakerSymbolStatus',
              isClosable: false,
            }],
          }],
        }}
        registerComponents={(myLayout) => {
          myLayout.registerComponent('MakerStatus', MakerStatus);
          myLayout.registerComponent('MakerSymbolStatus', MakerSymbolStatus);
        }}
      />
    );
  }
}
export default connect()(MultiWindow);
