import {
  ACTION_SCHEDULER_ADD_GROUPS,
} from '../actions/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case ACTION_SCHEDULER_ADD_GROUPS:
      return {
        ...state,
        groups: action.payload.groups,
      };
    default:
      return state;
  }
};
