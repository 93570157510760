import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';

class CustomCss extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
  };
  render() {
    let customCss = '';
    if (this.props.theme.className === 'theme-light') {
      customCss = `${process.env.PUBLIC_URL}/css/goldenlayout-light-theme.css`;
    } else {
      customCss = `${process.env.PUBLIC_URL}/css/goldenlayout-dark-theme.css`;
    }
    return (
      <link rel="stylesheet" type="text/css" href={customCss} />
    );
  }
}
export default connect(state => ({
  theme: state.theme,
}))(CustomCss);
