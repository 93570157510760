const balanceTransactionValidate = (values, props) => {
  const errors = {};
  if (!values.risk_account) {
    errors.risk_account =
        `${props.literals.forms.balance_transaction_form.risk_account} ${props.literals.forms.required_validate}`;
  }
  if (!values.comment) {
    errors.comment =
        `${props.literals.forms.balance_transaction_form.comment} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default balanceTransactionValidate;
