/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import { LiteralENGlobalProps, LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import { regExDigits, regExSignedDecimalNumber, validateSessions, parseSessions } from '../../shared/helper';
import UploadData from '../../shared/components/UploadData';

let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};
class UniSymbolUpload extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
    resetUniSymbol: PropTypes.func.isRequired,
  };

  getHeaderName=(header) => {
    const { literalsENGlobal } = this.props;
    switch (header) {
      case literalsENGlobal.forms.uni_symbol_form.name:
        return { col: 'name', type: 'text' };
      case literalsENGlobal.forms.uni_symbol_form.security:
        return { col: 'security', type: 'text' };
      case literalsENGlobal.forms.uni_symbol_form.base:
        return { col: 'base', type: 'text' };
      case literalsENGlobal.forms.uni_symbol_form.quote:
        return { col: 'quote', type: 'text' };
      case literalsENGlobal.forms.uni_symbol_form.category:
        return { col: 'category_value', type: 'text' };
      case literalsENGlobal.forms.uni_symbol_form.isin:
        return { col: 'isin', type: 'text' };
      case literalsENGlobal.forms.uni_symbol_form.description:
        return { col: 'description', type: 'text' };
      case literalsENGlobal.forms.uni_symbol_form.digits:
        return { col: 'digits', type: 'number' };
      case literalsENGlobal.forms.uni_symbol_form.vol_digits:
        return { col: 'vol_digits', type: 'number' };
      case literalsENGlobal.forms.uni_symbol_form.exposure_multiplier:
        return { col: 'exposure_multiplier', type: 'number' };
      case literalsENGlobal.forms.uni_symbol_form.state:
        return { col: 'state_value', type: 'text' };
      case literalsENGlobal.forms.uni_symbol_form.sessions:
        return { col: 'sessions', type: 'text' };
      default:
        return header;
    }
  };

  postMultiple = (files, allFiles, rawData, showLoader, hideLoader) => {
    try {
      showLoader();
      const bulkData = [];
      const errors = [];
      rawData.forEach((values) => {
        const symbol = _.trim(values.name);
        const categoryVal = this.props.formData.CategoryObj[values.category_value];
        if (!categoryVal) {
          errors.push({
            symbol,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.category}`,
          });
        }
        const category = categoryVal ? categoryVal.value : 0;
        const stateVal = this.props.formData.StateObj[values.state_value];
        if (!stateVal) {
          errors.push({
            symbol,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.state}`,
          });
        }
        const state = stateVal ? stateVal.value : 0;
        if (!regExDigits.test(values.digits)) {
          errors.push({
            symbol,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.digits}`,
          });
        }
        if (!validateSessions(values.sessions)) {
          errors.push({
            symbol,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.sessions}`
          });
        } else {
          values.sessions = parseSessions(_.trim(values.sessions));
        }
        if (!regExSignedDecimalNumber.test(values.exposure_multiplier)) {
          errors.push({
            symbol,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.uni_symbol_form.exposure_multiplier}`,
          });
        }
        let volDigits = parseInt(values.vol_digits, 10);
        if (Number.isNaN(volDigits)) {
          volDigits = 2;
        }
        const data = {
          name: symbol,
          security: _.trim(values.security),
          base: _.trim(values.base),
          quote: _.trim(values.quote),
          category: parseInt(category, 10),
          digits: parseInt(values.digits, 10),
          vol_digits: volDigits,
          state: state ? 1 : 0,
          sessions: _.trim(values.sessions),
          isin: _.trim(values.isin),
          description: _.trim(values.description),
          exposure_multiplier: parseFloat(values.exposure_multiplier),
        };
        bulkData.push(data);
      });
      if (errors.length > 0) {
        showNotification(
          this.props.literals.sidebar.uni_symbol, 'danger',
          JSON.stringify(errors), null,
        );
        hideLoader();
        return;
      }
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/security/uni_symbol_bulk`,
        JSON.stringify(bulkData),
      )
        .then((response) => {
          showNotification(
            this.props.literals.sidebar.uni_symbol, 'primary',
            this.props.literals.forms.completed_message, 3,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.uni_symbol, 'danger',
              `${this.props.literals.forms.uni_symbol_form.symbols_failed} ${response.data.toString()} `, null,
            );
          }
          allFiles.forEach(f => f.remove());
          this.props.resetUniSymbol();
          hideLoader();
        })
        .catch(() => {
          allFiles.forEach(f => f.remove());
          showNotification(
            this.props.literals.sidebar.uni_symbol, 'danger',
            this.props.literals.forms.fail_message, 3,
          );
          this.props.resetUniSymbol();
          hideLoader();
        });
    } catch (e) {
      showNotification(
        this.props.literals.sidebar.uni_symbol, 'danger',
        e.message, null,
      );
      hideLoader();
    }
  }

  render() {
    return (
      <UploadData
        tableID="sidebar_uni_symbol_import"
        title={this.props.literals.sidebar.uni_symbol}
        getHeaderName={this.getHeaderName}
        postMultiple={this.postMultiple}
      />
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal,
}))(UniSymbolUpload);
