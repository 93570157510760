import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import TimetableIcon from 'mdi-react/TimetableIcon';
import renderSelectField from '../form/SelectBox';
import renderTimePickerField from '../form/TimePicker';
import { LiteralProps } from '../../prop-types/ReducerProps';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class SessionForm extends PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      isUpdateSession: PropTypes.bool.isRequired,
      cancelSessionEdit: PropTypes.func.isRequired,
      literals: LiteralProps.isRequired,
    };

    render() {
      const {
        handleSubmit,
      } = this.props;
      let submitText = this.props.literals.tables.ag_table.add;
      if (this.props.isUpdateSession) {
        submitText = this.props.literals.tables.ag_table.update;
      }
      const daysWeek = [{
        label: 'MON',
        value: 'MON',
      }, {
        label: 'TUE',
        value: 'TUE',
      }, {
        label: 'WED',
        value: 'WED',
      }, {
        label: 'THU',
        value: 'THU',
      }, {
        label: 'FRI',
        value: 'FRI',
      }, {
        label: 'SAT',
        value: 'SAT',
      }, {
        label: 'SUN',
        value: 'SUN',
      }];
      return (
        <form className="form form--vertical" onSubmit={handleSubmit}>
          <div className="form__form-group" style={{ width: '20%', paddingRight: 5 }}>
            <span className="form__form-group-label">{this.props.literals.tables.ag_table.day_of_the_week}</span>
            <div className="form__form-group-field">
              <Field
                name="day_of_the_week"
                component={renderSelectField}
                type="text"
                options={daysWeek}
                disabled={this.props.isUpdateSession}
              />
            </div>
          </div>
          <div className="form__form-group" style={{ width: '20%', paddingRight: 5 }}>
            <span className="form__form-group-label">{this.props.literals.tables.ag_table.from}</span>
            <div className="form__form-group-field">
              <Field
                name="from_time"
                component={renderTimePickerField}
                timeFormat="HH:mm"
              />
              <div className="form__form-group-icon">
                <TimetableIcon />
              </div>
            </div>
          </div>
          <div className="form__form-group" style={{ width: '20%', paddingRight: 5 }}>
            <span className="form__form-group-label">{this.props.literals.tables.ag_table.to}</span>
            <div className="form__form-group-field">
              <Field
                name="to_time"
                component={renderTimePickerField}
                timeFormat="HH:mm"
              />
              <div className="form__form-group-icon">
                <TimetableIcon />
              </div>
            </div>
          </div>
          <div className="form__form-group" style={{ width: '40%' }}>
            <ButtonToolbar className="form__button-toolbar" style={{ marginTop: 20 }}>
              <Button color="primary" type="submit">{submitText}</Button>
              <Button color="primary" onClick={this.props.cancelSessionEdit}>{this.props.literals.forms.cancel}</Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

function mapStateToProps(state) {
  return { literals: state.literals };
}
export default connect(mapStateToProps)(reduxForm({
  form: 'session_form', // a unique identifier for this form
})(SessionForm));
