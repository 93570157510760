/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import DepthLevelForm from './DepthLevelForm';
import { BasicNotification } from '../Notification';
import { numberFormatter } from '../../helper';
import { LiteralProps } from '../../prop-types/ReducerProps';

const renderField = ({
  input, placeholder, type, disabled, step, min, max, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} min={min} max={max} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class DepthLevelEdit extends PureComponent {
  static propTypes = {
    change: PropTypes.func.isRequired,
    depthLevels: PropTypes.string.isRequired,
    initialDepthLevelValue: PropTypes.string.isRequired,
    submitDepthLevel: PropTypes.func.isRequired,
    resetDepthLevel: PropTypes.func,
    headerName: PropTypes.string,
    literals: LiteralProps.isRequired,
  };
  static defaultProps = {
    resetDepthLevel: () => {},
    headerName: '',
  };

  constructor(props) {
    super(props);
    let notification = null;
    NotificationSystem.newInstance({}, n => notification = n);
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: <BasicNotification
          title={title}
          message={message}
          color={color}
        />,
        duration: 3,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: 'right-up',
      });
    };
    this.state = {
      isUpdateDepthLevel: false,
      updateDepthLevelValue: 0,
      depthLevels: this.props.depthLevels,
      depthLevelsArray: [],
    };
  }
  componentDidMount() {
    this.onLoadDepthLevels();
  }
  onLoadDepthLevels=() => {
    this.setState({ depthLevelsArray: this.parseDepthLevelToArray() });
  };
  onDepthLevelEdit=(item) => {
    this.setState({ isUpdateDepthLevel: true });
    this.setState({ updateDepthLevelValue: item.id });
    this.props.change('depth_level_form', 'bid_vol_from', item.bid_vol_from);
    this.props.change('depth_level_form', 'bid_vol_to', item.bid_vol_to);
    this.props.change('depth_level_form', 'bid_markup', item.bid_markup);
    this.props.change('depth_level_form', 'ask_vol_from', item.ask_vol_from);
    this.props.change('depth_level_form', 'ask_vol_to', item.ask_vol_to);
    this.props.change('depth_level_form', 'ask_markup', item.ask_markup);
  };
  onDepthLevelDelete=(id) => {
    const depthLevelsSlice = this.state.depthLevelsArray.filter(t => t.id !== id);
    this.setState({ depthLevelsArray: depthLevelsSlice });
    this.showNotification(
      this.props.headerName, 'primary',
      this.props.literals.forms.aggregate_group_form.depth_delete_message,
    );
  };
  cancelDepthLevelEdit=() => {
    this.setState({ isUpdateDepthLevel: false });
    this.setState({ updateDepthLevelValue: 0 });
    this.props.change('depth_level_form', 'bid_vol_from', '');
    this.props.change('depth_level_form', 'bid_vol_to', '');
    this.props.change('depth_level_form', 'bid_markup', '');
    this.props.change('depth_level_form', 'ask_vol_from', '');
    this.props.change('depth_level_form', 'ask_vol_to', '');
    this.props.change('depth_level_form', 'ask_markup', '');
  };
  submitDepthLevel=() => {
    const depthLevelsData = this.state.depthLevelsArray.map((data) => {
      let val = `${data.bid_vol_from}:${data.bid_vol_to}:${data.bid_markup}:`;
      val += `${data.ask_vol_from}:${data.ask_vol_to}:${data.ask_markup}`;
      return val;
    });
    this.props.submitDepthLevel(depthLevelsData.join(','));
  };
  resetDepthLevel=() => {
    this.setState({ depthLevels: this.props.initialDepthLevelValue });
    this.props.resetDepthLevel();
  };
  addDepthLevel = (values, dispatch, props) => {
    const depthLevelsSlice = [...this.state.depthLevelsArray];
    const data = {
      bid_vol_from: '0',
      bid_vol_to: '0',
      bid_markup: '0',
      ask_vol_from: '0',
      ask_vol_to: '0',
      ask_markup: '0',
    };
    Object.entries(values).forEach((entry) => {
      data[entry[0]] = entry[1] ? entry[1].toString() : '0';
    });
    const foundIndex = depthLevelsSlice.findIndex(t => t.id === this.state.updateDepthLevelValue);
    if (foundIndex !== -1 && props.isUpdateDepthLevel) {
      data.id = this.state.updateDepthLevelValue;
      depthLevelsSlice[foundIndex] = data;
    } else {
      if (depthLevelsSlice.length >= 20) {
        this.showNotification(
          this.props.headerName, 'danger',
          this.props.literals.forms.aggregate_group_form.depth_maximum_message,
        );
        return;
      }
      data.id = depthLevelsSlice.length + 1;
      depthLevelsSlice.push(data);
    }
    this.setState({ depthLevelsArray: depthLevelsSlice });
    if (props.isUpdateDepthLevel) {
      this.showNotification(
        this.props.headerName, 'primary',
        this.props.literals.forms.aggregate_group_form.depth_update_message,
      );
    } else {
      this.showNotification(
        this.props.headerName, 'primary',
        this.props.literals.forms.aggregate_group_form.depth_create_message,
      );
    }
    if (props.isUpdateDepthLevel) {
      this.setState({ isUpdateDepthLevel: false });
      this.setState({ updateDepthLevelValue: 0 });
      this.props.change('depth_level_form', 'bid_vol_from', '');
      this.props.change('depth_level_form', 'bid_vol_to', '');
      this.props.change('depth_level_form', 'bid_markup', '');
      this.props.change('depth_level_form', 'ask_vol_from', '');
      this.props.change('depth_level_form', 'ask_vol_to', '');
      this.props.change('depth_level_form', 'ask_markup', '');
    }
  };
  parseDepthLevelToArray = () => {
    const depthLevel = [];
    if (this.state.depthLevels.trim()) {
      this.state.depthLevels.replace(/,$/, '')
        .split(',')
        .forEach((depth, i) => {
          const item = depth.split(':');
          depthLevel.push({
            id: i + 1,
            bid_vol_from: item[0],
            bid_vol_to: item[1],
            bid_markup: item[2],
            ask_vol_from: item[3],
            ask_vol_to: item[4],
            ask_markup: item[5],
          });
        });
    }
    return depthLevel;
  };
  parseBool=val => val === 'true' || val === true || val === '1' || val === 1;
  parseBoolToInt=val => (val === 'true' || val === true || val === '1' || val === 1 ? 1 : 0);

  render() {
    const depthLevel = this.state.depthLevelsArray;
    let k = 0;
    const depthList = depthLevel.map((item) => {
      k += 1;
      return (
        <tr key={k}>
          <td style={{ width: '13%' }}>{numberFormatter(parseFloat(item.bid_vol_from))}</td>
          <td style={{ width: '13%' }}>{numberFormatter(parseFloat(item.bid_vol_to))}</td>
          <td style={{ width: '13%' }}>{numberFormatter(parseInt(item.bid_markup, 10))}</td>
          <td style={{ width: '13%' }}>{numberFormatter(parseFloat(item.ask_vol_from))}</td>
          <td style={{ width: '13%' }}>{numberFormatter(parseFloat(item.ask_vol_to))}</td>
          <td style={{ width: '13%' }}>{numberFormatter(parseInt(item.ask_markup, 10))}</td>
          <td style={{ width: '10%' }}>
            <Button
              style={{ height: 14 }}
              id="DepthLevelEditClick"
              onClick={() => this.onDepthLevelEdit(item)}
              className="icon"
              color="primary"
            ><p
              style={{ lineHeight: 0 }}
              className="fa fa-edit"
            />
            </Button>
          </td>
          <td style={{ width: '10%' }}>
            <Button
              style={{ height: 14 }}
              id="DepthLevelDeleteClick"
              onClick={() => this.onDepthLevelDelete(item.id)}
              className="icon"
              color="primary"
            ><p
              style={{ lineHeight: 0 }}
              className="fa fa-trash-alt"
            />
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div className="session-edit">
        <DepthLevelForm
          onSubmit={this.addDepthLevel}
          isUpdateDepthLevel={this.state.isUpdateDepthLevel}
          cancelDepthLevelEdit={this.cancelDepthLevelEdit}
        />
        <table className="session-table">
          <tbody>
            <tr>
              <td>{this.props.literals.forms.aggregate_group_form.bid_vol_from}</td>
              <td>{this.props.literals.forms.aggregate_group_form.bid_vol_to}</td>
              <td>{this.props.literals.forms.aggregate_group_form.bid_markup}</td>
              <td>{this.props.literals.forms.aggregate_group_form.ask_vol_from}</td>
              <td>{this.props.literals.forms.aggregate_group_form.ask_vol_to}</td>
              <td>{this.props.literals.forms.aggregate_group_form.ask_markup}</td>
              <td />
              <td />
            </tr>
            {depthList}
          </tbody>
        </table>
        <div style={{ overflow: 'hidden' }}>
          <ButtonToolbar className="float-right">
            <Button color="primary" onClick={this.submitDepthLevel}>{this.props.literals.forms.submit}</Button>
            <Button color="primary" onClick={this.resetDepthLevel}>{this.props.literals.forms.cancel}</Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { literals: state.literals };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(DepthLevelEdit);
