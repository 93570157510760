/* eslint-disable no-case-declarations */
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  CHANGE_SIDEBAR_CATEGORY_COLLAPSE,
} from '../actions/sidebarActions';

const initialState = {
  show: false,
  collapse: true,
  collapseCategory: {},
};

const SidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case CHANGE_SIDEBAR_CATEGORY_COLLAPSE:
      if (state.collapseCategory) {
        if (action.payload in state.collapseCategory) {
          const collapseCat = {
            ...state.collapseCategory,
            ...{ [action.payload]: !state.collapseCategory[action.payload] },
          };
          return { ...state, collapseCategory: collapseCat };
        }
        const collapseCat = { ...state.collapseCategory, ...{ [action.payload]: true } };
        return { ...state, collapseCategory: collapseCat };
      }
      return { ...state, collapseCategory: { [action.payload]: true } };
    default:
      return state;
  }
};
const persistConfig = {
  key: 'sidebar',
  storage,
};

export default persistReducer(persistConfig, SidebarReducer);
