/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import LPSessionEditor from './LPSessionEditor';
import BoolRenderer from '../../shared/components/BoolRenderer';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import SessionRenderer from '../../shared/components/session/SessionRenderer';
import SessionHeaderComponent from '../../shared/components/multi_edit/SessionHeaderComponent';
import { onGridSizeChanged, onColumnResized, numberFormatter } from '../../shared/helper';
import DepthLevelRenderer from '../../shared/components/depth_level/DepthLevelRenderer';
import DepthLevelHeaderComponent from '../../shared/components/multi_edit/DepthLevelHeaderComponent';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class AggregateGroupDetailCellRenderer extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      data: PropTypes.instanceOf(Object).isRequired,
      api: PropTypes.instanceOf(Object).isRequired,
      context: PropTypes.instanceOf(Object).isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = props.context.rowsToUpdateDetail[props.node.id] || {};
      this.stylesToUpdate = props.context.stylesToUpdateDetail[props.node.id] || {};
      const { literals } = this.props;
      const { role } = props.rootStore.authStore;
      this.state = {
        modules: AllModules,
        colDefs: [
          {
            headerName: literals.forms.aggregate_symbol_form.symbol,
            field: 'symbol_val',
            editable: false,
            pinned: 'left',
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.aggregate_symbol_form.security,
            field: 'security',
            editable: role === 'admin',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: [],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: role === 'admin' ? 'cellHeaderComponent' : '',
            headerComponentParams: {
              selectValueLiteral: 'Security',
            },
          },
          {
            headerName: literals.forms.aggregate_symbol_form.aggregate_group,
            field: 'aggregate_group',
            editable: false,
          },
          {
            headerName: literals.forms.aggregate_symbol_form.lp_sessions,
            field: 'lp_sessions',
            cellEditor: 'lpSessionEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'cellHeaderComponent',
            headerComponentParams: {
              selectValueLiteral: 'LPSession',
            },
          },
          {
            headerName: literals.forms.aggregate_symbol_form.sessions,
            field: 'sessions',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'sessionHeaderComponent',
            editable: false,
            cellRenderer: 'sessionRenderer',
          },
          {
            headerName: literals.forms.aggregate_symbol_form.exec_mode,
            field: 'exec_mode_value',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: [],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
            headerComponentParams: {
              selectValueLiteral: 'ExecMode',
            },
          },
          {
            headerName: literals.forms.aggregate_symbol_form.exec_boost,
            field: 'exec_boost_value',
            editable: false,
            cellRenderer: 'boolRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.forms.aggregate_symbol_form.state,
            field: 'state_value',
            editable: false,
            cellRenderer: 'stateRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.forms.aggregate_symbol_form.filter_factor,
            field: 'filter_factor',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.aggregate_symbol_form.depth_levels,
            field: 'depth_levels',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'depthLevelHeaderComponent',
            editable: false,
            cellRenderer: 'depthLevelRenderer',
          },
          {
            headerName: literals.forms.lp_symbol_form.description,
            field: 'description',
            editable: false,
            tooltipField: 'description',
          },
        ],
        frameworkComponents: {
          lpSessionEditor: LPSessionEditor,
          stateRenderer: StateRenderer,
          boolRenderer: BoolRenderer,
          stateHeaderComponent: StateHeaderComponent,
          cellHeaderComponent: HeaderComponent,
          sessionRenderer: SessionRenderer,
          sessionHeaderComponent: SessionHeaderComponent,
          depthLevelRenderer: DepthLevelRenderer,
          depthLevelHeaderComponent: DepthLevelHeaderComponent,
        },
        rowData: [],
        rowOldData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        cellEditUpdate: props.context.cellEditUpdateDetail[props.node.id],
        getRowNodeId(data) {
          return data.symbol_val;
        },
      };

      this.state.rowID = props.node.id ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff') : props.node.id;
      this.gridWrapperID = 'grid-wrapper-sidebar_aggregate_group';
      this.myGridID = `myGrid_sidebar_aggregate_symbol_${this.state.rowID}`;
      this.state.masterGridApi = props.api;
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridInfo.columnApi) {
        const { literals } = this.props;
        if (literals.forms.aggregate_symbol_form.symbol !== prevProps.literals.forms.aggregate_symbol_form.symbol) {
          this.gridInfo.columnApi.getColumn('symbol_val').getColDef().headerName
              = literals.forms.aggregate_symbol_form.symbol;
          this.gridInfo.columnApi.getColumn('security').getColDef().headerName
                  = literals.forms.aggregate_symbol_form.security;
          this.gridInfo.columnApi.getColumn('aggregate_group').getColDef().headerName
                  = literals.forms.aggregate_symbol_form.aggregate_group;
          this.gridInfo.columnApi.getColumn('lp_sessions').getColDef().headerName
                  = literals.forms.aggregate_symbol_form.lp_sessions;
          this.gridInfo.columnApi.getColumn('sessions').getColDef().headerName
                  = literals.forms.aggregate_symbol_form.sessions;
          this.gridInfo.columnApi.getColumn('exec_mode_value').getColDef().headerName
                  = literals.forms.aggregate_symbol_form.exec_mode;
          this.gridInfo.columnApi.getColumn('exec_boost_value').getColDef().headerName
                  = literals.forms.aggregate_symbol_form.exec_boost;
          this.gridInfo.columnApi.getColumn('state_value').getColDef().headerName
                  = literals.forms.aggregate_symbol_form.state;
          this.gridInfo.columnApi.getColumn('filter_factor').getColDef().headerName
            = literals.forms.aggregate_symbol_form.filter_factor;
          this.gridInfo.columnApi.getColumn('depth_levels').getColDef().headerName
            = literals.forms.aggregate_symbol_form.depth_levels;
          this.gridInfo.columnApi.getColumn('description').getColDef().headerName
            = literals.forms.lp_symbol_form.description;
          this.gridInfo.api.refreshHeader();
        }
      }
    }

    componentWillUnmount = () => {
      if (this.gridInfo) {
        window[`colStateAggregateGroup${this.state.rowID}`] = this.gridInfo.columnApi.getColumnState();
        window[`groupStateAggregateGroup${this.state.rowID}`] = this.gridInfo.columnApi.getColumnGroupState();
        window[`filterStateAggregateGroup${this.state.rowID}`] = this.gridInfo.api.getFilterModel();
      }

      const detailGridId = this.createDetailGridId();

      // ag-Grid is automatically destroyed
      this.state.masterGridApi.removeDetailGridInfo(detailGridId);
    };

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridInfo.api.refreshCells({ rowNodes: [params.node], columns: ['symbol_val'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridInfo.api.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'state_value') {
          params.data.state =
            this.props.context.formData.StateObj[params.newValue].value;
        }
        if (params.colDef.field === 'exec_mode_value') {
          params.data.exec_mode =
            this.props.context.formData.ExecModeObj[params.newValue].value;
        }
        if (params.colDef.field === 'exec_boost_value') {
          params.data.exec_boost =
            this.props.context.formData.BoolObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
        this.props.context.updateStateDetail(
          this.state.rowID,
          true, this.rowsToUpdate, this.stylesToUpdate,
        );
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onGridReady(params) {
      const detailGridId = this.createDetailGridId();

      this.gridInfo = {
        id: detailGridId,
        api: params.api,
        columnApi: params.columnApi,
      };
      this.gridInfo.api.showLoadingOverlay();
      this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfo);

      this.gridInfo.columnApi.getColumn('security').getColDef().cellEditorParams.values =
          this.props.context.formData.Security ?
            this.props.context.formData.Security.map(a => a.label) : [];
      this.gridInfo.columnApi.getColumn('exec_mode_value').getColDef().cellEditorParams.values =
            this.props.context.formData.ExecMode ?
              this.props.context.formData.ExecMode.map(a => a.label) : [];
      if (window[`colStateAggregateGroup${this.state.rowID}`]) {
        this.gridInfo.columnApi.setColumnState(window[`colStateAggregateGroup${this.state.rowID}`]);
      }
      if (window[`groupStateAggregateGroup${this.state.rowID}`]) {
        this.gridInfo.columnApi.setColumnGroupState(window[`groupStateAggregateGroup${this.state.rowID}`]);
      }
      if (window[`filterStateAggregateGroup${this.state.rowID}`]) {
        this.gridInfo.api.setFilterModel(window[`filterStateAggregateGroup${this.state.rowID}`]);
      }
      this.setState({ rowData: this.props.data.aggregate_symbols });
      this.setState({ rowOldData: JSON.parse(JSON.stringify(this.props.data.aggregate_symbols)) });
      this.props.node.setDataValue(
        'lp_sessions',
        _.uniq(_.map(this.props.data.aggregate_symbols, item => item.lp_sessions).join(',').split(',')),
      );
      this.onGridSizeChanged(params);
    }

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['symbol_val', 'security', 'aggregate_group', 'lp_sessions', 'sessions',
          'exec_mode_value', 'exec_boost_value', 'state_value', 'filter_factor', 'depth_levels', 'description'],
        fileName: 'HubSymbol.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridInfo.api.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['symbol_val', 'security', 'aggregate_group', 'lp_sessions', 'sessions',
          'exec_mode_value', 'exec_boost_value', 'state_value', 'filter_factor', 'depth_levels', 'description'],
        fileName: 'HubSymbol.csv',
      };
      this.gridInfo.api.exportDataAsCsv(params);
    };

    createDetailGridId = () => `detail_${this.state.rowID}`;

    updateAggregateGroupSymbol=() => {
      this.props.context.showLoader();
      const bulkData = [];
      const rowData = _.keyBy(this.state.rowOldData, 'symbol_val');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.symbol_val];
        const dataToUpdate = {
          symbol_val: values.symbol_val,
          security: values.security,
          aggregate_group: values.aggregate_group,
          lp_sessions: values.lp_sessions,
          sessions: values.sessions,
          exec_mode: parseInt(values.exec_mode, 10),
          exec_boost: parseInt(values.exec_boost, 10),
          state: parseInt(values.state, 10),
          filter_factor: parseFloat(values.filter_factor),
          depth_levels: values.depth_levels,
          old_data: {
            symbol_val: oldRow.symbol_val,
            security: oldRow.security,
            aggregate_group: oldRow.aggregate_group,
            lp_sessions: oldRow.lp_sessions,
            sessions: oldRow.sessions,
            exec_mode: parseInt(oldRow.exec_mode, 10),
            exec_boost: parseInt(oldRow.exec_boost, 10),
            state: parseInt(oldRow.state, 10),
            filter_factor: parseFloat(oldRow.filter_factor),
            depth_levels: oldRow.depth_levels,
          },
        };
        bulkData.push(dataToUpdate);
      });
      axios.put(`${process.env.REACT_APP_BACKEND_API}/security/aggregate_symbol_bulk`, JSON.stringify(bulkData))
        .then((response) => {
          this.resetAggregateGroupSymbol();
          showNotification(
            this.props.literals.sidebar.aggregate_symbol, 'primary',
            this.props.literals.forms.completed_message,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.aggregate_symbol, 'danger',
              `${this.props.literals.forms.fail_message} - ${response.data.toString()}}`,
            );
          }
        })
        .catch((error) => {
          this.resetAggregateGroupSymbol();
          showNotification(
            this.props.literals.sidebar.aggregate_symbol, 'danger',
            `${this.props.literals.forms.fail_message} - ${error.message}}`,
          );
        });
    };

    resetAggregateGroupSymbol=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      this.props.context.resetAggregateGroup();
      window[`colStateAggregateGroup${this.state.rowID}`] = this.gridInfo.columnApi.getColumnState();
      window[`groupStateAggregateGroup${this.state.rowID}`] = this.gridInfo.columnApi.getColumnGroupState();
      window[`filterStateAggregateGroup${this.state.rowID}`] = this.gridInfo.api.getFilterModel();
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridInfo.api.getRowNode(keys);
        if (node) {
          this.gridInfo.api.refreshCells({ rowNodes: [node], columns: ['symbol_val'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridInfo.api.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
      this.props.context.updateStateDetail(
        this.state.rowID,
        false, this.rowsToUpdate, this.stylesToUpdate,
      );
    };

    render() {
      const { literals } = this.props;
      return (
        <div style={{
boxSizing: 'border-box', width: '100%', height: '100%',
}}
        >
          <div className="outer-grid-label">
            {literals.sidebar.aggregate_symbol} ({literals.tables.ag_table.total_records}: {this.state.rowData.length})
          </div>
          <div className="outer-grid-button">
            <ButtonToolbar>
              <Tooltip title={literals.tables.ag_table.save}>
                <Button
                  style={{
                                display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                  onClick={this.updateAggregateGroupSymbol}
                  className="icon"
                  color="primary"
                ><p className="fa fa-save" />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.undo}>
                <Button
                  style={{
                                display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                  onClick={() => {
                    this.props.context.showLoader();
                    this.resetAggregateGroupSymbol();
                  }}
                  className="icon"
                  color="primary"
                ><p
                  className="fas fa-undo"
                />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.export_excel}>
                <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                  <p className="fa fa-file-excel" />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.export_csv}>
                <Button
                  onClick={this.onBtExportCSV}
                  className="icon"
                  color="primary"
                >
                  <p className="fa fa-file-alt" />
                </Button>
              </Tooltip>
            </ButtonToolbar>
          </div>
          <div
            id={this.myGridID}
            style={{ width: '100%', height: this.props.node.rowHeight - REDUCE_HEIGHT, clear: 'both' }}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.colDefs}
              stopEditingWhenCellsLoseFocus
              suppressCellSelection
              suppressContextMenu
              immutableData
              getRowNodeId={this.state.getRowNodeId}
              frameworkComponents={this.state.frameworkComponents}
              defaultColDef={this.state.defaultColDef}
              onColumnResized={this.onColumnResized}
              onCellValueChanged={this.onCellValueChanged}
              rowData={this.state.rowData}
              onGridReady={this.onGridReady}
              onGridSizeChanged={this.onGridSizeChanged}
              rowHeight={20}
              context={{
                formData: this.props.context.formData,
                theme: this.props.theme,
                showLoader: this.props.context.showLoader,
                hideLoader: this.props.context.hideLoader,
                onCellValueChanged: this.onCellValueChanged,
              }}
            />
          </div>
        </div>

      );
    }
}

export default connect(state => ({
  literals: state.literals,
  theme: state.theme,
}))(withRouter(AggregateGroupDetailCellRenderer));

