/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';

class StateRenderer extends PureComponent {
    static propTypes = {
      value: PropTypes.string.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
      disabled: PropTypes.bool,
      empty: PropTypes.bool,
    };
    static defaultProps = {
      disabled: false,
      empty: false,
    };
    state = {
      selectedOption: this.props.value === 'ENABLED',
    };
    refresh(params) {
      if ((params.value === 'ENABLED') !== this.state.selectedOption) {
        this.setState({
          selectedOption: params.value === 'ENABLED',
        });
      }
      return true;
    }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.target.checked });
    const rowNode = this.props.node;
    if (selectedOption.target.checked === true) {
      rowNode.setDataValue(this.props.colDef.field, 'ENABLED');
    } else {
      rowNode.setDataValue(this.props.colDef.field, 'DISABLED');
    }
  };
  render() {
    const { selectedOption } = this.state;
    return (
      <React.Fragment>
        {!this.props.empty === true &&
          <label
            className={`checkbox-btn ag-checkbox-btn ${this.props.disabled === true ? 'disabled' : ''}`}
          >
            <input
              className="checkbox-btn__checkbox"
              type="checkbox"
              name="checkboxAG"
              checked={selectedOption}
              onChange={this.handleChange}
              disabled={this.props.disabled}
            />
            <span
              className="checkbox-btn__checkbox-custom"
              style={{ position: 'static' }}
            >
              <CheckIcon />
            </span>
          </label>
          }
      </React.Fragment>
    );
  }
}
export default StateRenderer;
