import React, { PureComponent } from 'react';
import axios from 'axios';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import ProfileForm from './form';
import profileAction from './action';

@inject('rootStore')
class Profile extends PureComponent {
  static propTypes = {
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
    axios.get(
      `${process.env.REACT_APP_BACKEND_API}/profile?username=${props.rootStore.authStore.user.username}`,
      { headers: { 'x-forward-client': props.rootStore.clientStore.CC } },
    )
      .then((response) => {
        this.setState({ formData: response.data });
      })
      .catch(() => {
      });
  }


  render() {
    return (
      <ProfileForm
        {...this.props}
        state={{
          formData: this.state.formData,
        }}
        onSubmit={profileAction}
      />
    );
  }
}
export default Profile;
