/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';

class StateRenderer extends PureComponent {
    static propTypes = {
      value: PropTypes.string.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
      disabled: PropTypes.bool,
    };
    static defaultProps = {
      disabled: false,
    };
    state = {
      selectedOption: this.props.value === 'YES',
    };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.target.checked });
    const rowNode = this.props.node;
    if (selectedOption.target.checked === true) {
      rowNode.setDataValue(this.props.colDef.field, 'YES');
    } else {
      rowNode.setDataValue(this.props.colDef.field, 'NO');
    }
  };
  render() {
    const { selectedOption } = this.state;
    return (
      <label
        className={`checkbox-btn ag-checkbox-btn ${this.props.disabled === true ? 'disabled' : ''}`}
      >
        <input
          className="checkbox-btn__checkbox"
          type="checkbox"
          name="checkboxAG"
          checked={selectedOption}
          onChange={this.handleChange}
          disabled={this.props.disabled}
        />
        <span
          className="checkbox-btn__checkbox-custom"
        >
          <CheckIcon />
        </span>
      </label>
    );
  }
}
export default StateRenderer;
