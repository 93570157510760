/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import autoSuggestInput from '../../shared/components/form/AutoSuggestInput';

class RuleMacroEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    literals: LiteralProps.isRequired,
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.context.formData.RuleMacro.filter(macro =>
      macro.toLowerCase().slice(0, inputLength) === inputValue);
  };
  renderSuggestion = suggestion => suggestion;

  render() {
    return (
      <div>
        <form className="form form--vertical" onSubmit={this.props.handleSubmit}>
          <div className="form__form-group">
            <span
              className="form__form-group-label"
            >{this.props.literals.forms.taker_api_link_form.rule_macro}
            </span>
            <div className="form__form-group-field">
              <Field
                name="rule_macro"
                component={autoSuggestInput}
                placeholder="Type '#'"
                getSuggestions={this.getSuggestions}
                getSuggestionValue={suggestion => suggestion}
                renderSuggestion={this.renderSuggestion}
              />
            </div>`
          </div>
          <div style={{ overflow: 'hidden', width: '97%' }}>
            <ButtonToolbar className="float-right">
              <Button color="primary" type="submit">{this.props.literals.forms.submit}</Button>
              <Button color="primary" onClick={this.props.toggleModalSession}>
                {this.props.literals.forms.cancel}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      </div>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    initialValues: {
      rule_macro: ownProps.value,
    },
  };
}
// export default connect(null, mapDispatchToProps)(SessionEdit);
export default connect(mapStateToProps)(reduxForm({
  form: 'taker_api_link_table_form', // a unique identifier for this form
})(RuleMacroEdit));
