/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import { LiteralENGlobalProps, LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import { regExDigits, regExSignedDecimalNumber, shallowEqual, validateSessions,
  regExZeroOrOne, regExUnSignedNumber, parseSessions } from '../../shared/helper';
import UploadData from '../../shared/components/UploadData';

let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};
class LPSymbolUpload extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
    resetLPSession: PropTypes.func.isRequired,
    rowOldData: PropTypes.instanceOf(Object).isRequired,
  };

  getHeaderName=(header) => {
    const { literalsENGlobal } = this.props;
    switch (header) {
      case literalsENGlobal.forms.lp_symbol_form.unisymbol:
        return { col: 'unisymbol', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.lp_session:
        return { col: 'lp_session', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.lp_symbol:
        return { col: 'lp_symbol', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.base:
        return { col: 'base', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.quote:
        return { col: 'quote', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.contract_size:
        return { col: 'contract_size', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.digits:
        return { col: 'digits', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.sessions:
        return { col: 'sessions', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.markup_bid:
        return { col: 'markup_bid', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.markup_ask:
        return { col: 'markup_ask', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.depth:
        return { col: 'depth', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.commission:
        return { col: 'commission', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.min_size:
        return { col: 'min_size', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.a_step:
        return { col: 'a_step', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.consume:
        return { col: 'consume_value', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.allow_sweep:
        return { col: 'allow_sweep_value', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.state:
        return { col: 'state_value', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.multi_req_trade:
        return { col: 'multi_req_trade', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.expiry_date:
        return { col: 'expiry_date', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.sub_volumes:
        return { col: 'sub_volumes', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.timeout_warn:
        return { col: 'timeout_warn', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.timeout_error:
        return { col: 'timeout_error', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.timeout_kill:
        return { col: 'timeout_kill', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.timeout_delay:
        return { col: 'timeout_delay', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.feed_side:
        return { col: 'feed_side_value', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.queue_timeout:
        return { col: 'queue_timeout', type: 'number' };
      case literalsENGlobal.forms.lp_symbol_form.disable_b_on_delay:
        return { col: 'disable_b_on_delay_value', type: 'text' };
      case literalsENGlobal.forms.lp_symbol_form.ms_description:
        return { col: 'description', type: 'text' };

      default:
        return { col: header, type: 'text' };
    }
  };

  postMultiple = (files, allFiles, rawData, showLoader, hideLoader) => {
    try {
      showLoader();
      const bulkData = [];
      const errors = [];
      const arr = this.props.rowOldData.reduce((pV, cV) => [...pV, ...cV.lp_symbols], []);
      const rowData = _.keyBy(arr, o => o.lp_session + o.unisymbol);
      rawData.forEach((values) => {
        const symbolAndLPSession = `${_.trim(values.unisymbol)}->${_.trim(values.lp_session)}`;
        const lpSession = this.props.formData.LPSessionObj[values.lp_session];
        if (!lpSession) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ` +
                `${this.props.literals.forms.lp_symbol_form.lp_session}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.contract_size)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.contract_size}`,
          });
        }
        if (!regExDigits.test(values.digits)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.digits}`,
          });
        }
        if (!validateSessions(values.sessions)) {
          errors.push({
            symbol: symbolAndLPSession,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.sessions}`
          });
        } else {
          values.sessions = parseSessions(_.trim(values.sessions));
        }
        if (!regExSignedDecimalNumber.test(values.markup_bid)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.markup_bid}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.markup_ask)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.markup_ask}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.depth)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.depth}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.commission)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.commission}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.min_size)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.min_size}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.a_step)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.a_step}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.a_step)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.a_step}`,
          });
        }
        const consumeVal = this.props.formData.BoolObj[values.consume_value];
        if (!consumeVal) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.consume}`,
          });
        }
        const consume = consumeVal ? consumeVal.value : 0;
        const allowSweepVal = this.props.formData.BoolObj[values.allow_sweep_value];
        if (!allowSweepVal) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.allow_sweep}`,
          });
        }
        const allowSweep = allowSweepVal ? allowSweepVal.value : 0;
        const stateVal = this.props.formData.StateObj[values.state_value];
        if (!stateVal) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.state}`,
          });
        }
        const state = stateVal ? stateVal.value : 0;
        if (!regExZeroOrOne.test(values.multi_req_trade)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.multi_req_trade}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.expiry_date)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.expiry_date}`,
          });
        }
        let subVolumes = '';
        if (values.sub_volumes !== '') {
          const subVolumesVal = values.sub_volumes.split(',').map(item => item.trim());
          subVolumesVal.forEach((value) => {
            if (!regExSignedDecimalNumber.test(value)) {
              errors.push({
                symbol: symbolAndLPSession,
                error:
                  `${this.props.literals.forms.invalid} ` +
                  `${this.props.literals.forms.lp_symbol_form.sub_volumes}`,
              });
            }
          });
          subVolumes = subVolumesVal.join();
        }
        if (!regExSignedDecimalNumber.test(values.timeout_warn)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.timeout_warn}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.timeout_error)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.timeout_error}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.timeout_kill)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.timeout_kill}`,
          });
        }
        if (!regExSignedDecimalNumber.test(values.timeout_delay)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.timeout_delay}`,
          });
        }
        const feedSideVal = this.props.formData.FeedSideObj[values.feed_side_value];
        if (!feedSideVal) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.feed_side}`,
          });
        }
        const feedSide = feedSideVal ? feedSideVal.value : 0;

        if (!regExUnSignedNumber.test(values.queue_timeout)) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.queue_timeout}`,
          });
        }
        const disableBOnDelayVal = this.props.formData.BoolObj[values.disable_b_on_delay_value];
        if (!disableBOnDelayVal) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid} ${this.props.literals.forms.lp_symbol_form.disable_b_on_delay}`,
          });
        }
        const disableBOnDelay = disableBOnDelayVal ? disableBOnDelayVal.value : 0;
        const oldRow = rowData[values.lp_session + values.unisymbol];
        if (!oldRow) {
          errors.push({
            symbol: symbolAndLPSession,
            error:
                `${this.props.literals.forms.invalid}`,
          });
        } else {
          const newData = {
            unisymbol: _.trim(values.unisymbol),
            security: _.trim(oldRow.security),
            lp_symbol: _.trim(values.lp_symbol),
            lp_session: _.trim(values.lp_session),
            base: _.trim(values.base),
            quote: _.trim(values.quote),
            expiry_date: parseInt(values.expiry_date, 10),
            contract_size: parseFloat(values.contract_size),
            digits: parseInt(values.digits, 10),
            sessions: _.trim(values.sessions),
            markup_bid: parseFloat(values.markup_bid),
            markup_ask: parseFloat(values.markup_ask),
            depth: parseInt(values.depth, 10),
            sub_volumes: subVolumes,
            commission: parseFloat(values.commission),
            min_size: parseFloat(values.min_size),
            a_step: parseFloat(values.a_step),
            consume: parseInt(consume, 10),
            allow_sweep: parseInt(allowSweep, 10),
            timeout_warn: parseInt(values.timeout_warn, 10),
            timeout_error: parseInt(values.timeout_error, 10),
            timeout_kill: parseInt(values.timeout_kill, 10),
            state: parseInt(state, 10),
            timeout_delay: parseInt(values.timeout_delay, 10),
            margin_perc: parseInt(oldRow.margin_perc, 10),
            multi_req_trade: parseInt(values.multi_req_trade, 10),
            queue_size: parseInt(oldRow.queue_size, 10),
            queue_action: parseInt(oldRow.queue_action, 10),
            feed_side: parseInt(feedSide, 10),
            queue_timeout: parseInt(values.queue_timeout, 10),
            disable_b_on_delay: parseInt(disableBOnDelay, 10),
            description: _.trim(values.description),
          };
          const oldData = {
            unisymbol: oldRow.unisymbol,
            security: oldRow.security,
            lp_symbol: oldRow.lp_symbol,
            lp_session: oldRow.lp_session,
            base: oldRow.base,
            quote: oldRow.quote,
            expiry_date: parseInt(oldRow.expiry_date, 10),
            contract_size: parseFloat(oldRow.contract_size),
            digits: parseInt(oldRow.digits, 10),
            sessions: oldRow.sessions,
            markup_bid: parseFloat(oldRow.markup_bid),
            markup_ask: parseFloat(oldRow.markup_ask),
            depth: parseInt(oldRow.depth, 10),
            sub_volumes: oldRow.sub_volumes,
            commission: parseFloat(oldRow.commission),
            min_size: parseFloat(oldRow.min_size),
            a_step: parseFloat(oldRow.a_step),
            consume: parseInt(oldRow.consume, 10),
            allow_sweep: parseInt(oldRow.allow_sweep, 10),
            timeout_warn: parseInt(oldRow.timeout_warn, 10),
            timeout_error: parseInt(oldRow.timeout_error, 10),
            timeout_kill: parseInt(oldRow.timeout_kill, 10),
            state: parseInt(oldRow.state, 10),
            timeout_delay: parseInt(oldRow.timeout_delay, 10),
            margin_perc: parseInt(oldRow.margin_perc, 10),
            multi_req_trade: parseInt(oldRow.multi_req_trade, 10),
            queue_size: parseInt(oldRow.queue_size, 10),
            queue_action: parseInt(oldRow.queue_action, 10),
            feed_side: parseInt(oldRow.feed_side, 10),
            queue_timeout: parseInt(oldRow.queue_timeout, 10),
            disable_b_on_delay: parseInt(oldRow.disable_b_on_delay, 10),
            description: oldRow.description,
          };
          const dataToUpdate = {
            ...newData,
            old_data: {
              ...oldData,
            },
          };
          if (!shallowEqual(newData, oldData)) {
            bulkData.push(dataToUpdate);
          }
        }
      });
      if (errors.length > 0) {
        showNotification(
          this.props.literals.sidebar.lp_symbol, 'danger',
          JSON.stringify(errors), null,
        );
        hideLoader();
        return;
      }
      axios.put(
        `${process.env.REACT_APP_BACKEND_API}/security/lp_symbol_bulk`,
        JSON.stringify(bulkData),
      )
        .then((response) => {
          showNotification(
            this.props.literals.sidebar.lp_symbol, 'primary',
            this.props.literals.forms.completed_message, 3,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.lp_symbol, 'danger',
              `${this.props.literals.forms.uni_symbol_form.symbols_failed} ${response.data.toString()} `, null,
            );
          }
          allFiles.forEach(f => f.remove());
          this.props.resetLPSession();
          hideLoader();
        })
        .catch(() => {
          allFiles.forEach(f => f.remove());
          showNotification(
            this.props.literals.sidebar.lp_symbol, 'danger',
            this.props.literals.forms.fail_message, 3,
          );
          this.props.resetLPSession();
          hideLoader();
        });
    } catch (e) {
      showNotification(
        this.props.literals.sidebar.lp_symbol, 'danger',
        e.message, null,
      );
      hideLoader();
    }
  }

  render() {
    return (
      <UploadData
        tableID="sidebar_lp_symbol_import"
        title={this.props.literals.sidebar.lp_symbol}
        getHeaderName={this.getHeaderName}
        postMultiple={this.postMultiple}
      />
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal,
}))(LPSymbolUpload);
