import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter } from '../../shared/helper';

class TotalStatusBarComponent extends Component {
    static propTypes = {
      frameworkComponentWrapper: PropTypes.instanceOf(Object).isRequired,
      api: PropTypes.instanceOf(Object).isRequired,
      literals: LiteralProps.isRequired,
    }
    constructor(props) {
      super(props);

      this.state = {
        rowDataTotal: {},
      };

      this.props.api.addEventListener('rowDataUpdated', () => {
        this.setState({ rowDataTotal: this.props.frameworkComponentWrapper.agGridReact.props.context.rowDataTotal });
      });
    }

    render() {
      const { literals } = this.props;
      return (
        <div className="ag-status-bar">
          <div className="ag-status-bar">
            <div className="ag-status-name-value ag-status-panel">
              <span className="ag-status-name-custom">
                {literals.tables.balance_transaction_table.total_unrealized_commission}
              </span>:&nbsp;
              <span className="ag-status-name-value-value">
                {numberFormatter(this.state.rowDataTotal.total_unrealized_commission || 0)}
              </span>
            </div>
          </div>
          <div className="ag-status-bar">
            <div className="ag-status-name-value ag-status-panel">
              <span className="ag-status-name-custom">
                {literals.tables.balance_transaction_table.total_unrealized_swaps}
              </span>:&nbsp;
              <span className="ag-status-name-value-value">
                {numberFormatter(this.state.rowDataTotal.total_unrealized_swaps || 0)}
              </span>
            </div>
          </div>
          <div className="ag-status-bar">
            <div className="ag-status-name-value ag-status-panel">
              <span className="ag-status-name-custom">
                {literals.tables.balance_transaction_table.total_unrealized_pl}
              </span>:&nbsp;
              <span className="ag-status-name-value-value">{
                  numberFormatter(this.state.rowDataTotal.total_unrealized_pl || 0)}
              </span>
            </div>
          </div>
          <div className="ag-status-bar">
            <div className="ag-status-name-value ag-status-panel">
              <span className="ag-status-name-custom">
                {literals.tables.balance_transaction_table.total_notional}
              </span>:&nbsp;
              <span className="ag-status-name-value-value">
                {numberFormatter(this.state.rowDataTotal.total_notional || 0)}
              </span>
            </div>
          </div>
        </div>
      );
    }
}
export default connect(state => ({
  literals: state.literals,
}))(TotalStatusBarComponent);
