import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import MakerNotionalSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';

const PAGE_SIZE = 50;

class MakerNotional extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
    };
    constructor(props) {
      super(props);
      const tableID = 'sidebar_maker_notional';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      const { literals } = props;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.tables.order_table.maker,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.maker'),
            field: 'maker',
            headerTooltip: literals.tables.order_table.maker,
            type: 'text',
          },
          {
            headerName: literals.tables.order_table.node_account,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.node_account'),
            field: 'tem',
            headerTooltip: literals.tables.order_table.node_account,
            type: 'text',
          },
          {
            headerName: literals.tables.order_table.symbol,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.symbol'),
            field: 'symbol',
            headerTooltip: literals.tables.order_table.symbol,
            type: 'text',
          },
          {
            headerName: literals.tables.order_table.fill_volume_value,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.fill_volume_value'),
            field: 'fill_volume',
            headerTooltip: literals.tables.order_table.fill_volume_value,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.order_table.long_fill_volume,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.long_fill_volume'),
            field: 'long_fill_volume',
            headerTooltip: literals.tables.order_table.long_fill_volume,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.order_table.short_fill_volume,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.short_fill_volume'),
            field: 'short_fill_volume',
            headerTooltip: literals.tables.order_table.short_fill_volume,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.order_table.notional,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.notional'),
            field: 'notional',
            headerTooltip: literals.tables.order_table.notional,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.order_table.long_notional,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.long_notional'),
            field: 'long_notional',
            headerTooltip: literals.tables.order_table.long_notional,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.order_table.short_notional,
            headerValueGetter: () => this.localizeHeader('literals.tables.order_table.short_notional'),
            field: 'short_notional',
            headerTooltip: literals.tables.order_table.short_notional,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
        ],
        rowData: [],
        collapse: '',
        defaultColDef: {
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
          menuTabs: ['filterMenuTab'],
          suppressMovable: true,
        },
        columnTypes: {
          text: { filter: 'agTextColumnFilter' },
          number: { filter: 'agNumberColumnFilter' },
          numberWithFilterReset: {
            filter: 'agNumberColumnFilter',
          },
        },
        paginationPageSize: PAGE_SIZE,
        cacheBlockSize: PAGE_SIZE,
        pinnedBottomRowData: [],
        riskAccountTem: {},
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.security_form.name !== prevProps.literals.forms.security_form.name) {
          this.gridApi.refreshHeader();
        }
      }
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.onGridSizeChanged(params);
      this.gridApi.refreshHeader();
    }

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['maker', 'tem', 'symbol', 'fill_volume', 'long_fill_volume', 'short_fill_volume',
          'notional', 'long_notional', 'short_notional'],
        fileName: 'MakerNotional.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['maker', 'tem', 'symbol', 'fill_volume', 'long_fill_volume', 'short_fill_volume',
          'notional', 'long_notional', 'short_notional'],
        fileName: 'MakerNotional.csv',
      };
      this.gridApi.exportDataAsCsv(params);
    };

    setBottomRowData=() => {
      let totNotional = 0;
      let totLongNotional = 0;
      let totShortNotional = 0;
      this.gridApi.forEachNodeAfterFilter((rowNode) => {
        totNotional += rowNode.data.notional;
        totLongNotional += rowNode.data.long_notional;
        totShortNotional += rowNode.data.short_notional;
      });
      const rowTotal = {
        notional: Math.round((totNotional + Number.EPSILON) * 100) / 100,
        long_notional: Math.round((totLongNotional + Number.EPSILON) * 100) / 100,
        short_notional: Math.round((totShortNotional + Number.EPSILON) * 100) / 100,
      };
      this.setState({ pinnedBottomRowData: [rowTotal] });
    };

    handleSubmit=(values) => {
      this.gridApi.showLoadingOverlay();
      const tem = [];
      if (values.risk_account && values.risk_account.length > 0) {
        const raTEM = this.state.riskAccountTem;
        values.risk_account.forEach((ra) => {
          if (ra in raTEM) {
            tem.push(...raTEM[ra]);
          }
        });
      }
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/report/maker_notional`,
        JSON.stringify({
          start_date: values.start_date ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
            : values.start_date,
          end_date: values.end_date ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
            : values.end_date,
          symbol: values.symbol,
          maker: values.maker,
          execution: [...values.execution || [], ...tem],
          agg_report: true,
        }),
      )
        .then((response) => {
          this.setState({ rowData: response.data });
          setTimeout(() => {
            this.setBottomRowData();
          }, 0);
          this.gridApi.hideOverlay();
        })
        .catch(() => {
          this.gridApi.hideOverlay();
        });
      this.setState({ collapse: 'force-close' });
      setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
    };

    updateRiskAccountTEM=(riskAccountTem) => {
      this.setState({ riskAccountTem });
    };

    localizeHeader=header => _.get(this.props, header)

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.maker_notional}
                </h5>
              </div>
              <Collapse
                title={literals.tables.ag_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <MakerNotionalSearchForm
                  onSubmit={this.handleSubmit}
                  onUpdateRiskAccountTEM={this.updateRiskAccountTEM}
                />
              </Collapse>
              <div className="outer-grid-label">
                {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
              </div>
              <div className="outer-grid-button">
                <ButtonToolbar>
                  <Tooltip title={literals.tables.ag_table.export_excel}>
                    <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                      <p className="fa fa-file-excel" />
                    </Button>
                  </Tooltip>
                  <Tooltip title={literals.tables.ag_table.export_csv}>
                    <Button
                      onClick={this.onBtExportCSV}
                      className="icon"
                      color="primary"
                    >
                      <p className="fa fa-file-alt" />
                    </Button>
                  </Tooltip>
                </ButtonToolbar>
              </div>
              <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                <div
                  id={this.myGridID}
                  style={{
                                    boxSizing: 'border-box',
                                    height: '85vh',
                                    width: '100%',
                                }}
                  className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnDefs}
                    suppressCellSelection
                    suppressContextMenu
                    defaultColDef={this.state.defaultColDef}
                    onColumnResized={this.onColumnResized}
                    rowData={this.state.rowData}
                    onGridReady={this.onGridReady}
                    onGridSizeChanged={this.onGridSizeChanged}
                    columnTypes={this.state.columnTypes}
                    pagination
                    paginationPageSize={this.state.paginationPageSize}
                    cacheBlockSize={this.state.cacheBlockSize}
                    pinnedBottomRowData={this.state.pinnedBottomRowData}
                    onFilterChanged={this.setBottomRowData}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(MakerNotional);
