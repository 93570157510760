/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import StateRenderer from '../../shared/components/StateRenderer';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized
} from '../../shared/helper';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

@inject('rootStore')
class LimitSymbolGroupDetailCellRenderer extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    data: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
    node: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    this.rowsToUpdate = props.context.rowsToUpdateDetail[props.node.id] || {};
    this.stylesToUpdate =
      props.context.stylesToUpdateDetail[props.node.id] || {};
    const { literals } = this.props;
    this.state = {
      modules: AllModules,
      colDefs: [
        {
          headerName: literals.forms.limit_symbol_form.symbol_val,
          field: 'symbol_val',
          editable: false,
          pinned: 'left',
          cellClassRules: {
            'arrow-right': (params) =>
              typeof this.stylesToUpdate[params.node.id] !== 'undefined'
          },
          width: 60,
          minWidth: 60
        },
        {
          headerName: literals.forms.limit_symbol_form.limit_symbol_group,
          field: 'limit_symbol_group',
          editable: false
        },
        {
          headerName: literals.forms.uni_symbol_form.security,
          field: 'security',
          editable: false
        },
        {
          headerName: literals.forms.limit_symbol_form.limit,
          field: 'limit',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.limit_symbol_form.margin_percentage,
          field: 'margin_percentage',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.limit_symbol_form.commission,
          field: 'commission',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.limit_symbol_form.commission_type,
          field: 'commission_type_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'CommissionType'
          }
        },
        {
          headerName: literals.forms.limit_symbol_form.swaps_type,
          field: 'swaps_type_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'SwapsType'
          }
        },
        {
          headerName: literals.forms.limit_symbol_form.contract_size,
          field: 'contract_size',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.limit_symbol_form.long_value,
          field: 'long_value',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.limit_symbol_form.short_value,
          field: 'short_value',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.limit_symbol_form.pl_multiplier,
          field: 'pl_multiplier',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.limit_symbol_form.three_days_swaps,
          field: 'three_days_swaps_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'ThreeDaysSwaps'
          }
        },
        {
          headerName: literals.forms.limit_symbol_form.settlement_time,
          field: 'settlement_time',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.lp_symbol_form.description,
          field: 'description',
          editable: false,
          tooltipField: 'description'
        }
      ],
      frameworkComponents: {
        stateRenderer: StateRenderer,
        cellHeaderComponent: HeaderComponent
      },
      rowData: [],
      rowOldData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50
      },
      cellEditUpdate: props.context.cellEditUpdateDetail[props.node.id],
      getRowNodeId(data) {
        return data.symbol_val;
      }
    };

    this.state.rowID = props.node.id
      ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff')
      : props.node.id;
    this.gridWrapperID = 'grid-wrapper-sidebar_limit_symbol_group';
    this.myGridID = `myGrid_sidebar_limit_symbol_${this.state.rowID}`;
    this.state.masterGridApi = props.api;
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridInfo.columnApi) {
      const { literals } = this.props;
      if (
        literals.forms.limit_symbol_form.symbol_val !==
        prevProps.literals.forms.limit_symbol_form.symbol_val
      ) {
        this.gridInfo.columnApi.getColumn('symbol_val').getColDef().headerName =
          literals.forms.limit_symbol_form.symbol_val;
        this.gridInfo.columnApi
          .getColumn('limit_symbol_group')
          .getColDef().headerName =
          literals.forms.limit_symbol_form.limit_symbol_group;
        this.gridInfo.columnApi.getColumn('security').getColDef().headerName =
          literals.forms.uni_symbol_form.security;
        this.gridInfo.columnApi.getColumn('limit').getColDef().headerName =
          literals.forms.limit_symbol_form.limit;
        this.gridInfo.columnApi
          .getColumn('margin_percentage')
          .getColDef().headerName =
          literals.forms.limit_symbol_form.margin_percentage;
        this.gridInfo.columnApi.getColumn('commission').getColDef().headerName =
          literals.forms.limit_symbol_form.commission;
        this.gridInfo.columnApi
          .getColumn('commission_type_value')
          .getColDef().headerName =
          literals.forms.limit_symbol_form.commission_type;
        this.gridInfo.columnApi
          .getColumn('swaps_type_value')
          .getColDef().headerName = literals.forms.limit_symbol_form.swaps_type;
        this.gridInfo.columnApi
          .getColumn('contract_size')
          .getColDef().headerName =
          literals.forms.limit_symbol_form.contract_size;
        this.gridInfo.columnApi.getColumn('long_value').getColDef().headerName =
          literals.forms.limit_symbol_form.long_value;
        this.gridInfo.columnApi
          .getColumn('short_value')
          .getColDef().headerName =
          literals.forms.limit_symbol_form.short_value;
        this.gridInfo.columnApi
          .getColumn('pl_multiplier')
          .getColDef().headerName =
          literals.forms.limit_symbol_form.pl_multiplier;
        this.gridInfo.columnApi
          .getColumn('three_days_swaps_value')
          .getColDef().headerName =
          literals.forms.limit_symbol_form.three_days_swaps;
        this.gridInfo.columnApi
          .getColumn('settlement_time')
          .getColDef().headerName =
          literals.forms.limit_symbol_form.settlement_time;
        this.gridInfo.columnApi
          .getColumn('description')
          .getColDef().headerName = literals.forms.lp_symbol_form.description;
        this.gridInfo.api.refreshHeader();
      }
    }
  }

  componentWillUnmount = () => {
    if (this.gridInfo) {
      window[`colStateLimitSymbolGroup${this.state.rowID}`] =
        this.gridInfo.columnApi.getColumnState();
      window[`groupStateLimitSymbolGroup${this.state.rowID}`] =
        this.gridInfo.columnApi.getColumnGroupState();
      window[`filterStateLimitSymbolGroup${this.state.rowID}`] =
        this.gridInfo.api.getFilterModel();
    }

    const detailGridId = this.createDetailGridId();

    // ag-Grid is automatically destroyed
    this.state.masterGridApi.removeDetailGridInfo(detailGridId);
  };

  onCellClass = (params) =>
    typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
    typeof this.stylesToUpdate[params.node.id][params.colDef.field] !==
      'undefined';

  onCellValueChanged = (params) => {
    if (params.oldValue != params.value) {
      if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
        this.stylesToUpdate[params.node.id] = {};
        this.gridInfo.api.refreshCells({
          rowNodes: [params.node],
          columns: ['symbol_val'],
          force: true
        });
      }
      this.stylesToUpdate[params.node.id][params.colDef.field] = {};
      this.gridInfo.api.refreshCells({
        rowNodes: [params.node],
        columns: [params.column],
        force: true
      });

      if (params.colDef.field === 'commission_type_value') {
        params.data.commission_type =
          this.props.context.formData.CommissionTypeObj[params.newValue].value;
      }
      if (params.colDef.field === 'swaps_type_value') {
        params.data.swaps_type =
          this.props.context.formData.SwapsTypeObj[params.newValue].value;
      }
      if (params.colDef.field === 'three_days_swaps_value') {
        params.data.three_days_swaps =
          this.props.context.formData.ThreeDaysSwapsObj[params.newValue].value;
      }

      this.rowsToUpdate[`${params.node.id}`] = params.data;
      this.setState({ cellEditUpdate: true });
      this.props.context.updateStateDetail(
        this.state.rowID,
        true,
        this.rowsToUpdate,
        this.stylesToUpdate
      );
    }
  };

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onGridReady(params) {
    const detailGridId = this.createDetailGridId();

    this.gridInfo = {
      id: detailGridId,
      api: params.api,
      columnApi: params.columnApi
    };
    this.gridInfo.api.showLoadingOverlay();
    this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfo);

    this.gridInfo.columnApi
      .getColumn('commission_type_value')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .CommissionType
      ? this.props.context.formData.CommissionType.map((a) => a.label)
      : [];
    this.gridInfo.columnApi
      .getColumn('swaps_type_value')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .SwapsType
      ? this.props.context.formData.SwapsType.map((a) => a.label)
      : [];
    this.gridInfo.columnApi
      .getColumn('three_days_swaps_value')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .ThreeDaysSwaps
      ? this.props.context.formData.ThreeDaysSwaps.map((a) => a.label)
      : [];

    if (window[`colStateLimitSymbolGroup${this.state.rowID}`]) {
      this.gridInfo.columnApi.setColumnState(
        window[`colStateLimitSymbolGroup${this.state.rowID}`]
      );
    }
    if (window[`groupStateLimitSymbolGroup${this.state.rowID}`]) {
      this.gridInfo.columnApi.setColumnGroupState(
        window[`groupStateLimitSymbolGroup${this.state.rowID}`]
      );
    }
    if (window[`filterStateLimitSymbolGroup${this.state.rowID}`]) {
      this.gridInfo.api.setFilterModel(
        window[`filterStateLimitSymbolGroup${this.state.rowID}`]
      );
    }
    this.setState({ rowData: this.props.data.limit_symbols });
    this.setState({
      rowOldData: JSON.parse(JSON.stringify(this.props.data.limit_symbols))
    });
    this.onGridSizeChanged(params);
  }

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: [
        'symbol_val',
        'limit_symbol_group',
        'security',
        'limit',
        'margin_percentage',
        'commission',
        'commission_type_value',
        'swaps_type_value',
        'contract_size',
        'long_value',
        'short_value',
        'pl_multiplier',
        'three_days_swaps_value',
        'settlement_time',
        'description'
      ],
      fileName: 'LimitSymbol.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridInfo.api.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: [
        'symbol_val',
        'limit_symbol_group',
        'security',
        'limit',
        'margin_percentage',
        'commission',
        'commission_type_value',
        'swaps_type_value',
        'contract_size',
        'long_value',
        'short_value',
        'pl_multiplier',
        'three_days_swaps_value',
        'settlement_time',
        'description'
      ],
      fileName: 'LimitSymbol.csv'
    };
    this.gridInfo.api.exportDataAsCsv(params);
  };

  createDetailGridId = () => `detail_${this.state.rowID}`;

  updateLimitSymbol = () => {
    this.props.context.showLoader();
    const bulkData = [];
    const rowData = _.keyBy(this.state.rowOldData, 'symbol_val');
    Object.values(this.rowsToUpdate).forEach((values) => {
      const oldRow = rowData[values.symbol_val];
      const dataToUpdate = {
        symbol_val: values.symbol_val,
        limit_symbol_group: values.limit_symbol_group,
        limit: parseFloat(values.limit),
        margin_percentage: parseFloat(values.margin_percentage),
        commission: parseFloat(values.commission),
        commission_type: parseInt(values.commission_type, 10),
        swaps_type: parseInt(values.swaps_type, 10),
        contract_size: parseFloat(values.contract_size),
        long_value: parseFloat(values.long_value),
        short_value: parseFloat(values.short_value),
        pl_multiplier: parseFloat(values.pl_multiplier),
        vol_multiplier: parseFloat(values.vol_multiplier),
        three_days_swaps: parseInt(values.three_days_swaps, 10),
        settlement_time: values.settlement_time,
        old_data: {
          symbol_val: oldRow.symbol_val,
          limit_symbol_group: oldRow.limit_symbol_group,
          limit: parseFloat(oldRow.limit),
          margin_percentage: parseFloat(oldRow.margin_percentage),
          commission: parseFloat(oldRow.commission),
          commission_type: parseInt(oldRow.commission_type, 10),
          swaps_type: parseInt(oldRow.swaps_type, 10),
          contract_size: parseFloat(oldRow.contract_size),
          long_value: parseFloat(oldRow.long_value),
          short_value: parseFloat(oldRow.short_value),
          pl_multiplier: parseFloat(oldRow.pl_multiplier),
          vol_multiplier: parseFloat(oldRow.vol_multiplier),
          three_days_swaps: parseInt(oldRow.three_days_swaps, 10),
          settlement_time: oldRow.settlement_time
        }
      };
      bulkData.push(dataToUpdate);
    });
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/account/limit_symbol_bulk`,
        JSON.stringify(bulkData)
      )
      .then((response) => {
        this.resetLimitSymbol();
        showNotification(
          this.props.literals.sidebar.limit_symbol,
          'primary',
          this.props.literals.forms.completed_message
        );
        if (response.data.length > 0) {
          showNotification(
            this.props.literals.sidebar.limit_symbol,
            'danger',
            `${
              this.props.literals.forms.fail_message
            } - ${response.data.toString()}}`
          );
        }
      })
      .catch((error) => {
        this.resetLimitSymbol();
        showNotification(
          this.props.literals.sidebar.limit_symbol,
          'danger',
          `${this.props.literals.forms.fail_message} - ${error.message}}`
        );
      });
  };

  resetLimitSymbol = () => {
    this.rowsToUpdate = {};
    this.setState({ cellEditUpdate: false });
    this.props.context.resetLimitSymbolGroup();
    window[`colStateLimitSymbolGroup${this.state.rowID}`] =
      this.gridInfo.columnApi.getColumnState();
    window[`groupStateLimitSymbolGroup${this.state.rowID}`] =
      this.gridInfo.columnApi.getColumnGroupState();
    window[`filterStateLimitSymbolGroup${this.state.rowID}`] =
      this.gridInfo.api.getFilterModel();
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate).forEach((keys) => {
      const node = this.gridInfo.api.getRowNode(keys);
      if (node) {
        this.gridInfo.api.refreshCells({
          rowNodes: [node],
          columns: ['symbol_val'],
          force: true
        });
        Object.keys(stylesToUpdate[keys]).forEach((values) => {
          this.gridInfo.api.refreshCells({
            rowNodes: [node],
            columns: [values],
            force: true
          });
        });
      }
    });
    this.props.context.updateStateDetail(
      this.state.rowID,
      false,
      this.rowsToUpdate,
      this.stylesToUpdate
    );
  };

  render() {
    const { literals } = this.props;
    return (
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          height: '100%'
        }}
      >
        <div className="outer-grid-label">
          {literals.sidebar.limit_symbol} (
          {literals.tables.ag_table.total_records}: {this.state.rowData.length})
        </div>
        <div className="outer-grid-button">
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.save}>
              <Button
                style={{
                  display: this.state.cellEditUpdate ? '' : 'none'
                }}
                onClick={this.updateLimitSymbol}
                className="icon"
                color="primary"
              >
                <p className="fa fa-save" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.undo}>
              <Button
                style={{
                  display: this.state.cellEditUpdate ? '' : 'none'
                }}
                onClick={() => {
                  this.props.context.showLoader();
                  this.resetLimitSymbol();
                }}
                className="icon"
                color="primary"
              >
                <p className="fas fa-undo" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button
                onClick={this.onBtExportExcel}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_csv}>
              <Button
                onClick={this.onBtExportCSV}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-alt" />
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </div>
        <div
          id={this.myGridID}
          style={{
            width: '100%',
            height: this.props.node.rowHeight - REDUCE_HEIGHT,
            clear: 'both'
          }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.colDefs}
            stopEditingWhenCellsLoseFocus
            suppressCellSelection
            suppressContextMenu
            immutableData
            getRowNodeId={this.state.getRowNodeId}
            frameworkComponents={this.state.frameworkComponents}
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResized}
            onCellValueChanged={this.onCellValueChanged}
            rowData={this.state.rowData}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.onGridSizeChanged}
            rowHeight={20}
            context={{
              formData: this.props.context.formData,
              showLoader: this.props.context.showLoader,
              hideLoader: this.props.context.hideLoader,
              onCellValueChanged: this.onCellValueChanged
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  literals: state.literals
}))(withRouter(LimitSymbolGroupDetailCellRenderer));
