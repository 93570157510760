/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham-dark.css';
import { withRouter } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UserActionRenderer from './userActionRenderer';
import { ThemeProps, LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import UserForm from './userForm';
import userAction from './userAction';
import Loader from '../../shared/components/Loader';
import StateRenderer from '../../shared/components/StateRenderer';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import RiskEditor from './riskEditor';

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class User extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = {};
      this.stylesToUpdate = {};
      const { literals } = this.props;
      const tableID = 'sidebar_user';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.forms.user_form.username,
            field: 'username',
            editable: false,
            pinned: 'left',
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.first_name,
            field: 'first_name',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.last_name,
            field: 'last_name',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.email,
            field: 'email',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.phone,
            field: 'phone',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.risk_account,
            field: 'user_risk_accounts',
            editable: false,
            cellRenderer: 'riskEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            filterValueGetter: params => this.userRiskAccountsFormat(params.node.data.user_risk_accounts),
          },
          {
            headerName: literals.forms.user_form.email_notify,
            field: 'email_notify',
            cellEditor: 'agLargeTextCellEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
          },
        ],
        frameworkComponents: {
          actionRenderer: UserActionRenderer,
          readOnlyRenderer: StateRenderer,
          riskEditor: RiskEditor,
        },
        rowData: [],
        rowOldData: [],
        formData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        getRowNodeId(data) {
          return data.username;
        },
        cellEditUpdate: false,
        activeTab: '1',
        height: window.innerHeight,
        loaderShow: false,
      };
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.user_form.username !== prevProps.literals.forms.user_form.username) {
          this.gridColumnApi.getColumn('username').getColDef().headerName
              = literals.forms.user_form.username;
          this.gridColumnApi.getColumn('first_name').getColDef().headerName
              = literals.forms.user_form.first_name;
          this.gridColumnApi.getColumn('last_name').getColDef().headerName
              = literals.forms.user_form.last_name;
          this.gridColumnApi.getColumn('email').getColDef().headerName
              = literals.forms.user_form.email;
          this.gridColumnApi.getColumn('phone').getColDef().headerName
              = literals.forms.user_form.phone;
          this.gridColumnApi.getColumn('user_risk_accounts').getColDef().headerName
              = literals.forms.user_form.risk_account;
          this.gridColumnApi.getColumn('email_notify').getColDef().headerName
            = literals.forms.user_form.email_notify;
          this.gridColumnApi.getColumn('actions').getColDef().headerName = literals.tables.ag_table.actions;
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.showLoadingOverlay();
      this.onGridSizeChanged(params);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/broker/user`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.User)) });
          this.gridColumnApi.getColumn('combined_risk_account').getColDef().cellEditorParams.values =
            response.data.RiskAccount ? response.data.RiskAccount.map(a => a.label) : [];
        })
        .catch(() => {
        });
    }

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridApi.refreshCells({ rowNodes: [params.node], columns: ['username'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridApi.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['username', 'first_name', 'last_name', 'email', 'phone', 'user_risk_accounts', 'email_notify'],
        fileName: 'User.xlsx',
        suppressTextAsCDATA: true,
      };
      params.processCellCallback = (cellParams) => {
        if (cellParams && cellParams.column.colId === 'user_risk_accounts') {
          return this.userRiskAccountsFormat(cellParams.value);
        } return cellParams.value;
      };
      this.gridApi.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['username', 'first_name', 'last_name', 'email', 'phone', 'user_risk_accounts', 'email_notify'],
        fileName: 'User.csv',
      };
      params.processCellCallback = (cellParams) => {
        if (cellParams && cellParams.column.colId === 'user_risk_accounts') {
          return this.userRiskAccountsFormat(cellParams.value);
        } return cellParams.value;
      };
      this.gridApi.exportDataAsCsv(params);
    };

    updateData = (data) => {
      this.setState({ rowData: data.User });
      this.setState({
        formData: {
          RiskAccount: data.RiskAccount,
          ReadOnlyObj: arrayToObject(data.ReadOnly, 'label'),
        },
      });
    };

    updateDimensions=() => {
      this.setState({ height: window.innerHeight });
    };

    updateUser=() => {
      this.showLoader();
      const len = Object.keys(this.rowsToUpdate).length;
      let i = 0;
      let j = 0;
      const successItems = [];
      const failItems = [];
      const errorItems = [];
      const rowData = _.keyBy(this.state.rowOldData, 'username');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.username];
        const dataToUpdate = {
          username: values.username,
          first_name: values.first_name,
          last_name: values.last_name,
          user_risk_accounts: values.user_risk_accounts,
          email: values.email,
          phone: values.phone,
          email_notify: values.email_notify,
          old_data: {
            username: oldRow.username,
            first_name: oldRow.first_name,
            last_name: oldRow.last_name,
            user_risk_accounts: oldRow.user_risk_accounts,
            email: oldRow.email,
            phone: oldRow.phone,
            email_notify: oldRow.email_notify,
          },
        };
        axios.put(`${process.env.REACT_APP_BACKEND_API}/broker/user`, JSON.stringify(dataToUpdate))
          .then((response) => {
            i += 1;
            if (response.data.status) {
              successItems.push(values.username);
            } else {
              failItems.push(values.username);
            }
            if (len === i) {
              if (successItems.length > 0) {
                showNotification(
                  this.props.literals.forms.user_form.title_update, 'primary',
                  `${this.props.literals.forms.success_message} - ${successItems.join(', ')}`,
                );
              }
              if (failItems.length > 0) {
                showNotification(
                  this.props.literals.forms.user_form.title_update, 'danger',
                  `${this.props.literals.forms.fail_message} - ${failItems.join(', ')}`,
                );
              }
              this.resetLocalUser();
              this.resetUser();
            }
          })
          .catch((error) => {
            j += 1;
            errorItems.push(values.username);
            if (len === j) {
              if (errorItems.length > 0) {
                showNotification(
                  this.props.literals.forms.user_form.title_update,
                  'danger', `${error.message} - ${errorItems.join(', ')}`,
                );
              }
              this.resetLocalUser();
              this.resetUser();
            }
          });
      });
    };

    resetLocalUser=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({ rowNodes: [node], columns: ['username'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridApi.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
    };

    resetUser=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/broker/user`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.User)) });
          this.gridColumnApi.getColumn('combined_risk_account').getColDef().cellEditorParams.values =
            response.data.RiskAccount ? response.data.RiskAccount.map(a => a.label) : [];
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    userRiskAccountsFormat = (userRiskAccounts) => {
      const riskAccounts = userRiskAccounts || [];
      const riskAccountList =
          riskAccounts.map(ra => `${ra.risk_account}->${ra.taker}->${ra.tem}->${ra.taker_feed}`);
      return riskAccountList.join(', ');
    };

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--bordered-top tabs__custom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                                        this.toggle('1');
                                    }}
                      >
                        {literals.sidebar.user}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                          this.toggle('2');
                        }}
                      >
                        {literals.forms.user_form.title_create}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="outer-grid-label">
                        {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                      </div>
                      <div className="outer-grid-button">
                        <ButtonToolbar>
                          <Tooltip title={literals.tables.ag_table.save}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={this.updateUser}
                              className="icon"
                              color="primary"
                            ><p className="fa fa-save" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.undo}>
                            <Button
                              style={{
                                                    display: this.state.cellEditUpdate ? '' : 'none',
                                                }}
                              onClick={() => { this.showLoader(); this.resetLocalUser(); this.resetUser(); }}
                              className="icon"
                              color="primary"
                            ><p
                              className="fas fa-undo"
                            />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_excel}>
                            <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                              <p className="fa fa-file-excel" />
                            </Button>
                          </Tooltip>
                          <Tooltip title={literals.tables.ag_table.export_csv}>
                            <Button
                              onClick={this.onBtExportCSV}
                              className="icon"
                              color="primary"
                            >
                              <p className="fa fa-file-alt" />
                            </Button>
                          </Tooltip>
                        </ButtonToolbar>
                      </div>

                      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                        <div
                          id={this.myGridID}
                          style={{
                                        boxSizing: 'border-box',
                                        height: this.state.height - REDUCE_HEIGHT,
                                        width: '100%',
                                    }}
                          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                        >
                          <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnDefs}
                            stopEditingWhenCellsLoseFocus
                            suppressCellSelection
                            suppressContextMenu
                            immutableData
                            getRowNodeId={this.state.getRowNodeId}
                            defaultColDef={this.state.defaultColDef}
                            onColumnResized={this.onColumnResized}
                            onCellValueChanged={this.onCellValueChanged}
                            rowData={this.state.rowData}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                            onGridSizeChanged={this.onGridSizeChanged}
                            rowHeight={20}
                            context={{
                              formData: this.state.formData,
                              resetUser: this.resetUser,
                              showLoader: this.showLoader,
                              hideLoader: this.hideLoader,
                              theme: this.props.theme,
                              userRiskAccountsFormat: this.userRiskAccountsFormat,
                            }}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <UserForm
                        {...this.props}
                        state={{
                          formData: this.state.formData,
                          resetUser: this.resetUser,
                          toggle: this.toggle,
                          showLoader: this.showLoader,
                          hideLoader: this.hideLoader,
                        }}
                        onSubmit={userAction}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default withRouter(connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(User));
