const masterRiskAccountValidate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${props.literals.forms.master_risk_account_form.name} ${props.literals.forms.required_validate}`;
  }
  if (!values.risk_accounts) {
    errors.risk_accounts =
        `${props.literals.forms.master_risk_account_form.risk_accounts} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default masterRiskAccountValidate;
