/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { LiteralProps, ThemeProps } from '../../prop-types/ReducerProps';
import SessionEdit from '../session/SessionEdit';
import SessionEditForm from '../session/SessionEditForm';
import { copyObject } from '../../helper';

class SessionHeaderComponent extends PureComponent {
  static propTypes = {
    context: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    column: PropTypes.instanceOf(Object).isRequired,
    setSort: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
  };
  state = {
    popoverOpen: false,
    ascSort: 'inactive',
    descSort: 'inactive',
    noSort: 'inactive',
    sessions: 'MON,00:00-23:59;TUE,00:00-23:59;WED,00:00-23:59;' +
      'THU,00:00-23:59;FRI,00:00-23:59;SAT,00:00-23:59;SUN,00:00-23:59;',
    selectedOption: 'session_interface',
  };
  componentDidMount() {
    this.onSortChanged();
  }
  onSortChanged=() => {
    this.setState({
      ascSort: this.props.column.isSortAscending() ? 'active' : 'inactive',
      descSort: this.props.column.isSortDescending() ? 'active' : 'inactive',
      noSort: !this.props.column.isSortAscending() && !this.props.column.isSortDescending() ? 'active' : 'inactive',
    });
  };
  onSortRequested = (order) => {
    this.props.setSort(order, false);
    this.onSortChanged();
  };
  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };
  submitSession = (session) => {
    this.props.context.showLoader();
    this.setState({ sessions: session });
    let count = 0;
    const totalRows = this.props.api.getModel().rootNode.childrenAfterFilter.length;
    this.props.api.forEachNodeAfterFilter((node) => {
      count += 1;
      const newItem = copyObject(node.data);
      newItem[this.props.column.colId] = session;
      const obj = {
        oldValue: node.data[this.props.column.colId],
        value: session,
        newValue: session,
        node,
        colDef: this.props.column.colDef,
        column: this.props.column,
        data: newItem,
      };
      this.props.context.onCellValueChanged(obj);
      this.props.api.applyTransactionAsync({ update: [newItem] }, this.resultCallback(totalRows, count));
    });
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };
  resultCallback=(totalRows, count) => {
    if (count === totalRows) {
      this.props.context.hideLoader();
    }
  }
  resetSession=() => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.target.value });
  };
  render() {
    const theme = this.props.theme.className;
    const { selectedOption } = this.state;
    return (
      <span className="ag-header-cell-label ag-header-cell-label-custom">
        <span className="ag-header-cell-text">
          <div
            id={`PopoverTop${this.props.column.colId}`}
            ref={(c) => {
                 this.target = c && ReactDOM.findDOMNode(c);
               }}
            onClick={this.toggle}
            className="fa fa-edit fa-400 customSortDownLabel"
            style={{ cursor: 'pointer' }}
          />
          <Popover
            placement="bottom-end"
            isOpen={this.state.popoverOpen}
            target={() => this.target}
            toggle={this.toggle}
            className={theme === 'theme-light'
              ? 'theme-light trading-light session-popover' : 'theme-dark trading-dark session-popover'}
          >
            <PopoverHeader>
              {this.props.literals.tables.ag_table.edit} {this.props.column.colDef.headerName}
            </PopoverHeader>
            <PopoverBody>
              <label
                className="radio-btn"
              >
                <input
                  className="radio-btn__radio"
                  type="radio"
                  name="session_edit"
                  value="session_interface"
                  checked={selectedOption === 'session_interface'}
                  onChange={this.handleChange}
                />
                <span className="radio-btn__radio-custom" />
                <span className="radio-btn__label">
                  {this.props.literals.tables.ag_table.interface}
                </span>
              </label>
              <label
                className="radio-btn"
              >
                <input
                  className="radio-btn__radio"
                  type="radio"
                  name="session_edit"
                  value="session_text"
                  checked={selectedOption === 'session_text'}
                  onChange={this.handleChange}
                />
                <span className="radio-btn__radio-custom" />
                <span className="radio-btn__label">
                  {this.props.literals.tables.ag_table.free_text}
                </span>
              </label>
              {this.state.selectedOption === 'session_text' &&
              <SessionEditForm
                sessions={this.state.sessions}
                initialSessionValue={this.state.sessions}
                submitSession={this.submitSession}
                resetSession={this.resetSession}
              />
              }
              {this.state.selectedOption === 'session_interface' &&
              <SessionEdit
                sessions={this.state.sessions}
                initialSessionValue={this.state.sessions}
                submitSession={this.submitSession}
                resetSession={this.resetSession}
              />
              }
            </PopoverBody>
          </Popover>
          <div
            onClick={() => { this.onSortRequested('asc'); }}
            onTouchEnd={() => { this.onSortRequested('asc'); }}
            className={`customSortDownLabel ${this.state.ascSort}`}
          >
            <i className="fa fa-long-arrow-alt-down" style={{ cursor: 'pointer' }} />
          </div>
          <div
            onClick={() => { this.onSortRequested('desc'); }}
            onTouchEnd={() => { this.onSortRequested('desc'); }}
            className={`customSortDownLabel ${this.state.descSort}`}
          >
            <i className="fa fa-long-arrow-alt-up" style={{ cursor: 'pointer' }} />
          </div>
          <div
            onClick={() => { this.onSortRequested(''); }}
            onTouchEnd={() => { this.onSortRequested(''); }}
            className={`customSortDownLabel ${this.state.noSort}`}
          >
            <i className="fa fa-times" style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ clear: 'both' }}>
            {this.props.column.colDef.headerName}
          </div>
        </span>
      </span>
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(SessionHeaderComponent);
