/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonToolbar } from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import { inject } from 'mobx-react';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';

@inject('rootStore')
class GiveUpRuleActionRenderer extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      context: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.deleteGiveUpRule = this.deleteGiveUpRule.bind(this);
      let notification = null;
      NotificationSystem.newInstance({}, n => notification = n);
      this.showNotification = (title, color, message) => {
        notification.notice({
          content: <BasicNotification
            title={title}
            message={message}
            color={color}
          />,
          duration: 3,
          closable: true,
          style: { top: 0 },
          className: 'left-up',
        });
      };
    }
    state = {
      visible: false,
      destroyOnClose: false,
    };
    onClick = () => {
      this.setState({
        visible: true,
      });
    };
    onClose = () => {
      this.setState({
        visible: false,
      });
    };

    deleteGiveUpRule() {
      this.props.context.showLoader();
      this.onClose();
      const dataToDelete = {
        id: parseInt(this.props.node.data.id, 10),
        // Below fields are only for Audit Log (not for Delete)
        takers: this.props.node.data.takers,
        tems: this.props.node.data.tems,
        target_taker: this.props.node.data.target_taker,
        securities: this.props.node.data.securities,
        symbols: this.props.node.data.symbols,
        sides: parseInt(this.props.node.data.sides, 10),
        source_extlogin: parseInt(this.props.node.data.source_extlogin, 10),
        source_extgroup: this.props.node.data.source_extgroup,
        target_login: parseInt(this.props.node.data.target_login, 10),
        gur_mode: parseInt(this.props.node.data.gur_mode, 10),
        makers: this.props.node.data.makers,
        gur_stl_mode: parseInt(this.props.node.data.gur_stl_mode, 10),
        symbol_format: this.props.node.data.symbol_format,
        ratio: parseFloat(this.props.node.data.ratio),
        orig_markup: parseInt(this.props.node.data.orig_markup, 10),
        markup_model: this.props.node.data.markup_model,
        priority: parseInt(this.props.node.data.priority, 10),
        description: this.props.node.data.description,
        state: parseInt(this.props.node.data.state, 10),
      };
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/give_up_rule/delete`,
        JSON.stringify(dataToDelete),
      )
        .then((response) => {
          if (response.data.status) {
            this.showNotification(
              this.props.literals.forms.give_up_rule_form.title_delete, 'primary',
              this.props.literals.forms.success_message,
            );
            this.props.context.resetGiveUpRule();
          } else {
            this.showNotification(
              this.props.literals.forms.give_up_rule_form.title_delete, 'danger',
              this.props.literals.forms.fail_message,
            );
            this.props.context.hideLoader();
          }
        })
        .catch((error) => {
          this.showNotification(
            this.props.literals.forms.give_up_rule_form.title_delete,
            'danger', error.message,
          );
          this.props.context.hideLoader();
        });
    }

    render() {
      const { literals } = this.props;
      let dialog;
      if (this.state.visible || !this.state.destroyOnClose) {
        dialog = (
          <Dialog
            visible={this.state.visible}
            wrapClassName="center"
            animation="slide-fade"
            maskAnimation="fade"
            onClose={this.onClose}
            style={{ width: 276 }}
            title={literals.forms.confirm_delete}
          >
            <p>{literals.forms.confirm_delete_note} {this.props.node.data.id}?</p>
            <div style={{
                        marginTop: 10,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '68%',
                    }}
            >
              <ButtonToolbar>
                <Button
                  className="icon"
                  color="primary"
                  key="close"
                  onClick={this.onClose}
                >
                  {literals.forms.cancel}
                </Button>
                <Button
                  className="icon"
                  color="primary"
                  key="save"
                  onClick={this.deleteGiveUpRule}
                >
                  {literals.forms.delete}
                </Button>
              </ButtonToolbar>
            </div>
          </Dialog>
        );
      }
      return (
        <span>
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.delete}>
              <Button
                style={{ height: 14 }}
                id="GiveUpRuleDeleteClick"
                onClick={this.onClick}
                className="icon"
                color="primary"
              ><p
                style={{ lineHeight: 0 }}
                className="fa fa-trash-alt"
              />
              </Button>
            </Tooltip>
          </ButtonToolbar>
          {dialog}
        </span>

      );
    }
}

export default connect(state => ({
  literals: state.literals,
}))(withRouter(GiveUpRuleActionRenderer));
