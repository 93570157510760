/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Popover,
  PopoverBody,
  PopoverHeader
} from 'reactstrap';
import ReactDOM from 'react-dom';

export default function ({
  onAction,
  theme,
  literals,
  mainButtonText,
  popupHeaderText,
  popupBodyText1,
  popupBodyText2
}) {
  const [open, setOpen] = useState(false);
  let linkRef = null;

  function toggle() {
    setOpen(!open);
  }

  const handleSubmit = () => {
    toggle();
    onAction();
  };

  return (
    <>
      <Button
        ref={(c) => {
          linkRef = c && ReactDOM.findDOMNode(c);
        }}
        color="primary"
        type="button"
        onClick={toggle}
      >
        {mainButtonText}
      </Button>
      <Popover
        placement="top"
        isOpen={open}
        target={() => linkRef}
        toggle={toggle}
        className={
          theme.className === 'theme-light' ? 'trading-light' : 'trading-dark'
        }
      >
        <PopoverHeader>{popupHeaderText}</PopoverHeader>
        <PopoverBody>
          <span>{`${popupBodyText1} ${popupBodyText2}?`}</span>
          <ButtonToolbar className="btn-toolbar--center">
            <Button className="btn btn-blue" onClick={toggle}>
              {literals.forms.cancel}
            </Button>
            <Button className="btn btn-blue" onClick={handleSubmit}>
              {literals.forms.confirm}
            </Button>
          </ButtonToolbar>
        </PopoverBody>
      </Popover>
    </>
  );
}
