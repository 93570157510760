import React, { Component } from 'react';
import TreeSelect from 'rc-tree-select';
import PropTypes from 'prop-types';

export default class SymbolFilter extends Component {
  static propTypes = {
    filterChangedCallback: PropTypes.func.isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
  }
  constructor(props) {
    super(props);

    this.state = {
      filterText: [],
    };
  }

  getModel=() => ({ values: this.state.filterText })

  setModel=(model) => {
    if (model) {
      this.setState({ filterText: model.values });
    }
  }

  doesFilterPass=() => true

  isFilterActive=() => this.state.filterText.length > 0

  switcherIcon = (props) => {
    if (props.isLeaf) {
      return '';
    }
    const color = '#70bbfd';
    if (props.expanded) {
      return <span className="fa fa-caret-down" style={{ color, fontSize: 16 }} />;
    }
    return <span className="fa fa-caret-right" style={{ color, fontSize: 16 }} />;
  };

  clearIcon = () => {
    const color = '#70bbfd';
    return <span className="fa fa-times" style={{ color, fontSize: 16 }} />;
  };

  handleChange = (selectedOption) => {
    this.setState({ filterText: selectedOption }, () =>
      this.props.filterChangedCallback());
  };

  render() {
    const theme = this.props.context.theme.className;
    return (
      <TreeSelect
        name="symbol_val"
        placeholder="Click to open the list"
        value={this.state.filterText}
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
        treeData={this.props.context.symbolData}
        treeLine
        treeCheckable
        onChange={this.handleChange}
        className={theme === 'theme-light' ?
            'rc-tree-select-dropdown-light ag-custom-component-popup' :
            'rc-tree-select-dropdown-dark ag-custom-component-popup'}
        dropdownClassName={theme === 'theme-light' ?
            'rc-tree-select-dropdown-light ag-custom-component-popup' :
            'rc-tree-select-dropdown-dark ag-custom-component-popup'}
        treeNodeFilterProp="title"
        switcherIcon={this.switcherIcon}
        autoClearSearchValue={false}
        allowClear
        clearIcon={this.clearIcon}
      />
    );
  }
}
