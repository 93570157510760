const serverInstanceValidate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${props.literals.forms.server_instance_form.name} ${props.literals.forms.required_validate}`;
  }
  if (!values.server_address) {
    errors.server_address =
        `${props.literals.forms.server_instance_form.server_address} ${props.literals.forms.required_validate}`;
  }
  if (!values.client) {
    errors.client = `${props.literals.forms.server_instance_form.client} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default serverInstanceValidate;
