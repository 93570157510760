/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MarkupGroupActionRenderer from './actionRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import MarkupGroupDetailCellRenderer from './detailCellRenderer';
import { BasicNotification } from '../../shared/components/Notification';
import MarkupGroupForm from './form';
import markupGroupAction from './action';
import StateRenderer from '../../shared/components/StateRenderer';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized
} from '../../shared/helper';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import Loader from '../../shared/components/Loader';
import MultiplierRenderer from './MultiplierRenderer';
import MarkupSymbolUpload from './upload';

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));
let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

@inject('rootStore')
class MarkupGroup extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    this.rowsToUpdate = {};
    this.stylesToUpdate = {};
    const { literals } = this.props;
    const tableID = 'sidebar_markup_group';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.forms.markup_group_form.name,
          field: 'name',
          cellRenderer: 'agGroupCellRenderer',
          editable: false,
          pinned: 'left',
          cellClassRules: {
            'arrow-right': (params) =>
              typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
              !params.node.master,
            'arrow-right-expand': (params) =>
              typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
              params.node.master
          },
          width: 60,
          minWidth: 60
        },
        {
          headerName: literals.forms.markup_group_form.bid_points,
          field: 'bid_points',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.markup_group_form.ask_points,
          field: 'ask_points',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.markup_group_form.state,
          field: 'state_value',
          editable: false,
          cellRenderer: 'stateRenderer',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'stateHeaderComponent'
        },
        {
          headerName: literals.forms.markup_group_form.multiplier_1,
          field: 'multiplier_1',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.markup_group_form.multiplier_2,
          field: 'multiplier_2',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.markup_group_form.multiplier_3,
          field: 'multiplier_3',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.markup_group_form.enable_multiplier,
          field: 'enable_multiplier',
          editable: false,
          cellRenderer: 'multiplierRenderer',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 120,
          minWidth: 120
        },
        {
          headerName: literals.forms.markup_group_form.description,
          field: 'description',
          cellEditor: 'agLargeTextCellEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.tables.ag_table.actions,
          field: 'actions',
          cellRenderer: 'actionRenderer',
          filter: false,
          editable: false
        }
      ],
      detailCellRenderer: 'myDetailCellRenderer',
      frameworkComponents: {
        myDetailCellRenderer: MarkupGroupDetailCellRenderer,
        actionRenderer: MarkupGroupActionRenderer,
        stateRenderer: StateRenderer,
        multiplierRenderer: MultiplierRenderer,
        stateHeaderComponent: StateHeaderComponent,
        cellHeaderComponent: HeaderComponent
      },
      rowData: [],
      rowOldData: [],
      formData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50
      },
      getRowNodeId(data) {
        return data.name;
      },
      cellEditUpdate: false,
      cellEditUpdateDetail: {},
      rowsToUpdateDetail: {},
      stylesToUpdateDetail: {},
      activeTab: '1',
      height: window.innerHeight,
      loaderShow: false
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (
        literals.forms.markup_group_form.name !==
        prevProps.literals.forms.markup_group_form.name
      ) {
        this.gridColumnApi.getColumn('name').getColDef().headerName =
          literals.forms.markup_group_form.name;
        this.gridColumnApi.getColumn('bid_points').getColDef().headerName =
          literals.forms.markup_group_form.bid_points;
        this.gridColumnApi.getColumn('ask_points').getColDef().headerName =
          literals.forms.markup_group_form.ask_points;
        this.gridColumnApi.getColumn('state_value').getColDef().headerName =
          literals.forms.markup_group_form.state;
        this.gridColumnApi.getColumn('multiplier_1').getColDef().headerName =
          literals.forms.markup_group_form.multiplier_1;
        this.gridColumnApi.getColumn('multiplier_2').getColDef().headerName =
          literals.forms.markup_group_form.multiplier_2;
        this.gridColumnApi.getColumn('multiplier_3').getColDef().headerName =
          literals.forms.markup_group_form.multiplier_3;
        this.gridColumnApi
          .getColumn('enable_multiplier')
          .getColDef().headerName =
          literals.forms.markup_group_form.enable_multiplier;
        this.gridColumnApi.getColumn('description').getColDef().headerName =
          literals.forms.markup_group_form.description;
        this.gridColumnApi.getColumn('actions').getColDef().headerName =
          literals.tables.ag_table.actions;
        this.gridApi.refreshHeader();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.onGridSizeChanged(params);
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/security/markup_group`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({
          rowOldData: JSON.parse(JSON.stringify(response.data.MarkupGroup))
        });
      })
      .catch(() => {});
  }

  onCellClass = (params) =>
    typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
    typeof this.stylesToUpdate[params.node.id][params.colDef.field] !==
      'undefined';

  onCellValueChanged = (params) => {
    if (params.oldValue != params.value) {
      if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
        this.stylesToUpdate[params.node.id] = {};
        this.gridApi.refreshCells({
          rowNodes: [params.node],
          columns: ['name'],
          force: true
        });
      }
      this.stylesToUpdate[params.node.id][params.colDef.field] = {};
      this.gridApi.refreshCells({
        rowNodes: [params.node],
        columns: [params.column],
        force: true
      });

      if (params.colDef.field === 'state_value') {
        params.data.state = this.state.formData.StateObj[params.newValue].value;
      }
      this.rowsToUpdate[`${params.node.id}`] = params.data;
      this.setState({ cellEditUpdate: true });
    }
  };

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: [
        'name',
        'bid_points',
        'ask_points',
        'state_value',
        'multiplier_1',
        'multiplier_2',
        'multiplier_3',
        'enable_multiplier',
        'description'
      ],
      fileName: 'MarkupModel.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: [
        'name',
        'bid_points',
        'ask_points',
        'state_value',
        'multiplier_1',
        'multiplier_2',
        'multiplier_3',
        'enable_multiplier',
        'description'
      ],
      fileName: 'MarkupModel.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  updateData = (data) => {
    this.setState({ rowData: data.MarkupGroup });
    this.setState({
      formData: {
        State: data.State,
        StateObj: arrayToObject(data.State, 'label'),
        Security: data.Security,
        MarkupGroup: data.MarkupGroupEnum,
        SpreadExec: data.SpreadExec,
        SpreadExecObj: arrayToObject(data.SpreadExec, 'label'),
        MinSpreadExec: data.MinSpreadExec,
        MinSpreadExecObj: arrayToObject(data.MinSpreadExec || [], 'label')
      }
    });
  };

  updateMarkupGroup = () => {
    this.showLoader();
    const bulkData = [];
    const rowData = _.keyBy(this.state.rowOldData, 'name');
    Object.values(this.rowsToUpdate).forEach((values) => {
      const oldRow = rowData[values.name];
      let multiplierState = '';
      if (values.enable_multiplier) {
        if (values.enable_multiplier === 'multiplier_1') {
          multiplierState = '1|0|0';
        } else if (values.enable_multiplier === 'multiplier_2') {
          multiplierState = '0|1|0';
        } else if (values.enable_multiplier === 'multiplier_3') {
          multiplierState = '0|0|1';
        }
      }
      const dataToUpdate = {
        name: values.name,
        state: parseInt(values.state, 10),
        description: values.description,
        bid_points: parseInt(values.bid_points, 10),
        ask_points: parseInt(values.ask_points, 10),
        multiplier: `${values.multiplier_1 || 1}|${values.multiplier_2 || 1}|${
          values.multiplier_3 || 1
        }`,
        multiplier_state: multiplierState,
        old_data: {
          name: oldRow.name,
          state: parseInt(oldRow.state, 10),
          description: oldRow.description,
          bid_points: parseInt(oldRow.bid_points, 10),
          ask_points: parseInt(oldRow.ask_points, 10),
          multiplier: oldRow.multiplier,
          multiplier_state: oldRow.multiplier_state
        }
      };
      bulkData.push(dataToUpdate);
    });
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/security/markup_group_bulk`,
        JSON.stringify(bulkData)
      )
      .then((response) => {
        this.resetLocalMarkupGroup();
        this.resetMarkupGroup();
        showNotification(
          this.props.literals.sidebar.markup_group,
          'primary',
          this.props.literals.forms.completed_message
        );
        if (response.data.length > 0) {
          showNotification(
            this.props.literals.sidebar.markup_group,
            'danger',
            `${
              this.props.literals.forms.fail_message
            } - ${response.data.toString()}}`
          );
        }
      })
      .catch((error) => {
        this.resetLocalMarkupGroup();
        this.resetMarkupGroup();
        showNotification(
          this.props.literals.sidebar.markup_group,
          'danger',
          `${this.props.literals.forms.fail_message} - ${error.message}}`
        );
      });
  };

  resetLocalMarkupGroup = () => {
    this.rowsToUpdate = {};
    this.setState({ cellEditUpdate: false });
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate).forEach((keys) => {
      const node = this.gridApi.getRowNode(keys);
      if (node) {
        this.gridApi.refreshCells({
          rowNodes: [node],
          columns: ['name'],
          force: true
        });
        Object.keys(stylesToUpdate[keys]).forEach((values) => {
          this.gridApi.refreshCells({
            rowNodes: [node],
            columns: [values],
            force: true
          });
        });
      }
    });
  };

  resetMarkupGroup = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/security/markup_group`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({
          rowOldData: JSON.parse(JSON.stringify(response.data.MarkupGroup))
        });
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  updateStateDetail = (key, cellEditUpdate, rowsToUpdate, stylesToUpdate) => {
    const cellEditUpdateDetail = { ...this.state.cellEditUpdateDetail };
    cellEditUpdateDetail[key] = cellEditUpdate;
    this.setState({ cellEditUpdateDetail });
    const rowsToUpdateDetail = { ...this.state.rowsToUpdateDetail };
    rowsToUpdateDetail[key] = rowsToUpdate;
    this.setState({ rowsToUpdateDetail });
    const stylesToUpdateDetail = { ...this.state.stylesToUpdateDetail };
    stylesToUpdateDetail[key] = stylesToUpdate;
    this.setState({ stylesToUpdateDetail });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="tabs tabs--bordered-top tabs__custom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1'
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {literals.sidebar.markup_group}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '2'
                      })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {literals.forms.markup_group_form.title_create}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '3'
                      })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      {`${literals.forms.title_import} ${literals.sidebar.markup_symbol}`}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="outer-grid-label">
                      {literals.tables.ag_table.total_records}:{' '}
                      {this.state.rowData ? this.state.rowData.length : 0}
                    </div>
                    <div className="outer-grid-button">
                      <ButtonToolbar>
                        <Tooltip title={literals.tables.ag_table.save}>
                          <Button
                            style={{
                              display: this.state.cellEditUpdate ? '' : 'none'
                            }}
                            onClick={this.updateMarkupGroup}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-save" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.undo}>
                          <Button
                            style={{
                              display: this.state.cellEditUpdate ? '' : 'none'
                            }}
                            onClick={() => {
                              this.showLoader();
                              this.resetLocalMarkupGroup();
                              this.resetMarkupGroup();
                            }}
                            className="icon"
                            color="primary"
                          >
                            <p className="fas fa-undo" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.export_excel}>
                          <Button
                            onClick={this.onBtExportExcel}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-file-excel" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.export_csv}>
                          <Button
                            onClick={this.onBtExportCSV}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-file-alt" />
                          </Button>
                        </Tooltip>
                      </ButtonToolbar>
                    </div>
                    <div
                      id={this.gridWrapperID}
                      style={{ width: '100%', height: '100%', clear: 'both' }}
                    >
                      <div
                        id={this.myGridID}
                        style={{
                          boxSizing: 'border-box',
                          height: this.state.height - REDUCE_HEIGHT,
                          width: '100%'
                        }}
                        className={
                          theme === 'theme-light'
                            ? 'ag-theme-balham'
                            : 'ag-theme-balham-dark'
                        }
                      >
                        <AgGridReact
                          modules={this.state.modules}
                          columnDefs={this.state.columnDefs}
                          stopEditingWhenCellsLoseFocus
                          suppressCellSelection
                          suppressContextMenu
                          immutableData
                          getRowNodeId={this.state.getRowNodeId}
                          defaultColDef={this.state.defaultColDef}
                          onColumnResized={this.onColumnResized}
                          onCellValueChanged={this.onCellValueChanged}
                          rowData={this.state.rowData}
                          masterDetail
                          getRowHeight={this.state.getRowHeight}
                          detailCellRenderer={this.state.detailCellRenderer}
                          detailRowHeight={340}
                          frameworkComponents={this.state.frameworkComponents}
                          onGridReady={this.onGridReady}
                          onGridSizeChanged={this.onGridSizeChanged}
                          rowHeight={20}
                          context={{
                            formData: this.state.formData,
                            resetMarkupGroup: this.resetMarkupGroup,
                            updateStateDetail: this.updateStateDetail,
                            cellEditUpdateDetail:
                              this.state.cellEditUpdateDetail,
                            rowsToUpdateDetail: this.state.rowsToUpdateDetail,
                            stylesToUpdateDetail:
                              this.state.stylesToUpdateDetail,
                            showLoader: this.showLoader,
                            hideLoader: this.hideLoader,
                            onCellValueChanged: this.onCellValueChanged
                          }}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <MarkupGroupForm
                      {...this.props}
                      state={{
                        formData: this.state.formData,
                        resetMarkupGroup: this.resetMarkupGroup,
                        toggle: this.toggle,
                        showLoader: this.showLoader,
                        hideLoader: this.hideLoader
                      }}
                      onSubmit={markupGroupAction}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <MarkupSymbolUpload
                      formData={this.state.formData}
                      resetMarkupGroup={this.resetMarkupGroup}
                      rowOldData={this.state.rowOldData}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(MarkupGroup);
