import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  CHANGE_THEME_TO_DARK,
  CHANGE_THEME_TO_LIGHT,
} from '../actions/themeActions';

const initialState = {
  className: 'theme-dark',
};

const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_THEME_TO_DARK:
      return { className: 'theme-dark' };
    case CHANGE_THEME_TO_LIGHT:
      return { className: 'theme-light' };
    default:
      return state;
  }
};
const persistConfig = {
  key: 'theme',
  storage,
};

export default persistReducer(persistConfig, ThemeReducer);
