/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { change } from 'redux-form';
import ConcentrationPerLoginActionRenderer from './actionRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import ConcentrationPerLoginForm from './form';
import takerAPILinkAction from './action';
import Loader from '../../shared/components/Loader';
import {
  onGridSizeChanged,
  onColumnResized,
  arrayToObject
} from '../../shared/helper';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import MultiEditor from './MultiEditor';

const REDUCE_HEIGHT = 200;
let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

@inject('rootStore')
class ConcentrationPerLogin extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.rowsToUpdate = {};
    this.stylesToUpdate = {};
    const { literals } = this.props;
    const tableID = 'sidebar_concentration_per_login';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.forms.concentration_per_login_form.id,
          field: 'id',
          cellClass: 'number',
          cellClassRules: {
            'arrow-right': (params) =>
              typeof this.stylesToUpdate[params.node.id] !== 'undefined'
          },
          editable: false
        },
        {
          headerName: literals.forms.concentration_per_login_form.takers,
          field: 'takers',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.concentration_per_login_form.tems,
          field: 'tems',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.concentration_per_login_form.securities,
          field: 'securities',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.concentration_per_login_form.symbols,
          field: 'symbols',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.concentration_per_login_form.sides,
          field: 'sides_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.concentration_per_login_form.ord_types,
          field: 'ord_types_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName:
            literals.forms.concentration_per_login_form.source_extlogin,
          field: 'source_extlogin',
          type: 'number'
        },
        {
          headerName:
            literals.forms.concentration_per_login_form.source_extgroup,
          field: 'source_extgroup'
        },
        {
          headerName: literals.forms.concentration_per_login_form.limit,
          field: 'limit',
          type: 'number'
        },
        {
          headerName:
            literals.forms.concentration_per_login_form.adjustment_value,
          field: 'adjustment_value',
          type: 'number'
        },
        {
          headerName: literals.forms.concentration_per_login_form.priority,
          field: 'priority',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.forms.concentration_per_login_form.description,
          field: 'description'
        },
        {
          headerName: literals.forms.concentration_per_login_form.state,
          field: 'state_value',
          editable: false,
          cellRenderer: 'stateRenderer',
          cellClassRules: { 'edit-row-color': this.onCellClass }
        },
        {
          headerName: literals.tables.ag_table.actions,
          field: 'actions',
          cellRenderer: 'actionRenderer',
          filter: false,
          editable: false
        }
      ],
      frameworkComponents: {
        actionRenderer: ConcentrationPerLoginActionRenderer,
        multiEditor: MultiEditor,
        stateRenderer: StateRenderer,
        stateHeaderComponent: StateHeaderComponent
      },
      rowData: [],
      rowOldData: [],
      formData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50
      },
      getRowNodeId(data) {
        return data.id;
      },
      cellEditUpdate: false,
      activeTab: '1',
      height: window.innerHeight,
      loaderShow: false,
      values: [{ tag: '', val: '' }]
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (
        literals.forms.security_form.name !==
        prevProps.literals.forms.security_form.name
      ) {
        this.gridApi.refreshHeader();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.onGridSizeChanged(params);
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/risk/concentration_per_login`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({
          rowOldData: JSON.parse(
            JSON.stringify(response.data.ConcentrationPerLogin)
          )
        });
        this.gridColumnApi
          .getColumn('sides_value')
          .getColDef().cellEditorParams.values = response.data.Side
          ? response.data.Side.map((a) => a.label)
          : [];
        this.gridColumnApi
          .getColumn('ord_types_value')
          .getColDef().cellEditorParams.values = response.data.OrdType
          ? response.data.OrdType.map((a) => a.label)
          : [];
        this.gridColumnApi
          .getColumn('priority')
          .getColDef().cellEditorParams.values = response.data.Priority
          ? response.data.Priority.map((a) => parseInt(a.label, 10))
          : [];
      })
      .catch(() => {});
  }

  onCellClass = (params) =>
    typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
    typeof this.stylesToUpdate[params.node.id][params.colDef.field] !==
      'undefined';

  onCellValueChanged = (params) => {
    if (params.oldValue != params.value) {
      if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
        this.stylesToUpdate[params.node.id] = {};
        this.gridApi.refreshCells({
          rowNodes: [params.node],
          columns: ['id'],
          force: true
        });
      }
      this.stylesToUpdate[params.node.id][params.colDef.field] = {};
      this.gridApi.refreshCells({
        rowNodes: [params.node],
        columns: [params.column],
        force: true
      });

      if (params.colDef.field === 'sides_value') {
        params.data.sides = this.state.formData.SideObj[params.newValue].value;
      }
      if (params.colDef.field === 'ord_types_value') {
        params.data.ord_types =
          this.state.formData.OrdTypeObj[params.newValue].value;
      }
      if (params.colDef.field === 'state_value') {
        params.data.state = this.state.formData.StateObj[params.newValue].value;
      }
      this.rowsToUpdate[`${params.node.id}`] = params.data;
      this.setState({ cellEditUpdate: true });
    }
  };

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: [
        'id',
        'takers',
        'tems',
        'securities',
        'symbols',
        'sides_value',
        'ord_types_value',
        'source_extlogin',
        'source_extgroup',
        'limit',
        'adjustment_value',
        'priority',
        'description',
        'state_value'
      ],
      fileName: 'ConcentrationPerLogin.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: [
        'id',
        'takers',
        'tems',
        'securities',
        'symbols',
        'sides_value',
        'ord_types_value',
        'source_extlogin',
        'source_extgroup',
        'limit',
        'adjustment_value',
        'priority',
        'description',
        'state_value'
      ]
    };
    this.gridApi.exportDataAsCsv(params);
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  updateData = (data) => {
    this.setState({ rowData: data.ConcentrationPerLogin });
    this.setState({
      formData: {
        Taker: data.Taker,
        TakerExecutionModel: data.TakerExecutionModel,
        Security: data.Security,
        Symbol: data.Symbol,
        Maker: data.Maker,
        Side: data.Side,
        SideObj: arrayToObject(data.Side, 'label'),
        OrdType: data.OrdType,
        OrdTypeObj: arrayToObject(data.OrdType, 'label'),
        Priority: data.Priority,
        SecuritySymbol: data.SecuritySymbol,
        State: data.State,
        StateObj: arrayToObject(data.State, 'label'),
        RuleMacro: data.RuleMacro
      }
    });
    this.props.dispatch(
      change('concentration_per_login_form', 'takers_tree', ['*,'])
    );
    this.props.dispatch(
      change('concentration_per_login_form', 'tems_tree', ['*,'])
    );
    this.props.dispatch(
      change('concentration_per_login_form', 'securities_tree', ['*,'])
    );
    this.props.dispatch(
      change('concentration_per_login_form', 'symbols_tree', ['*,'])
    );
  };

  updateConcentrationPerLogin = () => {
    this.showLoader();
    const len = Object.keys(this.rowsToUpdate).length;
    let i = 0;
    let j = 0;
    const successItems = [];
    const failItems = [];
    const errorItems = [];
    const rowData = _.keyBy(this.state.rowOldData, 'id');
    Object.values(this.rowsToUpdate).forEach((values) => {
      const oldRow = rowData[values.id];
      const dataToUpdate = {
        id: parseInt(values.id, 10),
        takers: values.takers,
        tems: values.tems,
        securities: values.securities,
        symbols: values.symbols,
        sides: parseInt(values.sides, 10),
        ord_types: parseInt(values.ord_types, 10),
        source_extlogin: parseInt(values.source_extlogin, 10),
        source_extgroup: values.source_extgroup,
        limit: parseFloat(values.limit),
        adjustment_value: parseFloat(values.adjustment_value),
        priority: parseInt(values.priority, 10),
        description: values.description,
        state: parseInt(values.state, 10),
        old_data: {
          id: parseInt(oldRow.id, 10),
          takers: oldRow.takers,
          tems: oldRow.tems,
          securities: oldRow.securities,
          symbols: oldRow.symbols,
          sides: parseInt(oldRow.sides, 10),
          ord_types: parseInt(oldRow.ord_types, 10),
          source_extlogin: parseInt(oldRow.source_extlogin, 10),
          source_extgroup: oldRow.source_extgroup,
          limit: parseFloat(oldRow.limit),
          adjustment_value: parseFloat(oldRow.adjustment_value),
          priority: parseInt(oldRow.priority, 10),
          description: oldRow.description,
          state: parseInt(oldRow.state, 10)
        }
      };
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}/risk/concentration_per_login`,
          JSON.stringify(dataToUpdate)
        )
        .then((response) => {
          i += 1;
          if (response.data.status) {
            successItems.push(values.id);
          } else {
            failItems.push(values.id);
          }
          if (len === i) {
            if (successItems.length > 0) {
              showNotification(
                this.props.literals.forms.concentration_per_login_form
                  .title_update,
                'primary',
                `${
                  this.props.literals.forms.success_message
                } - ${successItems.join(', ')}`
              );
            }
            if (failItems.length > 0) {
              showNotification(
                this.props.literals.forms.concentration_per_login_form
                  .title_update,
                'danger',
                `${this.props.literals.forms.fail_message} - ${failItems.join(
                  ', '
                )}`
              );
            }
            this.resetLocalConcentrationPerLogin();
            this.resetConcentrationPerLogin();
          }
        })
        .catch((error) => {
          j += 1;
          errorItems.push(values.id);
          if (len === j) {
            if (errorItems.length > 0) {
              showNotification(
                this.props.literals.forms.concentration_per_login_form
                  .title_update,
                'danger',
                `${error.message} - ${errorItems.join(', ')}`
              );
            }
            this.resetLocalConcentrationPerLogin();
            this.resetConcentrationPerLogin();
          }
        });
    });
  };

  resetLocalConcentrationPerLogin = () => {
    this.rowsToUpdate = {};
    this.setState({ cellEditUpdate: false });
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate).forEach((keys) => {
      const node = this.gridApi.getRowNode(keys);
      if (node) {
        this.gridApi.refreshCells({
          rowNodes: [node],
          columns: ['id'],
          force: true
        });
        Object.keys(stylesToUpdate[keys]).forEach((values) => {
          this.gridApi.refreshCells({
            rowNodes: [node],
            columns: [values],
            force: true
          });
        });
      }
    });
  };

  resetConcentrationPerLogin = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/risk/concentration_per_login`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({
          rowOldData: JSON.parse(
            JSON.stringify(response.data.ConcentrationPerLogin)
          )
        });
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  handleValuesChange = (values) => {
    this.setState({ values });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="tabs tabs--bordered-top tabs__custom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1'
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {literals.sidebar.concentration_per_login}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '2'
                      })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {literals.forms.concentration_per_login_form.title_create}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="outer-grid-label">
                      {literals.tables.ag_table.total_records}:{' '}
                      {this.state.rowData ? this.state.rowData.length : 0}
                    </div>
                    <div className="outer-grid-button">
                      <ButtonToolbar>
                        <Tooltip title={literals.tables.ag_table.save}>
                          <Button
                            style={{
                              display: this.state.cellEditUpdate ? '' : 'none'
                            }}
                            onClick={this.updateConcentrationPerLogin}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-save" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.undo}>
                          <Button
                            style={{
                              display: this.state.cellEditUpdate ? '' : 'none'
                            }}
                            onClick={() => {
                              this.showLoader();
                              this.resetLocalConcentrationPerLogin();
                              this.resetConcentrationPerLogin();
                            }}
                            className="icon"
                            color="primary"
                          >
                            <p className="fas fa-undo" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.export_excel}>
                          <Button
                            onClick={this.onBtExportExcel}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-file-excel" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.export_csv}>
                          <Button
                            onClick={this.onBtExportCSV}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-file-alt" />
                          </Button>
                        </Tooltip>
                      </ButtonToolbar>
                    </div>
                    <div
                      id={this.gridWrapperID}
                      style={{ width: '100%', height: '100%', clear: 'both' }}
                    >
                      <div
                        id={this.myGridID}
                        style={{
                          boxSizing: 'border-box',
                          height: this.state.height - REDUCE_HEIGHT,
                          width: '100%'
                        }}
                        className={
                          theme === 'theme-light'
                            ? 'ag-theme-balham'
                            : 'ag-theme-balham-dark'
                        }
                      >
                        <AgGridReact
                          modules={this.state.modules}
                          columnDefs={this.state.columnDefs}
                          stopEditingWhenCellsLoseFocus
                          suppressCellSelection
                          suppressContextMenu
                          immutableData
                          getRowNodeId={this.state.getRowNodeId}
                          defaultColDef={this.state.defaultColDef}
                          onColumnResized={this.onColumnResized}
                          onCellValueChanged={this.onCellValueChanged}
                          rowData={this.state.rowData}
                          frameworkComponents={this.state.frameworkComponents}
                          onGridReady={this.onGridReady}
                          onGridSizeChanged={this.onGridSizeChanged}
                          rowHeight={20}
                          context={{
                            formData: this.state.formData,
                            theme: this.props.theme,
                            resetConcentrationPerLogin:
                              this.resetConcentrationPerLogin,
                            showLoader: this.showLoader,
                            hideLoader: this.hideLoader
                          }}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <ConcentrationPerLoginForm
                      {...this.props}
                      state={{
                        formData: this.state.formData,
                        resetConcentrationPerLogin:
                          this.resetConcentrationPerLogin,
                        toggle: this.toggle,
                        showLoader: this.showLoader,
                        hideLoader: this.hideLoader
                      }}
                      onSubmit={takerAPILinkAction}
                      valuesData={this.state.values}
                      onChangeValues={this.handleValuesChange}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(ConcentrationPerLogin);
