/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import NotificationSystem from 'rc-notification';
import HeartIcon from 'mdi-react/AddIcon';
import nodeValidate from './validate';
import renderSelectField from '../../shared/components/form/SelectBox';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import accountAction from './accountAction';
import AccountForm from './accountForm';
import { BasicNotification } from '../../shared/components/Notification';

let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};
const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class NodeForm extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      change: PropTypes.func.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
      type: PropTypes.instanceOf(Object),
      name: PropTypes.string,
    };
    static defaultProps = {
      type: {},
      name: '',
    };
    constructor(props) {
      super(props);
      this.state = {
        riskAccountShow: false,
      };
    }

    toggleRiskAccount = () => {
      this.setState({ riskAccountShow: !this.state.riskAccountShow });
    };

    createRiskAccount = () => {
      let errors = '';
      const spaceRegExp = /^\S*$/;
      const atTheRateRegExp = /^[^@]*$/;
      if (!this.props.name) {
        errors = 'Name field shouldn’t be empty';
      }
      if (!spaceRegExp.test(this.props.name)) {
        errors = 'Name field shouldn’t contain spaces';
      }
      if (!atTheRateRegExp.test(this.props.name)) {
        errors = 'Name field shouldn’t contain @';
      }
      if (this.props.name && this.props.name.length > 32) {
        errors = 'Name field max length is 32 characters';
      }
      if (errors) {
        showNotification(
          this.props.literals.forms.node_form.title_create, 'danger',
          errors,
        );
      } else {
        this.toggleRiskAccount();
      }
    };

    selectRiskAccount = (ra) => {
      this.props.change('risk_account', ra);
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals, theme,
      } = this.props;
      const riskAccountValues = this.props.state.riskAccount;
      let typeValues = [];
      if (this.props.state) {
        typeValues = this.props.state.formData.Type;
      } else {
        typeValues = JSON.parse(JSON.parse(localStorage.getItem('persist:form')).node_form).values.formData.Type;
      }
      const disabled = false;
      const { submit } = literals.forms.node_form;

      return (
        <div>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.forms.node_form.name}</span>
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component={renderField}
                    type="text"
                    placeholder={literals.forms.node_form.name}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.forms.node_form.type}</span>
                <div className="form__form-group-field">
                  <Field
                    name="type"
                    component={renderSelectField}
                    type="text"
                    options={typeValues}
                    disabled={disabled}
                    onChange={this.onChangeType}
                  />
                </div>
              </div>
              {this.props.type.value === 0 &&
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                >{literals.forms.balance_transaction_form.risk_account}
                </span>
                <div className="form__form-group-field">
                  <button
                    className="product-card__wish-btn"
                    type="button"
                    onClick={() => this.createRiskAccount()}
                  >
                    <HeartIcon />{literals.forms.node_form.create_new_risk_account}
                  </button>
                </div>
                <span className="form__form-group-label" />
                <div className="form__form-group-field">
                  <Field
                    name="risk_account"
                    component={renderSelectField}
                    type="text"
                    options={riskAccountValues}
                    disabled={disabled}
                  />
                </div>
              </div>
              }
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.forms.node_form.ip_list}</span>
                <div className="form__form-group-field">
                  <Field
                    name="ip_list"
                    component="textarea"
                    type="text"
                    placeholder={literals.forms.node_form.ip_list}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.forms.node_form.state}</span>
                <div className="form__form-group-field">
                  <Field
                    name="state"
                    component={renderCheckBoxField}
                    label={literals.forms.node_form.state}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{literals.forms.node_form.description}</span>
                <div className="form__form-group-field">
                  <Field
                    name="description"
                    component="textarea"
                    type="text"
                    placeholder={literals.forms.node_form.description}
                    disabled={disabled}
                  />
                </div>
              </div>

              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">{submit}</Button>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => this.props.state.toggle('1')}
                >{literals.forms.node_form.cancel}
                </Button>
                <Button type="button" onClick={reset} disabled={pristine || submitting}>
                  {literals.forms.node_form.reset}
                </Button>
              </ButtonToolbar>
            </div>
          </form>
          <Modal
            isOpen={this.state.riskAccountShow}
            toggle={() => this.toggleRiskAccount}
            className={`modal-dialog--primary modal-dialog--header crp-modal ${theme.className}`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleRiskAccount()} />
              <h4 className="bold-text  modal__title">{literals.forms.balance_transaction_form.risk_account}</h4>
            </div>
            <div className="modal__body risk_account_modal_body">
              <AccountForm
                state={{
                      formData: this.props.state.formData,
                      addRiskAccount: this.props.state.addRiskAccount,
                      selectRiskAccount: this.selectRiskAccount,
                      taker_name: this.props.name,
                      toggleRiskAccount: this.toggleRiskAccount,
                      rootStore: this.props.rootStore,
                      literals: this.props.literals,
                      showLoader: this.props.state.showLoader,
                      hideLoader: this.props.state.hideLoader,
                    }}
                onSubmit={accountAction}
              />
            </div>
            <div className="modal__footer" />
          </Modal>
        </div>
      );
    }
}

const selector = formValueSelector('node_form');
function mapStateToProps(state) {
  return {
    theme: state.theme,
    literals: state.literals,
    type: selector(state, 'type'),
    name: selector(state, 'name'),
    risk_account: selector(state, 'risk_account'),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'node_form', // a unique identifier for this form
  validate: nodeValidate,
})(NodeForm));
