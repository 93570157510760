/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Button, ButtonToolbar, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { bindActionCreators } from 'redux';
import balanceTransactionValidate from './validate';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderInputNumberField from './NumberInputBT';
import balanceTransactionAction from './action';

const renderField = ({
  input, placeholder, type, disabled, step, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class BalanceTransactionForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
      type: PropTypes.instanceOf(Object),
      change: PropTypes.func.isRequired,
    };
     static defaultProps = {
       type: {},
     };
     constructor(props) {
       super(props);
       this.state = {
         popoverOpen: false,
       };
     }

    onTypeChange=(t) => {
      if (t.value === 0) {
        this.props.change('direction', { label: 'IN', value: 0 });
      } else if (t.value === 1) {
        this.props.change('direction', { label: 'OUT', value: 1 });
      }
    };

    togglePopover = () => {
      this.setState({
        popoverOpen: !this.state.popoverOpen,
      });
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals, theme,
      } = this.props;
      const riskAccountValues = this.props.state.formData.RiskAccount;
      let typeValues = this.props.state.formData.TypeBroker;
      const directionValues = this.props.state.formData.Direction;
      const disabled = false;
      const { submit } = literals.forms.balance_transaction_form;
      const { role } = this.props.rootStore.authStore;
      if (role === 'admin') {
        typeValues = this.props.state.formData.Type;
      }
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.balance_transaction_form.risk_account}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="risk_account"
                  component={renderSelectField}
                  type="text"
                  options={riskAccountValues}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.balance_transaction_form.type}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="type"
                  component={renderSelectField}
                  type="text"
                  options={typeValues}
                  disabled={disabled}
                  onChange={this.onTypeChange}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.balance_transaction_form.direction}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="direction"
                  component={renderSelectField}
                  type="text"
                  options={directionValues}
                  disabled={this.props.type && (this.props.type.value === 0 || this.props.type.value === 1)}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.balance_transaction_form.amount}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="amount"
                  component={renderInputNumberField}
                  min={0}
                  placeholder={literals.forms.balance_transaction_form.amount}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group" style={{ display: role === 'admin' ? '' : 'none' }}>
              <span
                className="form__form-group-label"
              >{literals.forms.balance_transaction_form.open_cen_order}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="open_cen_order"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.balance_transaction_form.open_cen_order}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group" style={{ display: role === 'admin' ? '' : 'none' }}>
              <span
                className="form__form-group-label"
              >{literals.forms.balance_transaction_form.close_cen_order}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="close_cen_order"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.balance_transaction_form.close_cen_order}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group" style={{ display: role === 'admin' ? '' : 'none' }}>
              <span
                className="form__form-group-label"
              >{literals.forms.balance_transaction_form.cen_volume}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="cen_volume"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.balance_transaction_form.cen_volume}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.balance_transaction_form.comment}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="comment"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.balance_transaction_form.comment}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button
                ref={(c) => {
                  this.PopoverTop = c && ReactDOM.findDOMNode(c);
                }}
                color="primary"
                type="button"
                onClick={this.togglePopover}
              >{submit}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.balance_transaction_form.reset}
              </Button>
              <Popover
                placement="top"
                isOpen={this.state.popoverOpen}
                target={() => this.PopoverTop}
                toggle={this.togglePopover}
                className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
              >
                <PopoverHeader>{literals.forms.balance_transaction_form.confirm_transaction}</PopoverHeader>
                <PopoverBody>
                  <span>{literals.forms.balance_transaction_form.confirm_transaction_note}
                  </span>
                  <ButtonToolbar className="btn-toolbar--center">
                    <Button className="btn btn-blue" onClick={this.togglePopover}>
                      {literals.forms.cancel}
                    </Button>
                    <Button
                      className="btn btn-blue"
                      onClick={handleSubmit(values => balanceTransactionAction({
                        ...values,
                        togglePopover: this.togglePopover,
                        literals,
                        rootStore: this.props.rootStore,
                        showLoader: this.props.state.showLoader,
                        hideLoader: this.props.state.hideLoader,
                      }))}
                    >
                      {literals.forms.confirm}
                    </Button>
                  </ButtonToolbar>
                </PopoverBody>
              </Popover>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('balance_transaction_form');
function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    type: selector(state, 'type'),
    direction: selector(state, 'direction'),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'balance_transaction_form', // a unique identifier for this form
  validate: balanceTransactionValidate,
})(BalanceTransactionForm));
