/* eslint-disable no-return-assign */
/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonToolbar, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip,
} from 'react-tippy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import { inject } from 'mobx-react';
import ReactDOM from 'react-dom';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import OTForm from './formOT';
import OTAction from './OTAction';

@inject('rootStore')
class OTActionRenderer extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  constructor(props) {
    super(props);
    let notification = null;
    NotificationSystem.newInstance({}, n => notification = n);
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: <BasicNotification
          title={title}
          message={message}
          color={color}
        />,
        duration: 3,
        closable: true,
        style: { top: 0 },
        className: 'left-up',
      });
    };
  }
  state = {
    popoverOpen: false,
  };

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <span>
        <ButtonToolbar>
          <Tooltip title={literals.tables.ag_table.close}>
            <Button
              style={{ height: 14 }}
              id="OpenTradeCloseClick"
              onClick={this.toggle}
              className="icon"
              color="primary"
              ref={(c) => {
                  this.target = c && ReactDOM.findDOMNode(c);
                }}
            ><p
              style={{ lineHeight: 0 }}
              className="fas fa-times"
            />
            </Button>
          </Tooltip>
        </ButtonToolbar>
        <Popover
          placement="bottom-end"
          isOpen={this.state.popoverOpen}
          target={() => this.target}
          toggle={this.toggle}
          className={theme === 'theme-light'
              ? 'theme-light trading-light' : 'theme-dark trading-dark'}
        >
          <PopoverHeader>{this.props.literals.tables.open_trade_table.title_close_open_trade}</PopoverHeader>
          <PopoverBody>
            <OTForm
              state={{
                  toggle: this.toggle,
                  node: this.props.node,
                  parent: this.props.api,
                  client_name: this.props.rootStore.authStore.user.client_name,
                }}
              onSubmit={OTAction}
            />
          </PopoverBody>
        </Popover>
      </span>

    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(withRouter(OTActionRenderer));
