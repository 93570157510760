import {
  LOAD_LITERALS_EN,
  LOAD_LITERALS_CN,
  LOAD_LITERALS_JA,
  LOAD_LITERALS_HI,
} from '../actions/literalActions';
import loadLang from '../../translations/index';

const lang = loadLang();

const defaultState = lang;

const LiteralReducer = (state = defaultState, { type }) => {
  switch (type) {
    case LOAD_LITERALS_EN:
      return loadLang('en');
    case LOAD_LITERALS_CN:
      return loadLang('cn');
    case LOAD_LITERALS_JA:
      return loadLang('ja');
    case LOAD_LITERALS_HI:
      return loadLang('hi');
    default:
      return state;
  }
};

export default LiteralReducer;
