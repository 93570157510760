/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import GoldenLayout from 'golden-layout';
import 'golden-layout/src/css/goldenlayout-base.css';
import $ from 'jquery';
import { inject } from 'mobx-react';
import CustomCss from '../multi_window/customCss';

@inject('rootStore')
class GoldenLayoutComponent extends PureComponent {
  state = {};

  componentDidMount() {
    $('.container__wrap').removeClass('lm_not');
    const savedState = JSON.parse(localStorage.getItem('makerStatusLayout'));
    this.goldenLayoutInstance = new GoldenLayout(
      savedState || this.props.config || {},
      this.containerRef.current,
    );
    this.goldenLayoutInstanceInit();
  }

  componentWillUnmount() {
    $('.container__wrap').addClass('lm_not');
    this.goldenLayoutInstance.destroy();
  }

  goldenLayoutInstance = undefined;
  containerRef = React.createRef();

  goldenLayoutInstanceInit = () => {
    this.goldenLayoutInstance._isFullPage = true;
    this.goldenLayoutInstance.on('stateChanged', () => {
      const state = JSON.stringify(this.goldenLayoutInstance.toConfig());
      localStorage.setItem('makerStatusLayout', state);
    });

    const containerCSS = (container) => {
      if (container.width > 480) { // min-width 480px
        container.getElement().addClass('lm_form--horizontal');
      } else {
        container.getElement().removeClass('lm_form--horizontal');
      }
      if (container.width < 1200) { // max-width 1200px
        container.getElement().addClass('lm_crp-select');
        container.getElement().addClass('lm_monitoring-tree');
        container.getElement().addClass('lm_balance_transaction_form');
      } else {
        container.getElement().removeClass('lm_crp-select');
        container.getElement().removeClass('lm_monitoring-tree');
        container.getElement().removeClass('lm_balance_transaction_form');
      }
      if (container.width < 767) { // max-width 767px
        container.getElement().addClass('lm_form__half');
        container.getElement().addClass('lm_form__form-group-date-cvc');
        container.getElement().addClass('lm_form__form-group-id-category');
        container.getElement().addClass('lm_form__form-group-price-discount');
        container.getElement().addClass('lm_depth');
        container.getElement().addClass('lm_depth-trading');
        container.getElement().addClass('lm_depth-order');
        container.getElement().addClass('lm_depth-monitoring-log');
      } else {
        container.getElement().removeClass('lm_form__half');
        container.getElement().removeClass('lm_form__form-group-date-cvc');
        container.getElement().removeClass('lm_form__form-group-id-category');
        container.getElement().removeClass('lm_form__form-group-price-discount');
        container.getElement().removeClass('lm_depth');
        container.getElement().removeClass('lm_depth-trading');
        container.getElement().removeClass('lm_depth-order');
        container.getElement().removeClass('lm_depth-monitoring-log');
      }
      if (container.width > 768 && container.width < 1023) { // min-width 768px and max-width 1023px
        container.getElement().addClass('lm_depth-trading');
        container.getElement().addClass('lm_depth-order');
        container.getElement().addClass('lm_depth-monitoring-log');
      } else {
        container.getElement().removeClass('lm_depth-trading');
        container.getElement().removeClass('lm_depth-order');
        container.getElement().removeClass('lm_depth-monitoring-log');
      }
      if (container.width === 1024) { // width 1024px
        container.getElement().addClass('lm_depth-trading');
        container.getElement().addClass('lm_depth-order');
        container.getElement().addClass('lm_depth-monitoring-log');
      } else {
        container.getElement().removeClass('lm_depth-trading');
        container.getElement().removeClass('lm_depth-order');
        container.getElement().removeClass('lm_depth-monitoring-log');
      }
    };

    this.goldenLayoutInstance.on('componentCreated', (c) => {
      containerCSS(c.container);
      c.container.on('resize', () => {
        containerCSS(c.container);
      });
    });

    if (this.props.registerComponents instanceof Function) {
      this.props.registerComponents(this.goldenLayoutInstance);
    }
    this.goldenLayoutInstance.reactContainer = this;
    this.goldenLayoutInstance.init();
  };

  componentRender(reactComponentHandler) {
    this.setState((state) => {
      const newRenderPanels = new Set(state.renderPanels);
      newRenderPanels.add(reactComponentHandler);
      return { renderPanels: newRenderPanels };
    });
  }

  componentDestroy(reactComponentHandler) {
    this.setState((state) => {
      const newRenderPanels = new Set(state.renderPanels);
      newRenderPanels.delete(reactComponentHandler);
      return { renderPanels: newRenderPanels };
    });
  }

  render() {
    window.dispatchEvent(new Event('resize'));
    const panels = Array.from(this.state.renderPanels || []);
    return (
      <div ref={this.containerRef} {...this.props.htmlAttrs}>
        <CustomCss />
        {panels.map(panel => ReactDOM.createPortal(
          panel._getReactComponent(),
          panel._container.getElement()[0],
        ))}
      </div>
    );
  }
}

export default GoldenLayoutComponent;


// Patching internal GoldenLayout.__lm.utils.ReactComponentHandler:

const { ReactComponentHandler } = GoldenLayout.__lm.utils;

class ReactComponentHandlerPatched extends ReactComponentHandler {
  _render() {
    const { reactContainer } = this._container.layoutManager; // Instance of GoldenLayoutComponent class
    if (reactContainer && reactContainer.componentRender) {
      reactContainer.componentRender(this);
    }
  }

  _destroy() {
    const { reactContainer } = this._container.layoutManager;
    if (reactContainer && reactContainer.componentDestroy) {
      reactContainer.componentDestroy(this);
    }
    this._container.off('open', this._render, this);
    this._container.off('destroy', this._destroy, this);
  }

  _getReactComponent() {
    // the following method is absolute copy of the original, provided to prevent depenency on window.React
    const defaultProps = {
      glEventHub: this._container.layoutManager.eventHub,
      glContainer: this._container,
    };
    const props = $.extend(defaultProps, this._container._config.props);
    return React.createElement(this._reactClass, props);
  }
}

GoldenLayout.__lm.utils.ReactComponentHandler = ReactComponentHandlerPatched;
