/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import Websocket from 'react-websocket';
import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import { BasicNotification } from '../../../shared/components/Notification';
import { LiteralProps } from '../../../shared/prop-types/ReducerProps';

let notificationUrgencyHeadlineNews = null;

@inject('rootStore')
class TopbarNotification extends PureComponent {
  static propTypes = {
    rootStore: PropTypes.instanceOf(Object).isRequired,
    literals: LiteralProps.isRequired,
  }
  state = {
    collapse: false,
    newIcon: '',
    notifications: [],
  };

  componentDidMount() {
    NotificationSystem.newInstance({}, n => notificationUrgencyHeadlineNews = n);
  }

  componentWillUnmount() {
    notificationUrgencyHeadlineNews.destroy();
  }

  showNotificationUrgencyHeadlineNews = (title, color, message) => {
    notificationUrgencyHeadlineNews.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: null,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
  };

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
    this.setState({ newIcon: '' });
  };

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    const { role } = this.props.rootStore.authStore;
    if (role === 'admin' || role === 'broker') {
      if ('urgency_headline_news' in data) {
        this.setState({ newIcon: 'topbar__btn--new' });
        const titleHead = 'Alerts';
        let backgroundColor = '';
        if (data.urgency_headline_news.urgency === '1') { // Urgency_FLASH
          backgroundColor = '#ff4861'; // danger
          this.showNotificationUrgencyHeadlineNews(
            `${titleHead} - ${data.urgency_headline_news.urgency_value}`,
            'danger', `[${data.urgency_headline_news.date}] ${data.urgency_headline_news.headline}`,
          );
        } else if (data.urgency_headline_news.urgency === '2') { // Urgency_BACKGROUND
          backgroundColor = '#a5ee6e'; // green
        } else if (data.urgency_headline_news.urgency === '0') { // Urgency_NORMAL
          backgroundColor = '#FFA500'; // orange
        }
        const notification = {
          name: `${titleHead} - ${data.urgency_headline_news.urgency_value}`,
          message: data.urgency_headline_news.headline,
          date: data.urgency_headline_news.date,
          backgroundColor,
        };
        this.setState(prevState => ({ notifications: [notification, ...prevState.notifications] }));
      }
    }
  };
  socketOpenListener = () => {
    const { role } = this.props.rootStore.authStore;
    if (role === 'admin' || role === 'broker') {
      this.sendMessage(JSON.stringify({
        key: 'urgency_headline_news',
        value: [],
      }));
    }
  };
  sendMessage=(message) => {
    this.refWebSocket.sendMessage(message);
  };

  render() {
    const { collapse, newIcon } = this.state;
    return (
      <div className="topbar__collapse topbar__collapse--notification">
        <button className={`topbar__btn ${newIcon}`} type="button" onClick={this.toggle}>
          <NotificationsIcon />
          <div className="topbar__btn-new-label">
            <div />
          </div>
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content"
        >
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">{this.props.literals.topbar.notifications}</p>
          </div>
          {this.state.notifications.map((notification, index) => (
            <div className="topbar__collapse-item" key={index}>
              <div style={{ width: '95%', position: 'absolute', left: 15 }}>
                <p className="topbar__collapse-name">
                  <span style={{ backgroundColor: notification.backgroundColor, color: 'black' }}>
                    {notification.name}
                  </span>
                </p>
                <p className="topbar__collapse-message topbar__collapse-message--mail">{notification.message}</p>
                <p className="topbar__collapse-date">{notification.date}</p>
              </div>
            </div>
          ))}
        </Collapse>
        <Websocket
          url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
          reconnectIntervalInMilliSeconds={1000}
          onMessage={this.socketMessageListener}
          onOpen={this.socketOpenListener}
          ref={(WS) => {
            this.refWebSocket = WS;
          }}
        />
      </div>
    );
  }
}

export default (connect(state => ({
  literals: state.literals,
}))(TopbarNotification));
