/* eslint-disable no-param-reassign */
import mem from 'mem/dist';
import axios from 'axios';
import storage from 'redux-persist/lib/storage';

const refreshTokenFn = async (props) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/refresh_token`,
      { headers: { 'x-forward-client': props.rootStore.clientStore.CC } },
    );
    if (response.data.code === 200) {
      props.rootStore.authStore.isAuthenticated = true;
      props.rootStore.authStore.expire = response.data.expire;
      props.rootStore.authStore.token = response.data.token;
    }

    return { accessToken: response.data.token };
  } catch (error) {
    axios.get(`${process.env.REACT_APP_FRONTEND_API}/logout`)
      .then(() => {
        props.rootStore.reset();
        storage.removeItem('persist:form');
        localStorage.clear();
        window.location.reload();
      })
      .catch(() => {
      });
  }
  return null;
};

const maxAge = 10000;

const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
export default memoizedRefreshToken;
