const accountGroupValidate = (values, props) => {
  const errors = {};
  if (!values.symbol_val) {
    errors.symbol_val =
        `${props.literals.forms.asset_class_symbol_form.name} ${props.literals.forms.required_validate}`;
  }
  if (values.symbol_val && values.symbol_val.length > 32) {
    errors.symbol_val = `${props.literals.forms.asset_class_symbol_form.name} ${props.literals.forms.max_32_validate}`;
  }
  if (!values.asset_class) {
    errors.asset_class = `${props.literals.forms.asset_class_form.name} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default accountGroupValidate;
