/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Modal, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Field, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import actionSchedulerValidate from './validate';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderTreeSelectField from './TreeSelect';
import renderInputNumberField from '../../shared/components/form/NumberInputCommon';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import SessionEditForm from '../../shared/components/session/SessionEditForm';
import SessionEdit from '../../shared/components/session/SessionEdit';
import { ACTION_SCHEDULER_ADD_GROUPS } from '../../redux/actions/actionTypes';
import MMForm from './MMForm';
import HAMForm from './HAMForm';
import TFForm from './TFForm';
import TEMForm from './TEMForm';
import renderMultiDatePicker from '../../shared/components/form/MultiDatePicker';
import { BasicNotification } from '../../shared/components/Notification';

const renderField = ({
  input, placeholder, type, disabled, step, min, max, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    {type === 'textarea' &&
    <textarea {...input} placeholder={placeholder} disabled={disabled} />
      }
    {type !== 'textarea' &&
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} min={min} max={max} />
      }
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class ActionSchedulerForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      symbols_pattern: PropTypes.bool,
      groups_pattern: PropTypes.bool,
      securities_pattern: PropTypes.bool,
      sessions: PropTypes.string,
      change: PropTypes.func.isRequired,
      action_scheduler_groups: PropTypes.instanceOf(Array),
      dispatch: PropTypes.func.isRequired,
      type: PropTypes.instanceOf(Object),
      inSessionRevertOld: PropTypes.bool,
      outSessionRevertOld: PropTypes.bool,
      search_values: PropTypes.instanceOf(Object),
    };
    static defaultProps = {
      symbols_pattern: false,
      groups_pattern: false,
      securities_pattern: false,
      sessions: '',
      action_scheduler_groups: [],
      type: {},
      inSessionRevertOld: false,
      outSessionRevertOld: false,
      search_values: {},
    }
    constructor(props) {
      super(props);
      this.state = {
        sessionModalShow: false,
        selectedOption: 'session_interface',
        popoverOpen: false,
      };
      let notification = null;
      NotificationSystem.newInstance({}, n => notification = n);
      this.showNotification = (title, color, message) => {
        notification.notice({
          content: <BasicNotification
            title={title}
            message={message}
            color={color}
          />,
          duration: 5,
          closable: true,
          style: { top: 0, left: 'calc(100vw - 100%)' },
          className: 'right-up',
        });
      };
    }
    onGroupsChange=(groups) => {
      if (groups && (groups?.[0] === '*,' || groups?.target?.value === '*,')) {
        const { literals } = this.props;
        this.showNotification(
          `${literals.sidebar.action_scheduler} ${literals.forms.alert}`, 'orange',
          literals.forms.action_scheduler_form.groups_alert_note,
        );
      }
    }
    toggleModalSession = () => {
      this.setState({ sessionModalShow: !this.state.sessionModalShow });
    };
    submitSession=(session) => {
      this.props.change('sessions', session);
      this.toggleModalSession();
    };
    resetSession=() => {
      this.toggleModalSession();
    };
    handleChange = (selectedOption) => {
      this.setState({ selectedOption: selectedOption.target.value });
    };
    handleTypeChange = (type) => {
      let groups = [];
      if (type.value === 1) {
        groups = this.props.state.formData.MM;
      } else if (type.value === 2) {
        groups = this.props.state.formData.HAM;
      } else if (type.value === 3) {
        groups = this.props.state.formData.TF;
      } else if (type.value === 4) {
        groups = this.props.state.formData.TEM;
      }
      this.props.dispatch({ type: ACTION_SCHEDULER_ADD_GROUPS, payload: { groups } });
    }
    togglePopover = () => {
      this.setState({
        popoverOpen: !this.state.popoverOpen,
      });
    };
    showGroupAlert=() => {
      const values = this.props.search_values;
      if (values?.groups_pattern && (values?.groups_text === undefined || values?.groups_text === '*,')) {
        return true;
      } else if (!values?.groups_pattern && (values?.groups_tree === undefined ||
          values?.groups_tree?.[0] === undefined || values?.groups_tree?.[0] === '*,')) {
        return true;
      }
      return false;
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals, theme,
      } = this.props;
      const disabled = false;
      const { submit } = literals.forms.action_scheduler_form;
      const { selectedOption } = this.state;
      const type = this.props.type.value;

      let button;
      if (this.showGroupAlert()) {
        button = (
          <span>
            <Button
              id="PopoverTop"
              color="primary"
              type="button"
              onClick={this.togglePopover}
            >{submit}
            </Button>
            <Popover
              placement="top"
              isOpen={this.state.popoverOpen}
              target="PopoverTop"
              toggle={this.togglePopover}
              className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
            >
              <PopoverHeader>
                {`${literals.forms.confirm} ${literals.sidebar.action_scheduler}`}
              </PopoverHeader>
              <PopoverBody>
                <span>
                  {`${literals.forms.action_scheduler_form.groups_alert_note}. \n` +
                  `${literals.forms.confirm_create_note} ${literals.sidebar.action_scheduler} ?`}
                </span>
                <ButtonToolbar className="btn-toolbar--center">
                  <Button className="btn btn-blue" onClick={this.togglePopover}>
                    {literals.forms.cancel}
                  </Button>
                  <Button color="primary" onClick={() => { this.togglePopover(); handleSubmit(); }}>{submit}</Button>
                </ButtonToolbar>
              </PopoverBody>
            </Popover>
          </span>);
      } else {
        button = <Button color="primary" type="submit">{submit}</Button>;
      }

      return (
      <>
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.id}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="id"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  precision={0}
                  placeholder={literals.forms.action_scheduler_form.id}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.type}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="type"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.Type}
                  placeholder={literals.forms.action_scheduler_form.type}
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.groups}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="groups_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.groups_pattern &&
                <Field
                  name="groups_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.action_scheduler_groups}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                  onChange={this.onGroupsChange}
                />
            }
                {this.props.groups_pattern &&
                <Field
                  name="groups_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.action_scheduler_form.groups}
                  onChange={this.onGroupsChange}
                />
            }
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.symbols}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="symbols_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.symbols_pattern &&
                <Field
                  name="symbols_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Symbol}
                  treeDefaultExpandAll={false}
                  treeDefaultExpandedKeys={['*,']}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
            }
                {this.props.symbols_pattern &&
                <Field
                  name="symbols_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.action_scheduler_form.symbols}
                />
            }
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.securities}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="securities_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.securities_pattern &&
                <Field
                  name="securities_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Security}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
            }
                {this.props.securities_pattern &&
                <Field
                  name="securities_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.action_scheduler_form.securities}
                />
            }
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.action_scheduler_form.in_session_values}</span>
            </div>
            <div className={this.props.inSessionRevertOld ? 'disabled-ui' : ''}>
              {type === 1 &&
              <MMForm
                literals={literals}
                renderField={renderField}
                spreadExec={this.props.state.formData.SpreadExec}
                dir="in_session_"
              />
              }
              {type === 2 &&
              <HAMForm
                literals={literals}
                theme={theme}
                renderField={renderField}
                execMode={this.props.state.formData.ExecMode}
                lpSession={this.props.state.formData.LPSession}
                dir="in_session_"
              />
              }
              {type === 3 &&
              <TFForm
                literals={literals}
                hamSingle={this.props.state.formData.HAMSingle}
                mmSingle={this.props.state.formData.MMSingle}
                dir="in_session_"
              />
              }
              {type === 4 &&
              <TEMForm
                literals={literals}
                renderField={renderField}
                hamSingle={this.props.state.formData.HAMSingle}
                mmSingle={this.props.state.formData.MMSingle}
                llAction={this.props.state.formData.LLAction}
                dir="in_session_"
              />
              }
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.forms.action_scheduler_form.out_session_values}</span>
              <div className="form__form-group-field">
                <Field
                  name="out_session_revert_old"
                  component={renderCheckBoxField}
                  label={literals.forms.action_scheduler_form.session_revert_old}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={this.props.outSessionRevertOld ? 'disabled-ui' : ''}>
              {type === 1 &&
              <MMForm
                literals={literals}
                renderField={renderField}
                spreadExec={this.props.state.formData.SpreadExec}
                dir="out_session_"
              />
              }
              {type === 2 &&
              <HAMForm
                literals={literals}
                theme={theme}
                renderField={renderField}
                execMode={this.props.state.formData.ExecMode}
                lpSession={this.props.state.formData.LPSession}
                dir="out_session_"
              />
              }
              {type === 3 &&
              <TFForm
                literals={literals}
                hamSingle={this.props.state.formData.HAMSingle}
                mmSingle={this.props.state.formData.MMSingle}
                dir="out_session_"
              />
              }
              {type === 4 &&
              <TEMForm
                literals={literals}
                renderField={renderField}
                hamSingle={this.props.state.formData.HAMSingle}
                mmSingle={this.props.state.formData.MMSingle}
                llAction={this.props.state.formData.LLAction}
                dir="out_session_"
              />
              }
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.date_calendar}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="date_calendar"
                  component={renderMultiDatePicker}
                  theme={theme.className}
                  multiple
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.priority}
              </span>
              <div className="form__form-group-field">
                <span style={{ color: 'orange' }}>
                  {literals.forms.reverse_trade_copier_form.priority_note}
                </span>
                <Field
                  name="priority"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.Priority}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.action_scheduler_form.description}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.sessions} (
                <Button
                  style={{
                    marginBottom: 0,
                    padding: '0 10px',
                  }}
                  color="link"
                  onClick={this.toggleModalSession}
                >Edit
                </Button>)
              </span>
              <div className="form__form-group-field">
                <Field
                  name="sessions"
                  component={renderField}
                  type="textarea"
                  placeholder={literals.forms.action_scheduler_form.sessions}
                  disabled
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.action_scheduler_form.state}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="state"
                  component={renderCheckBoxField}
                  label={literals.forms.action_scheduler_form.state}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              {button}
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.action_scheduler_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.action_scheduler_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
        <Modal
          isOpen={this.state.sessionModalShow}
          toggle={() => this.toggleModalSession}
          className={`modal-dialog--primary modal-dialog--header crp-modal session-modal ${theme.className}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleModalSession()} />
            <h4 className="bold-text  modal__title">{literals.forms.action_scheduler_form.sessions}</h4>
          </div>
          <div className="modal__body risk_account_modal_body">
            <label
              className="radio-btn"
            >
              <input
                className="radio-btn__radio"
                type="radio"
                name="session_edit"
                value="session_interface"
                checked={selectedOption === 'session_interface'}
                onChange={this.handleChange}
              />
              <span className="radio-btn__radio-custom" />
              <span className="radio-btn__label">
                {literals.tables.ag_table.interface}
              </span>
            </label>
            <label
              className="radio-btn"
            >
              <input
                className="radio-btn__radio"
                type="radio"
                name="session_edit"
                value="session_text"
                checked={selectedOption === 'session_text'}
                onChange={this.handleChange}
              />
              <span className="radio-btn__radio-custom" />
              <span className="radio-btn__label">
                {literals.tables.ag_table.free_text}
              </span>
            </label>
            {this.state.selectedOption === 'session_text' &&
            <SessionEditForm
              sessions={this.props.sessions}
              initialSessionValue={this.props.sessions}
              submitSession={this.submitSession}
              resetSession={this.resetSession}
            />
      }
            {this.state.selectedOption === 'session_interface' &&
            <SessionEdit
              sessions={this.props.sessions}
              initialSessionValue={this.props.sessions}
              submitSession={this.submitSession}
              resetSession={this.resetSession}
            />
      }
          </div>
          <div className="modal__footer" />
        </Modal>
      </>
      );
    }
}

const selector = formValueSelector('action_scheduler_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    action_scheduler_groups: state.actionScheduler.groups,
    symbols_pattern: selector(state, 'symbols_pattern'),
    groups_pattern: selector(state, 'groups_pattern'),
    securities_pattern: selector(state, 'securities_pattern'),
    sessions: selector(state, 'sessions'),
    type: selector(state, 'type'),
    outSessionRevertOld: selector(state, 'out_session_revert_old'),
    initialValues: {
      id: 0,
      state: false,
      priority: { label: '1', value: '1' },
      type: { label: 'MM', value: 1 },
      symbols_text: '*,',
      securities_text: '*,',
      sessions: 'MON,00:00-23:59;TUE,00:00-23:59;WED,00:00-23:59;' +
          'THU,00:00-23:59;FRI,00:00-23:59;SAT,00:00-23:59;SUN,00:00-23:59;',
    },
    search_values: getFormValues('action_scheduler_form')(state),
  };
}


export default connect(mapStateToProps)(reduxForm({
  form: 'action_scheduler_form', // a unique identifier for this form
  validate: actionSchedulerValidate,
})(ActionSchedulerForm));
