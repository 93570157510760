/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import FileDownload from 'js-file-download';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import Loader from '../../shared/components/Loader';
import Dropzone from 'react-dropzone-uploader';
import UploadButton from '../../shared/components/UploadButton';
import ConfirmButton from '../../shared/components/ConfirmButton';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

@inject('rootStore')
class RAStatementTemplate extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      loaderShow: false
    };
  }
  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };
  getUploadParams = ({ file, meta }) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(
      `${process.env.REACT_APP_BACKEND_API}/broker/upload_ra_statement_template`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-forward-client': this.props.rootStore.clientStore.CC
        }
      }
    );
  };
  handleSubmit = (files, allFiles, template_type) => {
    this.showLoader();
    const formData = new FormData();
    formData.append('file', allFiles[0].file);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/broker/upload_ra_statement_template?template_type=${template_type}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-forward-client': this.props.rootStore.clientStore.CC
          }
        }
      )
      .then((response) => {
        if (response?.data?.status) {
          showNotification(
            this.props.literals.sidebar.branded_statement,
            'primary',
            this.props.literals.forms.completed_message,
            3
          );
        } else {
          showNotification(
            this.props.literals.sidebar.branded_statement,
            'danger',
            this.props.literals.forms.fail_message,
            3
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        this.hideLoader();
        allFiles.forEach((f) => f.remove());
      });
  };

  downloadFile = (type, template_type) => {
    this.showLoader();
    axios({
      url: `${
        process.env.REACT_APP_BACKEND_API
      }/broker/download_ra_statement_template?statement_type=${type}&template_type=${template_type}&timestamp=${new Date().getTime()}`,
      headers: { 'x-forward-client': this.props.rootStore.clientStore.CC },
      method: 'GET',
      responseType: 'blob' // important
    })
      .then((response) => {
        this.hideLoader();
        FileDownload(
          response.data,
          `ra_statement_${template_type}_${type}.html`
        );
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  testFile = (type, template_type) => {
    this.showLoader();
    axios({
      url: `${
        process.env.REACT_APP_BACKEND_API
      }/broker/test_ra_statement_template?statement_type=${type}&template_type=${template_type}&timestamp=${new Date().getTime()}`,
      headers: { 'x-forward-client': this.props.rootStore.clientStore.CC },
      method: 'GET',
      responseType: 'blob' // important
    })
      .then((response) => {
        this.hideLoader();
        FileDownload(
          response.data,
          `ra_statement_${template_type}_${type}_test.html`
        );
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  deleteFile = (template_type) => {
    this.showLoader();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/broker/delete_ra_statement_template?template_type=${template_type}`,
        {
          headers: {
            'x-forward-client': this.props.rootStore.clientStore.CC
          }
        }
      )
      .then((response) => {
        if (response?.data?.status) {
          showNotification(
            this.props.literals.sidebar.branded_statement,
            'primary',
            this.props.literals.forms.completed_message,
            3
          );
        } else {
          showNotification(
            this.props.literals.sidebar.branded_statement,
            'danger',
            this.props.literals.forms.fail_message,
            3
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        this.hideLoader();
      });
  };

  render() {
    const { literals, theme } = this.props;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">
                {`${literals.sidebar.branded_statement} - ${literals.tables.branded_statement_table.upload_branded_template}`}
              </h5>
            </div>
            <Dropzone
              onSubmit={(files, allFiles) =>
                this.handleSubmit(files, allFiles, 'default')
              }
              maxFiles={1}
              multiple={false}
              accept=".html"
              inputContent={(files, extra) =>
                extra.reject
                  ? literals.tables.branded_statement_table.html_only
                  : literals.forms.uni_symbol_form.drop_a_file
              }
              SubmitButtonComponent={(props) => (
                <UploadButton
                  {...props}
                  theme={theme}
                  literals={literals}
                  title={literals.sidebar.branded_statement}
                />
              )}
              styles={{
                dropzone: { maxHeight: 150, minHeight: 150, width: '100%' },
                dropzoneActive: { borderColor: 'green' },
                dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                inputLabel: (files, extra) =>
                  extra.reject ? { color: 'red' } : {}
              }}
            />
            <div style={{ marginTop: 30 }}>
              <ButtonToolbar
                className="form__button-toolbar"
                style={{
                  marginTop: 15,
                  marginBottom: 0
                }}
              >
                <Button
                  color="primary"
                  onClick={() => this.downloadFile('default', 'default')}
                >
                  {
                    literals.tables.branded_statement_table
                      .download_default_template
                  }
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.testFile('default', 'default')}
                >
                  {
                    literals.tables.branded_statement_table
                      .sample_data_default_template
                  }
                </Button>
              </ButtonToolbar>
              <ButtonToolbar
                className="form__button-toolbar"
                style={{
                  marginTop: 15,
                  marginBottom: 0
                }}
              >
                <Button
                  color="primary"
                  onClick={() => this.downloadFile('branded', 'default')}
                >
                  {
                    literals.tables.branded_statement_table
                      .download_branded_template
                  }
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.testFile('branded', 'default')}
                >
                  {
                    literals.tables.branded_statement_table
                      .sample_data_branded_template
                  }
                </Button>
                <ConfirmButton
                  onAction={() => this.deleteFile('default')}
                  theme={theme}
                  literals={literals}
                  mainButtonText={
                    literals.tables.branded_statement_table
                      .delete_branded_template
                  }
                  popupHeaderText={literals.forms.confirm_delete}
                  popupBodyText1={literals.forms.confirm_delete_note}
                  popupBodyText2={literals.sidebar.branded_statement}
                />
              </ButtonToolbar>
            </div>
            <div className="card__title">
              <h5 className="bold-text">
                {`${literals.sidebar.branded_statement} - ${literals.tables.branded_statement_table.upload_branded_email_template}`}
              </h5>
            </div>
            <Dropzone
              onSubmit={(files, allFiles) =>
                this.handleSubmit(files, allFiles, 'email')
              }
              maxFiles={1}
              multiple={false}
              accept=".html"
              inputContent={(files, extra) =>
                extra.reject
                  ? literals.tables.branded_statement_table.html_only
                  : literals.forms.uni_symbol_form.drop_a_file
              }
              SubmitButtonComponent={(props) => (
                <UploadButton
                  {...props}
                  theme={theme}
                  literals={literals}
                  title={
                    literals.tables.branded_statement_table
                      .branded_email_template
                  }
                />
              )}
              styles={{
                dropzone: { maxHeight: 150, minHeight: 150, width: '100%' },
                dropzoneActive: { borderColor: 'green' },
                dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                inputLabel: (files, extra) =>
                  extra.reject ? { color: 'red' } : {}
              }}
            />
            <div style={{ marginTop: 30 }}>
              <ButtonToolbar
                className="form__button-toolbar"
                style={{
                  marginTop: 15,
                  marginBottom: 0
                }}
              >
                <Button
                  color="primary"
                  onClick={() => this.downloadFile('default', 'email')}
                >
                  {
                    literals.tables.branded_statement_table
                      .download_default_email_template
                  }
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.testFile('default', 'email')}
                >
                  {
                    literals.tables.branded_statement_table
                      .sample_data_default_email_template
                  }
                </Button>
              </ButtonToolbar>
              <ButtonToolbar
                className="form__button-toolbar"
                style={{
                  marginTop: 15,
                  marginBottom: 0
                }}
              >
                <Button
                  color="primary"
                  onClick={() => this.downloadFile('branded', 'email')}
                >
                  {
                    literals.tables.branded_statement_table
                      .download_branded_email_template
                  }
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.testFile('branded', 'email')}
                >
                  {
                    literals.tables.branded_statement_table
                      .sample_data_branded_email_template
                  }
                </Button>
                <ConfirmButton
                  onAction={() => this.deleteFile('email')}
                  theme={theme}
                  literals={literals}
                  mainButtonText={
                    literals.tables.branded_statement_table
                      .delete_branded_email_template
                  }
                  popupHeaderText={literals.forms.confirm_delete}
                  popupBodyText1={literals.forms.confirm_delete_note}
                  popupBodyText2={
                    literals.tables.branded_statement_table
                      .branded_email_template
                  }
                />
              </ButtonToolbar>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withRouter(
  connect((state) => ({
    theme: state.theme,
    literals: state.literals
  }))(RAStatementTemplate)
);
