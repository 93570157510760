/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import _ from 'lodash';
import {
  LiteralENGlobalProps,
  LiteralProps
} from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import {
  regExDigits,
  regExSignedDecimalNumber,
  shallowEqual,
  validateSessions,
  parseSessions
} from '../../shared/helper';
import UploadData from '../../shared/components/UploadData';

let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message, duration) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};
class MarkupSymbolUpload extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
    resetMarkupGroup: PropTypes.func.isRequired,
    rowOldData: PropTypes.instanceOf(Object).isRequired
  };

  getHeaderName = (header) => {
    const { literalsENGlobal } = this.props;
    switch (header) {
      case literalsENGlobal.forms.markup_symbol_form.symbol:
        return { col: 'symbol_val', type: 'text' };
      case literalsENGlobal.forms.markup_symbol_form.markup_group:
        return { col: 'markup_group', type: 'text' };
      case literalsENGlobal.forms.markup_symbol_form.digits:
        return { col: 'digits', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.sessions:
        return { col: 'sessions', type: 'text' };
      case literalsENGlobal.forms.markup_symbol_form.markup_bid:
        return { col: 'markup_bid', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.markup_ask:
        return { col: 'markup_ask', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.markup_bid_var:
        return { col: 'markup_bid_var', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.markup_ask_var:
        return { col: 'markup_ask_var', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.spread_min:
        return { col: 'spread_min', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.spread_max:
        return { col: 'spread_max', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.spread_exec:
        return { col: 'spread_exec_value', type: 'text' };
      case literalsENGlobal.forms.markup_symbol_form.min_spread_exec:
        return { col: 'min_spread_exec_value', type: 'text' };
      case literalsENGlobal.forms.markup_symbol_form.extra_digits:
        return { col: 'extra_digits', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.state:
        return { col: 'state_value', type: 'text' };
      case literalsENGlobal.forms.markup_symbol_form.level:
        return { col: 'level', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.wl_bid:
        return { col: 'wl_bid', type: 'number' };
      case literalsENGlobal.forms.markup_symbol_form.wl_ask:
        return { col: 'wl_ask', type: 'number' };
      default:
        return { col: header, type: 'text' };
    }
  };

  postMultiple = (files, allFiles, rawData, showLoader, hideLoader) => {
    try {
      showLoader();
      const bulkData = [];
      const errors = [];
      const arr = this.props.rowOldData.reduce(
        (pV, cV) => [...pV, ...cV.markup_symbols],
        []
      );
      const rowData = _.keyBy(arr, (o) => o.markup_group + o.symbol_val);
      rawData.forEach((values) => {
        const symbolAndMarkupGroup = `${_.trim(values.symbol_val)}->${_.trim(
          values.markup_group
        )}`;
        const spreadExecVal =
          this.props.formData.SpreadExecObj[values.spread_exec_value];
        if (!spreadExecVal) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.spread_exec}`
          });
        }
        const spreadExec = spreadExecVal ? spreadExecVal.value : 0;
        const minSpreadExecVal =
          this.props.formData.MinSpreadExecObj[values.min_spread_exec_value];
        if (!minSpreadExecVal) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.min_spread_exec}`
          });
        }
        const minSpreadExec = minSpreadExecVal ? minSpreadExecVal.value : 0;
        const stateVal = this.props.formData.StateObj[values.state_value];
        if (!stateVal) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.state}`
          });
        }
        const state = stateVal ? stateVal.value : 0;
        if (!regExDigits.test(values.digits)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.digits}`
          });
        }
        if (!regExDigits.test(values.extra_digits)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.extra_digits}`
          });
        }
        if (!validateSessions(values.sessions)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.sessions}`
          });
        } else {
          values.sessions = parseSessions(_.trim(values.sessions));
        }
        if (!regExSignedDecimalNumber.test(values.markup_bid)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.markup_bid}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.markup_ask)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.markup_ask}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.markup_bid_var)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.markup_bid_var}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.markup_ask_var)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.markup_ask_var}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.spread_min)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.spread_min}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.spread_max)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.spread_max}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.level)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.level}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.wl_bid)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.wl_bid}`
          });
        }
        if (!regExSignedDecimalNumber.test(values.wl_ask)) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid} ${this.props.literals.forms.markup_symbol_form.wl_ask}`
          });
        }
        const oldRow = rowData[values.markup_group + values.symbol_val];
        if (!oldRow) {
          errors.push({
            symbol: symbolAndMarkupGroup,
            error: `${this.props.literals.forms.invalid}`
          });
        } else {
          const newData = {
            symbol_val: _.trim(values.symbol_val),
            security: `${_.trim(oldRow.security)}{${parseFloat(
              values.wl_bid
            )},${parseFloat(values.wl_ask)}}`,
            markup_group: _.trim(values.markup_group),
            digits: parseInt(values.digits, 10),
            sessions: _.trim(values.sessions),
            markup_bid: parseFloat(values.markup_bid),
            markup_ask: parseFloat(values.markup_ask),
            markup_bid_var: parseFloat(values.markup_bid_var),
            markup_ask_var: parseFloat(values.markup_ask_var),
            spread_min: parseFloat(values.spread_min),
            spread_max: parseFloat(values.spread_max),
            spread_exec: parseInt(spreadExec, 10),
            min_spread_exec: parseInt(minSpreadExec, 10),
            extra_digits: parseFloat(values.extra_digits),
            state: parseInt(state, 10),
            level: parseInt(values.level, 10)
          };
          const oldData = {
            symbol_val: oldRow.symbol_val,
            security: `${oldRow.security}{${parseFloat(
              oldRow.wl_bid
            )},${parseFloat(oldRow.wl_ask)}}`,
            markup_group: oldRow.markup_group,
            digits: parseInt(oldRow.digits, 10),
            sessions: oldRow.sessions,
            markup_bid: parseFloat(oldRow.markup_bid),
            markup_ask: parseFloat(oldRow.markup_ask),
            markup_bid_var: parseFloat(oldRow.markup_bid_var),
            markup_ask_var: parseFloat(oldRow.markup_ask_var),
            spread_min: parseFloat(oldRow.spread_min),
            spread_max: parseFloat(oldRow.spread_max),
            spread_exec: parseInt(oldRow.spread_exec, 10),
            min_spread_exec: parseInt(oldRow.min_spread_exec, 10),
            extra_digits: parseFloat(oldRow.extra_digits),
            state: parseInt(oldRow.state, 10),
            level: parseInt(oldRow.level, 10)
          };
          const dataToUpdate = {
            ...newData,
            old_data: {
              ...oldData
            }
          };
          if (!shallowEqual(newData, oldData)) {
            bulkData.push(dataToUpdate);
          }
        }
      });
      if (errors.length > 0) {
        showNotification(
          this.props.literals.sidebar.markup_symbol,
          'danger',
          JSON.stringify(errors),
          null
        );
        hideLoader();
        return;
      }
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}/security/markup_symbol_bulk`,
          JSON.stringify(bulkData)
        )
        .then((response) => {
          showNotification(
            this.props.literals.sidebar.markup_symbol,
            'primary',
            this.props.literals.forms.completed_message,
            3
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.markup_symbol,
              'danger',
              `${
                this.props.literals.forms.uni_symbol_form.symbols_failed
              } ${response.data.toString()} `,
              null
            );
          }
          allFiles.forEach((f) => f.remove());
          this.props.resetMarkupGroup();
          hideLoader();
        })
        .catch(() => {
          allFiles.forEach((f) => f.remove());
          showNotification(
            this.props.literals.sidebar.markup_symbol,
            'danger',
            this.props.literals.forms.fail_message,
            3
          );
          this.props.resetMarkupGroup();
          hideLoader();
        });
    } catch (e) {
      showNotification(
        this.props.literals.sidebar.markup_symbol,
        'danger',
        e.message,
        null
      );
      hideLoader();
    }
  };

  render() {
    return (
      <UploadData
        tableID="sidebar_markup_symbol_import"
        title={this.props.literals.sidebar.markup_symbol}
        getHeaderName={this.getHeaderName}
        postMultiple={this.postMultiple}
      />
    );
  }
}
export default connect((state) => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal
}))(MarkupSymbolUpload);
