/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { Field } from 'redux-form';
import { Button } from 'reactstrap';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);
const handleRiskAccountChange = (riskAccount, selectedOption, form, clearFields, risk, setRisk) => {
  clearFields(form, false, false, `${riskAccount}.taker`);
  clearFields(form, false, false, `${riskAccount}.tem`);
  clearFields(form, false, false, `${riskAccount}.taker_feed`);
  setRisk({
    ...risk,
    [`${riskAccount}.taker`]: selectedOption.node,
  });
};
const handleTakerChange = (riskAccount, selectedOption, form, clearFields, risk, setRisk) => {
  clearFields(form, false, false, `${riskAccount}.tem`);
  clearFields(form, false, false, `${riskAccount}.taker_feed`);
  setRisk({
    ...risk,
    [`${riskAccount}.tem`]: selectedOption.account,
    [`${riskAccount}.taker_feed`]: selectedOption.feed,
  });
};

const renderRiskAccounts = ({
  literals, state, change, clearFields, form, fields, defaultRisk, meta: { error, submitFailed },
}) => {
  const [risk, setRisk] = useState();
  return (
    <div className="form__form-group">
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button
          onClick={() => fields.push({})}
          className="icon"
          color="primary"
          style={{ display: 'inline-block' }}
        >
          <p
            style={{ display: 'inline-block' }}
            className="fas fa-plus"
          />
          {literals.forms.user_form.add_risk_account}
        </Button>
        {submitFailed && error &&
        <span
          className="form__form-group-error"
          style={{ display: 'inline-block' }}
        >{error}
        </span>
        }
      </div>
      <div className="form__form-group-field">
        <div className="form__form-group-input-wrap label-text form-small-item">
          #
        </div>
        <div
          className="form__form-group-input-wrap label-text"
        >{literals.tables.ag_table.alias}
        </div>
        <div
          className="form__form-group-input-wrap label-text"
        >{literals.tables.position_table.risk_account}
        </div>
        <div
          className="form__form-group-input-wrap label-text"
        >{literals.tables.position_table.taker}
        </div>
        <div
          className="form__form-group-input-wrap label-text"
        >{literals.tables.order_table.node_account}
        </div>
        <div
          className="form__form-group-input-wrap label-text"
        >{literals.tables.trading_table.feed}
        </div>
        <div
          className="form__form-group-input-wrap label-text form-small-item"
        >{literals.forms.user_form.read_only}
        </div>
        <div
          className="form__form-group-input-wrap label-text form-small-item"
        >{literals.forms.user_form.daily_statement}
        </div>
        <div className="form__form-group-input-wrap label-text form-small-item">
          {literals.forms.user_form.ext_order_info}
        </div>
        <div
          className="form__form-group-input-wrap label-text form-small-item"
        >{literals.tables.ag_table.default}
        </div>
        <div
          className="form__form-group-input-wrap label-text form-small-item"
        >{literals.tables.ag_table.actions}
        </div>
      </div>
      {fields.map((riskAccount, index) => (
        <div className="form__form-group-field" style={{ paddingTop: 5 }} key={index}>
          <div className="form__form-group-input-wrap label-text form-small-item">
            {index + 1}
          </div>
          <Field
            name={`${riskAccount}.alias`}
            component={renderField}
            type="text"
            placeholder="Alias"
          />
          <Field
            name={`${riskAccount}.risk_account`}
            component={renderSelectField}
            type="text"
            options={state.formData.RiskAccount || []}
            onChange={selectedOption =>
                 handleRiskAccountChange(riskAccount, selectedOption, form, clearFields, risk, setRisk)}
          />
          <Field
            name={`${riskAccount}.taker`}
            component={renderSelectField}
            type="text"
            options={fields.get(index).risk_account ?
                fields.get(index).risk_account.node : (risk ? risk[`${riskAccount}.taker`] : [])}
            onChange={selectedOption =>
                handleTakerChange(riskAccount, selectedOption, form, clearFields, risk, setRisk)}
          />
          <Field
            name={`${riskAccount}.tem`}
            component={renderSelectField}
            type="text"
            options={fields.get(index).taker ?
                fields.get(index).taker.account : (risk ? risk[`${riskAccount}.tem`] : [])}
          />
          <Field
            name={`${riskAccount}.taker_feed`}
            component={renderSelectField}
            type="text"
            options={fields.get(index).taker ?
                fields.get(index).taker.feed : (risk ? risk[`${riskAccount}.taker_feed`] : [])}
          />
          <div className="form-small-item">
            <Field
              name={`${riskAccount}.read_only`}
              component={renderCheckBoxField}
            />
          </div>
          <div className="form-small-item">
            <Field
              name={`${riskAccount}.daily_statement`}
              component={renderCheckBoxField}
              defaultChecked
            />
          </div>
          <div className="form-small-item">
            <Field
              name={`${riskAccount}.ext_order_info`}
              component={renderCheckBoxField}
            />
          </div>
          <div className="form-small-item">
            <Field
              name="default_risk"
              component={renderRadioButtonField}
              defaultChecked={index === 0}
              radioValue={index.toString()}
              style={{ paddingRight: 20 }}
            />
          </div>
          <div className="form-small-item">
            <Button
              onClick={() => {
                if (defaultRisk > 0 && defaultRisk >= index) {
                  change('default_risk', (defaultRisk - 1).toString());
                }
                fields.remove(index);
}}
              className="icon"
              color="primary"
            ><p
              className="fa fa-trash-alt"
            />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default renderRiskAccounts;

