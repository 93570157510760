/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import AutoSuggest from 'react-autosuggest';
import PropTypes from 'prop-types';

class AutoSuggestInput extends PureComponent {
    static propTypes = {
      input: PropTypes.instanceOf(Object).isRequired,
      placeholder: PropTypes.string,
      getSuggestions: PropTypes.func,
      getSuggestionValue: PropTypes.func,
      renderSuggestion: PropTypes.func,
    }
    static defaultProps = {
      placeholder: '',
      getSuggestions: () => {},
      getSuggestionValue: () => {},
      renderSuggestion: () => {},
    };

    constructor(props) {
      super(props);

      this.state = {
        suggestions: [],
      };
    }

    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: this.props.getSuggestions(value),
      });
    };

    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: [],
      });
    };

    onSuggestionSelected = (event, { suggestionValue, method }) => {
      this.props.input.onChange(suggestionValue);
      if (method === 'enter') {
        event.preventDefault();
      }
    };

    onKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    getSuggestionValue=suggestion => this.props.getSuggestionValue(suggestion);

    renderSuggestion=suggestion => this.props.renderSuggestion(suggestion)

    render() {
      const { input } = this.props;
      this.props.input.placeholder = this.props.placeholder;
      this.props.input.onKeyDown = this.onKeyDown;
      const { suggestions } = this.state;
      return (
        <AutoSuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={input}
        />
      );
    }
}

const renderAutoSuggestField = props => (
  <div className="form__form-group-input-wrap">
    <AutoSuggestInput
      disabled={props.disabled}
      placeholder={props.placeholder}
      input={props.input}
      getSuggestions={props.getSuggestions}
      getSuggestionValue={props.getSuggestionValue}
      renderSuggestion={props.renderSuggestion}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderAutoSuggestField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  getSuggestions: PropTypes.func,
  getSuggestionValue: PropTypes.func,
  renderSuggestion: PropTypes.func,
};

renderAutoSuggestField.defaultProps = {
  meta: null,
  disabled: false,
  placeholder: '',
  getSuggestions: () => {},
  getSuggestionValue: () => {},
  renderSuggestion: () => {},
};

export default renderAutoSuggestField;
