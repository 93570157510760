const aggregateGroupValidate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${props.literals.forms.aggregate_group_form.name} ${props.literals.forms.required_validate}`;
  }
  if (values.name && values.name.length > 32) {
    errors.name = `${props.literals.forms.aggregate_group_form.name} ${props.literals.forms.max_32_validate}`;
  }
  return errors;
};

export default aggregateGroupValidate;
