import React, { PureComponent } from 'react';
import DatePicker from 'react-datetime';
import PropTypes from 'prop-types';
import moment from 'moment';

class DateTimePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Object),
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]),
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]).isRequired,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
  };

    static defaultProps = {
      options: [],
      defaultValue: null,
    };

    constructor(props) {
      super(props);
      this.state = {
        startDate: null,
      };
    }

    componentDidMount() {
      let defVal = null;
      if (this.props.value) {
        defVal = this.props.value;
      } else if (this.props.defaultValue) {
        defVal = this.props.defaultValue;
      } else if (this.props.name === 'start_date') {
        defVal = moment().startOf('day');
      } else if (this.props.name === 'end_date') {
        defVal = moment().endOf('day');
      }
      if (typeof defVal === 'object') {
        defVal = defVal.format('DD-MM-YYYY HH:mm:ss');
      }
      this.props.onChange(defVal);
    }

  handleChange = (date) => {
    let newDate = date;
    if (typeof date === 'object') {
      newDate = date.format('DD-MM-YYYY HH:mm:ss');
    }
    this.setState({
      startDate: newDate,
    });
    this.props.onChange(newDate);
  };

  render() {
    const {
      value, name, options,
    } = this.props;
    return (
      <div className="date-picker">
        <DatePicker
          name={name}
          value={value}
          options={options}
          timeFormat="HH:mm:ss"
          selected={this.state.startDate}
          onChange={this.handleChange}
          dateFormat="DD-MM-YYYY"
        />
      </div>
    );
  }
}

const renderDateTimePickerField = props => (
  <div className="form__form-group-input-wrap">
    <DateTimePickerField
      {...props.input}
      options={props.options}
      defaultValue={props.defaultValue}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    label: PropTypes.string,
  })),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
};

renderDateTimePickerField.defaultProps = {
  meta: null,
  options: [],
  defaultValue: null,
};

export default renderDateTimePickerField;
