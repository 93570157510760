/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderInputNumberField from '../../shared/components/form/NumberInput';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class PositionModifySLTPForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleModifySLTP: PropTypes.func.isRequired,
    update_sl_price: PropTypes.bool,
    update_tp_price: PropTypes.bool,
  };
  static defaultProps = {
    update_sl_price: false,
    update_tp_price: false,
  };
  render() {
    const { handleSubmit, literals } = this.props;
    const { submit } = literals.forms.uni_symbol_form;
    return (
      <form className="form  form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label" />
          <div className="form__form-group-field">
            <span className="marginLeftAuto">
              <Field
                name="update_sl_price"
                component={renderCheckBoxField}
                label={`${literals.tables.ag_table.update} ${literals.tables.position_table.sl_price}`}
              />
            </span>
          </div>
          <span className="form__form-group-label">
            {literals.tables.position_table.sl_price}
          </span>
          <div className="form__form-group-field">
            <Field
              name="sl_price"
              component={renderInputNumberField}
              step={1}
              min={0}
              disabled={!this.props.update_sl_price}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label" />
          <div className="form__form-group-field">
            <span className="marginLeftAuto">
              <Field
                name="update_tp_price"
                component={renderCheckBoxField}
                label={`${literals.tables.ag_table.update} ${literals.tables.position_table.tp_price}`}
              />
            </span>
          </div>
          <span className="form__form-group-label">
            {literals.tables.position_table.tp_price}
          </span>
          <div className="form__form-group-field">
            <Field
              name="tp_price"
              component={renderInputNumberField}
              step={1}
              min={0}
              disabled={!this.props.update_tp_price}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar form__button-toolbar-remove-top-padding">
          <Button color="primary" type="submit">
            {submit}
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => this.props.toggleModifySLTP()}
          >
            {literals.forms.uni_symbol_form.cancel}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}
const selector = formValueSelector('position_modify_sl_tp_form');
function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    theme: state.theme,
    update_sl_price: selector(state, 'update_sl_price'),
    update_tp_price: selector(state, 'update_tp_price'),
    initialValues: {
      sl_price: ownProps.node.data.sl_price,
      tp_price: ownProps.node.data.tp_price,
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'position_modify_sl_tp_form', // a unique identifier for this form
})(PositionModifySLTPForm));
