import NotificationSystem from 'rc-notification';
import React from 'react';
import { BasicNotification } from './Notification';

let notification = null;

NotificationSystem.newInstance({}, (n) => {
  notification = n;
});

/**
 * show a notification
 * @param {title, color, message, {duration, closable, style, className}} config
 * @returns
 */
const showNotification = (title, color = 'primary', message, props = {}) =>
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
    ...props,
  });

export default showNotification;

export const getNotificationHandler = () => notification;
export const showNotificationR = (title, color, message) =>
  showNotification(title, color, message, {
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
