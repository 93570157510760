/* eslint-disable  react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);
class ValuesForm extends PureComponent {
  static propTypes = {
    change: PropTypes.func.isRequired,
    values: PropTypes.instanceOf(Array).isRequired,
    onChangeValues: PropTypes.func.isRequired,
    literals: LiteralProps.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      values: props.values,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.changeValues();
    }, 0);
  }

  valuesChangeCallback=() => {
    this.props.onChangeValues(this.state.values);
  };

  valuesChangeCallbackRemove=() => {
    this.valuesChangeCallback();
    this.changeValues();
  };

  changeValues=() => {
    this.props.change('values.tag', []);
    this.props.change('values.value', []);
    this.state.values.forEach((item, idx) => {
      this.props.change(`values.tag[${idx}]`, item.tag);
      this.props.change(`values.value[${idx}]`, item.val);
    });
  };

  handleTagChange = idx => (evt) => {
    const newValues = this.state.values.map((value, sidx) => {
      if (idx !== sidx) return value;
      return { ...value, tag: evt.target.value };
    });

    this.setState({ values: newValues }, this.valuesChangeCallback);
  };

  handleValChange = idx => (evt) => {
    const newValues = this.state.values.map((value, sidx) => {
      if (idx !== sidx) return value;
      return { ...value, val: evt.target.value };
    });

    this.setState({ values: newValues }, this.valuesChangeCallback);
  };

  handleAddValue = () => {
    this.setState({
      values: this.state.values.concat([{ tag: '', val: '' }]),
    }, this.valuesChangeCallback);
  };

  handleRemoveValue = idx => () => {
    if (this.state.values.length > 1) {
      this.setState({
        values: this.state.values.filter((s, sidx) => idx !== sidx),
      }, this.valuesChangeCallbackRemove);
    }
  };

  render() {
    return (
      <div className="form__form-group" style={{ marginBottom: 0 }}>
        {this.state.values.map((value, idx) => (
          <React.Fragment key={idx}>
            <span
              className="form__form-group-label"
            >{idx === 0 ? this.props.literals.forms.maker_api_link_form.values : ''}
            </span>
            <div className="form__form-group-field">
              <Field
                name={`values.tag[${idx}]`}
                component={renderField}
                type="text"
                placeholder={`${this.props.literals.forms.maker_api_link_form.tag} ${idx + 1}`}
                onChange={this.handleTagChange(idx)}
              />
              <Field
                name={`values.value[${idx}]`}
                component={renderField}
                type="text"
                placeholder={`${this.props.literals.forms.maker_api_link_form.value} ${idx + 1}`}
                onChange={this.handleValChange(idx)}
              />
              <Button
                type="button"
                onClick={this.handleRemoveValue(idx)}
                color="primary"
                className="icon"
              >
                <p className="fas fa-minus" />
              </Button>
            </div>
          </React.Fragment>
        ))}
        <Button
          type="button"
          onClick={this.handleAddValue}
          color="primary"
        >
          {this.props.literals.tables.ag_table.add} {this.props.literals.forms.maker_api_link_form.value}
        </Button>
      </div>
    );
  }
}

export default ValuesForm;
