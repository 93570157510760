import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRouter = ({ component: Component, ...props }) => (
  <Route
    render={() => (
      props.rootStore.authStore.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/',
        state: { from: props.location },
      }}
      />
  )}
  />
);
export default PrivateRouter;
