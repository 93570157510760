/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { LiteralProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import AggregatorRestartForm from './AggregatorRestartForm';
import AggregatorRestartAction from './AggregatorRestartAction';

@inject('rootStore')
class TopbarAggregatorRestart extends PureComponent {
  static propTypes = {
    marginLeft: PropTypes.number,
    className: PropTypes.string,
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  static defaultProps = {
    marginLeft: 5,
    className: 'conn__status',
  };
  state = {
    popoverOpen: false,
  };

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  render() {
    const theme = this.props.theme.className;
    return (
      <div className={`topbar__profile ${this.props.className}`}>
        <div style={{ height: 36 }}>
          <span
            onClick={this.toggle}
            role="presentation"
            style={{ cursor: 'pointer' }}
            ref={(c) => {
            this.target = c && ReactDOM.findDOMNode(c);
          }}
          >
            <p
              className="fa fa-redo"
              style={{
              color: '#70bbfd',
              fontWeight: 'bold',
              float: 'left',
              lineHeight: 5.5,
            }}
            />
            <p
              style={{
              float: 'left',
              lineHeight: 3.6,
            marginLeft: this.props.marginLeft,
          }}
            >
              {this.props.literals.topbar.restart}
            </p>
          </span>
          <Popover
            placement="bottom-end"
            isOpen={this.state.popoverOpen}
            target={() => this.target}
            toggle={this.toggle}
            className={theme === 'theme-light'
              ? 'theme-light trading-light' : 'theme-dark trading-dark'}
          >
            <PopoverHeader>{this.props.literals.forms.aggregator_restart_form.title_create}</PopoverHeader>
            <PopoverBody>
              <AggregatorRestartForm
                state={{
                toggle: this.toggle,
                client_name: this.props.rootStore.authStore.user.client_name,
                role: this.props.rootStore.authStore.role,
              }}
                onSubmit={AggregatorRestartAction}
              />
            </PopoverBody>
          </Popover>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(withRouter(TopbarAggregatorRestart));
