import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Clock from 'react-live-clock';

@inject('rootStore')
class TopbarClientName extends PureComponent {
  static propTypes = {
    marginLeft: PropTypes.number,
    rootStore: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string
  };
  static defaultProps = {
    marginLeft: 5,
    className: 'conn__status'
  };

  render() {
    return (
      <div className={`topbar__profile ${this.props.className}`}>
        <div style={{ lineHeight: '20px' }}>
          <p />
          <p
            style={{
              display: 'inline-block',
              marginLeft: this.props.marginLeft + 10,
              backgroundColor: '#2f84ff',
              borderRadius: 5,
              padding: '0 5px',
              color: '#ffffff',
              fontWeight: 'bold'
            }}
          >
            {this.props.rootStore.authStore.user.company_name
              ? this.props.rootStore.authStore.user.company_name.toUpperCase()
              : this.props.rootStore.authStore.user.client_name.toUpperCase()}
          </p>
        </div>
        <div style={{ lineHeight: 0 }}>
          <p />
          <p
            style={{
              display: 'inline-block',
              marginLeft: this.props.marginLeft
            }}
          >
            <Clock format="DD-MM-YYYY HH:mm:ss" ticking timezone="UTC" /> UTC
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(TopbarClientName);
