import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({
  display,
}) => {
  if (display) {
    return (
      <div className="loader" />);
  }
  return (null);
};

Loader.propTypes = {
  display: PropTypes.bool,
};

Loader.defaultProps = {
  display: false,
};

export default Loader;
