/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { ThemeProps, LiteralENGlobalProps, LiteralProps } from '../prop-types/ReducerProps';
import Loader from './Loader';
import UploadButton from './UploadButton';

class UploadData extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    getHeaderName: PropTypes.func.isRequired,
    postMultiple: PropTypes.func.isRequired,
    tableID: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.gridWrapperID = `grid-wrapper-${this.props.tableID}`;
    this.myGridID = `myGrid_${this.props.tableID}`;
    this.state = {
      rawHeaders: [],
      data: [],
      loaderShow: false,
    };
  }

  getHeaderRow=(sheet) => {
    const headers = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);
    let C;
    const R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */

      let hdr = `UNKNOWN ${C}`; // <-- replace with your desired default
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      headers.push({ key: this.props.getHeaderName(_.trim(hdr)), value: hdr });
    }
    return headers;
  }

  handleChangeStatus = ({ file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (evt) => { // ev
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const rawHeaders = this.getHeaderRow(ws);
        const headers = _.map(rawHeaders, 'key.col');
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {
          header: headers, range: 1, defval: null, raw: false,
        }).map(row => _.mapValues(row, value => _.trim(value)));
        this.setState({ rawHeaders });
        this.setState({ data });
      };
      reader.readAsBinaryString(file);
    } else if (status === 'removed') {
      this.setState({ rawHeaders: [] });
      this.setState({ data: [] });
    }
  }

  handleSubmit = (files, allFiles) => {
    this.props.postMultiple(files, allFiles, this.state.data, this.showLoader, this.hideLoader);
  }

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  headerHeightSetter=(params) => {
    const padding = 20;
    const height = this.headerHeightGetter() + padding;
    params.api.setHeaderHeight(height);
    params.api.resetRowHeights();
  }

  headerHeightGetter=() => {
    const columnHeaderTexts = [
      ...document.querySelectorAll((`#${this.myGridID} .ag-header .ag-header-cell-text`)),
    ];
    const clientHeights = columnHeaderTexts.map(headerText => headerText.clientHeight);
    const tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  }

  render() {
    const theme = this.props.theme.className;
    return (
          <>
            <Dropzone
              onChangeStatus={this.handleChangeStatus}
              onSubmit={this.handleSubmit}
              maxFiles={1}
              multiple={false}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              inputContent={(files, extra) => (extra.reject ?
                  this.props.literals.forms.uni_symbol_form.xlsx_csv_only :
                  this.props.literals.forms.uni_symbol_form.drop_a_file)}
              SubmitButtonComponent={props => (<UploadButton
                {...props}
                theme={this.props.theme}
                literals={this.props.literals}
                title={this.props.title}
              />)}
              styles={{
                dropzone: { maxHeight: 150, minHeight: 150, width: '100%' },
                dropzoneActive: { borderColor: 'green' },
                dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
              }}
            />
            <div className="outer-grid-label">
              {this.props.literals.tables.ag_table.total_records}: {this.state.data ? this.state.data.length : 0}
            </div>
            <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
              <div
                id={this.myGridID}
                style={{
                    boxSizing: 'border-box',
                    height: '60vh',
                    width: '100%',
                  }}
                className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
              >
                <AgGridReact
                  rowData={this.state.data}
                  suppressCellSelection
                  suppressContextMenu
                  rowHeight={20}
                  defaultColDef={{
                      sortable: true,
                      resizable: true,
                      menuTabs: ['filterMenuTab'],
                      suppressMovable: true,
                      flex: 1,
                    }}
                  columnTypes={{
                      text: { filter: 'agTextColumnFilter' },
                      number: { filter: 'agNumberColumnFilter' },
                      numberWithFilterReset: {
                        filter: 'agNumberColumnFilter',
                      },
                    }}
                  onFirstDataRendered={this.headerHeightSetter}
                  onColumnResized={this.headerHeightSetter}
                >
                  {this.state.rawHeaders.map((header, i) =>
                      (<AgGridColumn
                        key={i}
                        field={header.key.col}
                        type={header.key.type}
                        headerName={header.value}
                      />))}
                </AgGridReact>
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </>
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal,
}))(UploadData);
