import { Field } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import renderSelectField from '../../shared/components/form/SelectBox';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from './TreeSelect';

const namePrefix = 'ham_';
const HAMForm = ({
  literals, theme, renderField, execMode, lpSession, dir,
}) => (
  <div className="borderValuesForm">
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{literals.forms.aggregate_symbol_form.lp_sessions}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}lp_sessions`}
          component={renderTreeSelectField}
          treeData={lpSession}
          treeDefaultExpandedKeys={['Select All']}
          className={theme.className === 'theme-light' ?
              'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
          dropdownClassName={theme.className === 'theme-light' ?
              'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}

        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{literals.forms.aggregate_symbol_form.exec_mode}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}exec_mode`}
          component={renderSelectField}
          type="text"
          options={execMode}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', verticalAlign: 'top' }}>
      <span
        className="form__form-group-label"
      >{literals.forms.aggregate_symbol_form.filter_factor}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}filter_factor`}
          component={renderField}
          type="number"
          step="any"
          placeholder={literals.forms.aggregate_symbol_form.filter_factor}
        />
      </div>
    </div>
  </div>
);
HAMForm.propTypes = {
  literals: LiteralProps.isRequired,
  theme: ThemeProps.isRequired,
  renderField: PropTypes.instanceOf(Object).isRequired,
  execMode: PropTypes.instanceOf(Array),
  lpSession: PropTypes.instanceOf(Array),
  dir: PropTypes.string.isRequired,
};
HAMForm.defaultProps = {
  execMode: [],
  lpSession: [],
};

export default HAMForm;
