import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import accountGroupValidate from './validate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import renderSelectField from '../../shared/components/form/Select';

const renderField = ({
  input, placeholder, type, disabled, step, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class AccountGroupForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals,
      } = this.props;
      const assetClassGroupValues = this.props.state.formData.AssetClassGroup;
      const limitSymbolGroupValues = this.props.state.formData.LimitSymbolGroup;
      const disabled = false;
      const { submit } = literals.forms.account_group_form;
      return (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <form className="form form--horizontal" onSubmit={handleSubmit}>
                <div className="form__half">
                  <div className="form__form-group">
                    <span
                      className="form__form-group-label"
                    >{literals.forms.account_group_form.name}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="name"
                        component={renderField}
                        type="text"
                        placeholder={literals.forms.account_group_form.name}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span
                      className="form__form-group-label"
                    >{literals.forms.account_group_form.asset_class_group}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="assetclass_group"
                        component={renderSelectField}
                        type="text"
                        options={assetClassGroupValues}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span
                      className="form__form-group-label"
                    >{literals.forms.account_group_form.state}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="state"
                        component={renderCheckBoxField}
                        label={literals.forms.account_group_form.state}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__half">
                  <div className="form__form-group">
                    <span
                      className="form__form-group-label"
                    >{literals.forms.account_group_form.limit_symbol_group}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="limit_symbol_group"
                        component={renderSelectField}
                        type="text"
                        options={limitSymbolGroupValues}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span
                      className="form__form-group-label"
                    >{literals.forms.account_group_form.description}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="description"
                        component={renderField}
                        type="text"
                        placeholder={literals.forms.account_group_form.description}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">{submit}</Button>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => this.props.state.toggle('1')}
                    >{literals.forms.account_group_form.cancel}
                    </Button>
                    <Button type="button" onClick={reset} disabled={pristine || submitting}>
                      {literals.forms.account_group_form.reset}
                    </Button>
                  </ButtonToolbar>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      );
    }
}

function mapStateToProps(state) {
  return { literals: state.literals };
}


export default connect(mapStateToProps)(reduxForm({
  form: 'account_group_form', // a unique identifier for this form
  validate: accountGroupValidate,
})(AccountGroupForm));
