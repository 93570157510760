const reverseTradeCopierValidate = (values, props) => {
  const errors = {};
  if (values.master_login !== 0 && values.master_login === values.target_login) {
    errors.master_login = props.literals.forms.reverse_trade_copier_form.master_target_login_equal_validate;
    errors.target_login = props.literals.forms.reverse_trade_copier_form.target_master_login_equal_validate;
  }
  return errors;
};

export default reverseTradeCopierValidate;
