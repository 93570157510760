const aggregatorRestart = (values) => {
  const errors = {};
  if (!values.reason) {
    errors.reason = 'Reason field shouldn’t be empty';
  }
  if (!values.sleep) {
    errors.sleep = 'Sleep field shouldn’t be empty';
  }
  return errors;
};

export default aggregatorRestart;
