import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Websocket from 'react-websocket';

@inject('rootStore')
class TopbarVersion extends PureComponent {
  static propTypes = {
    marginLeft: PropTypes.number,
    rootStore: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string,
  };
  static defaultProps = {
    marginLeft: 5,
    className: 'conn__status',
  };
  constructor(props) {
    super(props);
    this.state = {
      version: {},
    };
  }
  componentDidMount() {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/version`)
      .then((response) => {
        const data = response.data[0];
        this.setState({ version: data });
      })
      .catch(() => {
      });
  }

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    if ('aggregator_version' in data && data.aggregator_version) {
      this.setState({ version: data.aggregator_version });
    }
  };
  socketOpenListener = () => {
    this.sendMessage(JSON.stringify({
      key: 'aggregator_version',
      value: [],
    }));
  };
  sendMessage=(message) => {
    this.refWebSocket.sendMessage(message);
  };

  render() {
    const { version } = this.state;
    return (
      <React.Fragment>
        {version &&
          <React.Fragment>
            <div className={`topbar__profile ${this.props.className}`}>
              <div style={{ lineHeight: '20px' }}>
                <p />
                <p style={{
                  display: 'inline-block',
                  marginLeft: this.props.marginLeft,
                }}
                >
                  ver. {version.version}
                </p>
              </div>
              <div style={{ lineHeight: 0 }}>
                <p />
                <p style={{
                  display: 'inline-block',
                  marginLeft: this.props.marginLeft,
                }}
                >
                  hostname. {version.hn}
                </p>
              </div>
            </div>
            <div className={`topbar__profile ${this.props.className}`}>
              <div style={{ lineHeight: '20px' }}>
                <p />
                <p style={{
                  display: 'inline-block',
                  marginLeft: this.props.marginLeft,
                }}
                >
                  db. {version.db_name}
                </p>
              </div>
              <div style={{ lineHeight: 0 }}>
                <p />
                <p style={{
                  display: 'inline-block',
                  marginLeft: this.props.marginLeft,
                }}
                >
                  dbhost. {version.db_host}
                </p>
              </div>
            </div>
          </React.Fragment>
          }
        <Websocket
          url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
          reconnectIntervalInMilliSeconds={1000}
          onMessage={this.socketMessageListener}
          onOpen={this.socketOpenListener}
          ref={(WS) => {
                this.refWebSocket = WS;
              }}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(TopbarVersion);
