/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

const parseMultiPattern = (values, pattern, text, tree, securitySymbol) => {
  let result = '*,';
  if (values[pattern] && values[text]) {
    result = values[text].trim() || '*,';
  } else if (!values[pattern] && values[tree]) {
    if (tree === 'symbols_tree') {
      if (values[tree].length > 0) {
        const symbols = [];
        values[tree].forEach((item) => {
          if (item in securitySymbol) {
            symbols.push(...securitySymbol[item]);
          } else {
            symbols.push(item);
          }
        });
        result = symbols.join(',');
      } else {
        result = '*,';
      }
    } else {
      result = values[tree].length > 0 ? values[tree].join(',') : '*,';
    }
  }
  return result;
};

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, (n) => (notification = n));
  const showNotification = (title, color, message) => {
    notification.notice({
      content: (
        <BasicNotification title={title} message={message} color={color} />
      ),
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up'
    });
  };
  const vals = [];
  if (values.values && values.values.tag && values.values.value) {
    values.values.tag.forEach((tag, i) => {
      const value = values.values.value[i];
      if (tag && tag.trim() && value && value.trim()) {
        vals.push(`${tag.trim()}=${value.trim()}`);
      }
    });
  }
  const data = {
    id: parseInt(values.id, 10),
    takers: parseMultiPattern(
      values,
      'takers_pattern',
      'takers_text',
      'takers_tree'
    ),
    tems: parseMultiPattern(values, 'tems_pattern', 'tems_text', 'tems_tree'),
    hams: parseMultiPattern(values, 'hams_pattern', 'hams_text', 'hams_tree'),
    securities: parseMultiPattern(
      values,
      'securities_pattern',
      'securities_text',
      'securities_tree'
    ),
    symbols: parseMultiPattern(
      values,
      '',
      '',
      'symbols_tree',
      stateObj.formData.SecuritySymbol
    ),
    shared_ids: parseMultiPattern(
      values,
      'shared_ids_pattern',
      'shared_ids_text',
      'shared_ids_tree'
    ),
    sides: parseInt(values.sides.value, 10),
    ord_types: parseInt(values.ord_types.value, 10),
    priority: parseInt(values.priority.value, 10),
    values: vals.join(','),
    state: values.state ? 1 : 0,
    description: values.description
  };
  axios
    .post(
      `${process.env.REACT_APP_BACKEND_API}/security/maker_api_link`,
      JSON.stringify(data)
    )
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.maker_api_link_form.title_create,
          'primary',
          props.literals.forms.success_message
        );
        stateObj.resetMakerAPILink();
        stateObj.toggle('1');
      } else {
        showNotification(
          props.literals.forms.maker_api_link_form.title_create,
          'danger',
          props.literals.forms.fail_message
        );
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(
        props.literals.forms.maker_api_link_form.title_create,
        'danger',
        error.message
      );
      stateObj.hideLoader();
    });
};
