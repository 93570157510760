import moment from 'moment';

const lpSessionValidate = (values, props) => {
  const { role } = props.rootStore.authStore;
  const errors = {};
  if (!values.lp_category && (role !== 'admin' || (role === 'admin' && !values.external_maker_api))) {
    errors.lp_category =
        `${props.literals.forms.lp_session_form.lp_category} ${props.literals.forms.required_validate}`;
  }
  if (!values.lp_session_type) {
    errors.lp_session_type =
        `${props.literals.forms.lp_session_form.lp_session_type} ${props.literals.forms.required_validate}`;
  }
  if (!values.server_address) {
    errors.server_address =
        `${props.literals.forms.lp_session_form.server_address} ${props.literals.forms.required_validate}`;
  }
  if (!values.sender_comp_id) {
    errors.sender_comp_id =
        `${props.literals.forms.lp_session_form.sender_comp_id} ${props.literals.forms.required_validate}`;
  }
  if (!values.target_comp_id) {
    errors.target_comp_id =
        `${props.literals.forms.lp_session_form.target_comp_id} ${props.literals.forms.required_validate}`;
  }
  if (!values.shared_id && (role !== 'admin' || (role === 'admin' && !values.external_maker_api))) {
    errors.shared_id =
        `${props.literals.forms.lp_session_form.shared_id} ${props.literals.forms.required_validate}`;
  }
  if (values.shared_id && values.lp_session_type) {
    if (props.state.formData.LPSession && props.state.formData.LPSession.some(lp => lp.value === values.shared_id &&
        ((lp.session_type !== 'MD' && lp.session_type !== 'TD') ||
            // eslint-disable-next-line eqeqeq
            (values.lp_session_type.value == '1') ||
            // eslint-disable-next-line eqeqeq
            (lp.session_type === 'MD' && values.lp_session_type.value == '2') ||
            // eslint-disable-next-line eqeqeq
            (lp.session_type === 'TD' && values.lp_session_type.value == '3')))) {
      errors.shared_id =
          `${props.literals.forms.lp_session_form.shared_id} ${props.literals.forms.exists_validate}`;
    }
  }
  if (!values.start_day) {
    errors.start_day =
        `${props.literals.forms.lp_session_form.start_day} ${props.literals.forms.required_validate}`;
  }
  if (!moment(values.start_time, 'HH:mm:ss', true).isValid() ||
      (values.start_time && values.start_time.substr(0, 2) >= 24)) {
    errors.start_time =
        `${props.literals.forms.lp_session_form.start_time} ${props.literals.forms.fail_validate}`;
  }
  if (!values.end_day) {
    errors.end_day =
        `${props.literals.forms.lp_session_form.end_day} ${props.literals.forms.required_validate}`;
  }
  if (!moment(values.end_time, 'HH:mm:ss', true).isValid() ||
      (values.end_time && values.end_time.substr(0, 2) >= 24)) {
    errors.end_time =
        `${props.literals.forms.lp_session_form.end_time} ${props.literals.forms.fail_validate}`;
  }
  return errors;
};

export default lpSessionValidate;
