import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderTreeSelectField from '../../shared/components/form/TreeSelect';
import renderDateTimePickerField from '../../shared/components/form/DateTimePicker';
import validate from '../../shared/components/form/StartEndDateValidate';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class MakerSymbolStatusReportSearchForm extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      showLoader: PropTypes.func.isRequired,
      hideLoader: PropTypes.func.isRequired,
      gridColumns: PropTypes.instanceOf(Array),
      gridDisplayedColumns: PropTypes.instanceOf(Array),
      gridColumnsField: PropTypes.instanceOf(Array),
      change: PropTypes.func.isRequired,
      setGridFilterValues: PropTypes.func,
      onBtExport: PropTypes.func,
    };
    static defaultProps = {
      gridColumns: [],
      gridDisplayedColumns: [],
      gridColumnsField: [],
      setGridFilterValues: () => {},
      onBtExport: () => {},
    }
    constructor(props) {
      super(props);
      this.state = {
        shared_id: [],
        symbol_val: [],
      };
    }
    componentDidMount() {
      this.props.showLoader();
      axios.get(`${process.env.REACT_APP_BACKEND_API}/report/maker_symbol_status_search`)
        .then((response) => {
          this.setState({ shared_id: response.data.maker });
          this.setState({ symbol_val: response.data.symbol });
          if (this.props.gridColumnsField.length === 0) {
            this.props.change('grid_columns', this.props.gridDisplayedColumns);
          }
          this.props.setGridFilterValues(response);
          this.props.hideLoader();
        })
        .catch(() => {
          this.props.hideLoader();
        });
    }
    render() {
      const {
        handleSubmit, theme, literals,
      } = this.props;
      return (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__quarter form__search">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.ag_table.start_date}</span>
              <div className="form__form-group-field">
                <Field
                  name="start_date"
                  component={renderDateTimePickerField}
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit" className="icon-text-button">
                {literals.tables.ag_table.view_results}
              </Button>
              <Button color="primary" className="icon-text-button" onClick={() => this.props.onBtExport(2)}>
                {literals.tables.ag_table.export_csv}
              </Button>
              <Button color="primary" className="icon-text-button" onClick={() => this.props.onBtExport(1)}>
                {literals.tables.ag_table.export_excel}
              </Button>
            </ButtonToolbar>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.ag_table.end_date}</span>
              <div className="form__form-group-field">
                <Field
                  name="end_date"
                  component={renderDateTimePickerField}
                />
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.ag_table.grid_columns}</span>
              <div className="form__form-group-field">
                <Field
                  name="grid_columns"
                  component={renderTreeSelectField}
                  treeData={this.props.gridColumns}
                  treeDefaultExpandAll
                  className={theme.className === 'theme-light' ?
                              'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                              'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.maker_symbol_status_table.shared_id}</span>
              <div className="form__form-group-field">
                <Field
                  name="shared_id"
                  component={renderTreeSelectField}
                  treeData={this.state.shared_id}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
          <div className="form__quarter">
            <div className="form__form-group">
              <span className="form__form-group-label">{literals.tables.maker_symbol_status_table.symbol_val}</span>
              <div className="form__form-group-field">
                <Field
                  name="symbol_val"
                  component={renderTreeSelectField}
                  treeData={this.state.symbol_val}
                  treeDefaultExpandedKeys={['Select All']}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                />
              </div>
            </div>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('maker_symbol_status_report_search_form');

function mapStateToProps(state) {
  return { literals: state.literals, theme: state.theme, gridColumnsField: selector(state, 'grid_columns') };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'maker_symbol_status_report_search_form', // a unique identifier for this form
  destroyOnUnmount: false,
  validate,
})(MakerSymbolStatusReportSearchForm));
