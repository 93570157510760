/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';
import { BasicNotification } from '../../shared/components/Notification';
import BoolRenderer from '../../shared/components/BoolRenderer';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import SessionRenderer from '../../shared/components/session/SessionRenderer';
import SessionHeaderComponent from '../../shared/components/multi_edit/SessionHeaderComponent';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

const exportColumns = ['unisymbol', 'lp_symbol', 'lp_session', 'security', 'base', 'quote',
  'contract_size', 'digits', 'sessions', 'markup_bid', 'markup_ask', 'depth',
  'commission', 'min_size', 'a_step', 'consume_value',
  'allow_sweep_value', 'state_value', 'multi_req_trade', 'expiry_date',
  'sub_volumes', 'timeout_warn', 'timeout_error', 'timeout_kill', 'timeout_delay',
  'feed_side_value', 'queue_timeout', 'disable_b_on_delay_value', 'uni_description', 'description'];

@inject('rootStore')
class LPSessionDetailCellRenderer extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      data: PropTypes.instanceOf(Object).isRequired,
      api: PropTypes.instanceOf(Object).isRequired,
      context: PropTypes.instanceOf(Object).isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = props.context.rowsToUpdateDetail[props.node.id] || {};
      this.stylesToUpdate = props.context.stylesToUpdateDetail[props.node.id] || {};
      const { literals } = this.props;
      const { role } = props.rootStore.authStore;
      this.state = {
        modules: AllModules,
        colDefs: [
          {
            headerName: literals.forms.lp_symbol_form.unisymbol,
            field: 'unisymbol',
            editable: false,
            pinned: 'left',
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.lp_symbol_form.lp_symbol,
            field: 'lp_symbol',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'cellHeaderComponent',
            headerComponentParams: { customName: 'Symbol' },
          },
          {
            headerName: literals.forms.lp_symbol_form.lp_session,
            field: 'lp_session',
            editable: false,
          },
          {
            headerName: literals.forms.lp_symbol_form.security,
            field: 'security',
            editable: role === 'admin',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: [],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: role === 'admin' ? 'cellHeaderComponent' : '',
            headerComponentParams: {
              selectValueLiteral: 'Security',
            },
          },
          {
            headerName: literals.forms.lp_symbol_form.base,
            field: 'base',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.lp_symbol_form.quote,
            field: 'quote',
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.lp_symbol_form.contract_size,
            field: 'contract_size',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.digits,
            field: 'digits',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.sessions,
            field: 'sessions',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'sessionHeaderComponent',
            editable: false,
            cellRenderer: 'sessionRenderer',
          },
          {
            headerName: literals.forms.lp_symbol_form.markup_bid,
            field: 'markup_bid',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.markup_ask,
            field: 'markup_ask',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.depth,
            field: 'depth',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.commission,
            field: 'commission',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.min_size,
            field: 'min_size',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.a_step,
            field: 'a_step',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.consume,
            field: 'consume_value',
            editable: false,
            cellRenderer: 'boolRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.allow_sweep,
            field: 'allow_sweep_value',
            editable: false,
            cellRenderer: 'boolRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.state,
            field: 'state_value',
            editable: false,
            cellRenderer: 'stateRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.margin_perc,
            field: 'margin_perc',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
            hide: true,
          },
          {
            headerName: literals.forms.lp_symbol_form.multi_req_trade,
            field: 'multi_req_trade',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.expiry_date,
            field: 'expiry_date',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.sub_volumes,
            field: 'sub_volumes',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.timeout_warn,
            field: 'timeout_warn',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.timeout_error,
            field: 'timeout_error',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.timeout_kill,
            field: 'timeout_kill',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.timeout_delay,
            field: 'timeout_delay',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.feed_side,
            field: 'feed_side_value',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: [],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
            headerComponent: 'cellHeaderComponent',
            headerComponentParams: {
              selectValueLiteral: 'FeedSide',
            },
          },
          {
            headerName: literals.forms.lp_symbol_form.queue_timeout,
            field: 'queue_timeout',
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'cellHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.disable_b_on_delay,
            field: 'disable_b_on_delay_value',
            editable: false,
            cellRenderer: 'boolRenderer',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            headerComponent: 'stateHeaderComponent',
          },
          {
            headerName: literals.forms.lp_symbol_form.description,
            field: 'uni_description',
            editable: false,
            tooltipField: 'uni_description',
          },
          {
            headerName: literals.forms.lp_symbol_form.ms_description,
            field: 'description',
            cellEditor: 'agLargeTextCellEditor',
            headerComponent: 'cellHeaderComponent',
            tooltipField: 'description',
          },
        ],
        frameworkComponents: {
          boolRenderer: BoolRenderer,
          stateRenderer: StateRenderer,
          stateHeaderComponent: StateHeaderComponent,
          cellHeaderComponent: HeaderComponent,
          sessionRenderer: SessionRenderer,
          sessionHeaderComponent: SessionHeaderComponent,
        },
        rowData: [],
        rowOldData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        cellEditUpdate: props.context.cellEditUpdateDetail[props.node.id],
        getRowNodeId(data) {
          return data.unisymbol;
        },
      };

      this.state.rowID = props.node.id ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff') : props.node.id;
      this.gridWrapperID = 'grid-wrapper-sidebar_lp_session';
      this.myGridID = `myGrid_sidebar_lp_symbol_${this.state.rowID}`;
      this.state.masterGridApi = props.api;
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridInfo.columnApi) {
        const { literals } = this.props;
        if (literals.forms.lp_symbol_form.lp_symbol !== prevProps.literals.forms.lp_symbol_form.lp_symbol) {
          this.gridInfo.columnApi.getColumn('lp_symbol').getColDef().headerName
            = literals.forms.lp_symbol_form.lp_symbol;
          this.gridInfo.columnApi.getColumn('unisymbol').getColDef().headerName
            = literals.forms.lp_symbol_form.unisymbol;
          this.gridInfo.columnApi.getColumn('security').getColDef().headerName = literals.forms.lp_symbol_form.security;
          this.gridInfo.columnApi.getColumn('base').getColDef().headerName = literals.forms.lp_symbol_form.base;
          this.gridInfo.columnApi.getColumn('quote').getColDef().headerName = literals.forms.lp_symbol_form.quote;
          this.gridInfo.columnApi.getColumn('expiry_date').getColDef().headerName
            = literals.forms.lp_symbol_form.expiry_date;
          this.gridInfo.columnApi.getColumn('contract_size').getColDef().headerName
            = literals.forms.lp_symbol_form.contract_size;
          this.gridInfo.columnApi.getColumn('digits').getColDef().headerName = literals.forms.lp_symbol_form.digits;
          this.gridInfo.columnApi.getColumn('sessions').getColDef().headerName = literals.forms.lp_symbol_form.sessions;
          this.gridInfo.columnApi.getColumn('markup_bid').getColDef().headerName
            = literals.forms.lp_symbol_form.markup_bid;
          this.gridInfo.columnApi.getColumn('markup_ask').getColDef().headerName
            = literals.forms.lp_symbol_form.markup_ask;
          this.gridInfo.columnApi.getColumn('depth').getColDef().headerName = literals.forms.lp_symbol_form.depth;
          this.gridInfo.columnApi.getColumn('sub_volumes').getColDef().headerName
            = literals.forms.lp_symbol_form.sub_volumes;
          this.gridInfo.columnApi.getColumn('commission').getColDef().headerName
            = literals.forms.lp_symbol_form.commission;
          this.gridInfo.columnApi.getColumn('min_size').getColDef().headerName = literals.forms.lp_symbol_form.min_size;
          this.gridInfo.columnApi.getColumn('a_step').getColDef().headerName = literals.forms.lp_symbol_form.a_step;
          this.gridInfo.columnApi.getColumn('consume_value').getColDef().headerName
            = literals.forms.lp_symbol_form.consume;
          this.gridInfo.columnApi.getColumn('allow_sweep_value').getColDef().headerName
            = literals.forms.lp_symbol_form.allow_sweep;
          this.gridInfo.columnApi.getColumn('timeout_warn').getColDef().headerName
            = literals.forms.lp_symbol_form.timeout_warn;
          this.gridInfo.columnApi.getColumn('timeout_error').getColDef().headerName
            = literals.forms.lp_symbol_form.timeout_error;
          this.gridInfo.columnApi.getColumn('timeout_kill').getColDef().headerName
            = literals.forms.lp_symbol_form.timeout_kill;
          this.gridInfo.columnApi.getColumn('state_value').getColDef().headerName
            = literals.forms.lp_symbol_form.state;
          this.gridInfo.columnApi.getColumn('timeout_delay').getColDef().headerName
            = literals.forms.lp_symbol_form.timeout_delay;
          this.gridInfo.columnApi.getColumn('multi_req_trade').getColDef().headerName
            = literals.forms.lp_symbol_form.multi_req_trade;
          this.gridInfo.columnApi.getColumn('feed_side_value').getColDef().headerName
            = literals.forms.lp_symbol_form.feed_side;
          this.gridInfo.columnApi.getColumn('queue_timeout').getColDef().headerName
            = literals.forms.lp_symbol_form.queue_timeout;
          this.gridInfo.columnApi.getColumn('disable_b_on_delay_value').getColDef().headerName
            = literals.forms.lp_symbol_form.disable_b_on_delay;
          this.gridInfo.columnApi
            .getColumn('uni_description')
            .getColDef().headerName = literals.forms.lp_symbol_form.description;
          this.gridInfo.columnApi.getColumn('description').getColDef().headerName
            = literals.forms.lp_symbol_form.ms_description;
          this.gridInfo.api.refreshHeader();
        }
      }
    }

    componentWillUnmount = () => {
      if (this.gridInfo) {
        window[`colStateLPSession${this.state.rowID}`] = this.gridInfo.columnApi.getColumnState();
        window[`groupStateLPSession${this.state.rowID}`] = this.gridInfo.columnApi.getColumnGroupState();
        window[`filterStateLPSession${this.state.rowID}`] = this.gridInfo.api.getFilterModel();
      }

      const detailGridId = this.createDetailGridId();

      // ag-Grid is automatically destroyed
      this.state.masterGridApi.removeDetailGridInfo(detailGridId);
    };

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridInfo.api.refreshCells({ rowNodes: [params.node], columns: ['unisymbol'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridInfo.api.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'state_value') {
          params.data.state =
            this.props.context.formData.StateObj[params.newValue].value;
        }
        if (params.colDef.field === 'consume_value') {
          params.data.consume =
            this.props.context.formData.BoolObj[params.newValue].value;
        }
        if (params.colDef.field === 'allow_sweep_value') {
          params.data.allow_sweep =
            this.props.context.formData.BoolObj[params.newValue].value;
        }
        if (params.colDef.field === 'feed_side_value') {
          params.data.feed_side =
            this.props.context.formData.FeedSideObj[params.newValue].value;
        }
        if (params.colDef.field === 'disable_b_on_delay_value') {
          params.data.disable_b_on_delay =
            this.props.context.formData.BoolObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
        this.props.context.updateStateDetail(
          this.state.rowID,
          true, this.rowsToUpdate, this.stylesToUpdate,
        );
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onGridReady(params) {
      const detailGridId = this.createDetailGridId();

      this.gridInfo = {
        id: detailGridId,
        api: params.api,
        columnApi: params.columnApi,
      };
      this.gridInfo.api.showLoadingOverlay();
      this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfo);
      this.gridInfo.columnApi.getColumn('security').getColDef().cellEditorParams.values =
          this.props.context.formData.Security ?
            this.props.context.formData.Security.map(a => a.label) : [];
      this.gridInfo.columnApi.getColumn('feed_side_value').getColDef().cellEditorParams.values =
        this.props.context.formData.FeedSide ?
          this.props.context.formData.FeedSide.map(a => a.label) : [];
      if (window[`colStateLPSession${this.state.rowID}`]) {
        this.gridInfo.columnApi.setColumnState(window[`colStateLPSession${this.state.rowID}`]);
      }
      if (window[`groupStateLPSession${this.state.rowID}`]) {
        this.gridInfo.columnApi.setColumnGroupState(window[`groupStateLPSession${this.state.rowID}`]);
      }
      if (window[`filterStateLPSession${this.state.rowID}`]) {
        this.gridInfo.api.setFilterModel(window[`filterStateLPSession${this.state.rowID}`]);
      }
      this.setState({ rowData: this.props.data.lp_symbols });
      this.setState({ rowOldData: JSON.parse(JSON.stringify(this.props.data.lp_symbols)) });
      this.onGridSizeChanged(params);
    }

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: exportColumns,
        fileName: 'MakerSymbol.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridInfo.api.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: exportColumns,
        fileName: 'MakerSymbol.csv',
      };
      this.gridInfo.api.exportDataAsCsv(params);
    };

    createDetailGridId = () => `detail_${this.state.rowID}`;

    updateLPSymbol=() => {
      this.props.context.showLoader();
      const bulkData = [];
      const rowData = _.keyBy(this.state.rowOldData, 'unisymbol');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.unisymbol];
        const dataToUpdate = {
          unisymbol: values.unisymbol,
          security: values.security,
          lp_symbol: values.lp_symbol,
          lp_session: values.lp_session,
          base: values.base,
          quote: values.quote,
          expiry_date: parseInt(values.expiry_date, 10),
          contract_size: parseFloat(values.contract_size),
          digits: parseInt(values.digits, 10),
          sessions: values.sessions,
          markup_bid: parseFloat(values.markup_bid),
          markup_ask: parseFloat(values.markup_ask),
          depth: parseInt(values.depth, 10),
          sub_volumes: values.sub_volumes,
          commission: parseFloat(values.commission),
          min_size: parseFloat(values.min_size),
          a_step: parseFloat(values.a_step),
          consume: parseInt(values.consume, 10),
          allow_sweep: parseInt(values.allow_sweep, 10),
          timeout_warn: parseInt(values.timeout_warn, 10),
          timeout_error: parseInt(values.timeout_error, 10),
          timeout_kill: parseInt(values.timeout_kill, 10),
          state: parseInt(values.state, 10),
          timeout_delay: parseInt(values.timeout_delay, 10),
          margin_perc: parseInt(values.margin_perc, 10),
          multi_req_trade: parseInt(values.multi_req_trade, 10),
          queue_size: parseInt(values.queue_size, 10),
          queue_action: parseInt(values.queue_action, 10),
          feed_side: parseInt(values.feed_side, 10),
          queue_timeout: parseInt(values.queue_timeout, 10),
          disable_b_on_delay: parseInt(values.disable_b_on_delay, 10),
          description: values.description,
          old_data: {
            unisymbol: oldRow.unisymbol,
            security: oldRow.security,
            lp_symbol: oldRow.lp_symbol,
            lp_session: oldRow.lp_session,
            base: oldRow.base,
            quote: oldRow.quote,
            expiry_date: parseInt(oldRow.expiry_date, 10),
            contract_size: parseFloat(oldRow.contract_size),
            digits: parseInt(oldRow.digits, 10),
            sessions: oldRow.sessions,
            markup_bid: parseFloat(oldRow.markup_bid),
            markup_ask: parseFloat(oldRow.markup_ask),
            depth: parseInt(oldRow.depth, 10),
            sub_volumes: oldRow.sub_volumes,
            commission: parseFloat(oldRow.commission),
            min_size: parseFloat(oldRow.min_size),
            a_step: parseFloat(oldRow.a_step),
            consume: parseInt(oldRow.consume, 10),
            allow_sweep: parseInt(oldRow.allow_sweep, 10),
            timeout_warn: parseInt(oldRow.timeout_warn, 10),
            timeout_error: parseInt(oldRow.timeout_error, 10),
            timeout_kill: parseInt(oldRow.timeout_kill, 10),
            state: parseInt(oldRow.state, 10),
            timeout_delay: parseInt(oldRow.timeout_delay, 10),
            margin_perc: parseInt(oldRow.margin_perc, 10),
            multi_req_trade: parseInt(oldRow.multi_req_trade, 10),
            queue_size: parseInt(oldRow.queue_size, 10),
            queue_action: parseInt(oldRow.queue_action, 10),
            feed_side: parseInt(oldRow.feed_side, 10),
            queue_timeout: parseInt(oldRow.queue_timeout, 10),
            disable_b_on_delay: parseInt(oldRow.disable_b_on_delay, 10),
            description: oldRow.description,
          },
        };
        bulkData.push(dataToUpdate);
      });
      axios.put(`${process.env.REACT_APP_BACKEND_API}/security/lp_symbol_bulk`, JSON.stringify(bulkData))
        .then((response) => {
          this.resetLPSymbol();
          showNotification(
            this.props.literals.sidebar.lp_symbol, 'primary',
            this.props.literals.forms.completed_message,
          );
          if (response.data.length > 0) {
            showNotification(
              this.props.literals.sidebar.lp_symbol, 'danger',
              `${this.props.literals.forms.fail_message} - ${response.data.toString()}}`,
            );
          }
        })
        .catch((error) => {
          this.resetLPSymbol();
          showNotification(
            this.props.literals.sidebar.lp_symbol, 'danger',
            `${this.props.literals.forms.fail_message} - ${error.message}}`,
          );
        });
    };

    resetLPSymbol=() => {
      this.rowsToUpdate = {};
      this.setState({ cellEditUpdate: false });
      this.props.context.resetLPSession();
      window[`colStateLPSession${this.state.rowID}`] = this.gridInfo.columnApi.getColumnState();
      window[`groupStateLPSession${this.state.rowID}`] = this.gridInfo.columnApi.getColumnGroupState();
      window[`filterStateLPSession${this.state.rowID}`] = this.gridInfo.api.getFilterModel();
      const stylesToUpdate = { ...this.stylesToUpdate };
      this.stylesToUpdate = {};
      Object.keys(stylesToUpdate).forEach((keys) => {
        const node = this.gridInfo.api.getRowNode(keys);
        if (node) {
          this.gridInfo.api.refreshCells({ rowNodes: [node], columns: ['unisymbol'], force: true });
          Object.keys(stylesToUpdate[keys]).forEach((values) => {
            this.gridInfo.api.refreshCells({ rowNodes: [node], columns: [values], force: true });
          });
        }
      });
      this.props.context.updateStateDetail(
        this.state.rowID,
        false, this.rowsToUpdate, this.stylesToUpdate,
      );
    };

    render() {
      const { literals } = this.props;
      return (
        <div style={{
 boxSizing: 'border-box', width: '100%', height: '100%',
}}
        >
          <div className="outer-grid-label">
            {literals.sidebar.lp_symbol} ({literals.tables.ag_table.total_records}: {this.state.rowData.length})
          </div>
          <div className="outer-grid-button">
            <ButtonToolbar>
              <Tooltip title={literals.tables.ag_table.save}>
                <Button
                  style={{
                                display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                  onClick={this.updateLPSymbol}
                  className="icon"
                  color="primary"
                ><p className="fa fa-save" />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.undo}>
                <Button
                  style={{
                                display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                  onClick={() => {
                    this.props.context.showLoader();
                    this.resetLPSymbol();
                  }}
                  className="icon"
                  color="primary"
                ><p
                  className="fas fa-undo"
                />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.export_excel}>
                <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                  <p className="fa fa-file-excel" />
                </Button>
              </Tooltip>
              <Tooltip title={literals.tables.ag_table.export_csv}>
                <Button
                  onClick={this.onBtExportCSV}
                  className="icon"
                  color="primary"
                >
                  <p className="fa fa-file-alt" />
                </Button>
              </Tooltip>
            </ButtonToolbar>
          </div>
          <div
            id={this.myGridID}
            style={{ width: '100%', height: this.props.node.rowHeight - REDUCE_HEIGHT, clear: 'both' }}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.colDefs}
              stopEditingWhenCellsLoseFocus
              suppressCellSelection
              suppressContextMenu
              immutableData
              getRowNodeId={this.state.getRowNodeId}
              frameworkComponents={this.state.frameworkComponents}
              defaultColDef={this.state.defaultColDef}
              onColumnResized={this.onColumnResized}
              onCellValueChanged={this.onCellValueChanged}
              rowData={this.state.rowData}
              onGridReady={this.onGridReady}
              onGridSizeChanged={this.onGridSizeChanged}
              rowHeight={20}
              context={{
                formData: this.props.context.formData,
                showLoader: this.props.context.showLoader,
                hideLoader: this.props.context.hideLoader,
                theme: this.props.theme,
                onCellValueChanged: this.onCellValueChanged,
              }}
            />
          </div>
        </div>

      );
    }
}

export default connect(state => ({
  literals: state.literals,
  theme: state.theme,
}))(withRouter(LPSessionDetailCellRenderer));
