/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderInputNumberField from '../../shared/components/form/NumberInput';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class OpenOrderReplaceForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleReplace: PropTypes.func.isRequired,
    update_quantity: PropTypes.bool,
    update_price: PropTypes.bool,
  };
  static defaultProps = {
    update_quantity: false,
    update_price: false,
  };
  render() {
    const { handleSubmit, literals } = this.props;
    const { submit } = literals.forms.uni_symbol_form;
    return (
      <form className="form  form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label" />
          <div className="form__form-group-field">
            <span className="marginLeftAuto">
              <Field
                name="update_quantity"
                component={renderCheckBoxField}
                label={`${literals.tables.ag_table.update} ${literals.tables.open_order_table.volume}`}
              />
            </span>
          </div>
          <span className="form__form-group-label">
            {literals.tables.open_order_table.volume}
          </span>
          <div className="form__form-group-field">
            <Field
              name="quantity"
              component={renderInputNumberField}
              step={1}
              min={0}
              disabled={!this.props.update_quantity}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label" />
          <div className="form__form-group-field">
            <span className="marginLeftAuto">
              <Field
                name="update_price"
                component={renderCheckBoxField}
                label={`${literals.tables.ag_table.update} ${literals.tables.open_order_table.price}`}
              />
            </span>
          </div>
          <span className="form__form-group-label">
            {literals.tables.open_order_table.price}
          </span>
          <div className="form__form-group-field">
            <Field
              name="price"
              component={renderInputNumberField}
              step={1}
              min={0}
              disabled={!this.props.update_price}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar form__button-toolbar-remove-top-padding">
          <Button color="primary" type="submit">
            {submit}
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => this.props.toggleReplace()}
          >
            {literals.forms.uni_symbol_form.cancel}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}
const selector = formValueSelector('open_order_replace_form');
function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    theme: state.theme,
    update_quantity: selector(state, 'update_quantity'),
    update_price: selector(state, 'update_price'),
    initialValues: {
      quantity: ownProps.node.data.volume,
      price: ownProps.node.data.price,
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'open_order_replace_form', // a unique identifier for this form
})(OpenOrderReplaceForm));
