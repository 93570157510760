/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import AggregatorRestartValidate from './AggregatorRestartValidate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import renderInputNumberField from '../../shared/components/form/NumberInputCommon';

const renderField = ({
  input,
  placeholder,
  disabled,
  meta: { touched, error }
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <textarea {...input} placeholder={placeholder} disabled={disabled} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false
};

@inject('rootStore')
class AggregatorRestartForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    state: PropTypes.instanceOf(Object).isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };
  render() {
    const { handleSubmit, literals } = this.props;
    const disabled = false;
    const { submit } = literals.forms.aggregator_restart_form;
    const { role } = this.props.rootStore.authStore;
    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="reason"
              component={renderField}
              placeholder={literals.forms.aggregator_restart_form.reason}
              disabled={disabled}
            />
          </div>
        </div>
        {role === 'admin' && (
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.forms.aggregator_restart_form.type_of_restart}
            </span>
            <div className="form__form-group-field">
              <Field
                name="restart_type"
                component={renderRadioButtonField}
                label={literals.forms.aggregator_restart_form.logs}
                radioValue="logs"
              />
              <Field
                name="restart_type"
                component={renderRadioButtonField}
                label={literals.forms.aggregator_restart_form.no_logs}
                radioValue="no_logs"
                defaultChecked
              />
            </div>
          </div>
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">sleep (sec)</span>
          <div className="form__form-group-field">
            <Field
              name="sleep"
              component={renderInputNumberField}
              type="number"
              min={0}
              precision={1}
              disabled={disabled}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar form__button-toolbar-remove-top-padding">
          <Button color="primary" type="submit">
            {submit}
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => this.props.state.toggle()}
          >
            {literals.forms.aggregator_restart_form.cancel}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}
function mapStateToProps(state) {
  return {
    literals: state.literals,
    initialValues: {
      restart_type: 'no_logs',
      sleep: 2
    }
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'aggregator_restart_form', // a unique identifier for this form
    validate: AggregatorRestartValidate
  })(AggregatorRestartForm)
);
