/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import UserActionRenderer from './userActionRenderer';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class UserDetailCellRenderer extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      data: PropTypes.instanceOf(Object).isRequired,
      api: PropTypes.instanceOf(Object).isRequired,
      context: PropTypes.instanceOf(Object).isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.rowsToUpdate = props.context.rowsToUpdateDetail[props.node.id] || {};
      this.stylesToUpdate = props.context.stylesToUpdateDetail[props.node.id] || {};
      const { literals } = this.props;
      this.state = {
        modules: AllModules,
        colDefs: [
          {
            headerName: literals.forms.user_form.username,
            field: 'username',
            editable: false,
            pinned: 'left',
            cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.first_name,
            field: 'first_name',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.last_name,
            field: 'last_name',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.client,
            field: 'client_value',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: [],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.role,
            field: 'role',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: ['admin', 'broker', 'client'],
            },
            cellClassRules: { 'edit-row-color': this.onCellClass },
          },
          {
            headerName: literals.forms.user_form.email,
            field: 'email',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.phone,
            field: 'phone',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.forms.user_form.email_notify,
            field: 'email_notify',
            cellEditor: 'agLargeTextCellEditor',
            cellClassRules: { 'edit-row-color': this.onCellClass },
            width: 60,
            minWidth: 60,
          },
          {
            headerName: literals.tables.ag_table.actions,
            field: 'actions',
            cellRenderer: 'actionRenderer',
            filter: false,
            editable: false,
          },
        ],
        frameworkComponents: {
          actionRenderer: UserActionRenderer,
        },
        rowData: [],
        rowOldData: [],
        defaultColDef: {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          editable: true,
          suppressMenu: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
        },
        cellEditUpdate: props.context.cellEditUpdateDetail[props.node.id],
        getRowNodeId(data) {
          return data.username;
        },
      };

      this.state.rowID = props.node.id ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff') : props.node.id;
      this.gridWrapperID = 'grid-wrapper-sidebar_client';
      this.myGridID = `myGrid_sidebar_user_${this.state.rowID}`;
      this.state.masterGridApi = props.api;
      this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridInfo.columnApi) {
        const { literals } = this.props;
        if (literals.forms.user_form.username !== prevProps.literals.forms.user_form.username) {
          this.gridInfo.columnApi.getColumn('username').getColDef().headerName
                    = literals.forms.user_form.username;
          this.gridInfo.columnApi.getColumn('first_name').getColDef().headerName
                    = literals.forms.user_form.first_name;
          this.gridInfo.columnApi.getColumn('last_name').getColDef().headerName
                    = literals.forms.user_form.last_name;
          this.gridInfo.columnApi.getColumn('client_value').getColDef().headerName
            = literals.forms.user_form.client;
          this.gridInfo.columnApi.getColumn('role').getColDef().headerName
                    = literals.forms.user_form.role;
          this.gridInfo.columnApi.getColumn('email').getColDef().headerName
                    = literals.forms.user_form.email;
          this.gridInfo.columnApi.getColumn('phone').getColDef().headerName
                    = literals.forms.user_form.phone;
          this.gridInfo.columnApi.getColumn('email_notify').getColDef().headerName
            = literals.forms.user_form.email_notify;
          this.gridInfo.columnApi.getColumn('actions').getColDef().headerName = literals.tables.ag_table.actions;
          this.gridInfo.api.refreshHeader();
        }
      }
    }

    componentWillUnmount = () => {
      if (this.gridInfo) {
        window[`colStateUser${this.state.rowID}`] = this.gridInfo.columnApi.getColumnState();
        window[`groupStateUser${this.state.rowID}`] = this.gridInfo.columnApi.getColumnGroupState();
        window[`filterStateUser${this.state.rowID}`] = this.gridInfo.api.getFilterModel();
      }

      const detailGridId = this.createDetailGridId();

      // ag-Grid is automatically destroyed
      this.state.masterGridApi.removeDetailGridInfo(detailGridId);
    };

    onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
      typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

    onCellValueChanged=(params) => {
      if (params.oldValue != params.value) {
        if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
          this.stylesToUpdate[params.node.id] = {};
          this.gridInfo.api.refreshCells({ rowNodes: [params.node], columns: ['username'], force: true });
        }
        this.stylesToUpdate[params.node.id][params.colDef.field] = {};
        this.gridInfo.api.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

        if (params.colDef.field === 'client_value') {
          params.data.client =
            this.props.context.formData.ClientObj[params.newValue].value;
        }
        this.rowsToUpdate[`${params.node.id}`] = params.data;
        this.setState({ cellEditUpdate: true });
        this.props.context.updateStateDetail(
          this.state.rowID,
          true, this.rowsToUpdate, this.stylesToUpdate,
        );
      }
    };

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onGridReady(params) {
      const detailGridId = this.createDetailGridId();

      this.gridInfo = {
        id: detailGridId,
        api: params.api,
        columnApi: params.columnApi,
      };
      this.gridInfo.api.showLoadingOverlay();
      this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfo);

      this.gridInfo.columnApi.getColumn('client_value').getColDef().cellEditorParams.values =
        this.props.context.formData.Client ?
          this.props.context.formData.Client.map(a => a.label) : [];

      if (window[`colStateUser${this.state.rowID}`]) {
        this.gridInfo.columnApi.setColumnState(window[`colStateUser${this.state.rowID}`]);
      }
      if (window[`groupStateUser${this.state.rowID}`]) {
        this.gridInfo.columnApi.setColumnGroupState(window[`groupStateUser${this.state.rowID}`]);
      }
      if (window[`filterStateUser${this.state.rowID}`]) {
        this.gridInfo.api.setFilterModel(window[`filterStateUser${this.state.rowID}`]);
      }
      this.setState({ rowData: this.props.data.users });
      this.setState({ rowOldData: JSON.parse(JSON.stringify(this.props.data.users)) });
      this.onGridSizeChanged(params);
    }

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onBtExportExcel=() => {
      const params = {
        columnKeys: ['username', 'first_name', 'last_name', 'client_value', 'role', 'email', 'phone', 'email_notify'],
        fileName: 'User.xlsx',
        suppressTextAsCDATA: true,
      };
      this.gridInfo.api.exportDataAsExcel(params);
    };

    onBtExportCSV=() => {
      const params = {
        columnKeys: ['username', 'first_name', 'last_name', 'client_value', 'role', 'email', 'phone', 'email_notify'],
        fileName: 'User.csv',
      };
      this.gridInfo.api.exportDataAsCsv(params);
    };

    createDetailGridId = () => `detail_${this.state.rowID}`;

    updateUser=() => {
      this.props.context.showLoader();
      const len = Object.keys(this.rowsToUpdate).length;
      let i = 0;
      let j = 0;
      const successItems = [];
      const failItems = [];
      const errorItems = [];
      const rowData = _.keyBy(this.state.rowOldData, 'username');
      Object.values(this.rowsToUpdate).forEach((values) => {
        const oldRow = rowData[values.username];
        const dataToUpdate = {
          username: values.username,
          first_name: values.first_name,
          last_name: values.last_name,
          client: values.client,
          role: values.role,
          email: values.email,
          email_notify: values.email_notify,
          phone: values.phone,
          old_data: {
            username: oldRow.username,
            first_name: oldRow.first_name,
            last_name: oldRow.last_name,
            client: oldRow.client,
            role: oldRow.role,
            email: oldRow.email,
            email_notify: oldRow.email_notify,
            phone: oldRow.phone,
          },
        };
        axios.put(`${process.env.REACT_APP_BACKEND_API}/admin/user`, JSON.stringify(dataToUpdate))
          .then((response) => {
            i += 1;
            if (response.data.status) {
              successItems.push(values.username);
            } else {
              failItems.push(values.username);
            }
            if (len === i) {
              if (successItems.length > 0) {
                showNotification(
                  this.props.literals.forms.user_form.title_update, 'primary',
                  `${this.props.literals.forms.success_message} - ${successItems.join(', ')}`,
                );
              }
              if (failItems.length > 0) {
                showNotification(
                  this.props.literals.forms.user_form.title_update, 'danger',
                  `${this.props.literals.forms.fail_message} - ${failItems.join(', ')}`,
                );
              }
              this.resetUser();
            }
          })
          .catch((error) => {
            j += 1;
            errorItems.push(values.username);
            if (len === j) {
              if (errorItems.length > 0) {
                showNotification(
                  this.props.literals.forms.user_form.title_update,
                  'danger', `${error.message} - ${errorItems.join(', ')}`,
                );
              }
              this.resetUser();
            }
          });
      });
    };

  resetUser=() => {
    this.rowsToUpdate = {};
    this.setState({ cellEditUpdate: false });
    this.props.context.resetClient();
    window[`colStateUser${this.state.rowID}`] = this.gridInfo.columnApi.getColumnState();
    window[`groupStateUser${this.state.rowID}`] = this.gridInfo.columnApi.getColumnGroupState();
    window[`filterStateUser${this.state.rowID}`] = this.gridInfo.api.getFilterModel();
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate).forEach((keys) => {
      const node = this.gridInfo.api.getRowNode(keys);
      if (node) {
        this.gridInfo.api.refreshCells({ rowNodes: [node], columns: ['username'], force: true });
        Object.keys(stylesToUpdate[keys]).forEach((values) => {
          this.gridInfo.api.refreshCells({ rowNodes: [node], columns: [values], force: true });
        });
      }
    });
    this.props.context.updateStateDetail(
      this.state.rowID,
      false, this.rowsToUpdate, this.stylesToUpdate,
    );
  };

  render() {
    const { literals } = this.props;
    return (
      <div style={{
boxSizing: 'border-box', width: '100%', height: '100%',
}}
      >
        <div className="outer-grid-label">
          {literals.sidebar.user} ({literals.tables.ag_table.total_records}: {this.state.rowData.length})
        </div>
        <div className="outer-grid-button">
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.save}>
              <Button
                style={{
                                display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                onClick={this.updateUser}
                className="icon"
                color="primary"
              ><p className="fa fa-save" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.undo}>
              <Button
                style={{
                                display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                onClick={() => {
                  this.props.context.showLoader();
                  this.resetUser();
                }}
                className="icon"
                color="primary"
              ><p
                className="fas fa-undo"
              />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_csv}>
              <Button
                onClick={this.onBtExportCSV}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-alt" />
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </div>
        <div
          id={this.myGridID}
          style={{
 width: '100%',
height: this.props.node.rowHeight - REDUCE_HEIGHT,
clear: 'both',
}}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.colDefs}
            stopEditingWhenCellsLoseFocus
            suppressCellSelection
            suppressContextMenu
            immutableData
            getRowNodeId={this.state.getRowNodeId}
            frameworkComponents={this.state.frameworkComponents}
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResized}
            onCellValueChanged={this.onCellValueChanged}
            rowData={this.state.rowData}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.onGridSizeChanged}
            rowHeight={20}
            context={{
              resetClient: this.props.context.resetClient,
              showLoader: this.props.context.showLoader,
              hideLoader: this.props.context.hideLoader,
            }}
          />
        </div>
      </div>

    );
  }
}

export default connect(state => ({
  literals: state.literals,
}))(withRouter(UserDetailCellRenderer));

