import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import TakerNotionalSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized
} from '../../shared/helper';
import Loader from '../../shared/components/Loader';

const REDUCE_HEIGHT = 200;

class LoginSpread extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired
  };
  constructor(props) {
    super(props);
    const tableID = 'sidebar_login_spread_client';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    const { literals } = props;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.login_spread_table.ext_login,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.login_spread_table.ext_login'),
          field: 'ext_login',
          headerTooltip: literals.tables.login_spread_table.ext_login,
          type: 'text'
        },
        {
          headerName: literals.tables.login_spread_table.symbol,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.login_spread_table.symbol'),
          field: 'symbol',
          headerTooltip: literals.tables.login_spread_table.symbol,
          minWidth: 90,
          type: 'text'
        },
        {
          headerName: literals.tables.login_spread_table.ext_groups,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.login_spread_table.ext_groups'
            ),
          field: 'ext_groups',
          headerTooltip: literals.tables.login_spread_table.ext_groups,
          minWidth: 90,
          type: 'text'
        },
        {
          headerName: literals.tables.login_spread_table.notional_usd,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.login_spread_table.notional_usd'
            ),
          field: 'notional_usd',
          headerTooltip: literals.tables.login_spread_table.notional_usd,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          aggFunc: (params) => {
            let total = 0;
            params.values.forEach((value) => (total += value));
            return Math.round((total + Number.EPSILON) * 100) / 100;
          }
        },
        {
          headerName: literals.tables.login_spread_table.spread_usd,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.login_spread_table.spread_usd'
            ),
          field: 'spread_usd',
          headerTooltip: literals.tables.login_spread_table.spread_usd,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number',
          aggFunc: (params) => {
            let total = 0;
            params.values.forEach((value) => (total += value));
            return Math.round((total + Number.EPSILON) * 100) / 100;
          }
        },
        {
          headerName: literals.tables.login_spread_table.avg_spread_points,
          headerValueGetter: () =>
            this.localizeHeader(
              'literals.tables.login_spread_table.avg_spread_points'
            ),
          field: 'avg_spread_points',
          headerTooltip: literals.tables.login_spread_table.avg_spread_points,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          type: 'number'
        }
      ],
      rowData: [],
      collapse: '',
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter'
        }
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: ['filterMenuTab'],
        suppressMovable: true,
        width: 50,
        minWidth: 50
      },
      getRowNodeId(data) {
        return data.ext_login + data.symbol;
      },
      height: window.innerHeight,
      loaderShow: false,
      riskAccountTem: {}
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (
        literals.forms.security_form.name !==
        prevProps.literals.forms.security_form.name
      ) {
        this.gridApi.refreshHeader();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onGridSizeChanged(params);
    this.gridApi.refreshHeader();
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: [
        'ext_login',
        'symbol',
        'ext_groups',
        'notional_usd',
        'spread_usd',
        'avg_spread_points'
      ],
      fileName: 'LoginSpreadClient.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: [
        'ext_login',
        'symbol',
        'ext_groups',
        'notional_usd',
        'spread_usd',
        'avg_spread_points'
      ],
      fileName: 'LoginSpread.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  };

  handleSubmit = (values) => {
    this.gridApi.showLoadingOverlay();
    const tem = [];
    if (values.risk_account && values.risk_account.length > 0) {
      const raTEM = this.state.riskAccountTem;
      values.risk_account.forEach((ra) => {
        if (ra in raTEM) {
          tem.push(...raTEM[ra]);
        }
      });
    }
    let groups = [];
    if (values.group) {
      let arr = [];
      if (Array.isArray(values.group)) {
        arr = values.group;
      } else {
        arr = values.group.split(',');
      }
      groups = arr.map((element) => {
        return element.trim().toLowerCase();
      });
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/report/login_spread_client`,
        JSON.stringify({
          start_date: values.start_date
            ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf() *
              1000
            : values.start_date,
          end_date: values.end_date
            ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf() *
              1000
            : values.end_date,
          symbol: values.symbol,
          login: values.login
            ? values.login.split(',').map((item) => item.trim())
            : undefined,
          account: values.account,
          execution: [...(values.execution || []), ...tem],
          group: groups
        })
      )
      .then((response) => {
        this.setState({ rowData: response.data });
        this.gridApi.hideOverlay();
      })
      .catch(() => {
        this.gridApi.hideOverlay();
      });
    this.setState({ collapse: 'force-close' });
    setTimeout(() => {
      this.setState({ collapse: '' });
    }, 0);
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  localizeHeader = (header) => _.get(this.props, header);

  updateRiskAccountTEM = (riskAccountTem) => {
    this.setState({ riskAccountTem });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title card__title_custom">
              <h5 className="bold-text">
                {literals.sidebar.login_spread_client}
              </h5>
            </div>
            <Collapse
              title={literals.tables.ag_table.toggle_search}
              className="with-shadow"
              collapse={this.state.collapse}
            >
              <TakerNotionalSearchForm
                onSubmit={this.handleSubmit}
                showLoader={this.showLoader}
                hideLoader={this.hideLoader}
                onUpdateRiskAccountTEM={this.updateRiskAccountTEM}
              />
            </Collapse>
            <div className="outer-grid-label">
              {literals.tables.ag_table.total_records}:{' '}
              {this.state.rowData ? this.state.rowData.length : 0}
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip title={literals.tables.ag_table.export_excel}>
                  <Button
                    onClick={this.onBtExportExcel}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-excel" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.export_csv}>
                  <Button
                    onClick={this.onBtExportCSV}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-alt" />
                  </Button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div
              id={this.gridWrapperID}
              style={{ width: '100%', height: '100%', clear: 'both' }}
            >
              <div
                id={this.myGridID}
                style={{
                  boxSizing: 'border-box',
                  height: this.state.height - REDUCE_HEIGHT,
                  width: '100%'
                }}
                className={
                  theme === 'theme-light'
                    ? 'ag-theme-balham'
                    : 'ag-theme-balham-dark'
                }
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.columnDefs}
                  immutableData
                  suppressCellSelection
                  suppressContextMenu
                  defaultColDef={this.state.defaultColDef}
                  onColumnResized={this.onColumnResized}
                  getRowNodeId={this.state.getRowNodeId}
                  rowData={this.state.rowData}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.onGridSizeChanged}
                  rowHeight={20}
                  columnTypes={this.state.columnTypes}
                  groupIncludeTotalFooter
                />
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
  literals: state.literals
}))(LoginSpread);
