/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, Container, Row } from 'reactstrap';
import axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import AccountGroupForm from './form';
import accountGroupAction from './action';
import AccountForm from './accountForm';
import accountAction from './accountAction';
import RiskAccount from './riskAccount';
import AccountGroupList from './accountGroupList';
import Loader from '../../shared/components/Loader';

const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

@inject('rootStore')
class AccountGroup extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    constructor(props) {
      super(props);
      this.state = {
        rowOldData: [],
        rowOldDataRiskAccount: [],
        formData: [],
        activeTab: '1',
        loaderShow: false,
        gridApiAccountGroup: {},
        rowDataRiskAccount: [],
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      axios.get(`${process.env.REACT_APP_BACKEND_API}/account/group`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.AccountGroup)) });
          this.setState({ rowOldDataRiskAccount: JSON.parse(JSON.stringify(response.data.RiskAccount)) });
        })
        .catch(() => {
        });
    }
    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.account_group_form.name !== prevProps.literals.forms.account_group_form.name) {
          this.gridColumnApi.getColumn('name').getColDef().headerName = literals.forms.account_group_form.name;
          this.gridColumnApi.getColumn('assetclass_group').getColDef().headerName =
            literals.forms.account_group_form.asset_class_group;
          this.gridColumnApi.getColumn('limit_symbol_group').getColDef().headerName =
            literals.forms.account_group_form.limit_symbol_group;
          this.gridColumnApi.getColumn('state_value').getColDef().headerName
              = literals.forms.account_group_form.state;
          this.gridColumnApi.getColumn('description').getColDef().headerName
              = literals.forms.account_group_form.description;
          this.gridColumnApi.getColumn('actions').getColDef().headerName = literals.tables.ag_table.actions;
          this.gridApi.refreshHeader();
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    setGridApiAccountGroup = (gridApi) => {
      this.setState({ gridApiAccountGroup: gridApi });
    };

    setRiskAccountData=(data) => {
      this.setState({ rowDataRiskAccount: data });
    }

    updateData=(data) => {
      const unLinkedRiskAccounts = _.filter(
        data.RiskAccount,
        item => (!_.find(data.AccountGroup, { name: item.group })),
      );
      const accountGroups = data.AccountGroup;
      if (unLinkedRiskAccounts.length > 0) {
        accountGroups.push({ name: 'DETACHED' });
      }
      this.setState({
        formData: {
          State: data.State,
          StateObj: arrayToObject(data.State, 'label'),
          AccountGroup: data.AccountGroupEnum,
          AssetClassGroup: data.AssetClassGroup,
          LimitCurrencyGroup: data.LimitCurrencyGroup,
          LimitSymbolGroup: data.LimitSymbolGroup,
          Type: data.Type,
          TypeObj: arrayToObject(data.Type, 'label'),
          Category: data.Category,
          SquareOffMode: data.SquareOffMode,
          SquareOffModeObj: arrayToObject(data.SquareOffMode, 'label'),
          Taker: data.Taker,
          Maker: data.Maker,
          AccountGroupList: accountGroups,
          RiskAccount: data.RiskAccount,
          UnLinkedRiskAccount: unLinkedRiskAccounts,
          RiskAccountObj: _.groupBy(data.RiskAccount, 'group'),
        },
      });
    };

    resetAccountGroup=() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API}/account/group`)
        .then((response) => {
          this.updateData(response.data);
          this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.AccountGroup)) });
          this.setState({ rowOldDataRiskAccount: JSON.parse(JSON.stringify(response.data.RiskAccount)) });
          this.state.gridApiAccountGroup.refreshCells({
            columns: ['actions'], force: true,
          });
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };


  render() {
    const { literals } = this.props;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="tabs tabs--bordered-top tabs__custom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                                        this.toggle('1');
                                    }}
                    >
                      {literals.sidebar.account_group}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                                        this.toggle('2');
                                    }}
                    >
                      {literals.forms.account_group_form.title_create}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      {literals.forms.account_form.title_create}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Container>
                      <Row>
                        <AccountGroupList
                          formData={this.state.formData}
                          rowOldData={this.state.rowOldData}
                          showLoader={this.showLoader}
                          hideLoader={this.hideLoader}
                          resetAccountGroup={this.resetAccountGroup}
                          setGridApiAccountGroup={this.setGridApiAccountGroup}
                          setRiskAccountData={this.setRiskAccountData}
                        />
                      </Row>
                      <Row>
                        <RiskAccount
                          formData={this.state.formData}
                          rowOldDataRiskAccount={this.state.rowOldDataRiskAccount}
                          showLoader={this.showLoader}
                          hideLoader={this.hideLoader}
                          resetAccountGroup={this.resetAccountGroup}
                          rowDataRiskAccount={this.state.rowDataRiskAccount}
                        />
                      </Row>
                    </Container>
                  </TabPane>
                  <TabPane tabId="2">
                    <AccountGroupForm
                      {...this.props}
                      state={{
                                        formData: this.state.formData,
                                        resetAccountGroup: this.resetAccountGroup,
                                        toggle: this.toggle,
                                        showLoader: this.showLoader,
                                        hideLoader: this.hideLoader,
                                    }}
                      onSubmit={accountGroupAction}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <AccountForm
                      {...this.props}
                      state={{
                          formData: this.state.formData,
                          resetAccountGroup: this.resetAccountGroup,
                          toggle: this.toggle,
                          showLoader: this.showLoader,
                          hideLoader: this.hideLoader,
                        }}
                      onSubmit={accountAction}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(AccountGroup);
