/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const ra = [];
  const mra = [];
  if (values.risk_accounts) {
    values.risk_accounts.forEach((val) => {
      if (val.startsWith('master->')) {
        mra.push(val.replace('master->', ''));
      } else {
        ra.push(val);
      }
    });
  }
  const data = {
    name: values.name,
    risk_accounts: ra ? ra.join(',') : '',
    master_risk_accounts: mra ? mra.join(',') : '',
    description: values.description,
  };

  axios.post(`${process.env.REACT_APP_BACKEND_API}/account/master_risk_account`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        showNotification(
          props.literals.forms.master_risk_account_form.title_create, 'primary',
          props.literals.forms.success_message,
        );
        stateObj.resetMasterRiskAccount();
        stateObj.toggle('1');
      } else {
        showNotification(
          props.literals.forms.master_risk_account_form.title_create, 'danger',
          props.literals.forms.fail_message,
        );
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.master_risk_account_form.title_create, 'danger', error.message);
      stateObj.hideLoader();
    });
};
