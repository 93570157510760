/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import _ from 'lodash';
import RiskForm from './riskForm';
import userValidate from './userValidate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

class SessionEdit extends PureComponent {
  static propTypes = {
    toggleModalSession: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(Array),
    state: PropTypes.instanceOf(Object).isRequired,
    literals: LiteralProps.isRequired,
  };
    static defaultProps = {
      value: [],
    };
    componentDidMount() {
      setTimeout(() => {
        if (this.props.value && this.props.value.length > 0) {
          let defaultRisk = '0';
          const values = this.props.value.map((risk, i) => {
            const riskItem = {};
            if (risk.default_risk === 1) {
              defaultRisk = i.toString();
            }
            riskItem.alias = risk.alias;
            riskItem.read_only = risk.read_only === 1;
            riskItem.daily_statement = risk.daily_statement === 1;
            riskItem.ext_order_info = risk.ext_order_info === 1;
            const riskAccountItem = _.find(this.props.state.formData.RiskAccount, ['value', risk.risk_account]);
            riskItem.risk_account = riskAccountItem;
            const nodeItem = _.find(riskAccountItem ? riskAccountItem.node : [], ['value', risk.taker]);
            riskItem.taker = nodeItem;
            riskItem.tem = _.find(nodeItem ? nodeItem.account : [], ['value', risk.tem]);
            riskItem.taker_feed = _.find(nodeItem ? nodeItem.feed : [], ['value', risk.taker_feed]);
            return riskItem;
          });
          this.props.change('riskAccounts', values);
          this.props.change('default_risk', defaultRisk);
        }
      }, 0);
    }

    render() {
      return (
        <div>
          <form className="form form--vertical" onSubmit={this.props.handleSubmit}>
            <div className="borderValuesForm" style={{ width: '100%' }}>
              <FieldArray
                name="riskAccounts"
                component={RiskForm}
                props={this.props}
              />
            </div>
            <div style={{ overflow: 'hidden', width: '97%' }}>
              <ButtonToolbar className="float-right">
                <Button color="primary" type="submit">{this.props.literals.forms.submit}</Button>
                <Button color="primary" onClick={this.props.toggleModalSession}>
                  {this.props.literals.forms.cancel}
                </Button>
              </ButtonToolbar>
            </div>
          </form>
        </div>
      );
    }
}

const selector = formValueSelector('user_risk_table_form');

function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    defaultRisk: selector(state, 'default_risk'),
    initialValues: {
      riskAccounts: ownProps.value && ownProps.value.length > 0 ? ownProps.value.map(risk => ({
        ...risk,
        read_only: risk.read_only === 1,
        daily_statement: risk.daily_statement === 1,
        ext_order_info: risk.ext_order_info === 1,
      })) : [],
      default_risk: '0',
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'user_risk_table_form', // a unique identifier for this form
  enableReinitialize: true,
  validate: userValidate,
})(SessionEdit));

