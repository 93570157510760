import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { inject } from 'mobx-react';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import {
  LiteralProps,
  LiteralENGlobalProps
} from '../../../shared/prop-types/ReducerProps';

@inject('rootStore')
class SidebarContent extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    literalsENGlobal: LiteralENGlobalProps.isRequired,
    onClick: PropTypes.func.isRequired,
    changeSidebarCategoryCollapse: PropTypes.func.isRequired,
    sidebar: PropTypes.instanceOf(Object).isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    const { literals, literalsENGlobal } = this.props;
    const { role } = this.props.rootStore.authStore;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarCategory
            title={literals.sidebar.hub}
            icon="cogs"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="hub"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.security}
              titleEN={literalsENGlobal.sidebar.security}
              id="sidebar_security"
              route="/security/all"
              onClick={this.hideSidebar}
              icon="layer-group"
            />
            <SidebarLink
              title={literals.sidebar.uni_symbol}
              titleEN={literalsENGlobal.sidebar.uni_symbol}
              id="sidebar_uni_symbol"
              route="/security/uni_symbol"
              onClick={this.hideSidebar}
              icon="flag"
            />
            <SidebarLink
              title={literals.sidebar.aggregate_group}
              titleEN={literalsENGlobal.sidebar.aggregate_group}
              id="sidebar_aggregate_group"
              route="/security/aggregate_group"
              onClick={this.hideSidebar}
              icon="cog"
            />
            <SidebarLink
              title={literals.sidebar.markup_group}
              titleEN={literalsENGlobal.sidebar.markup_group}
              id="sidebar_markup_group"
              route="/security/markup_group"
              onClick={this.hideSidebar}
              icon="chart-line"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.lp}
            icon="cubes"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="makers"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.lp_session}
              titleEN={literalsENGlobal.sidebar.lp_session}
              id="sidebar_lp_session"
              route="/security/lp_session"
              onClick={this.hideSidebar}
              icon="compress-arrows-alt"
            />
            <SidebarLink
              title={literals.sidebar.maker_api_link}
              titleEN={literalsENGlobal.sidebar.maker_api_link}
              id="sidebar_maker_api_link"
              route="/security/maker_api_link"
              onClick={this.hideSidebar}
              icon="link"
            />
            <SidebarLink
              title={literals.sidebar.maker_scaling}
              titleEN={literalsENGlobal.sidebar.maker_scaling}
              id="sidebar_maker_scaling"
              route="/security/maker_scaling"
              onClick={this.hideSidebar}
              icon="steam-symbol"
              iconStyle="fab"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.node}
            icon="project-diagram"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="takers"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.node}
              titleEN={literalsENGlobal.sidebar.node}
              id="sidebar_node"
              route="/node/all"
              onClick={this.hideSidebar}
              icon="stream"
            />
            <SidebarLink
              title={literals.sidebar.node_feed}
              titleEN={literalsENGlobal.sidebar.node_feed}
              id="sidebar_node_feed"
              route="/node/feed"
              onClick={this.hideSidebar}
              icon="rss"
            />
            <SidebarLink
              title={literals.sidebar.node_account}
              titleEN={literalsENGlobal.sidebar.node_account}
              id="sidebar_node_account"
              route="/node/account"
              onClick={this.hideSidebar}
              icon="vector-square"
            />
            <SidebarLink
              title={literals.sidebar.taker_api_link}
              titleEN={literalsENGlobal.sidebar.taker_api_link}
              id="sidebar_taker_api_link"
              route="/node/taker_api_link"
              onClick={this.hideSidebar}
              icon="link"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.risk_account}
            icon="address-book"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="risk_account"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.account_group}
              titleEN={literalsENGlobal.sidebar.account_group}
              id="sidebar_account_group"
              route="/account/group"
              onClick={this.hideSidebar}
              icon="object-group"
            />
            <SidebarLink
              title={literals.sidebar.asset_class_group}
              titleEN={literalsENGlobal.sidebar.asset_class_group}
              id="sidebar_asset_class_group"
              route="/account/asset_class/group"
              onClick={this.hideSidebar}
              icon="object-ungroup"
            />
            <SidebarLink
              title={literals.sidebar.asset_class_symbol}
              titleEN={literalsENGlobal.sidebar.asset_class_symbol}
              id="sidebar_asset_class_symbol"
              route="/account/asset_class/symbol"
              onClick={this.hideSidebar}
              icon="vector-square"
            />
            <SidebarLink
              title={literals.sidebar.balance_transaction}
              titleEN={literalsENGlobal.sidebar.balance_transaction}
              id="sidebar_balance_transaction"
              route="/account/balance_transaction"
              onClick={this.hideSidebar}
              icon="cash-register"
            />
            <SidebarLink
              title={literals.sidebar.limit_symbol_group}
              titleEN={literalsENGlobal.sidebar.limit_symbol_group}
              id="sidebar_limit_symbol_group"
              route="/account/limit_symbol_group"
              onClick={this.hideSidebar}
              icon="funnel-dollar"
            />
            <SidebarLink
              title={literals.sidebar.master_risk_account}
              titleEN={literalsENGlobal.sidebar.master_risk_account}
              id="sidebar_master_risk_account"
              route="/account/master_risk_account"
              onClick={this.hideSidebar}
              icon="audible"
              iconStyle="fab"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.risk_user}
            icon="user-shield"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="risk_users"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.user}
              titleEN={literalsENGlobal.sidebar.user}
              id="sidebar_user"
              route="/broker/user"
              onClick={this.hideSidebar}
              icon="users"
            />
            <SidebarLink
              title={literals.sidebar.branded_statement}
              titleEN={literalsENGlobal.sidebar.branded_statement}
              id="sidebar_branded_statement"
              route="/broker/branded_statement"
              onClick={this.hideSidebar}
              icon="users"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.trade_copier}
            icon="cube"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="trade_copier"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.reverse_trade_copier}
              titleEN={literalsENGlobal.sidebar.reverse_trade_copier}
              id="sidebar_reverse_trade_copier"
              route="/trade_copier/reverse_trade_copier"
              onClick={this.hideSidebar}
              icon="snowflake"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.give_up_rule}
            icon="gg-circle"
            iconStyle="fab"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="give_up_rule"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.give_up_rule}
              titleEN={literalsENGlobal.sidebar.give_up_rule}
              id="sidebar_give_up_rule"
              route="/give_up_rule/all"
              onClick={this.hideSidebar}
              icon="stumbleupon-circle"
              iconStyle="fab"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.filtration_pool}
            icon="ring"
            iconStyle="fas"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="filtration_pool"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.filtration_pool}
              titleEN={literalsENGlobal.sidebar.filtration_pool}
              id="sidebar_filtration_pool"
              route="/filtration_pool/all"
              onClick={this.hideSidebar}
              icon="draw-polygon"
              iconStyle="fas"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.action_scheduler}
            icon="calendar-alt"
            iconStyle="fas"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="action_scheduler"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.action_scheduler}
              titleEN={literalsENGlobal.sidebar.action_scheduler}
              id="sidebar_action_scheduler"
              route="/action_scheduler/all"
              onClick={this.hideSidebar}
              icon="clock"
              iconStyle="fas"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.trading}
            icon="coins"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="trading"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.market_watch}
              titleEN={literalsENGlobal.sidebar.market_watch}
              id="sidebar_market_watch"
              route="/trading/market_watch"
              onClick={this.hideSidebar}
              icon="business-time"
            />
            <SidebarLink
              title={literals.sidebar.trading_platform}
              titleEN={literalsENGlobal.sidebar.trading_platform}
              id="sidebar_trading_platform"
              route="/trading/all"
              onClick={this.hideSidebar}
              icon="desktop"
            />
            <SidebarLink
              title={literals.sidebar.push_ticks}
              titleEN={literalsENGlobal.sidebar.push_ticks}
              id="sidebar_push_ticks"
              route="/trading/push_ticks"
              onClick={this.hideSidebar}
              icon="ticket-alt"
              display={role === 'admin' || role === 'broker'}
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.trading}
            icon="trading"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="trading"
            display={role === 'client'}
          >
            <SidebarLink
              title={literals.sidebar.market_watch}
              titleEN={literalsENGlobal.sidebar.market_watch}
              id="sidebar_market_watch"
              route="/trading/market_watch"
              onClick={this.hideSidebar}
              icon="market-watch"
            />
            <SidebarLink
              title={literals.sidebar.trading_platform}
              titleEN={literalsENGlobal.sidebar.trading_platform}
              id="sidebar_trading_platform"
              route="/trading/all"
              onClick={this.hideSidebar}
              icon="trading-platform"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.report}
            icon="chart-area"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="reports"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.position}
              titleEN={literalsENGlobal.sidebar.position}
              id="sidebar_position"
              route="/report/position"
              onClick={this.hideSidebar}
              icon="crosshairs"
            />
            <SidebarLink
              title={literals.sidebar.order}
              titleEN={literalsENGlobal.sidebar.order}
              id="sidebar_order"
              route="/report/order"
              onClick={this.hideSidebar}
              icon="dolly"
            />
            <SidebarLink
              title={literals.sidebar.order_maker}
              titleEN={literalsENGlobal.sidebar.order_maker}
              id="sidebar_order_maker"
              route="/report/order_maker"
              onClick={this.hideSidebar}
              icon="cart-arrow-down"
            />
            <SidebarLink
              title={literals.sidebar.giveup_order}
              titleEN={literalsENGlobal.sidebar.giveup_order}
              id="sidebar_giveup_order"
              route="/report/giveup_order"
              onClick={this.hideSidebar}
              icon="dolly"
            />
            <SidebarLink
              title={literals.sidebar.leg}
              titleEN={literalsENGlobal.sidebar.leg}
              id="sidebar_leg"
              route="/report/leg"
              onClick={this.hideSidebar}
              icon="network-wired"
            />
            <SidebarLink
              title={literals.sidebar.response}
              titleEN={literalsENGlobal.sidebar.response}
              id="sidebar_response"
              route="/report/response"
              onClick={this.hideSidebar}
              icon="ethernet"
            />
            <SidebarLink
              title={literals.sidebar.mt4reports}
              titleEN={literalsENGlobal.sidebar.mt4reports}
              id="sidebar_mt4reports"
              route="/report/mt4_report"
              onClick={this.hideSidebar}
              icon="file-contract"
            />
            <SidebarLink
              title={literals.sidebar.mt5reports}
              titleEN={literalsENGlobal.sidebar.mt5reports}
              id="sidebar_mt5reports"
              route="/report/mt5_report"
              onClick={this.hideSidebar}
              icon="file-signature"
            />
            <SidebarLink
              title={literals.sidebar.balance_transaction_report}
              titleEN={literalsENGlobal.sidebar.balance_transaction_report}
              id="sidebar_balance_transaction_report"
              route="/report/balance_transaction"
              onClick={this.hideSidebar}
              icon="money-check"
            />
            <SidebarLink
              title={literals.sidebar.raw_balance_transaction}
              titleEN={literalsENGlobal.sidebar.raw_balance_transaction}
              id="sidebar_raw_balance_transaction"
              route="/report/raw_balance_transaction"
              onClick={this.hideSidebar}
              icon="balance-scale"
            />
            <SidebarLink
              title={literals.sidebar.levels_advantage}
              titleEN={literalsENGlobal.sidebar.levels_advantage}
              id="sidebar_levels_advantage"
              route="/report/levels_advantage"
              onClick={this.hideSidebar}
              icon="money-check-alt"
            />
            <SidebarLink
              title={literals.sidebar.chart_maker_notional}
              titleEN={literalsENGlobal.sidebar.chart_maker_notional}
              id="sidebar_chart_maker_notional"
              route="/report/chart_maker_notional"
              onClick={this.hideSidebar}
              icon="chart-pie"
            />
            <SidebarLink
              title={literals.sidebar.chart_maker_symbol_notional}
              titleEN={literalsENGlobal.sidebar.chart_maker_symbol_notional}
              id="sidebar_chart_maker_symbol_notional"
              route="/report/chart_maker_symbol_notional"
              onClick={this.hideSidebar}
              icon="chart-bar"
              iconStyle="fas"
            />
            <SidebarLink
              title={literals.sidebar.chart_symbol_notional}
              titleEN={literalsENGlobal.sidebar.chart_symbol_notional}
              id="sidebar_chart_symbol_notional"
              route="/report/chart_symbol_notional"
              onClick={this.hideSidebar}
              icon="chart-bar"
              iconStyle="far"
            />
            <SidebarLink
              title={literals.sidebar.chart_symbol_detail_notional}
              titleEN={literalsENGlobal.sidebar.chart_symbol_detail_notional}
              id="sidebar_chart_symbol_detail_notional"
              route="/report/chart_symbol_detail_notional"
              onClick={this.hideSidebar}
              icon="chart-area"
            />
            <SidebarLink
              title={literals.sidebar.taker_notional}
              titleEN={literalsENGlobal.sidebar.taker_notional}
              id="sidebar_taker_notional"
              route="/report/taker_notional"
              onClick={this.hideSidebar}
              icon="stream"
            />
            <SidebarLink
              title={literals.sidebar.maker_notional}
              titleEN={literalsENGlobal.sidebar.maker_notional}
              id="sidebar_maker_notional"
              route="/report/maker_notional"
              onClick={this.hideSidebar}
              icon="compress-arrows-alt"
            />
            <SidebarLink
              title={literals.sidebar.symbol_notional}
              titleEN={literalsENGlobal.sidebar.symbol_notional}
              id="sidebar_symbol_notional"
              route="/report/symbol_notional"
              onClick={this.hideSidebar}
              icon="flag"
            />
            <SidebarLink
              title={literals.sidebar.markup_report}
              titleEN={literalsENGlobal.sidebar.markup_report}
              id="sidebar_markup_report"
              route="/report/markup"
              onClick={this.hideSidebar}
              icon="chart-line"
            />
            <SidebarLink
              title={literals.sidebar.markup_leg_report}
              titleEN={literalsENGlobal.sidebar.markup_leg_report}
              id="sidebar_markup_leg_report"
              route="/report/markup_leg"
              onClick={this.hideSidebar}
              icon="chart-line"
            />
            <SidebarLink
              title={literals.sidebar.slippage_report}
              titleEN={literalsENGlobal.sidebar.slippage_report}
              id="sidebar_slippage_report"
              route="/report/slippage"
              onClick={this.hideSidebar}
              icon="flag-checkered"
            />
            <SidebarLink
              title={literals.sidebar.slippage_leg_report}
              titleEN={literalsENGlobal.sidebar.slippage_leg_report}
              id="sidebar_slippage_leg_report"
              route="/report/slippage_leg"
              onClick={this.hideSidebar}
              icon="flag-checkered"
            />
            <SidebarLink
              title={literals.sidebar.net_per_login}
              titleEN={literalsENGlobal.sidebar.net_per_login}
              id="sidebar_net_per_login"
              route="/report/net_per_login"
              onClick={this.hideSidebar}
              icon="boxes"
            />
            <SidebarLink
              title={literals.sidebar.maker_symbol_status}
              titleEN={literalsENGlobal.sidebar.maker_symbol_status}
              id="sidebar_maker_symbol_status"
              route="/report/maker_symbol_status"
              onClick={this.hideSidebar}
              icon="bookmark"
            />
            <SidebarLink
              title={literals.sidebar.chart_maker_symbol_spread_history}
              titleEN={
                literalsENGlobal.sidebar.chart_maker_symbol_spread_history
              }
              id="sidebar_chart_maker_symbol_spread_history"
              route="/report/chart_maker_symbol_spread_history"
              onClick={this.hideSidebar}
              icon="chart-bar"
              iconStyle="fas"
            />
            <SidebarLink
              title={literals.sidebar.maker_rejection}
              titleEN={literalsENGlobal.sidebar.maker_rejection}
              id="sidebar_maker_rejection"
              route="/report/maker_rejection"
              onClick={this.hideSidebar}
              icon="bookmark"
            />
            <SidebarLink
              title={literals.sidebar.login_spread_client}
              titleEN={literalsENGlobal.sidebar.login_spread_client}
              id="sidebar_login_spread_client"
              route="/report/login_spread_client"
              onClick={this.hideSidebar}
              icon="boxes"
            />
            <SidebarLink
              title={literals.sidebar.login_spread_cover}
              titleEN={literalsENGlobal.sidebar.login_spread_cover}
              id="sidebar_login_spread_cover"
              route="/report/login_spread_cover"
              onClick={this.hideSidebar}
              icon="boxes"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.report}
            icon="reports"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="reports"
            display={role === 'client'}
          >
            <SidebarLink
              title={literals.sidebar.order}
              titleEN={literalsENGlobal.sidebar.order}
              id="sidebar_order"
              route="/report/order"
              onClick={this.hideSidebar}
              icon="orders"
            />
            <SidebarLink
              title={literals.sidebar.balance_transaction_report}
              titleEN={literalsENGlobal.sidebar.balance_transaction_report}
              id="sidebar_balance_transaction_report"
              route="/report/balance_transaction"
              onClick={this.hideSidebar}
              icon="makers"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.hybrid_model}
            icon="wave-square"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="settings"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.quant_based_hybrid}
              titleEN={literalsENGlobal.sidebar.quant_based_hybrid}
              id="sidebar_quant_based_hybrid"
              route="/settings/client_risk_profiling"
              onClick={this.hideSidebar}
              icon="weight"
            />
            <SidebarLink
              title={literals.sidebar.chart_bar_limit}
              titleEN={literalsENGlobal.sidebar.chart_bar_limit}
              id="sidebar_chart_bar_limit"
              route="/report/chart_bar_limit"
              onClick={this.hideSidebar}
              icon="chart-bar"
            />
            <SidebarLink
              title={literals.sidebar.chart_market_impact}
              titleEN={literalsENGlobal.sidebar.chart_market_impact}
              id="sidebar_chart_market_impact"
              route="/report/chart_market_impact"
              onClick={this.hideSidebar}
              icon="chart-line"
            />
            <SidebarLink
              title={literals.sidebar.concentration_per_login}
              titleEN={literalsENGlobal.sidebar.concentration_per_login}
              id="sidebar_concentration_per_login"
              route="/risk/concentration_per_login"
              onClick={this.hideSidebar}
              icon="money-bill-alt"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.monitoring}
            icon="tv"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="monitoring"
            display={role === 'admin' || role === 'broker'}
          >
            <SidebarLink
              title={literals.sidebar.logs}
              titleEN={literalsENGlobal.sidebar.logs}
              id="sidebar_logs"
              route="/monitoring/log"
              onClick={this.hideSidebar}
              icon="clipboard-list"
            />
            <SidebarLink
              title={literals.sidebar.taker_status}
              titleEN={literalsENGlobal.sidebar.taker_status}
              id="sidebar_taker_status"
              route="/monitoring/taker_status"
              onClick={this.hideSidebar}
              icon="stream"
            />
            <SidebarLink
              title={literals.sidebar.maker_status}
              titleEN={literalsENGlobal.sidebar.maker_status}
              id="sidebar_maker_status"
              route="/monitoring/maker_status"
              onClick={this.hideSidebar}
              icon="compress-arrows-alt"
            />
            <SidebarLink
              title={literals.sidebar.audit_log}
              titleEN={literalsENGlobal.sidebar.audit_log}
              id="sidebar_audit_log"
              route="/admin/audit_log"
              onClick={this.hideSidebar}
              icon="list-alt"
              display={role === 'admin'}
            />
            <SidebarLink
              title={literals.sidebar.access_log}
              titleEN={literalsENGlobal.sidebar.access_log}
              id="sidebar_access_log"
              route="/admin/access_log"
              onClick={this.hideSidebar}
              icon="th-list"
              display={role === 'admin'}
            />
            <SidebarLink
              title={literals.sidebar.urgency_headline_news}
              titleEN={literalsENGlobal.sidebar.urgency_headline_news}
              id="sidebar_urgency_headline_news"
              route="/monitoring/urgency_headline_news"
              onClick={this.hideSidebar}
              icon="newspaper"
            />
            <SidebarLink
              title={literals.sidebar.margin_level_warn}
              titleEN={literalsENGlobal.sidebar.margin_level_warn}
              id="sidebar_margin_level_warn"
              route="/monitoring/margin_level_warn"
              onClick={this.hideSidebar}
              icon="newspaper"
            />
          </SidebarCategory>
          <SidebarCategory
            title={literals.sidebar.admin}
            icon="user-cog"
            changeSidebarCategoryCollapse={
              this.props.changeSidebarCategoryCollapse
            }
            sidebar={this.props.sidebar}
            keyCategory="admin"
            display={role === 'admin'}
          >
            <SidebarLink
              title={literals.sidebar.client}
              titleEN={literalsENGlobal.sidebar.client}
              id="sidebar_client"
              route="/admin/client"
              onClick={this.hideSidebar}
              icon="user-friends"
            />
            <SidebarLink
              title={literals.sidebar.client_info}
              titleEN={literalsENGlobal.sidebar.client_info}
              id="sidebar_client_info"
              route="/admin/client_info"
              onClick={this.hideSidebar}
              icon="user-friends"
            />
            <SidebarLink
              title={literals.sidebar.server_instance}
              titleEN={literalsENGlobal.sidebar.server_instance}
              id="sidebar_server_instance"
              route="/admin/server_instance"
              onClick={this.hideSidebar}
              icon="server"
            />
            <SidebarLink
              title={literals.sidebar.config}
              titleEN={literalsENGlobal.sidebar.config}
              id="sidebar_config"
              route="/admin/config"
              onClick={this.hideSidebar}
              icon="sliders-h"
            />
            <SidebarLink
              title={literals.sidebar.lp}
              titleEN={literalsENGlobal.sidebar.lp}
              id="sidebar_lp"
              route="/admin/lp"
              onClick={this.hideSidebar}
              icon="ethernet"
            />
            <SidebarLink
              title={literals.sidebar.admin_position}
              titleEN={literalsENGlobal.sidebar.admin_position}
              id="sidebar_admin_position"
              route="/admin/position"
              onClick={this.hideSidebar}
              icon="crosshairs"
            />
            <SidebarLink
              title={literals.sidebar.commands}
              titleEN={literalsENGlobal.sidebar.commands}
              id="sidebar_commands"
              route="/admin/commands"
              onClick={this.hideSidebar}
              icon="terminal"
            />
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}

export default connect((state) => ({
  literals: state.literals,
  literalsENGlobal: state.literalsENGlobal
}))(SidebarContent);
