/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';
import { copyObject } from '../../helper';

class StateHeaderComponent extends PureComponent {
  static propTypes = {
    api: PropTypes.instanceOf(Object).isRequired,
    column: PropTypes.instanceOf(Object).isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
  };
  state = {
    selectedOption: false,
  };
  handleChange = (selectedOption) => {
    const { checked } = selectedOption.target;
    this.props.context.showLoader();
    let valA = 'ENABLED';
    let valB = 'DISABLED';
    if (this.props.column.colDef.cellRenderer === 'boolRenderer') {
      valA = 'TRUE';
      valB = 'FALSE';
    }
    let count = 0;
    this.setState({ selectedOption: checked });
    const totalRows = this.props.api.getModel().rootNode.childrenAfterFilter.length;
    let val;
    if (checked === true) {
      val = valA;
    } else {
      val = valB;
    }
    this.props.api.forEachNodeAfterFilter((node) => {
      count += 1;
      const newItem = copyObject(node.data);
      newItem[this.props.column.colId] = val;
      const obj = {
        oldValue: node.data[this.props.column.colId],
        value: val,
        newValue: val,
        node,
        colDef: this.props.column.colDef,
        column: this.props.column,
        data: newItem,
      };
      this.props.context.onCellValueChanged(obj);
      this.props.api.applyTransactionAsync({ update: [newItem] }, this.resultCallback(totalRows, count));
    });
  };
  resultCallback=(totalRows, count) => {
    if (count === totalRows) {
      this.props.context.hideLoader();
    }
  }
  render() {
    const { selectedOption } = this.state;
    return (
      <div className="ag-header-cell-label ag-header-cell-label-custom">
        <span className="ag-header-cell-text">
          <label
            className="checkbox-btn ag-checkbox-btn"
          >
            <input
              className="checkbox-btn__checkbox"
              type="checkbox"
              name="checkboxAG"
              checked={selectedOption}
              onChange={this.handleChange}
            />
            <span
              className="checkbox-btn__checkbox-custom"
            >
              <CheckIcon />
            </span>
          </label>
          <div style={{ clear: 'both' }} >
            {this.props.column.colDef.headerName}
          </div>
        </span>
      </div>
    );
  }
}
export default StateHeaderComponent;
