/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, ButtonToolbar, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import ReactDOM from 'react-dom';

export default function ({
  disabled, onSubmit, files, theme, literals, title,
}) {
  const [open, setOpen] = useState(false);
  let linkRef = null;

  function toggle() {
    setOpen(!open);
  }
  const _disabled =
        files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
        !files.some(f => ['headers_received', 'done'].includes(f.meta.status));
  const handleSubmit = () => {
    onSubmit(files.filter(f => ['headers_received', 'done'].includes(f.meta.status)));
  };
  return (
    <ButtonToolbar
      className="form__button-toolbar"
      style={{
                marginTop: 15,
                marginBottom: 0,
            }}
    >
      <Button
        ref={(c) => {
                    linkRef = c && ReactDOM.findDOMNode(c);
                }}
        color="primary"
        type="button"
        onClick={toggle}
        disabled={disabled || _disabled}
      >Upload
      </Button>
      <Popover
        placement="top"
        isOpen={open}
        target={() => linkRef}
        toggle={toggle}
        className={theme.className === 'theme-light' ? 'trading-light' : 'trading-dark'}
      >
        <PopoverHeader>{literals.forms.uni_symbol_form.confirm_upload}</PopoverHeader>
        <PopoverBody>
          <span>{`${literals.forms.confirm_upload_note} ${title}?`}
          </span>
          <ButtonToolbar className="btn-toolbar--center">
            <Button className="btn btn-blue" onClick={toggle}>
              {literals.forms.cancel}
            </Button>
            <Button
              className="btn btn-blue"
              onClick={handleSubmit}
              disabled={disabled || _disabled}
            >
              {literals.forms.confirm}
            </Button>
          </ButtonToolbar>
        </PopoverBody>
      </Popover>
    </ButtonToolbar>);
}
