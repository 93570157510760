/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonToolbar } from 'reactstrap';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import { inject } from 'mobx-react';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';

@inject('rootStore')
class MakerScalingSymbolActionRenderer extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    context: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    this.deleteMakerScalingSymbol = this.deleteMakerScalingSymbol.bind(this);
    let notification = null;
    NotificationSystem.newInstance({}, (n) => (notification = n));
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: (
          <BasicNotification title={title} message={message} color={color} />
        ),
        duration: 3,
        closable: true,
        style: { top: 0 },
        className: 'left-up'
      });
    };
  }
  state = {
    visible: false,
    destroyOnClose: false
  };
  onClick = () => {
    this.setState({
      visible: true
    });
  };
  onClose = () => {
    this.setState({
      visible: false
    });
  };

  deleteMakerScalingSymbol() {
    this.props.context.showLoader();
    this.onClose();
    const dataToDelete = {
      unisymbol: this.props.node.data.unisymbol,
      shared_id: this.props.node.data.shared_id,
      // Below fields are only for Audit Log (not for Delete)
      price_scale_in: parseFloat(this.props.node.data.price_scale_in),
      price_scale_out: parseFloat(this.props.node.data.price_scale_out),
      volume_scale_in: parseFloat(this.props.node.data.volume_scale_in),
      volume_scale_out: parseFloat(this.props.node.data.volume_scale_out),
      description: this.props.node.data.description,
      state: parseInt(this.props.node.data.state, 10)
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/maker/scaling_symbol_delete`,
        JSON.stringify(dataToDelete)
      )
      .then((response) => {
        if (response.data.status) {
          this.showNotification(
            this.props.literals.forms.maker_scaling_symbol_form.title_delete,
            'primary',
            this.props.literals.forms.success_message
          );
          this.props.context.resetMakerScalingSymbol();
        } else {
          this.showNotification(
            this.props.literals.forms.maker_scaling_symbol_form.title_delete,
            'danger',
            this.props.literals.forms.fail_message
          );
          this.props.context.hideLoader();
        }
      })
      .catch((error) => {
        this.showNotification(
          this.props.literals.forms.maker_scaling_symbol_form.title_delete,
          'danger',
          error.message
        );
        this.props.context.hideLoader();
      });
  }

  render() {
    const { literals } = this.props;
    let dialog;
    if (this.state.visible || !this.state.destroyOnClose) {
      dialog = (
        <Dialog
          visible={this.state.visible}
          wrapClassName="center"
          animation="slide-fade"
          maskAnimation="fade"
          onClose={this.onClose}
          style={{ width: 276 }}
          title={literals.forms.confirm_delete}
        >
          <p>
            {literals.forms.confirm_delete_note}{' '}
            {this.props.node.data.unisymbol} ({this.props.node.data.shared_id})?
          </p>
          <div
            style={{
              marginTop: 10,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '68%'
            }}
          >
            <ButtonToolbar>
              <Button
                className="icon"
                color="primary"
                key="close"
                onClick={this.onClose}
              >
                {literals.forms.cancel}
              </Button>
              <Button
                className="icon"
                color="primary"
                key="save"
                onClick={this.deleteMakerScalingSymbol}
              >
                {literals.forms.delete}
              </Button>
            </ButtonToolbar>
          </div>
        </Dialog>
      );
    }
    return (
      <span>
        <ButtonToolbar>
          <Tooltip title={literals.tables.ag_table.delete}>
            <Button
              style={{ height: 14 }}
              id="MakerScalingSymbolDeleteClick"
              onClick={this.onClick}
              className="icon"
              color="primary"
            >
              <p style={{ lineHeight: 0 }} className="fa fa-trash-alt" />
            </Button>
          </Tooltip>
        </ButtonToolbar>
        {dialog}
      </span>
    );
  }
}

export default connect((state) => ({
  literals: state.literals
}))(withRouter(MakerScalingSymbolActionRenderer));
