import { regExDigits, validateSessions } from '../../shared/helper';

const uniSymbolValidate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${props.literals.forms.uni_symbol_form.name} ${props.literals.forms.required_validate}`;
  }
  if (!values.security) {
    errors.security = `${props.literals.forms.uni_symbol_form.security} ${props.literals.forms.required_validate}`;
  }
  if (!regExDigits.test(values.digits)) {
    errors.digits = `${props.literals.forms.invalid} ${props.literals.forms.uni_symbol_form.digits}`;
  }
  if (!validateSessions(values.sessions)) {
    errors.sessions = `${props.literals.forms.invalid} ${props.literals.forms.uni_symbol_form.sessions}`;
  }
  return errors;
};

export default uniSymbolValidate;
