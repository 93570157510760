/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import axios from 'axios';
import { inject } from 'mobx-react';
import Websocket from 'react-websocket';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import PushTickSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import MarketWatchView from './MarketWatchView';

const renderField = ({
  input, placeholder, type, disabled, step, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

@inject('rootStore')
class PushTicks extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      search_values: PropTypes.instanceOf(Object),
      rootStore: PropTypes.instanceOf(Object).isRequired,
    };
    static defaultProps = {
      search_values: {},
    };
    constructor(props) {
      super(props);
      this.state = {
        collapse: '',
        selectedSymbol: {},
        marketWatch: [],
        marketWatchLast: [],
      };
    }

    componentDidMount() {
      setTimeout(() => {
        const values = this.props.search_values;
        const subscribe = values.feed && values.symbol ? [`${values.feed.value}||${values.symbol.value}`] : [];
        const dataToSend = {
          feed_symbols: subscribe,
        };
        axios.post(
          `${process.env.REACT_APP_BACKEND_API}/trading/market_data?page=trading_platform`,
          JSON.stringify(dataToSend),
        )
          .then((response) => {
            this.setState({ marketWatchLast: response.data });
          })
          .catch(() => {
          });
      }, 0);
    }

    componentWillUnmount() {
      clearTimeout(this.timerCollapse);
    }

    handleSubmit=(values) => {
      this.setState({ selectedSymbol: values.feed && values.symbol ? values.symbol : {} });
      const subscribe = values.feed && values.symbol ?
        `${values.feed.value}||${values.symbol.value}||${values.symbol.value}` : '';
      this.sendMessage(JSON.stringify({ key: 'symbol', value: subscribe }));
      this.setState({ collapse: 'force-close' });
      this.timerCollapse = setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
    };

    socketMessageListener = (event) => {
      const data = JSON.parse(event);
      if ('feed_symbols' in data) {
        if (data.feed_symbols && data.feed_symbols.length) {
          this.setState({ marketWatch: data.feed_symbols });
        }
      }
    };
    socketOpenListener = () => {
      const values = this.props.search_values;
      this.setState({ selectedSymbol: values.feed && values.symbol ? values.symbol : {} });
      const subscribe = values.feed && values.symbol ?
        `${values.feed.value}||${values.symbol.value}||${values.symbol.value}` : '';
      this.sendMessage(JSON.stringify({ key: 'symbol', value: subscribe }));
    };
    sendMessage=(message) => {
      this.refWebSocket.sendMessage(message);
    };

    render() {
      const { literals } = this.props;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.push_ticks}
                </h5>
              </div>
              <Collapse
                title={literals.tables.trading_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <PushTickSearchForm
                  onSubmit={this.handleSubmit}
                  showLoader={this.showLoader}
                  hideLoader={this.hideLoader}
                  mySocket={this.mySocket}
                />
              </Collapse>
              <MarketWatchView
                marketWatch={this.state.marketWatch}
                marketWatchLast={this.state.marketWatchLast}
                selectedSymbol={this.state.selectedSymbol}
                makerSession={this.props.search_values.maker_session}
              />
              <Websocket
                url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
                reconnectIntervalInMilliSeconds={1000}
                onMessage={this.socketMessageListener}
                onOpen={this.socketOpenListener}
                ref={(WS) => {
                  this.refWebSocket = WS;
                }}
              />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  literals: state.literals,
  search_values: getFormValues('push_tick_search_form')(state),
}))(PushTicks);
