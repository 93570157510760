/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { inject } from 'mobx-react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import {
  LiteralProps,
  ThemeProps,
  LanguageProps,
} from '../../shared/prop-types/ReducerProps';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized,
} from '../../shared/helper';
import OpenOrderActionRenderer from './openOrderActionRenderer';

@inject('rootStore')
class OpenOrderView extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    language: LanguageProps.isRequired,
    activeTab: PropTypes.string.isRequired,
    openOrderWatch: PropTypes.instanceOf(Array),
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  static defaultProps = {
    openOrderWatch: [],
  };
  constructor(props) {
    super(props);
    const tableID = 'sidebar_trading_platform_open_order';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    const { literals } = props;
    const readOnly = props.rootStore.reportStore.reportState.readOnly || 0;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.open_order_table.id,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.id'),
          field: 'id',
          headerTooltip: literals.tables.open_order_table.id,
          type: 'number',
        },
        {
          headerName: literals.tables.open_order_table.cen_ord_id,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.cen_ord_id'),
          field: 'cen_ord_id',
          headerTooltip: literals.tables.open_order_table.cen_ord_id,
          type: 'text',
        },
        {
          headerName: literals.tables.open_order_table.client_ord_id,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.client_ord_id'),
          field: 'client_ord_id',
          headerTooltip: literals.tables.open_order_table.client_ord_id,
          type: 'text',
        },
        {
          headerName: literals.tables.open_order_table.symbol,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.symbol'),
          field: 'symbol',
          headerTooltip: literals.tables.open_order_table.symbol,
          type: 'text',
        },
        {
          headerName: literals.tables.open_order_table.party_symbol,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.party_symbol'),
          field: 'party_symbol',
          headerTooltip: literals.tables.open_order_table.party_symbol,
          type: 'text',
        },
        {
          headerName: literals.tables.open_order_table.price,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.price'),
          field: 'price',
          headerTooltip: literals.tables.open_order_table.price,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          editable: true,
        },
        {
          headerName: literals.tables.open_order_table.close_price,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.close_price'),
          field: 'close_price',
          headerTooltip: literals.tables.open_order_table.close_price,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.open_order_table.volume,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.volume'),
          field: 'volume',
          headerTooltip: literals.tables.open_order_table.volume,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          editable: true,
        },
        {
          headerName: literals.tables.open_order_table.total_fill_volume,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.total_fill_volume'),
          field: 'total_fill_volume',
          headerTooltip: literals.tables.open_order_table.total_fill_volume,
          type: 'number',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.open_order_table.side,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.side'),
          field: 'side',
          headerTooltip: literals.tables.open_order_table.side,
          type: 'text',
        },
        {
          headerName: literals.tables.open_order_table.ord_type,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.ord_type'),
          field: 'ord_type',
          headerTooltip: literals.tables.open_order_table.ord_type,
          type: 'text',
        },
        {
          headerName: literals.tables.open_order_table.time_in_force,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.time_in_force'),
          field: 'time_in_force',
          headerTooltip: literals.tables.open_order_table.time_in_force,
          type: 'text',
        },
        {
          headerName: literals.tables.open_order_table.ord_state,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.ord_state'),
          field: 'ord_state',
          headerTooltip: literals.tables.open_order_table.ord_state,
          type: 'text',
        },
        {
          headerName: literals.tables.open_order_table.time,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.open_order_table.time'),
          field: 'time',
          headerTooltip: literals.tables.open_order_table.time,
          type: 'text',
        },
        {
          headerName: literals.tables.ag_table.actions,
          headerValueGetter: () =>
            this.localizeHeader('literals.tables.ag_table.actions'),
          field: 'actions',
          cellRenderer: 'actionRenderer',
          width: 60,
          minWidth: 60,
          resizable: false,
          filter: false,
          editable: false,
          suppressMenu: true,
          hide: readOnly === 1,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
        menuTabs: ['filterMenuTab'],
      },
      columnTypes: {
        text: { filter: 'agTextColumnFilter' },
        number: { filter: 'agNumberColumnFilter' },
        numberWithFilterReset: {
          filter: 'agNumberColumnFilter',
        },
      },
      frameworkComponents: {
        actionRenderer: OpenOrderActionRenderer,
      },
      getRowNodeId(data) {
        return data.id;
      },
    };
    this.onGridReadyOpenOrderView = this.onGridReadyOpenOrderView.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.gridApiOpenOrderView &&
      this.props.language.languageName !== prevProps.language.languageName
    ) {
      this.gridApiOpenOrderView.refreshHeader();
    }
  }
  onGridReadyOpenOrderView(params) {
    this.gridApiOpenOrderView = params.api;
    this.gridColumnApiOpenOrderView = params.columnApi;
    this.onGridSizeChangedOpenOrderView(params);
    this.gridApiOpenOrderView.refreshHeader();
  }
  onFirstDataRendered = (params) => {
    this.onGridSizeChangedOpenOrderView(params);
  };
  onGridSizeChangedOpenOrderView = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };
  onColumnResizedOpenOrderView = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };
  localizeHeader = header => _.get(this.props, header);
  render() {
    const theme = this.props.theme.className;
    const openOrderWatchData = this.props.openOrderWatch
      ? this.props.openOrderWatch
      : [];
    let openOrderData = [];
    if (this.props.activeTab === '1') {
      openOrderData = _.filter(
        openOrderWatchData,
        o => o.net_volume_value !== 0,
      );
    } else {
      openOrderData = [];
    }
    return (
      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%' }}>
        <div
          id={this.myGridID}
          style={{
            boxSizing: 'border-box',
            height: 150,
            width: '100%',
          }}
          className={
            theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'
          }
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            immutableData
            suppressCellSelection
            suppressContextMenu
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResizedOpenOrderView}
            rowData={openOrderData}
            getRowNodeId={this.state.getRowNodeId}
            onGridReady={this.onGridReadyOpenOrderView}
            onGridSizeChanged={this.onGridSizeChangedOpenOrderView}
            frameworkComponents={this.state.frameworkComponents}
            onFirstDataRendered={this.onFirstDataRendered}
            columnTypes={this.state.columnTypes}
          />
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
  language: state.language,
}))(OpenOrderView);
