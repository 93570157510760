/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable  no-param-reassign */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { connect } from 'react-redux';
import CheckIcon from 'mdi-react/CheckIcon';
import { LiteralProps, ThemeProps } from '../../prop-types/ReducerProps';
import DepthLevelEdit from '../depth_level/DepthLevelEdit';
import DepthLevelEditForm from '../depth_level/DepthLevelEditForm';
import { copyObject } from '../../helper';

class DepthLevelHeaderComponent extends PureComponent {
  static propTypes = {
    context: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    column: PropTypes.instanceOf(Object).isRequired,
    setSort: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
  };
  state = {
    popoverOpen: false,
    ascSort: 'inactive',
    descSort: 'inactive',
    noSort: 'inactive',
    depthLevels: '',
    selectedOption: 'depth_level_interface',
    selectedMidPoint: false,
  };
  componentDidMount() {
    this.onSortChanged();
  }
  onSortChanged=() => {
    this.setState({
      ascSort: this.props.column.isSortAscending() ? 'active' : 'inactive',
      descSort: this.props.column.isSortDescending() ? 'active' : 'inactive',
      noSort: !this.props.column.isSortAscending() && !this.props.column.isSortDescending() ? 'active' : 'inactive',
    });
  };
  onSortRequested = (order) => {
    this.props.setSort(order, false);
    this.onSortChanged();
  };
  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };
  submitDepthLevel = (depthLevel) => {
    this.props.context.showLoader();
    if (this.state.selectedOption === 'depth_level_interface') {
      const depthLevelRaw = depthLevel.trim() ? depthLevel.replace(/,$/, '').split(',') : [];
      if (typeof depthLevelRaw[0] !== 'undefined') {
        const depthLevelFirst = depthLevelRaw[0].split(':');
        if (this.state.selectedMidPoint) {
          if (typeof depthLevelFirst[6] !== 'undefined') {
            depthLevelFirst[6] = '1';
          } else {
            depthLevelFirst.push('1');
          }
        } else if (typeof depthLevelFirst[6] !== 'undefined') {
          depthLevelFirst[6] = '0';
        }
        if (depthLevelFirst.length > 0) {
          depthLevelRaw[0] = depthLevelFirst.join(':');
        }
        depthLevel = depthLevelRaw.join(',');
      }
    }
    this.setState({ depthLevels: depthLevel });
    let count = 0;
    const totalRows = this.props.api.getModel().rootNode.childrenAfterFilter.length;
    this.props.api.forEachNodeAfterFilter((node) => {
      count += 1;
      const newItem = copyObject(node.data);
      newItem[this.props.column.colId] = depthLevel;
      const obj = {
        oldValue: node.data[this.props.column.colId],
        value: depthLevel,
        newValue: depthLevel,
        node,
        colDef: this.props.column.colDef,
        column: this.props.column,
        data: newItem,
      };
      this.props.context.onCellValueChanged(obj);
      this.props.api.applyTransactionAsync({ update: [newItem] }, this.resultCallback(totalRows, count));
    });
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };
  resultCallback=(totalRows, count) => {
    if (count === totalRows) {
      this.props.context.hideLoader();
    }
  }
  resetDepthLevel=() => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.target.value });
  };
  handleChangeMidPoint = (selectedOption) => {
    this.setState({ selectedMidPoint: selectedOption.target.checked });
  };
  render() {
    const theme = this.props.theme.className;
    const { selectedOption, selectedMidPoint } = this.state;
    return (
      <span className="ag-header-cell-label ag-header-cell-label-custom">
        <span className="ag-header-cell-text">
          <div
            id={`PopoverTop${this.props.column.colId}`}
            ref={(c) => {
                 this.target = c && ReactDOM.findDOMNode(c);
               }}
            onClick={this.toggle}
            className="fa fa-edit fa-400 customSortDownLabel"
            style={{ cursor: 'pointer' }}
          />
          <Popover
            placement="bottom-end"
            isOpen={this.state.popoverOpen}
            target={() => this.target}
            toggle={this.toggle}
            className={theme === 'theme-light'
              ? 'theme-light trading-light session-popover' : 'theme-dark trading-dark session-popover'}
          >
            <PopoverHeader>{
              this.props.literals.tables.ag_table.edit} {this.props.column.colDef.headerName}
            </PopoverHeader>
            <PopoverBody>
              <label
                className="radio-btn"
              >
                <input
                  className="radio-btn__radio"
                  type="radio"
                  name="depth_level_edit"
                  value="depth_level_interface"
                  checked={selectedOption === 'depth_level_interface'}
                  onChange={this.handleChange}
                />
                <span className="radio-btn__radio-custom" />
                <span className="radio-btn__label">
                  {this.props.literals.tables.ag_table.interface}
                </span>
              </label>
              <label
                className="radio-btn"
              >
                <input
                  className="radio-btn__radio"
                  type="radio"
                  name="depth_level_edit"
                  value="depth_level_text"
                  checked={selectedOption === 'depth_level_text'}
                  onChange={this.handleChange}
                />
                <span className="radio-btn__radio-custom" />
                <span className="radio-btn__label">
                  {this.props.literals.tables.ag_table.free_text}
                </span>
              </label>
              {this.state.selectedOption === 'depth_level_interface' &&
              <span style={{ display: 'inline-block', position: 'absolute' }}>
                <label
                  className="checkbox-btn"
                >
                  <input
                    className="checkbox-btn__checkbox"
                    type="checkbox"
                    name="mid_point"
                    checked={selectedMidPoint}
                    onChange={this.handleChangeMidPoint}
                  />
                  <span
                    className="checkbox-btn__checkbox-custom"
                  >
                    <CheckIcon />
                  </span>
                  <span className="checkbox-btn__label">
                    {this.props.literals.tables.ag_table.mid_point}
                  </span>
                </label>
              </span>
              }
              {this.state.selectedOption === 'depth_level_text' &&
              <DepthLevelEditForm
                depthLevels={this.state.depthLevels}
                initialDepthLevelValue={this.state.depthLevels}
                submitDepthLevel={this.submitDepthLevel}
                resetDepthLevel={this.resetDepthLevel}
                headerName={this.props.column.colDef.headerName}
              />
              }
              {this.state.selectedOption === 'depth_level_interface' &&
              <DepthLevelEdit
                depthLevels={this.state.depthLevels}
                initialDepthLevelValue={this.state.depthLevels}
                submitDepthLevel={this.submitDepthLevel}
                resetDepthLevel={this.resetDepthLevel}
                headerName={this.props.column.colDef.headerName}
              />
              }
            </PopoverBody>
          </Popover>
          <div
            onClick={() => { this.onSortRequested('asc'); }}
            onTouchEnd={() => { this.onSortRequested('asc'); }}
            className={`customSortDownLabel ${this.state.ascSort}`}
          >
            <i className="fa fa-long-arrow-alt-down" style={{ cursor: 'pointer' }} />
          </div>
          <div
            onClick={() => { this.onSortRequested('desc'); }}
            onTouchEnd={() => { this.onSortRequested('desc'); }}
            className={`customSortDownLabel ${this.state.descSort}`}
          >
            <i className="fa fa-long-arrow-alt-up" style={{ cursor: 'pointer' }} />
          </div>
          <div
            onClick={() => { this.onSortRequested(''); }}
            onTouchEnd={() => { this.onSortRequested(''); }}
            className={`customSortDownLabel ${this.state.noSort}`}
          >
            <i className="fa fa-times" style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ clear: 'both' }}>
            {this.props.column.colDef.headerName}
          </div>
        </span>
      </span>
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(DepthLevelHeaderComponent);
