/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const data = {
    username: props.rootStore.authStore.user.username,
    first_name: values.first_name,
    last_name: values.last_name,
    email: values.email,
    phone: values.phone,
    daily_statement: values.daily_statement ? 1 : 0,
    email_notify: values.email_notify,
  };

  axios.post(
    `${process.env.REACT_APP_BACKEND_API}/profile`, JSON.stringify(data),
    { headers: { 'x-forward-client': props.rootStore.clientStore.CC } },
  )
    .then((response) => {
      if (response.data.status) {
        props.rootStore.authStore.user.first_name = values.first_name;
        props.rootStore.authStore.user.last_name = values.last_name;
        props.rootStore.authStore.user.email = values.email;
        props.rootStore.authStore.user.phone = values.phone;
        props.rootStore.authStore.user.email_notify = values.email_notify;
        showNotification(
          props.literals.forms.user_form.title_update, 'primary',
          props.literals.forms.success_message,
        );
      } else {
        showNotification(props.literals.forms.user_form.title_update, 'danger', props.literals.forms.fail_message);
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.user_form.title_update, 'danger', error.message);
    });
};
