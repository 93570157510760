/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import ValuesEditorForm from './ValuesEditorForm';

class SessionRenderer extends PureComponent {
    static propTypes = {
      context: PropTypes.instanceOf(Object).isRequired,
      value: PropTypes.string.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
    };
    state = {
      sessionModalShow: false,
    };
    toggleModalSession = () => {
      this.setState({ sessionModalShow: !this.state.sessionModalShow });
    };
    showModalSession = () => {
      if (!this.state.sessionModalShow) {
        this.setState({ sessionModalShow: true });
      }
    };
    handleSubmit=(values) => {
      const vals = [];
      if (values.values && values.values.tag && values.values.value) {
        values.values.tag.forEach((tag, i) => {
          const value = values.values.value[i];
          if (tag && tag.trim() && value && value.trim()) {
            vals.push(`${tag.trim()}=${value.trim()}`);
          }
        });
      }
      const rowNode = this.props.node;
      rowNode.setDataValue(this.props.colDef.field, vals.join(','));
      this.toggleModalSession();
    };
    render() {
      const theme = this.props.context.theme.className;
      return (
        <div onDoubleClick={this.showModalSession}>
          <label>
            {this.props.value}
          </label>
          <Modal
            isOpen={this.state.sessionModalShow}
            toggle={() => this.toggleModalSession}
            className={`modal-dialog--primary modal-dialog--header crp-modal ${theme}`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleModalSession()} />
              <h4 className="bold-text  modal__title">{this.props.colDef.headerName}</h4>
            </div>
            <div className="modal__body risk_account_modal_body">
              <ValuesEditorForm
                {...this.props}
                onSubmit={this.handleSubmit}
                toggleModalSession={this.toggleModalSession}
              />
            </div>
            <div className="modal__footer" />
          </Modal>
        </div>
      );
    }
}
export default SessionRenderer;
