/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import Websocket from 'react-websocket';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';
import YesNoRenderer from '../../shared/components/YesNoRenderer';
import SymbolFilter from './symbolFilter';

const exportColumns = ['shared_id', 'symbol_val', 'sub_id', 'subscribed_value',
  'ticks_count', 'avg_spread', 'avg_spread_points', 'spread_ticks_count', 'delayed_ticks_count',
  'skipped_ticks_count', 'consumed_ticks_count',
  'orders_count', 'long_orders', 'short_orders', 'avg_fill_time',
  'avg_travel_time', 'rejected_count', 'partial_fills_count', 'fully_fills_count',
  'total_fill_volume_value', 'total_volume_value', 'avg_slippage', 'avg_slippage_points'];

@inject('rootStore')
class MakerSymbolStatus extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };
  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_maker_symbol_status';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.maker_symbol_status_table.shared_id,
          field: 'shared_id',
          headerTooltip: literals.tables.maker_symbol_status_table.shared_id,
          width: 80,
          minWidth: 80,
          sort: 'asc',
          suppressMenu: false,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: [],
            defaultToNothingSelected: true,
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.symbol_val,
          field: 'symbol_val',
          headerTooltip: literals.tables.maker_symbol_status_table.symbol_val,
          sort: 'asc',
          suppressMenu: false,
          filter: 'symbolFilter',
          filterParams: {
            values: [],
            defaultToNothingSelected: true,
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.sub_id,
          field: 'sub_id',
          headerTooltip: literals.tables.maker_symbol_status_table.sub_id,
        },
        {
          headerName: literals.tables.maker_symbol_status_table.subscribed_value,
          field: 'subscribed_value',
          headerTooltip: literals.tables.maker_symbol_status_table.subscribed_value,
          cellRenderer: 'yesNoRenderer',
          cellRendererParams: {
            disabled: true,
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.ticks_count,
          field: 'ticks_count',
          headerTooltip: literals.tables.maker_symbol_status_table.ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_spread,
          field: 'avg_spread',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_spread,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_spread_points,
          field: 'avg_spread_points',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_spread_points,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.spread_ticks_count,
          field: 'spread_ticks_count',
          headerTooltip: literals.tables.maker_symbol_status_table.spread_ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.delayed_ticks_count,
          field: 'delayed_ticks_count',
          headerTooltip: literals.tables.maker_symbol_status_table.delayed_ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName:
          literals.tables.maker_symbol_status_table.skipped_ticks_count,
          field: 'skipped_ticks_count',
          headerTooltip:
          literals.tables.maker_symbol_status_table.skipped_ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName:
          literals.tables.maker_symbol_status_table.consumed_ticks_count,
          field: 'consumed_ticks_count',
          headerTooltip:
          literals.tables.maker_symbol_status_table.consumed_ticks_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.orders_count,
          field: 'orders_count',
          headerTooltip: literals.tables.maker_symbol_status_table.orders_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.long_orders,
          field: 'long_orders',
          headerTooltip: literals.tables.maker_symbol_status_table.long_orders,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.short_orders,
          field: 'short_orders',
          headerTooltip: literals.tables.maker_symbol_status_table.short_orders,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_fill_time,
          field: 'avg_fill_time',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_fill_time,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_travel_time,
          field: 'avg_travel_time',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_travel_time,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.rejected_count,
          field: 'rejected_count',
          headerTooltip: literals.tables.maker_symbol_status_table.rejected_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.partial_fills_count,
          field: 'partial_fills_count',
          headerTooltip: literals.tables.maker_symbol_status_table.partial_fills_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.fully_fills_count,
          field: 'fully_fills_count',
          headerTooltip: literals.tables.maker_symbol_status_table.fully_fills_count,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.total_fill_volume,
          field: 'total_fill_volume_value',
          headerTooltip: literals.tables.maker_symbol_status_table.total_fill_volume,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.total_volume_value,
          field: 'total_volume_value',
          headerTooltip: literals.tables.maker_symbol_status_table.total_volume_value,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_slippage,
          field: 'avg_slippage',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_slippage,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
        {
          headerName: literals.tables.maker_symbol_status_table.avg_slippage_points,
          field: 'avg_slippage_points',
          headerTooltip: literals.tables.maker_symbol_status_table.avg_slippage_points,
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
        },
      ],
      defaultColDef: {
        menuTabs: ['filterMenuTab'],
        suppressMenu: true,
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      getRowNodeId(data) {
        return data.shared_id + data.symbol_val;
      },
      rowData: [],
      frameworkComponents: {
        yesNoRenderer: YesNoRenderer,
        symbolFilter: SymbolFilter,
      },
      symbolData: [],
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/monitoring/maker_status_filter`)
      .then((response) => {
        this.setState({ symbolData: response.data.symbols || [] });
        const savedState = JSON.parse(localStorage.getItem('makerStatusFilter'));
        this.gridApi.getFilterInstance('shared_id').setFilterValues(response.data.shared_id || []);
        if (savedState) {
          this.gridApi.setFilterModel(savedState);
        }
      })
      .catch(() => {
      });
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (literals.tables.maker_symbol_status_table.shared_id !==
        prevProps.literals.tables.maker_symbol_status_table.shared_id) {
        this.gridColumnApi.getColumn('shared_id')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.shared_id;
        this.gridColumnApi.getColumn('symbol_val')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.symbol_val;
        this.gridColumnApi.getColumn('sub_id')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.sub_id;
        this.gridColumnApi.getColumn('subscribed_value')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.subscribed_value;
        this.gridColumnApi.getColumn('ticks_count')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.ticks_count;
        this.gridColumnApi.getColumn('avg_spread')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.avg_spread;
        this.gridColumnApi.getColumn('avg_spread_points')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.avg_spread_points;
        this.gridColumnApi.getColumn('spread_ticks_count')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.spread_ticks_count;
        this.gridColumnApi.getColumn('delayed_ticks_count')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.delayed_ticks_count;
        this.gridColumnApi
          .getColumn('skipped_ticks_count')
          .getColDef().headerName =
            literals.tables.maker_symbol_status_table.skipped_ticks_count;
        this.gridColumnApi
          .getColumn('consumed_ticks_count')
          .getColDef().headerName =
            literals.tables.maker_symbol_status_table.consumed_ticks_count;
        this.gridColumnApi.getColumn('orders_count')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.orders_count;
        this.gridColumnApi.getColumn('long_orders')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.long_orders;
        this.gridColumnApi.getColumn('short_orders')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.short_orders;
        this.gridColumnApi.getColumn('avg_fill_time')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.avg_fill_time;
        this.gridColumnApi.getColumn('avg_travel_time')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.avg_travel_time;
        this.gridColumnApi.getColumn('rejected_count')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.rejected_count;
        this.gridColumnApi.getColumn('partial_fills_count')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.partial_fills_count;
        this.gridColumnApi.getColumn('fully_fills_count')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.fully_fills_count;
        this.gridColumnApi.getColumn('total_fill_volume_value')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.total_fill_volume;
        this.gridColumnApi.getColumn('total_volume_value')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.total_volume_value;
        this.gridColumnApi.getColumn('avg_slippage')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.avg_slippage;
        this.gridColumnApi.getColumn('avg_slippage_points')
          .getColDef().headerName
          = literals.tables.maker_symbol_status_table.avg_slippage_points;
        this.gridApi.refreshHeader();
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onGridSizeChanged(params);
  }

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onFilterChanged=(params) => {
    const filter = params.api.getFilterModel();
    const state = JSON.stringify(filter);
    localStorage.setItem('makerStatusFilter', state);
    this.sendMessage(JSON.stringify({
      key: 'maker_symbol_status',
      value: JSON.stringify({
        shared_id: filter.shared_id ? filter.shared_id.values : [],
        symbol_val: filter.symbol_val ? filter.symbol_val.values : [],
      }),
    }));
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'MakerSymbolStatus.xlsx',
      suppressTextAsCDATA: true,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'MakerSymbolStatus.csv',
    };
    this.gridApi.exportDataAsCsv(params);
  };

  socketMessageListener = (event) => {
    const data = JSON.parse(event);
    if ('maker_symbol_status' in data) {
      this.setState({ rowData: data.maker_symbol_status });
    }
  };
  socketOpenListener = () => {
    const filter = this.gridApi.getFilterModel();
    if (filter.shared_id && filter.symbol_val) {
      this.sendMessage(JSON.stringify({
        key: 'maker_symbol_status',
        value: JSON.stringify({
          shared_id: filter.shared_id.values,
          symbol_val: filter.symbol_val.values,
        }),
      }));
    }
  };
  sendMessage=(message) => {
    this.refWebSocket.sendMessage(message);
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="outer-grid-label">
              ({literals.tables.maker_symbol_status_table.values_reset_note}
              ) {literals.tables.ag_table.total_records}: {this.state.rowData ?
                this.state.rowData.length : 0}
            </div>
            <div className="outer-grid-button">
              <ButtonToolbar>
                <Tooltip title={literals.tables.ag_table.export_excel}>
                  <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                    <p className="fa fa-file-excel" />
                  </Button>
                </Tooltip>
                <Tooltip title={literals.tables.ag_table.export_csv}>
                  <Button
                    onClick={this.onBtExportCSV}
                    className="icon"
                    color="primary"
                  >
                    <p className="fa fa-file-alt" />
                  </Button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div
              id={this.gridWrapperID}
              style={{
                width: '100%', height: '100%', clear: 'both',
              }}
            >
              <div
                id={this.myGridID}
                style={{
                  boxSizing: 'border-box',
                  height: '85vh',
                  width: '100%',
                }}
                className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.columnDefs}
                  immutableData
                  suppressCellSelection
                  suppressContextMenu
                  defaultColDef={this.state.defaultColDef}
                  onColumnResized={this.onColumnResized}
                  getRowNodeId={this.state.getRowNodeId}
                  rowData={this.state.rowData}
                  onGridReady={this.onGridReady}
                  onGridSizeChanged={this.onGridSizeChanged}
                  rowHeight={20}
                  accentedSort
                  onFilterChanged={this.onFilterChanged}
                  frameworkComponents={this.state.frameworkComponents}
                  context={{
                    symbolData: this.state.symbolData,
                    theme: this.props.theme,
                  }}
                />
              </div>
            </div>
            <Websocket
              url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
              reconnectIntervalInMilliSeconds={1000}
              onMessage={this.socketMessageListener}
              onOpen={this.socketOpenListener}
              ref={(WS) => {
                this.refWebSocket = WS;
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(MakerSymbolStatus);
