/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

class MultiplierRenderer extends PureComponent {
    static propTypes = {
      value: PropTypes.string.isRequired,
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
      literals: LiteralProps.isRequired,
    };
    state = {
      selectedOption: this.props.value,
    };
    // update cellValue when the cell's props are updated
    static getDerivedStateFromProps(nextProps) {
      return {
        selectedOption: nextProps.value,
      };
    }
    refresh(params) {
      if (params.value !== this.state.selectedOption) {
        this.setState({
          selectedOption: params.value,
        });
      }
      return true;
    }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.target.value });
    const rowNode = this.props.node;
    rowNode.setDataValue(this.props.colDef.field, selectedOption.target.value);
  };
  render() {
    const { selectedOption } = this.state;
    const { literals } = this.props;
    return (
      <div>
        <label
          className="radio-btn"
          style={{ paddingRight: 5 }}
        >
          <input
            className="radio-btn__radio"
            type="radio"
            name={`enable_multiplier_${this.props.node.__objectId}`}
            value="multiplier_1"
            checked={selectedOption === 'multiplier_1'}
            onChange={this.handleChange}
          />
          <span className="radio-btn__radio-custom" style={{ width: 14, height: 14 }} />
          <span className="radio-btn__label" style={{ paddingLeft: 1 }}>
            {literals.forms.markup_group_form.multiplier_1}
          </span>
        </label>
        <label
          className="radio-btn"
          style={{ paddingRight: 5 }}
        >
          <input
            className="radio-btn__radio"
            type="radio"
            name={`enable_multiplier_${this.props.node.__objectId}`}
            value="multiplier_2"
            checked={selectedOption === 'multiplier_2'}
            onChange={this.handleChange}
          />
          <span className="radio-btn__radio-custom" style={{ width: 14, height: 14 }} />
          <span className="radio-btn__label" style={{ paddingLeft: 1 }}>
            {literals.forms.markup_group_form.multiplier_2}
          </span>
        </label>
        <label
          className="radio-btn"
          style={{ paddingRight: 5 }}
        >
          <input
            className="radio-btn__radio"
            type="radio"
            name={`enable_multiplier_${this.props.node.__objectId}`}
            value="multiplier_3"
            checked={selectedOption === 'multiplier_3'}
            onChange={this.handleChange}
          />
          <span className="radio-btn__radio-custom" style={{ width: 14, height: 14 }} />
          <span className="radio-btn__label" style={{ paddingLeft: 1 }}>
            {literals.forms.markup_group_form.multiplier_3}
          </span>
        </label>
      </div>
    );
  }
}

export default connect(state => ({
  literals: state.literals,
}))(MultiplierRenderer);
