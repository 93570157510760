const accountValidate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${props.literals.forms.account_form.name} ${props.literals.forms.required_validate}`;
  }
  if (values.name && values.name.length > 32) {
    errors.name = `${props.literals.forms.account_form.name} ${props.literals.forms.max_32_validate}`;
  }
  if (!values.group) {
    errors.group = `${props.literals.forms.account_form.group} ${props.literals.forms.required_validate}`;
  }
  if (!values.category) {
    errors.category = `${props.literals.forms.account_form.category} ${props.literals.forms.required_validate}`;
  }
  if (!values.currency) {
    errors.currency = `${props.literals.forms.account_form.currency} ${props.literals.forms.required_validate}`;
  }
  if (!values.type) {
    errors.type = `${props.literals.forms.account_form.type} ${props.literals.forms.required_validate}`;
  }
  return errors;
};

export default accountValidate;
