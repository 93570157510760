/* eslint-disable arrow-parens */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';

const exportColumns = ['name', 'trading_state_value'];

@inject('rootStore')
class AccountBalanceState extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rowDataState: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_balance_transaction_state';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.account_balance_table.name,
          field: 'name',
          headerTooltip: literals.tables.account_balance_table.name,
          pinned: 'left',
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.tables.account_balance_table.trading_state,
          field: 'trading_state_value',
          headerTooltip: literals.tables.account_balance_table.trading_state,
          width: 60,
          minWidth: 60,
          cellRenderer(params) {
            if (params.value === 'REACHED NOP') {
              return `<span class="cell-renderer-blue">${params.value}</span>`;
            } else if (params.value === 'STOPPED OUT') {
              return `<span class="cell-renderer-red">${params.value}</span>`;
            } else if (params.value === 'MARGIN CALL') {
              return `<span class="cell-renderer-brown">${params.value}</span>`;
            }
            return `<span style='font:inherit'>${params.value}</span>`;
          },
        },
      ],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      getRowNodeId(data) {
        return data.name;
      },
      height: 200,
      headerHeight: 16,
    };
    this.onGridReadyState = this.onGridReadyState.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApiState) {
      const { literals } = this.props;
      if (literals.tables.account_balance_table.name !== prevProps.literals.tables.account_balance_table.name) {
        this.gridColumnApiState.getColumn('name')
          .getColDef().headerName
          = literals.tables.account_balance_table.name;
        this.gridColumnApiState.getColumn('trading_state_value')
          .getColDef().headerName
          = literals.tables.account_balance_table.trading_state;
        this.gridApiState.refreshHeader();
      }
    }
  }

  onGridReadyState(params) {
    this.gridApiState = params.api;
    this.gridColumnApiState = params.columnApi;
    this.onGridSizeChangedState(params);
  }

  onGridSizeChangedState = (params) => {
    let height = ((this.props.rowDataState ? this.props.rowDataState.length : 0) * 20)
      + 50 + this.state.headerHeight;
    const wHeight = window.innerHeight / 1.2;
    if (height > wHeight) {
      height = wHeight;
    }
    this.setState({ height });
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResizedState = (params) => {
    setTimeout(() => {
      const minHeight = onColumnResized(params, this.myGridID);
      this.setState({ headerHeight: minHeight });
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'AccountBalanceState.xlsx',
      suppressTextAsCDATA: true,
    };
    this.gridApiState.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: exportColumns,
      fileName: 'AccountBalanceState.csv',
    };
    this.gridApiState.exportDataAsCsv(params);
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    let height = ((this.props.rowDataState ? this.props.rowDataState.length : 0) * 20)
      + 50 + this.state.headerHeight;
    const wHeight = window.innerHeight / 1.2;
    if (height > wHeight) {
      height = wHeight;
    }
    this.setState({ height });
    return (
      <div>
        <div className="card__title card__title_custom">
          <h5 className="bold-text">{literals.sidebar.account_balance_state}
          </h5>
        </div>
        <div className="outer-grid-label">
          {literals.tables.ag_table.total_records}: {this.props.rowDataState ? this.props.rowDataState.length : 0}
        </div>
        <div className="outer-grid-button">
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_csv}>
              <Button
                onClick={this.onBtExportCSV}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-alt" />
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </div>
        <div
          id={this.gridWrapperID}
          style={{
 width: '100%', height: '100%', clear: 'both',
}}
        >
          <div
            id={this.myGridID}
            style={{
                                    boxSizing: 'border-box',
                                    height: this.state.height,
                                    width: '100%',
                                }}
            className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              immutableData
              suppressCellSelection
              suppressContextMenu
              defaultColDef={this.state.defaultColDef}
              onColumnResized={this.onColumnResizedState}
              getRowNodeId={this.state.getRowNodeId}
              rowData={this.props.rowDataState}
              onGridReady={this.onGridReadyState}
              onGridSizeChanged={this.onGridSizeChangedState}
              rowHeight={20}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(AccountBalanceState);
