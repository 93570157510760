import React, { PureComponent } from 'react';
import DatePicker from 'react-datetime';
import PropTypes from 'prop-types';

class DateTimePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Object),
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]),
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]).isRequired,
    timeFormat: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
  };

    static defaultProps = {
      options: [],
      timeFormat: 'HH:mm:ss',
    };


  handleChange = (date) => {
    let newDate = date;
    if (typeof date === 'object') {
      newDate = date.format(this.props.timeFormat);
    }
    this.props.onChange(newDate);
  };

  render() {
    const {
      value, name, options, timeFormat,
    } = this.props;
    return (
      <div className="date-picker">
        <DatePicker
          name={name}
          value={value}
          options={options}
          timeFormat={timeFormat}
          onChange={this.handleChange}
          dateFormat={false}
        />
      </div>
    );
  }
}

const renderDateTimePickerField = props => (
  <div className="form__form-group-input-wrap">
    <DateTimePickerField
      {...props.input}
      options={props.options}
      timeFormat={props.timeFormat}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    label: PropTypes.string,
  })),
  timeFormat: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

renderDateTimePickerField.defaultProps = {
  meta: null,
  options: [],
  timeFormat: 'HH:mm:ss',
};

export default renderDateTimePickerField;
