/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import ServerInstanceActionRenderer from './actionRenderer';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class ServerInstanceDetailCellRenderer extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    data: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
  };
  constructor(props) {
    super(props);
    this.rowsToUpdateServerInstance =
      props.context.rowsToUpdateDetail[props.node.id] || {};
    this.stylesToUpdate = props.context.stylesToUpdateDetail[props.node.id] || {};
    const { literals } = this.props;
    this.state = {
      modules: AllModules,
      frameworkComponentsServerInstance: {
        actionRenderer: ServerInstanceActionRenderer,
      },
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      colDefsServerInstance: [
        {
          headerName: literals.forms.server_instance_form.name,
          field: 'name',
          editable: false,
          pinned: 'left',
          cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.forms.server_instance_form.code,
          field: 'code',
          editable: false,
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.forms.server_instance_form.server_address,
          field: 'server_address',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.forms.server_instance_form.zmq_address,
          field: 'zmq_address',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.forms.server_instance_form.client,
          field: 'client_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: [],
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.tables.ag_table.actions,
          field: 'actions',
          cellRenderer: 'actionRenderer',
          filter: false,
          editable: false,
        },
      ],
      rowDataServerInstance: [],
      rowOldData: [],
      cellEditUpdateServerInstance:
        props.context.cellEditUpdateDetail[props.node.id],
      getRowNodeIdServerInstance(data) {
        return data.name;
      },
    };

    this.state.rowID = props.node.id ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff') : props.node.id;
    this.gridWrapperID = 'grid-wrapper-sidebar_server_instance';
    this.myGridID = `myGrid_sidebar_server_instance_${this.state.rowID}`;
    this.state.masterGridApi = props.api;
    this.onGridReadyServerInstance = this.onGridReadyServerInstance.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridInfoServerInstance.columnApi) {
      const { literals } = this.props;
      if (literals.forms.server_instance_form.name !== prevProps.literals.forms.server_instance_form.name) {
        this.gridInfoServerInstance.columnApi.getColumn('name').getColDef().headerName
          = literals.forms.server_instance_form.name;
        this.gridInfoServerInstance.columnApi.getColumn('code').getColDef().headerName
          = literals.forms.server_instance_form.code;
        this.gridInfoServerInstance.columnApi.getColumn('server_address').getColDef().headerName
          = literals.forms.server_instance_form.server_address;
        this.gridInfoServerInstance.columnApi.getColumn('zmq_address').getColDef().headerName
          = literals.forms.server_instance_form.zmq_address;
        this.gridInfoServerInstance.columnApi.getColumn('client_value').getColDef().headerName
          = literals.forms.server_instance_form.client;
        this.gridInfoServerInstance.columnApi.getColumn('actions').getColDef().headerName =
          literals.tables.ag_table.actions;
        this.gridInfoServerInstance.api.refreshHeader();
      }
    }
  }

  componentWillUnmount = () => {
    if (this.gridInfoServerInstance) {
      window[`colStateServerInstance${this.state.rowID}`] = this.gridInfoServerInstance.columnApi.getColumnState();
      window[`groupStateServerInstance${this.state.rowID}`]
        = this.gridInfoServerInstance.columnApi.getColumnGroupState();
      window[`filterStateServerInstance${this.state.rowID}`] = this.gridInfoServerInstance.api.getFilterModel();
    }

    const detailGridId = this.createDetailGridId();

    // ag-Grid is automatically destroyed
    this.state.masterGridApi.removeDetailGridInfo(detailGridId);
  };

  onCellClass=params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
    typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

  onCellValueChangedServerInstance=(params) => {
    if (params.oldValue != params.value) {
      if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
        this.stylesToUpdate[params.node.id] = {};
        this.gridInfoServerInstance.api.refreshCells({ rowNodes: [params.node], columns: ['name'], force: true });
      }
      this.stylesToUpdate[params.node.id][params.colDef.field] = {};
      this.gridInfoServerInstance.api.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

      if (params.colDef.field === 'client_value') {
        params.data.client =
          this.props.context.formData.ClientObj[params.newValue].value;
      }
      this.rowsToUpdateServerInstance[`${params.node.id}`] = params.data;
      this.setState({ cellEditUpdateServerInstance: true });
      this.props.context.updateStateDetail(
        this.state.rowID,
        true, this.rowsToUpdateServerInstance, this.stylesToUpdate,
      );
    }
  };

  onGridSizeChanged=(params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onGridReadyServerInstance(params) {
    const detailGridId = this.createDetailGridId();

    this.gridInfoServerInstance = {
      id: detailGridId,
      api: params.api,
      columnApi: params.columnApi,
    };
    this.gridInfoServerInstance.api.showLoadingOverlay();
    this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfoServerInstance);

    this.gridInfoServerInstance.columnApi.getColumn('client_value').getColDef().cellEditorParams.values =
      this.props.context.formData.Client ?
        this.props.context.formData.Client.map(a => a.label) : [];

    if (window[`colStateServerInstance${this.state.rowID}`]) {
      this.gridInfoServerInstance.columnApi.setColumnState(window[`colStateServerInstance${this.state.rowID}`]);
    }
    if (window[`groupStateServerInstance${this.state.rowID}`]) {
      this.gridInfoServerInstance.columnApi.setColumnGroupState(window[`groupStateServerInstance${this.state.rowID}`]);
    }
    if (window[`filterStateServerInstance${this.state.rowID}`]) {
      this.gridInfoServerInstance.api.setFilterModel(window[`filterStateServerInstance${this.state.rowID}`]);
    }
    this.setState({ rowDataServerInstance: this.props.data.server_instances });
    this.setState({ rowOldData: JSON.parse(JSON.stringify(this.props.data.server_instances)) });
    this.onGridSizeChanged(params);
  }

  onColumnResized=(params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcelServerInstance=() => {
    const params = {
      columnKeys: ['name', 'code', 'server_address', 'zmq_address', 'client_value'],
      fileName: 'ServerInstance.xlsx',
      suppressTextAsCDATA: true,
    };
    this.gridInfoServerInstance.api.exportDataAsExcel(params);
  };

  onBtExportCSVServerInstance=() => {
    const params = {
      columnKeys: ['name', 'code', 'server_address', 'zmq_address', 'client_value'],
      fileName: 'ServerInstance.csv',
    };
    this.gridInfoServerInstance.api.exportDataAsCsv(params);
  };

  createDetailGridId = () => `detail_${this.state.rowID}`;

  updateServerInstance=() => {
    this.props.context.showLoader();
    const len = Object.keys(this.rowsToUpdateServerInstance).length;
    let i = 0;
    let j = 0;
    const successItems = [];
    const failItems = [];
    const errorItems = [];
    const rowData = _.keyBy(this.state.rowOldData, 'name');
    Object.values(this.rowsToUpdateServerInstance).forEach((values) => {
      const oldRow = rowData[values.name];
      const dataToUpdate = {
        name: values.name,
        server_address: values.server_address,
        zmq_address: values.zmq_address,
        client: values.client,
        old_data: {
          name: oldRow.name,
          server_address: oldRow.server_address,
          zmq_address: oldRow.zmq_address,
          client: oldRow.client,
        },
      };
      axios.put(`${process.env.REACT_APP_BACKEND_API}/admin/server_instance`, JSON.stringify(dataToUpdate))
        .then((response) => {
          i += 1;
          if (response.data.status) {
            successItems.push(values.name);
          } else {
            failItems.push(values.name);
          }
          if (len === i) {
            if (successItems.length > 0) {
              showNotification(
                this.props.literals.forms.server_instance_form.title_update, 'primary',
                `${this.props.literals.forms.success_message} - ${successItems.join(', ')}`,
              );
            }
            if (failItems.length > 0) {
              showNotification(
                this.props.literals.forms.server_instance_form.title_update, 'danger',
                `${this.props.literals.forms.fail_message} - ${failItems.join(', ')}`,
              );
            }
            this.resetServerInstance();
          }
        })
        .catch((error) => {
          j += 1;
          errorItems.push(values.name);
          if (len === j) {
            if (errorItems.length > 0) {
              showNotification(
                this.props.literals.forms.server_instance_form.title_update,
                'danger', `${error.message} - ${errorItems.join(', ')}`,
              );
            }
            this.resetServerInstance();
          }
        });
    });
  };

  resetServerInstance=() => {
    this.rowsToUpdateServerInstance = {};
    this.setState({ cellEditUpdateServerInstance: false });
    this.props.context.resetClient();
    window[`colStateServerInstance${this.state.rowID}`] = this.gridInfoServerInstance.columnApi.getColumnState();
    window[`groupStateServerInstance${this.state.rowID}`] = this.gridInfoServerInstance.columnApi.getColumnGroupState();
    window[`filterStateServerInstance${this.state.rowID}`] = this.gridInfoServerInstance.api.getFilterModel();
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate).forEach((keys) => {
      const node = this.gridInfoServerInstance.api.getRowNode(keys);
      if (node) {
        this.gridInfoServerInstance.api.refreshCells({ rowNodes: [node], columns: ['name'], force: true });
        Object.keys(stylesToUpdate[keys]).forEach((values) => {
          this.gridInfoServerInstance.api.refreshCells({ rowNodes: [node], columns: [values], force: true });
        });
      }
    });
    this.props.context.updateStateDetail(
      this.state.rowID,
      false, this.rowsToUpdateServerInstance, this.stylesToUpdate,
    );
  };

  render() {
    const { literals } = this.props;
    return (
      <div style={{
        boxSizing: 'border-box', width: '100%', height: '100%',
      }}
      >
        <div className="outer-grid-label">
          {literals.sidebar.server_instance} (
          {literals.tables.ag_table.total_records}: {this.state.rowDataServerInstance.length})
        </div>
        <div className="outer-grid-button">
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.save}>
              <Button
                style={{
                  display: this.state.cellEditUpdateServerInstance ? '' : 'none',
                }}
                onClick={this.updateServerInstance}
                className="icon"
                color="primary"
              ><p className="fa fa-save" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.undo}>
              <Button
                style={{
                  display: this.state.cellEditUpdateServerInstance ? '' : 'none',
                }}
                onClick={() => {
                  this.props.context.showLoader();
                  this.resetServerInstance();
                }}
                className="icon"
                color="primary"
              ><p
                className="fas fa-undo"
              />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button onClick={this.onBtExportExcelServerInstance} className="icon" color="primary">
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_csv}>
              <Button
                onClick={this.onBtExportCSVServerInstance}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-alt" />
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </div>
        <div
          id={this.myGridID}
          style={{ width: '100%', height: this.props.node.rowHeight - REDUCE_HEIGHT, clear: 'both' }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.colDefsServerInstance}
            stopEditingWhenCellsLoseFocus
            suppressCellSelection
            suppressContextMenu
            immutableData
            getRowNodeId={this.state.getRowNodeIdServerInstance}
            frameworkComponents={this.state.frameworkComponentsServerInstance}
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResized}
            onCellValueChanged={this.onCellValueChangedServerInstance}
            rowData={this.state.rowDataServerInstance}
            onGridReady={this.onGridReadyServerInstance}
            onGridSizeChanged={this.onGridSizeChanged}
            rowHeight={20}
            context={{
              resetClient: this.props.context.resetClient,
              showLoader: this.props.context.showLoader,
              hideLoader: this.props.context.hideLoader,
            }}
          />
        </div>
      </div>

    );
  }
}

export default connect(state => ({
  literals: state.literals,
}))(withRouter(ServerInstanceDetailCellRenderer));

