/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import { Tooltip } from 'react-tippy';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import {
  numberFormatter,
  onGridSizeChanged,
  onColumnResized
} from '../../shared/helper';
import { BasicNotification } from '../../shared/components/Notification';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import HeaderComponent from '../../shared/components/multi_edit/HeaderComponent';
import SessionRenderer from '../../shared/components/session/SessionRenderer';
import SessionHeaderComponent from '../../shared/components/multi_edit/SessionHeaderComponent';

const REDUCE_HEIGHT = 40;
let notification = null;
NotificationSystem.newInstance({}, (n) => (notification = n));
const showNotification = (title, color, message) => {
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up'
  });
};

@inject('rootStore')
class MarkupGroupDetailCellRenderer extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    theme: ThemeProps.isRequired,
    data: PropTypes.instanceOf(Object).isRequired,
    api: PropTypes.instanceOf(Object).isRequired,
    context: PropTypes.instanceOf(Object).isRequired,
    node: PropTypes.instanceOf(Object).isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired
  };
  constructor(props) {
    super(props);
    this.rowsToUpdate = props.context.rowsToUpdateDetail[props.node.id] || {};
    this.stylesToUpdate =
      props.context.stylesToUpdateDetail[props.node.id] || {};
    const { literals } = this.props;
    const { role } = props.rootStore.authStore;
    this.state = {
      modules: AllModules,
      colDefs: [
        {
          headerName: literals.forms.markup_symbol_form.symbol,
          field: 'symbol_val',
          editable: false,
          pinned: 'left',
          cellClassRules: {
            'arrow-right': (params) =>
              typeof this.stylesToUpdate[params.node.id] !== 'undefined'
          },
          width: 60,
          minWidth: 60
        },
        {
          headerName: literals.forms.markup_symbol_form.security,
          field: 'security',
          editable: role === 'admin',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: role === 'admin' ? 'cellHeaderComponent' : '',
          headerComponentParams: {
            selectValueLiteral: 'Security'
          }
        },
        {
          headerName: literals.forms.markup_symbol_form.markup_group,
          field: 'markup_group',
          editable: false
        },
        {
          headerName: literals.forms.markup_symbol_form.digits,
          field: 'digits',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.markup_symbol_form.sessions,
          field: 'sessions',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 60,
          minWidth: 60,
          headerComponent: 'sessionHeaderComponent',
          editable: false,
          cellRenderer: 'sessionRenderer'
        },
        {
          headerName: literals.forms.markup_symbol_form.markup_bid,
          field: 'markup_bid',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: { customName: 'Points' }
        },
        {
          headerName: literals.forms.markup_symbol_form.markup_ask,
          field: 'markup_ask',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: { customName: 'Points' }
        },
        {
          headerName: literals.forms.markup_symbol_form.markup_bid_var,
          field: 'markup_bid_var',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: { customName: 'Points' }
        },
        {
          headerName: literals.forms.markup_symbol_form.markup_ask_var,
          field: 'markup_ask_var',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: { customName: 'Points' }
        },
        {
          headerName: literals.forms.markup_symbol_form.spread_min,
          field: 'spread_min',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: { customName: 'Points' }
        },
        {
          headerName: literals.forms.markup_symbol_form.spread_max,
          field: 'spread_max',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: { customName: 'Points' }
        },
        {
          headerName: literals.forms.markup_symbol_form.min_spread_exec,
          field: 'min_spread_exec_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'MinSpreadExec'
          }
        },
        {
          headerName: literals.forms.markup_symbol_form.spread_exec,
          field: 'spread_exec_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: []
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent',
          headerComponentParams: {
            selectValueLiteral: 'SpreadExec'
          }
        },
        {
          headerName: literals.forms.markup_symbol_form.extra_digits,
          field: 'extra_digits',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.markup_symbol_form.state,
          field: 'state_value',
          editable: false,
          cellRenderer: 'stateRenderer',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'stateHeaderComponent'
        },
        {
          headerName: literals.forms.markup_symbol_form.level,
          field: 'level',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.markup_symbol_form.wl_bid,
          field: 'wl_bid',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.markup_symbol_form.wl_ask,
          field: 'wl_ask',
          cellClass: 'number',
          valueFormatter(params) {
            return numberFormatter(params.value);
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
          headerComponent: 'cellHeaderComponent'
        },
        {
          headerName: literals.forms.lp_symbol_form.description,
          field: 'description',
          editable: false,
          tooltipField: 'description'
        }
      ],
      frameworkComponents: {
        stateRenderer: StateRenderer,
        stateHeaderComponent: StateHeaderComponent,
        cellHeaderComponent: HeaderComponent,
        sessionRenderer: SessionRenderer,
        sessionHeaderComponent: SessionHeaderComponent
      },
      rowData: [],
      rowOldData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50
      },
      cellEditUpdate: props.context.cellEditUpdateDetail[props.node.id],
      getRowNodeId(data) {
        return data.symbol_val;
      }
    };

    this.state.rowID = props.node.id
      ? props.node.id.replace(/[^a-zA-Z0-9]/g, '70659eff')
      : props.node.id;
    this.gridWrapperID = 'grid-wrapper-sidebar_markup_group';
    this.myGridID = `myGrid_sidebar_markup_symbol_${this.state.rowID}`;
    this.state.masterGridApi = props.api;
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.gridInfo.columnApi) {
      const { literals } = this.props;
      if (
        literals.forms.markup_symbol_form.symbol !==
        prevProps.literals.forms.markup_symbol_form.symbol
      ) {
        this.gridInfo.columnApi.getColumn('symbol_val').getColDef().headerName =
          literals.forms.markup_symbol_form.symbol;
        this.gridInfo.columnApi.getColumn('security').getColDef().headerName =
          literals.forms.markup_symbol_form.security;
        this.gridInfo.columnApi
          .getColumn('markup_group')
          .getColDef().headerName =
          literals.forms.markup_symbol_form.markup_group;
        this.gridInfo.columnApi.getColumn('digits').getColDef().headerName =
          literals.forms.markup_symbol_form.digits;
        this.gridInfo.columnApi.getColumn('sessions').getColDef().headerName =
          literals.forms.markup_symbol_form.sessions;
        this.gridInfo.columnApi.getColumn('markup_bid').getColDef().headerName =
          literals.forms.markup_symbol_form.markup_bid;
        this.gridInfo.columnApi.getColumn('markup_ask').getColDef().headerName =
          literals.forms.markup_symbol_form.markup_ask;
        this.gridInfo.columnApi
          .getColumn('markup_bid_var')
          .getColDef().headerName =
          literals.forms.markup_symbol_form.markup_bid_var;
        this.gridInfo.columnApi
          .getColumn('markup_ask_var')
          .getColDef().headerName =
          literals.forms.markup_symbol_form.markup_ask_var;
        this.gridInfo.columnApi.getColumn('spread_min').getColDef().headerName =
          literals.forms.markup_symbol_form.spread_min;
        this.gridInfo.columnApi.getColumn('spread_max').getColDef().headerName =
          literals.forms.markup_symbol_form.spread_max;
        this.gridInfo.columnApi
          .getColumn('spread_exec_value')
          .getColDef().headerName =
          literals.forms.markup_symbol_form.spread_exec;
        this.gridInfo.columnApi
          .getColumn('min_spread_exec_value')
          .getColDef().headerName =
          literals.forms.markup_symbol_form.min_spread_exec;
        this.gridInfo.columnApi
          .getColumn('extra_digits')
          .getColDef().headerName =
          literals.forms.markup_symbol_form.extra_digits;
        this.gridInfo.columnApi
          .getColumn('state_value')
          .getColDef().headerName = literals.forms.markup_symbol_form.state;
        this.gridInfo.columnApi.getColumn('level').getColDef().headerName =
          literals.forms.markup_symbol_form.level;
        this.gridInfo.columnApi.getColumn('wl_bid').getColDef().headerName =
          literals.forms.markup_symbol_form.wl_bid;
        this.gridInfo.columnApi.getColumn('wl_ask').getColDef().headerName =
          literals.forms.markup_symbol_form.wl_ask;
        this.gridInfo.columnApi
          .getColumn('description')
          .getColDef().headerName = literals.forms.lp_symbol_form.description;
        this.gridInfo.api.refreshHeader();
      }
    }
  }

  componentWillUnmount = () => {
    if (this.gridInfo) {
      window[`colStateMarkupGroup${this.state.rowID}`] =
        this.gridInfo.columnApi.getColumnState();
      window[`groupStateMarkupGroup${this.state.rowID}`] =
        this.gridInfo.columnApi.getColumnGroupState();
      window[`filterStateMarkupGroup${this.state.rowID}`] =
        this.gridInfo.api.getFilterModel();
    }

    const detailGridId = this.createDetailGridId();

    // ag-Grid is automatically destroyed
    this.state.masterGridApi.removeDetailGridInfo(detailGridId);
  };

  onCellClass = (params) =>
    typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
    typeof this.stylesToUpdate[params.node.id][params.colDef.field] !==
      'undefined';

  onCellValueChanged = (params) => {
    if (params.oldValue != params.value) {
      if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
        this.stylesToUpdate[params.node.id] = {};
        this.gridInfo.api.refreshCells({
          rowNodes: [params.node],
          columns: ['symbol_val'],
          force: true
        });
      }
      this.stylesToUpdate[params.node.id][params.colDef.field] = {};
      this.gridInfo.api.refreshCells({
        rowNodes: [params.node],
        columns: [params.column],
        force: true
      });

      if (params.colDef.field === 'state_value') {
        params.data.state =
          this.props.context.formData.StateObj[params.newValue].value;
      }
      if (params.colDef.field === 'spread_exec_value') {
        params.data.spread_exec =
          this.props.context.formData.SpreadExecObj[params.newValue].value;
      }
      if (params.colDef.field === 'min_spread_exec_value') {
        params.data.min_spread_exec =
          this.props.context.formData.MinSpreadExecObj[params.newValue].value;
      }
      this.rowsToUpdate[`${params.node.id}`] = params.data;
      this.setState({ cellEditUpdate: true });
      this.props.context.updateStateDetail(
        this.state.rowID,
        true,
        this.rowsToUpdate,
        this.stylesToUpdate
      );
    }
  };

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onGridReady(params) {
    const detailGridId = this.createDetailGridId();

    this.gridInfo = {
      id: detailGridId,
      api: params.api,
      columnApi: params.columnApi
    };
    this.gridInfo.api.showLoadingOverlay();
    this.state.masterGridApi.addDetailGridInfo(detailGridId, this.gridInfo);

    this.gridInfo.columnApi
      .getColumn('security')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .Security
      ? this.props.context.formData.Security.map((a) => a.label)
      : [];
    this.gridInfo.columnApi
      .getColumn('spread_exec_value')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .SpreadExec
      ? this.props.context.formData.SpreadExec.map((a) => a.label)
      : [];
    this.gridInfo.columnApi
      .getColumn('min_spread_exec_value')
      .getColDef().cellEditorParams.values = this.props.context.formData
      .MinSpreadExec
      ? this.props.context.formData.MinSpreadExec.map((a) => a.label)
      : [];
    if (window[`colStateMarkupGroup${this.state.rowID}`]) {
      this.gridInfo.columnApi.setColumnState(
        window[`colStateMarkupGroup${this.state.rowID}`]
      );
    }
    if (window[`groupStateMarkupGroup${this.state.rowID}`]) {
      this.gridInfo.columnApi.setColumnGroupState(
        window[`groupStateMarkupGroup${this.state.rowID}`]
      );
    }
    if (window[`filterStateMarkupGroup${this.state.rowID}`]) {
      this.gridInfo.api.setFilterModel(
        window[`filterStateMarkupGroup${this.state.rowID}`]
      );
    }
    this.setState({ rowData: this.props.data.markup_symbols });
    this.setState({
      rowOldData: JSON.parse(JSON.stringify(this.props.data.markup_symbols))
    });
    this.onGridSizeChanged(params);
  }

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: [
        'symbol_val',
        'security',
        'markup_group',
        'digits',
        'sessions',
        'markup_bid',
        'markup_ask',
        'markup_bid_var',
        'markup_ask_var',
        'spread_min',
        'spread_max',
        'min_spread_exec_value',
        'spread_exec_value',
        'extra_digits',
        'state_value',
        'level',
        'wl_bid',
        'wl_ask',
        'description'
      ],
      fileName: 'MarkupSymbol.xlsx',
      suppressTextAsCDATA: true
    };
    this.gridInfo.api.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: [
        'symbol_val',
        'security',
        'markup_group',
        'digits',
        'sessions',
        'markup_bid',
        'markup_ask',
        'markup_bid_var',
        'markup_ask_var',
        'spread_min',
        'spread_max',
        'min_spread_exec_value',
        'spread_exec_value',
        'extra_digits',
        'state_value',
        'level',
        'wl_bid',
        'wl_ask',
        'description'
      ],
      fileName: 'MarkupSymbol.csv'
    };
    this.gridInfo.api.exportDataAsCsv(params);
  };

  createDetailGridId = () => `detail_${this.state.rowID}`;

  updateMarkupGroupSymbol = () => {
    this.props.context.showLoader();
    const bulkData = [];
    const rowData = _.keyBy(this.state.rowOldData, 'symbol_val');
    Object.values(this.rowsToUpdate).forEach((values) => {
      const oldRow = rowData[values.symbol_val];
      const dataToUpdate = {
        symbol_val: values.symbol_val,
        security: `${values.security}{${values.wl_bid},${values.wl_ask}}`,
        markup_group: values.markup_group,
        digits: parseInt(values.digits, 10),
        sessions: values.sessions,
        markup_bid: parseFloat(values.markup_bid),
        markup_ask: parseFloat(values.markup_ask),
        markup_bid_var: parseFloat(values.markup_bid_var),
        markup_ask_var: parseFloat(values.markup_ask_var),
        spread_min: parseFloat(values.spread_min),
        spread_max: parseFloat(values.spread_max),
        spread_exec: parseFloat(values.spread_exec),
        min_spread_exec: parseFloat(values.min_spread_exec),
        extra_digits: parseFloat(values.extra_digits),
        state: parseInt(values.state, 10),
        level: parseInt(values.level, 10),
        old_data: {
          symbol_val: oldRow.symbol_val,
          security: `${oldRow.security}{${oldRow.wl_bid},${oldRow.wl_ask}}`,
          markup_group: oldRow.markup_group,
          digits: parseInt(oldRow.digits, 10),
          sessions: oldRow.sessions,
          markup_bid: parseFloat(oldRow.markup_bid),
          markup_ask: parseFloat(oldRow.markup_ask),
          markup_bid_var: parseFloat(oldRow.markup_bid_var),
          markup_ask_var: parseFloat(oldRow.markup_ask_var),
          spread_min: parseFloat(oldRow.spread_min),
          spread_max: parseFloat(oldRow.spread_max),
          spread_exec: parseFloat(oldRow.spread_exec),
          min_spread_exec: parseFloat(oldRow.min_spread_exec),
          extra_digits: parseFloat(oldRow.extra_digits),
          state: parseInt(oldRow.state, 10),
          level: parseInt(oldRow.level, 10)
        }
      };
      bulkData.push(dataToUpdate);
    });
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/security/markup_symbol_bulk`,
        JSON.stringify(bulkData)
      )
      .then((response) => {
        this.resetMarkupGroupSymbol();
        showNotification(
          this.props.literals.sidebar.markup_symbol,
          'primary',
          this.props.literals.forms.completed_message
        );
        if (response.data.length > 0) {
          showNotification(
            this.props.literals.sidebar.markup_symbol,
            'danger',
            `${
              this.props.literals.forms.fail_message
            } - ${response.data.toString()}}`
          );
        }
      })
      .catch((error) => {
        this.resetMarkupGroupSymbol();
        showNotification(
          this.props.literals.sidebar.markup_symbol,
          'danger',
          `${this.props.literals.forms.fail_message} - ${error.message}}`
        );
      });
  };

  resetMarkupGroupSymbol = () => {
    this.rowsToUpdate = {};
    this.setState({ cellEditUpdate: false });
    this.props.context.resetMarkupGroup();
    window[`colStateMarkupGroup${this.state.rowID}`] =
      this.gridInfo.columnApi.getColumnState();
    window[`groupStateMarkupGroup${this.state.rowID}`] =
      this.gridInfo.columnApi.getColumnGroupState();
    window[`filterStateMarkupGroup${this.state.rowID}`] =
      this.gridInfo.api.getFilterModel();
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate).forEach((keys) => {
      const node = this.gridInfo.api.getRowNode(keys);
      if (node) {
        this.gridInfo.api.refreshCells({
          rowNodes: [node],
          columns: ['symbol_val'],
          force: true
        });
        Object.keys(stylesToUpdate[keys]).forEach((values) => {
          this.gridInfo.api.refreshCells({
            rowNodes: [node],
            columns: [values],
            force: true
          });
        });
      }
    });
    this.props.context.updateStateDetail(
      this.state.rowID,
      false,
      this.rowsToUpdate,
      this.stylesToUpdate
    );
  };

  render() {
    const { literals } = this.props;
    return (
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          height: '100%'
        }}
      >
        <div className="outer-grid-label">
          {literals.sidebar.markup_symbol} (
          {literals.tables.ag_table.total_records}: {this.state.rowData.length})
        </div>
        <div className="outer-grid-button">
          <ButtonToolbar>
            <Tooltip title={literals.tables.ag_table.save}>
              <Button
                style={{
                  display: this.state.cellEditUpdate ? '' : 'none'
                }}
                onClick={this.updateMarkupGroupSymbol}
                className="icon"
                color="primary"
              >
                <p className="fa fa-save" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.undo}>
              <Button
                style={{
                  display: this.state.cellEditUpdate ? '' : 'none'
                }}
                onClick={() => {
                  this.props.context.showLoader();
                  this.resetMarkupGroupSymbol();
                }}
                className="icon"
                color="primary"
              >
                <p className="fas fa-undo" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_excel}>
              <Button
                onClick={this.onBtExportExcel}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-excel" />
              </Button>
            </Tooltip>
            <Tooltip title={literals.tables.ag_table.export_csv}>
              <Button
                onClick={this.onBtExportCSV}
                className="icon"
                color="primary"
              >
                <p className="fa fa-file-alt" />
              </Button>
            </Tooltip>
          </ButtonToolbar>
        </div>
        <div
          id={this.myGridID}
          style={{
            width: '100%',
            height: this.props.node.rowHeight - REDUCE_HEIGHT,
            clear: 'both'
          }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.colDefs}
            stopEditingWhenCellsLoseFocus
            suppressCellSelection
            suppressContextMenu
            immutableData
            getRowNodeId={this.state.getRowNodeId}
            frameworkComponents={this.state.frameworkComponents}
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResized}
            onCellValueChanged={this.onCellValueChanged}
            rowData={this.state.rowData}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.onGridSizeChanged}
            rowHeight={20}
            context={{
              formData: this.props.context.formData,
              showLoader: this.props.context.showLoader,
              hideLoader: this.props.context.hideLoader,
              theme: this.props.theme,
              onCellValueChanged: this.onCellValueChanged
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  literals: state.literals,
  theme: state.theme
}))(withRouter(MarkupGroupDetailCellRenderer));
