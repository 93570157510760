/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import NotificationSystem from 'rc-notification';
import axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { change } from 'redux-form';
import GiveUpRuleActionRenderer from './actionRenderer';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import GiveUpRuleForm from './form';
import giveUpRuleAction from './action';
import Loader from '../../shared/components/Loader';
import { onGridSizeChanged, onColumnResized } from '../../shared/helper';
import StateRenderer from '../../shared/components/StateRenderer';
import StateHeaderComponent from '../../shared/components/multi_edit/StateHeaderComponent';
import MultiEditor from './MultiEditor';

const REDUCE_HEIGHT = 200;
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));
let notification = null;
NotificationSystem.newInstance({}, n => notification = n);
const showNotification = (title, color, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={color}
    />,
    duration: 3,
    closable: true,
    style: { top: 0 },
    className: 'left-up',
  });
};

@inject('rootStore')
class GiveUpRule extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.rowsToUpdate = {};
    this.stylesToUpdate = {};
    const { literals } = this.props;
    const tableID = 'sidebar_give_up_rule';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.forms.give_up_rule_form.id,
          field: 'id',
          cellClass: 'number',
          cellClassRules: { 'arrow-right': params => (typeof this.stylesToUpdate[params.node.id] !== 'undefined') },
          editable: false,
        },
        {
          headerName: literals.forms.give_up_rule_form.takers,
          field: 'takers',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.tems,
          field: 'tems',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.securities,
          field: 'securities',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.symbols,
          field: 'symbols',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.sides,
          field: 'sides_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: [],
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.source_extgroup,
          field: 'source_extgroup',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.source_extlogin,
          field: 'source_extlogin',
          cellClass: 'number',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.target_taker,
          field: 'target_taker',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: [],
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.target_login,
          field: 'target_login',
          cellClass: 'number',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.orig_markup,
          field: 'orig_markup_value',
          editable: false,
          cellRenderer: 'stateRenderer',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.markup_model,
          field: 'markup_model',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: [],
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.ratio,
          field: 'ratio_value',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
          width: 70,
          minWidth: 70,
        },
        {
          headerName: literals.forms.give_up_rule_form.gur_mode,
          field: 'gur_mode_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: [],
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.filtration_pool_form.makers,
          field: 'makers',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.gur_stl_mode,
          field: 'gur_stl_mode_value',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: [],
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.priority,
          field: 'priority',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: [],
          },
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.symbol_format,
          field: 'symbol_format',
          editable: false,
          cellRenderer: 'multiEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.description,
          field: 'description',
          cellEditor: 'agLargeTextCellEditor',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.forms.give_up_rule_form.state,
          field: 'state_value',
          editable: false,
          cellRenderer: 'stateRenderer',
          cellClassRules: { 'edit-row-color': this.onCellClass },
        },
        {
          headerName: literals.tables.ag_table.actions,
          field: 'actions',
          cellRenderer: 'actionRenderer',
          filter: false,
          editable: false,
        },
      ],
      frameworkComponents: {
        actionRenderer: GiveUpRuleActionRenderer,
        multiEditor: MultiEditor,
        stateRenderer: StateRenderer,
        stateHeaderComponent: StateHeaderComponent,
      },
      rowData: [],
      rowOldData: [],
      formData: [],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      getRowNodeId(data) {
        return data.id;
      },
      cellEditUpdate: false,
      activeTab: '1',
      height: window.innerHeight,
      loaderShow: false,
      values: [{
        tag: '',
        val: '',
      }],
    };
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (literals.forms.give_up_rule_form.takers !== prevProps.literals.forms.give_up_rule_form.takers) {
        this.gridColumnApi.getColumn('takers')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.takers;
        this.gridColumnApi.getColumn('id')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.id;
        this.gridColumnApi.getColumn('tems')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.tems;
        this.gridColumnApi.getColumn('target_taker')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.target_taker;
        this.gridColumnApi.getColumn('securities')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.securities;
        this.gridColumnApi.getColumn('symbols')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.symbols;
        this.gridColumnApi.getColumn('sides_value')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.sides;
        this.gridColumnApi.getColumn('source_extlogin')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.source_extlogin;
        this.gridColumnApi.getColumn('source_extgroup')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.source_extgroup;
        this.gridColumnApi.getColumn('target_login')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.target_login;
        this.gridColumnApi.getColumn('gur_mode_value')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.gur_mode;
        this.gridColumnApi.getColumn('makers')
          .getColDef().headerName =
          literals.forms.filtration_pool_form.makers;
        this.gridColumnApi.getColumn('gur_stl_mode_value')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.gur_stl_mode;
        this.gridColumnApi.getColumn('symbol_format')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.symbol_format;
        this.gridColumnApi.getColumn('ratio_value')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.ratio;
        this.gridColumnApi.getColumn('orig_markup_value')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.orig_markup;
        this.gridColumnApi.getColumn('markup_model')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.markup_model;
        this.gridColumnApi.getColumn('priority')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.priority;
        this.gridColumnApi.getColumn('description')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.description;
        this.gridColumnApi.getColumn('state_value')
          .getColDef().headerName =
          literals.forms.give_up_rule_form.state;
        this.gridColumnApi.getColumn('actions')
          .getColDef().headerName = literals.tables.ag_table.actions;
        this.gridApi.refreshHeader();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.onGridSizeChanged(params);
    axios.get(`${process.env.REACT_APP_BACKEND_API}/give_up_rule`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.GiveUpRule)) });
        this.gridColumnApi.getColumn('target_taker').getColDef().cellEditorParams.values =
          response.data.TargetTaker ? response.data.TargetTaker.map(a => a.value) : [];
        this.gridColumnApi.getColumn('markup_model').getColDef().cellEditorParams.values =
          response.data.MarkupModel ? response.data.MarkupModel.map(a => a.value) : [];
        this.gridColumnApi.getColumn('sides_value')
          .getColDef().cellEditorParams.values =
          response.data.Side ? response.data.Side.map(a => a.label) : [];
        this.gridColumnApi.getColumn('gur_mode_value')
          .getColDef().cellEditorParams.values =
          response.data.GurMode ? response.data.GurMode.map(a => a.label) : [];
        this.gridColumnApi.getColumn('gur_stl_mode_value')
          .getColDef().cellEditorParams.values =
          response.data.GurSTLMode ? response.data.GurSTLMode.map(a => a.label) : [];
        this.gridColumnApi.getColumn('priority')
          .getColDef().cellEditorParams.values =
          response.data.Priority ? response.data.Priority.map(a => parseInt(a.label, 10)) : [];
      })
      .catch(() => {
      });
  }

  onCellClass = params => typeof this.stylesToUpdate[params.node.id] !== 'undefined' &&
    typeof this.stylesToUpdate[params.node.id][params.colDef.field] !== 'undefined';

  onCellValueChanged = (params) => {
    if (params.oldValue != params.value) {
      if (typeof this.stylesToUpdate[params.node.id] === 'undefined') {
        this.stylesToUpdate[params.node.id] = {};
        this.gridApi.refreshCells({
          rowNodes: [params.node],
          columns: ['id'],
          force: true,
        });
      }
      this.stylesToUpdate[params.node.id][params.colDef.field] = {};
      this.gridApi.refreshCells({
        rowNodes: [params.node],
        columns: [params.column],
        force: true,
      });

      if (params.colDef.field === 'sides_value') {
        params.data.sides = this.state.formData.SideObj[params.newValue].value;
      }
      if (params.colDef.field === 'gur_mode_value') {
        params.data.gur_mode = this.state.formData.GurModeObj[params.newValue].value;
        if (params.data.gur_mode !== 1) {
          params.node.setDataValue('makers', '*,');
        }
      }
      if (params.colDef.field === 'gur_stl_mode_value') {
        params.data.gur_stl_mode = this.state.formData.GurSTLModeObj[params.newValue].value;
      }
      if (params.colDef.field === 'orig_markup_value') {
        params.data.orig_markup = this.state.formData.StateObj[params.newValue].value;
      }
      if (params.colDef.field === 'state_value') {
        params.data.state = this.state.formData.StateObj[params.newValue].value;
      }
      this.rowsToUpdate[`${params.node.id}`] = params.data;
      this.setState({ cellEditUpdate: true });
    }
  };

  onGridSizeChanged = (params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized = (params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  onBtExportExcel = () => {
    const params = {
      columnKeys: ['id', 'takers', 'tems', 'securities', 'symbols', 'sides_value',
        'source_extgroup', 'source_extlogin', 'target_taker', 'target_login', 'orig_markup_value',
        'markup_model', 'ratio_value', 'gur_mode_value', 'makers', 'gur_stl_mode_value',
        'priority', 'symbol_format', 'description', 'state_value'],
      fileName: 'GiveUpRule.xlsx',
      suppressTextAsCDATA: true,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtExportCSV = () => {
    const params = {
      columnKeys: ['id', 'takers', 'tems', 'securities', 'symbols', 'sides_value',
        'source_extgroup', 'source_extlogin', 'target_taker', 'target_login', 'orig_markup_value',
        'markup_model', 'ratio_value', 'gur_mode_value', 'makers', 'gur_stl_mode_value',
        'priority', 'symbol_format', 'description', 'state_value'],
    };
    this.gridApi.exportDataAsCsv(params);
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  updateData = (data) => {
    this.setState({ rowData: data.GiveUpRule });
    this.setState({
      formData: {
        Taker: data.Taker,
        TakerExecutionModel: data.TakerExecutionModel,
        TargetTaker: data.TargetTaker,
        Security: data.Security,
        Symbol: data.Symbol,
        Side: data.Side,
        SideObj: arrayToObject(data.Side, 'label'),
        GurMode: data.GurMode,
        GurModeObj: arrayToObject(data.GurMode, 'label'),
        Maker: data.Maker,
        GurSTLMode: data.GurSTLMode,
        GurSTLModeObj: arrayToObject(data.GurSTLMode, 'label'),
        Ratio: data.Ratio,
        RatioObj: arrayToObject(data.Ratio, 'label'),
        MarkupModel: data.MarkupModel,
        Priority: data.Priority,
        SecuritySymbol: data.SecuritySymbol,
        State: data.State,
        StateObj: arrayToObject(data.State, 'label'),
        SymbolFormatPrefix: [{ label: '#ts#', value: '#ts#' },
          { label: '#us#', value: '#us#' }],
      },
    });
    this.props.dispatch(change('give_up_rule_form', 'takers_tree', ['*,']));
    this.props.dispatch(change('give_up_rule_form', 'tems_tree', ['*,']));
    this.props.dispatch(change('give_up_rule_form', 'securities_tree', ['*,']));
    this.props.dispatch(change('give_up_rule_form', 'symbols_tree', ['*,']));
    this.props.dispatch(change('give_up_rule_form', 'makers', ['*,']));
  };

  updateGiveUpRule = () => {
    this.showLoader();
    const len = Object.keys(this.rowsToUpdate).length;
    let i = 0;
    let j = 0;
    const successItems = [];
    const failItems = [];
    const errorItems = [];
    const rowData = _.keyBy(this.state.rowOldData, 'id');
    Object.values(this.rowsToUpdate)
      .forEach((values) => {
        const oldRow = rowData[values.id];
        const dataToUpdate = {
          id: parseInt(values.id, 10),
          takers: values.takers,
          tems: values.tems,
          target_taker: values.target_taker,
          securities: values.securities,
          symbols: values.symbols,
          sides: parseInt(values.sides, 10),
          source_extlogin: parseInt(values.source_extlogin, 10),
          source_extgroup: values.source_extgroup,
          target_login: parseInt(values.target_login, 10),
          gur_mode: parseInt(values.gur_mode, 10),
          makers: values.makers,
          gur_stl_mode: parseInt(values.gur_stl_mode, 10),
          symbol_format: values.symbol_format,
          ratio: parseFloat(values.ratio),
          orig_markup: parseInt(values.orig_markup, 10),
          markup_model: values.markup_model,
          priority: parseInt(values.priority, 10),
          description: values.description,
          state: parseInt(values.state, 10),
          old_data: {
            id: parseInt(oldRow.id, 10),
            takers: oldRow.takers,
            tems: oldRow.tems,
            target_taker: oldRow.target_taker,
            securities: oldRow.securities,
            symbols: oldRow.symbols,
            sides: parseInt(oldRow.sides, 10),
            source_extlogin: parseInt(oldRow.source_extlogin, 10),
            source_extgroup: oldRow.source_extgroup,
            target_login: parseInt(oldRow.target_login, 10),
            gur_mode: parseInt(oldRow.gur_mode, 10),
            makers: oldRow.makers,
            gur_stl_mode: parseInt(oldRow.gur_stl_mode, 10),
            symbol_format: oldRow.symbol_format,
            ratio: parseFloat(oldRow.ratio),
            orig_markup: parseInt(oldRow.orig_markup, 10),
            markup_model: oldRow.markup_model,
            priority: parseInt(oldRow.priority, 10),
            description: oldRow.description,
            state: parseInt(oldRow.state, 10),
          },
        };
        axios.put(
          `${process.env.REACT_APP_BACKEND_API}/give_up_rule`,
          JSON.stringify(dataToUpdate),
        )
          .then((response) => {
            i += 1;
            if (response.data.status) {
              successItems.push(values.id);
            } else {
              failItems.push(values.id);
            }
            if (len === i) {
              if (successItems.length > 0) {
                showNotification(
                  this.props.literals.forms.give_up_rule_form.title_update, 'primary',
                  `${this.props.literals.forms.success_message} - ${successItems.join(', ')}`,
                );
              }
              if (failItems.length > 0) {
                showNotification(
                  this.props.literals.forms.give_up_rule_form.title_update, 'danger',
                  `${this.props.literals.forms.fail_message} - ${failItems.join(', ')}`,
                );
              }
              this.resetLocalGiveUpRule();
              this.resetGiveUpRule();
            }
          })
          .catch((error) => {
            j += 1;
            errorItems.push(values.id);
            if (len === j) {
              if (errorItems.length > 0) {
                showNotification(
                  this.props.literals.forms.give_up_rule_form.title_update,
                  'danger', `${error.message} - ${errorItems.join(', ')}`,
                );
              }
              this.resetLocalGiveUpRule();
              this.resetGiveUpRule();
            }
          });
      });
  };

  resetLocalGiveUpRule = () => {
    this.rowsToUpdate = {};
    this.setState({ cellEditUpdate: false });
    const stylesToUpdate = { ...this.stylesToUpdate };
    this.stylesToUpdate = {};
    Object.keys(stylesToUpdate)
      .forEach((keys) => {
        const node = this.gridApi.getRowNode(keys);
        if (node) {
          this.gridApi.refreshCells({
            rowNodes: [node],
            columns: ['id'],
            force: true,
          });
          Object.keys(stylesToUpdate[keys])
            .forEach((values) => {
              this.gridApi.refreshCells({
                rowNodes: [node],
                columns: [values],
                force: true,
              });
            });
        }
      });
  };

  resetGiveUpRule = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/give_up_rule`)
      .then((response) => {
        this.updateData(response.data);
        this.setState({ rowOldData: JSON.parse(JSON.stringify(response.data.GiveUpRule)) });
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  showLoader = () => {
    this.setState({ loaderShow: true });
  };

  hideLoader = () => {
    this.setState({ loaderShow: false });
  };

  handleValuesChange = (values) => {
    this.setState({ values });
  };

  render() {
    const { literals } = this.props;
    const theme = this.props.theme.className;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="tabs tabs--bordered-top tabs__custom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      {literals.sidebar.give_up_rule}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      {literals.forms.give_up_rule_form.title_create}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="outer-grid-label">
                      {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
                    </div>
                    <div className="outer-grid-button">
                      <ButtonToolbar>
                        <Tooltip title={literals.tables.ag_table.save}>
                          <Button
                            style={{
                              display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                            onClick={this.updateGiveUpRule}
                            className="icon"
                            color="primary"
                          ><p className="fa fa-save" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.undo}>
                          <Button
                            style={{
                              display: this.state.cellEditUpdate ? '' : 'none',
                            }}
                            onClick={() => {
                              this.showLoader();
                              this.resetLocalGiveUpRule();
                              this.resetGiveUpRule();
                            }}
                            className="icon"
                            color="primary"
                          ><p
                            className="fas fa-undo"
                          />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.export_excel}>
                          <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                            <p className="fa fa-file-excel" />
                          </Button>
                        </Tooltip>
                        <Tooltip title={literals.tables.ag_table.export_csv}>
                          <Button
                            onClick={this.onBtExportCSV}
                            className="icon"
                            color="primary"
                          >
                            <p className="fa fa-file-alt" />
                          </Button>
                        </Tooltip>
                      </ButtonToolbar>
                    </div>
                    <div
                      id={this.gridWrapperID}
                      style={{
                      width: '100%',
                      height: '100%',
                      clear: 'both',
                    }}
                    >
                      <div
                        id={this.myGridID}
                        style={{
                          boxSizing: 'border-box',
                          height: this.state.height - REDUCE_HEIGHT,
                          width: '100%',
                        }}
                        className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                      >
                        <AgGridReact
                          modules={this.state.modules}
                          columnDefs={this.state.columnDefs}
                          stopEditingWhenCellsLoseFocus
                          suppressCellSelection
                          suppressContextMenu
                          immutableData
                          getRowNodeId={this.state.getRowNodeId}
                          defaultColDef={this.state.defaultColDef}
                          onColumnResized={this.onColumnResized}
                          onCellValueChanged={this.onCellValueChanged}
                          rowData={this.state.rowData}
                          frameworkComponents={this.state.frameworkComponents}
                          onGridReady={this.onGridReady}
                          onGridSizeChanged={this.onGridSizeChanged}
                          rowHeight={20}
                          context={{
                            formData: this.state.formData,
                            theme: this.props.theme,
                            resetGiveUpRule: this.resetGiveUpRule,
                            showLoader: this.showLoader,
                            hideLoader: this.hideLoader,
                          }}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <GiveUpRuleForm
                      {...this.props}
                      state={{
                        formData: this.state.formData,
                        resetGiveUpRule: this.resetGiveUpRule,
                        toggle: this.toggle,
                        showLoader: this.showLoader,
                        hideLoader: this.hideLoader,
                      }}
                      onSubmit={giveUpRuleAction}
                      valuesData={this.state.values}
                      onChangeValues={this.handleValuesChange}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Loader display={this.state.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(GiveUpRule);
