import React, { PureComponent } from 'react';
import DatePicker from 'react-datetime';
import PropTypes from 'prop-types';

class DateTimePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Object),
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]),
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]).isRequired,
    isValidDate: PropTypes.func,
    disabled: PropTypes.bool,
  };

    static defaultProps = {
      options: [],
      isValidDate: () => true,
      disabled: false,
    };

    constructor(props) {
      super(props);
      this.state = {
        startDate: null,
      };
    }

  handleChange = (date) => {
    let newDate = date;
    if (typeof date === 'object') {
      newDate = date.format('DD-MM-YYYY');
    }
    this.setState({
      startDate: newDate,
    });
    this.props.onChange(newDate);
  };

  render() {
    const {
      value, name, options, isValidDate, disabled,
    } = this.props;
    return (
      <div className="date-picker">
        <DatePicker
          name={name}
          value={value}
          options={options}
          timeFormat={false}
          selected={this.state.startDate}
          onChange={this.handleChange}
          dateFormat="DD-MM-YYYY"
          isValidDate={isValidDate}
          inputProps={{ disabled }}
        />
      </div>
    );
  }
}

const renderDateTimePickerField = props => (
  <div className="form__form-group-input-wrap">
    <DateTimePickerField
      {...props.input}
      options={props.options}
      isValidDate={props.isValidDate}
      disabled={props.disabled}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Object),
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    label: PropTypes.string,
  })),
  isValidDate: PropTypes.func,
  disabled: PropTypes.bool,
};

renderDateTimePickerField.defaultProps = {
  meta: null,
  options: [],
  isValidDate: () => true,
  disabled: false,
};

export default renderDateTimePickerField;
