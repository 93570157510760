import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  CHANGE_LANGUAGE_TO_EN,
  CHANGE_LANGUAGE_TO_CN,
  CHANGE_LANGUAGE_TO_JA,
  CHANGE_LANGUAGE_TO_HI,
} from '../actions/languageActions';

const initialState = {
  languageName: 'en',
};

const LanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_TO_EN:
      return { languageName: 'en' };
    case CHANGE_LANGUAGE_TO_CN:
      return { languageName: 'cn' };
    case CHANGE_LANGUAGE_TO_JA:
      return { languageName: 'ja' };
    case CHANGE_LANGUAGE_TO_HI:
      return { languageName: 'hi' };
    default:
      return state;
  }
};
const persistConfig = {
  key: 'language',
  storage,
};

export default persistReducer(persistConfig, LanguageReducer);
