/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import RiskEditorForm from './riskEditorForm';

class SessionRenderer extends PureComponent {
    static propTypes = {
      context: PropTypes.instanceOf(Object).isRequired,
      value: PropTypes.instanceOf(Array),
      node: PropTypes.instanceOf(Object).isRequired,
      colDef: PropTypes.instanceOf(Object).isRequired,
    };
    static defaultProps = {
      value: [],
    }
    state = {
      sessionModalShow: false,
    };
    toggleModalSession = () => {
      this.setState({ sessionModalShow: !this.state.sessionModalShow });
    };
    showModalSession = () => {
      if (!this.state.sessionModalShow) {
        this.setState({ sessionModalShow: true });
      }
    };
    handleSubmit=(values) => {
      const riskAccounts = values.riskAccounts.map((risk, i) => ({
        alias: risk.alias,
        risk_account: risk.risk_account.value,
        taker: risk.taker.value,
        tem: risk.tem.value,
        taker_feed: risk.taker_feed.value,
        default_risk: parseInt(values.default_risk, 10) === i ? 1 : 0,
        read_only: risk.read_only ? 1 : 0,
        daily_statement: risk.daily_statement ? 1 : 0,
        ext_order_info: risk.ext_order_info ? 1 : 0,
        master_risk_account: risk.risk_account.master_risk_account,
      }));
      const rowNode = this.props.node;
      rowNode.setDataValue(this.props.colDef.field, riskAccounts);
      this.toggleModalSession();
    };
    render() {
      const theme = this.props.context.theme.className;
      return (
        <div onDoubleClick={this.showModalSession}>
          <label>
            {this.props.context
            .userRiskAccountsFormat(this.props.node.data.user_risk_accounts)}
          </label>
          <Modal
            isOpen={this.state.sessionModalShow}
            toggle={() => this.toggleModalSession}
            className={`modal-dialog--primary modal-dialog--header crp-modal ${theme}`}
            style={{ maxWidth: document.body.offsetWidth / 1.2, minWidth: 800 }}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleModalSession()} />
              <h4 className="bold-text  modal__title">{this.props.colDef.headerName}</h4>
            </div>
            <div className="modal__body risk_account_modal_body">
              <RiskEditorForm
                {...this.props}
                onSubmit={this.handleSubmit}
                toggleModalSession={this.toggleModalSession}
                state={{
                    formData: this.props.context.formData,
                }}
              />
            </div>
            <div className="modal__footer" />
          </Modal>
        </div>
      );
    }
}
export default SessionRenderer;
