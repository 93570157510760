/* eslint-disable  react/no-array-index-key */
/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import TradingPanel from './TradingPanel';
import { numberFormatter } from '../../shared/helper';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

class OrderLogView extends PureComponent {
  static propTypes = {
    orderLogWatch: PropTypes.instanceOf(Array),
    literals: LiteralProps.isRequired,
  };
  static defaultProps = {
    orderLogWatch: [],
  }
  columns = [
    {
      accessor: 'transact_time',
      Header: this.props.literals.tables.response_table.res_recv_time_mcs_value,
      minWidth: 100,
      Cell: row => (
        row.value ? moment.utc(row.value, 'DD-MM-YYYY HH:mm:ss')
          .format('DD-MM-YYYY HH:mm:ss') : '-'
      ),
    },
    {
      accessor: 'cl_ord_id', Header: this.props.literals.tables.order_table.client_ord_id, minWidth: 130,
    },
    {
      accessor: 'ord_status',
      Header: this.props.literals.tables.order_table.state,
      minWidth: 70,
      Cell: row => (
        <span className={row.value === 'REJECTED' ? 'cell-renderer-red' :
          row.value === 'FILLED' || row.value === 'PARTIALLY_FILLED' ? 'cell-renderer-green' :
            row.value === 'CANCELED' ? 'cell-renderer-orange' : ''}
        >
          {row.value ? row.value.toUpperCase() : ''}
        </span>
      ),
    },
    {
      accessor: 'symbol', Header: this.props.literals.tables.order_table.symbol, minWidth: 70,
    },
    {
      accessor: 'side',
      Header: this.props.literals.tables.order_table.side_value,
      minWidth: 50,
      Cell: row => (
        <span className={row.value === 'Sell' ? 'cell-renderer-red' : 'cell-renderer-blue'}>
          {row.value ? row.value.toUpperCase() : ''}
        </span>
      ),
    },
    {
      accessor: 'ord_type', Header: this.props.literals.tables.order_table.ord_type_value, minWidth: 50,
    },
    {
      accessor: 'order_qty',
      Header: this.props.literals.tables.order_table.volume_value,
      minWidth: 50,
      style: {
        textAlign: 'right',
      },
      Cell: props => numberFormatter(props.value),
    },
    {
      accessor: 'fill_qty',
      Header: this.props.literals.tables.order_table.fill_volume_value,
      minWidth: 50,
      style: {
        textAlign: 'right',
      },
      Cell: props => numberFormatter(props.value),
    },
    {
      accessor: 'price',
      Header: this.props.literals.tables.order_table.price,
      minWidth: 50,
      style: {
        textAlign: 'right',
      },
      Cell: props => numberFormatter(props.value),
    },
    {
      accessor: 'avg_price',
      Header: this.props.literals.tables.order_table.avg_price,
      minWidth: 50,
      style: {
        textAlign: 'right',
      },
      Cell: props => numberFormatter(props.value),
    },
    {
      accessor: 'time_in_force', Header: this.props.literals.tables.order_table.time_in_force_value, minWidth: 50,
    },
    {
      accessor: 'deviation',
      Header: this.props.literals.tables.order_table.deviation,
      minWidth: 50,
      style: {
        textAlign: 'right',
      },
      Cell: props => numberFormatter(props.value),
    },
    {
      accessor: 'text', Header: this.props.literals.tables.order_table.text, minWidth: 50,
    },
  ];
  render() {
    const orderLogWatchData = this.props.orderLogWatch ?
      this.props.orderLogWatch : [];
    return (
      <TradingPanel
        md={8}
        divider
        title={this.props.literals.tables.trading_table.order_logs}
        className="depth depth-order"
      >
        <ReactTable
          data={orderLogWatchData}
          columns={this.columns}
          showPageSizeOptions={false}
          defaultPageSize={16}
          filterable
          sortable
          defaultFilterMethod={(filter, row) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      }}
        />
      </TradingPanel>
    );
  }
}
export default connect(state => ({
  literals: state.literals,
}))(OrderLogView);
