/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import classnames from 'classnames';
import { inject } from 'mobx-react';
import axios from 'axios';
import Websocket from 'react-websocket';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import MarketWatchSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import MarketWatchView from './MarketWatchView';
import MarketWatchListView from './MarketWatchListView';
import Loader from '../../shared/components/Loader';
import { setMarketWatchColor } from '../../redux/actions/marketWatchActions';

const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

@inject('rootStore')
class MarketWatch extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      search_values: PropTypes.instanceOf(Object),
      rootStore: PropTypes.instanceOf(Object).isRequired,
      dispatch: PropTypes.func.isRequired,
    };
    static defaultProps = {
      search_values: {},
    };
    constructor(props) {
      super(props);
      this.state = {
        activeTab: '1',
        selectedSymbols: [],
        loaderShow: false,
        collapse: '',
        marketWatch: [],
        marketWatchLast: [],
        symbol: [],
      };
    }

    componentDidMount() {
      setTimeout(() => {
        const symbols = this.props.search_values.symbol ? this.props.search_values.symbol : [];
        const feedSymbols = symbols.reduce((result, symbol) => {
          const { reportState } = this.props.rootStore.reportStore;
          if (symbol && reportState.alias) {
            result.push(`${reportState.alias}||${symbol}`);
          } return result;
        }, []);
        const dataToSend = {
          feed_symbols: feedSymbols,
        };
        axios.post(
          `${process.env.REACT_APP_BACKEND_API}/trading/market_data?page=market_watch`,
          JSON.stringify(dataToSend),
        )
          .then((response) => {
            this.setState({ marketWatchLast: response.data });
          })
          .catch(() => {
          });
      }, 0);
    }

    componentWillUnmount() {
      clearTimeout(this.timerCollapse);
    }

    handleSymbolChange=(symbol) => {
      this.setState({ symbol });
    }

    handleSubmit=(values) => {
      const symbolItems = [];
      if (this.state.symbol) {
        this.state.symbol.forEach((item) => {
          symbolItems.push(...item.children);
        });
      }
      const symbolItem = arrayToObject(symbolItems, 'value');
      const selectedSymbols = [];
      if (values.symbol) {
        values.symbol.forEach((item) => {
          selectedSymbols.push(symbolItem[item]);
        });
      }
      this.setState({ selectedSymbols });
      const symbols = selectedSymbols || [];
      const { reportState } = this.props.rootStore.reportStore;
      const feedSymbols = symbols.reduce((result, symbol) => {
        if (typeof symbol === 'object'
          && reportState.alias
          && typeof symbol.value !== 'undefined' && typeof symbol.uni_symbol !== 'undefined') {
          result.push(`${reportState.alias}||${symbol.value}||${symbol.uni_symbol}`);
        } return result;
      }, []);
      this.sendMessage(JSON.stringify({ key: 'symbol', value: feedSymbols.join(',') }));
      const feedSymbolsToSend = symbols.reduce((result, symbol) => {
        if (typeof symbol === 'object'
          && reportState.alias
          && typeof symbol.value !== 'undefined') {
          result.push(`${reportState.alias}||${symbol.value}`);
        } return result;
      }, []);
      const dataToSend = {
        feed_symbols: feedSymbolsToSend,
      };
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/trading/market_data?page=market_watch`,
        JSON.stringify(dataToSend),
      )
        .then((response) => {
          this.setState({ marketWatchLast: response.data });
        })
        .catch(() => {
        });
      this.setState({ collapse: 'force-close' });
      setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
    };

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        });
      }
    };

    showLoader = () => {
      this.setState({ loaderShow: true });
    };

    hideLoader = () => {
      this.setState({ loaderShow: false });
    };

    socketMessageListener = (event) => {
      const data = JSON.parse(event);
      if ('feed_symbols' in data) {
        if (data.feed_symbols && data.feed_symbols.length) {
          this.setState({ marketWatch: data.feed_symbols });
        } else {
          this.props.dispatch(setMarketWatchColor(false));
          this.props.dispatch(setMarketWatchColor(true));
        }
      }
    };
    socketOpenListener = () => {
      const symbolItems = [];
      if (this.state.symbol) {
        this.state.symbol.forEach((item) => {
          symbolItems.push(...item.children);
        });
      }
      const symbolItem = arrayToObject(symbolItems, 'value');
      const selectedSymbols = [];
      if (this.props.search_values.symbol && this.state.symbol) {
        this.props.search_values.symbol.forEach((item) => {
          selectedSymbols.push(symbolItem[item]);
        });
      }
      this.setState({ selectedSymbols });
      const symbols = selectedSymbols || [];
      const { reportState } = this.props.rootStore.reportStore;
      const feedSymbols = symbols.reduce((result, symbol) => {
        if (typeof symbol === 'object'
          && reportState.alias
          && typeof symbol.value !== 'undefined' && typeof symbol.uni_symbol !== 'undefined') {
          result.push(`${reportState.alias}||${symbol.value}||${symbol.uni_symbol}`);
        } return result;
      }, []);
      this.sendMessage(JSON.stringify({ key: 'symbol', value: feedSymbols.join(',') }));
      this.sendMessage(JSON.stringify({ key: 'fix_conn_status', value: [] }));
    };
    sendMessage=(message) => {
      this.refWebSocket.sendMessage(message);
    };

    render() {
      const { literals } = this.props;
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.market_watch}
                </h5>
              </div>
              <Collapse
                title={literals.tables.trading_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <MarketWatchSearchForm
                  onSubmit={this.handleSubmit}
                  marketWatchSend={this.socketOpenListener}
                  showLoader={this.showLoader}
                  hideLoader={this.hideLoader}
                  handleSymbolChange={this.handleSymbolChange}
                />
              </Collapse>
              <div className="tabs tabs--bordered-top">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                                        this.toggle('1');
                                    }}
                      >
                        {literals.tables.trading_table.ticket}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                                        this.toggle('2');
                                    }}
                      >
                        {literals.tables.trading_table.list}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <MarketWatchView
                        selectedSymbols={this.state.selectedSymbols}
                        marketWatch={this.state.marketWatch}
                        marketWatchLast={this.state.marketWatchLast}
                        activeTab={this.state.activeTab}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <MarketWatchListView
                        selectedSymbols={this.state.selectedSymbols}
                        marketWatch={this.state.marketWatch}
                        marketWatchLast={this.state.marketWatchLast}
                        activeTab={this.state.activeTab}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <Loader display={this.state.loaderShow} />
              <Websocket
                url={`${process.env.REACT_APP_BACKEND_WS}?q=${this.props.rootStore.clientStore.CC}` +
                `&token=${this.props.rootStore.authStore.token}`}
                reconnectIntervalInMilliSeconds={1000}
                onMessage={this.socketMessageListener}
                onOpen={this.socketOpenListener}
                ref={(WS) => {
                  this.refWebSocket = WS;
                }}
              />
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  literals: state.literals,
  search_values: getFormValues('market_watch_broker_user_search_form')(state),
}))(MarketWatch);
