/* eslint-disable  no-nested-ternary */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderInputNumberField from '../../shared/components/form/NumberInput';
import renderSelectField from '../../shared/components/form/SelectBox';

const renderField = ({
  input,
  placeholder,
  type,
  disabled,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

const bestAvail = 'Best Available';

class PositionCloseForm extends PureComponent {
  static propTypes = {
    literals: LiteralProps.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hidePopoverClose: PropTypes.func.isRequired,
    order_type: PropTypes.instanceOf(Object),
    change: PropTypes.func.isRequired,
  };
  static defaultProps = {
    order_type: {},
  };

  onOrderTypeChange = (selectedOption) => {
    if (selectedOption.value === 'Market') {
      this.props.change('price', bestAvail);
    } else {
      this.props.change('price', 0);
    }
  };

  render() {
    const { handleSubmit, literals } = this.props;
    const { submit } = literals.forms.uni_symbol_form;
    const orderType = [
      {
        label: 'Market',
        value: 'Market',
      },
      {
        label: 'Limit',
        value: 'Limit',
      },
      {
        label: 'Stop',
        value: 'Stop',
      },
    ];
    const validity = [
      {
        label: 'IOC',
        value: 'IOC',
      },
      {
        label: 'FOK',
        value: 'FOK',
      },
      {
        label: 'GTC',
        value: 'GTC',
      },
    ];
    return (
      <form className="form  form--vertical" onSubmit={handleSubmit}>
        <div className="form__half form__search">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.ord_type_value}
            </span>
            <div className="form__form-group-field">
              <Field
                name="order_type"
                component={renderSelectField}
                type="text"
                options={orderType}
                onChange={this.onOrderTypeChange}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.price}
            </span>
            <div className="form__form-group-field">
              <Field
                name="price"
                component={renderInputNumberField}
                step={1}
                min={0}
                disabled={
                  this.props.order_type
                    ? this.props.order_type.value === 'Market'
                    : true
                }
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.deviation}
            </span>
            <div className="form__form-group-field">
              <Field
                name="deviation"
                component={renderInputNumberField}
                step={1}
                min={0}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.ext_login}
            </span>
            <div className="form__form-group-field">
              <Field
                name="client_login"
                component={renderField}
                type="number"
                min="1"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.trading_table.bid_price}
            </span>
            <div className="form__form-group-field">
              <Field
                name="bid_price"
                component={renderInputNumberField}
                step={1}
                min={0}
              />
            </div>
          </div>
        </div>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.volume_value}
            </span>
            <div className="form__form-group-field">
              <Field
                name="quantity"
                component={renderInputNumberField}
                step={1}
                min={0}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.validity}
            </span>
            <div className="form__form-group-field">
              <Field
                name="validity"
                component={renderSelectField}
                type="text"
                options={validity}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.ext_order}
            </span>
            <div className="form__form-group-field">
              <Field
                name="order_id"
                component={renderField}
                type="number"
                min="1"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.order_table.ext_group}
            </span>
            <div className="form__form-group-field">
              <Field name="client_group" component={renderField} />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {literals.tables.trading_table.ask_price}
            </span>
            <div className="form__form-group-field">
              <Field
                name="ask_price"
                component={renderInputNumberField}
                step={1}
                min={0}
              />
            </div>
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar form__button-toolbar-remove-top-padding">
          <Button color="primary" type="submit">
            {submit}
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => this.props.hidePopoverClose()}
          >
            {literals.forms.uni_symbol_form.cancel}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}
const selector = formValueSelector('position_close_form');
function mapStateToProps(state, ownProps) {
  return {
    literals: state.literals,
    theme: state.theme,
    order_type: selector(state, 'order_type'),
    update_tp_price: selector(state, 'update_tp_price'),
    initialValues: {
      order_type: {
        label: 'Market',
        value: 'Market',
      },
      price: bestAvail,
      quantity: Math.abs(ownProps.node.data.net_volume_value),
      validity: {
        label: 'IOC',
        value: 'IOC',
      },
      deviation: '',
    },
  };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'position_close_form', // a unique identifier for this form
})(PositionCloseForm));
