import { action, observable } from 'mobx';

class ClientStore {
  @observable CC;
  constructor() {
    this.reset();
  }
  @action
  reset() {
    this.CC = '';
  }
}
export default new ClientStore();

