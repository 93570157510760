import { Field } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import renderSelectField from '../../shared/components/form/SelectBox';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';

const namePrefix = 'tf_';
const TFForm = ({
  literals, hamSingle, mmSingle, dir,
}) => (
  <div className="borderValuesForm">
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_feed_symbol_form.aggregate_group}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}ham`}
          component={renderSelectField}
          type="text"
          options={hamSingle}
        />
      </div>
    </div>
    <div className="form__form-group" style={{ width: '25%', display: 'inline-block', paddingRight: 5 }}>
      <span
        className="form__form-group-label"
      >{literals.forms.node_feed_symbol_form.markup_group}
      </span>
      <div className="form__form-group-field">
        <Field
          name={`${dir}${namePrefix}mm`}
          component={renderSelectField}
          type="text"
          options={mmSingle}
        />
      </div>
    </div>
  </div>
);
TFForm.propTypes = {
  literals: LiteralProps.isRequired,
  hamSingle: PropTypes.instanceOf(Array),
  mmSingle: PropTypes.instanceOf(Array),
  dir: PropTypes.string.isRequired,
};
TFForm.defaultProps = {
  hamSingle: [],
  mmSingle: [],
};
export default TFForm;
