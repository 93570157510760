/* eslint-disable  no-continue */
/* eslint-disable  jsx-a11y/label-has-for */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Card, CardBody, Col, Table, Modal, Popover
  , PopoverHeader, PopoverBody, ButtonToolbar, Button } from 'reactstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import NotificationSystem from 'rc-notification';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import CheckIcon from 'mdi-react/CheckIcon';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';
import Loader from '../../shared/components/Loader';

const risks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));
let serverInstancesObject = {};

@inject('rootStore')
@observer
class ClientRiskProfiling extends Component {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    rootStore: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);
    axios.get(
      `${process.env.REACT_APP_BACKEND_API}/settings/client_server_instances`,
      { headers: { 'x-forward-client': props.rootStore.clientStore.CC } },
    )
      .then((response) => {
        props.rootStore.settingStore.ClientServerInstances = response.data;
        serverInstancesObject = arrayToObject(response.data, 'code');
      })
      .catch(() => {
      });
    axios.get(
      `${process.env.REACT_APP_BACKEND_API}/settings/symbols`,
      { headers: { 'x-forward-client': props.rootStore.clientStore.CC } },
    )
      .then((response) => {
        props.rootStore.settingStore.Symbols = response.data;
      })
      .catch(() => {
      });
    let notification = null;
    NotificationSystem.newInstance({}, n => notification = n);
    this.showNotification = (title, color, message) => {
      notification.notice({
        content: <BasicNotification
          title={title}
          message={message}
          color={color}
        />,
        duration: 3,
        closable: true,
        style: { top: 0 },
        className: 'left-up',
      });
    };
  }

  componentDidMount() {
    let { clientServerInstance } = this.props.rootStore.settingStore.CRPOthers;
    if (!clientServerInstance) {
      clientServerInstance = this.props.rootStore.clientStore.CC;
    }
    this.clientChange(clientServerInstance);
  }

  clientChange = (clientServerInstance) => {
    const url = `
    ${process.env.REACT_APP_BACKEND_API}/settings/client_risk_profiling?client_server_instance=${clientServerInstance}
    `;
    axios.get(
      url,
      { headers: { 'x-forward-client': this.props.rootStore.clientStore.CC } },
    )
      .then((response) => {
        const CRP__INIT = Object.assign({}, this.props.rootStore.settingStore.CRP__INIT);
        const CRP_OTHERS__INIT = Object.assign({}, this.props.rootStore.settingStore.CRP_OTHERS__INIT);
        CRP_OTHERS__INIT.clientServerInstance = clientServerInstance;
        if (clientServerInstance !== this.props.rootStore.clientStore.CC) {
          CRP_OTHERS__INIT.rowVisible = 'crp-row-hide';
        } else {
          CRP_OTHERS__INIT.rowVisible = '';
        }
        const crp = response.data || {};
        Object.keys(crp).forEach((entry) => {
          CRP__INIT[entry] = crp[entry];
        });
        const instrumentOthers = {};
        const instrumentRisks = {};
        const tempInstrument = Object.assign({}, crp.instrument || {});
        const tempSTPRuleVolumePercentage = Object.assign({}, crp.stpRuleVolumePercentage || {});
        const tempSTPRuleVolumeOver = Object.assign({}, crp.stpRuleVolumeOver || {});
        risks.forEach((key) => {
          if (tempInstrument[`Risk${key}`]) {
            tempInstrument[`Risk${key}`].forEach((symbol) => {
              instrumentRisks[`Risk${key}${symbol}`] = true;
            });
            instrumentOthers[`checkedSymbolsLengthRisk${key}`] = tempInstrument[`Risk${key}`].length;
          }
          CRP_OTHERS__INIT.STP.stpRuleVolumeOver[`Risk${key}`] = !!tempSTPRuleVolumePercentage[`Risk${key}`];
          CRP_OTHERS__INIT.STP.stpRuleVolumePercentage[`Risk${key}`] = !!tempSTPRuleVolumeOver[`Risk${key}`];
        });
        CRP__INIT.instrument = instrumentRisks;
        CRP_OTHERS__INIT.instrumentOthers = instrumentOthers;
        this.props.rootStore.settingStore.CRP = CRP__INIT;
        this.props.rootStore.settingStore.CRPOthers = CRP_OTHERS__INIT;
      })
      .catch(() => {
      });
  };

  changeHandler = (event) => {
    const { CRP, CRPOthers } = this.props.rootStore.settingStore;
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    const names = name.split('.');
    CRP[names[0]][names[1]] = value;
    if (names[0] === 'stpRuleVolumePercentage') {
      CRPOthers.STP.stpRuleVolumeOver[names[1]] = value !== '';
    } else if (names[0] === 'stpRuleVolumeOver') {
      CRPOthers.STP.stpRuleVolumePercentage[names[1]] = value !== '';
    }
  };
  handleChangeInstrumentSelectAll = (event) => {
    const { CRP, Symbols, CRPOthers } = this.props.rootStore.settingStore;
    if (event.target.checked) {
      CRPOthers.instrumentOthers[`checkedSymbolsLength${CRPOthers.instrumentOthers.instrumentRisk}`] = Symbols.length;
    } else {
      CRPOthers.instrumentOthers[`checkedSymbolsLength${CRPOthers.instrumentOthers.instrumentRisk}`] = 0;
    }
    const instrumentRisks = Object.assign({}, CRP.instrument);
    Symbols.forEach((symbol) => {
      instrumentRisks[`${CRPOthers.instrumentOthers.instrumentRisk}${symbol}`] =
        event.target.checked;
    });
    CRP.instrument = instrumentRisks;
  };
  handleChangeInstrumentSymbol = (event, symbol) => {
    const { CRP, CRPOthers } = this.props.rootStore.settingStore;
    CRP.instrument[`${CRPOthers.instrumentOthers.instrumentRisk}${symbol}`] = event.target.checked;
    let checkedSymbolsLength =
      CRPOthers.instrumentOthers[`checkedSymbolsLength${CRPOthers.instrumentOthers.instrumentRisk}`] || 0;
    if (event.target.checked) {
      checkedSymbolsLength += 1;
    } else {
      checkedSymbolsLength -= 1;
    }
    CRPOthers.instrumentOthers[`checkedSymbolsLength${CRPOthers.instrumentOthers.instrumentRisk}`]
      = checkedSymbolsLength;
  };
  toggleInstrumentRisk = (key) => {
    const { CRPOthers } = this.props.rootStore.settingStore;
    CRPOthers.instrumentOthers[`modalInstrumentRisk${key}`] = !CRPOthers.instrumentOthers[`modalInstrumentRisk${key}`];
    CRPOthers.instrumentOthers.instrumentRisk = `Risk${key}`;
  };
  toggleLoginRisk = (key) => {
    const { CRPOthers } = this.props.rootStore.settingStore;
    CRPOthers.loginOthers[`modalLoginRisk${key}`] = !CRPOthers.loginOthers[`modalLoginRisk${key}`];
    CRPOthers.loginOthers.loginRisk = `Risk${key}`;
  };
  riskCheckboxItems = (item) => {
    const { CRP } = this.props.rootStore.settingStore;
    let k = 0;
    return risks.map((key) => {
      k += 1;
      return (
        <td key={k} className="crp-checkbox-td">
          <label
            className="checkbox-btn"
            htmlFor={`${item}.Risk${key}`}
          >
            <input
              className="checkbox-btn__checkbox"
              type="checkbox"
              id={`${item}.Risk${key}`}
              name={`${item}.Risk${key}`}
              onChange={this.changeHandler}
              checked={!!CRP[item][`Risk${key}`]}
            />
            <span
              className="checkbox-btn__checkbox-custom"
            >
              <CheckIcon />
            </span>
          </label>
        </td>
      );
    });
  };
  riskTextItems = (item) => {
    const { CRP, CRPOthers } = this.props.rootStore.settingStore;
    let k = 0;
    return risks.map((key) => {
      k += 1;
      return (
        <td key={k}>
          <input
            type="text"
            name={`${item}.Risk${key}`}
            value={CRP[item][`Risk${key}`] ? CRP[item][`Risk${key}`] : ''}
            onChange={this.changeHandler}
            disabled={!!CRPOthers.STP[item][`Risk${key}`]}
          />
        </td>
      );
    });
  };
  reCaptchaOnLoad = () => {
    this.props.rootStore.settingStore.CRPOthers.isRecaptchaVerified = false;
  };
  reCaptchaOnChange = (response) => {
    if (response) {
      this.props.rootStore.settingStore.CRPOthers.isRecaptchaVerified = true;
    }
  };
  toggleUpdate = () => {
    const { CRPOthers } = this.props.rootStore.settingStore;
    CRPOthers.popoverOpen = !CRPOthers.popoverOpen;
  };
  handleChange = (selectedOption) => {
    this.clientChange(selectedOption.value);
  };
  handleSubmit = () => {
    if (!this.props.rootStore.settingStore.CRPOthers.isRecaptchaVerified) {
      this.showNotification(
        'Client Risk Profiling', 'danger',
        'Recaptcha required!!!',
      );
      return;
    }
    this.showLoader();
    this.toggleUpdate();
    const { CRP, Symbols, CRPOthers } = this.props.rootStore.settingStore;
    let crp = {};
    if (CRPOthers.clientServerInstance !== this.props.rootStore.clientStore.CC) {
      crp.login = CRP.login;
      crp.stpRuleVolumePercentage = CRP.stpRuleVolumePercentage;
      crp.stpRuleVolumeOver = CRP.stpRuleVolumeOver;
    } else {
      crp = Object.assign({}, CRP);
    }
    crp.instrument = {};
    risks.forEach((key) => {
      const instrumentRiskSymbols = [];
      Symbols.forEach((symbol) => {
        if (CRP.instrument[`Risk${key}${symbol}`]) {
          instrumentRiskSymbols.push(symbol);
        }
      });
      if (instrumentRiskSymbols.length > 0) {
        crp.instrument[`Risk${key}`] = instrumentRiskSymbols;
      }
    });
    const crpFinal = {};
    Object.keys(crp).forEach((entry) => {
      if (Object.keys(crp[entry]).length > 0) {
        if (entry === 'instrument') {
          crpFinal[entry] = crp[entry];
        } else {
          Object.keys(crp[entry]).forEach((otherEntry) => {
            if (crp[entry][otherEntry]) {
              if (typeof crpFinal[entry] === 'undefined') {
                crpFinal[entry] = {};
              }
              if (entry === 'stpRuleVolumePercentage' || entry === 'stpRuleVolumeOver') {
                crpFinal[entry][otherEntry] = parseInt(crp[entry][otherEntry], 10);
              } else {
                crpFinal[entry][otherEntry] = crp[entry][otherEntry];
              }
            }
          });
        }
      }
    });
    if (!CRPOthers.clientServerInstance) {
      CRPOthers.clientServerInstance = this.props.rootStore.clientStore.CC;
    }
    const { clientServerInstance } = CRPOthers;
    const url = `
    ${process.env.REACT_APP_BACKEND_API}/settings/client_risk_profiling?client_server_instance=${clientServerInstance}
    `;
    axios.post(
      url, JSON.stringify(crpFinal),
      { headers: { 'x-forward-client': this.props.rootStore.clientStore.CC } },
    )
      .then((response) => {
        if (response.data.status) {
          this.showNotification(
            'Client Risk Profiling', 'primary',
            'Settings Updated',
          );
          this.hideLoader();
        } else {
          this.showNotification(
            'Client Risk Profiling', 'danger',
            'Settings Failed to Update',
          );
          this.hideLoader();
        }
      })
      .catch((error) => {
        this.showNotification('Client Risk Profiling', 'danger', error.message);
        this.hideLoader();
      });
  };

  showLoader = () => {
    this.props.rootStore.settingStore.CRPOthers.loaderShow = true;
  };

  hideLoader = () => {
    this.props.rootStore.settingStore.CRPOthers.loaderShow = false;
  };

  render() {
    const { literals } = this.props;
    const {
      CRP, Symbols, CRPOthers, ClientServerInstances,
    } = this.props.rootStore.settingStore;
    const theme = this.props.theme.className;

    const clientServerInstances = ClientServerInstances || [];
    const clientServerInstanceItems = clientServerInstances.map(clientServerInstance => ({
      value: clientServerInstance.code,
      label: `${clientServerInstance.name}`,
    }));

    let k = 0;
    const symbols = Symbols || [];
    const symbolItems = symbols.map((symbol) => {
      k += 1;
      return (
        <tr key={k}>
          <td>{symbol}</td>
          <td className="crp-checkbox-instrument-td">
            <label
              className="checkbox-btn"
              htmlFor={`${CRPOthers.instrumentOthers.instrumentRisk}${symbol}`}
            >
              <input
                className="checkbox-btn__checkbox"
                type="checkbox"
                id={`${CRPOthers.instrumentOthers.instrumentRisk}${symbol}`}
                name={`${CRPOthers.instrumentOthers.instrumentRisk}${symbol}`}
                onChange={e => this.handleChangeInstrumentSymbol(e, symbol)}
                checked={!!CRP.instrument[`${CRPOthers.instrumentOthers.instrumentRisk}${symbol}`]}
              />
              <span
                className="checkbox-btn__checkbox-custom"
              >
                <CheckIcon />
              </span>
            </label>
          </td>
        </tr>
      );
    });

    let kk = 0;
    const riskInstrumentItems = risks.map((key) => {
      kk += 1;
      const checkedSymbolsLength =
        CRPOthers.instrumentOthers[`checkedSymbolsLength${CRPOthers.instrumentOthers.instrumentRisk}`] || 0;
      const totalSymbolsLength = symbols.length;
      let selectAll = checkedSymbolsLength === totalSymbolsLength;
      if (checkedSymbolsLength <= 0 && totalSymbolsLength <= 0) {
        selectAll = false;
      }
      return (
        <td key={kk}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.toggleInstrumentRisk(key)}
          >Select
          </button>
          <Modal
            isOpen={CRPOthers.instrumentOthers[`modalInstrumentRisk${key}`]}
            toggle={() => this.toggleInstrumentRisk(key)}
            className={`modal-dialog--primary modal-dialog--header crp-modal ${theme}`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleInstrumentRisk(key)} />
              <h4 className="bold-text  modal__title">{literals.tables.crp_table.choose_instrument}</h4>
            </div>
            <div className="modal__body">
              <Table responsive>
                <thead>
                  <tr>
                    <th>{literals.tables.crp_table.instrument}</th>
                    <th>{literals.tables.crp_table.select}</th>
                  </tr>
                </thead>
                <tbody>
                  {symbolItems}
                </tbody>
              </Table>
            </div>
            <div className="modal__footer">
              <label
                className="checkbox-btn"
                htmlFor={`checkedInstrumentSelectAllRisk${key}`}
              >
                <input
                  className="checkbox-btn__checkbox"
                  type="checkbox"
                  id={`checkedInstrumentSelectAllRisk${key}`}
                  name={`checkedInstrumentSelectAllRisk${key}`}
                  onChange={this.handleChangeInstrumentSelectAll}
                  checked={selectAll}
                />
                <span
                  className="checkbox-btn__checkbox-custom"
                >
                  <CheckIcon />
                </span>
                <span className="checkbox-btn__label">
                  {literals.tables.crp_table.select_all}
                </span>
              </label>
            </div>
          </Modal>
        </td>
      );
    });

    let kkk = 0;
    const riskLoginItems = risks.map((key) => {
      kkk += 1;
      return (
        <td key={kkk}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.toggleLoginRisk(key)}
          >Select
          </button>
          <Modal
            isOpen={CRPOthers.loginOthers[`modalLoginRisk${key}`]}
            toggle={() => this.toggleLoginRisk(key)}
            className={`modal-dialog--primary modal-dialog--header crp-modal ${theme}`}
          >
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={() => this.toggleLoginRisk(key)} />
              <h4 className="bold-text  modal__title">{literals.tables.crp_table.choose_logins}</h4>
            </div>
            <div className="modal__body form">
              {literals.tables.crp_table.comma_separated_logins} 123456,456321
              <textarea
                rows="10"
                cols="50"
                className="textarea"
                name={`login.Risk${key}`}
                value={CRP.login[`Risk${key}`] ? CRP.login[`Risk${key}`] : ''}
                onChange={this.changeHandler}
              />
            </div>
          </Modal>
        </td>
      );
    });
    const selectValue = CRPOthers.clientServerInstance ?
      {
        label: serverInstancesObject[CRPOthers.clientServerInstance] ?
          serverInstancesObject[CRPOthers.clientServerInstance].name : CRPOthers.clientServerInstance,
        value: CRPOthers.clientServerInstance,
      } : null;
    const colourStyles = {
      control: styles => ({
        ...styles, minHeight: 22, height: 22, borderRadius: 0,
      }),
      indicatorsContainer: styles => ({ ...styles, height: 22 }),
      valueContainer: styles => ({ ...styles, height: 22, position: 'unset' }),
    };
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{literals.sidebar.quant_based_hybrid}</h5>
            </div>
            <form>
              <div className="crp-select form">
                <label>{literals.tables.crp_table.select_client_instance}</label>
                <Select
                  styles={colourStyles}
                  value={selectValue}
                  onChange={this.handleChange}
                  options={clientServerInstanceItems}
                  clearable={false}
                  className="form__form-group-select"
                  classNamePrefix="Select"
                />
              </div>
              <div className="form crp-table">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{literals.tables.crp_table.abbreviation}</th>
                      <th>{literals.tables.crp_table.title}</th>
                      <th>{literals.tables.crp_table.parameter}</th>
                      <th>{literals.tables.crp_table.value}</th>
                      <th>{literals.tables.crp_table.risk} 1</th>
                      <th>{literals.tables.crp_table.risk} 2</th>
                      <th>{literals.tables.crp_table.risk} 3</th>
                      <th>{literals.tables.crp_table.risk} 4</th>
                      <th>{literals.tables.crp_table.risk} 5</th>
                      <th>{literals.tables.crp_table.risk} 6</th>
                      <th>{literals.tables.crp_table.risk} 7</th>
                      <th>{literals.tables.crp_table.risk} 8</th>
                      <th>{literals.tables.crp_table.risk} 9</th>
                      <th>{literals.tables.crp_table.risk} 10</th>
                    </tr>
                  </thead>
                  <tbody className="crp-table-body">
                    <tr>
                      <td>IN</td>
                      <td>{literals.tables.crp_table.instrument}</td>
                      <td>{literals.tables.crp_table.select_instrument}</td>
                      <td>&nbsp;</td>
                      {riskInstrumentItems}
                    </tr>
                    <tr>
                      <td>LO</td>
                      <td>{literals.tables.crp_table.logins}</td>
                      <td>{literals.tables.crp_table.select_logins}</td>
                      <td>&nbsp;</td>
                      {riskLoginItems}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>MTG</td>
                      <td>{literals.tables.crp_table.mt_groups}</td>
                      <td>{literals.tables.crp_table.groups_list}</td>
                      <td>
                        <input
                          type="text"
                          name="mtGroups.Value"
                          value={CRP.mtGroups.Value ? CRP.mtGroups.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('mtGroups')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>AR</td>
                      <td>{literals.tables.crp_table.arbitrage}</td>
                      <td>{literals.tables.crp_table.count_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="arbitrage.Value"
                          value={CRP.arbitrage.Value ? CRP.arbitrage.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('arbitrage')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>AOI1</td>
                      <td>{literals.tables.crp_table.client_aggregated_open_interest}</td>
                      <td>{literals.tables.crp_table.client_aoi_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="clientAOI.Value"
                          value={CRP.clientAOI.Value ? CRP.clientAOI.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('clientAOI')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>CG</td>
                      <td>{literals.tables.crp_table.commission_generator}</td>
                      <td>{literals.tables.crp_table.commission_gt}</td>
                      <td>
                       P/L
                      </td>
                      {this.riskCheckboxItems('commissionGenerator')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>AOI2</td>
                      <td>{literals.tables.crp_table.company_aggregated_open_interest}</td>
                      <td>{literals.tables.crp_table.company_aoi_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="companyAOI.Value"
                          value={CRP.companyAOI.Value ? CRP.companyAOI.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('companyAOI')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>CL</td>
                      <td>{literals.tables.crp_table.current_leverage}</td>
                      <td>{literals.tables.crp_table.c_leverage_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="currentLeverage.Value"
                          value={CRP.currentLeverage.Value ? CRP.currentLeverage.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('currentLeverage')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>EA</td>
                      <td>{literals.tables.crp_table.expert_advisor}</td>
                      <td>{literals.tables.crp_table.using_ea}</td>
                      <td>
                       Yes
                      </td>
                      {this.riskCheckboxItems('expertAdvisor')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>HE</td>
                      <td>{literals.tables.crp_table.high_equity}</td>
                      <td>{literals.tables.crp_table.equity_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="highEquity.Value"
                          value={CRP.highEquity.Value ? CRP.highEquity.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('highEquity')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>WP</td>
                      <td>{literals.tables.crp_table.winning_percentage}</td>
                      <td>{literals.tables.crp_table.winning_perc_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="winningPercentage.Value"
                          value={CRP.winningPercentage.Value ? CRP.winningPercentage.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('winningPercentage')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>HF</td>
                      <td>{literals.tables.crp_table.high_frequency_trader}</td>
                      <td>{literals.tables.crp_table.Avg_trade_life_lt}</td>
                      <td>
                        <input
                          type="text"
                          name="hfTrader.Value"
                          value={CRP.hfTrader.Value ? CRP.hfTrader.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('hfTrader')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>ID</td>
                      <td>{literals.tables.crp_table.intraday_trader}</td>
                      <td>{literals.tables.crp_table.Avg_trade_life_lt}</td>
                      <td>
                       1 day
                      </td>
                      {this.riskCheckboxItems('intradayTrader')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>LL1</td>
                      <td>{literals.tables.crp_table.large_leverage}</td>
                      <td>{literals.tables.crp_table.Avg_leverage_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="largeLeverage.Value"
                          value={CRP.largeLeverage.Value ? CRP.largeLeverage.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('largeLeverage')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>LL2</td>
                      <td>{literals.tables.crp_table.larger_leverage}</td>
                      <td>{literals.tables.crp_table.Avg_leverage_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="largerLeverage.Value"
                          value={CRP.largerLeverage.Value ? CRP.largerLeverage.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('largerLeverage')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>LF</td>
                      <td>{literals.tables.crp_table.low_frequency_trader}</td>
                      <td>{literals.tables.crp_table.Avg_trade_life_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="lfTrader.Value"
                          value={CRP.lfTrader.Value ? CRP.lfTrader.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('lfTrader')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>EX</td>
                      <td>{literals.tables.crp_table.outstanding}</td>
                      <td>{literals.tables.crp_table.profit_factor_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="outstanding.Value"
                          value={CRP.outstanding.Value ? CRP.outstanding.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('outstanding')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>PF</td>
                      <td>{literals.tables.crp_table.profitable}</td>
                      <td>{literals.tables.crp_table.profit_factor_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="profitable.Value"
                          value={CRP.profitable.Value ? CRP.profitable.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('profitable')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>RPL1</td>
                      <td>{literals.tables.crp_table.realized_pl}</td>
                      <td>{literals.tables.crp_table.r_pl_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="realizedPL1.Value"
                          value={CRP.realizedPL1.Value ? CRP.realizedPL1.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('realizedPL1')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>RPL2</td>
                      <td>{literals.tables.crp_table.realized_pl}</td>
                      <td>{literals.tables.crp_table.r_pl_lt}</td>
                      <td>
                        <input
                          type="text"
                          name="realizedPL2.Value"
                          value={CRP.realizedPL2.Value ? CRP.realizedPL2.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('realizedPL2')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>TT1</td>
                      <td>{literals.tables.crp_table.time_trader_1_hour}</td>
                      <td>{literals.tables.crp_table.trades_count_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="tt1Hour.Value"
                          value={CRP.tt1Hour.Value ? CRP.tt1Hour.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('tt1Hour')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>TT5</td>
                      <td>{literals.tables.crp_table.time_trader_5_minutes}</td>
                      <td>{literals.tables.crp_table.trades_count_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="tt5Minutes.Value"
                          value={CRP.tt5Minutes.Value ? CRP.tt5Minutes.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('tt5Minutes')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>TS</td>
                      <td>{literals.tables.crp_table.trade_size}</td>
                      <td>{literals.tables.crp_table.trade_size_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="tradeSize.Value"
                          value={CRP.tradeSize.Value ? CRP.tradeSize.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('tradeSize')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>TV</td>
                      <td>{literals.tables.crp_table.trading_velocity}</td>
                      <td>{literals.tables.crp_table.avg_trade_count_day_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="tradingVelocity.Value"
                          value={CRP.tradingVelocity.Value ? CRP.tradingVelocity.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('tradingVelocity')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>UPL1</td>
                      <td>{literals.tables.crp_table.unrealized_pl}</td>
                      <td>{literals.tables.crp_table.unr_pl_gt}</td>
                      <td>
                        <input
                          type="text"
                          name="unrealizedPL1.Value"
                          value={CRP.unrealizedPL1.Value ? CRP.unrealizedPL1.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('unrealizedPL1')}
                    </tr>
                    <tr className={CRPOthers.rowVisible}>
                      <td>UPL2</td>
                      <td>{literals.tables.crp_table.unrealized_pl}</td>
                      <td>{literals.tables.crp_table.unr_pl_lt}</td>
                      <td>
                        <input
                          type="text"
                          name="unrealizedPL2.Value"
                          value={CRP.unrealizedPL2.Value ? CRP.unrealizedPL2.Value : ''}
                          onChange={this.changeHandler}
                        />
                      </td>
                      {this.riskCheckboxItems('unrealizedPL2')}
                    </tr>
                    <tr className="stp-tr-td-1">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>{literals.tables.crp_table.perc_of_volume}</td>
                      <td>&nbsp;</td>
                      {this.riskTextItems('stpRuleVolumePercentage')}
                    </tr>
                    <tr className="stp-tr">
                      <td>&nbsp;</td>
                      <td>{literals.tables.crp_table.stp_rule}</td>
                    </tr>
                    <tr className="stp-tr-td-2">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>{literals.tables.crp_table.volume_Over}</td>
                      <td>&nbsp;</td>
                      {this.riskTextItems('stpRuleVolumeOver')}
                    </tr>
                  </tbody>
                </Table>
              </div>
              <button
                ref={(c) => {
                  this.PopoverTop = c && ReactDOM.findDOMNode(c);
                }}
                type="button"
                className="btn btn-primary update-btn-crp"
                onClick={this.toggleUpdate}
              >{literals.tables.ag_table.update}
              </button>
              <Popover
                placement="top"
                isOpen={CRPOthers.popoverOpen}
                target={() => this.PopoverTop}
                toggle={this.toggleUpdate}
                className={theme === 'theme-light' ? 'trading-light' : 'trading-dark'}
              >
                <PopoverHeader>{literals.tables.crp_table.confirm_update}</PopoverHeader>
                <PopoverBody>
                  <span>{literals.tables.crp_table.confirm_update_note}
                  </span>
                  <div className="crp-re-captcha">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
                      size="compact"
                      theme={theme === 'theme-light' ? 'light' : 'dark'}
                      asyncScriptOnLoad={this.reCaptchaOnLoad}
                      onChange={this.reCaptchaOnChange}
                      onExpired={this.reCaptchaOnLoad}
                      onErrored={this.reCaptchaOnLoad}
                    />
                  </div>
                  <ButtonToolbar className="btn-toolbar--center">
                    <Button className="btn btn-primary" onClick={this.toggleUpdate}>
                      {literals.forms.cancel}
                    </Button>
                    <Button
                      className="btn btn-primary"
                      onClick={this.handleSubmit}
                    >
                      {literals.forms.confirm}
                    </Button>
                  </ButtonToolbar>
                </PopoverBody>
              </Popover>
            </form>
            <Loader display={this.props.rootStore.settingStore.CRPOthers.loaderShow} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(ClientRiskProfiling);
