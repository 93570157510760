import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import renderSelectField from '../../shared/components/form/SelectBox';
import renderTreeSelectField from './TreeSelect';
import renderInputNumberField from '../../shared/components/form/NumberInputCommon';
import renderCheckBoxField from '../../shared/components/form/CheckBox';

const renderField = ({
  input, placeholder, type, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class FiltrationPoolForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      theme: ThemeProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
      makers_pattern: PropTypes.bool,
      securities_pattern: PropTypes.bool,
      symbols_pattern: PropTypes.bool,
    };
    static defaultProps = {
      makers_pattern: false,
      securities_pattern: false,
      symbols_pattern: false,
    }

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals, theme,
      } = this.props;
      const disabled = false;
      const { submit } = literals.forms.filtration_pool_form;
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.id}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="id"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  precision={0}
                  placeholder={literals.forms.filtration_pool_form.id}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.makers}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="makers_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.makers_pattern &&
                <Field
                  name="makers_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Maker}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
                }
                {this.props.makers_pattern &&
                <Field
                  name="makers_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.filtration_pool_form.makers}
                />
                }
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.securities}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="securities_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.securities_pattern &&
                <Field
                  name="securities_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Security}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
                }
                {this.props.securities_pattern &&
                <Field
                  name="securities_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.filtration_pool_form.securities}
                />
                }
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.symbols}
              </span>
              <div className="form__form-group-field">
                <span className="marginLeftAuto">
                  <Field
                    name="symbols_pattern"
                    component={renderCheckBoxField}
                    label={literals.forms.maker_api_link_form.pattern}
                  />
                </span>
              </div>
              <span
                className="form__form-group-label"
              />
              <div className="form__form-group-field">
                {!this.props.symbols_pattern &&
                <Field
                  name="symbols_tree"
                  component={renderTreeSelectField}
                  treeData={this.props.state.formData.Symbol}
                  treeDefaultExpandAll={false}
                  treeDefaultExpandedKeys={['*,']}
                  className={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' : 'rc-tree-select-dropdown-dark'}
                  dropdownClassName={theme.className === 'theme-light' ?
                    'rc-tree-select-dropdown-light' :
                    'rc-tree-select-dropdown-dark'}
                />
                }
                {this.props.symbols_pattern &&
                <Field
                  name="symbols_text"
                  component="textarea"
                  type="text"
                  placeholder={literals.forms.filtration_pool_form.symbols}
                />
                }
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.unit}
              </span>
              <div className="form__form-group-field">
                <span style={{ color: 'orange' }}>
                  {literals.forms.filtration_pool_form.unit_note}
                </span>
                <Field
                  name="unit"
                  component={renderInputNumberField}
                  type="number"
                  step={0.1}
                  placeholder={literals.forms.filtration_pool_form.unit}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.factor}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="factor"
                  component={renderInputNumberField}
                  type="number"
                  step={0.1}
                  placeholder={literals.forms.filtration_pool_form.factor}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.priority}
              </span>
              <div className="form__form-group-field">
                <span style={{ color: 'orange' }}>
                  {literals.forms.reverse_trade_copier_form.priority_note}
                </span>
                <Field
                  name="priority"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.Priority}
                  placeholder={literals.forms.filtration_pool_form.priority}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.accepted_repeatance}
              </span>
              <div className="form__form-group-field">
                <span style={{ color: 'orange' }}>
                  {literals.forms.filtration_pool_form.accepted_repeatance_note}
                </span>
                <Field
                  name="accepted_repeatance"
                  component={renderInputNumberField}
                  type="number"
                  min={0}
                  precision={0}
                  placeholder={literals.forms.filtration_pool_form.accepted_repeatance}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.filtration_pool_form.description}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.filtration_pool_form.state}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="state"
                  component={renderCheckBoxField}
                  label={literals.forms.filtration_pool_form.state}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.filtration_pool_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.filtration_pool_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

const selector = formValueSelector('filtration_pool_form');

function mapStateToProps(state) {
  return {
    literals: state.literals,
    theme: state.theme,
    makers_pattern: selector(state, 'makers_pattern'),
    securities_pattern: selector(state, 'securities_pattern'),
    symbols_pattern: selector(state, 'symbols_pattern'),
    initialValues: {
      id: 0,
      makers_text: '*,',
      securities_text: '*,',
      symbols_text: '*,',
      unit: 1.0,
      factor: 2.0,
      priority: { label: '1', value: '1' },
      accepted_repeatance: 1,
      state: false,
    },
  };
}


export default connect(mapStateToProps)(reduxForm({
  form: 'filtration_pool_form', // a unique identifier for this form
})(FiltrationPoolForm));
