import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import markupGroupValidate from './validate';
import { LiteralProps } from '../../shared/prop-types/ReducerProps';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import renderSelectField from '../../shared/components/form/Select';

const renderField = ({
  input, placeholder, type, disabled, step, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} disabled={disabled} step={step} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
};

class MarkupGroupForm extends PureComponent {
    static propTypes = {
      literals: LiteralProps.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
      state: PropTypes.instanceOf(Object).isRequired,
    };

    render() {
      const {
        handleSubmit, pristine, reset, submitting, literals,
      } = this.props;
      const disabled = false;
      const { submit } = literals.forms.markup_group_form;
      return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.name}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.markup_group_form.name}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.bid_points}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="bid_points"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.markup_group_form.bid_points}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.ask_points}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="ask_points"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.markup_group_form.ask_points}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.state}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="state"
                  component={renderCheckBoxField}
                  label={literals.forms.markup_group_form.state}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.description}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component={renderField}
                  type="text"
                  placeholder={literals.forms.markup_group_form.description}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.enable_multiplier}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="enable_multiplier"
                  component={renderRadioButtonField}
                  label={literals.forms.markup_group_form.multiplier_1}
                  radioValue="multiplier_1"
                />
                <Field
                  name="enable_multiplier"
                  component={renderRadioButtonField}
                  label={literals.forms.markup_group_form.multiplier_2}
                  radioValue="multiplier_2"
                />
                <Field
                  name="enable_multiplier"
                  component={renderRadioButtonField}
                  label={literals.forms.markup_group_form.multiplier_3}
                  radioValue="multiplier_3"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.multiplier_1}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="multiplier_1"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.markup_group_form.multiplier_1}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.multiplier_2}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="multiplier_2"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.markup_group_form.multiplier_2}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.markup_group_form.multiplier_3}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="multiplier_3"
                  component={renderField}
                  type="number"
                  placeholder={literals.forms.markup_group_form.multiplier_3}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span
                className="form__form-group-label"
              >{literals.forms.node_account_form.copy_from}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="copy_from"
                  component={renderSelectField}
                  type="text"
                  options={this.props.state.formData.MarkupGroup}
                  disabled={disabled}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">{submit}</Button>
              <Button
                color="primary"
                type="button"
                onClick={() => this.props.state.toggle('1')}
              >{literals.forms.markup_group_form.cancel}
              </Button>
              <Button type="button" onClick={reset} disabled={pristine || submitting}>
                {literals.forms.markup_group_form.reset}
              </Button>
            </ButtonToolbar>
          </div>
        </form>
      );
    }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.state) {
    if (ownProps.state.detail) {
      return {
        literals: state.literals,
        initialValues: {
          name: ownProps.state.detail.name,
          state: ownProps.state.detail.state,
          description: ownProps.state.detail.description,
          bid_points: ownProps.state.detail.bid_points,
          ask_points: ownProps.state.detail.ask_points,
          stateMode: ownProps.state.mode,
          formData: ownProps.state.formData,
        },
      };
    }
    return {
      literals: state.literals,
      initialValues: {
        state: false,
        bid_points: 0,
        ask_points: 0,
        enable_multiplier: 'multiplier_1',
        multiplier_1: 1,
        multiplier_2: 1,
        multiplier_3: 1,
        stateMode: ownProps.state.mode,
        formData: ownProps.state.formData,
      },
    };
  }
  return { literals: state.literals };
}


export default connect(mapStateToProps)(reduxForm({
  form: 'markup_group_form', // a unique identifier for this form
  validate: markupGroupValidate,
})(MarkupGroupForm));
