/* eslint-disable no-return-assign */
import axios from 'axios';
import React from 'react';
import { reset } from 'redux-form';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../shared/components/Notification';

export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  let notification = null;
  NotificationSystem.newInstance({}, n => notification = n);
  const showNotification = (title, color, message) => {
    notification.notice({
      content: <BasicNotification
        title={title}
        message={message}
        color={color}
      />,
      duration: 3,
      closable: true,
      style: { top: 0 },
      className: 'left-up',
    });
  };
  const data = {
    lp_session_type: values.lp_session_type ? values.lp_session_type.value : '',
    lp_category: values.lp_category ? values.lp_category.value : '',
    server_address: values.server_address,
    sender_comp_id: values.sender_comp_id,
    target_comp_id: values.target_comp_id,
    external_maker: values.external_maker,
    taker: values.taker,
    protocol: values.protocol,
    shared_id: values.shared_id,
    currency: values.currency,
    username: values.username,
    password: values.password,
    account: values.account,
    session_qualifier: values.session_qualifier,
    disable_log: values.disable_log,
    enabled: values.enabled,
    reset_on_logon: values.reset_on_logon,
    persist_messages: values.persist_messages,
    ssl_protocol: values.ssl_protocol,
    copy_from: values.copy_from ? values.copy_from.value : '',
    start_day: values.start_day ? values.start_day.value : '',
    start_time: values.start_time,
    end_day: values.end_day ? values.end_day.value : '',
    end_time: values.end_time,
  };
  if (props.rootStore.authStore.role !== 'admin') {
    data.enabled = true;
    data.disable_log = false;
    if (data.lp_session_type === '2') { // FEEDING
      data.persist_messages = false;
    }
  }

  axios.post(`${process.env.REACT_APP_BACKEND_API}/security/lp_session`, JSON.stringify(data))
    .then((response) => {
      if (response.data.status) {
        dispatch(reset(stateObj.form));
        showNotification(
          props.literals.forms.lp_session_form.title_create, 'primary',
          props.literals.forms.success_message,
        );
        stateObj.resetLPSession();
      } else {
        showNotification(
          props.literals.forms.lp_session_form.title_create, 'danger',
          props.literals.forms.fail_message,
        );
        stateObj.hideLoader();
      }
    })
    .catch((error) => {
      showNotification(props.literals.forms.lp_session_form.title_create, 'danger', error.message);
      stateObj.hideLoader();
    });
};

