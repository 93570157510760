import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Tooltip } from 'react-tippy';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import BalanceTransactionReportSearchForm from './search';
import Collapse from '../../shared/components/Collapse';
import { numberFormatter, onGridSizeChanged, onColumnResized } from '../../shared/helper';
import TotalStatusBarComponent from './totalStatusBarComponent';

const windowHeight = window.screen.height;

class BalanceTransactionReport extends PureComponent {
    static propTypes = {
      theme: ThemeProps.isRequired,
      literals: LiteralProps.isRequired,
    };
    constructor(props) {
      super(props);
      const tableID = 'sidebar_raw_balance_transaction';
      this.gridWrapperID = `grid-wrapper-${tableID}`;
      this.myGridID = `myGrid_${tableID}`;
      this.gridWrapperIDTotal = `grid-wrapper-${tableID}-total`;
      this.myGridIDTotal = `myGrid_${tableID}-total`;
      const { literals } = props;
      this.state = {
        modules: AllModules,
        columnDefs: [
          {
            headerName: literals.tables.balance_transaction_table.ticket,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.ticket'),
            field: 'ticket',
            headerTooltip: literals.tables.balance_transaction_table.ticket,
            pinned: 'left',
            width: 85,
            minWidth: 85,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.open_time_msc,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.open_time_msc'),
            field: 'open_time_msc_value',
            headerTooltip: literals.tables.balance_transaction_table.open_time_msc,
            width: 90,
            minWidth: 90,
            suppressMenu: true,
          },
          {
            headerName: literals.tables.balance_transaction_table.volume,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.volume'),
            field: 'volume',
            headerTooltip: literals.tables.balance_transaction_table.volume,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.notional,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.notional'),
            field: 'notional',
            headerTooltip: literals.tables.balance_transaction_table.notional,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.amount,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.amount'),
            field: 'amount',
            headerTooltip: literals.tables.balance_transaction_table.amount,
            width: 40,
            minWidth: 40,
            cellClass: 'number',
            cellRenderer(params) {
              if (params.value < 0) {
                return `<span class="cell-renderer-red">${numberFormatter(params.value)}</span>`;
              }
              return `<span class="cell-renderer-blue">${numberFormatter(params.value)}</span>`;
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.type,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.type'),
            field: 'type_value',
            headerTooltip: literals.tables.balance_transaction_table.type,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.symbol,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.symbol'),
            field: 'symbol',
            headerTooltip: literals.tables.balance_transaction_table.symbol,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.currency,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.currency'),
            field: 'currency',
            headerTooltip: literals.tables.balance_transaction_table.currency,
            width: 33,
            minWidth: 33,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.open_price,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.open_price'),
            field: 'open_price',
            headerTooltip: literals.tables.balance_transaction_table.open_price,
            width: 40,
            minWidth: 40,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.open_cen_order,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.open_cen_order'),
            field: 'open_cen_order',
            headerTooltip: literals.tables.balance_transaction_table.open_cen_order,
            width: 85,
            minWidth: 85,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.close_time_msc,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.close_time_msc'),
            field: 'close_time_msc_value',
            headerTooltip: literals.tables.balance_transaction_table.close_time_msc,
            width: 90,
            minWidth: 90,
            suppressMenu: true,
          },
          {
            headerName: literals.tables.balance_transaction_table.close_price,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.close_price'),
            field: 'close_price',
            headerTooltip: literals.tables.balance_transaction_table.close_price,
            width: 40,
            minWidth: 40,
            cellClass: 'number',
            valueFormatter(params) {
              return numberFormatter(params.value);
            },
            type: 'number',
          },
          {
            headerName: literals.tables.balance_transaction_table.close_cen_order,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.close_cen_order'),
            field: 'close_cen_order',
            headerTooltip: literals.tables.balance_transaction_table.close_cen_order,
            width: 85,
            minWidth: 85,
            type: 'text',
          },
          {
            headerName: literals.tables.balance_transaction_table.comment,
            headerValueGetter: () => this.localizeHeader('literals.tables.balance_transaction_table.comment'),
            field: 'comment',
            headerTooltip: literals.tables.balance_transaction_table.comment,
            width: 90,
            minWidth: 90,
            type: 'text',
          },
        ],
        rowData: [],
        collapse: '',
        defaultColDef: {
          sortable: true,
          resizable: true,
          width: 50,
          minWidth: 50,
          menuTabs: ['filterMenuTab'],
          suppressMovable: true,
        },
        columnTypes: {
          text: { filter: 'agTextColumnFilter' },
          number: { filter: 'agNumberColumnFilter' },
          numberWithFilterReset: {
            filter: 'agNumberColumnFilter',
          },
        },
        getRowNodeId(data) {
          return data.ticket;
        },
        rowDataTotal: [],
        frameworkComponents: {
          totalStatusBarComponent: TotalStatusBarComponent,
        },
        statusBar: {
          statusPanels: [
            { statusPanel: 'totalStatusBarComponent' },
          ],
        },
      };
      this.onGridReady = this.onGridReady.bind(this);
      this.onGridReadyTotal = this.onGridReadyTotal.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.gridColumnApi) {
        const { literals } = this.props;
        if (literals.forms.security_form.name !== prevProps.literals.forms.security_form.name) {
          this.gridApi.refreshHeader();
        }
      }
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.onGridSizeChanged(params);
      this.gridApi.refreshHeader();
    }

    onGridSizeChanged=(params) => {
      onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
    };

    onColumnResized=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridID);
      }, 0);
    };

    onGridReadyTotal(params) {
      this.gridApiTotal = params.api;
      this.gridColumnApiTotal = params.columnApi;
      this.onGridSizeChangedTotal(params);
    }

    onGridSizeChangedTotal=(params) => {
      onGridSizeChanged(params, this.gridWrapperIDTotal, this.myGridIDTotal);
    };

    onColumnResizedTotal=(params) => {
      setTimeout(() => {
        onColumnResized(params, this.myGridIDTotal);
      }, 0);
    };

    onBtExportExcel=() => {
      const { literals } = this.props;
      const statusRows = [
        [],
        [
          {
            data: { value: literals.tables.balance_transaction_table.total_commission, type: 'String' },
          },
          {
            data: { value: literals.tables.balance_transaction_table.total_swaps, type: 'String' },
          },
          {
            data: { value: literals.tables.balance_transaction_table.total_realized_pl, type: 'String' },
          },
          {
            data: { value: literals.tables.balance_transaction_table.total_deposits, type: 'String' },
          },
          {
            data: { value: literals.tables.balance_transaction_table.total_withdrawal, type: 'String' },
          },
          {
            data: { value: literals.tables.balance_transaction_table.total_credit, type: 'String' },
          },
        ],
        [
          {
            data: { value: this.state.rowDataTotal.total_commission || 0, type: 'Number' },
          },
          {
            data: { value: this.state.rowDataTotal.total_swaps || 0, type: 'Number' },
          },
          {
            data: { value: this.state.rowDataTotal.total_realized_pl || 0, type: 'Number' },
          },
          {
            data: { value: this.state.rowDataTotal.total_deposits || 0, type: 'Number' },
          },
          {
            data: { value: this.state.rowDataTotal.total_withdrawal || 0, type: 'Number' },
          },
          {
            data: { value: this.state.rowDataTotal.total_credit || 0, type: 'Number' },
          },
        ],
      ];
      const params = {
        fileName: 'ClosedTransactions.xlsx',
        appendContent: statusRows,
      };
      this.gridApi.exportDataAsExcel(params);
    };

    handleSubmit=(values) => {
      this.gridApi.showLoadingOverlay();
      axios.post(
        `${process.env.REACT_APP_BACKEND_API}/report/raw_balance_transaction?page=balance_transaction`,
        JSON.stringify({
          start_date: values.start_date ? moment.utc(values.start_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
            : values.start_date,
          end_date: values.end_date ? moment.utc(values.end_date, 'DD-MM-YYYY HH:mm:ss').valueOf() * 1000
            : values.end_date,
          risk_account: values.risk_account ? values.risk_account.value : '',
        }),
      )
        .then((response) => {
          this.setState({ rowData: response.data.BalanceTransaction });
          const total =
            {
              total_commission: response.data.TotalCommission,
              total_swaps: response.data.TotalSwaps,
              total_realized_pl: response.data.TotalRealizedPL,
              total_deposits: response.data.TotalDeposits,
              total_withdrawal: response.data.TotalWithdrawal,
              total_credit: response.data.TotalCredit,
            };
          this.setState({ rowDataTotal: total });
          this.gridApi.hideOverlay();
        })
        .catch(() => {
          this.gridApi.hideOverlay();
        });
      this.setState({ collapse: 'force-close' });
      setTimeout(() => {
        this.setState({ collapse: '' });
      }, 0);
    };

    localizeHeader=header => _.get(this.props, header)

    render() {
      const { literals } = this.props;
      const theme = this.props.theme.className;
      let height = ((this.state.rowData ? this.state.rowData.length : 0) * 28) + 56 + 34;
      const wHeight = windowHeight - 550;
      if (height > wHeight) {
        height = wHeight;
      }
      return (
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title card__title_custom">
                <h5 className="bold-text">{literals.sidebar.closed_transaction}
                </h5>
              </div>
              <Collapse
                title={literals.tables.ag_table.toggle_search}
                className="with-shadow"
                collapse={this.state.collapse}
              >
                <BalanceTransactionReportSearchForm onSubmit={this.handleSubmit} />
              </Collapse>
              <div className="outer-grid-label">
                {literals.tables.ag_table.total_records}: {this.state.rowData ? this.state.rowData.length : 0}
              </div>
              <div className="outer-grid-button">
                <ButtonToolbar>
                  <Tooltip title={literals.tables.ag_table.export_excel}>
                    <Button onClick={this.onBtExportExcel} className="icon" color="primary">
                      <p className="fa fa-file-excel" />
                    </Button>
                  </Tooltip>
                </ButtonToolbar>
              </div>
              <div id={this.gridWrapperID} style={{ width: '100%', height: '100%', clear: 'both' }}>
                <div
                  id={this.myGridID}
                  style={{
                                    boxSizing: 'border-box',
                                    height,
                                    width: '100%',
                                }}
                  className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnDefs}
                    immutableData
                    suppressCellSelection
                    suppressContextMenu
                    defaultColDef={this.state.defaultColDef}
                    onColumnResized={this.onColumnResized}
                    getRowNodeId={this.state.getRowNodeId}
                    rowData={this.state.rowData}
                    onGridReady={this.onGridReady}
                    onGridSizeChanged={this.onGridSizeChanged}
                    columnTypes={this.state.columnTypes}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    context={{
                      rowDataTotal: this.state.rowDataTotal,
                    }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }
}

export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(BalanceTransactionReport);
