import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { connect } from 'react-redux';
import { toJS } from 'mobx';
import moment from 'moment';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { LiteralProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { addCommas, decimalPlaces, onGridSizeChanged, onColumnResized } from '../../shared/helper';

const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));
const windowHeight = window.screen.height;

class PositionView extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    literals: LiteralProps.isRequired,
    selectedSymbols: PropTypes.instanceOf(Array),
    marketWatch: PropTypes.instanceOf(Array),
    marketWatchLast: PropTypes.instanceOf(Array),
    activeTab: PropTypes.string.isRequired,
  };
  static defaultProps = {
    selectedSymbols: [],
    marketWatch: [],
    marketWatchLast: [],
  }
  constructor(props) {
    super(props);
    const { literals } = this.props;
    const tableID = 'sidebar_market_watch';
    this.gridWrapperID = `grid-wrapper-${tableID}`;
    this.myGridID = `myGrid_${tableID}`;
    this.state = {
      modules: AllModules,
      columnDefs: [
        {
          headerName: literals.tables.trading_table.symbol,
          field: 'symbol',
          headerTooltip: literals.tables.trading_table.symbol,
          pinned: 'left',
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.tables.trading_table.bid_price,
          field: 'bid_price',
          headerTooltip: literals.tables.trading_table.bid_price,
          cellClass: 'number',
          cellClassRules: {
            'price-change-down': 'node.data.bid_price_change === "down"',
            'price-change-up': 'node.data.bid_price_change === "up"',
            'price-change-neutral': 'node.data.bid_price_change === "neutral"',
          },
          valueFormatter(params) {
            return addCommas(parseFloat(params.value).toFixed(params.node.data.digits
              || decimalPlaces(params.value)).toString());
          },
        },
        {
          headerName: literals.tables.trading_table.ask_price,
          field: 'ask_price',
          headerTooltip: literals.tables.trading_table.ask_price,
          cellClass: 'number',
          cellClassRules: {
            'price-change-down': 'node.data.ask_price_change === "down"',
            'price-change-up': 'node.data.ask_price_change === "up"',
            'price-change-neutral': 'node.data.ask_price_change === "neutral"',
          },
          valueFormatter(params) {
            return addCommas(parseFloat(params.value).toFixed(params.node.data.digits
              || decimalPlaces(params.value)).toString());
          },
        },
        {
          headerName: literals.tables.trading_table.spread,
          field: 'spread',
          cellClass: 'number',
          headerTooltip: literals.tables.trading_table.spread,
          valueFormatter(params) {
            return addCommas(parseFloat(params.value).toFixed(params.node.data.digits
              || decimalPlaces(params.value)).toString());
          },
        },
        {
          headerName: literals.tables.trading_table.spread_points,
          field: 'spread_points',
          cellClass: 'number',
          headerTooltip: literals.tables.trading_table.spread_points,
          valueFormatter(params) {
            return addCommas(params.value.toString());
          },
        },
        {
          headerName: literals.tables.trading_table.bid_volume,
          field: 'bid_volume',
          cellClass: 'number',
          headerTooltip: literals.tables.trading_table.bid_volume,
          valueFormatter(params) {
            return new Intl.NumberFormat('en-GB', {
              notation: 'compact',
              maximumFractionDigits: params.node.data.vol_digits,
            }).format(params.value);
          },
        },
        {
          headerName: literals.tables.trading_table.ask_volume,
          field: 'ask_volume',
          cellClass: 'number',
          headerTooltip: literals.tables.trading_table.ask_volume,
          valueFormatter(params) {
            return new Intl.NumberFormat('en-GB', {
              notation: 'compact',
              maximumFractionDigits: params.node.data.vol_digits,
            }).format(params.value);
          },
        },
        {
          headerName: literals.tables.trading_table.last_updated,
          field: 'last_updated',
          headerTooltip: literals.tables.trading_table.last_updated,
          width: 60,
          minWidth: 60,
        },
        {
          headerName: literals.tables.trading_table.ltp_price,
          field: 'ltp_price',
          cellClass: 'number',
          headerTooltip: literals.tables.trading_table.ltp_price,
          valueFormatter(params) {
            return addCommas(parseFloat(params.value).toFixed(params.node.data.digits
                || decimalPlaces(params.value)).toString());
          },
        },
        {
          headerName: literals.tables.trading_table.ltp_volume,
          field: 'ltp_volume',
          cellClass: 'number',
          headerTooltip: literals.tables.trading_table.ltp_volume,
          valueFormatter(params) {
            return new Intl.NumberFormat('en-GB', {
              notation: 'compact',
              maximumFractionDigits: params.node.data.vol_digits,
            }).format(params.value);
          },
        },
      ],
      defaultColDef: {
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        sortable: true,
        resizable: true,
        width: 50,
        minWidth: 50,
      },
      getRowNodeId(data) {
        return data.symbol;
      },
    };
    this.onGridReady = this.onGridReady.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.gridColumnApi) {
      const { literals } = this.props;
      if (literals.tables.trading_table.symbol !== prevProps.literals.tables.trading_table.symbol) {
        this.gridColumnApi.getColumn('symbol').getColDef().headerName
          = literals.tables.trading_table.symbol;
        this.gridColumnApi.getColumn('bid_price').getColDef().headerName
          = literals.tables.trading_table.bid_price;
        this.gridColumnApi.getColumn('ask_price').getColDef().headerName
          = literals.tables.trading_table.ask_price;
        this.gridColumnApi.getColumn('spread').getColDef().headerName
          = literals.tables.trading_table.spread;
        this.gridColumnApi.getColumn('spread_points').getColDef().headerName
            = literals.tables.trading_table.spread_points;
        this.gridColumnApi.getColumn('bid_volume').getColDef().headerName
          = literals.tables.trading_table.bid_volume;
        this.gridColumnApi.getColumn('ask_volume').getColDef().headerName
          = literals.tables.trading_table.ask_volume;
        this.gridColumnApi.getColumn('last_updated').getColDef().headerName
            = literals.tables.trading_table.last_updated;
        this.gridApi.refreshHeader();
      }
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridSizeChanged=(params) => {
    onGridSizeChanged(params, this.gridWrapperID, this.myGridID);
  };

  onColumnResized=(params) => {
    setTimeout(() => {
      onColumnResized(params, this.myGridID);
    }, 0);
  };

  render() {
    const theme = this.props.theme.className;
    let height = 0;
    let symbolData = [];
    if (this.props.activeTab === '2') {
      const tt = this.props.marketWatch ?
        this.props.marketWatch : [];
      const ttLast = this.props.marketWatchLast ?
        this.props.marketWatchLast : [];
      const vt = this.props.selectedSymbols ? this.props.selectedSymbols : [];
      const symbolItem = arrayToObject(toJS(tt), 'symbol');
      const symbolItemLast = arrayToObject(toJS(ttLast), 'symbol');
      symbolData = vt.map((item) => {
        if (typeof item === 'object' && typeof item.value !== 'undefined' && typeof item.digits !== 'undefined') {
          let itemVal = symbolItem[item.value] || symbolItemLast[item.value];
          if (!itemVal) {
            itemVal = {};
            itemVal.symbol = item.value;
            itemVal.bid_volume = 0;
            itemVal.ask_volume = 0;
            itemVal.last_updated = '-';
          } else {
            const lastUpdatedLocalTime = itemVal.last_updated ?
              moment.utc(itemVal.last_updated, 'DD-MM-YYYY HH:mm:ss') : '-';
            itemVal.last_updated = itemVal.last_updated ?
              lastUpdatedLocalTime.format('DD-MM-YYYY HH:mm:ss') : lastUpdatedLocalTime;
          }
          itemVal.digits = item.digits;
          itemVal.vol_digits = item.vol_digits;
          itemVal.bid_price = itemVal.bid_price || 0;
          itemVal.ask_price = itemVal.ask_price || 0;
          itemVal.spread = Math.abs(itemVal.ask_price - itemVal.bid_price);
          itemVal.spread_points = 0;
          if (parseFloat(itemVal.ask_price || 0) !== 0 && parseFloat(itemVal.bid_price || 0) !== 0) {
            itemVal.spread_points = Math.round(itemVal.spread * (10 ** item.digits));
          }
          return itemVal;
        }
        return null;
      });
      height = ((symbolData.length) * 28) + 56 + 34;
      const wHeight = windowHeight - 420;
      if (height > wHeight) {
        height = wHeight;
      }
    }
    return (
      <div id={this.gridWrapperID} style={{ width: '100%', height: '100%' }}>
        <div
          id={this.myGridID}
          style={{
            boxSizing: 'border-box',
            height,
            width: '100%',
          }}
          className={theme === 'theme-light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            immutableData
            suppressCellSelection
            suppressContextMenu
            defaultColDef={this.state.defaultColDef}
            onColumnResized={this.onColumnResized}
            rowData={symbolData}
            getRowNodeId={this.state.getRowNodeId}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.onGridSizeChanged}
          />
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  theme: state.theme,
  literals: state.literals,
}))(PositionView);
