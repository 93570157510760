export const LOAD_LITERALS_EN = 'LOAD_LITERALS_EN';
export const LOAD_LITERALS_CN = 'LOAD_LITERALS_CN';
export const LOAD_LITERALS_JA = 'LOAD_LITERALS_JA';
export const LOAD_LITERALS_HI = 'LOAD_LITERALS_HI';

export function loadLiteralsEN() {
  return {
    type: LOAD_LITERALS_EN,
  };
}

export function loadLiteralsCN() {
  return {
    type: LOAD_LITERALS_CN,
  };
}

export function loadLiteralsJA() {
  return {
    type: LOAD_LITERALS_JA,
  };
}

export function loadLiteralsHI() {
  return {
    type: LOAD_LITERALS_HI,
  };
}
