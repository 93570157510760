export default async (values, dispatch, props) => {
  const { state: stateObj } = props;
  stateObj.showLoader();
  const data = {
    name: values.name,
    group: values.group ? values.group.value : null,
    currency: values.currency,
    category: 0,
    tm_models: `${values.tm_models}@*`,
    type: values.type ? parseInt(values.type.value, 10) : null,
    stopout: parseInt(values.stopout, 10),
    exposure_limit: parseFloat(values.exposure_limit),
    exposure_delta: -1,
    exposure_delta_span: -1,
    underflow: -1,
    warn_levels: values.warn_levels,
    description: values.description,
    state: values.state ? 1 : 0,
    square_off_mode: values.square_off_mode ? parseInt(values.square_off_mode.value, 10) : null,
    margin_call_level: parseFloat(values.margin_call_level),
  };

  const ra = { label: values.name, value: values.name, risk_account: data };
  stateObj.addRiskAccount(ra);
  stateObj.selectRiskAccount(ra);
  stateObj.toggleRiskAccount();
  stateObj.hideLoader();
};
