const loginValidate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Username field shouldn’t be empty';
  }
  if (!values.password) {
    errors.password = 'Password field shouldn’t be empty';
  }
  return errors;
};

export default loginValidate;

